<avn-dialog-layout *transloco="let t; read: 'tagOperation.TAG_OPERATION.TAG_VALUE_DETAIL.CREATE_TAG'">
  <avn-dialog-header [showCloseIcon]="dialogConfig.showCloseIcon" [title]="t('ASSIGN_TAG_50')" (closeClicked)="onClose()"></avn-dialog-header>
  <avn-dialog-content class="tag-creation-modal-body-content">
    <ng-container *ngIf="tagKeysOptionList$ | async as options">
      <!-- Choose tag -->
      <avn-autocomplete
        class="tag-creation-modal-dropdown"
        [placeholder]="t('ENTER_A_VALUE_100')"
        [maxOptionsVisibleInViewport]="7"
        [value]=""
        [datasource]="options"
        [highlightMatchingText]="true"
        (valueChanged)="onKeyChanged($event)"
      >
      </avn-autocomplete>

      <!-- Define value based on json-schema -->
      <avn-json-schema-control-field
        *ngIf="currentSchema$ | async as schema"
        class="json-schema-form"
        [jsonSchema]="schema"
        [formControl]="jsonSchemaControl"
        [freeForm]="isJsonSchema$ | async"
        [required]="true"
      ></avn-json-schema-control-field>

      <div *ngIf="currentKey$ | async | applyFunction: getOwnerProperty as owner" class="owner">
        <label>{{ 'engineTagManager.CONTROL.APP_OWNER.TITLE' | transloco }}: </label>
        <span>{{ owner }}</span>
      </div>
    </ng-container>
  </avn-dialog-content>

  <avn-dialog-footer class="row-flex">
    <avn-button [label]="'button.cancel' | transloco" (actioned)="onClose()"></avn-button>
    <avn-button [disabled]="!jsonSchemaControl.valid" [label]="t('ASSIGN_TAG_50')" (actioned)="onSave()" avnTheme="primary"></avn-button>
  </avn-dialog-footer>
</avn-dialog-layout>
