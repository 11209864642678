import { InjectionToken } from '@angular/core';

/** Site management module config token */
export interface IEngineTagManagerConfig {
  /** app base path for the module */
  moduleBasePath: string[];
}

/** Default values of site management module config token */
export const DEFAULT_ENGINE_TAG_MANAGER_CONFIG: IEngineTagManagerConfig = {
  moduleBasePath: ['app', 'engine-tags'],
};

export const ENGINE_TAG_MANAGER_MODULE_CONFIG = new InjectionToken<IEngineTagManagerConfig>('ENGINE_TAG_MANAGER_CONFIG');
