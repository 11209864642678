<avn-page>
  <avn-ribbon [title]="'siteManagementScope.SITE_MANAGEMENT.EXPERIENCE_TEMPLATE.RIBBON.TITLE.TITLE_50' | transloco"
              [fabIcons]="'action:settings'" (fabActionClicked)="openSettingsDrawer()">
    <avn-breadcrumbs>
      <avn-breadcrumb [label]="'siteManagementScope.SITE_MANAGEMENT.DASHBOARD.RIBBON.TITLE.TITLE_50' | transloco"
                      [routerLink]="['..']"></avn-breadcrumb>
    </avn-breadcrumbs>
  </avn-ribbon>
  <avn-main>
    <avn-resizeable-layout [layout]="layout">
      <section *avnResizeableLayoutSection="'list'">
        <amp-experience-template-list [editMode]="true" (selectTemplate)="onSelectTemplate($event)"
                                      (addNewTemplate)="addNewTemplate()"></amp-experience-template-list>
      </section>
      <section class="experience-template-wizard-section" *avnResizeableLayoutSection="'edit'" avnScroll>
        <amp-experience-template-edit-general [template]="selectedTemplate" (selectBoard)="onBoardSelect($event)"
                                              (addNewBoard)="addNewBoard()"></amp-experience-template-edit-general>
      </section>

      <section class="experience-template-wizard-section" *avnResizeableLayoutSection="'detail'" avnScroll>
        <amp-experience-template-edit-board [boardTemplate]="selectedBoard"></amp-experience-template-edit-board>
      </section>
    </avn-resizeable-layout>
  </avn-main>
</avn-page>
