import { Action, createReducer, on } from '@ngrx/store';
import { DeviceActionCommandEnum } from '../model/device-command.enum';
import { CommandEntityType, IRunningCommand } from '../model/running-command.interface';
import * as CommandsAction from './commands.action';

export interface ICommandsState {
  /** The commands manually run by the user **/
  runningCommands: IRunningCommand[];
  /** The commands manually run by the user and completed **/
  completedCommands: IRunningCommand[];
  /** Throttled commands cannot be run again until they are removed from this array **/
  throttledCommands: {
    entityId: number;
    action: DeviceActionCommandEnum;
  }[];
}

export const initialCommandsState: ICommandsState = {
  runningCommands: [],
  completedCommands: [],
  throttledCommands: [],
};

const reducer = createReducer(
  initialCommandsState,

  on(CommandsAction.RunDeviceCommandSuccess, (state, action) => {
    // add the command to the list of running commands
    const { runningCommand } = action;

    return {
      ...state,
      runningCommands: [...state.runningCommands, runningCommand],
    };
  }),

  on(CommandsAction.DeviceCommandThrottle, (state, action) => {
    const { runningCommand } = action;

    const entityIds: number[] = runningCommand.entityType === CommandEntityType.DEVICE ?
      [runningCommand.entityId] : runningCommand.devices.map((device) => device.id);
    const entityIdsAlreadyThrottled: number[] = state.throttledCommands.filter((cmd) => cmd.action === runningCommand.action).map((cmd) => cmd.entityId);
    const entityIdsToThrottled = entityIds.filter((entityId) => !entityIdsAlreadyThrottled.includes(entityId));
    if (entityIdsToThrottled.length === 0) {
      return state;
    }
    return {
      ...state,
      throttledCommands: [
        ...state.throttledCommands,
        ...entityIdsToThrottled.map((entityIdToThrottled) => ({ action: runningCommand.action, entityId: entityIdToThrottled }))
      ],
    };
  }),

  on(CommandsAction.DeviceCommandUnthrottle, (state, action) => {
    const { runningCommand } = action;

    const entityIds: number[] = runningCommand.entityType === CommandEntityType.DEVICE ? [runningCommand.entityId] :
      runningCommand.devices.map((device) => device.id);

    const hasThrottledCommandsToRemove = state.throttledCommands.some((throttledCommand) => throttledCommand.action === runningCommand.action && entityIds.includes(throttledCommand.entityId));
    if (!hasThrottledCommandsToRemove) {
      return state;
    }

    const throttledCommands = [];
    state.throttledCommands.forEach((cmd) => {
      if (!entityIds.includes(cmd.entityId)) {
        throttledCommands.push(cmd);
      }
    });

    return {
      ...state,
      throttledCommands,
    };
  }),

  on(CommandsAction.DeviceCommandCompleted, (state, action) => {
    const { commandStatus } = action;
    // find the running command
    const completedCommand = state.runningCommands.find((runningCommand) => runningCommand.commandStatusId === commandStatus.id);
    if (!completedCommand) {
      return state;
    }

    // remove from the list of running commands and add to the completed commands
    return {
      ...state,
      runningCommands: state.runningCommands.filter((r) => r.commandStatusId !== commandStatus.id),
      completedCommands: [
        ...state.completedCommands,
        {
          ...completedCommand,
          commandStatus,
        },
      ],
    };
    return state;
  }),

  on(CommandsAction.DeviceCommandUpdateStatus, (state, action) => {
    // remove from the list of running commands
    const { commandStatus, deviceCommand } = action;
    const commandIndex = state.runningCommands.findIndex((rc) => rc.commandStatusId === commandStatus.id);
    if (commandIndex === -1) {
      return state;
    }
    const updatedCommand: IRunningCommand = {
      ...state.runningCommands[commandIndex],
      commandStatus,
      deviceCommand: deviceCommand || state.runningCommands[commandIndex].deviceCommand,
    };
    return {
      ...state,
      runningCommands: [...state.runningCommands.slice(0, commandIndex), updatedCommand, ...state.runningCommands.slice(commandIndex + 1)],
    };
  }),

  on(CommandsAction.DeviceCommandClearCompleted, (state) => ({
    ...state,
    completedCommands: [],
  }))
);

export const commandsReducer = (state: ICommandsState | undefined, action: Action): ICommandsState => reducer(state, action);
