import { DeviceDTO } from '@activia/cm-api';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import * as DeviceActions from './device.actions';
import * as SiteActions from '../site-management.actions';
import * as DisplayAction from '../display/display.actions';
import { AsyncDataState, LoadingState } from '@activia/ngx-components';

export const DEVICE_FEATURE_KEY = 'device';

export const adapter: EntityAdapter<DeviceDTO> = createEntityAdapter<DeviceDTO>();

export interface DeviceState extends EntityState<DeviceDTO> {
  temporaryDeletedDevices: Record<number, DeviceDTO>; // Optimistic delete
  temporaryUpdatedDevices: Record<number, DeviceDTO>; // Optimistic update

  /** Site id where the devices are linked */
  currentSiteId: number;

  /** Active device that is selected/activated in Players tab */
  activatedDeviceId: number;

  /** Current state of the async data.  **/
  status: AsyncDataState;

  /** Last attempt loading the data **/
  lastAttempt?: Date;

  /** Last attempt successfully data was process successfully **/
  lastSuccess?: Date;
}

export const initialState: DeviceState = adapter.getInitialState({
  temporaryDeletedDevices: {},
  temporaryUpdatedDevices: {},
  currentSiteId: undefined,
  activatedDeviceId: undefined,
  status: LoadingState.INIT,
  lastAttempt: undefined,
  lastSuccess: undefined,
});

export const reducer = createReducer(
  initialState,
  //#region FetchSiteDetail
  on(SiteActions.FetchSiteDetail, (state) => ({
    ...state,
    currentSiteId: undefined,
    status: LoadingState.LOADING,
    lastAttempt: new Date(),
  })),

  on(SiteActions.FetchSiteDetailSuccess, (state, action) => ({
    ...adapter.setAll(action.devices, state),
    currentSiteId: action.site.id,
    status: LoadingState.LOADED,
    lastSuccess: new Date(),
  })),

  on(SiteActions.FetchSiteDetailFail, (state, action) => ({
    ...state,
    status: { errorMsg: action.error },
  })),
  //#endregion

  //#region CreateTemplateBoards
  on(SiteActions.CreateTemplateBoardsSuccess, (state, action) => {
    // Check if the updated site is the current site
    if (action.site.id === state.currentSiteId) {
      return {
        ...adapter.addMany(action.devices, state),
      };
    } else {
      // Do nothing if we're not editing the current site
      return state;
    }
  }),
  //#endregion

  //#region SetDeviceToDisplay
  on(DisplayAction.SetDeviceToDisplaySuccess, (state, action) => adapter.upsertOne(action.device, state)),
  //#endregion

  //#region ChangeActiveDevice
  on(DeviceActions.ChangeActiveDevice, (state, action) => ({
    ...state,
    activatedDeviceId: action.deviceId,
  })),
  //#endregion

  //#region AddDevices
  on(DeviceActions.AddDevices, (state, action) => adapter.addMany(action.devices, state)), // Optimistic Add

  on(DeviceActions.AddDevicesFail, (state, action) => adapter.removeMany(action.deviceIds, state)),
  //#endregion

  //#region DeleteDevices
  on(DeviceActions.DeleteDevices, (state, action) => {
    const deletedDevices = action.deviceIds.reduce((acc, curr) => {
      acc[curr] = state.entities[curr];
      return acc;
    }, {});

    return {
      ...adapter.removeMany(action.deviceIds, state), // Optimistic delete
      temporaryDeletedDevices: {
        ...state.temporaryDeletedDevices,
        ...deletedDevices, // Temporary deleted device saved in case it fails
      },
    };
  }),

  on(DeviceActions.DeleteDevicesSuccess, (state, action) => adapter.removeMany(action.deviceIds, state)),

  on(DeviceActions.DeleteDevicesFail, (state, action) => {
    // Delete failed so remove deleted board from backup cache and add it back to entities
    const backupDevices = [];
    const temporaryDeletedDevices = { ...state.temporaryDeletedDevices };

    action.deviceIds.forEach((id) => {
      backupDevices.push(state.temporaryDeletedDevices[id]);
      delete temporaryDeletedDevices[id];
    });

    return {
      ...adapter.addMany(backupDevices, state),
      temporaryDeletedDevices,
      status: { errorMsg: action.error },
    };
  })
  //#endregion
);
