import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ISiteManagementSyncState } from './site-sync.reducer';
import { SiteValidationStatus } from './site-sync.model';

const siteSyncSelector = createFeatureSelector<ISiteManagementSyncState>('siteSync');

const status = createSelector(siteSyncSelector, (state) => state.status);
const hasSyncJob = createSelector(status, (state) => state !== 'init');
const hasSyncJobInProgress = createSelector(status, (state) => state === 'in-progress');
const messages = createSelector(siteSyncSelector, (state) => state.messages);
const sitesSyncState = createSelector(siteSyncSelector, (state) => state.sitesSyncState);
const sitesStatus = createSelector(sitesSyncState, (state) => Object.values(state));
const allSitesCount = createSelector(sitesStatus, (state) => state.length);
const sitesValidationStatusCount = createSelector(sitesSyncState, (state) => {
  if (!state) {
    return null;
  }
  const res: Record<SiteValidationStatus | 'invalid', number> = { add: 0, update: 0, unchanged: 0, 'invalid-id': 0, invalid: 0 };
  Object.values(state).forEach((siteImportState) => {
    res[siteImportState.validationStatus]++;
    if (['invalid-id'].includes(siteImportState.validationStatus)) {
      res['invalid']++;
    }
  });
  return res;
});
const errorSitesCount = createSelector(sitesValidationStatusCount, (state) => state.invalid);
const hasNewSites = createSelector(sitesValidationStatusCount, (state) => state.add > 0);

const canProcessCsvFile = createSelector(sitesValidationStatusCount, (counts) => counts?.add > 0 || counts?.update > 0);

const fixedMissingRequiredValueSites = createSelector(siteSyncSelector, (state) => state.fixedMissingRequiredValueSites);
const isMissingRequiredValueFixedForSite = (siteUuid: string) => createSelector(fixedMissingRequiredValueSites, (fixedSitesUids) => fixedSitesUids.includes(siteUuid));

const fixedGeoSites = createSelector(siteSyncSelector, (state) => state.fixedGeoSites);
const isGeoFixedForSite = (siteUuid: string) => createSelector(fixedGeoSites, (fixedSitesUids) => fixedSitesUids.includes(siteUuid));

const deviceTypes = createSelector(siteSyncSelector, (state) => state.deviceTypes);

export const siteSyncSelectors = {
  status,
  hasSyncJobInProgress,
  hasSyncJob,
  messages,
  sitesSyncState,
  sitesValidationStatusCount,
  canProcessCsvFile,
  sitesStatus,
  allSitesCount,
  errorSitesCount,
  hasNewSites,
  isMissingRequiredValueFixedForSite,
  isGeoFixedForSite,
  deviceTypes,
};
