import { SiteDTO } from '@activia/cm-api';
import { CountryService } from '@activia/geo';
import { ISiteManagementConfig, SITE_MANAGEMENT_MODULE_CONFIG } from '@amp/environment';
import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { first, Observable } from 'rxjs';
import { CMRole } from '@amp/auth';
import { ISiteManagementState } from '../../../../store/site-management.reducer';
import { siteManagementEntities } from '../../../../store/site-management.selectors';
import * as SiteManagementAction from '../../../../store/site-management.actions';
import { ThemeType } from '@activia/ngx-components';
import { formatSiteAddress } from '../../../../utils/site.utils';
import { SiteMonitoringFacade, SiteProperties } from '@amp/site-monitoring-shared';
import { SiteManagementService } from '../../../../services/site-management.service';

@Component({
  selector: 'amp-site-detail-side-menu',
  templateUrl: './site-detail-side-menu.component.html',
  styleUrls: ['./site-detail-side-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SiteDetailSideMenuComponent {
  /** roles */
  CmRole = CMRole;

  site$: Observable<SiteDTO> = this._siteStore.pipe(siteManagementEntities.selectedCurrentSite$);
  selectedSites$: Observable<SiteDTO[]> = this._siteStore.pipe(siteManagementEntities.selectedSites$);

  THEME_TYPES = ThemeType;
  SiteProperties = SiteProperties;

  editable$: Observable<boolean>;

  constructor(
    private _siteStore: Store<ISiteManagementState>,
    private router: Router,
    private _countryService: CountryService,
    @Inject(SITE_MANAGEMENT_MODULE_CONFIG) private _siteManagementConfig: ISiteManagementConfig,
    public siteMonitoringFacade: SiteMonitoringFacade,
    private _siteManagementService: SiteManagementService,
  ) {
    this.editable$ = this._siteManagementService.hasAuthority$('site');
  }

  goToBoardDetail() {
    this.site$.pipe(first()).subscribe((site) => {
      this.router.navigate([...this._siteManagementConfig.moduleBasePath, site.id]);
    });
  }

  formatAddress(site: SiteDTO): string {
    return formatSiteAddress(site, this._countryService);
  }

  onSiteSelectionChange(selectedSiteId: string): void {
    this.router.navigate([...this._siteManagementConfig.moduleBasePath, 'sites', selectedSiteId], { queryParamsHandling: 'preserve' });
  }

  onPushToPlayers() {
    this._siteStore.dispatch(SiteManagementAction.PushChangesToPlayers({ currentSiteOnly: true }));
  }
}
