<ng-container *ngIf="model$ | async as model">
  <avn-task-panel-item [icon]="'social:location_city'" [iconText]="'siteManagementScope.SITE_MANAGEMENT.SYNC_SITES.TASK_PANEL.TITLE_50' | transloco">
    <div class="progress" avnTaskPanelItemFeedbackSlot>
      <avn-progressbar *ngIf="model.totalCount > 0" [showValue]="true" [showIcon]="false" [max]="model.totalCount" [finished]="model.completePercentage === 100">
        <avn-progress-segment [value]="model.completedCount" avnTheme="info"></avn-progress-segment>
      </avn-progressbar>
      <div class="status-info-wrapper">
        <span>{{ 'siteManagementScope.SITE_MANAGEMENT.SYNC_SITES.TASK_PANEL.COMPLETED_TEXT_50' | transloco: { completed: model.completedCount, total: model.totalCount } }}</span>
        <div class="count-icons">
          <ng-container *ngIf="model.errorCount > 0">
            <ng-container *ngTemplateOutlet="iconCount; context: { icon: SiteSyncStatusIcons['error'], count: model.errorCount, theme: SiteSyncStatusThemes['error'] }"></ng-container>
          </ng-container>
          <ng-container *ngIf="model.warningCount > 0">
            <ng-container *ngTemplateOutlet="iconCount; context: { icon: SiteSyncStatusIcons['warning'], count: model.warningCount, theme: SiteSyncStatusThemes['warning'] }"></ng-container>
          </ng-container>
          <ng-container *ngIf="model.skippedCount > 0">
            <ng-container *ngTemplateOutlet="iconCount; context: { icon: SiteSyncStatusIcons['unchanged'], count: model.skippedCount, theme: SiteSyncStatusThemes['unchanged'] }"></ng-container>
          </ng-container>
          <ng-container *ngIf="model.processedCount > 0">
            <ng-container *ngTemplateOutlet="iconCount; context: { icon: SiteSyncStatusIcons['processed'], count: model.processedCount, theme: SiteSyncStatusThemes['processed'] }"></ng-container>
          </ng-container>
        </div>
      </div>
    </div>

    <avn-button
      avnTaskPanelItemActionSlot
      size="small"
      [avnTheme]="'primary'"
      [label]="'siteManagementScope.SITE_MANAGEMENT.SYNC_SITES.TASK_PANEL.ACTION_TITLE_50' | transloco"
      (actioned)="showImportSummary()"
    >
    </avn-button>
  </avn-task-panel-item>
</ng-container>

<ng-template #iconCount let-theme="theme" let-count="count" let-icon="icon">
  <div class="icon-count-wrapper">
    <avn-icon [fontSize]="15" [icon]="icon" [avnTheme]="theme"></avn-icon>
    <span>{{ count }}</span>
  </div>
</ng-template>
