import { Observable, of, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { KeyValueDTO, CurrentUserService } from '@activia/cm-api';
import { HttpResponse } from '@angular/common/http';

/**
 * Get all user preferences of the current user.
 *
 * @param currentUserService reference to the user preference service
 */
export const getUserPreferences = (currentUserService: CurrentUserService): Observable<Array<KeyValueDTO>> => currentUserService.getPreferencesByUsername();

/**
 * Get one user preference by key.
 * If 404 not found is returned, and if handleNotFoundError is set to true, the default value would be returned.
 * If 404 not found is returned, and if handleNotFoundError is set to false, the same error would be re-thrown.
 *
 * @param currentUserService reference to the user preference service
 * @param preferenceKey the key of the preference
 * @param defaultValue a default value to be returned if the preference data does not exists
 */
export const getUserPreferencesByKey = <T>(currentUserService: CurrentUserService, preferenceKey: string, defaultValue: T, handleNotFoundError = true): Observable<T> => currentUserService.getSinglePreferenceByKey(preferenceKey).pipe(
    map((dto) => {
      const pref = JSON.parse((dto as KeyValueDTO)?.value || null) || defaultValue;
      return pref;
    }),
    catchError((err) => (err.status === 404 && handleNotFoundError ? of(defaultValue) : throwError(err)))
  );

/**
 * Update one user preference by key
 *
 * @param currentUserService reference to the user preference service
 * @param preferenceKey the key of the preference
 * @param value the value to save in preferences
 */
export const updateUserPreferencesByKey = <T = any>(currentUserService: CurrentUserService, preferenceKey: string, value: T): Observable<HttpResponse<any>> => currentUserService.setKeyValuesPreferencesByUsername([
    {
      key: preferenceKey,
      value: JSON.stringify(value),
    },
  ]);

/**
 * Update a list of user preferences
 *
 * @param currentUserService reference to the user preference service
 * @param preferenceKey the key of the preference
 * @param value the value to save in preferences
 */
export const updateUserPreferences = (currentUserService: CurrentUserService, preferences: Array<KeyValueDTO>): Observable<HttpResponse<any>> => currentUserService.setKeyValuesPreferencesByUsername(preferences);
