import { DisplayDTO, SiteDTO } from '@activia/cm-api';
import { AsyncDataState, dataOnceReady, SectionBreadCrumb } from '@activia/ngx-components';
import { ISiteManagementConfig, SITE_MANAGEMENT_MODULE_CONFIG } from '@amp/environment';
import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { first, map, Observable } from 'rxjs';
import { BoardState } from '../../../../store/board/board.reducer';
import { DisplayState } from '../../../../store/display/display.reducer';
import * as DisplaySelectors from '../../../../store/display/display.selectors';
import * as BoardSelectors from '../../../../store/board/board.selectors';
import { ISiteManagementState } from '../../../../store/site-management.reducer';
import { siteManagementEntities } from '../../../../store/site-management.selectors';
import { IBoard } from '../../../../models/board-config.interface';
import * as SiteManagementAction from '../../../../store/site-management.actions';
import { RouterFacade } from '@amp/router-store';
import { Dictionary } from '@ngrx/entity';
import { SiteManagementService } from '../../../../services/site-management.service';

@Component({
  selector: 'amp-board-detail-side-menu',
  templateUrl: './board-detail-side-menu.component.html',
  styleUrls: ['./board-detail-side-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BoardDetailSideMenuComponent implements OnInit {
  boardState$: Observable<AsyncDataState> = this._boardStore.select(BoardSelectors.selectBoardStatus);
  boardList$: Observable<IBoard[]>;
  displayList$: Observable<Dictionary<DisplayDTO>> = this._displayStore.select(DisplaySelectors.selectDisplayEntities);
  site$: Observable<SiteDTO> = this._siteStore.pipe(siteManagementEntities.selectedCurrentSite$);
  selectedSites$: Observable<SiteDTO[]> = this._siteStore.pipe(siteManagementEntities.selectedSites$);

  editable$: Observable<boolean>;

  constructor(
    private _boardStore: Store<BoardState>,
    private _displayStore: Store<DisplayState>,
    private _siteStore: Store<ISiteManagementState>,
    private _routerFacade: RouterFacade,
    private router: Router,
    @Inject(SITE_MANAGEMENT_MODULE_CONFIG) private _siteManagementConfig: ISiteManagementConfig,
    private _siteManagementService: SiteManagementService,
  ) {
    this.editable$ = this._siteManagementService.hasAuthority$('site');
  }

  public ngOnInit(): void {
    this.boardList$ = dataOnceReady(this._boardStore.select(BoardSelectors.selectAllBoards), this._boardStore.select(BoardSelectors.selectBoardStatus)).pipe(
      map((board) => board.sort(this.comparator))
    );
  }

  public goToBoardDetail(): void {
    this.site$.pipe(first()).subscribe((site) => {
      this.router.navigate([...this._siteManagementConfig.moduleBasePath, site.id]);
    });
  }

  public onSiteSelectionChange(selectedSiteId: string): void {
    this._routerFacade.navigate({ path: [...this._siteManagementConfig.moduleBasePath, ...['sites', selectedSiteId, 'board']] });
    this.changeCurrentSiteInfoFromSources(selectedSiteId);
  }

  /** Get all sections for the breadcrumb in the accordion */
  getBoardBreadcrumbSections(board: IBoard): SectionBreadCrumb[] {
    return [...board.tagsStructure.map((e) => ({ value: e.value })), { value: board.name }];
  }

  private changeCurrentSiteInfoFromSources(selectedSiteId: string): void {
    this._siteStore.dispatch(SiteManagementAction.FetchSiteDetail({ siteId: +selectedSiteId }));
  }

  private comparator(a: IBoard, b: IBoard): number {
    const orgPathA = a.organizationPath.split('.');
    const orgPathB = b.organizationPath.split('.');
    if (orgPathA < orgPathB) {
      return -1;
    }
    if (orgPathA > orgPathB) {
      return 1;
    }
    return 0;
  }
}
