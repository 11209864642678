<ng-container *ngIf="model$ | async as model">
  <avn-task-panel-item [icon]="'fa:gears'" [iconText]="'commands.COMMANDS.TASK_PANEL.TITLE_20' | transloco">
    <div class="progress" avnTaskPanelItemFeedbackSlot>
      <avn-progressbar [showValue]="true" [showIcon]="false" [max]="model.totalCommandsCount" [finished]="model.allCommandsFinished">
        <avn-progress-segment [value]="model.completedCommandsCount" avnTheme="info"></avn-progress-segment>
      </avn-progressbar>
      <div class="status-info-wrapper">
        <span *ngIf="model.totalCompletionPercent < 100">{{
          'commands.COMMANDS.TASK_PANEL.COMMANDS_RUNNING_TEXT_50' | transloco: { completed: model.completedCommandsCount, total: model.totalCommandsCount }
        }}</span>
        <span *ngIf="model.totalCompletionPercent === 100">{{
          'commands.COMMANDS.TASK_PANEL.COMMANDS_COMPLETED_TEXT_50' | transloco: { completed: model.completedCommandsCount, total: model.totalCommandsCount }
        }}</span>
        <div class="count-icons">
          <ng-container *ngIf="model.completedCommandsSuccessCount > 0">
            <ng-container *ngTemplateOutlet="iconCount; context: { icon: 'action:check_circle', count: model.completedCommandsSuccessCount, theme: 'success' }"></ng-container>
          </ng-container>
          <ng-container *ngIf="model.completedCommandsFailedCount > 0">
            <ng-container *ngTemplateOutlet="iconCount; context: { icon: 'alert:error', count: model.completedCommandsFailedCount, theme: 'danger' }"></ng-container>
          </ng-container>
          <ng-container *ngIf="model.completedCommandsWarningCount > 0">
            <ng-container *ngTemplateOutlet="iconCount; context: { icon: 'alert:warning', count: model.completedCommandsWarningCount, theme: 'warning' }"></ng-container>
          </ng-container>
        </div>
      </div>
    </div>

    <avn-button avnTaskPanelItemActionSlot [label]="'commands.COMMANDS.TASK_PANEL.BUTTON_VIEW_DETAIL_15' | transloco" size="small" [avnTheme]="'primary'" (actioned)="showCommandSummary()"></avn-button>
  </avn-task-panel-item>
</ng-container>

<ng-template #iconCount let-theme="theme" let-count="count" let-icon="icon">
  <div class="icon-count-wrapper">
    <avn-icon [fontSize]="15" [icon]="icon" [avnTheme]="theme"></avn-icon>
    <span>{{ count }}</span>
  </div>
</ng-template>
