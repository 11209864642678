// prettier-ignore
import {
  Day,
  EncFanCount,
  EncFantime,
  EncFanType,
  EncHumid0,
  EncHumid1,
  EncMode,
  EncPower0,
  EncPower1,
  EncPressure,
  EncPwm0,
  EncPwm1,
  EncTemperatureAim,
  EncTemperatureEx,
  EncTemperatureIn,
  EncTemperatureMax,
  EncTemperatureMin,
  EncType,
  EncVersion,
  Hostname,
  Hour,
  InterfaceMac,
  IpAddress,
  LocationLevel1,
  LocationLevel2,
  LocationLevel3,
  LocationLevel4,
  LocationLevel5,
  LocationZip,
  Minute,
  Month,
  MvAgentVersion,
  MvHealthStatus,
  MvLastAttempt,
  MvLastUpdate,
  MvOmnicastSvc,
  CodLastUpdate,
  CodState,
  PosLastUpdate,
  PosLastModified,
  PosState,
  MvPl,
  MvPlayerCs,
  MvLastContentUpdate,
  MvLastContentFailedUpdate,
  MvLastContentUpTodate,
  MvPlayerCsMsg,
  MvPlayerSt,
  MvPlayerStMsg,
  MvPlayerSvc,
  MvPlayerSvcMsg,
  MvPlayerVersion,
  MvSpls,
  MvSysCpu,
  MvSysDisk,
  MvSysMem,
  NlpOperationalState,
  Second,
  Serial,
  SiteManager,
  TypeBrand,
  TypeModel,
  TypeSeries,
  Week,
  Year
} from './device-filter/device-filter.tokens';

export const MONITORING_NLP_DEVICE_FIELDS = [
  /** START [Device Properties] **/
  Hostname,
  IpAddress,
  NlpOperationalState,
  Serial,
  LocationZip,
  LocationLevel1,
  LocationLevel2,
  LocationLevel3,
  LocationLevel4,
  LocationLevel5,
  TypeBrand,
  TypeModel,
  TypeSeries,
  InterfaceMac,
  /** END [Device Properties] **/
  /** START [Monitoring Properties] **/
  MvPlayerVersion,
  MvAgentVersion,
  MvSpls,
  MvPlayerStMsg,
  MvPlayerCsMsg,
  MvPlayerSvcMsg,
  MvSysCpu,
  MvSysMem,
  MvSysDisk,
  MvOmnicastSvc,
  MvPlayerSvc,
  MvPlayerCs,
  MvLastContentUpdate,
  MvLastContentFailedUpdate,
  MvLastContentUpTodate,
  MvPlayerSt,
  MvPl, // put here because the pattern matches the start of other tokens
  MvHealthStatus,
  MvLastUpdate,
  MvLastAttempt,
  Second,
  Minute,
  Hour,
  Day,
  Week,
  Month,
  Year,
  /** END [Monitoring Properties] **/
  /** START [System Plugins] **/
  CodLastUpdate,
  CodState,
  PosLastUpdate,
  PosLastModified,
  PosState,
  /** END [System Plugins] **/
  /** Enclosure */
  EncFanCount,
  EncFanType,
  EncVersion,
  EncType,
  EncFantime,
  EncHumid0,
  EncHumid1,
  EncMode,
  EncPower0,
  EncPower1,
  EncPressure,
  EncPwm0,
  EncPwm1,
  EncTemperatureMin,
  EncTemperatureMax,
  EncTemperatureAim,
  EncTemperatureEx,
  EncTemperatureIn,
  /** START [Other Properties] **/
  SiteManager,
];
