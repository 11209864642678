import { Action, createReducer, on } from '@ngrx/store';
import * as SiteMonitoringAction from './site-monitoring.actions';
import { HealthStatusCode } from '@amp/devices';
import { ISiteMonitoringListColumnConfig } from '../model/site-monitoring-list-column-config.interface';
import { IDataTableDataSort, AsyncData, LoadingState, IDataSource } from '@activia/ngx-components';
import { ISiteMonitoringListDataRow } from '../model/site-monitoring-list-data-row.interface';
import { SiteProperties } from '../model/site-properties.enum';
import { SiteDTO } from '@activia/cm-api';
import { ISiteMonitoringKeyMetricConfig } from '../model/site-monitoring-key-metric-config.interface';
import { IColumnNameOverride } from '../model/column-name-override.interface';
import { SiteMonitoringProfile } from '../model/site-monitoring-profile.type';
import { CountAggregationData } from '../model/site-monitoring-data.interface';
import { ISiteMonitoringUserPreferences, SiteMonitoringPreference } from '../model/site-monitoring-preference.interface';

export interface ISiteMonitoringState {
  /** The current profile used. Each profile has a different set of preferences. */
  profile: AsyncData<SiteMonitoringProfile>;

  /** Site module config that from app preference, has priority over SiteModuleConfig */
  siteMonitoringPreference: AsyncData<SiteMonitoringPreference>;

  /** The amount of sites accessible to the user */
  sitesCount: AsyncData<number>;

  /** Data for the site list */
  siteList: {
    /** The datasource containing all records displayed in the list */
    dataSource: AsyncData<IDataSource<ISiteMonitoringListDataRow>>;

    /** filters for the list */
    filters: {
      healthStatus: HealthStatusCode;
      searchText: string;
    };

    /** sort for the list */
    sort: IDataTableDataSort;

    /** Selected columns for the list*/
    columns: AsyncData<ISiteMonitoringListColumnConfig[]>;

    /** Site ids matching the search text filter */
    filteredSiteIds: AsyncData<number[]>;
  };

  /** health status count */
  healthStatusCount: AsyncData<CountAggregationData<HealthStatusCode>>;

  /** indicates if the data should auto refresh */
  refreshIntervalMs: number;

  /** Data for the site detail page */
  detailSite: AsyncData<SiteDTO>;

  /** the selected key metrics **/
  keyMetrics: AsyncData<ISiteMonitoringKeyMetricConfig[]>;

  /** column names overrides - global to the module so not nested in site list */
  columnNameOverrides: AsyncData<IColumnNameOverride[]>;

  /** Recently viewed sites - list of ids */
  recentSites: AsyncData<number[]>;

  /** Max amount of sites that can be added to recent (by the system) */
  recentSitesMax: number;

  /** Starred sites - list of ids */
  starredSites: AsyncData<number[]>;

  /** Max amount of sites that can be added to starred sites (by the user) */
  starredSitesMax: number;

  /** User preferences */
  userPreferences: AsyncData<ISiteMonitoringUserPreferences>;
}

export const siteMonitoringInitialState: ISiteMonitoringState = {
  profile: {
    data: null,
    state: LoadingState.INIT,
  },
  siteMonitoringPreference: {
    data: null,
    state: LoadingState.INIT,
  },
  sitesCount: {
    data: null,
    state: LoadingState.INIT,
  },
  siteList: {
    dataSource: {
      data: null,
      state: LoadingState.INIT,
    },
    filters: {
      healthStatus: null,
      searchText: '',
    },
    sort: {
      id: SiteProperties.ExternalId,
      direction: 'asc',
    },
    columns: {
      data: null,
      state: LoadingState.INIT,
    },
    filteredSiteIds: {
      data: [],
      state: LoadingState.INIT,
    },
  },
  healthStatusCount: { data: null, state: LoadingState.INIT },
  refreshIntervalMs: 100000,
  detailSite: {
    data: null,
    state: LoadingState.INIT,
  },
  keyMetrics: {
    data: [],
    state: LoadingState.INIT,
  },
  columnNameOverrides: {
    data: [],
    state: LoadingState.INIT,
  },
  recentSites: {
    data: [],
    state: LoadingState.INIT,
  },
  recentSitesMax: 10,
  starredSites: {
    data: [],
    state: LoadingState.INIT,
  },
  starredSitesMax: 20,
  userPreferences: {
    data: null,
    state: LoadingState.INIT,
  },
};

const reducer = createReducer<ISiteMonitoringState>(
  siteMonitoringInitialState,

  /** Fetch profile **/
  on(SiteMonitoringAction.SiteMonitoringFetchProfile, (state, _) => ({
    ...state,
    profile: {
      ...state.profile,
      state: LoadingState.LOADING,
    },
  })),

  on(SiteMonitoringAction.SiteMonitoringFetchProfileSuccess, (state, action) => ({
    ...state,
    profile: {
      data: action.profile,
      state: LoadingState.LOADED,
    },
  })),

  on(SiteMonitoringAction.SiteMonitoringFetchProfileFail, (state, action) => ({
    ...state,
    profile: {
      data: action.profile,
      state: { errorMsg: action.error },
    },
  })),

  /** Update Profile **/
  on(SiteMonitoringAction.SiteMonitoringUpdateProfile, (state, _) => ({
    ...state,
    profile: {
      ...state.profile,
      state: LoadingState.LOADING,
    },
  })),

  on(SiteMonitoringAction.SiteMonitoringUpdateProfileSuccess, (state, action) => ({
    ...state,
    profile: {
      data: action.profile,
      state: LoadingState.LOADED,
    },
    // reset the site list cache when the profile changes
    siteList: {
      ...state.siteList,
      dataSource: siteMonitoringInitialState.siteList.dataSource,
    },
  })),

  on(SiteMonitoringAction.SiteMonitoringUpdateProfileFail, (state, action) => ({
    ...state,
    profile: {
      ...state.profile,
      state: { errorMsg: action.error },
    },
  })),

  /** Fetch Site Count **/
  on(SiteMonitoringAction.SiteMonitoringFetchUserSiteCount, (state, _) => ({
    ...state,
    sitesCount: {
      ...state.sitesCount,
      state: LoadingState.LOADING,
    },
  })),

  on(SiteMonitoringAction.SiteMonitoringFetchUserSiteCountSuccess, (state, action) => ({
    ...state,
    sitesCount: {
      data: action.siteCount,
      state: LoadingState.LOADED,
    },
  })),

  on(SiteMonitoringAction.SiteMonitoringFetchUserSiteCountFail, (state, action) => ({
    ...state,
    sitesCount: {
      ...state.sitesCount,
      state: { errorMsg: action.error },
    },
  })),

  /** Fetch Health Status Site Count **/
  on(SiteMonitoringAction.SiteMonitoringFetchHealthStatusSiteCount, (state, _) => ({
    ...state,
    healthStatusCount: {
      ...state.healthStatusCount,
      state: LoadingState.LOADING,
    },
  })),

  on(SiteMonitoringAction.SiteMonitoringFetchHealthStatusSiteCountSuccess, (state, action) => ({
    ...state,
    healthStatusCount: {
      data: action.status,
      state: LoadingState.LOADED,
    },
  })),

  on(SiteMonitoringAction.SiteMonitoringFetchHealthStatusSiteCountFail, (state, action) => ({
    ...state,
    healthStatusCount: {
      ...state.healthStatusCount,
      state: { errorMsg: action.error },
    },
  })),

  on(SiteMonitoringAction.SiteMonitoringFetchUserSiteCountFail, (state, action) => ({
    ...state,
    sitesCount: {
      ...state.sitesCount,
      state: { errorMsg: action.error },
    },
  })),

  /** Fetch All Sites **/
  on(SiteMonitoringAction.SiteMonitoringFetchListDataSource, (state, _) => ({
    ...state,
    siteList: {
      ...state.siteList,
      dataSource: {
        ...state.siteList.dataSource,
        state: LoadingState.LOADING,
        lastAttempt: new Date(),
      },
    },
  })),

  on(SiteMonitoringAction.SiteMonitoringRefreshListDataSource, (state, _) => ({
    ...state,
    siteList: {
      ...state.siteList,
      dataSource: {
        ...state.siteList.dataSource,
        lastAttempt: new Date(),
      },
    },
  })),

  on(SiteMonitoringAction.SiteMonitoringFetchListDataSourceSuccess, (state, action) => ({
    ...state,
    siteList: {
      ...state.siteList,
      dataSource: {
        ...state.siteList.dataSource,
        data: action.dataSource,
        state: LoadingState.LOADED,
        lastSuccess: new Date(),
      },
    },
  })),

  on(SiteMonitoringAction.SiteMonitoringFetchListDataSourceFail, (state, action) => ({
    ...state,
    siteList: {
      ...state.siteList,
      dataSource: {
        ...state.siteList.dataSource,
        state: { errorMsg: action.error },
      },
    },
  })),

  on(SiteMonitoringAction.SiteMonitoringListResetDataSource, (state) => ({
    ...state,
    siteList: {
      ...state.siteList,
      dataSource: siteMonitoringInitialState.siteList.dataSource,
    },
  })),

  on(SiteMonitoringAction.SiteMonitoringRefreshListDataSourceSuccess, (state, action) => ({
    ...state,
    siteList: {
      ...state.siteList,
      dataSource: {
        ...state.siteList.dataSource,
        data: action.dataSource,
        state: LoadingState.LOADED,
        lastSuccess: new Date(),
      },
    },
  })),

  on(SiteMonitoringAction.SiteMonitoringListUpdateSearchTextFilter, (state, action) => {
    const isSearchFilterEmpty = action.searchText.length === 0;
    return {
      ...state,
      siteList: {
        ...state.siteList,
        filters: {
          ...state.siteList.filters,
          searchText: action.searchText,
        },
        filteredSiteIds: {
          ...state.siteList.filteredSiteIds,
          data: isSearchFilterEmpty ? [] : state.siteList.filteredSiteIds.data,
          state: isSearchFilterEmpty ? LoadingState.LOADED : LoadingState.LOADING,
        },
      },
    };
  }),

  /** Update Site List filtered sites **/
  on(SiteMonitoringAction.SiteMonitoringListUpdateFilteredSitesSuccess, (state, action) => ({
    ...state,
    siteList: {
      ...state.siteList,
      filteredSiteIds: {
        ...state.siteList.filteredSiteIds,
        data: (action.sites || []).map((site) => site.id),
        state: LoadingState.LOADED,
      },
    },
  })),

  on(SiteMonitoringAction.SiteMonitoringListUpdateFilteredSitesFail, (state, action) => ({
    ...state,
    siteList: {
      ...state.siteList,
      filteredSiteIds: {
        ...state.siteList.filteredSiteIds,
        state: { errorMsg: action.error },
      },
    },
  })),

  on(SiteMonitoringAction.SiteMonitoringListUpdateSort, (state, action) => ({
    ...state,
    siteList: {
      ...state.siteList,
      sort: action.sort,
    },
  })),

  /** Fetch Site List selected columns **/
  on(SiteMonitoringAction.SiteMonitoringListFetchColumns, (state, _) => ({
    ...state,
    siteList: {
      ...state.siteList,
      columns: {
        ...state.siteList.columns,
        state: LoadingState.LOADING,
      },
    },
  })),

  on(SiteMonitoringAction.SiteMonitoringListFetchColumnsSuccess, (state, action) => ({
    ...state,
    siteList: {
      ...state.siteList,
      columns: {
        ...state.siteList.columns,
        data: action.columns,
        state: LoadingState.LOADED,
      },
    },
  })),

  on(SiteMonitoringAction.SiteMonitoringListFetchSelectedColumnsFail, (state, action) => ({
    ...state,
    siteList: {
      ...state.siteList,
      columns: {
        ...state.siteList.columns,
        state: { errorMsg: action.error },
      },
    },
  })),

  /** Update Site List selected columns **/
  on(SiteMonitoringAction.SiteMonitoringListUpdateColumns, (state, _) => ({
    ...state,
    siteList: {
      ...state.siteList,
      columns: {
        ...state.siteList.columns,
        state: LoadingState.LOADING,
      },
    },
  })),

  on(SiteMonitoringAction.SiteMonitoringListUpdateColumnsSuccess, (state, action) => ({
    ...state,
    siteList: {
      ...state.siteList,
      columns: {
        ...state.siteList.columns,
        data: action.columns,
        state: LoadingState.LOADED,
      },
    },
  })),

  on(SiteMonitoringAction.SiteMonitoringListUpdateColumnsFail, (state, action) => ({
    ...state,
    siteList: {
      ...state.siteList,
      columns: {
        ...state.siteList.columns,
        state: { errorMsg: action.error },
      },
    },
  })),

  /** Fetch Detail Site **/
  on(SiteMonitoringAction.SiteMonitoringFetchDetailSite, (state, _) => ({
    ...state,
    detailSite: {
      ...state.detailSite,
      state: LoadingState.LOADING,
      lastAttempt: new Date(),
    },
  })),

  on(SiteMonitoringAction.SiteMonitoringFetchDetailSiteSuccess, (state, action) => ({
    ...state,
    detailSite: {
      ...state.detailSite,
      data: action.site,
      state: LoadingState.LOADED,
      lastSuccess: new Date(),
    },
  })),

  on(SiteMonitoringAction.SiteMonitoringFetchDetailSiteFail, (state, action) => ({
    ...state,
    detailSite: {
      ...state.detailSite,
      state: { errorMsg: action.error },
    },
  })),

  /** Fetch key metrics **/
  on(SiteMonitoringAction.SiteMonitoringFetchKeyMetrics, (state, _) => ({
    ...state,
    keyMetrics: {
      ...state.keyMetrics,
      state: LoadingState.LOADING,
    },
  })),

  on(SiteMonitoringAction.SiteMonitoringFetchKeyMetricsSuccess, (state, action) => ({
    ...state,
    keyMetrics: {
      ...state.keyMetrics,
      data: action.keyMetrics,
      state: LoadingState.LOADED,
    },
  })),

  on(SiteMonitoringAction.SiteMonitoringFetchKeyMetricsFail, (state, action) => ({
    ...state,
    keyMetrics: {
      ...state.keyMetrics,
      state: { errorMsg: action.error },
    },
  })),

  /** Update key metrics **/
  on(SiteMonitoringAction.SiteMonitoringUpdateKeyMetrics, (state, _) => ({
    ...state,
    keyMetrics: {
      ...state.keyMetrics,
      state: LoadingState.LOADING,
    },
  })),

  on(SiteMonitoringAction.SiteMonitoringUpdateKeyMetricsSuccess, (state, action) => ({
    ...state,
    keyMetrics: {
      ...state.keyMetrics,
      data: action.keyMetrics,
      state: LoadingState.LOADED,
    },
  })),

  on(SiteMonitoringAction.SiteMonitoringUpdateKeyMetricsFail, (state, action) => ({
    ...state,
    keyMetrics: {
      ...state.keyMetrics,
      state: { errorMsg: action.error },
    },
  })),

  /** Fetch column name overrides **/
  on(SiteMonitoringAction.SiteMonitoringFetchColumnNameOverrides, (state, _) => ({
    ...state,
    columnNameOverrides: {
      ...state.columnNameOverrides,
      state: LoadingState.LOADING,
    },
  })),

  on(SiteMonitoringAction.SiteMonitoringFetchColumnNameOverridesSuccess, (state, action) => ({
    ...state,
    columnNameOverrides: {
      ...state.columnNameOverrides,
      data: action.columnNameOverrides,
      state: LoadingState.LOADED,
    },
  })),

  on(SiteMonitoringAction.SiteMonitoringFetchColumnNameOverridesFail, (state, action) => ({
    ...state,
    columnNameOverrides: {
      ...state.columnNameOverrides,
      state: { errorMsg: action.error },
    },
  })),

  /** Update column name **/
  on(SiteMonitoringAction.SiteMonitoringUpdateColumnName, (state, _) => ({
    ...state,
    columnNameOverrides: {
      ...state.columnNameOverrides,
      state: LoadingState.LOADING,
    },
  })),

  on(SiteMonitoringAction.SiteMonitoringUpdateColumnNameSuccess, (state, action) => ({
    ...state,
    columnNameOverrides: {
      ...state.columnNameOverrides,
      data: action.columnNameOverrides,
      state: LoadingState.LOADED,
    },
  })),

  on(SiteMonitoringAction.SiteMonitoringUpdateColumnNameFail, (state, action) => ({
    ...state,
    columnNameOverrides: {
      ...state.columnNameOverrides,
      state: { errorMsg: action.error },
    },
  })),

  /** Fetch recent sites **/
  on(SiteMonitoringAction.SiteMonitoringFetchRecentSites, (state, _) => ({
    ...state,
    recentSites: {
      ...state.recentSites,
      state: LoadingState.LOADING,
    },
  })),

  on(SiteMonitoringAction.SiteMonitoringFetchRecentSitesSuccess, (state, action) => ({
    ...state,
    recentSites: {
      data: action.siteIds,
      state: LoadingState.LOADED,
    },
  })),

  on(SiteMonitoringAction.SiteMonitoringFetchRecentSitesFail, (state, action) => ({
    ...state,
    recentSites: {
      ...state.recentSites,
      state: { errorMsg: action.error },
    },
  })),

  /** Add recent site - dont update loading state for updates **/
  on(SiteMonitoringAction.SiteMonitoringAddRecentSiteSuccess, (state, action) => ({
    ...state,
    recentSites: {
      ...state.recentSites,
      data: action.siteIds || state.recentSites.data, // if empty, it means no change to recent sites
    },
  })),

  /** Fetch starred sites **/
  on(SiteMonitoringAction.SiteMonitoringFetchStarredSites, (state, _) => ({
    ...state,
    starredSites: {
      ...state.starredSites,
      state: LoadingState.LOADING,
    },
  })),

  on(SiteMonitoringAction.SiteMonitoringFetchStarredSitesSuccess, (state, action) => ({
    ...state,
    starredSites: {
      data: action.siteIds,
      state: LoadingState.LOADED,
    },
  })),

  on(SiteMonitoringAction.SiteMonitoringFetchStarredSitesFail, (state, action) => ({
    ...state,
    starredSites: {
      ...state.starredSites,
      state: { errorMsg: action.error },
    },
  })),

  /** star site  - dont update loading state for updates **/
  on(SiteMonitoringAction.SiteMonitoringStarSiteSuccess, (state, action) => ({
    ...state,
    ...getUpdatedSiteListStarredSites(state, action.siteId, true),
    starredSites: {
      ...state.starredSites,
      data: action.siteId ? [action.siteId, ...state.starredSites.data] : state.starredSites.data,
    },
  })),

  /** unstar site - dont update loading state for updates **/
  on(SiteMonitoringAction.SiteMonitoringUnstarSiteSuccess, (state, action) => ({
    ...state,
    ...getUpdatedSiteListStarredSites(state, action.siteId, false),
    starredSites: {
      ...state.starredSites,
      data: state.starredSites.data.filter((id) => id !== action.siteId),
    },
  })),

  /** quick site search **/
  on(SiteMonitoringAction.SiteMonitoringUpdateQuickSiteSearchFilter, (state, action) => ({
    ...state,
    quickSiteSearch: {
      filterText: action.searchText,
    },
  })),

  /** Fetch preference **/
  on(SiteMonitoringAction.SiteMonitoringFetchPreference, (state, _) => ({
    ...state,
    siteMonitoringPreference: {
      ...state.siteMonitoringPreference,
      state: LoadingState.LOADING,
    },
  })),

  on(SiteMonitoringAction.SiteMonitoringFetchPreferenceSuccess, (state, action) => ({
    ...state,
    siteMonitoringPreference: {
      data: action.preference,
      state: LoadingState.LOADED,
    },
  })),

  on(SiteMonitoringAction.SiteMonitoringFetchPreferenceFail, (state, action) => ({
    ...state,
    siteMonitoringPreference: {
      ...state.siteMonitoringPreference,
      state: { errorMsg: action.error },
    },
  })),

  /** Update preference **/
  on(SiteMonitoringAction.SiteMonitoringUpdatePreference, (state, _) => ({
    ...state,
    siteMonitoringPreference: {
      ...state.siteMonitoringPreference,
      state: LoadingState.LOADING,
    },
  })),

  on(SiteMonitoringAction.SiteMonitoringUpdatePreferenceSuccess, (state, action) => ({
    ...state,
    siteMonitoringPreference: {
      data: action.preference,
      state: LoadingState.LOADED,
    },
  })),

  on(SiteMonitoringAction.SiteMonitoringUpdatePreferenceFail, (state, action) => ({
    ...state,
    siteMonitoringPreference: {
      ...state.siteMonitoringPreference,
      state: { errorMsg: action.error },
    },
  })),

  /** Fetch User Preferences **/
  on(SiteMonitoringAction.SiteMonitoringFetchUserPreferences, (state, _) => ({
    ...state,
    userPreferences: {
      ...state.userPreferences,
      state: LoadingState.LOADING,
    },
  })),

  on(SiteMonitoringAction.SiteMonitoringFetchUserPreferencesSuccess, (state, action) => ({
    ...state,
    userPreferences: {
      data: action.userPreferences,
      state: LoadingState.LOADED,
    },
  })),

  on(SiteMonitoringAction.SiteMonitoringFetchUserPreferencesFail, (state, action) => ({
    ...state,
    userPreferences: {
      ...state.userPreferences,
      state: { errorMsg: action.error },
    },
  })),

  /** Update User Preferences **/
  on(SiteMonitoringAction.SiteMonitoringUpdateUserPreferences, (state, _) => ({
    ...state,
    userPreferences: {
      ...state.userPreferences,
      state: LoadingState.LOADING,
    },
  })),

  on(SiteMonitoringAction.SiteMonitoringUpdateUserPreferencesSuccess, (state, action) => ({
    ...state,
    userPreferences: {
      data: action.userPreferences,
      state: LoadingState.LOADED,
    },
  })),

  on(SiteMonitoringAction.SiteMonitoringUpdateUserPreferencesFail, (state, action) => ({
    ...state,
    userPreferences: {
      ...state.userPreferences,
      state: { errorMsg: action.error },
    },
  }))
);

export const siteMonitoringReducer = (state: ISiteMonitoringState | undefined, action: Action): ISiteMonitoringState => reducer(state, action);

/** Update the site list with the new state of the starred site **/
export const getUpdatedSiteListStarredSites = (state: ISiteMonitoringState, siteId: number, starred: boolean): typeof siteMonitoringInitialState.siteList | object => {
  // if no site id, that means there is no update to do (the star limit was reached)
  if (siteId == null) {
    return {};
  }
  const siteIndex = state.siteList.dataSource.data?.data.findIndex((row) => row.site.id === siteId);
  if (siteIndex === undefined || siteIndex === -1) {
    return {};
  }
  return {
    siteList: {
      ...state.siteList,
      dataSource: {
        ...state.siteList.dataSource,
        data: {
          ...state.siteList.dataSource.data,
          data: [
            ...state.siteList.dataSource.data.data.slice(0, siteIndex),
            { ...state.siteList.dataSource.data.data[siteIndex], starred },
            ...state.siteList.dataSource.data.data.slice(siteIndex + 1),
          ],
        },
      },
    },
  };
};
