import { InjectionToken } from '@angular/core';

export interface IFeatureToggleOptions {
  /** Relative file path to the features config file.
   * If not provided, will use the default one within the consuming application: ./src/assets/features.json */
  configFilePath?: string;
}

// I am the token that makes the raw options available to the following factory function.
export const FEATURE_TOGGLE_OPTIONS = new InjectionToken<IFeatureToggleOptions>('FEATURE_TOGGLE_OPTIONS');
