import { ThemeType } from '@activia/ngx-components';
import { Component, ChangeDetectionStrategy, Input, Output, EventEmitter, OnInit, SimpleChanges, OnChanges } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { IBoardExperienceTemplate, IExperienceTemplate } from '../../../models/experience-template.interface';

@Component({
  selector: 'amp-experience-template-edit-general',
  templateUrl: './experience-template-edit-general.component.html',
  styleUrls: ['./experience-template-edit-general.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ExperienceTemplateEditGeneralComponent implements OnInit, OnChanges {
  @Input() template: IExperienceTemplate;

  @Output() selectBoard = new EventEmitter<IBoardExperienceTemplate>();

  @Output() addNewBoard = new EventEmitter<void>();

  form: UntypedFormGroup;

  themeType = ThemeType;

  dataSource = [
    { id: 0, label: 'Edit Board', icon: 'action:edit', disabled: false },
    { id: 0, label: 'Duplicate Board', icon: 'action:add', disabled: false },
    { id: 1, label: 'Delete', icon: 'action:delete', disabled: false },
  ];

  selectedBoard: IBoardExperienceTemplate;

  constructor(private fb: UntypedFormBuilder) {}

  ngOnInit(): void {
    this.form = this.fb.group({
      label: [this.template?.label, [Validators.required]],
      orgPath: ['', [Validators.required]],
    });
  }

  ngOnChanges({ template }: SimpleChanges): void {
    this.form?.patchValue({
      label: template.currentValue?.label,
      orgPath: template.currentValue.orgPath,
    });
  }

  addBoard() {
    this.addNewBoard.emit();
  }

  onSelectBoard(board: IBoardExperienceTemplate) {
    this.selectedBoard = this.selectedBoard?.label !== board.label ? board : undefined;
    this.selectBoard.emit(this.selectedBoard);
  }
}
