import { dateTimeToString, IExpressionSuggestionsResult, IOptionData, NlpOverlayComponent, NlpOverlayService, stringToDateTime, trimWrapperChar } from '@activia/ngx-components';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { TranslocoService } from '@ngneat/transloco';
import { tokenMatcher } from 'chevrotain';
import { Subject } from 'rxjs';
import { distinctUntilChanged, filter, map, takeUntil } from 'rxjs/operators';

import { DateField, DateValue, Day, Equals, Hour, Minute, Month, NotEquals, Second, Week, Year } from '../device-filter/device-filter.tokens';
import { NLP_INTERNAL_DATE_FORMAT } from '../device-filter/device-filter.utils';

import { INlpDatePickerValue } from './nlp-date-picker-value.interface';
import { DateTime } from 'luxon';

/** A type defining  the possible view for the component **/
type ViewType = 'list' | 'duration' | 'date' | 'time';

/**
 * This is a custom nlp picker that is to help user select a date range or a duration of time
 * depends on the curent expression's field:
 *
 *  - DateField : pick a date or a duration of time. e.g. LastUpdateTime
 *    the date field expecting a date can be converted from a duration of time ( 5 weeks ago)
 *
 *  - DurationField : only pick a time duration. e.g. EncFantime
 *    the durationField is expecting a time durating (5 weeks), where a particular date doesn't fit
 */
@Component({ selector: 'amp-nlp-date-picker', templateUrl: './nlp-date-picker.component.html', styleUrls: ['./nlp-date-picker.component.scss'], changeDetection: ChangeDetectionStrategy.OnPush })
export class NlpDatePickerComponent extends NlpOverlayComponent<INlpDatePickerValue> implements OnInit, OnDestroy {
  /** The current view being displayed */
  currentView: ViewType;

  /** The form containing duration information - will show in 'duration' view */
  durationForm: UntypedFormGroup;

  /** The list of options for the initial 'list' view */
  listOptions: IOptionData<{ view: ViewType }>[] = [];

  /** The list of options for the time unit dropdown */
  timeUnitOptions: IOptionData<any>[];

  /** The date selected from the calendar */
  selectedDate: string;

  /** The output date format to use for the date selected from the calendar */
  dateFormat = NLP_INTERNAL_DATE_FORMAT;

  predefinedGroup: string;

  customGroup: string;

  customDateTimeLabel: string;

  customDurationLabel: string;

  /** @ignore pattern for destroy all subscriptions **/
  private _componentDestroyed$: Subject<void> = new Subject<void>();

  /** @ignore **/
  constructor(private formBuilder: UntypedFormBuilder, private _nlpOverlayService: NlpOverlayService, private _changeDetector: ChangeDetectorRef, private _translate: TranslocoService) {
    super(_nlpOverlayService);

    // init & localize the time unit options
    this.timeUnitOptions = [Second, Minute, Hour, Day, Week, Month, Year].map((t) => ({ value: t.PATTERN, label: this._nlpOverlayService.getTokenI18nValuesMap().get(t.LABEL) }));

    // init & localize the duration options
    const durationOptions = [Hour, Day, Week, Month, Year].map((t) => `1 ${this._nlpOverlayService.getTokenI18nValuesMap().get(t.LABEL)}`);

    // init the initial list of options
    this.predefinedGroup = this._translate.translate('NLP.CATEGORY.PREDEFINED');
    this.customGroup = this._translate.translate('NLP.CATEGORY.CUSTOM');
    this.customDurationLabel = this._translate.translate('NLP.LABEL.CUSTOM_DURATION');
    this.customDateTimeLabel = this._translate.translate('NLP.LABEL.CUSTOM_DATE_TIME');

    // prettier-ignore
    this.listOptions = [
      ...durationOptions.map(d => ({value: d, label: d, group: this.predefinedGroup})),
      {value: '', label: this.customDurationLabel, group: this.customGroup, data: {view: 'duration'}}
    ];
  }

  /** @ignore **/
  ngOnInit() {
    // init the forms
    this.durationForm = this.formBuilder.group({ durationAmount: new UntypedFormControl('', [Validators.required]), durationUnit: new UntypedFormControl('', [Validators.required]) });

    // create observable of duration form changes when valid and value has changed
    const durationChanges$ = this.durationForm.valueChanges.pipe(
      filter(() => this.durationForm.valid && this.durationForm.dirty),
      map(() => {
        const option = this.timeUnitOptions.find((o) => o.value === this.durationForm.get('durationUnit').value);
        return [this.durationForm.get('durationAmount').value, option.label].filter((v) => !!v).join(' ');
      }),
      distinctUntilChanged()
    );

    // watch any form changes and update the value via the nlp overlay service
    durationChanges$.pipe(takeUntil(this._componentDestroyed$)).subscribe((v) => {
      // when the duration and time have been entered, we can mark the value selection as completed
      this._nlpOverlayService.setValueSelected(`${v}`);
    });

    // Update the view and form value when the value passed to this component is updated
    this.activated$.pipe(takeUntil(this._componentDestroyed$)).subscribe((suggestionResults: IExpressionSuggestionsResult) => {
      const currentFieldExpression = suggestionResults.currentFieldExpression;
      const isDateField = tokenMatcher(currentFieldExpression.field, DateField);
      if (isDateField) {
        this.listOptions.push({ value: '', label: this.customDateTimeLabel, group: this.customGroup, data: { view: 'date' } });
      }
      const isDateOnlyOperator = [Equals, NotEquals].some((t) => tokenMatcher(currentFieldExpression.operator, t));

      // determine the view to display
      if ((isDateOnlyOperator && isDateField) || this.value.date || this.value.time) {
        const isTimeSelection =
          this.value.date && suggestionResults.partialValueInfo && suggestionResults.partialValueInfo.partialValue && suggestionResults.partialValueInfo.partialValue.length >= this.dateFormat.length;
        if (isTimeSelection) {
          this.currentView = 'time';
        } else {
          this.currentView = 'date';
        }
      } else if (this.value.duration) {
        this.currentView = 'duration';
      } else {
        this.currentView = 'list';
      }

      // fill the forms accordingly
      if (this.currentView === 'duration') {
        this.durationForm.patchValue({ durationAmount: this.value.duration.amount, durationUnit: this.value.duration.timeUnit });
      } else if (this.currentView === 'date' || this.currentView === 'time') {
        if (this.value.time?.hour) {
          this.selectedDate = dateTimeToString(DateTime.fromFormat(`${this.value.date} ${this.value.time.hour}:${this.value.time.minute}`, `${this.dateFormat} HH:mm`), 'floating');
        } else if (this.value.date) {
          this.selectedDate = dateTimeToString(DateTime.fromFormat(this.value.date, this.dateFormat), 'floating');
        }
      }
      // update the view
      this._changeDetector.detectChanges();
    });
  }

  /** @ignore Builds a date/time string from the specified params **/
  /** Builds a date/time string from the specified params **/
  private _buildDateTime(dateTimeIsoString: string, setTime = false): string {
    const hasTime = this.value.time?.hour;
    const d = stringToDateTime(dateTimeIsoString, 'floating');
    if (hasTime || setTime) {
      return '"' + d.toFormat(`${this.dateFormat} HH:mm`) + '"';
    }
    return '"' + d.toFormat(`${this.dateFormat}`) + '"';
  }

  /** Called when a value is selected from the list of the 'list' view **/
  onListSelection(option: IOptionData<{ view: ViewType }>) {
    // the user has either selected a predefined value or navigated to a view
    if (!option.data) {
      this._nlpOverlayService.setValueSelected(option.value);
      return;
    }
    this.currentView = option.data.view;
  }

  /** Called when a date is selected from the calendar **/
  onDateSelected(isoDateString: string) {
    // parse the date
    this.selectedDate = isoDateString;
    // reposition the cursor before the ending quote so the time picker will appear
    this._nlpOverlayService.setValueSelected(this._buildDateTime(isoDateString), -1);
  }

  /** Called when a time is selected from the timepicker **/
  onTimeSelected(isoDateString: string) {
    // parse the date
    this.selectedDate = isoDateString;
    // reposition the cursor before the ending quote so the time picker will appear
    this._nlpOverlayService.setValueSelected(this._buildDateTime(this.selectedDate, true), -1, true);
  }

  /** @ignore parses the value from the expression parsing results **/
  protected parseValue(res: IExpressionSuggestionsResult): INlpDatePickerValue {
    if (!res.currentFullFieldExpression.values || res.currentFullFieldExpression.values.length === 0) {
      return {};
    }
    // check the type of our current value
    const tokenType = res.currentFullFieldExpression.values[0].tokenType;
    const isDate = tokenType.name === DateValue.name && tokenType.PATTERN.toString() === DateValue.PATTERN.toString();
    if (isDate) {
      // get the date value (remove wrapping quotes of dates)
      const currentValue = trimWrapperChar(res.currentFullFieldExpression.values[0].image, '"');

      const split = currentValue.split(' ');
      let hour;
      let minute;
      if (split[1] && split[1].length > 0) {
        const timeSplit = split[1].split(':');
        hour = timeSplit[0];
        minute = timeSplit[1];
      }
      return { date: split[0], ...(hour ? { time: { hour, minute } } : {}) };
    }
    return {
      duration: { amount: Number(res.currentFullFieldExpression.values[0].image), timeUnit: res.currentFullFieldExpression.values.length > 1 ? res.currentFullFieldExpression.values[1].image : null },
    };
  }

  /**
   * Called when the option list of any of the select components in our forms is toggled
   * This will prevent the overlay to close
   **/
  onOptionListToggled(opened: boolean) {
    this._nlpOverlayService.setAllowCloseOnOutsideClick(!opened);
  }

  /** @ignore **/
  ngOnDestroy(): void {
    this._componentDestroyed$.next();
    this._componentDestroyed$.complete();
  }
}
