import { AsyncData, LoadingState } from '@activia/ngx-components';
import { Action, createReducer, on } from '@ngrx/store';
import { differenceBy } from 'lodash';
import { IGeoAutoFixSite, IGeoFixerSite } from '../../models/geo-fixer-site.interface';
import * as GeoFixerActions from './geo-fixer.actions';
import * as SiteManagementAction from '../site-management.actions';
import { areGeodeticCoordinatesComplete, isAddressComplete } from '../../utils/geo-location-validator.utils';
import { isTimezoneComplete } from '../../utils/site.utils';
import { SiteSyncUpdateSiteInfo } from '../site-sync/site-sync.actions';

export interface IGeoFixerState {
  /** All sites with geo location issues */
  allGeoProblematicSites: AsyncData<IGeoFixerSite[]>;

  /** Ids of sites currently in the process of being fixed **/
  autoFixSites: IGeoAutoFixSite[];
}

export const initialGeoFixerState: IGeoFixerState = {
  allGeoProblematicSites: {
    data: [],
    state: LoadingState.INIT,
  },
  autoFixSites: [],
};

const reducer = createReducer<IGeoFixerState>(
  initialGeoFixerState,

  //#region Fetch all sites with geo location issues
  on(GeoFixerActions.FetchAllGeoProblematicSites, (state, _) => ({
    ...state,
    allGeoProblematicSites: {
      ...state.allGeoProblematicSites,
      state: LoadingState.LOADING,
      lastAttempt: new Date(),
    },
  })),

  on(GeoFixerActions.FetchAllGeoProblematicSitesSuccess, (state, action) => ({
    ...state,
    allGeoProblematicSites: {
      ...state.allGeoProblematicSites,
      data: [...state.allGeoProblematicSites.data, ...action.sites],
      state: LoadingState.LOADED,
      lastSuccess: new Date(),
    },
  })),

  on(GeoFixerActions.FetchAllGeoProblematicSitesFail, (state, action) => ({
    ...state,
    allGeoProblematicSites: {
      ...state.allGeoProblematicSites,
      state: { errorMsg: action.error },
    },
  })),
  //#endregion

  //#region Auto-fix sites
  on(GeoFixerActions.AutoFixGeoProblematicSites, (state, { sites }) => {
    const sitesNotYetMarkedAsFix = differenceBy(sites, state.autoFixSites, 'id');

    const newSitesToFix = sitesNotYetMarkedAsFix.map((site) => ({ ...site, autoFixStatus: 'PENDING' } as IGeoAutoFixSite));

    return {
      ...state,
      autoFixSites: [...state.autoFixSites, ...newSitesToFix],
    };
  }),

  on(GeoFixerActions.AutoFixGeoProblematicSiteSuccess, (state, action) => {
    const updatedSiteIdx = state.autoFixSites.findIndex((s) => s.id === action.site.id);
    const updatedSite: IGeoAutoFixSite = { ...action.site, autoFixStatus: 'UPDATED' };
    return {
      ...state,
      allGeoProblematicSites: {
        ...state.allGeoProblematicSites,
        data: state.allGeoProblematicSites.data.filter((site) => site.id !== action.site.id),
      },
      autoFixSites: [...state.autoFixSites.slice(0, updatedSiteIdx), updatedSite, ...state.autoFixSites.slice(updatedSiteIdx + 1)],
    };
  }),

  on(GeoFixerActions.AutoFixGeoProblematicSiteFail, (state, { site, error }) => {
    const failSiteIndex = state.allGeoProblematicSites.data.findIndex((s) => s.id === site.id);
    const failSiteIdx = state.autoFixSites.findIndex((s) => s.id === site.id);
    return {
      ...state,
      allGeoProblematicSites: {
        ...state.allGeoProblematicSites,
        data: [
          ...state.allGeoProblematicSites.data.slice(0, failSiteIndex),
          site,
          ...state.allGeoProblematicSites.data.slice(failSiteIndex + 1)
        ],
      },
      autoFixSites: [
        ...state.autoFixSites.slice(0, failSiteIdx),
        { ...site, autoFixStatus: 'FAILED', error },
        ...state.autoFixSites.slice(failSiteIdx + 1)
      ],
    };
  }),

  on(GeoFixerActions.UpdateAutoFixGeoProblematicSiteStatus, (state, { siteId, status }) => {
    const updatedSiteIdx = state.autoFixSites.findIndex((s) => s.id === siteId);
    const updatedSite: IGeoAutoFixSite = { ...state.autoFixSites[updatedSiteIdx], autoFixStatus: status };
    return {
      ...state,
      autoFixSites: [...state.autoFixSites.slice(0, updatedSiteIdx), updatedSite, ...state.autoFixSites.slice(updatedSiteIdx + 1)],
    };
  }),

  on(GeoFixerActions.ClearAutoFixedGeoProblematicSites, (state) => ({
    ...state,
    autoFixSites: [],
  })),
  //#endregion

  /**
   * When user manually updates a site, verify if the missing info is completed.
   * If so, remove from here (coz if user updates location, geo info of the site must be valid at this point)
   */
  on(SiteManagementAction.UpdateSiteSuccess, (state, action) => {
    if (!isAddressComplete(action.site) || !areGeodeticCoordinatesComplete(action.site) || !isTimezoneComplete(action.site)) {
      return;
    }

    return {
      ...state,
      allGeoProblematicSites: {
        ...state.allGeoProblematicSites,
        data: state.allGeoProblematicSites.data.filter((site) => site.id !== action.site.id),
      },
    };
  }),

  on(SiteManagementAction.DeleteSiteSuccess, (state, action) => ({
    ...state,
    allGeoProblematicSites: {
      ...state.allGeoProblematicSites,
      data: state.allGeoProblematicSites.data.filter((site) => site.id !== action.siteId),
    },
    autoFixSites: state.autoFixSites.filter((site) => site.id !== action.siteId),
  })),

  /** When a site is updated from site sync, remove from here coz site info must be valid at this point */
  on(SiteSyncUpdateSiteInfo, (state, { site }) => ({
    ...state,
    allGeoProblematicSites: {
      ...state.allGeoProblematicSites,
      data: state.allGeoProblematicSites.data.filter((s) => s.id !== site.id),
    },
  }))
);

export const geoFixerReducer = (state: IGeoFixerState | undefined, action: Action): IGeoFixerState => reducer(state, action);
