import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges, OnDestroy,
  Output,
  SimpleChanges
} from '@angular/core';
import {
  BehaviorSubject,
  combineLatest,
  distinctUntilChanged,
  filter,
  map,
  Observable,
  ReplaySubject,
  share,
  Subject,
  withLatestFrom
} from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { CountAggregationData } from '../../model/site-monitoring-data.interface';
import { SiteMonitoringFacade } from '../../store/site-monitoring.facade';
import { HealthStatusCode} from '@amp/devices';
import { TranslocoService } from '@ngneat/transloco';
import { ApiRequestState, AsyncDataService, AsyncDataState } from '@activia/ngx-components';
import { convertSiteHealthToPieData, ISiteHealthPieDatum } from '../../utils/site-health-monitor.utils';

/** This component display health monitor chart of a single site in DMB */
@Component({
  selector: 'amp-site-health-monitor',
  templateUrl: './site-health-monitor.component.html',
  styleUrls: ['./site-health-monitor.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SiteHealthMonitorComponent implements OnChanges, OnDestroy {

  @Input() siteId: number;

  /**
   * In DMB, when optimisticView is true, values of all status, except error, are flattened to greyed out status
   * (will put to OK status). When optimisticView is false, only warning and error are visible in the chart,
   * values from all other status besides these 2 are flattened to greyed out status (will put to OK status).
   */
  @Input() optimisticViewEnabled = false;

  /** Dimension of the chart, this value will be used as height and width */
  @Input() heightInPx: number;

  /**
   * Emit the health status and count among the sites. The values are optimized.
   * The emitted value will not have UNREACHABLE and NOT_MONITORED status, the values of these 2 status are
   * flattened to OK state. If optimisticViewEnabled is set to true, the emitted value will not have WARNING as
   * the value for WARNING is also flattened to OK.
   * E.g.
   * { 0: 1, 2: 2 } // 1 Ok, 2 Error
   * { 0: 1, 1: 3, 2: 2 } // 1 Ok, 3 Warning, 2 Error
   */
  @Output() metricsState: EventEmitter<{ data: CountAggregationData<HealthStatusCode, number>; dataState: AsyncDataState }> = new EventEmitter();

  pieDataItems$: Observable<ISiteHealthPieDatum[]>;

  /**
   * The datum which the value would be displayed in the center of the chart by default.
   * Either that value of the error status, or if not available, the value of the warning status.
   */
  displayedPieDatum$: Observable<ISiteHealthPieDatum>;

  apiRequestState = new ApiRequestState();

  private _siteIdSub: BehaviorSubject<number> = new BehaviorSubject<number>(null);
  private _optimisticViewEnabledSub: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(this.optimisticViewEnabled);

  private _componentDestroyed$: Subject<void> = new Subject<void>();

  constructor(
    private _translocoService: TranslocoService,
    private _siteMonitoringFacade: SiteMonitoringFacade,
    private _asyncDataService: AsyncDataService,
  ) {
    const healthStatus$: Observable<CountAggregationData<HealthStatusCode, number>> = this._siteIdSub.pipe(
      filter((siteId) => !!siteId),
      distinctUntilChanged(),
      switchMap((siteId) => this._asyncDataService.doRequestWithState$<CountAggregationData<HealthStatusCode, number>>(
        this._siteMonitoringFacade.fetchHealthStatusByOrgLevel(siteId),
        this.apiRequestState,
        null,
        {}
      )),
    );

    this.pieDataItems$ = combineLatest([healthStatus$, this._optimisticViewEnabledSub]).pipe(
      withLatestFrom(this.apiRequestState.dataState$),
      map(([[healthStatus, optimisticView], dataState]) => {
        healthStatus = healthStatus || {};

        const statusMap = this.optimizeSitesStates(healthStatus, optimisticView);

        // Convert the status and count to the pie data
        const pieDataItems = convertSiteHealthToPieData(statusMap, this._translocoService);

        this.metricsState.emit({ data: statusMap, dataState });

        // Sort in the order of Error - Warning - OK
        return pieDataItems;
      }),
      share({
        connector: () => new ReplaySubject(1),
        resetOnRefCountZero: true,
        resetOnComplete: false,
        resetOnError: false,
      }),
    );

    // Get the datum which the value will be displayed in the center of the chart by default
    // Data are already sorted by level, from error - warning - ok, hence just take the 1st datum
    this.displayedPieDatum$ = this.pieDataItems$.pipe(
      map((pieDataItems) => pieDataItems[0]),
    );
  }

  ngOnChanges({ siteId, optimisticViewEnabled }: SimpleChanges) {
    if (siteId && siteId.currentValue) {
      this._siteIdSub.next(this.siteId);
    }

    if (optimisticViewEnabled) {
      this._optimisticViewEnabledSub.next(this.optimisticViewEnabled);
    }
  }

  ngOnDestroy() {
    this._componentDestroyed$.next();
    this._componentDestroyed$.complete();
  }

  optimizeSitesStates(healthStatus: CountAggregationData<HealthStatusCode, number>, optimisticView: boolean): CountAggregationData<HealthStatusCode, number> {
    return Object.entries(healthStatus).reduce((res, [k, value]) => {
      let key = +k;
      switch (key) {
        case HealthStatusCode.WARNING:
          key = optimisticView ? HealthStatusCode.OK : HealthStatusCode.WARNING;
          break;
        case HealthStatusCode.OK:
        case HealthStatusCode.NOT_MONITORED:
        case HealthStatusCode.UNREACHABLE:
          key = HealthStatusCode.OK;
          break;
        default:
          break;
      }
      if (res[key]) {
        res[key] += value;
      } else {
        res[key] = value;
      }
      return res;
    }, {});
  }
}
