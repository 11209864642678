import { SiteSummaryDTO } from '@activia/cm-api';

/**
 * Compares two arrays of sites data and return a count of how many records have been added, removed or updated
 * **/
export const compareSiteData = (newSitesData: SiteSummaryDTO[], oldSitesData: SiteSummaryDTO[]): { added: number; removed: number; updated: number } => {
  oldSitesData = oldSitesData || [];
  const newSitesIds = newSitesData.map((s) => s.site.id);
  const oldSitesIds = oldSitesData.map((s) => s.site.id);
  const added = newSitesIds.filter((id) => !oldSitesIds.includes(id)).length;
  const removed = oldSitesIds.filter((id) => !newSitesIds.includes(id)).length;
  const updated = oldSitesData
    .filter((site) => newSitesIds.includes(site.site.id))
    .map((oldSiteData) =>
      isSameSiteData(
        newSitesData.find((s) => s.site.id === oldSiteData.site.id),
        oldSiteData
      )
    )
    .filter((hasChanged) => hasChanged).length;
  return { added, removed, updated };
};

export const isSameSiteData = (site1: SiteSummaryDTO, site2: SiteSummaryDTO): boolean => JSON.stringify(site1) !== JSON.stringify(site2);
