<avn-panel>
  <avn-panel-body>
    <avn-tabs [justify]="'fill'" [avnTheme]="'primary'" (tabChange)="onTabChange($event.activeTab.id)">
      <avn-tab [id]="'players'" [active]="(selectedTabId$ | async) === 'players'" [headerTemplate]="tabSearch">
        <amp-site-management-devices></amp-site-management-devices>
      </avn-tab>

      <avn-tab
        [id]="'provision'"
        [active]="(selectedTabId$ | async) === 'provision'"
        [label]="'siteManagementScope.SITE_MANAGEMENT.SITE_DETAIL.SITE_PROPERTIES_EDITOR.UNLINKED_PLAYERS.TITLE_35' | transloco"
      >
        <amp-site-management-provision-list></amp-site-management-provision-list>
      </avn-tab>
    </avn-tabs>

    <ng-template #tabSearch>
      <div class="number-icon">
        <span>{{ 'siteManagementScope.SITE_MANAGEMENT.SITE_DETAIL.SITE_PROPERTIES_EDITOR.PLAYERS.TITLE_35' | transloco }}</span>
        <div *ngIf="!!(deviceCount$ | async)">
          <avn-badge [label]="deviceCount$ | async | count" badgeSize="extraSmall"></avn-badge>
        </div>
      </div>
    </ng-template>
  </avn-panel-body>
</avn-panel>
