<avn-dialog-layout>
  <avn-dialog-header
    [showCloseIcon]="dialogConfig.showCloseIcon"
    [title]="'siteMonitoringSharedScope.SITE_MONITORING.ALARM_EVENTS.ALARMS_10' | transloco"
    (closeClicked)="onClose()"
  ></avn-dialog-header>

  <avn-dialog-content avnScroll>
    <amp-board-alarms
      [siteId]="dialogConfig.data.siteId"
      [boards]="[dialogConfig.data.board]"
      [boardDevicesInfo]="dialogConfig.data.board.combinedDevicesInfo"
      [expand]="true"
      [disableExpansion]="true"
      (viewDetail)="onClose()"
    ></amp-board-alarms>
  </avn-dialog-content>
</avn-dialog-layout>
