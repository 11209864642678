import { AnalyticsModule, IGoogleAnalyticsConfig } from '@activia/ngx-components';
import { CommonModule } from '@angular/common';
import { InjectionToken, ModuleWithProviders, NgModule } from '@angular/core';

import { CmDimensionsProviderService } from './adapters/cm-dimensions-provider.service';
import { RouterListenerService } from './listeners/router-listener.service';
import { RouterModule } from '@angular/router';

// I am the token that makes the raw options available to the following factory function.
export const ANALYTICS_OPTIONS = new InjectionToken<IGoogleAnalyticsConfig>('ANALYTICS_OPTIONS');

@NgModule({
  imports: [CommonModule, AnalyticsModule, RouterModule],
  providers: [RouterListenerService],
})
/**
 * Note:
 * This AmpAnalyticsModule provides CmDimensionsProviderService to AnalyticsModule.
 * The CmDimensionsProviderService currently contains 4 values from 2 injection tokens.
 * If any of these values requires to be initialized in other module, e.g. username$ is takend
 * from AuthFacade, then this AmpAnalyticsModule should be imported after AuthModule in order to
 * have the dimensions set correctly before routerListenerService.init() is triggered.
 */
export class AmpAnalyticsModule {
  constructor(routerListenerService: RouterListenerService) {
    routerListenerService.init();
  }

  static forRoot(cfg?: IGoogleAnalyticsConfig): ModuleWithProviders<AnalyticsModule> {
    return {
      ngModule: AmpAnalyticsModule,
      providers: [
        { provide: ANALYTICS_OPTIONS, useValue: cfg },
        AnalyticsModule.forRoot({
          googleAnalyticsConfig: cfg,
          dimensionsProviderService: CmDimensionsProviderService,
        }).providers,
      ],
    };
  }
}
