import { IJsonSchema } from '@activia/json-schema-forms';
import { createAction, props } from '@ngrx/store';
import { IOrgPathDefRoot } from '../../model/board-org-path-definition.interface';

/** Fetch board org path definition actions */
export const FetchSiteOrgPathDefinition = createAction('[SiteOrgPathDefinition/API] Fetch Site org path definition');
export const FetchSiteOrgPathDefinitionSuccess = createAction(
  '[SiteOrgPathDefinition/API] Fetch Site org path definition Success',
  props<{ siteOrgPathDefinition: IOrgPathDefRoot; tagKeys: Record<string, IJsonSchema> }>()
);
export const FetchSiteOrgPathDefinitionFail = createAction(
  '[SiteOrgPathDefinition/API] Fetch Site org path definition Fail',
  props<{
    error: any;
  }>()
);

/** Fetch board org path definition actions */
export const SaveSiteOrgPathDefinition = createAction(
  '[SiteOrgPathDefinition/API] Save Site org path definition',
  props<{
    siteOrgPathDefinition: IOrgPathDefRoot;
  }>()
);
export const SaveSiteOrgPathDefinitionSuccess = createAction(
  '[SiteOrgPathDefinition/API] Save Site org path definition Success',
  props<{ siteOrgPathDefinition: IOrgPathDefRoot; tagKeys: Record<string, IJsonSchema> }>()
);
export const SaveSiteOrgPathDefinitionFail = createAction(
  '[SiteOrgPathDefinition/API] Save Site org path definition Fail',
  props<{
    error: any;
  }>()
);
