import {
    provideTransloco,
    TranslocoModule,
    provideTranslocoScope,
} from '@ngneat/transloco';
import { NgModule } from '@angular/core';
import { provideTranslocoMessageformat } from '@ngneat/transloco-messageformat';
import { I18nLoaderService } from './i18n-config';

@NgModule({
    exports: [ TranslocoModule ],
    providers: [
        provideTransloco({
            config: {
                // availableLangs: ['en', 'fr', 'es'],
                // defaultLang: 'en',
                fallbackLang: ['en'],
                // Remove this option if your application doesn't support changing language in runtime.
                reRenderOnLangChange: true,
                missingHandler: {
                    allowEmpty: true,
                    useFallbackTranslation: true,
                }
            },
            loader: I18nLoaderService
        }),
        provideTranslocoMessageformat({
            locales: ['en', 'fr', 'es'],
        }),
        provideTranslocoScope('device-fields'),
    ],
})
export class TranslocoRootModule {}
