import { DeviceGroupDTO, SystemAboutInfoDTO } from '@activia/cm-api';
import { createAction, props } from '@ngrx/store';
import { IUserPreferences } from './user-preferences.interface';

export const UserConnectionStatusUpdate = createAction('[Global] User Connection Status Update', props<{ isUserOnline: boolean }>());

export const UserPreferencesFetch = createAction('[Global] User Preferences Fetch');
export const UserPreferencesFetchSuccess = createAction('[Global] User Preferences Fetch Success', props<{ preferences: IUserPreferences }>());
export const UserPreferencesFetchFail = createAction('[Global] User Preferences Fetch Fail', props<{ error: any }>());

export const UserPreferencesSingleKeyFetch = createAction('[Global] User Preferences Single Key Fetch', props<{ key: string; errorMessageKey?: string }>());
export const UserPreferencesSingleKeyFetchSuccess = createAction('[Global] User Preferences Single Key Fetch Success', props<{ key: string; data: any }>());
export const UserPreferencesSingleKeyFetchFail = createAction('[Global] User Preferences Single Key Fetch Fail', props<{ key: string; errorMessage: string }>());

export const UserPreferencesSingleKeyUpdate = createAction('[Global] User Preferences Single Key Update', props<{ key: string; data: any; errorMessageKey?: string; custom?: any }>());
export const UserPreferencesSingleKeyUpdateSuccess = createAction('[Global] User Preferences Single Key Update Success', props<{ key: string; data: any; custom?: any }>());
export const UserPreferencesSingleKeyUpdateFail = createAction('[Global] User Preferences Single Key Update Fail', props<{ key: string; errorMessage: string }>());

export const UserPreferencesUpdate = createAction('[Global] Update User Preferences', props<{ preferences: Array<{ key: string; value: any; field: string }> }>());
export const UserPreferencesUpdateSuccess = createAction('[Global] Update User Preferences Success', props<{ preferences: Record<string, any> }>());
export const UserPreferencesUpdateFail = createAction('[Global] Update User Preferences Fail', props<{ errorMessage: string }>());

export const UserLanguageUpdate = createAction('[Global] User Language Update', props<{ language: string }>());
export const UserLanguageUpdateSuccess = createAction('[Global] User Language Update success', props<{ language: string }>());
export const UserLanguageUpdateFail = createAction('[Global] User Language Update failed', props<{ error: any }>());

export const SystemInformationFetch = createAction('[Global] System Information Fetch');
export const SystemInformationFetchSuccess = createAction('[Global] System Information Fetch Success', props<{ info: SystemAboutInfoDTO }>());
export const SystemInformationFetchFail = createAction('[Global] System Information Fetch Fail', props<{ error: any }>());

export const DeviceGroupsForRoleFetch = createAction('[Global] Device Groups for Role Fetch', props<{ ids: number[] }>());
export const DeviceGroupsForRoleFetchSuccess = createAction('[Global] Device Groups for Role Fetch Success', props<{ deviceGroups: Array<DeviceGroupDTO> }>());
export const DeviceGroupsForRoleFetchFail = createAction('[Global] Device Groups for Role Fetch Fail', props<{ error: any }>());

export const AppPreferencesSingleKeyFetch = createAction('[Global] Application Preferences Single Key Fetch', props<{ key: string; errorMessageKey?: string }>());
export const AppPreferencesSingleKeyFetchSuccess = createAction('[Global] Application Preferences Single Key Fetch Success', props<{ key: string; data: any }>());
export const AppPreferencesSingleKeyFetchFail = createAction('[Global] Application Preferences Single Key Fetch Failed', props<{ key: string; errorMessage: string }>());

export const AppPreferencesSingleKeyUpdate = createAction('[Global] Application Preferences Single Key Update', props<{ key: string; data: any; errorMessageKey?: string; custom?: any }>());
export const AppPreferencesSingleKeyUpdateSuccess = createAction('[Global] Application Preferences Single Key Update Success', props<{ key: string; data: any; custom?: any }>());
export const AppPreferencesSingleKeyUpdateFail = createAction('[Global] Application Preferences Single Key Update Failed', props<{ key: string; errorMessage: string }>());
