import { Pipe, PipeTransform } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';

interface DurationUnits {
  d: number;
  h: number;
  m: number;
  s: number;
}

const convertMS = (ms): DurationUnits => {
  let h;
  let m;
  let s;
  s = Math.floor(ms / 1000);
  m = Math.floor(s / 60);
  s = s % 60;
  h = Math.floor(m / 60);
  m = m % 60;
  const d = Math.floor(h / 24);
  h = h % 24;
  return { d, h, m, s };
};
/**
 * Convert timestamp to correct time display
 * Can either show time display as
 * - time units numbers:
 * Ex: 15000 => '00:00:15'
 *     52658000 => '00:00:15'
 *     12822360000 => '00:00:15'
 *
 * - human friendly text display with a maximum of n time units
 * e.g. 1 hour, 1 hr 5 mins 10 secs
 */
@Pipe({ name: 'duration' })
export class DurationPipe implements PipeTransform {
  constructor(private translate: TranslocoService) {}

  transform(durationMs = 0, options: { displayAsText: boolean; maxTimeUnits?: number } = { displayAsText: false }): string {
    if (durationMs < 1000) {
      return `< 1 ${this.translate.translate('TIME.UNITS.SECOND.MEDIUM')}`;
    }

    const duration = convertMS(durationMs);
    if (options?.displayAsText) {
      return this._getTextDisplay(duration, options.maxTimeUnits || 2);
    }
    return this._getNumberDisplay(duration);
  }

  private _getNumberDisplay(duration: DurationUnits) {
    let durationAsString = '';

    if (duration.d) {
      durationAsString += `${duration.d} ${this.translate.translate(duration.d > 1 ? 'DATE.UNITS.DAY.PLURAL' : 'DATE.UNITS.DAY.SINGULAR')} `;
    }
    if (duration.h < 10) {
      durationAsString += '0';
    }
    durationAsString += duration.h + ':';
    if (duration.m < 10) {
      durationAsString += '0';
    }
    durationAsString += duration.m + ':';
    if (duration.s < 10) {
      durationAsString += '0';
    }
    durationAsString += duration.s;

    return durationAsString;
  }

  private _getTextDisplay(duration: DurationUnits, maxTimeUnits: number) {
    const daysStr = duration.d > 0 ? `${duration.d} ${this.translate.translate(duration.d > 1 ? 'DATE.UNITS.DAY.PLURAL' : 'DATE.UNITS.DAY.SINGULAR')}` : null;
    const hoursStr = duration.h > 0 ? `${duration.h} ${this.translate.translate(duration.h > 1 ? 'TIME.UNITS.HOUR.MEDIUM_PLURAL' : 'TIME.UNITS.HOUR.MEDIUM')}` : null;
    const minsStr = duration.m > 0 ? `${duration.m} ${this.translate.translate(duration.m > 1 ? 'TIME.UNITS.MINUTE.MEDIUM_PLURAL' : 'TIME.UNITS.MINUTE.MEDIUM')}` : null;
    const secsStr = duration.s > 0 ? `${duration.s} ${this.translate.translate(duration.s > 1 ? 'TIME.UNITS.SECOND.MEDIUM_PLURAL' : 'TIME.UNITS.SECOND.MEDIUM')}` : null;

    const units = [daysStr, hoursStr, minsStr, secsStr];
    return units.filter(Boolean).slice(0, maxTimeUnits).join(' ');
  }
}
