export enum SITE_MONITORING_GA_EVENT_CATEGORY {
  PROFILE = 'site-monitoring.profile',
  SITE_SELECTIONS = 'site-monitoring.site-selections',
  SETTINGS = 'site-monitoring.settings',
  SITE_LIST = 'site-monitoring.site-list',
  SITE = 'site-monitoring.site',
  DEVICE = 'site-monitoring.device',
  COLUMNS = 'site-monitoring.columns',
  KEYMETRICS = 'site-monitoring.keymetrics',
  USER_PREFERENCES = 'site-monitoring.user-preferences',
  APP_SETTINGS = 'site-monitoring.app-settings', // Application Settings tab in the site monitoring settings drawer
}

export enum SITE_MONITORING_GA_EVENT_ACTION {
  UPDATE = 'update',
  VIEW = 'view',
  STARRED = 'starred',
  UNSTARRED = 'unstarred',
  SEARCH = 'search',
  COPY_INFO = 'copy info',
}
