export const SITE_MANAGEMENT_GA_EVENT_CATEGORY = {
  USER_PREFERENCE: 'site-management.user-preference',
  DEVICE: 'site-management.device',
};

export enum SITE_MANAGEMENT_GA_EVENT_ACTION {
  FETCH = 'fetch',
  CREATE = 'create',
  UPDATE = 'update',
  DELETE = 'delete',
  VIEW = 'view',
  SEARCH = 'search',
}
