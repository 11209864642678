import { SiteMonitoredValue } from '../model/site-monitored-value.enum';
import { CountAggregationData } from '../model/site-monitoring-data.interface';
import { SiteMonitoringKeyMetric } from '../model/site-monitoring-key-metric.enum';
import {
  ALARM_EVENT_OPTIMISTIC_COMBINATIONS,
  CONTENT_STATUS_OPTIMISTIC_COMBINATIONS,
  DEVICE_PROPERTIES_STATUS_OPTIMISTIC_COMBINATIONS,
  HEALTH_STATUS_OPTIMISTIC_COMBINATIONS
} from '../model/optimistic-view-status-combinations';
import { OptimisticViewStatusCombinations } from '@amp/devices';

/** Returns the combinations of statuses for the optimistic view **/
export const getOptimisticViewCombinations = (field: SiteMonitoredValue | SiteMonitoringKeyMetric): OptimisticViewStatusCombinations => {
  if (field === SiteMonitoredValue.HealthStatus || field === SiteMonitoringKeyMetric.HealthStatusSummary) {
    return HEALTH_STATUS_OPTIMISTIC_COMBINATIONS;
  }
  if (field === SiteMonitoredValue.DevicePropertiesStatus) {
    return DEVICE_PROPERTIES_STATUS_OPTIMISTIC_COMBINATIONS;
  }
  if (field === SiteMonitoredValue.ContentStatus) {
    return CONTENT_STATUS_OPTIMISTIC_COMBINATIONS;
  }
  if (field === SiteMonitoringKeyMetric.EnclosureStatus) {
    return ALARM_EVENT_OPTIMISTIC_COMBINATIONS;
  }
  return null;
};

/**
 * Returns the list of statuses that will be counted as the provided status when optimistic view is enabled
 * examples:
 * combinations for HEALTH_STATUS and status = OK returns [WARNING]
 * combinations for DEVICE_PROPERTIES and status = OK returns [NoticeDYN, NoticeALL, WarningDYN, WarningAll]
 * **/
export const getOptimisticViewAdditionalStatuses = <T>(combinations: OptimisticViewStatusCombinations, status: T): T[] => {
  if (!combinations) {
    return [];
  }
  return Array.from(combinations.entries()).reduce((acc, [statusFrom, statusTo]) => {
    if (status === statusTo) {
      acc.push(statusFrom);
    }
    return acc;
  }, []);
};

/** combines statuses based on provided status combinations, and sum the counts */
export const combineOptimisticData = (data: CountAggregationData, combinations: OptimisticViewStatusCombinations): CountAggregationData => {
  if (!data || !combinations) {
    return data;
  }
  return Object.entries(data).reduce((res, [key, count]) => {
    // ignore keys that are marked as ignored
    const combinaison = combinations.has(key) ? combinations.get(key) : combinations.get(+key);

    // if no combinaison is found, the status does not need to be combined
    if (combinaison === undefined) {
      res[key] = (res[key] || 0) + count;
      return res;
    }
    // if the combinaison is null, the status is ignored
    if (combinaison === null) {
      return res;
    }
    // otherwise combine the statuss
    res[combinaison] = (res[combinaison] || 0) + count;
    return res;
  }, {});
};
