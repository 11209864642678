import { Injectable } from '@angular/core';
import { FeatureToggleService } from '@amp/feature-toggle';
import { LogLevel, LogTopic } from './logger.enum';
import { createDownloadLink } from '@amp/utils/common';

@Injectable({ providedIn: 'root' })
export class LoggerService {
  private displayMapping: { [key: number]: string[] } = {};

  constructor(private featureToggleService: FeatureToggleService) {}

  loggerEnabled(): boolean {
    return this.featureToggleService.isOn('logger');
  }

  initDisplayLogger(key: number) {
    this.displayMapping[key] = [];
  }

  log(level: LogLevel, topic: LogTopic, key: number, value: string) {
    if (!this.loggerEnabled()) {
      return;
    }
    switch (topic) {
      case LogTopic.DISPLAY_MAPPING:
        if (!this.displayMapping[key]) {
          this.displayMapping[key] = [];
        }
        this.displayMapping[key].push(`${level}: ${value}\n`);
        break;
      default:
        break;
    }
  }

  download(topic: LogTopic, key: number, name: string) {
    let file: Blob;
    switch (topic) {
      case LogTopic.DISPLAY_MAPPING:
        file = new Blob([...(this.displayMapping[key] || [])], { type: 'text/plain' });
        break;
      default:
        break;
    }
    createDownloadLink(file, `${name}`);
  }
}
