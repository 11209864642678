<ng-container *transloco="let t; read: 'siteManagementScope.SITE_MANAGEMENT'">
  <div class="filter">
    <avn-checkbox
      [label]="t('BOARD_EDITOR.DISPLAY_DEVICE_SELECTOR.AVAILABLE_CONNECTIONS_ONLY_50')"
      [checked]="isAvailableConnectionsOnly$ | async"
      (cbChange)="showAvailablePlayers($event)"
    ></avn-checkbox>
    <avn-input
      [placeholder]="t('BOARD_EDITOR.DEVICE_EDITOR.SEARCH_BY_HOSTNAME_30')"
      (debounceTime)="(800)"
      (changed)="searchByHostname($event)"
    >
    </avn-input>
  </div>

  <avn-data-table
    *ngIf="loadingState !== LoadingState.INIT"
    [forceLoadingState]="loadingState === LoadingState.LOADING"
    [columns]="columns"
    [dataSource]="dataSource$ | async"
    [rowHeight]="40"
    [pageSize]="20"
    [showHeader]="(dataSource$ | async)?.totalRowCount > 0"
    [dragDropRowsEnabled]="editable$ | async"
    (rowActivated)="onRowChanged($event.activeRow)"
    (rowClicked)="onRowChanged($event)"
  >
    <avn-empty-data-message
      icon="communication:desktop_access_disabled"
      [text]="((allDevices$ | async) || []).length ?
        t('SITE_DETAIL.SITE_PROPERTIES_EDITOR.PLAYERS.NO_MATCH.TEXT_100') :
        t('SITE_DETAIL.SITE_PROPERTIES_EDITOR.PLAYERS.EMPTY.EMPTY_TEXT_100')"
      [subText]="((allDevices$ | async) || []).length ?
        t('SITE_DETAIL.SITE_PROPERTIES_EDITOR.PLAYERS.NO_MATCH.MESSAGE_100') :
        (editable$ | async) ? t('SITE_DETAIL.SITE_PROPERTIES_EDITOR.PLAYERS.EMPTY.EMPTY_MESSAGE_100') : t('SITE_DETAIL.SITE_PROPERTIES_EDITOR.PLAYERS.EMPTY.EMPTY_MESSAGE_READONLY_100')"
    >
    </avn-empty-data-message>
  </avn-data-table>
</ng-container>
