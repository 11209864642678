<amp-search-devices-form
  [showDeviceGroupInput]="false"
  [searchQuery]="searchQuery"
  (searchQueryChanged)="onSearchQueryChanged($event)">
</amp-search-devices-form>

<avn-data-table
  *ngIf="loadingState !== LoadingStates.INIT"
  [forceLoadingState]="loadingState === LoadingStates.LOADING"
  [isStaticDataSource]="false"
  [columns]="columns"
  [dataSource]="dataSource"
  [rowHeight]="40"
  [pageSize]="20"
  [keyboardNavigationEnabled]="true"
  [showHeader]="dataSource?.totalRowCount > 0"
  [selectionMode]="selectionMode"
  (fetchData)="onFetchData($event)"
  (selectionChanged)="onSelectionChanged($event)"
>
  <avn-empty-data-message
    icon="image:grid_off"
    [text]="'deviceFields.DEVICE.SEARCH_DEVICES.EMPTY.EMPTY_TEXT_100' | transloco"
    [subText]="'deviceFields.DEVICE.SEARCH_DEVICES.EMPTY.EMPTY_MESSAGE_100' | transloco">
  </avn-empty-data-message>
</avn-data-table>
