import { CountAggregationData, ISiteMonitoringData } from '../model/site-monitoring-data.interface';
import { ISiteMonitoringKeyMetricConfig } from '../model/site-monitoring-key-metric-config.interface';
import { SiteMonitoredValue } from '../model/site-monitored-value.enum';
import { SiteMonitoringKeyMetric } from '../model/site-monitoring-key-metric.enum';
import { OptimisticViewPipe } from '../pipes/optimistic-view.pipe';
import { ISiteMonitoringKeyMetricServiceSettings } from '../model/key-metrics/site-monitoring-key-metric-service-settings.interface';
import {
  AlarmEventLevel,
  ContentStatus,
  HealthStatusCode,
  ServiceStatusCode
} from '@amp/devices';
import { ISiteMonitoringKeyMetricViewerData } from '../model/site-monitoring-key-metric-viewer-data.interface';
import {
  codCardConfig,
  contentStatusCardConfig,
  healthSummaryKeyMetricConfig,
  posCardConfig
} from '../components/keymetrics/key-metric-viewer-config.constant';
import { BoardDTO, MonitoringAlarmEventDTO } from '@activia/cm-api';
import { getBoardsDeviceIds } from './site-boards.utils';
import { IDeviceAlarm } from '../model/alarm-event.interface';
import {
  getKeyMetricEnclosureAlarmNameCategoryMap,
  IKeyMetricSiteEnclosureStatus,
  KeyMetricEnclosureCategory,
  KeyMetricEnclosureStatusCount
} from '../model/key-metrics/site-monitoring-key-metric-enclosure-status-settings.interface';
import { IDeviceInfo } from '../components/site-monitoring-detail/store/site-monitoring-detail.model';
import {
  filterEnclosureAlarms,
  filterEnclosures,
  getEnclosureAlarms,
  getEnclosureKeyMetricViewerData,
  getHighestAlarmLevel,
  groupAlarmsByDevicesIds
} from './key-metric-enclosure.utils';

/** Get the monitoring values of health summary **/
export const getHealthSummaryStatusData = (
  keyMetric: ISiteMonitoringKeyMetricConfig,
  monitoringData: ISiteMonitoringData,
  optimisticViewEnabled: boolean,
  hideKeyMetricOnOk: boolean
): ISiteMonitoringKeyMetricViewerData => {
  let healthStatusData = monitoringData[SiteMonitoredValue.HealthStatus] || {};
  if (optimisticViewEnabled) {
    healthStatusData = new OptimisticViewPipe().transform(healthStatusData, true, SiteMonitoredValue.HealthStatus);
  }

  const statuses = Object.keys(healthStatusData);
  return hideKeyMetricOnOk && statuses.length === 1 && +statuses[0] === HealthStatusCode.OK ?
    null :
    {
      id: keyMetric.id,
      config: keyMetric,
      data: healthStatusData,
      date: healthSummaryKeyMetricConfig.date?.field ? monitoringData[healthSummaryKeyMetricConfig.date?.field] : null
    } as ISiteMonitoringKeyMetricViewerData;
};

/** Get the monitoring values of services **/
export const getKeyMetricsServicesData = (
  keyMetric: ISiteMonitoringKeyMetricConfig,
  monitoringData: ISiteMonitoringData,
  servicesConfig: ISiteMonitoringKeyMetricServiceSettings,
  hideKeyMetricOnOk: boolean,
): ISiteMonitoringKeyMetricViewerData => {
  // should show status in order: Player, Http, Omnicast
  const services = servicesConfig?.services || [];
  const orderedServices = [
    ...(services.includes(SiteMonitoredValue.ServicePlayer) ? [SiteMonitoredValue.ServicePlayer] : []),
    ...(services.includes(SiteMonitoredValue.HttpService) ? [SiteMonitoredValue.HttpService] : []),
    ...(services.includes(SiteMonitoredValue.OmnicastStatus) ? [SiteMonitoredValue.OmnicastStatus] : []),
  ].reduce((dataSource, service) => {
    // create an entry for each service (even if it has no data)
    const serviceData = monitoringData[service] as CountAggregationData<ServiceStatusCode, number>;
    return {
      ...dataSource,
      [`${service}`]: serviceData || {},
    };
  }, {});

  const visibleServices = Object.keys(orderedServices).filter((serviceStatus) => {
    const statuses = Object.keys(orderedServices[serviceStatus]);
    return hideKeyMetricOnOk && statuses.length === 1 && +statuses[0] === ServiceStatusCode.OK ? false : true;
  });
  return visibleServices.length > 0 ?
    {
      id: keyMetric.id,
      config: keyMetric,
      data: visibleServices.reduce((acc, service) => ({ ...acc, [`${service}`]: orderedServices[service] }), {})
    } as ISiteMonitoringKeyMetricViewerData :
    null;
};

/** Get monitoring value for content status, COD state and POS state */
export const getKeyMetricsStatusData = (
  keyMetric: ISiteMonitoringKeyMetricConfig,
  monitoringData: ISiteMonitoringData,
  field: SiteMonitoredValue,
  optimisticViewEnabled: boolean,
  hideKeyMetricOnOk: boolean,
  dateField: SiteMonitoredValue,
  okStatus: ContentStatus | ServiceStatusCode,
): ISiteMonitoringKeyMetricViewerData => {
  let data: CountAggregationData = monitoringData[field] as CountAggregationData || {};

  if (optimisticViewEnabled) {
    data = new OptimisticViewPipe().transform(data, true, field);
  }

  const statuses = Object.keys(data);
  return hideKeyMetricOnOk && statuses.length === 1 && +statuses[0] === okStatus ?
    null :
    {
      id: keyMetric.id,
      config: keyMetric,
      data,
      date: dateField ? monitoringData[dateField] : null
    } as ISiteMonitoringKeyMetricViewerData;
};

/** Get the key metric data for enclosure status **/
export const getKeyMetricsEnclosureStatusData = (
  keyMetric: ISiteMonitoringKeyMetricConfig,
  optimisticViewEnabled: boolean,
  hideKeyMetricOnOk: boolean,
  alarms: MonitoringAlarmEventDTO[],
  boards: BoardDTO[],
  devices: Partial<IDeviceInfo>[]
): ISiteMonitoringKeyMetricViewerData => {

  if (!(boards || []).length || !(devices || []).length) {
    return getEnclosureKeyMetricViewerData(keyMetric, { enclosureCount: 0 } as IKeyMetricSiteEnclosureStatus);
  }

  // Filter out boards that have no CONF_ENCLOSURE_TYPE and keep the enclosures only
  const enclosures = filterEnclosures(boards, devices);
  if (!enclosures.length) {
    return getEnclosureKeyMetricViewerData(keyMetric, { enclosureCount: 0 } as IKeyMetricSiteEnclosureStatus);
  }

  // Get a mapping that can find the category from alarm event name (e.g. "filterservice" -> "Filter(s) dirty")
  const enclosureAlarmNameCategoryMap = getKeyMetricEnclosureAlarmNameCategoryMap();

  // Filter out only the alarms needed for enclosure status
  const alarmEvents: MonitoringAlarmEventDTO[] = filterEnclosureAlarms(alarms);

  if (!(alarms || []).length || !(alarmEvents || []).length) {
    return hideKeyMetricOnOk ? null : getEnclosureKeyMetricViewerData(keyMetric, { enclosureCount: enclosures.length } as IKeyMetricSiteEnclosureStatus);
  }

  // Get all devices in all enclosures
  const deviceIds = getBoardsDeviceIds(enclosures).sort((a, b) => a - b);

  // Group alarms by the above devices
  const deviceAlarms: Array<Partial<IDeviceAlarm>> = groupAlarmsByDevicesIds(deviceIds, alarmEvents, optimisticViewEnabled);

  /**
   * Group alarms by category per enclosure
   * E.g.
   * Enclosure 1
   *   - DOORS_OPENED: { count: 1, level: Emergency }
   *   - LATCHES_NOT_ENGAGED: { count: 1, level: Error }
   * Enclosure 2
   *   - DOORS_OPENED: { count: 1, level: Critical }
   */
  const enclosuresAlarms: Partial<KeyMetricEnclosureStatusCount>[] = enclosures.map((enclosure) => {
    // Get alarms found in all the devices in this enclosure
    const enclosureAlarms = getEnclosureAlarms(enclosure, deviceAlarms);

    // Get status if a matching alarm event for an enclosure status is found
    // At this point the alarms are enclosure specific alarm, just need to set count in the matching field in the status
    // The status is per enclosure, not per device. Hence, even if an enclosure has 3 devices, and all 3 devices
    // have 'dooropen' error, the count is still 1. For the alarm level, take the highest level
    const enclosureAlarmEvents: Partial<KeyMetricEnclosureStatusCount> = {};
    enclosureAlarms.forEach((enclosureAlarm) => {
      const category = enclosureAlarmNameCategoryMap[enclosureAlarm.name];
      enclosureAlarmEvents[category] = enclosureAlarmEvents[category] ?
        { count: 1, level: getHighestAlarmLevel(enclosureAlarm.level, enclosureAlarmEvents[category].level) } :
        { count: 1, level: enclosureAlarm.level };
    });

    return enclosureAlarmEvents;
  });

  /**
   * Group overall alarms by category for this site
   * E.g. DOORS_OPENED: { count: 2, level: Emergency }
   *      LATCHES_NOT_ENGAGED: { count: 1, level: Error }
   */
  let enclosuresStatuses: Partial<KeyMetricEnclosureStatusCount> = enclosuresAlarms.reduce((res, enclosureCount) => {
    Object.keys(enclosureCount).forEach((category) => {
      res[category] = {
        count: res[category].count + enclosureCount[category].count, // Collect count of each alarm type from all enclosures
        level: getHighestAlarmLevel(enclosureCount[category].level, res[category].level), // Get highest alarm level of each alarm type
      } ;
    });
    return res;
  }, {
    FAN_FAILURES: { count: 0, level: null },
    FILTERS_DIRTY: { count: 0, level: null },
    LATCHES_NOT_ENGAGED: { count: 0, level: null },
    DOORS_OPENED: { count: 0, level: null },
    THERMAL_TRIPS_ENGAGED: { count: 0, level: null },
  } as KeyMetricEnclosureStatusCount);

  const selectedCategories: KeyMetricEnclosureCategory[] = keyMetric.customConfig.enclosureStatus as KeyMetricEnclosureCategory[];

  // Filter out alarm event that is either not selected to show or has 0 count
  enclosuresStatuses = Object.keys(enclosuresStatuses).reduce((res, curr) =>
    selectedCategories.includes(curr as KeyMetricEnclosureCategory) && enclosuresStatuses[curr].count > 0 ?
      { ...res, [curr]: enclosuresStatuses[curr] } :
      { ...res }, {} as KeyMetricEnclosureStatusCount);

  // Apply hideKeyMetricOnOk if it's set to true
  const isVisible = hideKeyMetricOnOk === false ||
    Object.keys(enclosuresStatuses).some((status) => enclosuresStatuses[status].count > 0 && enclosuresStatuses[status].level < AlarmEventLevel.Info);

  // Get the highest alarm level from all enclosures in this site
  const siteAlarmLevel = Object.values(enclosuresStatuses).map((status) => status.level)
    .reduce((res, curr) => getHighestAlarmLevel(res, curr), null);

  return isVisible ?
    getEnclosureKeyMetricViewerData(keyMetric, { count: enclosuresStatuses, level: siteAlarmLevel, enclosureCount: enclosures.length } as IKeyMetricSiteEnclosureStatus) :
    null;
};

/** If a key metric has hideKeyMetricsOnOk on and it is in ok state, it should be hidden */
export const getVisibleKeyMetrics = (
  keyMetrics: ISiteMonitoringKeyMetricConfig[],
  monitoringData: ISiteMonitoringData,
  alarms: MonitoringAlarmEventDTO[],
  boards: BoardDTO[],
  devices: Partial<IDeviceInfo>[]
): ISiteMonitoringKeyMetricViewerData[] => {
  const visibleKeyMetric = keyMetrics.map((keyMetric) => {
    const optimisticViewEnabled = keyMetric?.customConfig?.optimisticViewEnabled || false;
    const hideKeyMetricOnOk = keyMetric?.customConfig?.hideKeyMetricOnOk || false;

    switch (keyMetric.id) {
      case SiteMonitoringKeyMetric.HealthStatusSummary:
        return getHealthSummaryStatusData(keyMetric, monitoringData, optimisticViewEnabled, hideKeyMetricOnOk);

      case SiteMonitoringKeyMetric.ContentStatus:
        return getKeyMetricsStatusData(keyMetric, monitoringData, SiteMonitoredValue.ContentStatus, optimisticViewEnabled, hideKeyMetricOnOk, contentStatusCardConfig.date?.field, ContentStatus.OK);

      case SiteMonitoringKeyMetric.Services:
        return getKeyMetricsServicesData(keyMetric, monitoringData, keyMetric?.customConfig, hideKeyMetricOnOk);

      case SiteMonitoringKeyMetric.CodState:
        return getKeyMetricsStatusData(keyMetric, monitoringData, SiteMonitoredValue.CodState, optimisticViewEnabled, hideKeyMetricOnOk, codCardConfig.date?.field, ServiceStatusCode.OK);

      case SiteMonitoringKeyMetric.PosState:
        return getKeyMetricsStatusData(keyMetric, monitoringData, SiteMonitoredValue.PosState, optimisticViewEnabled, hideKeyMetricOnOk, posCardConfig.date?.field, ServiceStatusCode.OK);

      case SiteMonitoringKeyMetric.EnclosureStatus:
        return getKeyMetricsEnclosureStatusData(keyMetric, optimisticViewEnabled, hideKeyMetricOnOk, alarms, boards, devices);

      default:
        return { id: keyMetric.id, config: keyMetric, data: monitoringData[keyMetric.id] } as ISiteMonitoringKeyMetricViewerData;
    }
  }).filter((keyMetric) => keyMetric);

  return visibleKeyMetric;
};

export const hasVisibleKeyMetrics = (visileKeyMetrics: ISiteMonitoringKeyMetricViewerData[]): boolean =>
  visileKeyMetrics.some((visileKeyMetric) => visileKeyMetric.id === SiteMonitoringKeyMetric.Services ?
    Object.values(visileKeyMetric.data).some((servceData) => !!servceData) :
    !!visileKeyMetric.data);
