import { Pipe, PipeTransform } from '@angular/core';
import { CountAggregationData } from '../model/site-monitoring-data.interface';

/**
 * Calculate the ratio of status1 over status2
 * If status2 is omitted it will calculate the ratio of status1 over all other statuses
 *
 * Ex. value = { 'ok': 3, 'error': 6, 'warning': 10}, status1 = 'ok', status2 = 'error' => it will return 33.33%
 * Ex. value = { 'ok': 5, 'error': 5, 'warning': 10}, status1 = 'ok', status2 = 'error', it will return 50%
 * Ex. value = { 'ok': 5, 'error': 5, 'warning': 10}, status1 = 'ok', status2 = null, it will return 25%
 */
@Pipe({ name: 'statusRatio' })
export class StatusRatioPipe implements PipeTransform {
  transform(value: CountAggregationData, status1: string, status2?: string): number {
    if (!value || Object.values(value).length === 0) {
      return null;
    }
    const hasAtLeastOneCountGreaterThanZero = Object.values(value).some((v) => v > 0);
    if (!hasAtLeastOneCountGreaterThanZero) {
      return null;
    }
    const status1Count = value[status1] || 0;
    const otherStatusesCount = status2
      ? value[status2] || 0
      : Object.entries(value)
          .filter(([key, _]) => key !== status1)
          .reduce((res, [_, count]) => res + (count || 0), 0);

    const total = status1Count + otherStatusesCount;
    return total === 0 ? 0 : (status1Count / total) * 100;
  }
}
