import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

/**
 * This components shows a progress bar for a master command.
 * It can have one to many device commands when run on a device group hence the command counts for failed, ok etc..
 */
@Component({
  selector: 'avn-command-progress-bar',
  templateUrl: 'command-progress-bar.component.html',
  styleUrls: ['command-progress-bar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CommandProgressBarComponent {
  /** Title of the command **/
  @Input() title: string;
  /** Indicates if the command has progress information **/
  @Input() hasProgressInfo: boolean;
  /** Indicates the total completion percentage of the command **/
  @Input() progressPercentCompleted: number;
  /** Indicates if the command is completed **/
  @Input() isCompleted: boolean;
  /** The total amount of sub device commands for the command **/
  @Input() commandCount: number;
  /** The total amount of sub device commands in a ok state **/
  @Input() okCommandCount: number;
  /** The total amount of sub device commands in a failed state **/
  @Input() failedCommandCount: number;
  /** The total amount of sub device commands in a other state (TBD other = skipped) **/
  @Input() otherCommandCount: number;
  /** Info about who trigged the command **/
  @Input() triggerInfo: string;

  get showProgressSegmentNone(): boolean {
    return !!this.commandCount && !this.okCommandCount && !this.failedCommandCount && !this.otherCommandCount;
  }
  get showProgressSegmentOk(): boolean {
    return !!this.okCommandCount;
  }
  get showProgressSegmentFail(): boolean {
    return !!this.failedCommandCount;
  }
  get showProgressSegmentOther(): boolean {
    return !!this.otherCommandCount;
  }
}
