export type CommandActionControls =
  AssetsCommandControls |
  DisplayCommandControls |
  MuteCommandControls |
  PlaybackCommandControls |
  SystemPowerCommandControls;

/**
 * Assets controls available to pass to DeviceCommandService.runDeviceGroupAssetsCommand or
 * DeviceCommandService.runDeviceAssetsCommand
 */
export enum AssetsCommandControls {
  FLUSH = 'flush',
}

/**
 * Display controls available to pass to DeviceCommandService.runDeviceGroupDisplayCommand or
 * DeviceCommandService.runDeviceDisplayCommand
 */
export enum DisplayCommandControls {
  ON = 'on',
  OFF = 'off',
  DEFAULT = 'default',
}

/**
 * Mute controls available to pass to DeviceCommandService.runDeviceGroupMuteCommand or
 * DeviceCommandService.runDeviceMuteCommand
 */
export enum MuteCommandControls {
  ON = 'on',
  OFF = 'off',
  DEFAULT = 'default',
}

/**
 * Playback controls available to pass to DeviceCommandService.runDeviceGroupPlayerCommand or
 * DeviceCommandService.runDevicePlaybackCommand
 */
export enum PlaybackCommandControls {
  START = 'start',
  STOP = 'stop',
  RESTART = 'restart',
}

/**
 * System power controls available to pass to DeviceCommandService.runDeviceGroupPowerCommand
 * and DeviceCommandService.runDevicePowerCommand
 */
export enum SystemPowerCommandControls {
  REBOOT = 'reboot',
  SHUTDOWN = 'shutdown',
}
