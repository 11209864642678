import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

export type destinationType = 'brower_file' | 'field_description';

@Component({
  selector: 'amp-site-sync-dnd-csv',
  templateUrl: './site-sync-dnd-csv.component.html',
  styleUrls: ['./site-sync-dnd-csv.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SiteSyncDndCsvComponent {
  allowedTypes = ['.csv'];

  @Input() hasSiteSelection: boolean;
  @Input() downloadCsvSitesEnabled: boolean;

  @Output() fileSelected = new EventEmitter<File>();
  @Output() downloadCsvSites = new EventEmitter<void>();
  @Output() openFieldDescription = new EventEmitter<void>();

  onSelectedFile(files: File[]) {
    this.fileSelected.emit(files[0]);
  }
}
