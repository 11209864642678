/** Where is this key created on */
export enum EngineTagLevel {
  /** tags associated to sites */
  SITE = 'site',
  /** tags associated to boards */
  BOARD = 'board',
  /** tags associated to screens */
  SCREEN = 'screen',
  NONE = 'none',
}

export const ALL_TAG_LEVEL = 'all';

export const EngineTagLevels = [
  {id: ALL_TAG_LEVEL, icon: 'content:select_all', label: 'engineTagManager.CONTROL.LEVEL.ALL'},
  {
    id: EngineTagLevel.SITE,
    icon: 'social:location_city',
    label: 'engineTagManager.TAG_OVERVIEW_DASHBOARD.TITLE.SITE_TAGS_50'
  },
  {
    id: EngineTagLevel.BOARD,
    icon: 'content:web_stories',
    label: 'engineTagManager.TAG_OVERVIEW_DASHBOARD.TITLE.BOARD_TAGS_50'
  },
  {
    id: EngineTagLevel.SCREEN,
    icon: 'hardware:tv',
    label: 'engineTagManager.TAG_OVERVIEW_DASHBOARD.TITLE.SCREEN_TAGS_50'
  },
];

/** Indicate the level user want to manage tags and how to get them*/
export type EngineTagFilterLevel = EngineTagLevel | typeof ALL_TAG_LEVEL;

/** The entity that tags are created on */
export type PropertyType = EngineTagLevel | 'asset';

/** todo i18n  */
export const ALL_TAG_OWNER = 'View All';
export const INTERNAL_APP = 'None';
