import { IDrawerComponent, ModalRef } from '@activia/ngx-components';
import { ChangeDetectionStrategy, Component, EventEmitter, ViewChild } from '@angular/core';
import { SiteManagementPreferencesComponent } from '../site-management-preferences/site-management-preferences.component';

@Component({
  selector: 'amp-site-management-settings-drawer',
  templateUrl: './site-management-settings-drawer.component.html',
  styleUrls: ['./site-management-settings-drawer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SiteManagementSettingsDrawerComponent implements IDrawerComponent {
  @ViewChild(SiteManagementPreferencesComponent) preferences: SiteManagementPreferencesComponent;
  /*** Settings were saved */
  actioned = new EventEmitter<'settingsChanged'>();

  /** @ignore **/
  constructor(private drawerRef: ModalRef<SiteManagementSettingsDrawerComponent>) {}

  /** Specifies if the drawer can be closed **/
  canClose(): boolean {
    return this.preferences?.preferencesFormGroup.pristine;
  }

  closeDrawer() {
    this.drawerRef.close();
  }
}
