import { IModalComponent, IModalConfig, ModalRef, MODAL_CONFIG, ThemeType } from '@activia/ngx-components';
import { ChangeDetectionStrategy, Component, EventEmitter, Inject, Output } from '@angular/core';
import { ITagChangeSummary } from '../../model/tag-values-operations.interface';
import { PropertyType } from '../../model/operation-scope.interface';

@Component({
  selector: 'amp-invalid-tag-dialog',
  templateUrl: './invalid-tag-dialog.component.html',
  styleUrls: ['./invalid-tag-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InvalidTagDialogComponent implements IModalComponent {
  @Output() actioned = new EventEmitter<void>();

  ThemeType = ThemeType;

  constructor(
    private _dialogRef: ModalRef<InvalidTagDialogComponent>,
    @Inject(MODAL_CONFIG) public dialogConfig: IModalConfig<{ propertyType: PropertyType; impactList: ITagChangeSummary[] }>
  ) { }

  canClose(): boolean {
    return false;
  }

  editTags() {
    this.actioned.emit();
    this._dialogRef.close();
  }

  onClose() {
    this._dialogRef.close();
  }
}
