/**
 * Enum that represents all the properties of a site
 */
export enum SiteProperties {
  Id = 'ID',
  ExternalId = 'EXTERNAL_ID',
  Name = 'NAME',
  Street = 'STREET',
  City = 'CITY',
  State = 'STATE',
  Country = 'COUNTRY',
  ZipPostalCode = 'ZIP_POSTAL',
  DeviceCount = 'DEVICE_COUNT',
}
