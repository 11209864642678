import { ChangeDetectionStrategy, Component, ElementRef, OnDestroy, ViewChild } from '@angular/core';
import { IBadgeSize, IModalComponent, ModalModule, ModalRef, StepperComponent, ThemeType } from '@activia/ngx-components';
import { Subject } from 'rxjs';
import { DeviceDTO } from '@activia/cm-api';
import { CdkOverlayOrigin } from '@angular/cdk/overlay';
import { DeviceProperty, DevicesModule } from '@amp/devices';
import { DisplayType } from '@amp/column-picker';
import { TranslocoModule } from '@ngneat/transloco';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'amp-site-management-device-info-selector-modal',
  templateUrl: './site-management-device-info-selector-modal.component.html',
  styleUrls: ['./site-management-device-info-selector-modal.component.scss'],
  standalone: true,
  imports: [CommonModule, ModalModule, DevicesModule, TranslocoModule],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SiteManagementDeviceInfoSelectorModalComponent implements OnDestroy, IModalComponent {
  @ViewChild(StepperComponent, { static: true }) stepper: StepperComponent;
  @ViewChild('overlayOriginInput', { static: false }) originInput: CdkOverlayOrigin;
  @ViewChild('connectedElement', { static: false }) connectedElement: ElementRef;

  device: DeviceDTO;

  DisplayType = DisplayType;
  DeviceProperty = DeviceProperty;
  badgeSize: IBadgeSize = IBadgeSize.SMALL;
  themeType = ThemeType;

  private componentDestroyed$: Subject<void> = new Subject();

  constructor(private _dialogRef: ModalRef<SiteManagementDeviceInfoSelectorModalComponent>) {}

  canClose(): boolean {
    return true;
  }

  onClose(): void {
    this._dialogRef.close();
  }

  ngOnDestroy(): void {
    this.componentDestroyed$.next();
    this.componentDestroyed$.complete();
  }
}
