<ng-container *ngIf="isDataAvailable; else dataNotAvailableTemplate">
  <!-- Display according to the display type -->
  <ng-container [ngSwitch]="displayType" *ngIf="displayType; else noDisplayTypeTemplate">
    <ng-container *ngSwitchCase="DisplayType.Raw">
      {{ value | aggregation: aggregationType }}
    </ng-container>

    <ng-container *ngSwitchCase="DisplayType.Percent">
      {{ value ? (value | aggregation: aggregationType) + '%' : '' }}
    </ng-container>

    <ng-container *ngSwitchCase="DisplayType.Boolean">
      {{ value ? 'true' : 'false' }}
    </ng-container>

    <ng-container *ngSwitchCase="DisplayType.Numeric">
      {{ (value | aggregation: aggregationType) || '0' }}
    </ng-container>

    <ng-container *ngSwitchCase="DisplayType.TwoDecimals">
      {{ value ? (value | aggregation: aggregationType).toFixed(2) : '0.00' }}
    </ng-container>

    <ng-container *ngSwitchCase="DisplayType.Currency">
      {{ (value ? (value | aggregation: aggregationType) : 0) | currency: 'CAD':'symbol-narrow':'4.2-2' }}
    </ng-container>

    <ng-container *ngSwitchCase="DisplayType.TimeAgo">
      {{ value | aggregation: aggregationType | timeAgo | async }}
    </ng-container>

    <ng-container *ngSwitchCase="DisplayType.DateTime">
      {{ value ? (value | aggregation: aggregationType | date: 'medium') : '' }}
    </ng-container>

    <ng-container *ngSwitchCase="DisplayType.Duration">
      {{ value ? (value | aggregation: aggregationType | duration) : '' }}
    </ng-container>

    <ng-container *ngSwitchCase="DisplayType.TwoLines"> {{ value.line1 }}<br />{{ value.line2 }} </ng-container>

    <ng-container *ngSwitchCase="DisplayType.HealthStatus">
      <ng-container *ngTemplateOutlet="healthStatusIndicator"></ng-container>
    </ng-container>

    <ng-container *ngSwitchCase="DisplayType.HealthErrorIds">
      <ng-container *ngTemplateOutlet="healthErrorsIndicator"></ng-container>
    </ng-container>

    <ng-container *ngSwitchCase="DisplayType.ServiceStatus">
      <ng-container *ngTemplateOutlet="serviceStatusIndicator"></ng-container>
    </ng-container>

    <ng-container *ngSwitchCase="DisplayType.CountPieChart">
      <ng-container *ngTemplateOutlet="countPieChartIndicator; context: { threshold: 50 }"></ng-container>
    </ng-container>

    <ng-container *ngSwitchCase="DisplayType.PercentChart">
      <ng-container *ngTemplateOutlet="percentageIndicator; context: { threshold: 50 }"></ng-container>
    </ng-container>

    <ng-container *ngSwitchCase="DisplayType.PercentPieChart">
      <ng-container *ngTemplateOutlet="percentPieChart"></ng-container>
    </ng-container>

    <ng-container *ngSwitchCase="DisplayType.PlayerVersion">
      <ng-container *ngTemplateOutlet="playerVersionIndicator"></ng-container>
    </ng-container>

    <ng-container *ngSwitchCase="DisplayType.DevicePropertiesStatus">
      <ng-container *ngTemplateOutlet="devicePropertiesStatus"></ng-container>
    </ng-container>

    <ng-container *ngSwitchCase="DisplayType.ContentState">
      <ng-container *ngTemplateOutlet="contentStateIndicator"></ng-container>
    </ng-container>

    <ng-container *ngSwitchCase="DisplayType.ContentStatus">
      <ng-container *ngTemplateOutlet="contentStatusIndicator"></ng-container>
    </ng-container>
  </ng-container>

  <ng-template #noDisplayTypeTemplate>
    <!-- Display according to the column id -->
    <ng-container [ngSwitch]="field">
      <ng-container *ngSwitchCase="SiteMonitoredValue.SystemDisk">
        <ng-container *ngTemplateOutlet="percentageIndicator; context: { threshold: 50 }"></ng-container>
      </ng-container>

      <ng-container *ngSwitchCase="SiteMonitoredValue.CpuPercent">
        <ng-container *ngTemplateOutlet="percentageIndicator; context: { threshold: 50 }"></ng-container>
      </ng-container>

      <ng-container *ngSwitchCase="SiteMonitoredValue.SystemMemory">
        <ng-container *ngTemplateOutlet="percentageIndicator; context: { threshold: 50 }"></ng-container>
      </ng-container>

      <ng-container *ngSwitchCase="SiteMonitoredValue.HealthStatus">
        <ng-container *ngTemplateOutlet="healthStatusIndicator"></ng-container>
      </ng-container>

      <ng-container *ngSwitchCase="DisplayType.CountPieChart">
        <ng-container *ngTemplateOutlet="countPieChartIndicator; context: { threshold: 50 }"></ng-container>
      </ng-container>

      <ng-container *ngSwitchDefault>
        {{ value }}
      </ng-container>
    </ng-container>
  </ng-template>

  <ng-template #percentageIndicator let-threshold="threshold">
    <div class="percentage-indicator" *ngIf="(value | aggregation: aggregationType) >= 0">
      <div class="gauge" [style.width.%]="value | aggregation: aggregationType" [avnThemify]="+(value | aggregation: aggregationType) | columnThresholdTheme: field"></div>
      <span>{{ value | aggregation: aggregationType | number: '1.0-0' }}%</span>
    </div>
  </ng-template>

  <ng-template #percentPieChart>
    <ng-container *ngTemplateOutlet="percentagePieIndicator; context: { value: value | aggregation: aggregationType }"></ng-container>
  </ng-template>

  <ng-template #percentagePieIndicator let-value="value">
    <div class="percentage-pie-chart-indicator" *ngIf="value !== null && value !== 100">
      <avn-percent-donut [style.width.px]="'60'" [style.height.px]="'60'" [color]="value | columnThresholdTheme: field:true" [value]="value" [valueSuffix]="'%'"> </avn-percent-donut>
    </div>
  </ng-template>

  <ng-template #contentStateIndicator>
    <div class="list-container" *ngIf="value !== undefined">
      <ng-container *ngFor="let countItem of value | optimisticView: optimisticViewEnabled:field | removeZeroCountPipe | keyvalue | statusSeverity: field">
        <avn-badge
          badgeSize="small"
          [label]="('deviceFields.DEVICE.ENUM.CONTENT_STATE.' + countItem.key | transloco) + ' - ' + countItem.value"
          [avnTheme]="+countItem.key === 1 ? 'success' : 'danger'"
        ></avn-badge>
      </ng-container>
    </div>
  </ng-template>

  <ng-template #serviceStatusIndicator>
    <div class="list-container" *ngIf="value !== undefined">
      <ng-container *ngFor="let countItem of value | removeZeroCountPipe | keyvalue | statusSeverity: field">
        <ng-container [ngSwitch]="countItem.key">
          <ng-container *ngSwitchCase="ServiceStatusCode.OK">
            <avn-badge badgeSize="small" [label]="'Up - ' + countItem.value" [avnTheme]="'success'"></avn-badge>
          </ng-container>
          <ng-container *ngSwitchCase="ServiceStatusCode.ERROR">
            <avn-badge badgeSize="small" [label]="'Down - ' + countItem.value" [avnTheme]="'danger'"></avn-badge>
          </ng-container>
        </ng-container>
      </ng-container>
    </div>
  </ng-template>

  <ng-template #healthStatusIndicator>
    <div class="list-container">
      <ng-container *ngFor="let countItem of value | optimisticView: optimisticViewEnabled:field | removeZeroCountPipe | keyvalue | statusSeverity: field">
        <ng-container [ngSwitch]="countItem.key">
          <ng-container *ngSwitchCase="HealthStatusCode.OK">
            <avn-badge badgeSize="small" [label]="('deviceFields.DEVICE.ENUM.HEALTH_STATUS.OK' | transloco) + ' - ' + countItem.value" avnTheme="success"></avn-badge>
          </ng-container>
          <ng-container *ngSwitchCase="HealthStatusCode.WARNING">
            <avn-badge badgeSize="small" [label]="('deviceFields.DEVICE.ENUM.HEALTH_STATUS.WARNING' | transloco) + ' - ' + countItem.value" avnTheme="warning"></avn-badge>
          </ng-container>
          <ng-container *ngSwitchCase="HealthStatusCode.ERROR">
            <avn-badge badgeSize="small" [label]="('deviceFields.DEVICE.ENUM.HEALTH_STATUS.ERROR' | transloco) + ' - ' + countItem.value" avnTheme="danger"></avn-badge>
          </ng-container>
          <ng-container *ngSwitchCase="HealthStatusCode.UNREACHABLE">
            <avn-badge badgeSize="small" [label]="('deviceFields.DEVICE.ENUM.HEALTH_STATUS.UNREACHABLE' | transloco) + ' - ' + countItem.value" avnTheme="secondary"></avn-badge>
          </ng-container>
          <ng-container *ngSwitchCase="HealthStatusCode.NOT_MONITORED">
            <avn-badge badgeSize="small" [label]="('deviceFields.DEVICE.ENUM.HEALTH_STATUS.NOT_MONITORED' | transloco) + ' - ' + countItem.value" avnTheme="info"></avn-badge>
          </ng-container>
        </ng-container>
      </ng-container>
    </div>
  </ng-template>

  <ng-template #healthErrorsIndicator>
    <ng-container *ngFor="let error of !!value ? value.split(',') : [value]; let i = index">
      <ng-container [ngSwitch]="error">
        <ng-container *ngSwitchCase="HealthErrorIds.PLAYER_SERVICE_DOWN">
          {{ 'deviceFields.DEVICE.ENUM.HEALTH_ERROR_IDS.PLAYER_SERVICE_DOWN' | transloco }}
        </ng-container>
        <ng-container *ngSwitchCase="HealthErrorIds.HTTP_SERVICE_DOWN">
          {{ 'deviceFields.DEVICE.ENUM.HEALTH_ERROR_IDS.HTTP_SERVICE_DOWN' | transloco }}
        </ng-container>
        <ng-container *ngSwitchCase="HealthErrorIds.OMNICAST_SERVICE_DOWN">
          {{ 'deviceFields.DEVICE.ENUM.HEALTH_ERROR_IDS.OMNICAST_SERVICE_DOWN' | transloco }}
        </ng-container>
        <ng-container *ngSwitchCase="HealthErrorIds.FTP_SERVICE_DOWN">
          {{ 'deviceFields.DEVICE.ENUM.HEALTH_ERROR_IDS.FTP_SERVICE_DOWN' | transloco }}
        </ng-container>
        <ng-container *ngSwitchCase="HealthErrorIds.MISSING_CONTENT">
          {{ 'deviceFields.DEVICE.ENUM.HEALTH_ERROR_IDS.MISSING_CONTENT' | transloco }}
        </ng-container>
        <ng-container *ngSwitchCase="HealthErrorIds.HD_ERROR">
          {{ 'deviceFields.DEVICE.ENUM.HEALTH_ERROR_IDS.HD_ERROR' | transloco }}
        </ng-container>
        <ng-container *ngSwitchCase="HealthErrorIds.FAN_ERROR">
          {{ 'deviceFields.DEVICE.ENUM.HEALTH_ERROR_IDS.FAN_ERROR' | transloco }}
        </ng-container>
        <ng-container *ngSwitchCase="HealthErrorIds.HIGH_PROCESSOR_TEMPERATURE">
          {{ 'deviceFields.DEVICE.ENUM.HEALTH_ERROR_IDS.HIGH_PROCESSOR_TEMPERATURE' | transloco }}
        </ng-container>
        <ng-container *ngSwitchCase="HealthErrorIds.HIGH_CPU">
          {{ 'deviceFields.DEVICE.ENUM.HEALTH_ERROR_IDS.HIGH_CPU' | transloco }}
        </ng-container>
        <ng-container *ngSwitchCase="HealthErrorIds.HIGH_MEMORY">
          {{ 'deviceFields.DEVICE.ENUM.HEALTH_ERROR_IDS.HIGH_MEMORY' | transloco }}
        </ng-container>
      </ng-container>
      <ng-container *ngIf="!!value && value.split(',').length > i + 1">, </ng-container>
    </ng-container>
  </ng-template>

  <ng-template #countPieChartIndicator>
    <div class="count-pie-chart">
      <div class="chart">
        <avn-pie
          [style.width.px]="100"
          [style.height.px]="100"
          [showDataLabels]="false"
          [innerSize]="'70%'"
          [selectionEnabled]="false"
          [data]="value | optimisticView: optimisticViewEnabled:field | datavizPieDataItems: field"
        >
        </avn-pie>
        <span *ngIf="(value | optimisticView: optimisticViewEnabled:field | datavizPieDataItems: field).length > 0"
          >{{ (value | optimisticView: optimisticViewEnabled:field | statusRatio: pieConfig.okStatus | number: '1.0-0') || 0 }}%</span
        >
      </div>
      <avn-badge
        *ngIf="(value | optimisticView: optimisticViewEnabled:field)[pieConfig.errorStatus] as errorCount"
        [badgeSize]="'small'"
        [label]="('deviceFields.DEVICE.ENUM.HEALTH_STATUS.ERROR' | transloco) + ' - ' + errorCount"
        avnTheme="danger"
      >
      </avn-badge>
    </div>
  </ng-template>

  <ng-template #playerVersionIndicator>
    <ng-container *ngIf="value !== undefined">
      <span *ngIf="!!value.description; else textOnly" [avnTooltip]="value.description" [tooltipPlacement]="'bottom'">{{ value.versionCode }}</span>
      <ng-template #textOnly>
        <span>{{ value.versionCode }}</span>
      </ng-template>
    </ng-container>
  </ng-template>

  <ng-template #devicePropertiesStatus>
    <div class="list-container">
      <ng-container *ngFor="let countItem of value | optimisticView: optimisticViewEnabled:field | devicePropertiesStatus | keyvalue | statusSeverity: field">
        <avn-badge
          [badgeSize]="BadgeSize.SMALL"
          [label]="('deviceFields.DEVICE.ENUM.DEVICE_PROPERTIES_STATUS_COMBINED.' + countItem.key | transloco) + ' - ' + countItem.value"
          [avnTheme]="countItem.key"
        ></avn-badge>
      </ng-container>
    </div>
  </ng-template>

  <ng-template #contentStatusIndicator>
    <div class="list-container">
      <ng-container *ngFor="let countItem of value | optimisticView: optimisticViewEnabled:field | removeZeroCountPipe | keyvalue | statusSeverity: field">
        <avn-badge
          [badgeSize]="'small'"
          [label]="('deviceFields.DEVICE.ENUM.CONTENT_STATUS.' + countItem.key | transloco) + ' - ' + countItem.value"
          [avnTheme]="ContentStatusThemes[countItem.key]"
        ></avn-badge>
      </ng-container>
    </div>
  </ng-template>
</ng-container>

<ng-template #dataNotAvailableTemplate>
  <ng-container *ngIf="showEmptyPlaceholder">
    <span class="not-available" [style.opacity]="emptyPlaceholderOpacity">{{ 'siteMonitoringSharedScope.SITE_MONITORING.GLOBAL.DATA_NOT_AVAILABLE_20' | transloco }}&nbsp;</span>
  </ng-container>
</ng-template>
