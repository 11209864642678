/**
 * Indicates which screenshot should be displayed depending on the screenshot information
 * See https://confluence.stratacache.com/display/DMP/Screenshot+interface
 */
export const getDisplayScreenshotInUse = (
  primaryInputInfo: { on?: boolean; show?: boolean; failover?: 'primary' | 'backup' },
  backupInputInfo: { on?: boolean; show?: boolean; failover?: 'primary' | 'backup' }
): 'primary' | 'backup' | 'any-available' | null => {
  if (!primaryInputInfo && !backupInputInfo) {
    return null;
  }

  // standalone
  if (!backupInputInfo) {
    return 'primary';
  }
  if (!primaryInputInfo) {
    return 'backup';
  }

  // with primary and backup
  if (matchCriteria(primaryInputInfo, true, true, 'primary')) {
    return 'primary';
  }
  if (matchCriteria(backupInputInfo, true, true, 'backup')) {
    return 'backup';
  }
  if (matchCriteria(backupInputInfo, true, false, 'primary')) {
    return 'primary';
  }
  if (matchCriteria(primaryInputInfo, true, false, 'backup')) {
    return 'backup';
  }
  if (matchCriteria(primaryInputInfo, true, undefined, 'primary')) {
    return 'any-available';
  }
  if (matchCriteria(backupInputInfo, true, undefined, 'backup')) {
    return 'any-available';
  }
  if (matchCriteria(primaryInputInfo, false, undefined, 'primary')) {
    return 'primary';
  }
  if (matchCriteria(backupInputInfo, false, undefined, 'backup')) {
    return 'backup';
  }
  if (matchCriteria(primaryInputInfo, undefined, undefined, 'primary')) {
    return 'primary';
  }
  if (matchCriteria(backupInputInfo, undefined, undefined, 'primary')) {
    return 'backup';
  }
  // If information provided by primary and backup devices are not consistent, the primary information should be used.
  return 'primary';
};

const matchCriteria = (inputInfo: { on?: boolean; show?: boolean; failover?: 'primary' | 'backup' }, on: boolean, show: boolean, failover: 'primary' | 'backup') => {
  const equals = (a, b) => (a ?? null) === (b ?? null);
  return equals(inputInfo.on, on) && equals(inputInfo.show, show) && equals(inputInfo.failover, failover);
};
