/**
 * Returns an enum value as a i18n key
 * we format the value so it matches whats in the i18n files:
 * - replace spaces with _
 * - each capital letter must be suffixed by _
 * - then we uppercase the result
 *
 * @param enumValue
 */

export const getI18nFormattedValue = (enumValue: any): string => {
  if (!enumValue) {
    return enumValue;
  }
  const strValue = `${enumValue}`.trim();
  // if already uppercase then just replace spaces with _
  if (strValue === strValue.toUpperCase()) {
    return strValue.replace(/\s/g, '_');
  }
  let res = '';
  for (let i = 0; i < strValue.length; i++) {
    const chr = strValue.charAt(i);
    const previousChar = res.charAt(i - 1);
    if (chr === ' ') {
      res = `${res}_`;
    } else {
      const isUppercase = chr === chr.toUpperCase();
      const prefix = isUppercase && previousChar && previousChar !== '_' ? '_' : '';
      res = `${res}${prefix}${chr}`;
    }
  }
  return res.toUpperCase();
};
