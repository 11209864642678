import { ThemeType } from '@activia/ngx-components';

/**
 * See https://confluence.stratacache.com/display/CM/AMP+Monitoring+Values for more info
 */
export enum ContentStatus {
  OK = 0,
  WARNING = 1,
  ERROR = 2,
}

export const ContentStatusThemes = {
  [ContentStatus.OK]: ThemeType.SUCCESS,
  [ContentStatus.WARNING]: ThemeType.WARNING,
  [ContentStatus.ERROR]: ThemeType.DANGER,
};

/** The statuses ordered by severity (high to low) **/
export const ContentStatusSeverity = [ContentStatus.ERROR, ContentStatus.WARNING, ContentStatus.OK];
