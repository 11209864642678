import { DeviceDTO, DeviceService } from '@activia/cm-api';
import { extractTotalRecordsFromHttpHeaders } from '@amp/utils/common';
import { map } from 'rxjs/operators';
import { addLogicalPlayerMonitoredValues, aggregateRelatedMonitoringValue, getMonitoringValue, parsePlayerVersion } from '../utils/monitored-values-util';
import { DeviceColumnType } from '../model/device-column-type';
import { IMonitoringListDatum, IMonitoringListDTO } from '../model/monitoring-list.interface';
import { MonitoredValue } from '../model/monitored-value.enum';
import { DeviceFilterApiExpressionService } from '../nlp/device-filter/device-filter-api-expression.service';
import { MONITORING_NLP_DEVICE_FIELDS } from '../nlp/monitoring-nlp-fields';
import { Observable } from 'rxjs';

export const getMonitoringListMonitoredValues = (list: IMonitoringListDTO): string[] => {
  // fetch the monitored values required for the columns
  let monitoredValues = list.columns.filter((col) => col.type === DeviceColumnType.MonitoredValue).map((col) => col.value);
  // add logical player values
  monitoredValues = addLogicalPlayerMonitoredValues(monitoredValues);
  return monitoredValues;
};

export const fetchMonitoringListPaginatedDevices = (
  deviceService: DeviceService, deviceFilterApiExpressionService: DeviceFilterApiExpressionService,
  list: IMonitoringListDTO, tagsColumns: string[], amount: number, offset: number, sort: string, sortAsc: boolean): Observable<{ total: number; devices: DeviceDTO[]; queryResultId?: number }> => {

  const monitoredValues = getMonitoringListMonitoredValues(list);
  const _filter = deviceFilterApiExpressionService.getDeviceFilter(list.filter, list.deviceTypes, MONITORING_NLP_DEVICE_FIELDS, true);

  return deviceService.getDevices(amount, offset, sort, sortAsc, _filter, list.deviceGroupId, monitoredValues, tagsColumns, null, 'response').pipe(
    map((response) => ({ total: extractTotalRecordsFromHttpHeaders<DeviceDTO>(response), devices: response.body || [] })),
  );
};

/**
 * Converts a deviceListDTO to a datatable row for the monitoring list manager
 *
 * @param deviceListDTO
 */
export const toMonitoringListData = (devices: DeviceDTO[], tagColumns: string[]): IMonitoringListDatum[] => {
  let dataRows: IMonitoringListDatum[] = [];
  if (devices?.length > 0) {
    dataRows = devices.map((device) => {
      let dataRow: IMonitoringListDatum;
      // get the monitoring data
      let monitoringData = {};
      if (device.deviceInfo.monitoringData.sample) {
        for (const monitoredValue of device.deviceInfo.monitoringData.sample.monitoredValues) {
          if (monitoredValue.name === MonitoredValue.PlayerVersion) {
            monitoringData[monitoredValue.name] = parsePlayerVersion(getMonitoringValue(monitoredValue));
          } else {
            monitoringData[monitoredValue.name] = getMonitoringValue(monitoredValue);
          }
        }
        monitoringData = aggregateRelatedMonitoringValue(monitoringData);
      }
      // tags
      if (tagColumns) {
        const tagsData = {};
        tagColumns.forEach((col) => {
          const tag = device.deviceInfo.keyValuePairs && device.deviceInfo.keyValuePairs.find((kv) => kv.key === col);
          tagsData[col] = tag ? tag.value : null;
        });
        dataRow = {
          device,
          tagsData,
          monitoringData,
        };
      } else {
        dataRow = {
          device,
          monitoringData,
        };
      }
      return dataRow;
    });
  }
  return dataRows;
};

/** Adds extra device data to our data rows */
export const addExtraDeviceData = (row: IMonitoringListDatum) => {
  // add last attempt
  row.monitoringData[MonitoredValue.LastAttempt] = row.device.deviceInfo.monitoringData.lastAttempt;
  // add last update
  row.monitoringData[MonitoredValue.LastUpdate] = row.device.deviceInfo.monitoringData.lastUpdate;
  // add device response time
  row.monitoringData[MonitoredValue.CcpRespTime] = row.device.deviceInfo.monitoringData.responseTime;
  // add hostnameIp value
  row.device.deviceInfo['hostnameIp'] = { line1: row.device.deviceInfo.hostname, line2: row.device.deviceInfo.ipAddress };
};
