import { Pipe, PipeTransform } from '@angular/core';
import { SiteDTO } from '@activia/cm-api';

/**
 * This pipe returns the site title based on presence of site name / external Id
 * e.g.:
 * - if no external id + site name = Display site name  => My site
 * - if external id + site name = Display both info => My site (#1234)
 * - if external id + no site name = Display City, State (# externalId) => Montreal, QC(#1234)
 *
 */

@Pipe({ name: 'siteTitle' })
export class SiteTitlePipe implements PipeTransform {
  transform(site: SiteDTO): string {
    if (!site) {
      return '';
    }
    const externalId = site.externalId ? `(#${site.externalId})` : '';
    if (site.name) {
      return `${site.name} ${externalId}`;
    } else {
      return `${site.address.city}, ${site.address.state} ${externalId}`;
    }
  }
}
