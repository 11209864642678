import { v4 as uuid } from 'uuid';
import { CsvSiteColumnName, ICsvSite, SITE_MANAGEMENT_CSV_COLUMNS_DEFINITIONS } from '../../../models/site-management-csv-columns';

/**
 * Headers in csv files are case insensitive. However we convert them to camel case to match the SiteDTO property format.
 * Transform a csv file header to match the case required.
 */
export const transformSiteSyncCsvFileHeader = (header: string): string => {
  const availableHeaders = Object.keys(SITE_MANAGEMENT_CSV_COLUMNS_DEFINITIONS);
  const headerIndex = findCsvColumnHeaderIndex(header, availableHeaders);
  if (headerIndex === -1) {
    return header;
  }
  return availableHeaders[headerIndex];
};

export const transformSiteSyncCsvFileSiteRow = (site: Record<CsvSiteColumnName, string>): ICsvSite => {
  const csvSite: ICsvSite = {
    uid: uuid(),
    ...site,
    id: (site.id ?? '').trim().length === 0 ? null : Number(site.id),
    latitude: (site.latitude ?? '').trim().length === 0 ? null : Number(site.latitude),
    longitude: (site.longitude ?? '').trim().length === 0 ? null : Number(site.longitude),
  };
  return csvSite;
};

export const findCsvColumnHeaderIndex = (header: string, availableHeaders: string[]): number => {
  const headerIndex = availableHeaders.findIndex((availableHeader) => availableHeader.toLowerCase() === header.trim().toLowerCase());
  return headerIndex;
};
