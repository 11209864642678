import { getNodeById, IModalComponent, IModalConfig, ITreeNodeItem, MODAL_CONFIG, ModalRef, ApiRequestState, AsyncDataService } from '@activia/ngx-components';
import { AfterViewInit, ChangeDetectionStrategy, Component, EventEmitter, Inject, OnDestroy, Output, ViewChild } from '@angular/core';
import { SiteMonitoringFacade } from '../../../store/site-monitoring.facade';
import { combineLatest, forkJoin, Observable, ReplaySubject, share, Subject } from 'rxjs';
import { ISiteMonitoringListColumnConfig } from '../../../model/site-monitoring-list-column-config.interface';
import { IColumnPickerItem } from '@amp/column-picker';
import { map, startWith, take, takeUntil, takeWhile, tap } from 'rxjs/operators';
import { SITE_MONITORING_COLUMNS_CATEGORIES, SITE_MONITORING_COLUMNS_MANDATORY } from '../../../model/site-monitoring-columns-categories';
import { TranslocoService } from '@ngneat/transloco';
import { SiteMonitoringColumnsEditorComponent } from '../site-monitoring-columns-editor/site-monitoring-columns-editor.component';
import { SiteMonitoringColumnType } from '../../../model/site-monitoring-column-type';
import { ISiteMonitoringColumnPickerExtraConfig } from '../../../model/site-monitoring-column-picker-extra-config.interface';
import { IColumnNameOverride } from '../../../model/column-name-override.interface';
import { ISiteMonitoringModalPickerData } from '../../modal-picker-data.interface';
import { getApplicationPreferencesByKey, updateApplicationPreferencesByKey } from '@amp/utils/common';
import { ApplicationPreferencesService } from '@activia/cm-api';
import { ISiteMonitoringModalPickerComponent } from '../../modal-picker-component.interface';
import { ISiteMonitoringKeyMetricConfig } from '../../../model/site-monitoring-key-metric-config.interface';
import { SITE_MONITORING_COLUMN_NAME_OVERRIDES_PREFERENCE_KEY } from '../../../model/preference-key.constants';

/**
 * Container component responsible to fetch all the data required for the site list monitoring columns selection
 */
@Component({
  selector: 'amp-site-monitoring-columns-editor-modal',
  templateUrl: './site-monitoring-columns-editor-modal.component.html',
  styleUrls: ['./site-monitoring-columns-editor-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SiteMonitoringColumnsEditorModalComponent implements AfterViewInit, OnDestroy, IModalComponent, ISiteMonitoringModalPickerComponent<ISiteMonitoringListColumnConfig> {
  @ViewChild(SiteMonitoringColumnsEditorComponent, { static: false }) siteMonitoringColumnsEditorComponent: SiteMonitoringColumnsEditorComponent;

  /** Emits when the columns are saved **/
  @Output() saved = new EventEmitter<ISiteMonitoringListColumnConfig[]>();

  /** treeview data **/
  data$: Observable<{
    allTreeviewColumns: ITreeNodeItem<IColumnPickerItem<ISiteMonitoringColumnPickerExtraConfig>>[];
    selectedItems: IColumnPickerItem<ISiteMonitoringColumnPickerExtraConfig>[];
  }>;

  /** Indicates if the column picker data has been changed by the user. Used to toggle closeable state of the modal **/
  hasDataChanged = false;

  /** Indicates if the column picker data is being edited by the user. Used to toggle closeable state of the modal **/
  isDataEdited = false;

  /** Indicates if the current profile is the default application profile used by the app **/
  isDefaultAppProfile: boolean;

  /** Indicates if the data for the picker is loading **/
  loading$: Observable<boolean>;

  /** Indicates if the data for the picker could not be loaded **/
  hasError$: Observable<boolean>;

  /** @ignore contains the currenlty selected columns **/
  private _currentlySelectedColumns: ISiteMonitoringListColumnConfig[] = [];

  /** @ignore **/
  private _componentDestroyed$: Subject<void> = new Subject<void>();

  /** @ignore **/
  constructor(
    private _dialogRef: ModalRef<SiteMonitoringColumnsEditorModalComponent>,
    @Inject(MODAL_CONFIG) public dialogConfig: IModalConfig<ISiteMonitoringModalPickerData>,
    private _siteMonitoringFacade: SiteMonitoringFacade,
    private _translateService: TranslocoService,
    private _asyncDataService: AsyncDataService,
    private _applicationPreferencesService: ApplicationPreferencesService
  ) {
    const columnsRequestState = new ApiRequestState();
    const columnOverridesRequestState = new ApiRequestState();

    const selectedColumns$ = this._asyncDataService
      .doRequestWithState$<ISiteMonitoringListColumnConfig[]>(
        getApplicationPreferencesByKey<ISiteMonitoringListColumnConfig[]>(this._applicationPreferencesService, `${dialogConfig.data.profile}.siteMonitoring.siteListColumns`, []),
        columnsRequestState,
        { message: 'siteMonitoringSharedScope.SITE_MONITORING.COLUMNS_PICKER.ERROR.FETCH_FAIL_150' },
        []
      )
      .pipe(tap((selectedColumns) => (this._currentlySelectedColumns = selectedColumns)));

    const columnNameOverrides$ = this._asyncDataService.doRequestWithState$<IColumnNameOverride[]>(
      getApplicationPreferencesByKey<IColumnNameOverride[]>(this._applicationPreferencesService, `${dialogConfig.data.profile}.${SITE_MONITORING_COLUMN_NAME_OVERRIDES_PREFERENCE_KEY}`, []),
      columnOverridesRequestState,
      { message: 'siteMonitoringSharedScope.SITE_MONITORING.COLUMNS_PICKER.ERROR.NAME_OVERRIDES_FETCH_FAIL_150' },
      []
    );

    this.loading$ = combineLatest([columnsRequestState.loading$, columnOverridesRequestState.loading$]).pipe(map((loadingStates) => loadingStates.some((loading) => !!loading)));
    this.hasError$ = combineLatest([columnsRequestState.error$, columnOverridesRequestState.error$]).pipe(map((errorStates) => errorStates.some((error) => !!error)));

    // build the treeview columns (use shareReplay to only build it once and then share the value amongs all subscribes)
    this.data$ = forkJoin([selectedColumns$, columnNameOverrides$])
      .pipe(
        map(([selectedColumns, columnNameOverrides]) => {
          const treeviewColumns = this.getTreeviewColumns(selectedColumns, columnNameOverrides);
          return {
            allTreeviewColumns: treeviewColumns,
            selectedItems: this._getSelectedColumns(selectedColumns, treeviewColumns),
          };
        })
      )
      .pipe(
        share({
          connector: () => new ReplaySubject(1),
          resetOnRefCountZero: false,
          resetOnComplete: false,
          resetOnError: false,
        }),
      );

    this.isDefaultAppProfile = this._siteMonitoringFacade.isDefaultAppProfile(dialogConfig.data.profile);
  }

  ngAfterViewInit(): void {
    this.siteMonitoringColumnsEditorComponent.treeColumnPicker.valueChanged.pipe(take(1), takeUntil(this._componentDestroyed$)).subscribe(() => (this.hasDataChanged = true));
    combineLatest([
      this.siteMonitoringColumnsEditorComponent.columnSettingsChanged.pipe(startWith(false)),
      this.siteMonitoringColumnsEditorComponent.treeColumnPicker.dataEdited.pipe(startWith(false)),
    ])
      .pipe(
        takeWhile(() => !this.hasDataChanged),
        takeUntil(this._componentDestroyed$)
      )
      .subscribe(([columnSettingsChanged, columnNameChanged]) => (this.isDataEdited = columnSettingsChanged || columnNameChanged));
  }

  canClose(): boolean {
    return !this.hasDataChanged && !this.isDataEdited;
  }

  onClose() {
    this._dialogRef.close();
  }

  /** Called when the cancel button is clicked **/
  onCancel() {
    this._dialogRef.close();
  }

  /** Called when the save button is clicked **/
  onSave() {
    // map our selected columns back to the monitoring format
    this._siteMonitoringFacade.profile$.pipe(take(1)).subscribe((activeProfile) => {
      const columns: ISiteMonitoringListColumnConfig[] = this.siteMonitoringColumnsEditorComponent.treeColumnPicker.getSelectedColumns().map((selectedCol) => {
        // do we have it in our current cols? we need to keep extra info like custom width etc..
        const existingCol = this._currentlySelectedColumns.find((col) => col.id === selectedCol.columnDefinition.id);
        if (existingCol) {
          return {
            ...existingCol,
            displayType: selectedCol.selectedDisplayType,
            aggregationType: selectedCol.selectedAggregationType,
            ...(selectedCol.extra ? { optimisticViewEnabled: !!selectedCol.extra.optimisticViewEnabled } : {}),
          };
        }
        return {
          type: selectedCol.columnDefinition.type as SiteMonitoringColumnType,
          id: selectedCol.columnDefinition.id,
          displayType: selectedCol.selectedDisplayType,
          aggregationType: selectedCol.selectedAggregationType,
          ...(selectedCol.extra ? { optimisticViewEnabled: !!selectedCol.extra.optimisticViewEnabled } : {}),
        };
      });

      // if the columns edited are for the current profile, we also need to update the app state
      // close modal when saved successfully
      if (activeProfile === this.dialogConfig.data.profile) {
        this._siteMonitoringFacade.onSiteMonitoringListUpdateSelectedColumnsSuccess$.pipe(take(1), takeUntil(this._componentDestroyed$)).subscribe(() => {
          this._dialogRef.close();
          this.saved.emit(columns);
        });
        this._siteMonitoringFacade.updateSiteListSelectedColumns(columns);
      } else {
        // save the data directly
        this._asyncDataService
          .doRequest$<any>(
            updateApplicationPreferencesByKey<ISiteMonitoringKeyMetricConfig[]>(this._applicationPreferencesService, `${this.dialogConfig.data.profile}.siteMonitoring.siteListColumns`, columns),
            { message: 'siteMonitoringSharedScope.SITE_MONITORING.COLUMNS_PICKER.ERROR.UPDATE_FAIL_150' },
            []
          )
          .pipe(takeUntil(this._componentDestroyed$))
          .subscribe(() => {
            this._dialogRef.close();
            this.saved.emit(columns);
          });
      }
    });
  }

  /** @ignore **/
  ngOnDestroy(): void {
    this._componentDestroyed$.next();
    this._componentDestroyed$.complete();
  }

  /**
   * Returns the columns that can be used in the monitoring list.
   * Combination of device properties, monitored variables and device tags
   * */
  private getTreeviewColumns(
    selectedColumns: ISiteMonitoringListColumnConfig[],
    columnNameOverrides: IColumnNameOverride[]
  ): ITreeNodeItem<IColumnPickerItem<ISiteMonitoringColumnPickerExtraConfig>>[] {
    // convert our column categories into our treeview format
    // remove any category not returned by the available columns endpoint
    return SITE_MONITORING_COLUMNS_CATEGORIES.map((category) => {
      const categoryName = this._translateService.translate(category.label, {}, 'site-monitoring-shared-scope');
      const treeNode: ITreeNodeItem<IColumnPickerItem> = {
        id: category.id,
        label: categoryName,
        children: [
          ...category.children.map((columnDefinition) => {
            // check if the column is already selected to get the current display types
            const currentListSelectedColumn = selectedColumns.find((col) => col.id === columnDefinition.id);
            const isMandatoryColumn = SITE_MONITORING_COLUMNS_MANDATORY.includes(columnDefinition.id);
            let columnDefinitionName: string;
            let description: string;
            if (columnDefinition.name.includes('MONITORED_VALUES')) {
              columnDefinitionName = `${columnDefinition.name}.TITLE_35`;
              description = `${columnDefinition.description}.DESCRIPTION_200`;
            } else {
              columnDefinitionName = `${columnDefinition.name}`;
              description = `${columnDefinition.description}`;
            }
            const columnName = columnNameOverrides.find((c) => c.columnId === columnDefinition.id)?.name || this._translateService.translate(columnDefinitionName, {}, 'site-monitoring-shared-scope');
            return {
              id: columnDefinition.id,
              label: columnName,
              disabled: isMandatoryColumn,
              data: {
                columnDefinition: {
                  ...columnDefinition,
                  name: columnName,
                  description: `siteMonitoringSharedScope.${description}`,
                },
                categoryName,
                mandatory: isMandatoryColumn,
                selectedDisplayType: currentListSelectedColumn?.displayType || columnDefinition.defaultDisplayType,
                selectedAggregationType: currentListSelectedColumn?.aggregationType || columnDefinition.defaultAggregationType,
                extra: columnDefinition.extra?.optimisticViewEnabled ? { optimisticViewEnabled: !!currentListSelectedColumn?.optimisticViewEnabled } : null,
              },
            };
          }),
        ],
      };
      return treeNode;
    });
  }

  /** @ignore convert the selected columns from db into treeview format **/
  private _getSelectedColumns(
    currentSelectedColumns: ISiteMonitoringListColumnConfig[],
    availableColumns: ITreeNodeItem<IColumnPickerItem<ISiteMonitoringColumnPickerExtraConfig>>[]
  ): IColumnPickerItem<ISiteMonitoringColumnPickerExtraConfig>[] {
    // get the currently selected columns (find the corresponding tree node item)
    // also filter that dont exist anymore
    let selectedColumns: IColumnPickerItem[] = currentSelectedColumns.map((col) => getNodeById(availableColumns, col.id)?.data).filter((col) => !!col);

    // if a mandatory column is not present in the selected columns, add it
    [...SITE_MONITORING_COLUMNS_MANDATORY].reverse().forEach((column) => {
      const columnsExists = currentSelectedColumns.findIndex((col) => col.id === column) !== -1;
      if (!columnsExists) {
        const addColumn = getNodeById(availableColumns, column);
        if (addColumn) {
          selectedColumns = [addColumn.data, ...selectedColumns];
        }
      }
    });
    return selectedColumns;
  }
}
