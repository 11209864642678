import { BoardTagsService } from '@activia/cm-api';
import { IJsonSchema } from '@activia/json-schema-forms';
import { dataOnceReady, IModalConfig, IStandardDialogData, LoadingState, ModalDialogType, ModalService, ThemeType } from '@activia/ngx-components';
import { ErrorHandlingService } from '@amp/error';
import {
  EngineTagLevel,
  IEngineTagKey,
  IEngineTagKeyDesc,
  ITagOperationChange,
  SaveBoardOrgPathDefinition,
  selectBoardOrgPathDefinition,
  selectBoardOrgPathDefinitionState,
  selectBoardOrgPathSaveState,
  TagAdapterService,
  TagOperationService,
} from '@amp/tag-operation';
import { HttpErrorResponse } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { tapResponse } from '@ngrx/component-store';
import { Store } from '@ngrx/store';
import { cloneDeep } from 'lodash';
import { filter, map, Observable, of, Subject, switchMap, take, takeUntil } from 'rxjs';
import { v4 as uuidV4 } from 'uuid';
import { IOrgPathNode, OrgpathState } from '../orgpath.interface';
import { OrgpathStoreBase } from '../orgpath-store-base';

const initialState: OrgpathState = {
  selectedNode: undefined,
  nodeEntities: {},
  tagsDefinitions: {},
  state: LoadingState.INIT,
  isSaved: true,
};

@Injectable()
export class BoardOrgpathStore extends OrgpathStoreBase implements OnDestroy {
  //#region Selectors
  selectedNode$ = this.select((state) => state.nodeEntities[state.selectedNode]);
  selectOrgPathDefinition$ = this.select((state) => Object.values(state.nodeEntities).find((e) => !e.parent));
  selectNodeEntities$ = this.select((state) => state.nodeEntities);
  selectTagsDefinitions$ = this.select((state) => state.tagsDefinitions);
  selectLoadingState$ = this.select((state) => state.state);
  selectIsSaved$ = this.select((state) => state.isSaved);
  selectErrors$ = this.select(this.selectNodeEntities$, this.selectTagsDefinitions$, (nodeEntities, tagsDefinitions) => this.getErrorsInNodes(nodeEntities, tagsDefinitions));
  //#endregion

  onDestroyed$ = new Subject<void>();

  constructor(
    private _store: Store,
    private _boardTagsService: BoardTagsService,
    private _tagOperationService: TagOperationService,
    private _transloco: TranslocoService,
    private _errorHandlingService: ErrorHandlingService,
    private _modalService: ModalService,
    private _tagAdapterService: TagAdapterService
  ) {
    super(initialState);

    // Loading while waiting for org path definition
    this.patchState({ state: LoadingState.LOADING });

    // Initialize tags definitions
    this._boardTagsService
      .findAllTagKeys()
      .pipe(map((tags) => this._tagAdapterService.normalizeIncomingTagDescriptions(tags)))
      .subscribe((tags) => this.patchState({ tagsDefinitions: tags }));

    // Initialize org path definition
    dataOnceReady(this._store.select(selectBoardOrgPathDefinition), this._store.select(selectBoardOrgPathDefinitionState), 1).subscribe((orgPathDef) => {
      this.patchState({ nodeEntities: this.linkChildToParent(cloneDeep(orgPathDef.root)), state: LoadingState.LOADED });
    });
  }

  //#region Reducer

  /** Change the selected node in the tree */
  selectNode = this.updater((state, nodeId: string | undefined) => ({
    ...state,
    selectedNode: nodeId,
  }));

  /** Add a new root to the tree */
  addRootNode = this.updater((state) => {
    // add new child to this node
    const newNode = { id: uuidV4() } as IOrgPathNode;

    return {
      ...state,
      nodeEntities: {
        ...state.nodeEntities,
        [newNode.id]: newNode,
      },
      selectedNode: newNode.id,
      isSaved: false,
    };
  });

  /** Add a new child node at the specified node ID */
  addNewNode = this.updater((state, nodeId: string) => {
    const node = state.nodeEntities[nodeId];

    // add new child to this node
    const newNode = { parent: node, id: uuidV4() } as IOrgPathNode;
    if (node.childOneOf) {
      node.childOneOf.push(newNode);
    } else {
      node.childOneOf = [newNode];
    }

    return {
      ...state,
      nodeEntities: {
        ...state.nodeEntities,
        [newNode.id]: newNode,
      },
      selectedNode: newNode.id,
      isSaved: false,
    };
  });

  /** Delete the specified Node id */
  deleteNode = this.updater((state, nodeId: string) => {
    const node = state.nodeEntities[nodeId];

    // Delete node from the tree
    if (node.parent) {
      const index = node.parent.childOneOf.findIndex((e) => e.id === nodeId);
      node.parent.childOneOf.splice(index, 1);
    }

    // Remove all children associated with current node
    const deletedIds = this.getAllChildren(node);

    // Rebuild dictionnary without deleted nodes
    const nodeEntities = Object.keys(state.nodeEntities)
      .filter((currentNodeId) => !deletedIds.includes(currentNodeId))
      .reduce((acc, curr) => {
        acc[curr] = state.nodeEntities[curr];
        return acc;
      }, {});

    return {
      ...state,
      nodeEntities,
      selectedNode: node.id === state.selectedNode ? null : state.selectedNode,
      isSaved: false,
    };
  });

  /** Change the condition of the node (dependent on the parent JSON schema)  */
  editNodeCondition = this.updater((state, condition?: string | number) => {
    const selectedNode = state.nodeEntities[state.selectedNode];
    selectedNode.dependentItem = condition?.toString();

    return {
      ...state,
      nodeEntities: {
        ...state.nodeEntities,
        [selectedNode.id]: selectedNode,
      },
      isSaved: false,
    };
  });

  /** Change the type of the node in the tree (name or tag) */
  editNodeTagOrProperty = this.updater((state, newTagProperty: string) => {
    const selectedNode = state.nodeEntities[state.selectedNode];

    if (newTagProperty === 'name') {
      selectedNode.property = newTagProperty;
      delete selectedNode.tag;

      // Default Schema for "name"
      selectedNode.schema = {
        type: 'string',
        pattern: '^[a-zA-Z0-9]*$',
        title: this._transloco.translate('siteManagementScope.SITE_MANAGEMENT.GLOBAL.ORGANIZATIONAL_PATH.ORGPATH_EDITOR.NODE_EDITOR.BOARD_NAME_TITLE_30'),
      };
    } else {
      selectedNode.tag = newTagProperty;
      delete selectedNode.property;
      delete selectedNode.schema;
    }
    // on changing a tag with child elements, we clear dependentItem conditions to prevent errors
    this.clearDependentItemsFromChildren(selectedNode);

    return {
      ...state,
      nodeEntities: {
        ...state.nodeEntities,
        [selectedNode.id]: selectedNode,
      },
      isSaved: false,
    };
  });

  /** Change the JSON schema for the selected node of type "Board Name" */
  editPropertySchema = this.updater((state, schema: IJsonSchema) => {
    const selectedNode = state.nodeEntities[state.selectedNode];
    selectedNode.schema = {
      // Keep all properties coming from 'description'
      ...(selectedNode.schema.title && { title: selectedNode.schema.title }),
      ...(selectedNode.schema.description && { description: selectedNode.schema.description }),
      ...((selectedNode.schema as any).examples && { examples: (selectedNode.schema as any).examples }),
      ...((selectedNode.schema as any).default && { default: (selectedNode.schema as any).default }),

      // Add properties from 'constraint'
      ...(schema.type && { type: schema.type }),
      ...(!isNaN(schema.minimum) && { minimum: schema.minimum }),
      ...(!isNaN(schema.maximum) && { maximum: schema.maximum }),
      ...(schema.enum && { enum: schema.enum }),
      ...(!isNaN(schema.maxLength) && { maxLength: schema.maxLength }),
      ...(!isNaN(schema.minLength) && { minLength: schema.minLength }),
      ...(schema.pattern && { pattern: schema.pattern }),
    };

    return {
      ...state,
      nodeEntities: {
        ...state.nodeEntities,
        [selectedNode.id]: selectedNode,
      },
      isSaved: false,
    };
  });

  /** Change the Description property of the selected node */
  editPropertyDescription = this.updater((state, schema: Partial<IJsonSchema>) => {
    const selectedNode = state.nodeEntities[state.selectedNode];
    selectedNode.schema = {
      ...selectedNode.schema,
      title: schema.title,
      description: schema.description,
      examples: (schema as any).examples,
      default: (schema as any).default,
    } as any;

    return {
      ...state,
      nodeEntities: {
        ...state.nodeEntities,
        [selectedNode.id]: selectedNode,
      },
      isSaved: false,
    };
  });

  /** Add a new Json schema definition of a tag  */
  addTagDefinition = this.updater((state, tag: IEngineTagKey) => ({
    ...state,
    tagsDefinitions: {
      ...this.get().tagsDefinitions,
      [tag.key]: tag.description,
    },
  }));

  /** Make node higher in the "dependentItem" priority  */
  moveNodeUp = this.updater((state, nodeId: string) => {
    const node = state.nodeEntities[nodeId];

    if (node.parent) {
      const index = node.parent.childOneOf.findIndex((e) => e.id === node.id);

      if (index > 0) {
        node.parent.childOneOf.splice(index, 1); // remove node at the current position
        node.parent.childOneOf.splice(index - 1, 0, node); // add it back 1 position up
      }
    }

    return {
      ...state,
      isSaved: false,
    };
  });

  /** Make node lower in the "dependentItem" priority  */
  moveNodeDown = this.updater((state, nodeId: string) => {
    const node = state.nodeEntities[nodeId];

    if (node.parent) {
      const index = node.parent.childOneOf.findIndex((e) => e.id === node.id);

      if (index < node.parent.childOneOf.length - 1) {
        node.parent.childOneOf.splice(index, 1); // remove node at the current position
        node.parent.childOneOf.splice(index + 1, 0, node); // add it back 1 position down
      }
    }

    return {
      ...state,
      isSaved: false,
    };
  });
  //#endregion

  //#region Effects

  /** Add a new board tag in backend */
  readonly addNewTag = this.effect((tag$: Observable<IEngineTagKey>) =>
    tag$.pipe(
      map((tag) => this._tagAdapterService.normalizeOutgoingTagKey(tag)),
      switchMap((tag) =>
        this._boardTagsService.updateTagKey(tag.key, tag.description).pipe(
          tapResponse(
            () => {
              const incomingTag = this._tagAdapterService.normalizeIncomingTagKey(tag);
              this.addTagDefinition(incomingTag);
              this.editNodeTagOrProperty(tag.key);
            },
            (error) => this._errorHandlingService.catchError(error as HttpErrorResponse, undefined, 'Error add new tag')
          )
        )
      )
    )
  );

  /** Edit a tag in backend */
  readonly saveTag = this.effect(
    (
      tag$: Observable<{
        key: string;
        description: IEngineTagKeyDesc;
        operations: ITagOperationChange;
      }>
    ) =>
      tag$.pipe(
        map((tag) => ({
          ...this._tagAdapterService.normalizeOutgoingTagKey(tag),
          operations: tag.operations,
        })),
        switchMap((tag) => this._boardTagsService.updateTagKey(tag.key, tag.description).pipe(map(() => tag))),
        switchMap(({ key, description, operations }) =>
          this._tagOperationService
            .syncTagValuesAfterKeyUpdate(
              {
                key,
                description: description as IEngineTagKeyDesc,
                level: EngineTagLevel.BOARD,
              },
              operations
            )
            .pipe(
              tapResponse(
                () => {
                  const incomingDescription = this._tagAdapterService.normalizeIncomingTagDescription(description);
                  this.addTagDefinition({ key, description: incomingDescription });
                  this.editNodeTagOrProperty(key);
                },
                (error) => this._errorHandlingService.catchError(error as HttpErrorResponse, undefined, 'Error save tag')
              )
            )
        )
      )
  );

  /** Remove node. If node is a tag and is already used show modal to confirm deletion */
  readonly removeNode = this.effect((id$: Observable<string>) =>
    id$.pipe(
      map((id) => this.get().nodeEntities[id]),
      switchMap((node) => {
        if (node.tag) {
          // Check if tag is already used
          return this._boardTagsService
            .getInvalidCountForNewTagKeyDesc(node.tag, {
              dynamic: false,
              multivalues: false,
              schema: {
                type: 'string',
                pattern: '/(?=a)b/', // Always failing pattern to get the count of all boards using this tag
              },
            })
            .pipe(
              switchMap((res) => {
                if (res.count > 0) {
                  // Tag is already used
                  return this._showConfirmationModal(node.tag, res.count).pipe(map(() => node.id)); // if user cancel, then observable complete
                } else {
                  // Tag is unused and safe to delete
                  return of(node.id);
                }
              })
            );
        } else {
          return of(node.id);
        }
      }),
      tapResponse(
        (id: string) => this.deleteNode(id),
        (error) => this._errorHandlingService.catchError(error as HttpErrorResponse, undefined, 'Error deleting node')
      )
    )
  );

  /** Save the definition in database */
  saveDefinition(): Observable<boolean> {
    const root = Object.values(this.get().nodeEntities).find((e) => !e.parent); // Get root of the tree

    const sanitizedRoot = this.sanitizeNode(root); // Sanitize tree

    // Dispatch action to save tree
    this._store.dispatch(SaveBoardOrgPathDefinition({ boardOrgPathDefinition: { type: 'board', root: sanitizedRoot } }));

    // Listen at the result of the save action
    const isSaved$ = this._store.select(selectBoardOrgPathSaveState).pipe(
      filter((state) => state !== LoadingState.LOADING),
      map((state) => state === LoadingState.LOADED), // Board org path def saved successfully
      take(1),
      takeUntil(this.onDestroyed$)
    );

    // Change state if saved successfully
    isSaved$.subscribe((isSaved) => {
      if (isSaved) {
        this.patchState({ isSaved: true });
      }
    });

    return isSaved$;
  }

  //#endregion

  hasUnsavedChanges() {
    return !this.get().isSaved;
  }

  ngOnDestroy(): void {
    this.onDestroyed$.next();
    this.onDestroyed$.complete();
  }

  private _showConfirmationModal(tagKey: string, count: number): Observable<boolean> {
    const dialogData: IStandardDialogData = {
      type: ModalDialogType.Confirm,
      theme: ThemeType.DANGER,
      title: this._transloco.translate('siteManagementScope.SITE_MANAGEMENT.GLOBAL.ORGANIZATIONAL_PATH.ORGPATH_EDITOR.NODE_TREE.MODAL_DELETE_CONFIRMATION_TITLE_40'),
      message: this._transloco.translate('siteManagementScope.SITE_MANAGEMENT.GLOBAL.ORGANIZATIONAL_PATH.ORGPATH_EDITOR.NODE_TREE.MESSAGE_100', {
        tagKey,
        count,
      }),
      closeActionLabel: this._transloco.translate('button.cancel'),
      acceptActionLabel: this._transloco.translate('button.delete'),
    };

    const dialogConfig: IModalConfig<IStandardDialogData> = {
      showCloseIcon: true,
      closeOnBackdropClick: true,
      data: dialogData,
    };
    const modalRef = this._modalService.openStandardDialog(dialogConfig);
    return modalRef.componentInstance.accepted.pipe(take(1), takeUntil(this.onDestroyed$), takeUntil(modalRef.afterClosed));
  }
}
