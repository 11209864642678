<ng-container *ngTemplateOutlet="iconHeader; context: { title: title }"></ng-container>

<avn-slider
  [multiRange]="false"
  [selectedValueDisplayMode]="'always'"
  [barHeight]="8"
  [minSelected]="value$ | async"
  [maxSelected]="max"
  [min]="min"
  [max]="max"
  [showStepIndicator]="false"
  [step]="1"
  [hideValues]="false"
  [showTooltip]="true"
  [avnTheme]="'primary'"
  (released)="onValueChanged($event)"
>
</avn-slider>

<ng-template #iconHeader let-title="title" let-icon="icon">
  <div class="icon-header">
    <avn-icon [icon]="icon" *ngIf="icon"></avn-icon>
    <h6>{{ title }}</h6>
  </div>
</ng-template>
