<avn-dialog-layout>
  <avn-dialog-header [title]="'siteManagementScope.SITE_MANAGEMENT.GEODETIC_FIXER.AUTO_FIX_SITES_TASK_PANEL.TITLE_50' | transloco"></avn-dialog-header>

  <avn-dialog-content avnScroll>
    <avn-data-table
      [columns]="columns$ | async"
      [dataSource]="dataSource$ | async"
      [rowHeight]="80"
      [tableHeight]="'auto'"
      [showHeader]="true"
      [serverSorting]="false"
      [infiniteScrollEnabled]="false"
    >
    </avn-data-table>
  </avn-dialog-content>
</avn-dialog-layout>

<ng-template #statusTemplate let-row="row">
  <div class="status">
    <avn-icon
      [fontSize]="18"
      [icon]="row.autoFixStatus === 'UPDATED' ? 'action:check_circle' : 'alert:error'"
      [avnTheme]="row.autoFixStatus === 'UPDATED' ? 'success' : 'danger'">
    </avn-icon>
    <span>{{ row.autoFixStatus }}</span>
  </div>
</ng-template>

<ng-template #errorTemplate let-row="row">
  <span class="error">{{ row.error }}</span>
</ng-template>

<amp-site-templates></amp-site-templates>
