import { ThemeType } from '@activia/ngx-components';
import { SiteSyncStatus, SiteValidationStatus } from '../store/site-sync/site-sync.model';

/** Mapping of the corresponding icon for each import job filter */
export const SiteSyncStatusIcons: Record<SiteValidationStatus | SiteSyncStatus, string> = {
  add: 'content:add',
  update: 'content:add',
  unchanged: 'notification:do_not_disturb',
  processing: 'notification:sync',
  processed: 'action:check_circle_outline',
  warning: 'alert:warning_amber',
  queued: 'action:schedule',
  'invalid-id': 'alert:error_outline',
  error: 'alert:error_outline',
};

/** Mapping of the corresponding icon for each import job filter */
export const SiteSyncStatusThemes: Record<SiteValidationStatus | SiteSyncStatus, ThemeType> = {
  add: ThemeType.SUCCESS,
  update: ThemeType.SUCCESS,
  unchanged: ThemeType.INFO,
  processing: ThemeType.HIGHLIGHT,
  processed: ThemeType.SUCCESS,
  warning: ThemeType.WARNING,
  queued: null,
  'invalid-id': ThemeType.DANGER,
  error: ThemeType.DANGER,
};
