import { Pipe, PipeTransform } from '@angular/core';
import { SiteMonitoredValue } from '../model/site-monitored-value.enum';
import { CountAggregationData } from '../model/site-monitoring-data.interface';
import { combineOptimisticData, getOptimisticViewCombinations } from '../utils/site-monitoring-optimistic-view.utils';

/**
 * Combines multiple statuses together and sum the counts
 */
@Pipe({ name: 'optimisticView' })
export class OptimisticViewPipe implements PipeTransform {
  transform(data: CountAggregationData, enabled: boolean, field: SiteMonitoredValue): CountAggregationData {
    if (!data || !field || !enabled) {
      return data;
    }

    // get the status combinations for the current field
    const combinations = getOptimisticViewCombinations(field);
    if (!combinations) {
      return data;
    }

    return combineOptimisticData(data, combinations);
  }
}
