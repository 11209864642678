import { Action, createReducer, on } from '@ngrx/store';
import { AsyncData, AsyncDataState, ISort, LoadingState } from '@activia/ngx-components';
import * as SiteManagementAction from './site-management.actions';
import { SiteDTO } from '@activia/cm-api';
import { ISiteManagementConfigurationSchema } from '@amp/environment';
import { SiteColumn } from '../sites/models/site-column';
import { difference } from 'lodash/array';
import { SiteSyncUpdateSiteInfo } from '../store/site-sync/site-sync.actions';
import * as GeoFixerAction from './geo-fixer/geo-fixer.actions';

export interface ISiteManagementState {
  /** Site management configuration */
  siteConfig: AsyncData<ISiteManagementConfigurationSchema>;

  /** All sites in the system with site ID as the key */
  allSites: AsyncData<Record<string, SiteDTO>>;

  /** Interval in ms to auto-refresh all sites */
  refreshIntervalMs: number;

  /** Field and direction to sort the sites by. */
  siteListSort: ISort;

  /** The site that is displayed in site detail */
  siteDetail: {
    siteId: AsyncData<number>;

    /** Status of the experience template boards creation **/
    createTemplateBoardsStatus: {
      stepId: string; // step currently executed in the board creation
      loadingState: AsyncDataState; // loading state of the step
    };
  };

  /** Ids of sites currently in the process of being deleted **/
  deleteSiteIds: number[];

  selectedSiteIds: string[];
}

export const siteManagementInitialState: ISiteManagementState = {
  siteConfig: {
    data: undefined,
    state: LoadingState.INIT,
  },
  allSites: {
    data: {},
    state: LoadingState.INIT,
  },
  refreshIntervalMs: 300000,
  siteListSort: { id: SiteColumn.LAST_UPDATED, direction: 'desc', prop: 'modificationTime' },
  siteDetail: {
    siteId: {
      data: undefined,
      state: LoadingState.INIT,
    },
    createTemplateBoardsStatus: null,
  },
  deleteSiteIds: [],
  selectedSiteIds: [],
};

const reducer = createReducer<ISiteManagementState>(
  siteManagementInitialState,

  /** Fetch site config schema **/
  on(SiteManagementAction.FetchSiteConfig, (state, _) => ({
    ...state,
    siteConfig: {
      ...state.siteConfig,
      state: LoadingState.LOADING,
      lastAttempt: new Date(),
    },
  })),

  on(SiteManagementAction.FetchSiteConfigSuccess, (state, action) => ({
    ...state,
    siteConfig: {
      ...state.siteConfig,
      data: action.siteConfig,
      state: LoadingState.LOADED,
      lastSuccess: new Date(),
    },
  })),

  /** Fetch all sites */
  on(SiteManagementAction.FetchAllSites, (state, _) => ({
    ...state,
    allSites: {
      ...state.allSites,
      state: LoadingState.LOADING,
      lastAttempt: new Date(),
    },
  })),

  on(SiteManagementAction.FetchAllSitesSuccess, (state, action) => ({
    ...state,
    allSites: {
      ...state.allSites,
      data: action.sites.reduce((acc, curr) => {
        acc[curr.id] = curr;
        return acc;
      }, {} as Record<string, SiteDTO>),
      state: LoadingState.LOADED,
      lastSuccess: new Date(),
    },
  })),

  on(SiteManagementAction.FetchAllSitesFail, (state, action) => ({
    ...state,
    allSites: {
      ...state.allSites,
      state: { errorMsg: action.error },
    },
  })),

  /** Delete some sites */
  on(SiteManagementAction.DeleteSites, (state, { siteIds }) => {
    const siteIdsNotYetMarkedForDeletion = difference(siteIds, state.deleteSiteIds);

    return {
      ...state,
      deleteSiteIds: [...state.deleteSiteIds, ...siteIdsNotYetMarkedForDeletion],
    };
  }),

  on(SiteManagementAction.DeleteSiteSuccess, (state, { siteId }) => {
    const newSiteMap = {
      ...state.allSites.data,
    };
    delete newSiteMap[siteId];
    return {
      ...state,
      allSites: {
        ...state.allSites,
        data: newSiteMap,
      },
    };
  }),

  on(SiteManagementAction.ClearDeletedSites, (state) => ({
    ...state,
    deleteSiteIds: [],
  })),

  /** Set sort by field of the sites */
  on(SiteManagementAction.UpdateSiteListSort, (state, action) => ({
    ...state,
    siteListSort: action.sortBy,
  })),

  /** Fetch current site along with boards and devices */
  on(SiteManagementAction.FetchSiteDetail, (state, _) => ({
    ...state,
    siteDetail: {
      ...state.siteDetail,
      siteId: {
        data: null,
        state: LoadingState.LOADING,
        lastAttempt: new Date(),
      },
    },
  })),

  on(SiteManagementAction.FetchSiteDetailSuccess, (state, action) => ({
    ...state,
    siteDetail: {
      ...state.siteDetail,
      siteId: {
        ...state.siteDetail.siteId,
        data: action.site.id,
        state: LoadingState.LOADED,
        lastSuccess: new Date(),
      },
    },
    allSites: {
      ...state.allSites,
      data: {
        ...state.allSites.data,
        [action.site.id]: { ...action.site },
      },
    },
  })),

  on(SiteManagementAction.FetchSiteDetailFail, (state, action) => ({
    ...state,
    siteDetail: {
      ...state.siteDetail,
      siteId: {
        ...state.siteDetail.siteId,
        data: undefined,
        state: { errorMsg: action.error },
      },
    },
  })),

  /** Create boards without devices from template */
  on(SiteManagementAction.CreateTemplateBoardsSuccess, (state, { site }) => ({
    ...state,
    allSites: {
      ...state.allSites,
      data: {
        ...state.allSites.data,
        [site.id]: { ...site },
      },
    },
    siteDetail: {
      ...state.siteDetail,
      createTemplateBoardsStatus: null,
    },
  })),

  /** Create boards without devices from template */
  on(SiteManagementAction.CreateTemplateBoardsUpdateStatus, (state, { stepId, loadingState }) => ({
    ...state,
    siteDetail: {
      ...state.siteDetail,
      createTemplateBoardsStatus: {
        stepId,
        loadingState,
      },
    },
  })),

  /** Refresh one single site */
  on(SiteManagementAction.RefreshSiteSuccess, (state, action) => ({
    ...state,
    allSites: {
      ...state.allSites,
      data: {
        ...state.allSites.data,
        [action.site.id]: { ...action.site },
      },
    },
  })),

  /** Manually create a site success */
  on(SiteManagementAction.CreateSiteSuccess, (state, action) => ({
    ...state,
    allSites: {
      ...state.allSites,
      data: {
        ...state.allSites.data,
        [action.site.id]: { ...action.site },
      },
    },
  })),

  /** Update a site */
  on(SiteManagementAction.UpdateSiteSuccess, (state, action) => {
    const aa = {
      ...state,
      allSites: {
        ...state.allSites,
        data: {
          ...state.allSites.data,
          [action.site.id]: { ...action.site },
        },
      },
    };
    return aa;
  }),

  /** Insert/update a site from site sync */
  on(SiteSyncUpdateSiteInfo, (state, { site }) => ({
    ...state,
    allSites: {
      ...state.allSites,
      data: {
        ...state.allSites.data,
        [site.id]: site,
      },
    },
  })),

  /** Update a site from geo fixer after auto-fix incomplete coordinate or missing timezone */
  on(GeoFixerAction.AutoFixGeoProblematicSiteSuccess, (state, { site }) => ({
    ...state,
    allSites: {
      ...state.allSites,
      data: {
        ...state.allSites.data,
        [site.id]: site,
      },
    },
  })),

  /** Update User Preferences **/
  on(SiteManagementAction.UpdatePreferences, (state, _) => ({
    ...state,
    siteConfig: {
      ...state.siteConfig,
      state: LoadingState.LOADING,
    },
  })),

  on(SiteManagementAction.UpdatePreferencesSuccess, (state, action) => ({
    ...state,
    siteConfig: {
      data: { ...state.siteConfig.data, ...(action.preferences as any) },
      state: LoadingState.LOADED,
    },
  })),

  on(SiteManagementAction.UpdatePreferencesFail, (state, action) => ({
    ...state,
    siteConfig: {
      ...state.siteConfig,
      state: { errorMsg: action.error },
    },
  })),

  on(SiteManagementAction.UpdateSelectedSites, (state, action) => ({
    ...state,
    selectedSiteIds: action.selectedSiteIds,
  })),

  on(SiteManagementAction.UnloadSelectedSites, (state) => ({
    ...state,
    selectedSiteIds: [],
    siteDetail: {
      siteId: {
        data: undefined,
        state: LoadingState.INIT,
      },
      createTemplateBoardsStatus: null,
    },
  }))
);

export const siteManagementReducer = (state: ISiteManagementState | undefined, action: Action): ISiteManagementState => reducer(state, action);
