<form [formGroup]="form">
  <avn-form-field [enableValidTheme]="false">
    <avn-checkbox-group
      formControlName="enclosureStatus"
      [items]="options"
      [direction]="'vertical'"
    ></avn-checkbox-group>
    <avn-form-field-error key="required" [message]="'siteMonitoringSharedScope.SITE_MONITORING.KEY_METRICS_PICKER.SETTINGS.ENCLOSURE_STATUS.VALIDATION.REQUIRED_50' | transloco"></avn-form-field-error>
  </avn-form-field>
</form>
