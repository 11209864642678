import { ISiteMonitoringKeyMetricConfig } from '../../model/site-monitoring-key-metric-config.interface';
import { CountAggregationData, MinMaxAggregationData } from '../../model/site-monitoring-data.interface';
import { AsyncDataState, LoadingState } from '@activia/ngx-components';

type Constructor<T> = new (...args: any[]) => T;

/**
 * basic interface for a key metrics component
 */
export interface ISiteMonitoringKeyMetricComponent<T = any> {
  /** The configuration of the current key metric component **/
  config: ISiteMonitoringKeyMetricConfig<T>;

  /** Contains loading state info of the monitoring data api call */
  monitoringDataDataState: AsyncDataState;

  /** Indicates if component should show the compact view */
  showCompactView: boolean;

  /** Monitoring data needed for this key metric component **/
  monitoringData: CountAggregationData | MinMaxAggregationData<number> | { [key: string]: CountAggregationData};

  /** Optional date value of this key metric **/
  monitoringDate: MinMaxAggregationData<number>;

  /** A function that allows to determine when the data is considered as empty. Used to determine when to display the empty content. */
  emptyMonitoringDataFn(): boolean;
}

/** @docs-private */
export type SiteMonitoringKeyMetricComponentCtor<T> = Constructor<ISiteMonitoringKeyMetricComponent<T>>;

/**
 * Mixin to apply to key metrics components. It contains the shared functionality of all key metrics.
 **/
export const mixinKeyMetric = <T extends Constructor<any>, C>(base: T): SiteMonitoringKeyMetricComponentCtor<C> & T => class extends base {
    /** The configuration of the current key metric component **/
    config: ISiteMonitoringKeyMetricConfig<T>;

    /** Contains loading state info of the monitoring data api call */
    monitoringDataDataState = LoadingState.INIT;

    /** Indicates if component should show the compact view */
    showCompactView = false;

    /** current monitoring values needed for this key metric component **/
    monitoringData: CountAggregationData | MinMaxAggregationData<number>;

    /** Optional date value of this key metric **/
    monitoringDate: MinMaxAggregationData<number>;

    /** A function that indicates if the key metric monitoring data is empty **/
    emptyMonitoringDataFn(): boolean {
      return !this.monitoringData || Object.keys(this.monitoringData).length === 0;
    }

    constructor(...args: any[]) {
      super(...args);
    }
  };

export class KeyMetricsMixinCore {}

export const KeyMetricsBaseMixin = <T = any>(): SiteMonitoringKeyMetricComponentCtor<T> & typeof KeyMetricsMixinCore => mixinKeyMetric(KeyMetricsMixinCore);
