import { Pipe, PipeTransform } from '@angular/core';
import { CountAggregationData } from '../model/site-monitoring-data.interface';
import { DevicePropertiesStatusPipe } from './device-properties-status.pipe';
import { SiteMonitoredValue } from '../model/site-monitored-value.enum';
import { IPieDataItem, themeTypeToCssVar } from '@activia/dataviz';
import { ContentStateThemeMap, ContentStatusThemes, HealthStatusCode, HealthStatusThemes, ServiceStatusThemeMap } from '@amp/devices';
import { TranslocoService } from '@ngneat/transloco';
import { ThemeType } from '@activia/ngx-components';

@Pipe({ name: 'datavizPieDataItems' })
export class DatavizPieDataItemsPipe implements PipeTransform {
  constructor(private translate: TranslocoService) {}

  transform(value: CountAggregationData, field: SiteMonitoredValue): Array<IPieDataItem> {
    let chartDataSource: Array<IPieDataItem> = [];

    switch (field) {
      case SiteMonitoredValue.HealthStatus: {
        const healthStatusNames = Object.entries(HealthStatusCode).reduce((acc, [k, v]) => ({ ...acc, [v]: k }), {});
        chartDataSource = Object.entries(value).map(([status, count]) => ({
            name: this.translate.translate(`deviceFields.DEVICE.ENUM.HEALTH_STATUS.${healthStatusNames[status]}`),
            value: +count,
            color: themeTypeToCssVar(HealthStatusThemes[+status]),
          }));
        break;
      }
      case SiteMonitoredValue.ContentState: {
        chartDataSource = Object.entries(value).map(([status, count]) => ({
            name: this.translate.translate(`deviceFields.DEVICE.ENUM.CONTENT_STATE.${status}`),
            value: +count,
            color: themeTypeToCssVar(ContentStateThemeMap[+status]),
          }));
        break;
      }
      case SiteMonitoredValue.ContentStatus: {
        chartDataSource = Object.entries(value).map(([status, count]) => ({
            name: this.translate.translate(`deviceFields.DEVICE.ENUM.CONTENT_STATUS.${status}`),
            value: +count,
            color: themeTypeToCssVar(ContentStatusThemes[+status]),
          }));
        break;
      }
      case SiteMonitoredValue.HttpService:
      case SiteMonitoredValue.OmnicastStatus:
      case SiteMonitoredValue.ServicePlayer:
      case SiteMonitoredValue.PosState: {
        // default to service
        chartDataSource = Object.entries(value).map(([status, count]) => ({
            name: this.translate.translate(`deviceFields.DEVICE.ENUM.SERVICE_STATUS_CODE.${status}`),
            value: +count,
            color: themeTypeToCssVar(ServiceStatusThemeMap[+status]),
          }));
        break;
      }
      case SiteMonitoredValue.DevicePropertiesStatus: {
        chartDataSource = Object.entries(new DevicePropertiesStatusPipe().transform(value)).map(([status, count]) => ({
            name: this.translate.translate(`deviceFields.DEVICE.ENUM.DEVICE_PROPERTIES_STATUS_COMBINED.${status}`),
            value: count,
            color: themeTypeToCssVar(status as ThemeType),
          }));
        break;
      }
      default:
        break;
    }

    return chartDataSource;
  }
}
