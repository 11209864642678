/**
 * ALl possible statuses for a 'command' (aka a device command)
 * A command is part of a master 'command status'
 * https://gitlab.activia.io/cm/engine-core/blob/master/src/net/activia/cm/domain/command/DeviceCommandStatusBase.java
 * */
export enum DeviceCommandStatusEnum {
  OK = 0,
  FAILED = 1,
  MISMATCH_ID = 2,
  OPNOTSUPP = 3,
  INPROGRESS = 6,
  CANCELLED = 8,
  UNREACHED = 15,
  UNKNOWN = 16,
  WAITING = 17,
  SKIPPED = 18,
  CANCELLING = 19,
}

export const getDeviceCommandStatusByCode = (value: DeviceCommandStatusEnum) => Object.keys(DeviceCommandStatusEnum)[Object.values(DeviceCommandStatusEnum).indexOf(value)];
