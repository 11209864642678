import { ThemeType } from '@activia/ngx-components';

/**
 * See https://confluence.stratacache.com/display/CM/AMP+Monitoring+Values for more info
 */
export enum DevicePropertiesStatus {
  /**
   * Glossary:
   *
   * device.tags.mtime = the last time device tags were modified in CM (meta.mtime property)
   * last.distribution = date of the last channel distribution triggered on this device
   * last.properties.update = last properties update (through a command)
   */

  OK = 0,

  /**
   * Device is in this status if
   * - error and warning conditions are false and device.tags.mtime > PROPERTIES_MTIMEDYN
   */
  NoticeDYN = 1,

  /**
   * Device is in this status if
   * - error and warning conditions are false and device.tags.mtime > PROPERTIES_MTIME
   */
  NoticeALL = 2,

  /**
   * Device is in this status if
   * - error conditions are false and device.tags.mtime - PROPERTIES_MTIMEDYN > x days (x configurable)
   */
  WarningDYN = 3,

  /**
   * Device is in this status if
   * - error conditions are false and device.tags.mtime - PROPERTIES_MTIME > x days (x configurable)
   */
  WarningALL = 4,

  /**
   * Device is in this status if
   * - last.properties.update > device.tags.mtime > max (PROPERTIES_MTIMEDYN, PROPERTIES_MTIME)
   */
  ErrorDYN = 5,

  /**
   * Device is in this status if
   * - last.distribution > device.tags.mtime > PROPERTIES_MTIME
   */
  ErrorALL = 6,
}

export const DevicePropertiesStatusThemeMap = new Map<DevicePropertiesStatus, ThemeType>([
  [DevicePropertiesStatus.OK, ThemeType.SUCCESS],
  [DevicePropertiesStatus.NoticeDYN, ThemeType.INFO],
  [DevicePropertiesStatus.NoticeALL, ThemeType.INFO],
  [DevicePropertiesStatus.WarningDYN, ThemeType.WARNING],
  [DevicePropertiesStatus.WarningALL, ThemeType.WARNING],
  [DevicePropertiesStatus.ErrorDYN, ThemeType.DANGER],
  [DevicePropertiesStatus.ErrorALL, ThemeType.DANGER],
]);

/** The statuses ordered by severity (high to low) **/
export const DevicePropertiesStatusSeverity = [
  DevicePropertiesStatus.ErrorALL,
  DevicePropertiesStatus.ErrorDYN,
  DevicePropertiesStatus.WarningALL,
  DevicePropertiesStatus.WarningDYN,
  DevicePropertiesStatus.NoticeALL,
  DevicePropertiesStatus.NoticeDYN,
  DevicePropertiesStatus.OK,
];
