import { Component, Input } from '@angular/core';
import { IBoardAlarm } from '../../model/alarm-event.interface';
import { SiteMonitoringFacade } from '../../store/site-monitoring.facade';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';

@Component({
  selector: 'amp-site-alarms',
  templateUrl: './site-alarms.component.html',
  styleUrls: ['./site-alarms.component.scss'],
})
export class SiteAlarmsComponent {
  @Input() siteId: number;
  @Input() siteTitle: string;

  /** Observable of alarms from all the board in a given site **/
  @Input() boardAlarms: Array<IBoardAlarm>;

  // Key: the organization path of the section, e.g. "indoor", "outdoor.lane1"
  @Input() boardAlarmsBySection: Map<string, { hasBoardInError: boolean; boards: Array<IBoardAlarm> }>;

  showOnlyAlarmErrors$: Observable<boolean>;

  constructor(private _siteMonitoringFacade: SiteMonitoringFacade) {
    this.showOnlyAlarmErrors$ = this._siteMonitoringFacade.userPreferences$.pipe(map((userPref) => userPref.showOnlyAlarmErrors));
  }

  toggleErrorOnlyView() {
    this.showOnlyAlarmErrors$.pipe(take(1)).subscribe((showOnlyAlarmErrors) => {
      this._siteMonitoringFacade.updateUserPreferences({ showOnlyAlarmErrors: !showOnlyAlarmErrors });
    });
  }
}
