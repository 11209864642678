import { SiteMonitoredValue } from '../../model/site-monitored-value.enum';
import { AggregationType, DisplayType } from '@amp/column-picker';
import { ContentStatus, ContentStatusThemes, ServiceStatusCode, ServiceStatusThemeMap } from '@amp/devices';
import { IStatusCardConfig } from './status-count-card/status-card-config.interface';
import { IKeyMetricViewConfig } from './key-metric-viewer-config.interface';

/** The config for the health summary key metrics */
export const healthSummaryKeyMetricConfig: IKeyMetricViewConfig = {
  data: {
    field: SiteMonitoredValue.HealthStatus,
    compactView: {
      displayType: DisplayType.HealthStatus,
    }
  },
  date: {
    field: SiteMonitoredValue.LastUpdate,
    compactView: {
      displayType: DisplayType.TimeAgo,
      aggregationType: AggregationType.Min,
    }
  },
};

/** The config for the content status key metrics */
export const contentStatusCardConfig: IStatusCardConfig = {
  data: {
    field: SiteMonitoredValue.ContentStatus,
    displayType: DisplayType.ContentStatus,
  },
  date: {
    field: SiteMonitoredValue.LastContentUpdate,
    displayType: DisplayType.TimeAgo,
    aggregationType: AggregationType.Min,
  },
  statuses: [
    {
      name: 'ERROR',
      code: ContentStatus.ERROR,
      theme: ContentStatusThemes[ContentStatus.ERROR],
    },
    {
      name: 'WARNING',
      code: ContentStatus.WARNING,
      theme: ContentStatusThemes[ContentStatus.WARNING],
    },
    {
      name: 'OK',
      code: ContentStatus.OK,
      theme: ContentStatusThemes[ContentStatus.OK],
    },
  ],
};

/** The config for the COD status key metrics */
export const codCardConfig: IStatusCardConfig = {
  data: {
    field: SiteMonitoredValue.CodState,
    displayType: DisplayType.ServiceStatus,
  },
  date: {
    field: SiteMonitoredValue.CodLastUpdate,
    displayType: DisplayType.TimeAgo,
    aggregationType: AggregationType.Min,
  },
  statuses: [
    {
      name: 'ERROR',
      code: ServiceStatusCode.ERROR,
      theme: ServiceStatusThemeMap[ServiceStatusCode.ERROR],
    },
    {
      name: 'OK',
      code: ServiceStatusCode.OK,
      theme: ServiceStatusThemeMap[ServiceStatusCode.OK],
    },
  ],
};

/** The config for the POS status key metrics */
export const posCardConfig: IStatusCardConfig = {
  data: {
    field: SiteMonitoredValue.PosState,
    displayType: DisplayType.ServiceStatus,
  },
  date: {
    field: SiteMonitoredValue.PosLastUpdate,
    displayType: DisplayType.TimeAgo,
    aggregationType: AggregationType.Min,
  },
  statuses: [
    {
      name: 'ERROR',
      code: ServiceStatusCode.ERROR,
      theme: ServiceStatusThemeMap[ServiceStatusCode.ERROR],
    },
    {
      name: 'OK',
      code: ServiceStatusCode.OK,
      theme: ServiceStatusThemeMap[ServiceStatusCode.OK],
    },
  ],
};

/** The config for the system disk key metrics */
export const systemDiskKeyMetricConfig: IKeyMetricViewConfig = {
  data: {
    field: SiteMonitoredValue.SystemDisk,
    displayType: DisplayType.PercentChart,
    aggregationType: AggregationType.Max,
  },
};
