<!-- Existing device -->
<div
  *ngIf="device"
  class="smdc-player has-player"
  [class.active]="!!(activatedDeviceId$ | async) && !!device?.id && device.id === (activatedDeviceId$ | async)"
  [class.inactive]="!!(activatedDeviceId$ | async) && !!device?.id && device.id !== (activatedDeviceId$ | async)"
  [class.disabled]="!editable"
  (click)="onClick()"
  [ngStyle]="{ 'border-color': 'var(--' + OperationalStateThemes[device.deviceInfo.operationalState] + '-base)' }"
>
  <div class="smdc-player-label">
    <span>{{ editable | applyFunction : getLabel : [] : this | transloco }}</span>
    <avn-icon *ngIf="editable" [icon]="'action:delete_forever'" [fontSize]="12" (click)="deleteDeviceInput($event)" (mouseenter)="onShakeLock($event)" (mouseleave)="onShakeLock($event)"></avn-icon>
  </div>
  <ng-container *ngIf="displayInput.deviceId !== null && displayInput.deviceId !== undefined; else emptyDevice">
    <span class="smdc-player-hostname">{{ device?.deviceInfo.hostname || displayInput.deviceId }}</span>
    <span class="smdc-logical-player-id"> {{ ('siteManagementScope.SITE_MANAGEMENT.BOARD_EDITOR.GLOBAL.LOGICAL_PLAYER_20' | transloco) + ' : ' + displayInput.playerId }}</span>
  </ng-container>

  <!-- Input exist but no device connected to it -->
  <ng-template #emptyDevice>
    <span> {{ 'siteManagementScope.SITE_MANAGEMENT.BOARD_EDITOR.GLOBAL.NO_PLAYER_SET_20' | transloco }} </span>
  </ng-template>
</div>

<!-- No device but existing input -->
<div
  *ngIf="!device && displayInput"
  class="smdc-player smdc-add-device"
  [class.inactive]="!!(activatedDeviceId$ | async)"
  [class.disabled]="!editable"
  [ngStyle]="{ 'border-color': 'var(--' + ThemeType.DANGER + '-base)' }"
  (click)="onClick()"
>
  <div class="smdc-player-action">
    <avn-icon *ngIf="editable" [icon]="'action:add'" [fontSize]="12"></avn-icon>
    <avn-icon
      *ngIf="editable && displayInputType !== DisplayInputType.PRIMARY"
      [icon]="'action:delete_forever'"
      [fontSize]="12"
      (click)="deleteDeviceInput($event)"
      (mouseenter)="onShakeLock($event)"
      (mouseleave)="onShakeLock($event)"
    ></avn-icon>
  </div>
  <div class="smdc-player-label">
    <span>{{ editable | applyFunction : getLabel : [] : this | transloco }}</span>
  </div>
  <div class="smdc-add-button">
    <span class="smdcp-text">{{ 'siteManagementScope.SITE_MANAGEMENT.BOARD_EDITOR.GLOBAL.NO_PLAYER_SET_20' | transloco }}</span>
  </div>
</div>

<!-- No device and no input -->
<div *ngIf="!displayInput" class="smdc-player smdc-add-player" [class.inactive]="!!(activatedDeviceId$ | async)" [class.disabled]="!editable" (click)="onClick()">
  <div class="smdc-player-action">
    <avn-icon *ngIf="editable" icon="content:add" [fontSize]="12"></avn-icon>
  </div>
  <div class="smdc-no-input-label">
    <span class="smdcp-text">{{ editable | applyFunction : getLabelForNoInput : [] : this | transloco }}</span>
  </div>
</div>
