import { DeviceHealthCountDTO, DeviceService } from '@activia/cm-api';
import { GlobalFacade } from '@amp/global';
import { TimerService } from '@amp/messenger';
import { Injectable } from '@angular/core';
import {
  BehaviorSubject,
  combineLatest,
  EMPTY, filter,
  Observable,
  ReplaySubject,
  share,
  takeWhile,
  tap
} from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { ErrorHandlingService } from '@amp/error';
import { AsyncDataState, LoadingState } from '@activia/ngx-components';
import { HttpErrorResponse } from '@angular/common/http';

export type IDeviceHealthCount = DeviceHealthCountDTO & { total: number };
export interface IDeviceHealthStatus {
  deviceGroupId: number;
  moduleName: string;
  statusSubject: BehaviorSubject<{ data: IDeviceHealthCount; state: AsyncDataState }>;
}

@Injectable({ providedIn: 'root' })
export class DevicesStatusService {
  devicesStatusState$: BehaviorSubject<AsyncDataState> = new BehaviorSubject<AsyncDataState>(LoadingState.INIT);

  /** Refresh counts of all device groups in ms */
  private _refreshDelay = 5 * 60 * 1000;

  /** Indicate if error occurs while fetching the data */
  private _hasError: BehaviorSubject<boolean> = new BehaviorSubject(false);
  private _timer$: Observable<number> = this.timerService.timer$(this._refreshDelay).pipe(
    takeWhile(() => !this._hasError.value),
  );

  devicesStatus$: Observable<IDeviceHealthCount> = combineLatest([
    this._timer$,
    this.globalFacade.defaultDeviceGroup$.pipe(filter((defaultDeviceGroup) => !!defaultDeviceGroup)),
  ]).pipe(
    tap(() => {
      this.devicesStatusState$.next(LoadingState.LOADING);
    }),
    switchMap(([_, defaultDeviceGroup]) => this.devicesService.getHealthStatusCountForDeviceGroup(defaultDeviceGroup.id).pipe(
        map((_count) => {
          this.devicesStatusState$.next(LoadingState.LOADED);
          return { ..._count, total: _count.ok + _count.warning + _count.error + _count.unreachable + _count.notMonitored };
        }),
        catchError((err: HttpErrorResponse) => {
          if (err.status !== 0) {
            this.errorHandlingService.catchError(err, undefined, 'global.error.fetch-device-group-health-status-fail', { key: defaultDeviceGroup.id });
          }
          this.devicesStatusState$.next({ errorMsg: err.message });
          this._hasError.next(true);
          return EMPTY;
        })
      )),
    share({
      connector: () => new ReplaySubject(1),
      resetOnRefCountZero: true,
      resetOnComplete: false,
      resetOnError: false,
    }),
  );

  constructor(
    private devicesService: DeviceService,
    private globalFacade: GlobalFacade,
    private errorHandlingService: ErrorHandlingService,
    private timerService: TimerService,
  ) {}
}
