import { createFeatureSelector, createSelector } from '@ngrx/store';

import { IGlobalState } from './global.reducer';

const globalSelector = createFeatureSelector<IGlobalState>('global');

export const getIsUserOnline = createSelector(globalSelector, (state) => state.isUserOnline);

export const getUserLanguage = createSelector(globalSelector, (state) => state.lang);
export const getDefaultTimeZone = createSelector(globalSelector, (state) => state.defaultTimeZone);
export const getDeviceGroups = createSelector(globalSelector, (state) => state.deviceGroups.data);
export const getDeviceGroupsDataState = createSelector(globalSelector, (state) => state.deviceGroups.state);
export const getDefaultDeviceGroup = createSelector(globalSelector, (state) => state.defaultDeviceGroup.data);
export const getDefaultDeviceGroupDataState = createSelector(globalSelector, (state) => state.defaultDeviceGroup.state);
export const getSearchResultSize = createSelector(globalSelector, (state) => state.searchResultSize);
export const getEngineVersion = createSelector(globalSelector, (state) => state.engineVersion);
export const getCustomerName = createSelector(globalSelector, (state) => state.customerName);


export const globalQuery = {
  getIsUserOnline,
  getUserLanguage,
  getDefaultTimeZone,
  getDeviceGroups,
  getDeviceGroupsDataState,
  getDefaultDeviceGroup,
  getDefaultDeviceGroupDataState,
  getSearchResultSize,
  getEngineVersion,
  getCustomerName,
};
