import { LoadingState } from '@activia/ngx-components';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { EXPERIENCE_TEMPLATE_FEATURE_KEY, IExperienceTemplateState } from './experience-template.reducer';

// Lookup the 'ExperienceTemplate' feature state managed by NgRx
export const getExperienceTemplateState = createFeatureSelector<IExperienceTemplateState>(EXPERIENCE_TEMPLATE_FEATURE_KEY);

export const getExperienceTemplates = createSelector(getExperienceTemplateState, (state) => state.experienceTemplates.data);

export const getExperienceTemplatesDataState = createSelector(getExperienceTemplateState, (state) => state.experienceTemplates.state);

export const getExperienceTemplatesList = createSelector(getExperienceTemplates, (experienceTemplates) => Object.values(experienceTemplates));

export const isExperienceTemplatesLoading = createSelector(getExperienceTemplateState, (state) => state.experienceTemplates.state === LoadingState.LOADING);

export const isExperienceTemplatesLoaded = createSelector(getExperienceTemplateState, (state) => state.experienceTemplates.state === LoadingState.LOADED);
