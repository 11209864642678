/**
 * Enum that represents display types for a column
 */
export enum DisplayType {
  Boolean = 'BOOLEAN', // true = 'true', false = 'false'
  Currency = 'CURRENCY',
  Numeric = 'NUMERIC',
  Percent = 'PERCENT',
  PercentChart = 'PERCENT_CHART',
  CountPieChart = 'COUNT_PIE_CHART',
  PercentPieChart = 'PERCENT_PIE_CHART',
  PlayerVersion = 'PLAYER_VERSION',
  Raw = 'RAW',
  RawWithTooltip = 'RAW_WITH_TOOLTIP',
  HostnameIp = 'HOSTNAME_IP',
  HealthStatus = 'HEALTH_STATUS',
  HealthErrorIds = 'HEALTH_ERROR_IDS',
  OperationalState = 'OPERATIONAL_STATE',
  ServiceStatus = 'SERVICE_STATUS', // true = up, false = down
  Status = 'STATUS', // 0 = Error, 1 = Ok
  TwoDecimals = 'TWO_DECIMALS',
  TwoLines = 'TWO_LINES',
  DateTime = 'DATETIME',
  TimeAgo = 'TIME_AGO',
  Duration = 'DURATION',
  DevicePropertiesStatus = 'DEVICE_PROPERTIES_STATUS',
  ContentState = 'CONTENT_STATE',
  ContentStatus = 'CONTENT_STATUS',
  ContentStatusErrorIds = 'CONTENT_STATUS_ERROR_IDS',
}
