import { SiteMonitoringFacade } from '../store/site-monitoring.facade';
import { Observable } from 'rxjs';
import { first, map, tap } from 'rxjs/operators';
import { LoadingState } from '@activia/ngx-components';
import { Store } from '@ngrx/store';
import { FetchBoardOrgPathDefinition, selectBoardOrgPathDefinitionState } from '@amp/tag-operation';

export const preloadProfile = (_siteMonitoringFacade: SiteMonitoringFacade): Observable<any> =>
  _siteMonitoringFacade.profileDataState$.pipe(
    first(),
    map((state) => state !== LoadingState.LOADING && state !== LoadingState.LOADED),
    tap((shouldFetch) => {
      if (shouldFetch) {
        _siteMonitoringFacade.fetchProfile();
      }
    }),
    map((_) => _)
  );

export const preloadColumnNameOverrides = (_siteMonitoringFacade: SiteMonitoringFacade): Observable<any> =>
  _siteMonitoringFacade.columnNameOverridesDataState$.pipe(
    first(),
    map((state) => state !== LoadingState.LOADING && state !== LoadingState.LOADED),
    tap((shouldFetch) => {
      if (shouldFetch) {
        _siteMonitoringFacade.fetchColumnNameOverrides();
      }
    }),
    map((_) => _)
  );

export const preloadKeyMetrics = (_siteMonitoringFacade: SiteMonitoringFacade): Observable<any> =>
  _siteMonitoringFacade.keyMetricsDataState$.pipe(
    first(),
    map((state) => state !== LoadingState.LOADING && state !== LoadingState.LOADED),
    tap((shouldFetchKeyMetrics) => {
      if (shouldFetchKeyMetrics) {
        _siteMonitoringFacade.fetchKeyMetrics();
      }
    }),
    map((_) => _)
  );

export const preloadBoardOrgPathDefinition = (_store: Store): Observable<any> =>
  _store.select(selectBoardOrgPathDefinitionState).pipe(
    first(),
    tap((state) => {
      if (state !== LoadingState.LOADING && state !== LoadingState.LOADED) {
        _store.dispatch(FetchBoardOrgPathDefinition());
      }
    }),
    map((_) => _)
  );
