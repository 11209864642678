/**
 * ALl possible commands/actions that can be run on a device / device group
 */
export enum DeviceActionCommandEnum {
  StartPlayback = 'StartPlayback',
  StopPlayback = 'StopPlayback',
  RestartPlayback = 'RestartPlayback',
  FlushAssets = 'FlushAssets',
  RebootDevice = 'RebootDevice',
  ShutdownDevice = 'ShutdownDevice',
  SetDns = 'SetDns',
  SetTimezone = 'SetTimezone',
  SetNtp = 'SetNtp',
  Sync = 'Sync',
  SetDate = 'SetDate',
  SetAudioLevel = 'SetAudioLevel',
  DisplayOn = 'DisplayOn',
  DisplayOff = 'DisplayOff',
  Mute = 'Mute',
  Unmute = 'Unmute',
  UpdateDeviceProperties = 'UpdateDeviceProperties',
}
