import { IDataTableColumnConfig } from '@activia/ngx-components';
import { Injectable, TemplateRef } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { map, Observable, Subject } from 'rxjs';
import { PlayerColumn } from '../models/player-column';
import { DEVICE_PROPERTIES_MAP, DeviceProperty } from '@amp/devices';

@Injectable({
  providedIn: 'root',
})
export class PlayerColumnService {
  /**
   * All columns available to be shown in the table of media player, without templates
   */
  allPlayerColumns: Map<string, IDataTableColumnConfig<void>>;

  /**
   * Internal storage of templates
   */
  private _templatesSubject$: Subject<Map<string, [TemplateRef<void>, TemplateRef<void>]>> = new Subject();

  /**
   * @param cols - optional list of columns to show. Omitting this param returns all cols.
   */
  getFormattedPlayerColumns$(cols?: PlayerColumn[], prefix = ''): Observable<IDataTableColumnConfig<void>[]> {
    return this._templatesSubject$.pipe(
      map((templates) => {
        let applicableCols = cols ? (Object.values(cols) as string[]) : [];

        if (!cols || cols.length === 0) {
          applicableCols = Array.from(this.allPlayerColumns.values()).map((v) => v.id);
        }
        const reqCol: IDataTableColumnConfig<any>[] = [];
        applicableCols.forEach((col) => {
          if (this.allPlayerColumns.has(col)) {
            const colDef = this.allPlayerColumns.get(col);
            if (templates && templates.has(col)) {
              colDef.dataCellTemplate = templates.get(col)[0];
            }
            reqCol.push({ ...colDef, prop: `${prefix}${colDef.prop}` });
          }
        });
        return reqCol;
      })
    );
  }

  constructor(private translateService: TranslocoService) {
    this.allPlayerColumns = this._initSiteCols();
  }

  /**
   * @param templateRefs map of templates per PlayerColumn [cell, headers]
   */
  registerTemplates(templateRefs?: Map<PlayerColumn, [TemplateRef<void>, TemplateRef<void>]>): void {
    this._templatesSubject$.next(templateRefs);
  }

  private _initSiteCols(): Map<string, IDataTableColumnConfig<void>> {
    const defaultCol = {
      sortable: false,
      resizable: true,
      draggable: true,
    };
    const hostnameIpCol: IDataTableColumnConfig<any> = {
      ...defaultCol,
      id: PlayerColumn.HOSTNAME_IP,
      name: this.translateService.translate('deviceFields.DEVICE.PROPERTY.HOSTNAME_IP.NAME'),
      prop: DEVICE_PROPERTIES_MAP.get(DeviceProperty.HostnameIp),
    };
    const hostnameIpWithLinkCol: IDataTableColumnConfig<any> = {
      ...defaultCol,
      id: PlayerColumn.HOSTNAME_IP_WITH_LINK,
      name: this.translateService.translate('deviceFields.DEVICE.PROPERTY.HOSTNAME_IP.NAME'),
      prop: DEVICE_PROPERTIES_MAP.get(DeviceProperty.HostnameIp),
    };
    const modelCol: IDataTableColumnConfig<any> = {
      ...defaultCol,
      id: PlayerColumn.MODEL,
      prop: DEVICE_PROPERTIES_MAP.get(DeviceProperty.Model),
      widthPx: 130,
    };
    const operationalStateCol: IDataTableColumnConfig<any> = {
      ...defaultCol,
      id: PlayerColumn.OPERATIONAL_STATE,
      prop: DEVICE_PROPERTIES_MAP.get(DeviceProperty.OperationalState),
      widthPx: 170,
    };
    const serialMacCol: IDataTableColumnConfig<any> = {
      ...defaultCol,
      id: PlayerColumn.SERIAL_MAC,
    };

    const columns = [
      hostnameIpCol,
      hostnameIpWithLinkCol,
      modelCol,
      operationalStateCol,
      serialMacCol,
    ];

    const columnMap = new Map<string, IDataTableColumnConfig<void>>(columns.map((x) => [x.id, x]));
    return columnMap;
  }
}
