import { Pipe, PipeTransform } from '@angular/core';
import { AggregationType } from '@amp/column-picker';

@Pipe({ name: 'aggregation' })
export class AggregationPipe implements PipeTransform {
  transform(value: any, aggregationType: AggregationType): any {
    if (!aggregationType || !value) {
      return value;
    }

    if (aggregationType === AggregationType.Min) {
      return value.min ?? value;
    }
    if (aggregationType === AggregationType.Max) {
      return value.max ?? value;
    }
    if (aggregationType === AggregationType.Average) {
      return value.avg ?? value;
    }
    return value;
  }
}
