export enum SharedDeviceColumn {
  NAME = 'NAME',
  DESCRIPTION = 'DESCRIPTION',
  NAME_DESCRIPTION = 'NAME_DESCRIPTION',
  COLUMN_COUNT = 'COLUMN_COUNT',
  DEVICE_COUNT = 'DEVICE_COUNT',
  OWNER = 'OWNER',
  ACTIVE = 'ACTIVE',
}

/** Columns if shown in device monitoring */
export const DEVICE_MONITORING_SHARED_LIST_COLS = [
  SharedDeviceColumn.NAME_DESCRIPTION,
  SharedDeviceColumn.DEVICE_COUNT,
  SharedDeviceColumn.OWNER,
  SharedDeviceColumn.ACTIVE,
];

/** Columns if shown in site monitoring config */
export const SITE_MONITORING_CONFIG_SHARED_LIST_COLS = [
  SharedDeviceColumn.NAME,
  SharedDeviceColumn.DESCRIPTION,
  SharedDeviceColumn.COLUMN_COUNT,
  SharedDeviceColumn.DEVICE_COUNT,
];

/** Columns if shown in site monitoring dashboard */
export const SITE_MONITORING_DASHBOARD_SHARED_LIST_COLS = [
  SharedDeviceColumn.NAME_DESCRIPTION,
  SharedDeviceColumn.DEVICE_COUNT,
];
