import { DisplayDTO } from '@activia/cm-api';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import * as DisplayActions from './display.actions';
import * as BoardActions from '../board/board.actions';
import * as SiteActions from '../site-management.actions';
import { DisplayInputType } from '../../models/player-unit.enum';

export const DISPLAY_FEATURE_KEY = 'display';

export const adapter: EntityAdapter<DisplayDTO> = createEntityAdapter<DisplayDTO>({
  sortComparer: (a: DisplayDTO, b: DisplayDTO) => a.boardScreenIdx - b.boardScreenIdx,
});

export interface DisplayState extends EntityState<DisplayDTO> {
  currentSiteId: number;
  temporaryDeletedDisplays: Record<number, DisplayDTO>; // Optimistic delete
  temporaryUpdatedDisplays: Record<number, DisplayDTO>; // Optimistic update
}

export const initialState: DisplayState = adapter.getInitialState({
  currentSiteId: undefined,
  temporaryDeletedDisplays: {},
  temporaryUpdatedDisplays: {},
});

export const reducer = createReducer(
  initialState,
  //#region FetchSiteDetail
  on(SiteActions.FetchSiteDetailSuccess, (state, action) => ({
    ...adapter.setAll(action.displays, state),
    currentSiteId: action.site.id,
  })),
  //#endregion

  //#region CreateTemplateBoards
  on(SiteActions.CreateTemplateBoardsSuccess, (state, action) => {
    // Check if the updated site is the current site
    if (action.site.id === state.currentSiteId) {
      const addedDisplays = action.displays;

      return {
        ...adapter.addMany(addedDisplays, state),
      };
    } else {
      // Do nothing if we're not editing the current site
      return state;
    }
  }),
  //#endregion

  //#region AddDisplay
  on(DisplayActions.AddDisplaySuccess, (state, action) => {
    // All displays has been replaced. Delete the old ones.
    const removedDisplayIds = Object.values(state.entities)
      .filter((display) => display.parentBoardId === action.boardId)
      .map((display) => display.id);

    return {
      ...adapter.addMany(action.displays, adapter.removeMany(removedDisplayIds, state)), // replace old displays with new one
    };
  }),
  //#endregion

  //#region DeleteDisplay
  on(DisplayActions.DeleteDisplay, (state, action) => ({
    ...adapter.removeOne(action.displayId, state), // Optimistic delete
    temporaryDeletedDisplays: {
      ...state.temporaryDeletedDisplays,
      [action.displayId]: { ...state.entities[action.displayId] }, // Temporary deleted display saved in case it fails
    },
  })),

  on(DisplayActions.DeleteDisplaySuccess, (state, action) => {
    // Delete succeded so remove deleted board from backup cache
    const { [action.displayId]: deletedDisplay, ...temporaryDeletedDisplays } = state.temporaryDeletedDisplays;

    // All displays has been replaced. Delete the old ones.
    const removedDisplayIds = Object.values(state.entities)
      .filter((display) => display.parentBoardId === deletedDisplay.parentBoardId)
      .map((display) => display.id);

    return {
      ...adapter.addMany(action.displays, adapter.removeMany(removedDisplayIds, state)), // replace old displays with new one
      temporaryDeletedDisplays,
    };
  }),

  on(DisplayActions.DeleteDisplayFail, (state, action) => {
    // Delete failed so remove deleted board from backup cache and add it back to entities
    const { [action.displayId]: failedDeletedDisplay, ...temporaryDeletedDisplays } = state.temporaryDeletedDisplays;

    return {
      ...adapter.addOne(failedDeletedDisplay, state),
      temporaryDeletedDisplays,
      status: { errorMsg: action.error },
    };
  }),
  //#endregion

  //#region UpdateDisplayName
  on(DisplayActions.UpdateDisplayName, (state, action) => ({
    ...adapter.updateOne({ id: action.displayId, changes: { name: action.displayName.trim() } }, state), // Optimistic update
    temporaryUpdatedDisplays: {
      ...state.temporaryUpdatedDisplays,
      [action.displayId]: state.entities[action.displayId],
    },
  })),

  on(DisplayActions.UpdateDisplayNameSuccess, (state, action) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { [action.displayId]: updatedDisplay, ...temporaryUpdatedDisplays } = state.temporaryUpdatedDisplays;

    return {
      ...state,
      temporaryUpdatedDisplays,
    };
  }),

  on(DisplayActions.UpdateDisplayNameFail, (state, action) => {
    const { [action.displayId]: updatedDisplay, ...temporaryUpdatedDisplays } = state.temporaryUpdatedDisplays;

    return {
      ...adapter.updateOne({ id: action.displayId, changes: { ...updatedDisplay } }, state),
      temporaryUpdatedDisplays,
    };
  }),
  //#endregion

  //#region ChangeDisplayOrientation
  on(DisplayActions.ChangeDisplayOrientation, (state, action) => ({
    ...adapter.updateOne(
      {
        id: action.displayId,
        changes: {
          geometry: {
            ...state.entities[action.displayId].geometry,
            orientation: action.orientation,
          },
        },
      },
      state
    ), // Optimistic update
    temporaryUpdatedDisplays: {
      ...state.temporaryUpdatedDisplays,
      [action.displayId]: state.entities[action.displayId],
    },
  })),

  on(DisplayActions.ChangeDisplayOrientationSuccess, (state, action) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { [action.displayId]: updatedDisplay, ...temporaryUpdatedDisplays } = state.temporaryUpdatedDisplays;

    return {
      ...state,
      temporaryUpdatedDisplays,
    };
  }),

  on(DisplayActions.ChangeDisplayOrientationFail, (state, action) => {
    const { [action.displayId]: updatedDisplay, ...temporaryUpdatedDisplays } = state.temporaryUpdatedDisplays;

    return {
      ...adapter.updateOne({ id: action.displayId, changes: { ...updatedDisplay } }, state),
      temporaryUpdatedDisplays,
    };
  }),
  //#endregion

  //#region SetDeviceToDisplay
  on(DisplayActions.SetDeviceToDisplay, (state, action) => {
    // Attach device to input of the display
    const inputs = [...state.entities[action.displayId].inputs];
    inputs[action.displayInputType] = {
      deviceId: action.device.id,
      output: action.output,
      playerId: action.playerId,
    };

    return {
      ...adapter.updateOne({ id: action.displayId, changes: { inputs } }, state), // Optimistic update
      temporaryUpdatedDisplays: {
        ...state.temporaryUpdatedDisplays,
        [action.displayId]: state.entities[action.displayId],
      },
    };
  }),

  on(DisplayActions.SetDeviceToDisplaySuccess, (state, action) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { [action.displayId]: updatedDisplay, ...temporaryUpdatedDisplays } = state.temporaryUpdatedDisplays;

    return {
      ...state,
      temporaryUpdatedDisplays,
    };
  }),

  on(DisplayActions.SetDeviceToDisplayFail, (state, action) => {
    const { [action.displayId]: updatedDisplay, ...temporaryUpdatedDisplays } = state.temporaryUpdatedDisplays;

    return {
      ...adapter.updateOne({ id: action.displayId, changes: { ...updatedDisplay } }, state),
      temporaryUpdatedDisplays,
    };
  }),
  //#endregion

  //#region RemoveDeviceToDisplay
  on(DisplayActions.RemoveDeviceFromDisplay, (state, action) => {
    // Attach device to input of the display
    let inputs = [...state.entities[action.displayId].inputs];

    if (action.displayDevice === DisplayInputType.PRIMARY) {
      // it is not possible to delete the input completely for PRIMARY
      inputs[DisplayInputType.PRIMARY] = {
        deviceId: undefined,
        output: undefined,
        playerId: undefined,
      };
    } else {
      inputs = [state.entities[action.displayId].inputs[0]];
    }

    return {
      ...adapter.updateOne({ id: action.displayId, changes: { inputs } }, state), // Optimistic update
      temporaryUpdatedDisplays: {
        ...state.temporaryUpdatedDisplays,
        [action.displayId]: state.entities[action.displayId],
      },
    };
  }),

  on(DisplayActions.RemoveDeviceFromDisplaySuccess, (state, action) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { [action.displayId]: updatedDisplay, ...temporaryUpdatedDisplays } = state.temporaryUpdatedDisplays;

    return {
      ...state,
      temporaryUpdatedDisplays,
    };
  }),

  on(DisplayActions.RemoveDeviceFromDisplayFail, (state, action) => {
    const { [action.displayId]: updatedDisplay, ...temporaryUpdatedDisplays } = state.temporaryUpdatedDisplays;

    return {
      ...adapter.updateOne({ id: action.displayId, changes: { ...updatedDisplay } }, state),
      temporaryUpdatedDisplays,
    };
  }),
  //#endregion

  //#region RemoveInputToDisplay
  on(DisplayActions.RemoveInputFromDisplay, (state, action) => {
    // Attach device to input of the display
    const inputs = [...state.entities[action.displayId].inputs];

    inputs[action.displayDevice] = {
      deviceId: undefined,
      output: undefined,
      playerId: undefined,
    };

    return {
      ...adapter.updateOne({ id: action.displayId, changes: { inputs } }, state), // Optimistic update
      temporaryUpdatedDisplays: {
        ...state.temporaryUpdatedDisplays,
        [action.displayId]: state.entities[action.displayId],
      },
    };
  }),
  //#endregion

  //#region Board changes
  on(BoardActions.UpdateBoardSuccess, (state, action) => {
    const oldDisplays = Object.values(state.entities)
      .filter((e) => e.parentBoardId === action.boardId)
      .map((e) => e.id);

    return {
      // Remove all previous displays attached to board, then add new one
      ...adapter.addMany(action.displays, adapter.removeMany(oldDisplays, state)),
    };
  }),

  on(BoardActions.UpdateBoardSizeSuccess, (state, action) => {
    const oldDisplays = Object.values(state.entities)
      .filter((e) => e.parentBoardId === action.board.id)
      .map((e) => e.id);

    return {
      // Remove all previous displays attached to board, then add new one
      ...adapter.addMany(action.displays, adapter.removeMany(oldDisplays, state)),
    };
  }),

  //#endregion

  //#region Add Board
  on(BoardActions.AddBoardSuccess, (state, action) => adapter.addMany(action.displays, state)),
  //#endregion

  //#region Delete Board
  on(BoardActions.DeleteBoardSuccess, (state, action) => {
    const oldDisplays = Object.values(state.entities)
      .filter((e) => e.parentBoardId === action.boardId)
      .map((e) => e.id);

    return {
      // Remove all deleted displays attached to board
      ...adapter.removeMany(oldDisplays, state),
    };
  })
  //#endregion
);
