/**
 * Enum that represents all the key metrics available for the site monitoring module
 * Key metrics are combinations of 1 or more monitored values together to create a 'widget' or a component that gives
 * a more intuitive / quicker overview of a group of variables (e.g. combine 'content status' + 'content last updated date' into one
 * widget)
 * https://confluence.stratacache.com/display/CM/AMP+Monitoring+Values
 * https://confluence.stratacache.com/display/CM/PoS+Monitoring+Values
 */
export enum SiteMonitoringKeyMetric {
  HealthStatusSummary = 'HEALTH_STATUS_SUMMARY',
  ContentStatus = 'CONTENT_STATUS',
  Services = 'SERVICES', // only show errors
  CodState = 'COD_STATE',
  PosState = 'POS_STATE',
  SystemDisk = 'SYSTEM_DISK',
  EnclosureStatus = 'ENCLOSURE_STATUS',
}
