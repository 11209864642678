import { ChangeDetectionStrategy, Component, OnInit, ViewChild } from '@angular/core';
import { EngineTagValueDetailComponent, TagValueAssignmentScope } from '@amp/tag-operation';
import { ISaveBeforeExit } from '../../../../guards/save-before-exit.guard';
import { map, Observable } from 'rxjs';
import { withLatestFrom } from 'rxjs/operators';
import { RouterFacade } from '@amp/router-store';
import { Store } from '@ngrx/store';
import { ISiteManagementState } from '../../../../store/site-management.reducer';
import { dataOnceReady } from '@activia/ngx-components';
import { siteManagementEntities } from '../../../../store/site-management.selectors';
import { IBoard } from '../../../../models/board-config.interface';
import { Dictionary } from '@ngrx/entity';
import { DisplayDTO } from '@activia/cm-api';
import * as BoardSelectors from '../../../../store/board/board.selectors';
import { BoardState } from '../../../../store/board/board.reducer';
import { DisplayState } from '../../../../store/display/display.reducer';
import * as DisplaySelectors from '../../../../store/display/display.selectors';
import { mapScreens } from '../../../../utils/display.utils';
import { SiteManagementService } from '../../../../services/site-management.service';

@Component({
  selector: 'amp-screen-tag-assignment-container',
  templateUrl: './screen-tag-assignment-container.component.html',
  styleUrls: ['./screen-tag-assignment-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ScreenTagAssignmentContainerComponent implements OnInit, ISaveBeforeExit {
  /** Component */
  @ViewChild(EngineTagValueDetailComponent) detailComponent: EngineTagValueDetailComponent;

  /** Tag assignment scope */
  scope$: Observable<TagValueAssignmentScope>;
  site$ = dataOnceReady(this._store.pipe(siteManagementEntities.currSiteData$), this._store.pipe(siteManagementEntities.currSiteDataState$));
  board$: Observable<IBoard> = this._boardStore.select(BoardSelectors.selectedCurrentBoard);
  displayList$: Observable<Dictionary<DisplayDTO>> = this._displayStore.select(DisplaySelectors.selectDisplayEntities);

  /** If there is unsaved changes in the tag editor */
  changeDetails: { hasUnsavedChanges: boolean; hasInvalidChanges?: boolean };

  editable$: Observable<boolean>;

  constructor(
    private _routerFacade: RouterFacade,
    private _store: Store<ISiteManagementState>,
    private _boardStore: Store<BoardState>,
    private _displayStore: Store<DisplayState>,
    private _siteManagementService: SiteManagementService,
  ) {
    this.editable$ = this._siteManagementService.hasAuthority$('tag');
  }

  ngOnInit() {
    this.scope$ = this._routerFacade.currentRoute$.pipe(
      withLatestFrom(this.board$, this.displayList$),
      map(([route, board, displays]) => {
        const screens = Object.values(displays);
        const screenName = route.params.screenName;
        const displayId = screens.find((screen) => screen.parentBoardId === board.id && screen.name === screenName)?.id;
        return mapScreens(board, screens, displays[displayId]?.name);
      })
    );
  }

  getChangeDetails(): { hasUnsavedChanges: boolean; hasInvalidChanges?: boolean } {
    return this.changeDetails;
  }

  save(): Observable<boolean> {
    return this.detailComponent.onPushChanges();
  }
}
