import { DisplayDTO } from '@activia/cm-api';
import { getCurrentRoute } from '@amp/router-store';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { selectedCurrentBoard } from '../board/board.selectors';
import * as fromDisplay from './display.reducer';

export const selectDisplayState = createFeatureSelector<fromDisplay.DisplayState>(fromDisplay.DISPLAY_FEATURE_KEY);

const { selectIds, selectEntities, selectAll, selectTotal } = fromDisplay.adapter.getSelectors();

export const selectDisplayIds = createSelector(selectDisplayState, selectIds);
export const selectDisplayEntities = createSelector(selectDisplayState, selectEntities);
export const selectAllDisplays = createSelector(selectDisplayState, selectAll);
export const selectDisplayTotal = createSelector(selectDisplayState, selectTotal);

/** Calculate and return a dictionary <id, number of usage> of all devices used by display */
export const selectDeviceUsage = createSelector(selectAllDisplays, (displays) => {
  const usage: Record<number, { count: number; boardId: number }> = {};

  displays.forEach((display) => {
    display.inputs.forEach((input) => {
      if (input.deviceId) {
        if (usage[input.deviceId]) {
          usage[input.deviceId].count++;
        } else {
          usage[input.deviceId] = { count: 1, boardId: display.parentBoardId };
        }
      }
    });
  });

  return usage;
});

export const selectedCurrentDisplay = createSelector(getCurrentRoute, selectedCurrentBoard, selectAllDisplays, (router, board, displays): DisplayDTO => {
  if (router.data.module === 'site-management') {
    return displays.find((e) => e.name === router.params.screenName && e.parentBoardId === board?.id) || displays.find((e) => e.id === +router.params.siteId);
  }
});

export const selectTemporaryUpdatedDisplays = createSelector(selectDisplayState, (state: fromDisplay.DisplayState) => state.temporaryUpdatedDisplays);
