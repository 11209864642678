import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ISiteMonitoringState } from './site-monitoring.reducer';
import { LoadingState } from '@activia/ngx-components';

const siteMonitoringSelector = createFeatureSelector<ISiteMonitoringState>('siteMonitoring');

/** Returns the profile to be used in the site monitoring module **/
const getProfile = createSelector(siteMonitoringSelector, (state) => state.profile.data);
const getProfileDataState = createSelector(siteMonitoringSelector, (state) => state.profile.state);

const getSiteMonitoringPreference = createSelector(siteMonitoringSelector, (state) => state.siteMonitoringPreference.data);
const getSiteMonitoringPreferenceDataState = createSelector(siteMonitoringSelector, (state) => state.siteMonitoringPreference.state);

/** Returns the total amount of sites that the user has access to **/
const getSitesCount = createSelector(siteMonitoringSelector, (state) => state.sitesCount.data);
const getSitesCountDataState = createSelector(siteMonitoringSelector, (state) => state.sitesCount.state);

/** Returns the site list datasource **/
export const getSiteListDataSource = createSelector(siteMonitoringSelector, (state) => state.siteList.dataSource.data);
export const getSiteListDataState = createSelector(siteMonitoringSelector, (state) => state.siteList.dataSource.state);

export const isSiteListDataSourceInitiated = createSelector(siteMonitoringSelector, (state) => state.siteList.dataSource.state !== LoadingState.INIT && state.siteList.dataSource.data !== null);
export const isSiteListDataSourceFullyLoaded = createSelector(
  siteMonitoringSelector,
  (state) => state.siteList.dataSource.state === LoadingState.LOADED && state.siteList.dataSource.data.data.length === state.siteList.dataSource.data.total
);

/** Returns the selected site to view in the detail page **/
export const getSiteListDataSourceTimeMsBeforeRefresh = createSelector(siteMonitoringSelector, (state) => {
  const lastAttempt = state.siteList.dataSource.lastAttempt;
  const refreshIntervalMs = state.refreshIntervalMs;
  if (!lastAttempt) {
    return 0;
  }
  const diffMs = new Date().getTime() - lastAttempt.getTime();

  return diffMs >= refreshIntervalMs ? 0 : refreshIntervalMs - diffMs;
});

/**
 * Returns the interval for periodic data refresh in the site monitoring module. Used to refresh:
 * - health status counts
 * - site list datasource
 * - site detail boards and screenshots
 * **/
export const getDataRefreshIntervalMs = createSelector(siteMonitoringSelector, (state) => state.refreshIntervalMs);

/** Returns the current site list search text filter **/
export const getSiteListFilteredSites = createSelector(siteMonitoringSelector, (state) => state.siteList.filteredSiteIds.data);
export const getSiteListFilteredSitesDataState = createSelector(siteMonitoringSelector, (state) => state.siteList.filteredSiteIds.state);

/** Returns the sort field and sort order for the site list **/
export const getSiteListSort = createSelector(siteMonitoringSelector, (state) => state.siteList.sort);

/** Returns the site being viewed in the site detail page **/
const getDetailSite = createSelector(siteMonitoringSelector, (state) => state.detailSite.data);
const getDetailSiteDataState = createSelector(siteMonitoringSelector, (state) => state.detailSite.state);

/** Returns the site list columns **/
const getSiteListColumns = createSelector(siteMonitoringSelector, (state) => state.siteList.columns.data);
const getSiteListColumnsDataState = createSelector(siteMonitoringSelector, (state) => state.siteList.columns.state);

/** Returns the key metrics **/
const getKeyMetrics = createSelector(siteMonitoringSelector, (state) => state.keyMetrics.data);
const getKeyMetricsDataState = createSelector(siteMonitoringSelector, (state) => state.keyMetrics.state);

/** Returns the key metrics **/
const getColumnNameOverrides = createSelector(siteMonitoringSelector, (state) => state.columnNameOverrides.data);
const getColumnNameOverridesDataState = createSelector(siteMonitoringSelector, (state) => state.columnNameOverrides.state);

/** Recent / fav sites **/
const getRecentSites = createSelector(siteMonitoringSelector, (state) => state.recentSites.data);
const getRecentSitesDataState = createSelector(siteMonitoringSelector, (state) => state.recentSites.state);
const getStarredSites = createSelector(siteMonitoringSelector, (state) => state.starredSites.data);
const getStarredSitesDataState = createSelector(siteMonitoringSelector, (state) => state.starredSites.state);
const getRecentSitesMax = createSelector(siteMonitoringSelector, (state) => state.recentSitesMax);
const getStarredSitesMax = createSelector(siteMonitoringSelector, (state) => state.starredSitesMax);
const isStarredSitesMaxReached = createSelector(siteMonitoringSelector, (state) => state.starredSites.data.length >= state.starredSitesMax);

/** */
const getHealthStatusSiteCount = createSelector(siteMonitoringSelector, (state) => state.healthStatusCount.data);
const getHealthStatusSiteCountState = createSelector(siteMonitoringSelector, (state) => state.healthStatusCount.state);

/** Return user preferences */
const getSiteMonitoringUserPreferences = createSelector(siteMonitoringSelector, (state) => state.userPreferences.data);
const getSiteMonitoringUserPreferencesDataState = createSelector(siteMonitoringSelector, (state) => state.userPreferences.state);

export const siteMonitoringQuery = {
  getProfile,
  getProfileDataState,
  getSitesCount,
  getSitesCountDataState,
  getSiteListSort,
  getSiteListDataSource,
  getSiteListDataState,
  isSiteListDataSourceInitiated,
  isSiteListDataSourceFullyLoaded,
  getSiteListDataSourceTimeMsBeforeRefresh,
  getDataRefreshIntervalMs,
  getSiteListFilteredSites,
  getSiteListFilteredSitesDataState,
  getDetailSite,
  getDetailSiteDataState,
  getSiteListColumns,
  getSiteListColumnsDataState,
  getKeyMetrics,
  getKeyMetricsDataState,
  getColumnNameOverrides,
  getColumnNameOverridesDataState,
  getRecentSites,
  getRecentSitesDataState,
  getRecentSitesMax,
  getStarredSites,
  getStarredSitesDataState,
  getStarredSitesMax,
  isStarredSitesMaxReached,
  getHealthStatusSiteCount,
  getHealthStatusSiteCountState,
  getSiteMonitoringPreference,
  getSiteMonitoringPreferenceDataState,
  getSiteMonitoringUserPreferences,
  getSiteMonitoringUserPreferencesDataState,
};
