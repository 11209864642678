import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { OnInit } from '@angular/core';
import { OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { AuthFacade } from './store/auth.facade';
import { hasUserPermission } from './has-any-permission.utils';

/**
 * @whatItDoes Conditionally includes an HTML element if current user has any
 * of the permissions passed as the `expression`.
 *
 * @howToUse
 * ```
 *     <some-element *ampHasAnyAuthority="'ROLE_ADMIN'">...</some-element>
 *
 *     <some-element *ampHasAnyAuthority="['ROLE_ADMIN', 'ROLE_USER']">...</some-element>
 * ```
 */
@Directive({
  selector: '[ampHasAnyPermissions]',
})
export class HasAnyPermissionDirective implements OnInit, OnDestroy {
  private permissions: string[];
  private userPermissions: string[] = [];
  private componentDestroyed$: Subject<void> = new Subject<void>();

  constructor(private authFacade: AuthFacade, private templateRef: TemplateRef<any>, private viewContainerRef: ViewContainerRef) {}

  @Input()
  set ampHasAnyPermissions(value: string | string[]) {
    this.permissions = typeof value === 'string' ? [value as string] : value as string[];
    this.updateView();
  }

  ngOnInit() {
    this.authFacade.authenticatedUserPermissions$.pipe(takeUntil(this.componentDestroyed$)).subscribe((permissions: string[]) => {
      this.userPermissions = permissions ? [...permissions] : [];
      this.updateView();
    });
  }

  ngOnDestroy() {
    this.componentDestroyed$.next();
    this.componentDestroyed$.complete();
  }

  private updateView(): void {
    if (hasUserPermission(this.permissions, this.userPermissions)) {
      this.viewContainerRef.clear();
      this.viewContainerRef.createEmbeddedView(this.templateRef);
    } else {
      this.viewContainerRef.clear();
    }
  }
}
