import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import * as BoardOrgPathDefinitionActions from './board-org-path-definition.actions';
import { BoardsService, BoardTagsService } from '@activia/cm-api';
import { MessengerNotificationService } from '@amp/messenger';
import { IOrgPathDefNode, IOrgPathDefRoot } from '../../model/board-org-path-definition.interface';
import { IJsonSchema } from '@activia/json-schema-forms';
import { getAllUsedTagsInBoardOrgPathDefinition, getBoardTagsDefinitions } from '../../..';

@Injectable()
export class BoardOrgPathDefinitionEffects {
  /**
   * Fetch the board org path definition and all tags key associated with the definition
   */
  fetchBoardOrgPathDefinition$ = createEffect(() =>
    this._actions$.pipe(
      ofType(BoardOrgPathDefinitionActions.FetchBoardOrgPathDefinition),
      switchMap(() => this._boardService.getBoardLocation()),
      switchMap((boardOrgPathDef: IOrgPathDefRoot) => {
        const tags = getAllUsedTagsInBoardOrgPathDefinition(boardOrgPathDef.root as IOrgPathDefNode);

        return getBoardTagsDefinitions(this._boardTagsService, tags).pipe(map((tagsKey) => [boardOrgPathDef, tagsKey] as [IOrgPathDefRoot, Record<string, IJsonSchema>]));
      }),
      map(([boardOrgPathDefinition, tagKeys]) =>
        BoardOrgPathDefinitionActions.FetchBoardOrgPathDefinitionSuccess({
          boardOrgPathDefinition,
          tagKeys,
        })
      ),
      catchError((err) => {
        this._messengerNotificationService.showErrorMessage('siteManagementScope.SITE_MANAGEMENT.GLOBAL.ERROR.FETCH_BOARD_JSON_SCHEMA_FAIL_150');
        return of(BoardOrgPathDefinitionActions.FetchBoardOrgPathDefinitionFail({ error: err }));
      })
    )
  );

  /**
   * Save the board org path definition
   */
  saveBoardOrgPathDefinition$ = createEffect(() =>
    this._actions$.pipe(
      ofType(BoardOrgPathDefinitionActions.SaveBoardOrgPathDefinition),
      switchMap((action) => this._boardService.updateBoardsOrgPath(action.boardOrgPathDefinition).pipe(map(() => action.boardOrgPathDefinition))),
      switchMap((boardOrgPathDef: IOrgPathDefRoot) => {
        const tags = getAllUsedTagsInBoardOrgPathDefinition(boardOrgPathDef.root as IOrgPathDefNode);

        return getBoardTagsDefinitions(this._boardTagsService, tags).pipe(map((tagsKey) => [boardOrgPathDef, tagsKey] as [IOrgPathDefRoot, Record<string, IJsonSchema>]));
      }),
      map(([boardOrgPathDefinition, tagKeys]) =>
        BoardOrgPathDefinitionActions.SaveBoardOrgPathDefinitionSuccess({
          boardOrgPathDefinition,
          tagKeys,
        })
      ),
      catchError((err) => {
        this._messengerNotificationService.showErrorMessage('siteManagementScope.SITE_MANAGEMENT.GLOBAL.ERROR.SAVE_BOARD_JSON_SCHEMA_FAIL_150');
        return of(BoardOrgPathDefinitionActions.SaveBoardOrgPathDefinitionFail({ error: err }));
      })
    )
  );

  constructor(private _actions$: Actions, private _boardService: BoardsService, private _boardTagsService: BoardTagsService, private _messengerNotificationService: MessengerNotificationService) {}
}
