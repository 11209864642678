import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ErrorComponent } from './components/error/error.component';
import { ButtonModule, LayoutModule, CoreModule } from '@activia/ngx-components';
import { TranslocoModule } from '@ngneat/transloco';
import { ErrorHandlingService } from './error-handling/error-handling.service';

@NgModule({
  imports: [CommonModule, ButtonModule, LayoutModule, CoreModule, TranslocoModule],
  declarations: [ErrorComponent],
  providers: [ErrorHandlingService],
  exports: [],
})
export class ErrorModule {}
