import { defaultSchema, PropertyTypes } from './orgpath.interface';
import { IOptionData, ThemeType } from '@activia/ngx-components';
import { ConstraintTypeEnum, ConstraintTypeEnum as JsonSchemaConstraintTypeEnum, getJSONSchemaType, IConstraintTypeOption, IJsonSchema, JsonSchemaDefaultType } from '@activia/json-schema-forms';
import { TranslocoService } from '@ngneat/transloco';
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { BehaviorSubject, Observable } from 'rxjs';
import { IEngineTagKeyDesc } from '@amp/tag-operation';
import { map } from 'rxjs/operators';

/**
 * Base abstract class for Orgpath nodes.
 * Provides common properties and methods for managing and manipulating node data.
 */
export abstract class OrgpathNodeBase {
  propertyTypes = PropertyTypes;
  themeType: typeof ThemeType = ThemeType;

  schemaTypeOptions: ConstraintTypeEnum[] = [ConstraintTypeEnum.text, ConstraintTypeEnum.specific, ConstraintTypeEnum.numeric];

  schemaEditorConstraintTypeOptions: IConstraintTypeOption[] = this.schemaTypeOptions.map((type) => ({
    value: JsonSchemaConstraintTypeEnum[type],
    label: this._transloco.translate('tagOperation.TAG_OPERATION.TAG_TYPES.' + type.toUpperCase() + '_NAME_20'),
    description: this._transloco.translate('tagOperation.TAG_OPERATION.TAG_TYPES.' + type.toUpperCase() + '_DESC_100'),
  }));

  descriptionForm = new FormGroup({
    title: new FormControl(''),
    description: new FormControl(''),
    examples: new FormArray([new FormControl('')]),
    default: new FormControl('' as JsonSchemaDefaultType),
  });

  /** Form control for the json-schema-controls to change the dependent value */
  dependentFormControl = new FormControl();
  jsonSchemaEditorControl = new FormControl();
  enableCondition = false;

  nodeId$ = new BehaviorSubject<string>(null);

  constructor(private _transloco: TranslocoService) {}

  /**
   * Updates the state of form controls based on the provided disabled flag.
   */
  updateControlState(disabled: boolean) {
    if (disabled) {
      this.jsonSchemaEditorControl.disable();
      this.dependentFormControl.disable();
    } else {
      this.jsonSchemaEditorControl.enable();
      this.dependentFormControl.enable();
    }
  }

  /**
   * Determines whether there is a change between two instances of IJsonSchema objects.
   */
  isSchemaChanged(schema: IJsonSchema, newSchema: IJsonSchema): boolean {
    return (
      schema.type !== newSchema.type ||
      schema.minimum !== newSchema.minimum ||
      schema.maximum !== newSchema.maximum ||
      schema.enum?.join() !== newSchema.enum?.join() ||
      schema.maxLength !== newSchema.maxLength ||
      schema.minLength !== newSchema.minLength ||
      schema.pattern !== newSchema.pattern ||
      schema.title !== newSchema.title ||
      schema.description !== newSchema.description ||
      schema.examples?.join() !== newSchema.examples?.join()
    );
  }

  /**
   * Maps a node to a corresponding JSON schema based on its properties.
   */
  mapSchema(node, tagsDef) {
    if (node.tag && tagsDef[node.tag]) {
      return tagsDef[node.tag].schema as IJsonSchema;
    } else if (node.property) {
      return node.schema || defaultSchema;
    } else {
      return defaultSchema;
    }
  }

  /**
   * Returns an observable of tag options filtered based on certain conditions.
   */
  getTagsOptions$(tagsDefinitions$: Observable<Record<string, IEngineTagKeyDesc>>): Observable<IOptionData<IJsonSchema>[]> {
    const allowedTypes: ConstraintTypeEnum[] = [ConstraintTypeEnum.numeric, ConstraintTypeEnum.text, ConstraintTypeEnum.specific];

    return tagsDefinitions$.pipe(
      map((tags) =>
        Object.entries(tags)
          .filter(([, tagValue]) => {
            const jsonSchemaType: ConstraintTypeEnum = getJSONSchemaType((tagValue.schema as IJsonSchema)?.items || (tagValue.schema as IJsonSchema));

            const isTypeAllowed = allowedTypes.indexOf(jsonSchemaType) !== -1;
            return !tagValue.multivalues && isTypeAllowed;
          })
          .map(([tagName, tag]) => ({
            label: tagName,
            value: tagName,
            data: tag.schema as IJsonSchema,
          }))
      )
    );
  }
}
