import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import * as SiteOrgPathDefinitionActions from '../store/site-org-path-definition/site-org-path-definition.actions';
import { selectSiteOrgPathDefinitionState } from '../store/site-org-path-definition';
import { filter, take } from 'rxjs/operators';
import { LoadingState } from '@activia/ngx-components';

@Injectable()
export class PreloadSiteOrgPathDefinitionGuard {
  constructor(private _store: Store) {}

  canActivate(): boolean {
    this._store
      .select(selectSiteOrgPathDefinitionState)
      .pipe(
        take(1),
        filter((state) => state !== LoadingState.LOADED)
      )
      .subscribe((_) => {
        this._store.dispatch(SiteOrgPathDefinitionActions.FetchSiteOrgPathDefinition());
      });

    return true;
  }
}
