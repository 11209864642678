import { createAction, props } from '@ngrx/store';
import { HealthStatusCode } from '@amp/devices';
import { IDataSource, IDataTableDataFetchEvent, IDataTableDataSort } from '@activia/ngx-components';
import { ISiteMonitoringListDataRow } from '../model/site-monitoring-list-data-row.interface';
import { SiteDTO } from '@activia/cm-api';
import { ISiteMonitoringKeyMetricConfig } from '../model/site-monitoring-key-metric-config.interface';
import { ISiteMonitoringListColumnConfig } from '../model/site-monitoring-list-column-config.interface';
import { IColumnNameOverride } from '../model/column-name-override.interface';
import { SiteMonitoringProfile } from '../model/site-monitoring-profile.type';
import { CountAggregationData } from '../model/site-monitoring-data.interface';
import { ISiteMonitoringUserPreferences, SiteMonitoringPreference } from '../model/site-monitoring-preference.interface';

/** Fetches the user profile. We do need a profile on error fetching the current profile, hence the profile in error action **/
export const SiteMonitoringFetchProfile = createAction('[Site Monitoring] Fetch Profile');
export const SiteMonitoringFetchProfileSuccess = createAction('[Site Monitoring] Fetch Profile Success', props<{ profile: SiteMonitoringProfile }>());
export const SiteMonitoringFetchProfileFail = createAction('[Site Monitoring] Fetch Profile Fail', props<{ profile: SiteMonitoringProfile; error: any }>());
export const SiteMonitoringUpdateProfile = createAction('[Site Monitoring] Update Profile', props<{ profile: SiteMonitoringProfile }>());
export const SiteMonitoringUpdateProfileSuccess = createAction('[Site Monitoring] Update Profile Success', props<{ profile: SiteMonitoringProfile }>());
export const SiteMonitoringUpdateProfileFail = createAction('[Site Monitoring] Update Profile Fail', props<{ error: any }>());

export const SiteMonitoringFetchUserSiteCount = createAction('[Site Monitoring] Fetch User Site Count');
export const SiteMonitoringFetchUserSiteCountSuccess = createAction('[Site Monitoring] Fetch User Site Count Success', props<{ siteCount: number }>());
export const SiteMonitoringFetchUserSiteCountFail = createAction('[Site Monitoring] Fetch User Site Count Fail', props<{ error: any }>());

export const SiteMonitoringFetchListDataSource = createAction('[Site Monitoring] Fetch List Data Source', props<{ dataFetchSettings: IDataTableDataFetchEvent }>());
export const SiteMonitoringFetchListDataSourceSuccess = createAction(
  '[Site Monitoring] Fetch List Data Source Success',
  props<{ dataFetchSettings: IDataTableDataFetchEvent; dataSource: IDataSource<ISiteMonitoringListDataRow> }>()
);
export const SiteMonitoringFetchListDataSourceFail = createAction('[Site Monitoring] Fetch List Data Source Fail', props<{ error: any }>());
export const SiteMonitoringListResetDataSource = createAction('[Site Monitoring] Reset List Data Source');
export const SiteMonitoringRefreshListDataSource = createAction('[Site Monitoring] Refresh List Data Source');
export const SiteMonitoringRefreshListDataSourceSuccess = createAction('[Site Monitoring] Refresh List Data Source Success', props<{ dataSource: IDataSource<ISiteMonitoringListDataRow> }>());
export const SiteMonitoringRefreshListDataSourceFail = createAction('[Site Monitoring] Refresh List Data Source Fail', props<{ error: any }>());

export const SiteMonitoringListUpdateSort = createAction('[Site Monitoring] List Update Sort', props<{ sort: IDataTableDataSort }>());
export const SiteMonitoringListUpdateSearchTextFilter = createAction('[Site Monitoring] List Update Search Text Filter', props<{ searchText: string }>());

export const SiteMonitoringListUpdateFilteredSitesSuccess = createAction('[Site Monitoring] Update List Filtered Sites Success', props<{ sites: SiteDTO[] }>());
export const SiteMonitoringListUpdateFilteredSitesFail = createAction('[Site Monitoring] Update List Filtered Sites Fail', props<{ error: any }>());

export const SiteMonitoringFetchDetailSite = createAction('[Site Monitoring] Fetch Detail Site', props<{ siteId: number }>());
export const SiteMonitoringFetchDetailSiteSuccess = createAction('[Site Monitoring] Fetch Detail Site Success', props<{ site: SiteDTO }>());
export const SiteMonitoringFetchDetailSiteFail = createAction('[Site Monitoring] Fetch Site Detail Fail', props<{ error: any }>());

export const SiteMonitoringListFetchColumns = createAction('[Site Monitoring] Fetch List Columns');
export const SiteMonitoringListFetchColumnsSuccess = createAction('[Site Monitoring] Fetch List Columns Success', props<{ columns: ISiteMonitoringListColumnConfig[] }>());
export const SiteMonitoringListFetchSelectedColumnsFail = createAction('[Site Monitoring] Fetch ListColumns Fail', props<{ error: any }>());

export const SiteMonitoringListUpdateColumns = createAction('[Site Monitoring] Update Columns', props<{ columns: ISiteMonitoringListColumnConfig[] }>());
export const SiteMonitoringListUpdateColumnsSuccess = createAction('[Site Monitoring] Update Columns Success', props<{ columns: ISiteMonitoringListColumnConfig[] }>());
export const SiteMonitoringListUpdateColumnsFail = createAction('[Site Monitoring] Update Columns Fail', props<{ error: any }>());

export const SiteMonitoringFetchKeyMetrics = createAction('[Site Monitoring] Fetch Key Metrics');
export const SiteMonitoringFetchKeyMetricsSuccess = createAction('[Site Monitoring] Fetch Key Metrics Success', props<{ keyMetrics: ISiteMonitoringKeyMetricConfig[] }>());
export const SiteMonitoringFetchKeyMetricsFail = createAction('[Site Monitoring] Fetch Key Metrics Fail', props<{ error: any }>());

export const SiteMonitoringUpdateKeyMetrics = createAction('[Site Monitoring] Update Key Metrics', props<{ keyMetrics: ISiteMonitoringKeyMetricConfig[] }>());
export const SiteMonitoringUpdateKeyMetricsSuccess = createAction('[Site Monitoring] Update Key Metrics Success', props<{ keyMetrics: ISiteMonitoringKeyMetricConfig[] }>());
export const SiteMonitoringUpdateKeyMetricsFail = createAction('[Site Monitoring] Update Key Metrics Fail', props<{ error: any }>());

export const SiteMonitoringFetchColumnNameOverrides = createAction('[Site Monitoring] Fetch Column Name Overrides');
export const SiteMonitoringFetchColumnNameOverridesSuccess = createAction('[Site Monitoring] Fetch Column Name Overrides Success', props<{ columnNameOverrides: IColumnNameOverride[] }>());
export const SiteMonitoringFetchColumnNameOverridesFail = createAction('[Site Monitoring] Fetch Column Name Overrides Fail', props<{ error: any }>());

export const SiteMonitoringUpdateColumnName = createAction('[Site Monitoring] Update Column Name Overrides', props<{ columnNameOverrides: IColumnNameOverride[] }>());
export const SiteMonitoringUpdateColumnNameSuccess = createAction('[Site Monitoring] Update Column Name Overrides Success', props<{ columnNameOverrides: IColumnNameOverride[] }>());
export const SiteMonitoringUpdateColumnNameFail = createAction('[Site Monitoring] Update Column Name Overrides Fail', props<{ error: any }>());

export const SiteMonitoringFetchRecentSites = createAction('[Site Monitoring] Fetch Recent Sites');
export const SiteMonitoringFetchRecentSitesSuccess = createAction('[Site Monitoring] Fetch Recent Sites Success', props<{ siteIds: number[] }>());
export const SiteMonitoringFetchRecentSitesFail = createAction('[Site Monitoring] Fetch Recent Sites Fail', props<{ error: any }>());
export const SiteMonitoringAddRecentSite = createAction('[Site Monitoring] Add Recent Site', props<{ siteId: number }>());
export const SiteMonitoringAddRecentSiteSuccess = createAction('[Site Monitoring] Add Recent Site Success', props<{ siteIds?: number[] }>()); // leave empty for no update
export const SiteMonitoringAddRecentSiteFail = createAction('[Site Monitoring] Add Recent Site Fail', props<{ error: any }>());

export const SiteMonitoringFetchStarredSites = createAction('[Site Monitoring] Fetch Starred Sites');
export const SiteMonitoringFetchStarredSitesSuccess = createAction('[Site Monitoring] Fetch Starred Sites Success', props<{ siteIds: number[] }>());
export const SiteMonitoringFetchStarredSitesFail = createAction('[Site Monitoring] Fetch Starred Starred Fail', props<{ error: any }>());
export const SiteMonitoringStarSite = createAction('[Site Monitoring] Star Site', props<{ siteId: number }>());
export const SiteMonitoringStarSiteSuccess = createAction('[Site Monitoring] Star Site Success', props<{ siteId?: number }>()); // leave empty for no update
export const SiteMonitoringStarSiteFail = createAction('[Site Monitoring] Star Site Fail', props<{ error: any }>());
export const SiteMonitoringUnstarSite = createAction('[Site Monitoring] Unstar Site', props<{ siteId: number }>());
export const SiteMonitoringUnstarSiteSuccess = createAction('[Site Monitoring] Unstar Site Success', props<{ siteId?: number }>()); // leave empty for no update
export const SiteMonitoringUnstarSiteFail = createAction('[Site Monitoring] Unstar Site Fail', props<{ error: any }>());
export const SiteMonitoringUpdateQuickSiteSearchFilter = createAction('[Site Monitoring] Update Quick Site Search Filter', props<{ searchText: string }>());

export const SiteMonitoringFetchHealthStatusSiteCount = createAction('[Site Monitoring] Fetch Health Status Site Count');
export const SiteMonitoringFetchHealthStatusSiteCountSuccess = createAction('[Site Monitoring] Fetch Health Status Site Count Success', props<{ status: CountAggregationData<HealthStatusCode> }>());
export const SiteMonitoringFetchHealthStatusSiteCountFail = createAction('[Site Monitoring] Fetch Health Status Site Count Fail', props<{ error: any }>());

export const SiteMonitoringFetchPreference = createAction('[Site Monitoring] Fetch preference');
export const SiteMonitoringFetchPreferenceSuccess = createAction('[Site Monitoring] Fetch Preference Success', props<{ preference: SiteMonitoringPreference }>());
export const SiteMonitoringFetchPreferenceFail = createAction('[Site Monitoring] Fetch perference fail', props<{ error: any }>());
export const SiteMonitoringUpdatePreference = createAction('[Site Monitoring] Update preference', props<{ preference: SiteMonitoringPreference; profile: SiteMonitoringProfile }>());
export const SiteMonitoringUpdatePreferenceSuccess = createAction('[Site Monitoring] Update Preference Success', props<{ preference: SiteMonitoringPreference; profile: SiteMonitoringProfile }>());
export const SiteMonitoringUpdatePreferenceFail = createAction('[Site Monitoring] Update perference fail', props<{ error: any }>());

export const SiteMonitoringFetchUserPreferences = createAction('[Site Monitoring] Fetch User Preferences');
export const SiteMonitoringFetchUserPreferencesSuccess = createAction('[Site Monitoring] Fetch User Preferences Success', props<{ userPreferences: ISiteMonitoringUserPreferences }>());
export const SiteMonitoringFetchUserPreferencesFail = createAction('[Site Monitoring] Fetch User Preference Fail', props<{ error: any }>());

export const SiteMonitoringUpdateUserPreferences = createAction('[Site Monitoring] Update User Preferences', props<{ userPreferences: Partial<ISiteMonitoringUserPreferences> }>());
export const SiteMonitoringUpdateUserPreferencesSuccess = createAction('[Site Monitoring] Update User Preferences Success', props<{ userPreferences: ISiteMonitoringUserPreferences }>());
export const SiteMonitoringUpdateUserPreferencesFail = createAction('[Site Monitoring] Update User Preference Fail', props<{ error: any }>());
