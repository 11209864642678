import { IJsonSchema } from '@activia/json-schema-forms';
import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { TranslocoModule, TranslocoService } from '@ngneat/transloco';
import { combineLatest, map, Observable } from 'rxjs';
import { SiteOrgpathStore } from '../site-orgpath.store';
import { IOrgPathNode, PropertyTypes } from '../../orgpath.interface';
import { OrgpathTreeBase } from '../../orgpath-tree-base';
import { defaultIfEmpty } from 'rxjs/operators';
import { CoreModule, IconModule, TooltipModule } from '@activia/ngx-components';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'amp-site-orgpath-tree',
  templateUrl: './site-orgpath-tree.component.html',
  styleUrls: ['./site-orgpath-tree.component.scss'],
  standalone: true,
  imports: [CommonModule, IconModule, CoreModule, TranslocoModule, TooltipModule],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SiteOrgpathTreeComponent extends OrgpathTreeBase implements OnInit {
  @Input() id: string;
  @Input() editable: boolean;

  hasError$: Observable<string>;
  isSelected$: Observable<boolean> = this.siteOrgpathStore.selectedNode$.pipe(map((selectedNode) => selectedNode?.id === this.id));
  node$: Observable<IOrgPathNode> = this.siteOrgpathStore.selectNodeEntities$.pipe(map((entities) => entities[this.id]));
  schema$: Observable<IJsonSchema>;

  propertyTypes = PropertyTypes;

  constructor(private siteOrgpathStore: SiteOrgpathStore, transloco: TranslocoService) {
    super(transloco);
  }

  ngOnInit(): void {
    this.schema$ = combineLatest([this.node$, this.siteOrgpathStore.selectTagsDefinitions$]).pipe(map(([node, tagDefs]) => this.mapSchema(node, tagDefs)));

    this.hasError$ = this.siteOrgpathStore.selectErrors$.pipe(
      map((errors) => errors?.[this.id]),
      map((errors) => (errors ? this.getOrgpathNodeErrors(errors) : undefined)),
      defaultIfEmpty(undefined)
    );
  }

  selectNode(): void {
    this.siteOrgpathStore.selectNode(this.id);
  }

  addNewNode(): void {
    this.siteOrgpathStore.addNewNode(this.id);
  }

  deleteNode(): void {
    this.siteOrgpathStore.removeNode(this.id);
  }

  moveUp(): void {
    this.siteOrgpathStore.moveNodeUp(this.id);
  }

  moveDown(): void {
    this.siteOrgpathStore.moveNodeDown(this.id);
  }
}
