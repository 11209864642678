import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { SITE_MONITORING_GA_EVENT_ACTION, SITE_MONITORING_GA_EVENT_CATEGORY } from '../../model/site-monitoring-ga-events.constant';
import { SiteMonitoringFacade } from '../../store/site-monitoring.facade';
import { ICombinedDeviceInfo } from '../site-monitoring-detail/store/site-monitoring-detail.model';
import { commandsQuery, DEVICE_COMMANDS, DeviceActionCommandEnum, IRunningCommand, RunDeviceCommand } from '@amp/commands';
import { MessengerNotificationService } from '@amp/messenger';
import { TranslocoService } from '@ngneat/transloco';
import { map, share } from 'rxjs/operators';
import { Observable, ReplaySubject } from 'rxjs';
import { BoardAlarmPipe } from '../site-monitoring-detail/board-alarm.pipe';
import { IAlarmEventDisplayConfig, IAlarmEventTextInfo, toAlarmEventTextInfo } from '@amp/devices';
import { Store } from '@ngrx/store';
import { CMRole } from '@amp/auth';

type DeviceAction = 'restart-device' | 'copy-info';

@Component({
  selector: 'amp-site-device-actions',
  templateUrl: './site-device-actions.component.html',
  styleUrls: ['./site-device-actions.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SiteDeviceActionsComponent implements OnInit {
  @Input() deviceInfo: ICombinedDeviceInfo;
  @Input() iconSize: number;

  contextMenuActions: { action: string; label: string; icon: string; rolesRequired?: CMRole[] }[] = [
    {
      action: 'restart-device',
      label: this._translate.translate('siteMonitoringSharedScope.SITE_MONITORING.SITE_DETAIL.DISPLAY.RESTART_PLAYER_50'),
      icon: 'av:replay',
      rolesRequired: [CMRole.ROLE_ADMIN, CMRole.ROLE_NETWORK_ADMIN, CMRole.ROLE_HOTLINE]
    },
    {
      action: 'copy-info',
      label: this._translate.translate('siteMonitoringSharedScope.SITE_MONITORING.SITE_DETAIL.DISPLAY.COPY_PLAYER_INFO_50'),
      icon: 'fa:copy',
    },
  ];

  isDeviceRestarting$: Observable<boolean>;

  constructor(private _store: Store, private _messengerService: MessengerNotificationService, private _translate: TranslocoService, private _siteMonitoringFacade: SiteMonitoringFacade) {}

  ngOnInit(): void {
    const runningCommands$ = this._store.select<IRunningCommand[]>(commandsQuery.getRunningCommands);

    this.isDeviceRestarting$ = runningCommands$.pipe(
      map((runningCommands) =>
        runningCommands.some((runningCommand) => runningCommand.action === DeviceActionCommandEnum.RestartPlayback && runningCommand.entityId === this.deviceInfo.device.deviceId)
      ),
      share({
        connector: () => new ReplaySubject(1),
        resetOnRefCountZero: true,
        resetOnComplete: false,
        resetOnError: false,
      })
    );
  }

  /** Called when a device context menu item is selected **/
  onDeviceAction(action: DeviceAction): void {
    if (action === 'restart-device') {
      this._restartDevice();
    } else if (action === 'copy-info') {
      this._copyDeviceInfo();
    }
  }

  private _copyDeviceInfo() {
    this._siteMonitoringFacade.trackGAEvent(SITE_MONITORING_GA_EVENT_CATEGORY.DEVICE, SITE_MONITORING_GA_EVENT_ACTION.COPY_INFO, `Copy Info for Device ID: ${this.deviceInfo.device.deviceId}`);
    navigator.clipboard.writeText(this._getDeviceInfo()).then(() => this._messengerService.showInfoMessage('siteMonitoringSharedScope.SITE_MONITORING.SITE_DETAIL.DISPLAY.COPY_TO_CLIPBOARD_100'));
  }

  private _getDeviceInfo() {
    // no need to add the customer actions to the copied to clipboard text
    const alarmDisplayConfig: IAlarmEventDisplayConfig = {
      showCustomerMessage: true,
      showLongMessage: true,
      isDmb: true,
    };

    // General device info
    let info = `${this._translate.translate('siteMonitoringSharedScope.SITE_MONITORING.SITE_DETAIL.BOARD.COPY_INFO.DEVICE_INFO_50')}\n`;
    info += ` ${this._translate.translate('deviceFields.DEVICE.PROPERTY.DEVICE_ID.NAME_LONG')}: ${this.deviceInfo.device.deviceId}\n`;
    info += ` ${this._translate.translate('deviceFields.DEVICE.PROPERTY.HOSTNAME.NAME')}: ${this.deviceInfo.device.hostname}\n`;
    info += ` ${this._translate.translate('deviceFields.DEVICE.PROPERTY.IP_ADDRESS.NAME')}: ${this.deviceInfo.device.ipAddress}\n`;
    info += ` ${this._translate.translate('deviceFields.DEVICE.PROPERTY.DEVICE_TYPE.NAME')}: ${this.deviceInfo.device.deviceType?.type || ''}\n`;
    info += ` ${this._translate.translate('deviceFields.DEVICE.PROPERTY.DEVICE_BRAND.NAME')}: ${this.deviceInfo.device.deviceType?.brand || ''}\n`;
    info += ` ${this._translate.translate('deviceFields.DEVICE.PROPERTY.DEVICE_MODEL.NAME')}: ${this.deviceInfo.device.deviceType?.model || ''}\n`;
    info += ` ${this._translate.translate('deviceFields.DEVICE.PROPERTY.DEVICE_SERIES.NAME')}: ${this.deviceInfo.device.deviceType?.series || ''}\n`;

    // Monitored values
    if (this.deviceInfo?.monitoringData) {
      info += ` ${this._translate.translate('siteMonitoringSharedScope.SITE_MONITORING.SITE_DETAIL.BOARD.COPY_INFO.MONITORED_VALUES_50')}:\n`;
      Object.keys(this.deviceInfo?.monitoringData).forEach((mv) => {
        info += `   ${mv}: ${this.deviceInfo?.monitoringData[mv]}\n`;
      });
      info += `   ${this._translate.translate('deviceFields.DEVICE.MONITORED_VALUE.LAST_UPDATE.NAME')}: ${this.deviceInfo?.time || ''}\n`;
    }

    // TODO: alarms seem to be never included
    // Device alarms
    if (this.deviceInfo?.alarms?.length > 0) {
      info += ` ${this._translate.translate('siteMonitoringSharedScope.SITE_MONITORING.SITE_DETAIL.BOARD.COPY_INFO.ERRORS_50')}:\n`;
      this.deviceInfo?.alarms.forEach((alarm) => {
        const alarmDTO = new BoardAlarmPipe(this._translate).transform(alarm);
        const alarmMessage = this._toAlarmText(toAlarmEventTextInfo(alarmDTO, alarmDisplayConfig));
        info += `   - ${alarmMessage}\n`;
      });
    }

    // Players
    if (this.deviceInfo?.logicalPlayers) {
      info += `\n${this._translate.translate('siteMonitoringSharedScope.SITE_MONITORING.SITE_DETAIL.BOARD.COPY_INFO.PLAYERS_INFO_50')}\n`;
      info += ` ${this._translate.translate('siteMonitoringSharedScope.SITE_MONITORING.SITE_DETAIL.BOARD.COPY_INFO.PLAYERS_COUNT_50')}: ${this.deviceInfo?.logicalPlayers?.length || ''}\n`;

      this.deviceInfo?.logicalPlayers.forEach((player) => {
        info += `\n`;
        info += ` ${this._translate.translate('siteMonitoringSharedScope.SITE_MONITORING.SITE_DETAIL.BOARD.COPY_INFO.PLAYERS_ID_50')}: ${player.id}\n`;
        info += ` ${this._translate.translate('siteMonitoringSharedScope.SITE_MONITORING.SITE_DETAIL.BOARD.COPY_INFO.PLAYERS_NAME_50')}: ${player.player?.title}\n`;
        info += ` ${this._translate.translate('siteMonitoringSharedScope.SITE_MONITORING.SITE_DETAIL.BOARD.COPY_INFO.PLAYERS_STATUS_50')}: ${player.status ? 'Ok' : 'Error'}\n`;
        if (player.alarms.length > 0) {
          // Player errors
          info += ` ${this._translate.translate('siteMonitoringSharedScope.SITE_MONITORING.SITE_DETAIL.BOARD.COPY_INFO.ERRORS_50')}:\n`;
          player.alarms?.forEach((alarm) => {
            const alarmDTO = new BoardAlarmPipe(this._translate).transform(alarm);
            const alarmMessage = this._toAlarmText(toAlarmEventTextInfo(alarmDTO, alarmDisplayConfig));
            info += `   - ${alarmMessage}\n`;
          });
        }
      });
    }

    return info;
  }

  private _toAlarmText(alarmTextInfo: IAlarmEventTextInfo): string {
    const namespace = alarmTextInfo.namespace ? this._translate.translate(alarmTextInfo.namespace.key, alarmTextInfo.namespace.params) : null;
    const customerMessage = alarmTextInfo.customerMessage ? this._translate.translate(alarmTextInfo.customerMessage.key, alarmTextInfo.customerMessage.params) : null;
    return `${namespace ? '[' + namespace + '] ' : ''}${[customerMessage, alarmTextInfo.longMessage].filter((v) => !!v).join(' - ')}`;
  }

  private _restartDevice() {
    this._store.dispatch(
      RunDeviceCommand({ deviceId: this.deviceInfo.device.deviceId, deviceInfo: this.deviceInfo.device, command: DEVICE_COMMANDS.RestartPlayback, gaCategory: 'monitoring.device-command' })
    );
    this._messengerService.showInfoMessage('siteMonitoringSharedScope.SITE_MONITORING.SITE_DETAIL.DISPLAY.RESTART_PLAYERS_SINGULAR_100', {
      hostname: this.deviceInfo.device.hostname,
      ip: this.deviceInfo.device.ipAddress,
    });
  }
}
