import { dataOnceReady, IOptionData, ModalService, ModalType, ThemeType } from '@activia/ngx-components';
import { Overlay } from '@angular/cdk/overlay';
import { AfterViewInit, ChangeDetectionStrategy, Component, EventEmitter, Inject, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { combineLatest, Observable, of, ReplaySubject, Subject } from 'rxjs';
import { filter, first, map, take, takeUntil, tap, withLatestFrom } from 'rxjs/operators';
import { IEngineTagKeyDesc, IEngineTagKey } from '../../model/engine-tag-key.interface';
import { ALL_TAG_OWNER, EngineTagLevel, INTERNAL_APP } from '../../model/operation-scope.interface';
import { TagValueCreationConfig } from '../../model/tag-value-creation-config.interface';
import { IEngineTagValue, PatchOperation, TagValueAssignmentScope } from '../../model/tag-value.interface';
import { TagValueCreationModalComponent } from '../tag-value-creation-modal/tag-value-creation-modal.component';
import { EngineTagValueDetailStore } from './engine-tag-value-detail.store';
import { ActivatedRoute, Router } from '@angular/router';
import { ISiteManagementConfig, SITE_MANAGEMENT_MODULE_CONFIG } from '@amp/environment';
import { getAllUsedTagsInBoardOrgPathDefinition } from '../../utils/tag.util';
import { selectBoardOrgPathDefinition, selectBoardOrgPathDefinitionState } from '../../store/board-org-path-definition/board-org-path-definition.selectors';
import { Store } from '@ngrx/store';
import { FormControl, FormGroup } from '@angular/forms';
import { EXPERIENCE_TEMPLATE_TAG } from 'libs/features/modules/site-management/src/lib/services/experience-template.service';

const EXTERNAL_APP_COLORS = ['#0069ab', '#3e9dd9', '#4c7893'];

@Component({
  selector: 'amp-engine-tag-value-detail',
  templateUrl: './engine-tag-value-detail.component.html',
  styleUrls: ['./engine-tag-value-detail.component.scss'],
  providers: [EngineTagValueDetailStore],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EngineTagValueDetailComponent implements OnInit, AfterViewInit, OnChanges, OnDestroy {
  @Input() warningLabel: string;
  /** looking on which scope of tag assignment */
  @Input() assignmentScope: TagValueAssignmentScope;

  @Input() showSiteLabel = true;

  @Input() editable: boolean;

  @Output() hasUnsavedChange = new EventEmitter<boolean>();

  @Output() changeDetails = new EventEmitter<{ hasUnsavedChanges: boolean; hasInvalidChanges?: boolean }>();

  @Output() saved = new EventEmitter<void>();

  /** All used app owner names */
  appOwnerNames$: Observable<string[]>;
  /** external app owner count >0 */
  showOwnerFilter$: Observable<boolean>;
  /** app owner config*/
  appOwnerConfig$: Observable<{ name: string; color: string; external: boolean }[]>;
  /** created tags before filter */
  currentTags$: Observable<Array<IEngineTagValue>>;
  /** Datasource of tags */
  filteredTags$: Observable<Array<IEngineTagValue>>;
  /** Indicate if there is tag keys in the system */
  hasTagKeys$: Observable<boolean>;
  /** Tag keys that have default value for current entity */
  defaultProvidedTagKeys$: Observable<IEngineTagKey[]>;
  /** Filter on app owner */
  currentAppOwner$: Subject<string> = new ReplaySubject(1);
  /** Filter on search text */
  currentSearchTxt$: Subject<string> = new ReplaySubject(1);
  /** Keep a reference of all edited tags, key is a combination of key-level*/
  editedTags$: Observable<Record<string, IEngineTagValue>> = this._tagStore.editedTagsRecord$;
  pristineTags$: Observable<IEngineTagValue[]> = dataOnceReady<IEngineTagValue[]>(this._tagStore.pristineTags$, this._tagStore.tagState$);
  themeType = ThemeType;
  isLoading$: Observable<boolean> = this._tagStore.isLoading$;

  boardOrgPathDef$: Observable<string[]>;

  /** Represent the list of form control for each tag */
  tagFormGroup = new FormGroup({});

  private _componentDestroyed$: Subject<void> = new Subject();

  constructor(
    private _store: Store,
    private _tagStore: EngineTagValueDetailStore,
    private _overlay: Overlay,
    private _modalService: ModalService,
    private _translateService: TranslocoService,
    private router: Router,
    private route: ActivatedRoute,
    @Inject(SITE_MANAGEMENT_MODULE_CONFIG) private _siteManagementConfig: ISiteManagementConfig
  ) {
    this.boardOrgPathDef$ = dataOnceReady(this._store.select(selectBoardOrgPathDefinition), this._store.select(selectBoardOrgPathDefinitionState)).pipe(
      map((boardOrgPathDef) => getAllUsedTagsInBoardOrgPathDefinition(boardOrgPathDef.root))
    );
  }

  public ngOnInit(): void {
    this.appOwnerNames$ = combineLatest([this._tagStore.appOwners$, this._tagStore.appOwnerFromEditedTags$]).pipe(
      map(([ownerTags, ownerFromEditedTags]) => [...new Set([...ownerTags, ...ownerFromEditedTags])])
    );

    this.showOwnerFilter$ = this.appOwnerNames$.pipe(map((names) => names.filter((name) => name && name !== INTERNAL_APP).length > 0));

    this.appOwnerConfig$ = this.appOwnerNames$.pipe(map((owners) => this.mapAppOwnerConfig(owners)));

    this.currentTags$ = combineLatest([this.pristineTags$, this.editedTags$.pipe(tap((tags) => this.emitTagChange(tags)))]).pipe(
      map(([tags, editedTags]: [IEngineTagValue[], Record<string, IEngineTagValue>]) => this.getUpdatedTags(tags, editedTags))
    );

    this.filteredTags$ = combineLatest([this.currentTags$, this.currentAppOwner$, this.currentSearchTxt$, this.boardOrgPathDef$]).pipe(
      map(([tags, owner, search, orgPathDefKeys]) => this.filterTags(tags, owner, search, orgPathDefKeys))
    );

    // Side effect when filtered tags changes
    this.filteredTags$
      .pipe(
        // Map array into Record<tag.key, tag>
        map((tags) =>
          tags.reduce((acc, curr) => {
            acc[curr.key] = curr;
            return acc;
          }, {})
        ),
        takeUntil(this._componentDestroyed$)
      )
      .subscribe((tags) => {
        Object.keys(tags).forEach((key) => {
          // Add control for each key that doesn't exist
          if (!this.tagFormGroup.contains(key)) {
            this.tagFormGroup.addControl(key, new FormControl());
          }
        });

        if (this.editable) {
          this.tagFormGroup.enable();
        } else {
          this.tagFormGroup.disable();
        }
      });
  }

  public ngAfterViewInit(): void {
    this.showOwnerFilter$
      .pipe(
        withLatestFrom(this.currentAppOwner$),
        filter(([showFilter, owner]) => !showFilter && owner !== ALL_TAG_OWNER),
        takeUntil(this._componentDestroyed$)
      )
      .subscribe(() => this.currentAppOwner$.next(ALL_TAG_OWNER));
  }

  public ngOnChanges({ assignmentScope }: SimpleChanges): void {
    if (assignmentScope && assignmentScope.currentValue) {
      const current = assignmentScope.currentValue;
      const previous = assignmentScope.previousValue;
      const scopeChanged = JSON.stringify(current) !== JSON.stringify(previous);
      if (scopeChanged) {
        this.initAssignmentScope(assignmentScope.currentValue);
      }
      const idsEqual = current?.ids?.length === previous?.ids?.length && current?.ids.every((cId) => previous?.ids.indexOf(cId) > -1);
      if (current?.ids && !idsEqual) {
        this._tagStore.updateSelectedIds(assignmentScope.currentValue.ids);
      }
    }
  }

  public ngOnDestroy(): void {
    this._componentDestroyed$.next();
    this._componentDestroyed$.complete();
  }

  public emitTagChange(editedTags: Record<string, IEngineTagValue>): void {
    this.hasUnsavedChange.emit(Object.keys(editedTags).length !== 0);
    this.changeDetails.emit({ hasUnsavedChanges: Object.keys(editedTags).length !== 0, hasInvalidChanges: this.hasEmptyValue(editedTags) || !this.tagFormGroup.valid });
  }

  /** track function to avoid recreating rows that have not changed **/
  public trackByFn(_: number, data: IEngineTagValue) {
    return this.getTagKey(data);
  }

  public parseAppOwnerOptions(owners: { name: string; external: boolean }[]): IOptionData<void>[] {
    const allOption = {
      label: this._translateService.translate('tagOperation.TAG_OPERATION.TAG_VALUE_DETAIL.FILTER.ALL_APP_20').toUpperCase(),
      value: ALL_TAG_OWNER,
    };
    const ownerOptions = (owners || []).map((owner) => ({ label: owner.name, value: owner.name }));

    return [allOption, ...ownerOptions];
  }

  public getOptionColor(owners: { name: string; color: string; external: boolean }[], option: string): string {
    return owners.find((owner) => owner.name === option)?.color;
  }

  public getAppOwner$(tag: IEngineTagValue): Observable<{ name: string; color: string }> {
    return this.appOwnerConfig$.pipe(map((apps) => apps.find((app) => app.name === (tag?.keyDescription as IEngineTagKeyDesc)?.owner?.toUpperCase()) || apps.find((app) => app.name === INTERNAL_APP)));
  }

  public onEditTags(tag: IEngineTagValue, operation: PatchOperation): void {
    tag.operation = operation;
    this._tagStore.updateEditedTags({ [this.getTagKey(tag)]: tag });
  }

  public onDeleteTags(tag: IEngineTagValue) {
    // Update the form group
    this.tagFormGroup.removeControl(tag.key);
    this.tagFormGroup.markAsDirty();

    tag.operation = 'delete';
    this._tagStore.updateEditedTags({ [this.getTagKey(tag)]: tag });
  }

  /** show warning icon */
  public displayOwnerWarning(editedTags: Record<string, IEngineTagValue>): boolean {
    return !!Object.values(editedTags).some((tag) => (tag?.keyDescription as IEngineTagKeyDesc)?.owner && (tag.keyDescription as IEngineTagKeyDesc).owner !== INTERNAL_APP);
  }

  /** Check if there is no tag changes */
  public isPristine(editedTags: Record<string, IEngineTagValue>): boolean {
    return Object.keys(editedTags).length === 0;
  }

  public onPushChanges(): Observable<boolean> {
    this._tagStore.applyTagsChange();
    this.saved.emit();
    return dataOnceReady(of(true), this._tagStore.updatingState$);
  }

  /** Open dialog and create tags on save */
  public addTag(tags: IEngineTagValue[], filteredKey$: Observable<IOptionData<IEngineTagKeyDesc>[]>): void {
    const modalRef = this._openCreationTagModal(tags, filteredKey$);
    modalRef.componentInstance.actioned.pipe(takeUntil(modalRef.afterClosed)).subscribe((tag: IEngineTagValue) => {
      this.onEditTags(tag, 'add');
      this.currentAppOwner$.next(ALL_TAG_OWNER);
    });
  }

  /** apply defaults for current entity */
  public applyDefaults() {
    this.defaultProvidedTagKeys$.pipe(take(1)).subscribe((tagKeys) => {
      let createList = {};
      tagKeys.forEach((tagKey) => {
        const tagUpdated: IEngineTagValue = {
          key: tagKey.key,
          propertyType: tagKey.level,
          values: tagKey.description.schema.default,
          keyDescription: tagKey.description,
          operation: 'add',
        };
        createList = { ...createList, [`${tagKey.key}-${tagKey.level}`]: tagUpdated };
      });
      this._tagStore.updateEditedTags(createList);
    });
  }

  public getCancelLabel(level: EngineTagLevel): string {
    return `siteManagementScope.SITE_MANAGEMENT.SITE_DETAIL.SITE_PROPERTIES_EDITOR.INFO.BACK_TO_${level === EngineTagLevel.SITE ? 'OVERVIEW' : 'BOARDS'}_50`;
  }

  public onGoBack(): void {
    this.router.navigate([...this._siteManagementConfig.moduleBasePath, 'sites', this.route.snapshot.paramMap.get('siteId'), this.assignmentScope.level === EngineTagLevel.SITE ? 'detail' : 'board']);
  }

  public onCancel() {
    this._tagStore.resetEditedTags();

    // Reset form with pristine values
    this.pristineTags$
      .pipe(
        first(),
        // Map array into Record<tag.key, tag.values>
        map((tags) =>
          tags.reduce((acc, curr) => {
            acc[curr.key] = curr.values;
            return acc;
          }, {})
        )
      )
      .subscribe((tags) => this.tagFormGroup.reset(tags));
  }

  public filteredKeyOptions(tags: IEngineTagValue[]): Observable<IOptionData<IEngineTagKeyDesc>[]> {
    const keysList = tags.map((tag) => tag.key);

    const engineTagKeysByLevel$ = (level: EngineTagLevel) => this._tagStore.engineTagKeys$.pipe(map((tagKeys) => tagKeys.filter((item) => item.level === level)));

    return engineTagKeysByLevel$(this.assignmentScope.level)?.pipe(
      withLatestFrom(this.boardOrgPathDef$),
      map(([tagKeys, orgPathDefKeys]: [IEngineTagKey[], string[]]) =>
        tagKeys
          .filter((item) => !keysList.includes(item.key) && !orgPathDefKeys.includes(item.key) && item.key !== EXPERIENCE_TEMPLATE_TAG)
          .map((item) => ({
            group: item.level,
            label: item.key,
            value: item.key,
            data: item.description,
          }))
      )
    );
  }

  public isTagModified(tag: IEngineTagValue, editedTags: Record<string, IEngineTagValue>): boolean {
    return Object.values(editedTags).length > 0 && editedTags[this.getTagKey(tag)] && JSON.stringify(tag.values) === JSON.stringify(editedTags[this.getTagKey(tag)].values);
  }

  private getUpdatedTags(tags: IEngineTagValue[], editedTags: Record<string, IEngineTagValue>) {
    const hasEditedTags = Object.values(editedTags)?.length !== 0;
    if (!hasEditedTags) {
      return tags;
    }
    if (tags.length === 0 && hasEditedTags) {
      return Object.values(editedTags).filter((tag) => !editedTags[this.getTagKey(tag)] || editedTags[this.getTagKey(tag)].operation !== 'delete');
    }
    let updatedTags: IEngineTagValue[] = tags
      .filter((tag) => !editedTags[this.getTagKey(tag)] || editedTags[this.getTagKey(tag)].operation !== 'delete')
      .map((pristineTag) => this.getModifiedTags(pristineTag, editedTags));

    const addedTags: IEngineTagValue[] = Object.values(editedTags).filter((item) => item.operation === 'add');
    if (addedTags?.length > 0) {
      updatedTags = updatedTags.concat(addedTags);
    }
    return updatedTags;
  }

  private getModifiedTags(tag: IEngineTagValue, editedTags: Record<string, IEngineTagValue>): IEngineTagValue {
    const key = this.getTagKey(tag);
    const editedTag = editedTags[key];
    if (!editedTag) {
      return tag;
    }
    switch (editedTag.operation) {
      case 'replace':
        // comparison to pristine state, if it has the same values, tag hasn't changed
        if (JSON.stringify(tag.values) === JSON.stringify(editedTag.values)) {
          delete editedTags[key];
        } else {
          return editedTag;
        }
        break;
      case 'delete':
        delete editedTags[key];
        break;
      case 'add':
        return editedTag;
      default:
        break;
    }
    return undefined;
  }

  /** Reset everything when assignment scope changes */
  private initAssignmentScope(scope: TagValueAssignmentScope): void {
    this._tagStore.resetEditedTags();
    this.currentAppOwner$.next(ALL_TAG_OWNER);
    this.currentSearchTxt$.next('');
    this.tagFormGroup = new FormGroup({}); // Clear formGroup

    this.defaultProvidedTagKeys$ = this._tagStore.engineTagKeys$.pipe(map((keys) => keys.filter((key) => key.level === scope.level && (key?.description?.schema as any)?.default)));

    this.hasTagKeys$ = this._tagStore.engineTagKeys$.pipe(map((keys) => keys.filter((key) => key.level === scope.level).length > 0));

    this._tagStore.getTags(scope);
  }

  private mapAppOwnerConfig(owners: string[]) {
    return owners.map((owner, index) => ({
      name: owner === INTERNAL_APP ? INTERNAL_APP : owner.toUpperCase(),
      color: owner === INTERNAL_APP ? undefined : EXTERNAL_APP_COLORS[index % EXTERNAL_APP_COLORS.length],
      external: owner !== INTERNAL_APP,
    }));
  }

  private _openCreationTagModal(tags: IEngineTagValue[], filteredKeyOptions$: Observable<IOptionData<IEngineTagKeyDesc>[]>) {
    return this._modalService.open<TagValueCreationModalComponent, TagValueCreationConfig>(
      TagValueCreationModalComponent,
      {
        showCloseIcon: true,
        closeOnBackdropClick: true,
        data: { existingTags: tags, keyOptions$: filteredKeyOptions$ },
      },
      {
        width: '475px',
        maxHeight: '750px',
        panelClass: 'overlay-panel-class',
        positionStrategy: this._overlay.position().global().centerHorizontally().top('150px'),
      },
      ModalType.Dialog
    );
  }

  private getTagKey(tag: IEngineTagValue): string {
    return `${tag.key}-${tag.propertyType}`;
  }

  private filterTags(tags: IEngineTagValue[], owner: string, search: string, orgPathDefKeys: string[]) {
    const filteredTags = tags.filter((tag) => {
      const tagOwner = (tag?.keyDescription as IEngineTagKeyDesc)?.owner?.toUpperCase();
      if (owner === ALL_TAG_OWNER) {
        return true;
      }
      if (owner === INTERNAL_APP) {
        return !tagOwner || tagOwner === INTERNAL_APP;
      }
      return tagOwner === owner.toUpperCase();
    });

    if (search) {
      return filteredTags.filter((tag) => tag.key.includes(search));
    }

    return filteredTags.filter((tag) => !orgPathDefKeys.includes(tag?.key) && tag?.key !== EXPERIENCE_TEMPLATE_TAG);
  }

  private hasEmptyValue(editedTags: Record<string, IEngineTagValue>): boolean {
    return Object.values(editedTags).some(
      (tag) => (Array.isArray(tag.values) && tag.values?.length === 0) || (Array.isArray(tag.values) && tag.values.some((value) => value == null || (typeof value === 'string' && value.trim() === '')))
    );
  }
}
