import { Inject, Injectable, OnDestroy } from '@angular/core';
import { WINDOW } from '@activia/ngx-components';
import { fromEvent, Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { IGlobalState } from '../store/global.reducer';
import { UserConnectionStatusUpdate } from '../store/global.action';
import { MessengerNotificationService } from '@amp/messenger';

@Injectable({
  providedIn: 'root',
})
export class NetworkConnectivityService implements OnDestroy {
  /**
   * @ignore Pattern used to close all subscriptions
   * There should be no need for that since this service is a singleton and therefore destroyed with
   * the app, but we add it just in case its being moved at some stage. Does not hurt to have it.
   * */
  private serviceDestroyed$: Subject<void> = new Subject<void>();

  constructor(@Inject(WINDOW) public window: Window, private store: Store<IGlobalState>, private messengerNotificationService: MessengerNotificationService) {}

  /** @ignore **/
  ngOnDestroy() {
    this.serviceDestroyed$.next();
    this.serviceDestroyed$.complete();
  }

  init() {
    fromEvent(this.window, 'online')
      .pipe(
        tap((_) => this.updateUserConnectionStatus(true)),
        takeUntil(this.serviceDestroyed$)
      )
      .subscribe();

    fromEvent(this.window, 'offline')
      .pipe(
        tap((_) => this.updateUserConnectionStatus(false)),
        takeUntil(this.serviceDestroyed$)
      )
      .subscribe();
  }

  updateUserConnectionStatus(connected: boolean) {
    if (connected) {
      this.store.dispatch(UserConnectionStatusUpdate({ isUserOnline: true }));
      this.messengerNotificationService.showInfoMessage('error.network-online');
    } else {
      this.store.dispatch(UserConnectionStatusUpdate({ isUserOnline: false }));
      this.messengerNotificationService.showErrorMessage('error.network-offline');
    }
  }
}
