import { IJsonSchema, JsonSchemaControlFieldComponent, JsonSchemaEditorComponent } from '@activia/json-schema-forms';
import { CoreModule, IconModule, IOptionData, LayoutModule, MasterDetailModule, SelectModule, SwitchModule } from '@activia/ngx-components';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, HostBinding, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { TranslocoModule, TranslocoService } from '@ngneat/transloco';
import { combineLatest, filter, first, map, Observable, of, Subject, switchMap } from 'rxjs';
import { SiteOrgpathStore } from '../site-orgpath.store';
import { IOrgPathNode, PropertyTypes } from '../../orgpath.interface';
import { IEngineTagKeyDesc } from '@amp/tag-operation';
import { OrgpathNodeBase } from '../../orgpath-node-base';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';

@Component({
  selector: 'amp-site-orgpath-node-editor',
  templateUrl: './site-orgpath-node-editor.component.html',
  styleUrls: ['./site-orgpath-node-editor.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    LayoutModule,
    CoreModule,
    MasterDetailModule,
    TranslocoModule,
    SwitchModule,
    IconModule,
    JsonSchemaControlFieldComponent,
    SelectModule,
    JsonSchemaEditorComponent,
    ReactiveFormsModule,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SiteOrgpathNodeEditorComponent extends OrgpathNodeBase implements OnInit, OnDestroy {
  @Input() set node(val: IOrgPathNode) {
    this.nodeId$.next(val?.id);
  }

  /**
   * Specify the roles required to show this component in editable mode.
   * If no value is provided, this component is in editable mode.
   * If any value is provided and the current user has the role required, this component will be in
   * editable mode, otherwise will be in read-only mode
   */
  @Input() editable: boolean;

  @Output() openEditTagPanel = new EventEmitter<void>();
  @Output() openAddTagPanel = new EventEmitter<void>();

  translocoService: TranslocoService;

  tagsDefinitions$: Observable<Record<string, IEngineTagKeyDesc>> = this._orgPathStore.selectTagsDefinitions$;
  tagsOptions$: Observable<IOptionData<IJsonSchema>[]> = this.getTagsOptions$(this.tagsDefinitions$);
  node$: Observable<IOrgPathNode>;
  nodeSchema$: Observable<IJsonSchema>;
  parentSchema$: Observable<IJsonSchema>;

  componentDestroyed$: Subject<void> = new Subject();

  @HostBinding('class.disabled') disabled: boolean;

  constructor(private _cdr: ChangeDetectorRef, private _orgPathStore: SiteOrgpathStore, translocoService: TranslocoService) {
    super(translocoService);
    this.translocoService = translocoService;
  }

  ngOnInit(): void {
    this.disabled = !this.editable;
    this.updateControlState(this.disabled);
    // Node currently being displayed
    this.node$ = combineLatest([this._orgPathStore.selectNodeEntities$, this.nodeId$]).pipe(map(([nodeEntities, nodeId]) => nodeEntities[nodeId]));

    this.nodeSchema$ = combineLatest([this.node$, this.tagsDefinitions$]).pipe(
      filter(([node, tagsDef]) => !!(node && tagsDef)),
      map(([node, tagsDef]) => this.mapSchema(node, tagsDef))
    );

    this.parentSchema$ = combineLatest([this.node$, this.tagsDefinitions$]).pipe(
      map(([node, tagsDef]) => {
        const parentNode = node?.parent;

        return tagsDef[parentNode?.tag]?.schema as IJsonSchema;
      })
    );

    // Initialize the description form for property name
    this.nodeSchema$.pipe(first()).subscribe((nodeSchema) => this.descriptionForm.patchValue(nodeSchema as any));
    // Initialize the dependent condition
    this.node$.pipe(first()).subscribe((node) => this.dependentFormControl.patchValue(node.dependentItem));
  }

  onClose(): void {
    this._orgPathStore.selectNode(undefined); // unselect node
  }

  onConditionChange({ status }: { status: boolean }): void {
    this.enableCondition = status;
    this._cdr.detectChanges();
    if (!status) {
      this._orgPathStore.editNodeCondition(undefined); // Remove condition
    } else {
      this.updateCondition(); // Add condition
    }
  }

  updateCondition(): void {
    if (this.dependentFormControl.valid) {
      this._orgPathStore.editNodeCondition(this.dependentFormControl.value);
    }
  }

  updateTagProperty(value: string): void {
    value = value === PropertyTypes.TAG ? '' : value;
    if (!this.disabled) {
      this._orgPathStore.editNodeTagOrProperty(value);
    }
  }

  updateSchema(newSchema: IJsonSchema): void {
    this.nodeSchema$.pipe(first()).subscribe((schema) => {
      if (this.isSchemaChanged(schema, newSchema)) {
        this._orgPathStore.editPropertySchema(newSchema);
        this._orgPathStore.editPropertyDescription(newSchema);
      }
    });
  }

  getHeaderTitle$(): Observable<string> {
    return this.node$.pipe(
      filter((node) => !!node),
      switchMap((node) => {
        if (!node.tag && node.property !== 'name') {
          return of(this.translocoService.translate('siteManagementScope.SITE_MANAGEMENT.GLOBAL.ORGANIZATIONAL_PATH.ORGPATH_EDITOR.NODE_EDITOR.CREATE_NEW_LEVEL_60'));
        } else if (node.tag) {
          return this.getTagTitle$(node.tag).pipe(
            map((title) => title ?? this.translocoService.translate('siteManagementScope.SITE_MANAGEMENT.GLOBAL.ORGANIZATIONAL_PATH.ORGPATH_EDITOR.NODE_EDITOR.SITE_TAG_30'))
          );
        } else {
          return of(this.translocoService.translate('siteManagementScope.SITE_MANAGEMENT.GLOBAL.ORGANIZATIONAL_PATH.ORGPATH_EDITOR.NODE_EDITOR.SITE_NAME_30'));
        }
      })
    );
  }

  getTagTitle$(tag: string): Observable<string> {
    return this.tagsDefinitions$.pipe(
      first(),
      map((tagDef) => (tagDef?.[tag]?.schema as IJsonSchema)?.title)
    );
  }

  openEditPanel(): void {
    this.openEditTagPanel.emit();
  }

  openAddPanel(): void {
    this.openAddTagPanel.emit();
  }

  ngOnDestroy(): void {
    this.componentDestroyed$.next();
    this.componentDestroyed$.complete();
    this.nodeId$.complete();
  }
}
