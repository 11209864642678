import { Injectable } from '@angular/core';
import { IAPITagKey, IAPITagsList, IEngineTagsList, IEngineTagKeyDesc, IEngineTagKey } from '../model/engine-tag-key.interface';
import { APITagValue, IAPITagValue, IEngineTagValue } from '../model/tag-value.interface';
import { IJsonSchema, JsonSchemaDefaultType } from '@activia/json-schema-forms';
import { TagKeyDescDTO } from '@activia/cm-api';

@Injectable({ providedIn: 'root' })
export class TagAdapterService {
  /**
   * Normalizes list of tags coming from API, returns keys with valid json schemas
   */
  normalizeIncomingTagDescriptions(tags: IAPITagsList): IEngineTagsList {
    return Object.keys(tags).reduce(
      (acc, curr) => ({
        ...acc,
        [curr]: this._convertToValidTagDescription(tags[curr]),
      }),
      {} as IEngineTagsList
    );
  }

  /**
   * Normalizes a single tag description coming from api, returns with valid json schema
   */
  normalizeIncomingTagDescription(tag: TagKeyDescDTO): IEngineTagKeyDesc {
    return this._convertToValidTagDescription(tag);
  }

  /**
   * Normalizes single tag key coming from API, returns keys with valid json schema
   */
  normalizeIncomingTagKey(tag: IAPITagKey): IEngineTagKey {
    return {
      ...tag,
      description: this._convertToValidTagDescription(tag.description),
    };
  }

  /**
   * Normalizes outgoing tags by first filtering empty values from the schema
   * then fully converting to a backend friendly multivalue schema
   */
  normalizeOutgoingTagKey(tag: IEngineTagKey): IAPITagKey {
    const transforms = [this._filterEmptyValuesFromSchema, this._convertToAPITagDescription];
    return {
      ...tag,
      description: transforms.reduce((acc, curr) => curr.call(this, acc), tag.description),
    };
  }

  /**
   * Normalizes a single incoming tag from API with invalid json schema and
   * string only values into a UI friendly value with a valid schema and values
   */
  normalizeIncomingTagValue(tagValue: IAPITagValue): IEngineTagValue {
    return this._convertToValidEngineTagValue(tagValue);
  }

  /**
   * Normalizes a list incoming tag values from API with invalid json schema and
   * string only values into a UI friendly value with a valid schema and values
   */
  normalizeIncomingTagValues(tagValues: Record<string, IAPITagValue>): Record<string, IEngineTagValue> {
    return Object.keys(tagValues).reduce(
      (acc, curr) => ({
        ...acc,
        [curr]: this._convertToValidEngineTagValue(tagValues[curr]),
      }),
      {} as Record<string, IEngineTagValue>
    );
  }

  /**
   * Normalizes a single outgoing tag from UI with valid json schema and proper
   * values into a API friendly value with a invalid schema and string values
   */
  normalizeOutgoingTagValue(tagValue: IEngineTagValue): IAPITagValue {
    return this._convertToValidAPITagValue(tagValue);
  }

  /**
   * Normalizes a list of outgoings tags from UI with valid json schema and UI
   * friendly values into a list of API friendly tags with invalid json schema
   * and backend friendly values
   */
  normalizeOutgoingTagValues(tagValues: Record<string, IEngineTagValue>): Record<string, IAPITagValue> {
    return Object.keys(tagValues).reduce(
      (acc, curr) => ({
        ...acc,
        [curr]: this._convertToValidAPITagValue(tagValues[curr]),
      }),
      {} as Record<string, IAPITagValue>
    );
  }

  /**
   * Coverts a single tag as seen by UI with valid json-schema and tag values
   * into a single tag for API with invalid json-schema and string only values
   */
  private _convertToValidAPITagValue(tagValue: IEngineTagValue): IAPITagValue {
    if (!tagValue) {
      return null;
    }

    // Only force conversion on valid json schema descriptions
    let apiDescription = tagValue.keyDescription as TagKeyDescDTO;
    if (tagValue.keyDescription.multivalues === true && tagValue.keyDescription?.schema?.type === 'array') {
      apiDescription = this._convertToAPITagDescription(tagValue.keyDescription);
    }

    // In the case of json tags that are arrays of objects, the backend still
    // wants the root array enclosed within an array for the replace operation
    const isArrayOfObjects = tagValue.keyDescription.schema?.type === 'array' && tagValue.keyDescription.schema.items?.type === 'object';
    const apiValues = this._convertValidValueToAPIType(tagValue.values, tagValue.keyDescription.schema);
    return {
      ...tagValue,
      values: isArrayOfObjects ? [apiValues] : apiValues,
      keyDescription: apiDescription,
    };
  }

  /**
   * Coverts a single tag as seen by API with invalid json-schema and string only
   * values into a single tag for UI with valid json-schema and correct valid values
   * The values are converted types that will be consumable by inputs and respect
   * the schemas constraints
   */
  private _convertToValidEngineTagValue(tagValue: IAPITagValue): IEngineTagValue {
    if (!tagValue) {
      return null;
    }

    // Only force conversion on invalid descriptions
    let validDescription = tagValue.keyDescription as IEngineTagKeyDesc;
    if (validDescription.multivalues && validDescription.schema.type !== 'array') {
      validDescription = this._convertToValidTagDescription(tagValue.keyDescription);
    }

    // In the case of json tags that are arrays of objects, the backend still
    // serves the array within an array, which is different from the typical
    // arrays for multivalues tags, so we must account for this
    const isArrayOfObjects = validDescription.schema?.type === 'array' && validDescription.schema.items?.type === 'object';
    const validValues = this._convertAPIValueToValidType(isArrayOfObjects ? (tagValue.values as [][])[0] : tagValue.values, validDescription.schema);
    return {
      ...tagValue,
      values: validValues,
      keyDescription: validDescription,
    };
  }

  /**
   * Converts a single tag value into it's corresponding ui friendly type as described
   * in the json schema. These values arrive as mostly strings, and always an array.
   *
   * In the case of arrays, will trigger this method recursively and rebuild the array
   * with the correct types
   */
  private _convertAPIValueToValidType(value: APITagValue, schema: IJsonSchema): JsonSchemaDefaultType {
    if (value === null || value === undefined || (Array.isArray(value) && value.length === 0)) {
      return null;
    }

    switch (schema?.type) {
      case 'array':
        return value?.map((v) => this._convertAPIValueToValidType([v], schema.items));
      case 'object':
        return Array.isArray(value) ? value[0] : value;
      case 'boolean':
        return value[0] === 'true' || (typeof value === 'boolean' && value === true);
      case 'number':
      case 'integer':
        return +value[0];
      case 'string':
      default:
        return '' + value[0];
    }
  }

  /**
   * Converts a single tag value into it's corresponding required api value type
   * as described in the json schema. These values are also required to be in arrays.
   *
   * In the case of arrays types, will trigger this method recursively and rebuild the
   * array with the correct types
   */
  private _convertValidValueToAPIType(value: JsonSchemaDefaultType, schema: IJsonSchema): APITagValue {
    if (value === null || value === undefined || (Array.isArray(value) && value.length === 0)) {
      console.warn('[tag-adapter-service] attempting to send empty value as type ' + schema?.type + ' to backend, this could cause invalid data');
      return [null];
    }
    switch (schema.type) {
      case 'array':
        return (value as APITagValue)?.map((v) => this._convertValidValueToAPIType(v, schema.items)[0]) as APITagValue;
      case 'object':
        return [value as object];
      case 'boolean':
        return [typeof value === 'boolean' && value === true ? 'true' : 'false'];
      case 'number':
      case 'integer':
      case 'string':
      default:
        return ['' + value];
    }
  }

  /**
   * Converts single tag description coming from API, returns description with valid json schema
   */
  private _convertToValidTagDescription(tagDescription: TagKeyDescDTO): IEngineTagKeyDesc {
    return {
      ...tagDescription,
      schema: tagDescription.multivalues ? this._convertToArrayJsonSchema(tagDescription.schema) : (tagDescription.schema as IJsonSchema),
    };
  }

  /**
   * Converts single tag description to friendly for api, returns description api friendly schema
   * as well as enforces enum defaults to be single val instead of string
   */
  private _convertToAPITagDescription(tagDescription: IEngineTagKeyDesc): TagKeyDescDTO {
    const apiSchema: IJsonSchema = {
      ...tagDescription.schema,
    };
    if (apiSchema.enum && apiSchema.default && Array.isArray(apiSchema.default)) {
      apiSchema.default = apiSchema.default[0];
    }
    return {
      ...tagDescription,
      schema: tagDescription.multivalues ? this._convertToFlatJsonSchema(apiSchema) : (apiSchema as object),
    };
  }

  /**
   * Flattens array type JSON schema, removes array type and flattens items
   */
  private _convertToFlatJsonSchema(schema: IJsonSchema): object {
    if (!schema) {
      return null;
    }

    const { description, title, examples, default: defaultValue, items, ...remainder } = schema;

    return {
      ...(description ? { description } : {}),
      ...(title ? { title } : {}),
      ...(examples ? { examples } : {}),
      ...(defaultValue ? { default: defaultValue } : {}),
      ...remainder,
      ...items,
    };
  }

  /**
   * Creates valid array type json schema from backend schema, creates
   * items property and sets type to array
   */
  private _convertToArrayJsonSchema(schema: object): IJsonSchema {
    if (!schema) {
      return null;
    }

    const { description, title, examples, ...remainder } = schema as IJsonSchema;
    delete remainder.default; // Fixes old data that had default in mv tags
    return {
      ...(description ? { description } : {}),
      ...(title ? { title } : {}),
      ...(examples ? { examples } : {}),
      items: remainder,
      type: 'array',
    };
  }

  /** Sanitize tag description for api.
   *  Removes default if multivalue is true, multivalue default unsupported by api.
   *  Removes null or undefined properties => default, examples, description */
  private _filterEmptyValuesFromSchema(tagDescription: IEngineTagKeyDesc): IEngineTagKeyDesc {
    const propertiesToFilter = [];
    if (tagDescription.multivalues || ('default' in tagDescription.schema && (tagDescription.schema['default'] === null || tagDescription.schema['default'] === undefined))) {
      propertiesToFilter.push('default');
    }
    if ('examples' in tagDescription.schema && (tagDescription.schema['examples'] === null || tagDescription.schema['examples'] === undefined)) {
      propertiesToFilter.push('examples');
    }
    if ('description' in tagDescription.schema && (tagDescription.schema['description'] === null || tagDescription.schema['description'] === undefined)) {
      propertiesToFilter.push('description');
    }
    if ('title' in tagDescription.schema && (tagDescription.schema['title'] === null || tagDescription.schema['title'] === undefined)) {
      propertiesToFilter.push('title');
    }

    return {
      ...tagDescription,
      schema: Object.keys(tagDescription.schema).reduce((acc, currentSchemaProp) => {
        if (!propertiesToFilter.some((prop) => prop === currentSchemaProp)) {
          acc[currentSchemaProp] = tagDescription.schema[currentSchemaProp];
        }
        return acc;
      }, {} as IJsonSchema),
    };
  }
}
