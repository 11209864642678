import { DeviceDTO } from '@activia/cm-api';
import { createAction, props } from '@ngrx/store';

export const AddDevices = createAction('[Device/API] Add Devices', props<{ devices: DeviceDTO[] }>());
export const AddDevicesSuccess = createAction('[Device/API] Add Devices Success', props<{ devices: DeviceDTO[] }>());
export const AddDevicesFail = createAction('[Device/API] Add Devices Fail', props<{ error: any; deviceIds: number[] }>());

export const DeleteDevices = createAction('[Device/API] Delete Devices', props<{ deviceIds: number[] }>());
export const DeleteDevicesSuccess = createAction('[Device/API] Delete Devices Success', props<{ deviceIds: number[] }>());
export const DeleteDevicesFail = createAction('[Device/API] Delete Devices Fail', props<{ error: any; deviceIds: number[] }>());

export const ChangeActiveDevice = createAction('[Device/API] Change Active Device', props<{ deviceId: number }>());
