import { ChangeDetectionStrategy, Component, OnChanges, SimpleChanges } from '@angular/core';
import { ISiteMonitoringKeyMetricComponent, KeyMetricsBaseMixin } from '../key-metrics.mixin';
import { MinMaxAggregationData } from '../../../model/site-monitoring-data.interface';
import { SiteMonitoredValue } from '../../../model/site-monitored-value.enum';
import { ColumnThresholdThemePipe } from '../../../pipes/column-threshold-theme.pipe';
import { SiteMonitoringAggregationTypeMappings } from '../../../utils/site-monitored-values.utils';
import { systemDiskKeyMetricConfig } from '../key-metric-viewer-config.constant';

@Component({
  selector: 'amp-key-metrics-disk',
  templateUrl: './key-metrics-disk.component.html',
  styleUrls: ['./key-metrics-disk.component.scss'],
  inputs: ['config', 'monitoringData', 'monitoringDataDataState', 'showCompactView'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class KeyMetricsDiskComponent extends KeyMetricsBaseMixin() implements ISiteMonitoringKeyMetricComponent, OnChanges {
  /** data for the component  **/
  chartData: {
    /** The disk percentage value **/
    diskPercent: number;

    /** theme to use  **/
    theme: string;
  };

  /** the data to display in the component **/
  dataInfo = systemDiskKeyMetricConfig;

  ngOnChanges({ monitoringData }: SimpleChanges) {
    if (!this.showCompactView) {
      if (monitoringData && monitoringData.currentValue) {
        this.chartData = this._toChartData(this.monitoringData as MinMaxAggregationData<number>);
      }
    }
  }

  /** @ignore Converts the monitoring values to the component data model **/
  private _toChartData(monitoringData: MinMaxAggregationData<number>) {
    const aggregationTypeProperty = SiteMonitoringAggregationTypeMappings[this.dataInfo.data.aggregationType];
    const maxDiskPercent = (monitoringData || {})[aggregationTypeProperty];
    return {
      diskPercent: maxDiskPercent,
      theme: new ColumnThresholdThemePipe().transform(maxDiskPercent, SiteMonitoredValue.SystemDisk, true),
    };
  }

  dataDisplayTypeCode() {
    const toChartData = this._toChartData(this.monitoringData as MinMaxAggregationData<number>);
    return (new ColumnThresholdThemePipe()).transform(toChartData.diskPercent, SiteMonitoredValue.SystemDisk);
  }
}
