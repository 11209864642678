<ng-container *avnAsyncData="monitoringData; state: monitoringDataDataState; emptyTemplate: showCompactView ? null : emptyTemplate; loadingTemplate: loadingTemplate; emptyFn: emptyMonitoringDataFn.bind(this)">
  <ng-container *ngIf="!showCompactView; else compactViewTemplate">
    <avn-pie
      [subtitle]="'siteMonitoringSharedScope.SITE_MONITORING.KEY_METRICS.HEALTH_STATUS_SUMMARY.TITLE_35' | transloco"
      [showDataLabels]="false"
      [name]="'siteMonitoringSharedScope.SITE_MONITORING.KEY_METRICS.HEALTH_STATUS_SUMMARY.TITLE_35' | transloco"
      [innerSize]="'60%'"
      [selectionEnabled]="false"
      [data]="healthStatusDataSource"
    >
      <avn-simple-legend layout="horizontal" verticalAlign="bottom" [align]="'center'"></avn-simple-legend>
      <avn-slice-chart-tooltip-formatter [uom]="'siteMonitoringSharedScope.SITE_MONITORING.KEY_METRICS.HEALTH_STATUS_SUMMARY.UOM_15' | transloco"> </avn-slice-chart-tooltip-formatter>
    </avn-pie>
  </ng-container>

  <ng-template #compactViewTemplate>
    <amp-key-metrics-accordion
      class="key-metrics-content"
      [keyMetricsAccordionBorderColor]="dataDisplayTypeCode()"
      [keyMetricsAccordionHeader]="'siteMonitoringSharedScope.SITE_MONITORING.KEY_METRICS.HEALTH_STATUS_SUMMARY.TITLE_35' | transloco"
      [keyMetricsAccordionContent]="accordionTemplate"
    ></amp-key-metrics-accordion>
  </ng-template>
</ng-container>

<ng-template #accordionTemplate>
  <div class="accordion-content">
    <div class="item inline">
      <span>{{ 'siteMonitoringSharedScope.SITE_MONITORING.COLUMNS.KEY_METRICS.HEALTH_STATUS_SUMMARY.LAST_UPDATE_20' | transloco }}:</span>
      <amp-site-field-templates
        [field]="compactViewInfo.date.field"
        [displayType]="compactViewInfo.date.compactView.displayType"
        [aggregationType]="compactViewInfo.date.compactView.aggregationType"
        [value]="monitoringDate"
        [showEmptyPlaceholder]="true"
      ></amp-site-field-templates>
    </div>
    <div class="item right">
      <amp-site-field-templates
        [field]="compactViewInfo.data.field"
        [displayType]="compactViewInfo.data.compactView.displayType"
        [optimisticViewEnabled]="config.customConfig.optimisticViewEnabled"
        [value]="monitoringData"
        [showEmptyPlaceholder]="true"
      ></amp-site-field-templates>
    </div>
  </div>
</ng-template>

<ng-template #emptyTemplate>
  <avn-skeleton
    data-e2e="empty"
    [animate]="false"
    [animateDirection]="'VERTICAL'"
    [text]="
      ('siteMonitoringSharedScope.SITE_MONITORING.KEY_METRICS.KEY_METRICS_EMPTY_MSG1_50' | transloco) + ('siteMonitoringSharedScope.SITE_MONITORING.KEY_METRICS.' + config.id + '.TITLE_35' | transloco)
    "
    [subText]="'siteMonitoringSharedScope.SITE_MONITORING.KEY_METRICS.KEY_METRICS_EMPTY_MSG2_50' | transloco"
    [skeletonType]="'BAR_CHART'"
  >
  </avn-skeleton>
</ng-template>

<ng-template #loadingTemplate>
  <div class="key-metric-loading-container">
    <h6 class="title">{{ 'siteMonitoringSharedScope.SITE_MONITORING.KEY_METRICS.HEALTH_STATUS_SUMMARY.TITLE_35' | transloco }}</h6>
    <avn-skeleton
      data-e2e="loading"
      [animate]="true"
      [animateDirection]="'VERTICAL'"
      [text]="'siteMonitoringSharedScope.SITE_MONITORING.KEY_METRICS.KEY_METRIC_DATA_LOADING_TEXT_50' | transloco"
      [skeletonType]="'BAR_CHART'"
    >
    </avn-skeleton>
  </div>
</ng-template>
