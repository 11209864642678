import {DeviceHealthCountDTO} from '@activia/cm-api';
import {ChangeDetectionStrategy, Component} from '@angular/core';
import {filter, Observable, startWith} from 'rxjs';
import {BadgeModule, CoreModule, LayoutStore, LoadingState} from '@activia/ngx-components';
import {CommonModule} from '@angular/common';
import {TranslocoModule} from '@ngneat/transloco';
import {RouterModule} from '@angular/router';
import {UIModule} from '@amp/ui';
import {DevicesStatusService} from '../../status/devices-status.service';

@Component({
  selector: 'amp-devices-status',
  styleUrls: ['./devices-status.component.scss'],
  templateUrl: './devices-status.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule, CoreModule, TranslocoModule, RouterModule, BadgeModule, UIModule],
})
export class DevicesStatusComponent {
  count$: Observable<DeviceHealthCountDTO>;
  sideNavOpened$: Observable<boolean>;

  constructor(private statusService: DevicesStatusService, private _layoutStore: LayoutStore) {
    this.count$ = this.statusService.devicesStatus$.pipe(
      filter(() => this.statusService.devicesStatusState$.value === LoadingState.LOADED),
      startWith({ ok: 0, warning: 0, error: 0, unreachable: 0, notMonitored: 0, total: 0 }),
    );
    this.sideNavOpened$ = this._layoutStore.sideNavOpened$;
  }
}
