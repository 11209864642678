import { IJsonSchema } from '@activia/json-schema-forms';
import { createAction, props } from '@ngrx/store';
import { IOrgPathDefRoot } from '../../model/board-org-path-definition.interface';

/** Fetch board org path definition actions */
export const FetchBoardOrgPathDefinition = createAction('[BoardOrgPathDefinition/API] Fetch Board org path definition');
export const FetchBoardOrgPathDefinitionSuccess = createAction(
  '[BoardOrgPathDefinition/API] Fetch Board org path definition Success',
  props<{ boardOrgPathDefinition: IOrgPathDefRoot; tagKeys: Record<string, IJsonSchema> }>()
);
export const FetchBoardOrgPathDefinitionFail = createAction('[BoardOrgPathDefinition/API] Fetch Board org path definition Fail', props<{ error: any }>());

/** Fetch board org path definition actions */
export const SaveBoardOrgPathDefinition = createAction('[BoardOrgPathDefinition/API] Save Board org path definition', props<{ boardOrgPathDefinition: IOrgPathDefRoot }>());
export const SaveBoardOrgPathDefinitionSuccess = createAction(
  '[BoardOrgPathDefinition/API] Save Board org path definition Success',
  props<{ boardOrgPathDefinition: IOrgPathDefRoot; tagKeys: Record<string, IJsonSchema> }>()
);
export const SaveBoardOrgPathDefinitionFail = createAction('[BoardOrgPathDefinition/API] Save Board org path definition Fail', props<{ error: any }>());
