import { SiteProperties } from './site-properties.enum';

/**
 * Maps each site's property to its actually property in the returned DTO object
 */
export const SITE_PROPERTIES_DATA_MAP = new Map<SiteProperties, string>([
  [SiteProperties.Id, 'id'],
  [SiteProperties.ExternalId, 'externalId'],
  [SiteProperties.Name, 'name'],
  [SiteProperties.Street, 'address.addressLine1'],
  [SiteProperties.City, 'address.city'],
  [SiteProperties.State, 'address.state'],
  [SiteProperties.Country, 'address.country'],
  [SiteProperties.ZipPostalCode, 'address.zip'],
  [SiteProperties.DeviceCount, 'deviceCount'],
]);
