import { InjectionToken } from '@angular/core';

export type SiteManagementOrgPathBoardCaseType = 'none' | 'uppercase' | 'capitalize';
export const SiteManagementOrgPathBoardCaseTypes = ['none', 'uppercase', 'capitalize'];

export interface ISiteManagementConfigSchema {
  /**
   * Configure whether devices can be used in multiple boards.
   * Set to false if each device can only be used in a single board.
   * Set to true if each device can be used in multiple boards within the same site.
   */
  allowDevicesAcrossMultipleBoards: boolean;

  /**
   * Configure the default number of logical player in one device
   */
  defaultPlayerCountPerDevice: number;

  /**
   * Configure the default number of output connection in one logical player
   */
  defaultOutputCountPerPlayer: number;

  /** Cast of the org path and the board name */
  orgPathBoardNameCase: SiteManagementOrgPathBoardCaseType;
}

export interface ISiteManagementConfigurationSchema extends ISiteManagementConfigSchema {
  maxDefaultPlayerCountPerDevice: number;
  minDefaultPlayerCountPerDevice: number;
  maxDefaultOutputCountPerPlayer: number;
  minDefaultOutputCountPerPlayer: number;
}

/** Used as the fallback if fail to load site management config JSON file */
export const DEFAULT_SITE_MANAGEMENT_CONFIG_SCHEMA: ISiteManagementConfigurationSchema = {
  allowDevicesAcrossMultipleBoards: false,
  defaultPlayerCountPerDevice: 4,
  minDefaultPlayerCountPerDevice: 1,
  maxDefaultPlayerCountPerDevice: 10,
  defaultOutputCountPerPlayer: 1,
  minDefaultOutputCountPerPlayer: 1,
  maxDefaultOutputCountPerPlayer: 10,
  orgPathBoardNameCase: 'none',
};

/** Site management module config token */
export interface ISiteManagementConfig {
  /** app base path for the module */
  moduleBasePath: string[];

  /**
   * Relative file path to the site config file.
   * If not provided, will use the default one within the consuming application: assets/config/site-management-config.json
   */
  configFilePath?: string;

  /**
   * Relative file path to the experience template file.
   * If not provided, will use the default one within the consuming application: assets/config/experience-template.json
   */
  experienceTemplatePath?: string;
}

/** Default values of site management module config token */
export const DEFAULT_SITE_MANAGEMENT_CONFIG: ISiteManagementConfig = {
  moduleBasePath: ['app', 'site-management'],
  configFilePath: 'assets/config/site-management-config.json',
  experienceTemplatePath: 'assets/config/experience-template.json',
};

export const SITE_MANAGEMENT_MODULE_CONFIG = new InjectionToken<ISiteManagementConfig>('SITE_MANAGEMENT_MODULE_CONFIG');
