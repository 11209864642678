import { IDataTableColumnConfig } from '@activia/ngx-components';

/** columns for tag key presentation */
export const enum TAG_KEY_COLUMN {
  KEY = 'KEY',
  LEVEL = 'LEVEL',
  TYPE = 'TYPE',
  TITLE = 'TITLE',
  USAGE = 'USAGE',
  DISTINCT_VALUE_USAGE = 'DISTINCT_VALUE_USAGE',
  CREATED_TIME = 'CREATED_TIME',
  LAST_MODIFIED_ON = 'LAST_MODIFIED_ON',
  LAST_MODIFIED_BY = 'LAST_MODIFIED_BY',
  CONSTRAINT = 'CONSTRAINT',
  ACTION = 'ACTION',
  STATUS_INDICATOR = 'STATUS_INDICATOR',
}

export const ENGINE_TAG_OPEATION_COLUMNS: IDataTableColumnConfig<any>[] = [
  {
    id: TAG_KEY_COLUMN.STATUS_INDICATOR,
    name: '',
    sortable: false,
    widthPx: 4,
    padding: '0px',
  },
  {
    id: TAG_KEY_COLUMN.KEY,
    name: 'tagOperation.TAG_OPERATION.TAG_LIST.COLUMNS.KEY_20',
    sortable: true,
    widthRatio: 1,
    prop: 'key',
    sticky: true,
    stickyPosition: 'left',
  },
  {
    id: TAG_KEY_COLUMN.LEVEL,
    name: 'tagOperation.TAG_OPERATION.TAG_LIST.COLUMNS.TAG_LEVEL_20',
    sortable: true,
    widthRatio: 1,
    prop: 'level',
    sticky: true,
    stickyPosition: 'left',
  },
  {
    id: TAG_KEY_COLUMN.TYPE,
    name: 'tagOperation.TAG_OPERATION.TAG_LIST.COLUMNS.TYPE_20',
    sortable: true,
    prop: 'type',
    widthRatio: 1,
  },
  {
    id: TAG_KEY_COLUMN.TITLE,
    name: 'tagOperation.TAG_OPERATION.TAG_LIST.COLUMNS.TITLE_20',
    sortable: true,
    prop: 'description.schema.title',
    widthRatio: 1,
  },
  {
    id: TAG_KEY_COLUMN.DISTINCT_VALUE_USAGE,
    sortable: true,
    name: 'tagOperation.TAG_OPERATION.TAG_LIST.COLUMNS.DISTINCT_VALUE_USAGE_60',
    widthRatio: 1,
    widthPx: 100,
  },
  {
    id: TAG_KEY_COLUMN.CONSTRAINT,
    name: 'tagOperation.TAG_OPERATION.TAG_LIST.COLUMNS.CONSTRAINTS_40',
    sortable: false,
    widthRatio: 1,
  },
  {
    id: TAG_KEY_COLUMN.CREATED_TIME,
    name: 'tagOperation.TAG_OPERATION.TAG_LIST.COLUMNS.CREATED_DATE_40',
    sortable: true,
    prop: 'description.metadata.creationTime',
    widthRatio: 1,
  },
  {
    id: TAG_KEY_COLUMN.LAST_MODIFIED_ON,
    name: 'tagOperation.TAG_OPERATION.TAG_LIST.COLUMNS.LAST_MODIFIED_DATE_40',
    sortable: true,
    prop: 'description.metadata.modificationTime',
    widthRatio: 1,
  },

  {
    id: TAG_KEY_COLUMN.LAST_MODIFIED_BY,
    name: 'tagOperation.TAG_OPERATION.TAG_LIST.COLUMNS.LAST_MODIFIED_BY_40',
    sortable: true,
    widthRatio: 1,
    prop: 'description.metadata.lastModifiedBy',
  },
  {
    id: TAG_KEY_COLUMN.ACTION,
    name: '',
    sortable: false,
    widthPx: 40,
    horizontalAlign: 'right',
    sticky: true,
    stickyPosition: 'right',
  },
];
