<ng-container
  *avnAsyncData="enclosureStatus$ | async;
  state: enclosureStatusDataState$ | async;
  emptyTemplate: showCompactView ? null : emptyTemplate;
  loadingTemplate: loadingTemplate;
  emptyFn: emptyEnclosureStatusFn.bind(this)"
>
  <ng-container *ngIf="!showCompactView; else compactViewTemplate">
    <ng-container *ngIf="(enclosureStatus$ | async).valid; else errorCard">
      <avn-count-card
        [style.height.px]="cardDimensions"
        [style.width.px]="cardDimensions"
        [title]="'siteMonitoringSharedScope.SITE_MONITORING.COLUMNS.KEY_METRICS.ENCLOSURE_STATUS.TITLE_20' | transloco"
        [theme]="(enclosureStatus$ | async).theme"
        [value]="(enclosureStatus$ | async).enclosureCount"
        [footerText]="null"
        [footerTemplate]="dateTemplate"
        [uom]="'OK'"
        [valueSize]="CountCardValueSize.lg"
      >
      </avn-count-card>
    </ng-container>

    <ng-template #errorCard>
      <avn-summary-card
        [header]="'siteMonitoringSharedScope.SITE_MONITORING.COLUMNS.KEY_METRICS.ENCLOSURE_STATUS.TITLE_20' | transloco"
        [footer]="keyMetric.date ? ('siteMonitoringSharedScope.SITE_MONITORING.COLUMNS.KEY_METRICS.ENCLOSURE_STATUS.LAST_UPDATE_20' | transloco: { date: keyMetric?.date | timeAgo | async }) : null"
        [height]="'200px'"
        [width]="'200px'"
        [avnTheme]="(enclosureStatus$ | async).theme"
        [icon]="(enclosureStatus$ | async).icon"
      >
        <div
          class="enclosure-status"
          *ngFor="let status of (enclosureStatus$ | async).count | keyvalue"
          [avnTooltip]="keyMetric.config.customConfig.showTooltip ? status.value.description : null"
          [tooltipPlacement]="'bottom'"
        >
          {{ status.value.count | i18nPlural: i18nMessages[status.key] | transloco: { count: status.value.count } }}
        </div>
      </avn-summary-card>
    </ng-template>
  </ng-container>

  <ng-template #compactViewTemplate>
    <amp-key-metrics-accordion
      class="key-metrics-content"
      [keyMetricsAccordionBorderColor]="(enclosureStatus$ | async).theme"
      [keyMetricsAccordionHeader]="'siteMonitoringSharedScope.SITE_MONITORING.COLUMNS.KEY_METRICS.ENCLOSURE_STATUS.TITLE_20' | transloco"
      [keyMetricsAccordionContent]="accordionTemplate"
    ></amp-key-metrics-accordion>
  </ng-template>
</ng-container>

<ng-template #accordionTemplate>
  <ng-container *ngIf="(enclosureStatus$ | async).enclosureCount; else emptyList">
    <ng-container *ngIf="(enclosureStatus$ | async).valid; else errorList">
      <div class="accordion-content">
        <div class="item inline">
          <ng-container *ngTemplateOutlet="dateTemplate"></ng-container>
        </div>
        <div class="item right">
          <avn-badge
            badgeSize="small"
            avnTheme="success"
            [label]="'siteMonitoringSharedScope.SITE_MONITORING.COLUMNS.KEY_METRICS.ENCLOSURE_STATUS.ENCLOSURE_COUNT_20' | transloco: { count: (enclosureStatus$ | async).enclosureCount }">
          </avn-badge>
        </div>
      </div>
    </ng-container>

    <ng-template #errorList>
      <ng-container *ngFor="let status of (enclosureStatus$ | async).count | keyvalue">
        <div
          class="accordion-content"
          [avnTooltip]="keyMetric.config.customConfig.showTooltip ? status.value.description : null"
          [tooltipPlacement]="'bottom'"
        >
          <div class="item">
            <span>{{ 'siteMonitoringSharedScope.SITE_MONITORING.COLUMNS.KEY_METRICS.ENCLOSURE_STATUS.STATUS.' + status.key.toUpperCase() + '.TITLE_35' | transloco }}</span>
          </div>
          <div class="item right">
            <avn-badge
              badgeSize="small"
              [avnTheme]="status.value.level !== null ? AlarmLevelsInfo[status.value.level].theme : 'success'"
              [label]="status.value.level !== null ?
                (('siteMonitoringSharedScope.SITE_MONITORING.KEY_METRICS.ENCLOSURE_STATUS.ALARM_LEVEL.' + status.value.level + '_15' | transloco) + ' - ' + status.value.count) :
                'siteMonitoringSharedScope.SITE_MONITORING.KEY_METRICS.ENCLOSURE_STATUS.ALARM_LEVEL.OK_15' | transloco"
            ></avn-badge>
          </div>
        </div>
      </ng-container>
    </ng-template>
  </ng-container>
</ng-template>

<ng-template #emptyList>
  <div class="accordion-content">
    <div class="item inline">
      <ng-container *ngTemplateOutlet="dateTemplate"></ng-container>
    </div>
    <div class="item right">
      <ng-container>
        <ng-container *ngTemplateOutlet="notAvailableTemplate"></ng-container>
      </ng-container>
    </div>
  </div>
</ng-template>

<ng-template #dateTemplate>
  <div class="date-container" *ngIf="keyMetric.date; else notAvailableTemplate">
    {{ 'siteMonitoringSharedScope.SITE_MONITORING.COLUMNS.KEY_METRICS.ENCLOSURE_STATUS.LAST_UPDATE_20' | transloco: { date: keyMetric?.date | timeAgo | async } }}
  </div>
</ng-template>

<ng-template #notAvailableTemplate>
  <span class="not-available">{{ 'siteMonitoringSharedScope.SITE_MONITORING.GLOBAL.DATA_NOT_AVAILABLE_20' | transloco }}</span>
</ng-template>

<ng-template #emptyTemplate>
  <ng-container *ngIf="!showCompactView">
    <avn-skeleton
      data-e2e="empty"
      [style.height.px]="_elementRef.nativeElement.parentElement.clientHeight"
      [style.width.px]="_elementRef.nativeElement.parentElement.clientHeight"
      [animate]="false"
      [text]="'siteMonitoringSharedScope.SITE_MONITORING.COLUMNS.KEY_METRICS.ENCLOSURE_STATUS.NOT_AVAILABLE_125' | transloco"
      [skeletonType]="'CUSTOM_SVG'"
    >
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 80 80"></svg>
    </avn-skeleton>
  </ng-container>
</ng-template>

<ng-template #loadingTemplate>
  <div class="key-metric-loading-container">
    <h6 class="title">{{ 'siteMonitoringSharedScope.SITE_MONITORING.KEY_METRICS.ENCLOSURE_STATUS.TITLE_35' | transloco }}</h6>
    <avn-skeleton
      data-e2e="loading"
      [animate]="true"
      [animateDirection]="'VERTICAL'"
      [text]="'siteMonitoringSharedScope.SITE_MONITORING.KEY_METRICS.KEY_METRIC_DATA_LOADING_TEXT_50' | transloco"
      [skeletonType]="'CARD'"
    >
    </avn-skeleton>
  </div>
</ng-template>
