/**
 * Reference: https://confluence.stratacache.com/display/CM/AMP+Player+Alarms
 */
import { ThemeType } from '@activia/ngx-components';

/** Health status for any organization level (e.g. zone, board) */
export interface IOrganizationLevelHealthStatus {
  status?: OrganizationLevelHealthStatus;
  icon: string;
  theme: ThemeType;
  tooltip?: string; // TODO: might not needed anymore
  count?: number; // TODO: might not needed anymore
  total?: number; // TODO: might not needed anymore
}

export enum OrganizationLevelHealthStatus {
  OK = 0,
  WARNING = 1,
  ERROR = 2,
}

export interface IOrganizationLevelHealthStatusTheme {
  icon: string;
  theme: ThemeType;
}

export const OrganizationLevelHealthStatusThemes: { [key in OrganizationLevelHealthStatus]: IOrganizationLevelHealthStatusTheme } = {
  [OrganizationLevelHealthStatus.OK]: { icon: 'action:check_circle', theme: ThemeType.SUCCESS },
  [OrganizationLevelHealthStatus.WARNING]: { icon: 'alert:warning', theme: ThemeType.WARNING },
  [OrganizationLevelHealthStatus.ERROR]: { icon: 'alert:error', theme: ThemeType.DANGER },
};
