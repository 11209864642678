import {AuthFacade} from './store/auth.facade';
import {IAuthority} from './authority.interface';
import {combineLatest, Observable, of} from 'rxjs';
import {mergeMap, switchMap, take} from 'rxjs/operators';

/**
 * Verify if user has any of the specific permissions required.
 * Return false when user has no permissions, or no required permissions specified.
 */
const hasRoleRequired = (rolesRequired: Array<string>, userRoles: Array<string>): boolean => {
  for (let i = 0; i < rolesRequired.length; i++) {
    for (let j = 0; j < userRoles.length; j++) {
      if (userRoles[j] === rolesRequired[i]) {
        return true;
      }
    }
  }
  return false;
}

/**
 * Verify if user has any of the specific permissions required.
 * Return false when user has no permissions, or no required permissions specified.
 */
const hasPermissionsRequired = (permissionsRequired: Array<string>, userPermissions: Array<string>): boolean => {
  for (let i = 0; i < permissionsRequired.length; i++) {
    for (let j = 0; j < userPermissions.length; j++) {
      if (userPermissions[j] === permissionsRequired[i]) {
        return true;
      }
    }
  }
  return false;
}

/**
 * This function checks if user has certain role, and also certain permissions
 * User by RoleGuard and other places where certain roles + permissions are required
 */
export const hasRolePermission = (authFacade: AuthFacade, authoritiesRequired: IAuthority): Observable<boolean> => {
  if (!authoritiesRequired || !authoritiesRequired?.roles?.length && !authoritiesRequired?.permissions?.length) {
    return of(true);
  }

  return authFacade.authStateReady$.pipe(
    take(1),
    switchMap(() => combineLatest([authFacade.authenticatedUserRoles$, authFacade.authenticatedUserPermissions$])),
    mergeMap(([roles, userPermissions]) => {
      if (roles && hasRoleRequired(authoritiesRequired?.roles || [], roles.map((role) => role.name))) {
        return of(true);
      } else if (hasPermissionsRequired(authoritiesRequired?.permissions || [], userPermissions)) {
        return of(true);
      } else {
        return of(false);
      }
    })
  );
};
