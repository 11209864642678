import { SiteDTO } from '@activia/cm-api';
import { Observable, of } from 'rxjs';
import { switchMap, take } from 'rxjs/operators';
import { ValidationErrors } from '@angular/forms';

const areEqual = (val1: string, val2: string) => {
  const value1 = String(val1);
  const value2 = String(val2);
  return (value1 ?? '').toLowerCase() === (value2 ?? '').toLowerCase();
};

/**
 * Checks if the specified property value is unique within an array of SiteDTO objects.
 */
export const isSitePropertyUnique = (sites$: Observable<SiteDTO[]>, siteId: number, value: string, field: string, subField?: string): Observable<ValidationErrors | null> => {
  if (!value) {
    return of(null);
  }

  return sites$.pipe(
    take(1),
    switchMap((sites) => {
      let hasDuplicate = false;

      for (const site of sites) {
        const hasSameValue = areEqual(!subField ? site?.[field] : site?.[field]?.[subField], value);
        if (hasSameValue && (!siteId || site.id !== siteId)) {
          hasDuplicate = true;
          break;
        }
      }

      return hasDuplicate ? of({ duplicate: true, field }) : of(null);
    })
  );
};
