<avn-data-table [columns]="columns" [dataSource]="dataSource" [rowHeight]="35" [keyboardNavigationEnabled]="true" [headerTooltipsEnabled]="true" [infiniteScrollEnabled]="false"> </avn-data-table>

<ng-template #requiredField let-row="row">
  <ng-container *ngIf="!!row.required; else emptyValue">
    <div class="centered-field">
      <avn-icon [icon]="'action:check_circle'" [avnTheme]="'success'"></avn-icon>
    </div>
  </ng-container>
</ng-template>
<ng-template #uniqueField let-row="row">
  <ng-container *ngIf="!!row.unique; else emptyValue">
    <div class="centered-field">
      <avn-icon [icon]="'action:check_circle'" [avnTheme]="'success'"></avn-icon>
    </div>
  </ng-container>
</ng-template>

<ng-template #emptyValue>
  <div class="centered-field">
    <span>-</span>
  </div>
</ng-template>
