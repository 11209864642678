import { AsyncDataState, ItemPerSlideConfig } from '@activia/ngx-components';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { combineLatest, Observable, Subject, take } from 'rxjs';
import { SiteMonitoredValue } from '../../../model/site-monitored-value.enum';
import { SiteMonitoringKeyMetric } from '../../../model/site-monitoring-key-metric.enum';
import { ISiteMonitoringKeyMetricViewerData } from '../../../model/site-monitoring-key-metric-viewer-data.interface';
import { KeyMetricsViewerService } from './key-metrics-viewer.service';

@Component({
  selector: 'amp-key-metrics-viewer',
  templateUrl: './key-metrics-viewer.component.html',
  styleUrls: ['./key-metrics-viewer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SiteMonitoringKeyMetricsViewerComponent implements OnDestroy {

  @Input() set siteId(id: number) {
    this._fetchKeyMetricsData(id);
  }

  /** carousel configuration. Defaults to a full screen config. */
  @Input() carouselSlideConfig: ItemPerSlideConfig = {
    handset: 1,
    tablet: 2,
    web: 3,
    huge: 4,
    yuge: 5,
    gigantic: 9,
  };

  /** Indicates if the carousel is enabled **/
  @Input() carouselEnabled = true;

  /** height for the viewer (charts may need a fixed height to fill their container, depending on the parent container). **/
  @Input() height: number;

  /** display as compact view */
  @Input() showCompactView = false;

  /** indicates if the viewer has anything to show so that apps consumming this as an element can show / hide as required  */
  @Output() metricsState: EventEmitter<{ dataState: AsyncDataState; hasData: boolean }> = new EventEmitter();

  // Visible key metrics with config info and monitored data. Key: key metric ID
  keyMetrics$: Observable<ISiteMonitoringKeyMetricViewerData[]>;

  keyMetricsDataState$: Observable<AsyncDataState>;

  /** @ignore for use in template **/
  SiteMonitoringKeyMetric = SiteMonitoringKeyMetric;

  /** @ignore for use in template **/
  SiteMonitoredValue = SiteMonitoredValue;

  /** @ignore **/
  private componentDestroyed$ = new Subject<void>();

  constructor(private _keyMetricsViewerService: KeyMetricsViewerService) {
  }

  /** A function that indicates if key metrics are empty **/
  emptyKeyMetricsFn(keyMetrics: ISiteMonitoringKeyMetricViewerData[]): boolean {
    return (keyMetrics || []).length === 0;
  }

  ngOnDestroy() {
    this.componentDestroyed$.next();
    this.componentDestroyed$.complete();
  }

  private _fetchKeyMetricsData(siteId: number) {
    const { keyMetrics$, keyMetricsDataState$ } = this._keyMetricsViewerService.getSiteKeyMetrics(siteId);
    this.keyMetrics$ = keyMetrics$;
    this.keyMetricsDataState$ = keyMetricsDataState$;

    combineLatest([keyMetrics$, keyMetricsDataState$])
    .pipe(take(1)).subscribe(([keyMetrics, dataState]) => {
      this.metricsState.emit({ dataState, hasData: !((keyMetrics || []).length === 0) });
    });

  }
}
