import { ChangeDetectionStrategy, Component, EventEmitter, Inject, Input, OnChanges, OnDestroy, Output, SimpleChanges } from '@angular/core';
import { SiteMonitoringFacade } from '../../../store/site-monitoring.facade';
import { Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { RouterFacade } from '@amp/router-store';
import { ISiteMonitoringConfig, SITE_MONITORING_MODULE_CONFIG } from '@amp/environment';
import { IBoardAlarm, IDeviceAlarm } from '../../../model/alarm-event.interface';
import { IAlarmEventDisplayConfig } from '@amp/devices';
import { ICombinedDeviceInfo } from '../../site-monitoring-detail/store/site-monitoring-detail.model';
import { OrganizationLevelHealthStatus } from '../../../model/organization-level-health';

@Component({
  selector: 'amp-board-alarms',
  templateUrl: './board-alarms.component.html',
  styleUrls: ['./board-alarms.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BoardAlarmsComponent implements OnChanges, OnDestroy {
  @Input() siteId: number;

  /** Alarms from all the board in a given site **/
  _boards: Array<IBoardAlarm>;
  get boards(): Array<IBoardAlarm> {
    return this._boards;
  }
  @Input() set boards(value: Array<IBoardAlarm>) {
    // Sort list based on the organizationPath
    const sortedList = value.sort((a, b) => (a.organizationPath.join() + a.name).localeCompare(b.organizationPath.join() + b.name));

    this._boards = sortedList;
  }

  /** Combined info about all the devices of the board.
   * - Will be set if the component is called from the site detail (we have contacted the device already.)
   * - Will be empty if the component is called from the site list drawer (we haven't contacted the device) **/
  @Input() boardDevicesInfo: ICombinedDeviceInfo[];

  /**
   * Format of the board header title:
   * - fullOrgPath: display the full org path as the board header title
   * - boardNameOnly: display only the board name as the board header title
   */
  @Input() boardHeaderStyle: 'fullOrgPath' | 'boardNameOnly' = 'fullOrgPath';

  /**
   * True to expand an accordion panel by default
   */
  @Input() expand = false;

  /**
   * True to disable the expansion of the accordion
   */
  @Input() disableExpansion = false;

  /**
   * True to show Details button on the accordion header
   */
  @Input() showDetailsButton = true;

  @Output() viewDetail: EventEmitter<void> = new EventEmitter<void>();

  OrganizationLevelHealthStatus = OrganizationLevelHealthStatus;

  /**
   * Indicates the device to displays mapping with connector lines is enabled
   * **/
  showBoardPlayerMapping$: Observable<boolean>;

  /** Configuration of alarm messages */
  alarmDisplayConfig$: Observable<IAlarmEventDisplayConfig>;

  boardHeaderOpenMap = new Map<number, boolean>();

  private componentDestroyed$: Subject<void> = new Subject<void>();

  constructor(@Inject(SITE_MONITORING_MODULE_CONFIG) private siteMonitoringConfig: ISiteMonitoringConfig, private routerFacade: RouterFacade, private siteMonitoringFacade: SiteMonitoringFacade) {
    this.showBoardPlayerMapping$ = this.siteMonitoringFacade.preference$.pipe(map((preferences) => preferences.showBoardPlayerMapping));
    this.alarmDisplayConfig$ = this.siteMonitoringFacade.alarmDisplayConfig$;
  }

  ngOnChanges({ boards }: SimpleChanges): void {
    if (boards?.currentValue) {
      boards.currentValue.forEach((board: IBoardAlarm) => {
        this.boardHeaderOpenMap.set(board.id, this.expand || board.boardStatus.status === OrganizationLevelHealthStatus.ERROR);
      });
    }
  }

  ngOnDestroy(): void {
    this.componentDestroyed$.next();
    this.componentDestroyed$.complete();
  }

  onDetailsClicked($event: { stopPropagation: () => void }, boardId: number): void {
    // Prevents the default click action on accordion header (expand/collapse)
    $event.stopPropagation();
    this.routerFacade.navigate({ path: [...this.siteMonitoringConfig.moduleBasePath, 'detail', this.siteId], query: { board: boardId }, extras: { queryParamsHandling: 'merge' } });
    this.viewDetail.emit();
  }

  toDeviceInfo(deviceAlarm: IDeviceAlarm): ICombinedDeviceInfo {
    // returns the already computed info about the device if we have it
    const combinedDeviceInfo = this.boardDevicesInfo?.find((deviceInfo) => deviceInfo.device.deviceId === deviceAlarm.deviceId);
    if (combinedDeviceInfo) {
      return combinedDeviceInfo;
    }
    return {
      device: deviceAlarm.device,
      monitoringData: null, // needs to be fetched on the api
      logicalPlayers: null,
      deviceLoading: null,
      time: new Date().toUTCString(),
      alarms: deviceAlarm.alarms.map((alarm) => ({ alarm: 'event', data: alarm })),
    } as ICombinedDeviceInfo;
  }

  toggleExpanded(event: { opened: boolean }, boardId: number): void {
    this.boardHeaderOpenMap.set(boardId, event.opened);
  }

  getBreadcrumbSections(sections: string[]) {
    return sections.map((e) => ({ value: e }));
  }
}
