import { Component, Output, EventEmitter, Inject } from '@angular/core';
import { IModalComponent, IModalConfig, MODAL_CONFIG, ModalRef } from '@activia/ngx-components';
import { GlobalConfigConfirmationType, GlobalConfigConfirmationTypes } from './global-config-confirmation-types.interface';
import { getMismatchColumns, getMismatchKeyMetrics } from '../../utils/site-monitoring-global-optimistic-view.utils';
import { ISiteMonitoringKeyMetricConfig } from '../../model/site-monitoring-key-metric-config.interface';
import { ISiteMonitoringListColumnConfig } from '../../model/site-monitoring-list-column-config.interface';
import { SiteMonitoringColumnType } from '../../model/site-monitoring-column-type';
import { SiteMonitoringProfile } from '../../model/site-monitoring-profile.type';

@Component({
  selector: 'amp-site-global-config-confirmation',
  templateUrl: './site-global-config-confirmation.component.html',
  styleUrls: ['./site-global-config-confirmation.component.scss'],
})
export class SiteGlobalConfigConfirmationComponent implements IModalComponent {
  @Output() actioned = new EventEmitter<GlobalConfigConfirmationType>();
  @Output() cancelled = new EventEmitter<void>();

  confirmationTypes = GlobalConfigConfirmationTypes;
  SiteMonitoringColumnType = SiteMonitoringColumnType;

  mismatchColumns: ISiteMonitoringListColumnConfig[];
  mismatchKeyMetrics: ISiteMonitoringKeyMetricConfig[];
  profile: SiteMonitoringProfile;

  configMessage: { [key: string]: string } = {
    '=1': 'siteMonitoringSharedScope.SITE_MONITORING.PREFERENCE.GLOBAL_CONFIG_CONFIRMATION.MESSAGE.SINGULAR_500',
    other: 'siteMonitoringSharedScope.SITE_MONITORING.PREFERENCE.GLOBAL_CONFIG_CONFIRMATION.MESSAGE.PLURAL_500',
  };
  columnI18n: { [key: string]: string } = {
    '=0': '',
    '=1': 'siteMonitoringSharedScope.SITE_MONITORING.COLUMNS.SINGULAR_10',
    other: 'siteMonitoringSharedScope.SITE_MONITORING.COLUMNS.PLURAL_10',
  };
  keyMetricI18n: { [key: string]: string } = {
    '=0': '',
    '=1': 'siteMonitoringSharedScope.SITE_MONITORING.KEY_METRICS.SINGULAR_10',
    other: 'siteMonitoringSharedScope.SITE_MONITORING.KEY_METRICS.PLURAL_10',
  };

  constructor(
    private _dialogRef: ModalRef<SiteGlobalConfigConfirmationComponent>,
    @Inject(MODAL_CONFIG)
    public dialogConfig: IModalConfig<{
      defaultToOptimisticView: boolean;
      columns: ISiteMonitoringListColumnConfig[];
      keyMetrics: ISiteMonitoringKeyMetricConfig[];
      profile: SiteMonitoringProfile;
    }>
  ) {
    this.mismatchColumns = getMismatchColumns(this.dialogConfig.data.defaultToOptimisticView, this.dialogConfig.data.columns);
    this.mismatchKeyMetrics = getMismatchKeyMetrics(this.dialogConfig.data.defaultToOptimisticView, this.dialogConfig.data.keyMetrics);
    this.profile = this.dialogConfig.data.profile;
  }

  canClose(): boolean {
    return false;
  }

  onSave(type: GlobalConfigConfirmationType) {
    this.actioned.emit(type);
    this._dialogRef.close();
  }

  onCancel() {
    this.cancelled.emit();
    this._dialogRef.close();
  }
}
