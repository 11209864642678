import { HttpParameterCodec, HttpUrlEncodingCodec } from '@angular/common/http';

export class CmApiCustomHttpParameterCodec extends HttpUrlEncodingCodec implements HttpParameterCodec {
  constructor() {
    super();
  }
  encodeValue(v: string): string {
    const vEncoded = super.encodeValue(v).replace(/[=+]/g, (c) => '%' + c.charCodeAt(0).toString(16));
    return vEncoded;
  }
}
