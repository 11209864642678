<ng-template #hostnameIp let-row="row">
  <ng-container *ngTemplateOutlet="hostnameIpTemplate; context: { row: row, withUrl: false }"></ng-container>
</ng-template>

<ng-template #hostnameIpWithLink let-row="row">
  <ng-container *ngTemplateOutlet="hostnameIpTemplate; context: { row: row, withUrl: true }"></ng-container>
</ng-template>

<ng-template #hostnameIpTemplate let-row="row" let-withUrl="withUrl">
  <amp-device-field-templates
    [displayType]="withUrl ? DisplayType.HostnameIp : DisplayType.TwoLines"
    [value]="{ line1: row.deviceInfo.hostname, line2: row.deviceInfo.ipAddress }"
    [url]="withUrl ? '/app/monitoring/detail/' + row.deviceInfo.deviceId : null">
  </amp-device-field-templates>
</ng-template>

<ng-template #operationalState let-row="row">
  <amp-device-field-templates [field]="DeviceProperty.OperationalState" [badgeSize]="badgeSize" [value]="row.deviceInfo.operationalState"></amp-device-field-templates>
</ng-template>

<ng-template #serialMac let-row="row">
  <div style="display: flex; flex-direction: column;">
    <span>{{ 'deviceFields.DEVICE.PROPERTY.MEDIA_PLAYER.SERIAL' | transloco }} {{ row?.deviceInfo?.serial }}</span>
    <span>{{ 'deviceFields.DEVICE.PROPERTY.MEDIA_PLAYER.MAC' | transloco }} {{ row?.deviceInterfaces?.macAddress }}</span>
  </div>
</ng-template>
