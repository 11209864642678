import {
  ApplicationPreferencesService,
  DeviceService,
  CurrentUserService,
} from '@activia/cm-api';
import { AuthFacade, CMRole } from '@amp/auth';
import { DeviceFilterApiExpressionService } from '../nlp/device-filter/device-filter-api-expression.service';
import { MONITORING_NLP_DEVICE_FIELDS } from '../nlp/monitoring-nlp-fields';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { forkJoin, of } from 'rxjs';
import { catchError, map, mergeMap, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { getApplicationPreferencesByKey, getUserPreferencesByKey } from '@amp/utils/common';
import { ErrorHandlingService } from '@amp/error';
import * as DeviceAction from './devices.actions';
import { DevicesFacade } from './devices.facade';
import { IMonitoringSharedList, SHARED_TO_ALL } from '../model/monitoring-list.interface';
import { SiteManager } from '../nlp/device-filter/device-filter.tokens';
import { TaskPanelService } from '@activia/ngx-components';
import { ExportDevicesTaskPanelItemComponent } from '../components/export-devices-task-panel-item/export-devices-task-panel-item.component';
import { DownloadLogsTaskPanelItemComponent } from '../components/download-logs/download-logs-task-panel-item/download-logs-task-panel-item.component';

@Injectable()
export class DevicesEffects {

  fetchSharedLists$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DeviceAction.DevicesFetchSharedLists),
      switchMap((key) => forkJoin([
        getUserPreferencesByKey<any>(this.currentUserService, key.userSharedListsKey, []),
        getApplicationPreferencesByKey<any>(this.appPreferencesService, key.appSharedListsKey, []),
      ]).pipe(
        withLatestFrom(this.authFacade.authenticatedUser$, this.authFacade.authenticatedUserRoles$),
        map(([[listIds, sharedLists], user, roles]) => {
          let userListIds = listIds;
          const appLists = sharedLists;
          const currentUser = user.username;
          const shared: IMonitoringSharedList = { new: [], dataset: [] };
          // find the list shared to current user
          if (roles.find((role) => role.name === CMRole.ROLE_ADMIN)) {
            shared.dataset = appLists;
          } else {
            // current user not admin, check each list if shared to him
            shared.dataset = appLists.filter((list) => (list.sharedTo === SHARED_TO_ALL || currentUser) && list.active);
            userListIds = userListIds.filter((userListId) => shared.dataset.find((list) => list.id === userListId));
            shared.new = shared.dataset.filter((list) => !userListIds.find((id) => id === list.id)).map((list) => list.id);
          }
          return DeviceAction.DevicesFetchSharedListsSuccess({ data: shared });
        }),
        catchError((err) => {
          this.errorHandlingService.catchError(err);
          return of(DeviceAction.DevicesFetchSharedListsFail(err));
        })
      ))
    )
  );

  updateSharedListCount$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DeviceAction.DevicesUpdateSharedListCount),
      withLatestFrom(this.devicesFacade.sharedLists$),
      mergeMap(([action, sharedLists]) => {
        const sharedList = sharedLists.filter((list) => list.id === action.sharedListId)?.[0];

        const optionalFilter = action.managerId ? sharedList.filter?.length > 0 ? `& ${SiteManager.PATTERN}=${action.managerId}` : `${SiteManager.PATTERN}=${action.managerId}` : null;
        const _filter = this.deviceFilterApiExpressionService.getDeviceFilter(sharedList.filter, sharedList.deviceTypes, MONITORING_NLP_DEVICE_FIELDS, true, optionalFilter);
        const countRequest$ =
          (_filter || '').trim().length === 0 ? this.deviceService.getDeviceCountForFilter(sharedList.deviceGroupId) : this.deviceService.getDeviceCountForFilter(sharedList.deviceGroupId, _filter);

        return countRequest$.pipe(
          map((deviceCountDTO) => DeviceAction.DevicesUpdateSharedListCountSuccess({ sharedListId: action.sharedListId, count: deviceCountDTO.count })),
          catchError((err) => {
            this.errorHandlingService.catchError(err);
            return of(DeviceAction.DevicesUpdateSharedListCountFail({ sharedListId: action.sharedListId, errorMessage: err?.message }));
          })
        );
      })
    )
  );

  exportCsv$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(DeviceAction.DevicesAddExportTask),
        tap(() => {
          this.taskPanelService.addTaskComponent(ExportDevicesTaskPanelItemComponent);
        })
      ),
    { dispatch: false }
  );

  downloadLogs$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(DeviceAction.DevicesAddDownloadLogsTask),
        tap(() => {
          this.taskPanelService.addTaskComponent(DownloadLogsTaskPanelItemComponent);
        })
      ),
    { dispatch: false }
  );

  constructor(
    private actions$: Actions,
    private deviceService: DeviceService,
    private authFacade: AuthFacade,
    private currentUserService: CurrentUserService,
    private appPreferencesService: ApplicationPreferencesService,
    private deviceFilterApiExpressionService: DeviceFilterApiExpressionService,
    private errorHandlingService: ErrorHandlingService,
    private devicesFacade: DevicesFacade,
    private taskPanelService: TaskPanelService,
  ) {}
}
