<avn-detail-layout *ngIf="node$ | async as node">
  <avn-detail-header [title]="node | applyFunction : getHeaderTitle$ : [] : this | async" (closed)="onClose()"> </avn-detail-header>

  <avn-detail-content avnScroll>
    <!-- Choose between Name or Tag -->
    <div class="node-editor-section node-editor-options">
      <div class="node-editor-option-box" [class.disabled]="!!node?.childOneOf?.length" [class.highlight]="node.property === propertyTypes.NAME" (click)="updateTagProperty(propertyTypes.NAME)">
        <h5>{{ 'siteManagementScope.SITE_MANAGEMENT.GLOBAL.ORGANIZATIONAL_PATH.ORGPATH_EDITOR.NODE_EDITOR.SITE_NAME_30' | transloco }}</h5>
        <span> {{ 'siteManagementScope.SITE_MANAGEMENT.GLOBAL.ORGANIZATIONAL_PATH.ORGPATH_EDITOR.NODE_EDITOR.SITE_PROPERTY_DESCRIPTION_TEXT_100' | transloco }}</span>
      </div>

      <div class="node-editor-option-box" [class.highlight]="!!node.tag || node.tag === ''" (click)="updateTagProperty(propertyTypes.TAG)">
        <h5>{{ 'siteManagementScope.SITE_MANAGEMENT.GLOBAL.ORGANIZATIONAL_PATH.ORGPATH_EDITOR.NODE_EDITOR.SITE_TAG_30' | transloco }}</h5>
        <span> {{ 'siteManagementScope.SITE_MANAGEMENT.GLOBAL.ORGANIZATIONAL_PATH.ORGPATH_EDITOR.NODE_EDITOR.SITE_TAG_DESCRIPTION_TEXT_100' | transloco }}</span>
      </div>
    </div>

    <ng-container *ngIf="!!node.tag || node.tag === '' || node.property === propertyTypes.NAME">
      <!-- Condition appear only if there is a parent to the node -->
      <div class="node-editor-section" *ngIf="node.parent">
        <!-- Condition -->
        <div class="node-editor-condition-header">
          <h5>{{ 'siteManagementScope.SITE_MANAGEMENT.GLOBAL.ORGANIZATIONAL_PATH.ORGPATH_EDITOR.NODE_EDITOR.CONDITION_30' | transloco }}</h5>
          <avn-switch [value]="!!node.dependentItem" avnTheme="primary" [disabled]="!editable" (statusChanged)="onConditionChange($event)"></avn-switch>
        </div>
        <div *ngIf="node.dependentItem || enableCondition">
          <div class="node-editor-condition-description">
            <avn-icon icon="action:info"></avn-icon>
            <span>
              {{
                'siteManagementScope.SITE_MANAGEMENT.GLOBAL.ORGANIZATIONAL_PATH.ORGPATH_EDITOR.NODE_EDITOR.CONDITION_DESCRIPTION_100'
                  | transloco : { value: (node.parent.tag | applyFunction : getTagTitle$ : [] : this | async) }
              }}
            </span>
          </div>
          <ng-container *ngIf="parentSchema$ | async as parentJsonSchema">
            <avn-json-schema-control-field [jsonSchema]="parentJsonSchema" [formControl]="dependentFormControl" [required]="true" (changed)="updateCondition()"></avn-json-schema-control-field>
          </ng-container>
        </div>
      </div>

      <!-- Select Tag/Property -->
      <div class="node-editor-section" *ngIf="node.property === propertyTypes.NAME || !!node.tag || node.tag === ''">
        <div class="node-editor-tag-header">
          <h5 *ngIf="node.property === propertyTypes.NAME">{{ 'siteManagementScope.SITE_MANAGEMENT.GLOBAL.ORGANIZATIONAL_PATH.ORGPATH_EDITOR.NODE_EDITOR.SITE_NAME_30' | transloco }}</h5>
          <h5 *ngIf="!!node.tag || node.tag === ''">{{ 'siteManagementScope.SITE_MANAGEMENT.GLOBAL.ORGANIZATIONAL_PATH.ORGPATH_EDITOR.NODE_EDITOR.SITE_TAG_30' | transloco }}</h5>
        </div>
        <div class="node-editor-tag" *ngIf="!!node.tag || node.tag === ''">
          <avn-select
            class="node-editor-select-tag"
            [placeholder]="'siteManagementScope.SITE_MANAGEMENT.GLOBAL.ORGANIZATIONAL_PATH.ORGPATH_EDITOR.NODE_EDITOR.PLACEHOLDER_SELECT_100' | transloco"
            [options]="tagsOptions$ | async"
            [value]="node.tag || node.property"
            [disabled]="!editable"
            (change)="updateTagProperty($event?.value)"
          ></avn-select>
          <ng-container *ngIf="!!node.tag || node.tag === ''">
            <ng-container *ngIf="editable">
              <avn-icon [disabled]="!node.tag" [fontSize]="24" class="edit-tag" icon="action:edit" (click)="openEditPanel()"></avn-icon>
              <avn-icon [fontSize]="24" class="add-tag" icon="action:add_circle" (click)="openAddPanel()"></avn-icon>
            </ng-container>
          </ng-container>
        </div>

        <ng-container *ngIf="node.property === propertyTypes.NAME">
          <div class="node-editor-property-schema" *ngIf="nodeSchema$ | async as nodeSchema">
            <avn-json-schema-editor
              #jsonSchemaEditor
              (changed)="updateSchema($event)"
              [jsonSchema]="nodeSchema"
              [formControl]="jsonSchemaEditorControl"
              [portraitMode]="true"
              [constraintTypeOptions]="schemaEditorConstraintTypeOptions"
              [labels]="{
                typeField: 'tagOperation.TAG_OPERATION.TAG_DETAIL.CONSTRAINT_TYPES_40' | transloco,
                constraintsHeader: 'tagOperation.TAG_OPERATION.TAG_DETAIL.CONSTRAINTS_40' | transloco,
                descriptionHeader: 'tagOperation.TAG_OPERATION.TAG_DETAIL.DESCRIPTION_40' | transloco
              }"
            ></avn-json-schema-editor>
          </div>
        </ng-container>
      </div>
    </ng-container>
  </avn-detail-content>
</avn-detail-layout>
