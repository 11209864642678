import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, Optional } from '@angular/core';
import { FEATURE_TOGGLE_OPTIONS, IFeatureToggleOptions } from './feature-toggle.token';

@Injectable({
  providedIn: 'root',
})
export class FeatureToggleService {
  private features: any = {};

  constructor(private httpClient: HttpClient, @Inject(FEATURE_TOGGLE_OPTIONS) @Optional() private config: IFeatureToggleOptions) {}

  /**
   * given a key (feature name) will return if the feature should is active or not.
   */
  isOn(key: string): boolean {
    let pass = this.features;
    (key ?? '').split('.').map((item) => {
      pass = pass && pass[item];
    });
    return pass === true || !!this.features[key];
  }

  /**
   * loads features file specified in the config, if not provided, loads from the features.json as default
   */
  load(): Promise<any> {
    return new Promise((res, rej) => {
      this.httpClient.get(this.config?.configFilePath || 'assets/features.json').subscribe(
        (r) => {
          this.features = r;
          res(r);
        },
        (e) => rej(e)
      );
    });
  }
}
