import { SiteDTO } from '@activia/cm-api';
import { getCurrentRoute } from '@amp/router-store';
import { LoadingState } from '@activia/ngx-components';
import { createFeatureSelector, createSelector, select } from '@ngrx/store';
import { ISiteManagementState } from './site-management.reducer';

const siteManagementSelector = createFeatureSelector<ISiteManagementState>('siteManagement');

const siteConfigData = createSelector(siteManagementSelector, (state) => state.siteConfig.data);
const siteConfigDataState = createSelector(siteManagementSelector, (state) => state.siteConfig.state);

const allSites = createSelector(siteManagementSelector, (state) => state.allSites.data);
const allSitesData = createSelector(allSites, (sitesMap) => Object.values(sitesMap));
const allSitesDataState = createSelector(siteManagementSelector, (state) => state.allSites.state);
const allSitesLoaded = createSelector(allSitesDataState, (state) => state === LoadingState.LOADED);

const dataRefreshIntervalMs = createSelector(siteManagementSelector, (state) => state.refreshIntervalMs);
const siteListSort = createSelector(siteManagementSelector, (state) => state.siteListSort);

const currSiteData = createSelector(siteManagementSelector, (state) => state.allSites.data[state.siteDetail.siteId.data]);
const currSiteDataState = createSelector(siteManagementSelector, (state) => state.siteDetail?.siteId?.state);

const createTemplateBoardsStatus = createSelector(siteManagementSelector, (state) => state.siteDetail.createTemplateBoardsStatus);
const deleteSiteIds = createSelector(siteManagementSelector, (state) => state.deleteSiteIds);

const selectedCurrentSite = createSelector(getCurrentRoute, allSites, (router, sites): SiteDTO => {
  if (router?.data?.module === 'site-management') {
    return sites[router.params.siteId];
  }
});

const selectedSiteIds = createSelector(siteManagementSelector, (state): string[] => state?.selectedSiteIds);
const selectedSites = createSelector(siteManagementSelector, (state): SiteDTO[] => state?.selectedSiteIds.map((siteId) => state.allSites.data[siteId]));

export const siteManagementSelectors = {
  allSites,
  allSitesData,
  allSitesDataState,
  allSitesLoaded,
  createTemplateBoardsStatus,
  currSiteData,
  currSiteDataState,
  dataRefreshIntervalMs,
  deleteSiteIds,
  siteConfigData,
  siteConfigDataState,
  siteListSort,
  selectedCurrentSite,
  selectedSites,
  selectedSiteIds,
};

const siteConfigData$ = select(siteManagementSelectors.siteConfigData);
const siteConfigDataState$ = select(siteManagementSelectors.siteConfigDataState);
const allSitesData$ = select(siteManagementSelectors.allSitesData);
const allSitesDataState$ = select(siteManagementSelectors.allSitesDataState);
const dataRefreshIntervalMs$ = select(siteManagementSelectors.dataRefreshIntervalMs);
const siteListSort$ = select(siteManagementSelectors.siteListSort);
const currSiteData$ = select(siteManagementSelectors.currSiteData);
const currSiteDataState$ = select(siteManagementSelectors.currSiteDataState);
const selectedCurrentSite$ = select(siteManagementSelectors.selectedCurrentSite);
const selectedSites$ = select(siteManagementSelectors.selectedSites);
const selectedSiteIds$ = select(siteManagementSelectors.selectedSiteIds);

export const siteManagementEntities = {
  siteConfigData$,
  siteConfigDataState$,
  allSitesData$,
  allSitesDataState$,
  dataRefreshIntervalMs$,
  siteListSort$,
  currSiteData$,
  currSiteDataState$,
  selectedCurrentSite$,
  selectedSites$,
  selectedSiteIds$,
};
