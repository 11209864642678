import { SiteStatesDTO } from '@activia/cm-api';
import { HealthStatusCode, HealthStatusIcons, HealthStatusThemes } from '@amp/devices';
import { CountAggregationData } from '../model/site-monitoring-data.interface';
import { IOrganizationLevelHealthStatus } from '../model/organization-level-health';

/** filter on health status of a give orgPath, and calculate it's indicator including theme, icon tooltip*/
export const updateOrgLevelHealthStatus = (status: CountAggregationData<HealthStatusCode, number>, optimisticView: boolean): IOrganizationLevelHealthStatus => {
  status = status || {};

  const i18nMessageMap = {
    error: {
      singular: 'siteMonitoringSharedScope.SITE_MONITORING.GLOBAL.HEALTH_STATUS.INDICATOR.ERROR.SINGULAR_100',
      plural: 'siteMonitoringSharedScope.SITE_MONITORING.GLOBAL.HEALTH_STATUS.INDICATOR.ERROR.PLURAL_100',
      all: 'siteMonitoringSharedScope.SITE_MONITORING.GLOBAL.HEALTH_STATUS.INDICATOR.ERROR.ALL_100',
    },
    warning: {
      singular: 'siteMonitoringSharedScope.SITE_MONITORING.GLOBAL.HEALTH_STATUS.INDICATOR.WARNING.SINGULAR_100',
      plural: 'siteMonitoringSharedScope.SITE_MONITORING.GLOBAL.HEALTH_STATUS.INDICATOR.WARNING.PLURAL_100',
      all: 'siteMonitoringSharedScope.SITE_MONITORING.GLOBAL.HEALTH_STATUS.INDICATOR.WARNING.ALL_100',
    },
    ok: {
      singular: 'siteMonitoringSharedScope.SITE_MONITORING.GLOBAL.HEALTH_STATUS.INDICATOR.OK.SINGULAR_100',
      plural: 'siteMonitoringSharedScope.SITE_MONITORING.GLOBAL.HEALTH_STATUS.INDICATOR.OK.PLURAL_100',
    },
  };

  const statusMap = Object.entries(status).reduce((res, [k, value]) => {
    let key = +k;
    switch (key) {
      case HealthStatusCode.WARNING:
        key = optimisticView ? HealthStatusCode.OK : HealthStatusCode.WARNING;
        break;
      case HealthStatusCode.UNREACHABLE:
        key = optimisticView ? HealthStatusCode.ERROR : HealthStatusCode.UNREACHABLE;
        break;
      default:
        break;
    }
    if (res[key]) {
      res[key] = res[key] + value;
    } else {
      res[key] = value;
    }
    return res;
  }, {});

  const totalCount = Object.entries(status).reduce((res, [_, value]) => {
    res = res + value;
    return res;
  }, 0);

  if (statusMap[HealthStatusCode.ERROR]) {
    const count = statusMap[HealthStatusCode.ERROR];
    const msg = count === totalCount ? i18nMessageMap.error.all : count === 1 ? i18nMessageMap.error.singular : i18nMessageMap.error.plural;
    return { icon: HealthStatusIcons[HealthStatusCode.ERROR], theme: HealthStatusThemes[HealthStatusCode.ERROR], tooltip: msg, count, total: totalCount };
  } else if (statusMap[HealthStatusCode.WARNING]) {
    const count = statusMap[HealthStatusCode.WARNING];
    const msg = count === totalCount ? i18nMessageMap.warning.all : count === 1 ? i18nMessageMap.warning.singular : i18nMessageMap.warning.plural;
    return { icon: HealthStatusIcons[HealthStatusCode.WARNING], theme: HealthStatusThemes[HealthStatusCode.WARNING], tooltip: msg, count, total: totalCount };
  } else {
    const msg = totalCount === 1 ? i18nMessageMap.ok.singular : i18nMessageMap.ok.plural;
    return { icon: HealthStatusIcons[HealthStatusCode.OK], theme: HealthStatusThemes[HealthStatusCode.OK], tooltip: msg, count: totalCount, total: totalCount };
  }
};

/**
 * Convert an array of sites and their health status states into a map of healthStatus and count representation
 * - used for display sites count for each health status
 */
export const calculateSitesPerHealthStatus = (siteStates: Array<SiteStatesDTO>): { [key: string]: number } => {
  const sitesPerStatus: { [key: string]: number } = {
    [HealthStatusCode.OK.toString()]: 0,
    [HealthStatusCode.WARNING.toString()]: 0,
    [HealthStatusCode.ERROR.toString()]: 0,
    [HealthStatusCode.UNREACHABLE.toString()]: 0,
    [HealthStatusCode.NOT_MONITORED.toString()]: 0,
  };

  siteStates.forEach((siteState: SiteStatesDTO) => {
    // make sure to filter nulls (sometimes returnend by backend - See https://jira.activia-networks.com/browse/CM-5504)
    const distinctStates = siteState.distinctStates.filter((state) => !!state);
    const siteHealthStatus = resolveSiteHealthStatusFromDistinctStates(distinctStates);
    // also some sites may have no devices so do nothing in that case
    if (siteHealthStatus) {
      sitesPerStatus[siteHealthStatus] += 1;
    }
  });
  return sitesPerStatus;
};

/**
 * adjust the weight of each status, only show the one with the highest weight
 * Weight: Error -> Warning -> Unreachable -> Ok
 */
export const resolveSiteHealthStatusFromDistinctStates = (distinctStates: string[]): string => {
  // see https://confluence.stratacache.com/display/CM/AMP+Monitoring+Values
  // HEALTH_STATUS
  // 0=OK, 1=Warning, 2=Error, 3=Unreachable, 4=Not Monitored
  const siteState = distinctStates.reduce((p, c) => {
    let prev = +p;
    let cur = +c;
    cur = cur === HealthStatusCode.ERROR || cur === HealthStatusCode.WARNING ? cur * 5 : cur;
    cur = cur === HealthStatusCode.UNREACHABLE ? cur * 2 : cur;
    prev = prev === HealthStatusCode.ERROR || prev === HealthStatusCode.WARNING ? prev * 5 : prev;
    prev = prev === HealthStatusCode.UNREACHABLE ? prev * 2 : prev;
    return prev > cur ? p : c;
  }, null);

  return siteState;
};
