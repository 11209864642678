import { Inject, Injectable } from '@angular/core';
import { SiteSyncDndCsvModalComponent } from '../components/site-sync-dnd-csv-modal/site-sync-dnd-csv-modal.component';
import { ModalService, ModalType } from '@activia/ngx-components';
import { SiteDTO } from '@activia/cm-api';
import { Overlay } from '@angular/cdk/overlay';
import { SITE_MANAGEMENT_CSV_COLUMNS_DEFINITIONS } from '../models/site-management-csv-columns';
import { toCsvSites } from '../utils/csv-export.utils';
import { DEFAULT_PAPAPARSE_CONFIG } from '@amp/utils/common';
import { downloadData, exportAsCsv } from '@amp/utils/common';
import { DateTime } from 'luxon';
import { Papa } from '@amp/utils/common';
import { DOCUMENT } from '@angular/common';

/**
 * Service that opens the site sync modal.
 * Used in several places so extracted into a service
 */
@Injectable({ providedIn: 'root' })
export class SyncModalService {
  constructor(private _modalService: ModalService, private _overlay: Overlay, private _papaParse: Papa, @Inject(DOCUMENT) private _document: Document) {}

  /**
   * Open a modal to start the csv site upload process
   */
  openSiteSyncModal(selectedSitesList: SiteDTO[]) {
    const modalRef = this._modalService.open(
      SiteSyncDndCsvModalComponent,
      {
        closeOnBackdropClick: true,
        data: {
          isSelectedSites: !!selectedSitesList.length,
        },
      },
      {
        width: '900px',
        height: '605px',
        panelClass: 'overlay-panel-class',
        positionStrategy: this._overlay.position().global().centerHorizontally().top('50px'),
      },
      ModalType.Dialog
    );
    return modalRef;
  }

  exportSites(sites: SiteDTO[]) {
    const csvColumns = Object.keys(SITE_MANAGEMENT_CSV_COLUMNS_DEFINITIONS);
    const csvDataRows = toCsvSites(sites);
    // export data to csv format and download it
    const csvFormattedData = exportAsCsv(this._papaParse, csvColumns, csvDataRows, DEFAULT_PAPAPARSE_CONFIG);
    const filename = `sites_${DateTime.now().toFormat('yyyyMMdd-HHmmss-SSS')}.csv`;
    downloadData(this._document, filename, csvFormattedData, 'text/csv;charset=utf-8');
  }
}
