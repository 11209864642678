<amp-search-devices
  [selectionMode]="'multiple'"
  [columns]="columns$ | async"
  [dataSource]="dataSource$ | async"
  [loadingState]="loadingState$ | async"
  (fetchData)="onFetchData($event)"
  (searchQueryChanged)="searchQueryChanged($event)"
  (selectedDevices)="onSelectionChanged($event)"
>
</amp-search-devices>

<amp-player-column-templates></amp-player-column-templates>
