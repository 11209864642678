import {IMonitoringListDatum, IMonitoringSharedListDTO} from './monitoring-list.interface';
import {ThemeType} from '@activia/ngx-components';

export type ExportDevicesTaskStatus = 'PROCESSING' | 'SUCCESS' | 'FAIL';

export interface IExportDevicesTask {
  id?: string;

  list: IMonitoringSharedListDTO;

  /** Used only when exported the entire list. Need to specify whether caching the list with or without manager ID */
  withManagerId: boolean;

  /** If exporting only a subset of devices within a list, the devices would be kept in this field */
  devices?: IMonitoringListDatum[];

  /** Success when csvData is ready. Null when it's still processing to get csvData. Otherwise fail. */
  status?: ExportDevicesTaskStatus;

  /** Exportable csv data in string */
  csvData?: string;
}

export const ExportDevicesTaskIcons: Record<ExportDevicesTaskStatus, string> = {
  PROCESSING: 'image:rotate_right',
  SUCCESS: 'action:check_circle',
  FAIL: 'alert:error_outline',
};

/** Mapping of the corresponding icon for each import job filter */
export const ExportDevicesTaskThemes: Record<ExportDevicesTaskStatus, ThemeType> = {
  PROCESSING: null,
  SUCCESS: ThemeType.SUCCESS,
  FAIL: ThemeType.DANGER,
};
