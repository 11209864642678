import { RoleDTO, UserDTO } from '@activia/cm-api';
import { ScopeDTO } from '@activia/cm-api';

import { Action, createReducer, on } from '@ngrx/store';
import * as AuthAction from './auth.action';

export interface IAuthState {
  user: UserDTO | null;
  permissions: string[];
  roles: RoleDTO[];
  scopes: ScopeDTO[];
  pending: boolean;
  error: string | null;
  token: string | null;
}

export const initialAuthState: IAuthState = {
  user: null,
  permissions: null,
  roles: [],
  pending: true,
  scopes: [],
  error: null,
  token: null,
};

const reducer = createReducer(
  initialAuthState,

  on(AuthAction.LoginInitiated, (state, _) => ({
    ...state,
    pending: true,
  })),

  on(AuthAction.LoginFail, (state, action) => ({
    ...state,
    pending: false,
    error: action.error,
  })),

  on(AuthAction.LoginSuccess, (state, action) => ({
    ...state,
    token: action.authenticatedUser.sessionId,
    pending: true,
    error: null,
    roles: Array.from(action.authenticatedUser.userRoles || []),
  })),

  on(AuthAction.AppSessionReady, (state, action) => ({
    ...state,
    pending: true,
    token: action.token,
  })),

  on(AuthAction.LoginComplete, (state, action) => ({
    ...state,
    pending: false,
    user: action.userInfo,
    roles: action.userRoles,
    permissions: action.permissions,
    scopes: action.scopes,
  })),

  on(AuthAction.UserInfoUpdate, (state, action) => ({
    ...state,
    user: action.user,
  })),

  on(AuthAction.LoginIncomplete, AuthAction.LogoutSuccess, AuthAction.LogoutFail, (state, _) => ({
    ...state,
    pending: false,
    user: undefined,
    token: undefined,
    roles: undefined,
    permissions: undefined,
  }))
);

export const authReducer = (state: IAuthState | undefined, action: Action): IAuthState => reducer(state, action);
