import { Component, EventEmitter, Inject, OnDestroy, Output, ViewChild } from '@angular/core';
import { IModalComponent, IModalConfig, MODAL_CONFIG, ModalRef } from '@activia/ngx-components';
import { IModalPickerComponent } from '../../models/modal-picker-component.interface';
import { SiteDTO } from '@activia/cm-api';
import { Subject } from 'rxjs';
import { ILocation } from '../site-management-location/location.interface';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { SiteManagementSiteBasicInfoEditorComponent } from '../site-management-site-basic-info-editor/site-management-site-basic-info-editor.component';
import { LocationEditorComponent } from '../site-management-location/location-editor/location-editor.component';

export type SiteInfoEditorTab = 'general' | 'location';

@Component({
  selector: 'amp-site-management-site-info-editor-modal',
  templateUrl: './site-management-site-info-editor-modal.component.html',
  styleUrls: ['./site-management-site-info-editor-modal.component.scss'],
})
export class SiteManagementSiteInfoEditorModalComponent implements IModalComponent, IModalPickerComponent<Partial<SiteDTO>>, OnDestroy {
  @ViewChild(SiteManagementSiteBasicInfoEditorComponent) siteEditorComponent: SiteManagementSiteBasicInfoEditorComponent;
  @ViewChild(LocationEditorComponent) locationEditorComponent: LocationEditorComponent;

  /** Emits to notify that the site is ready to save **/
  @Output() saved = new EventEmitter<Partial<SiteDTO>>();

  mapLocation: ILocation;

  /** Form used to check org path and name validation */
  form: UntypedFormGroup = this._fb.group({});

  /** @ignore **/
  private _componentDestroyed$: Subject<void> = new Subject<void>();

  constructor(
    private _dialogRef: ModalRef<SiteManagementSiteInfoEditorModalComponent>,
    @Inject(MODAL_CONFIG) public dialogConfig: IModalConfig<{ site: SiteDTO; validateFormOnInitialLoad?: boolean; }>,
    private _fb: UntypedFormBuilder,
  ) {
    this.mapLocation = {
      id: this.dialogConfig.data.site.id,
      geodeticCoordinates: {
        ...this.dialogConfig.data.site.geodeticCoordinates,
      },
      address: {
        ...this.dialogConfig.data.site.address,
      },
    };
  }

  /** @ignore **/
  ngOnDestroy(): void {
    this._componentDestroyed$.next();
    this._componentDestroyed$.complete();
  }

  canClose(): boolean {
    return this.dialogConfig.closeOnBackdropClick;
  }

  /** Called when the cancel button is clicked **/
  onCancel() {
    this._close();
  }

  onSave(): void {
    const siteDto = this.siteEditorComponent.getData().site;
    const locationDto = this.locationEditorComponent.getData();

    this.saved.emit({ ...this.dialogConfig.data.site, ...siteDto, ...locationDto });
    this._close();
  }

  private _close() {
    this._dialogRef.close();
  }
}
