import { SiteMonitoredValue } from './site-monitored-value.enum';
import { ThemeType } from '@activia/ngx-components';

/**
 * Defines the theme color to use for a specific column.
 * Depending on the data value, the color used to show the data can vary.
 * For example, showing a memory gauge might use the success theme up 80% of usage, and danger theme for a value > 80%, to catch the user's attention
 * { thresholdValue: 80, theme: ThemeType.WARNING } - { theme: ThemeType.SUCCESS }
 */
export interface ISiteMonitoringColumnThresholdTheme {
  thresholdValue?: number;
  theme: ThemeType;
}

/**
 * Map of threshold per column
 */
export const SITE_MONITORING_COLUMNS_THRESHOLDS: { [id: string]: ISiteMonitoringColumnThresholdTheme[] } = {
  // the following variables are in a success state the HIGHER the value
  [SiteMonitoredValue.HealthStatus]: [{ theme: ThemeType.DANGER }, { thresholdValue: 80, theme: ThemeType.WARNING }, { thresholdValue: 95, theme: ThemeType.SUCCESS }],
  [SiteMonitoredValue.ContentState]: [{ theme: ThemeType.DANGER }, { thresholdValue: 80, theme: ThemeType.WARNING }, { thresholdValue: 95, theme: ThemeType.SUCCESS }],
  [SiteMonitoredValue.ContentStatus]: [{ theme: ThemeType.DANGER }, { thresholdValue: 80, theme: ThemeType.WARNING }, { thresholdValue: 95, theme: ThemeType.SUCCESS }],
  [SiteMonitoredValue.DevicePropertiesStatus]: [{ theme: ThemeType.DANGER }, { thresholdValue: 80, theme: ThemeType.WARNING }, { thresholdValue: 95, theme: ThemeType.SUCCESS }],
  // the following variables are in a success state the LOWER the value
  [SiteMonitoredValue.CpuPercent]: [{ theme: ThemeType.SUCCESS }, { thresholdValue: 70, theme: ThemeType.WARNING }, { thresholdValue: 85, theme: ThemeType.DANGER }],
  [SiteMonitoredValue.SystemDisk]: [{ theme: ThemeType.SUCCESS }, { thresholdValue: 70, theme: ThemeType.WARNING }, { thresholdValue: 85, theme: ThemeType.DANGER }],
  [SiteMonitoredValue.SystemMemory]: [{ theme: ThemeType.SUCCESS }, { thresholdValue: 70, theme: ThemeType.WARNING }, { thresholdValue: 85, theme: ThemeType.DANGER }],
};
