import {
  ApplicationPreferencesService,
  DeviceGroupService,
  CurrentUserService,
  RoleDTO,
  SystemInformationService,
  DeviceGroupDTO
} from '@activia/cm-api';
import * as AuthAction from '@amp/auth';
import { Injectable } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { forkJoin, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { exhaustMap } from 'rxjs/operators';
import { map } from 'rxjs/operators';
import { switchMap } from 'rxjs/operators';
import { mergeMap, tap } from 'rxjs/operators';
import * as GlobalAction from './global.action';
import { assignUserPreference, INITIAL_USER_PREFERENCES, IUserPreferences, UserPreferenceKeys } from './user-preferences.interface';
import { MessengerNotificationService } from '@amp/messenger';
import { getUserPreferences, getUserPreferencesByKey, updateApplicationPreferencesByKey, updateUserPreferences, updateUserPreferencesByKey } from '@amp/utils/common';

@Injectable()
export class GlobalEffects {
  loginComplete$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthAction.LoginComplete),
      switchMap((res: { permissions: string[]; userInfo: any; userRoles: RoleDTO[]; scopes: any }) => [
        GlobalAction.UserPreferencesFetch(),
        GlobalAction.SystemInformationFetch(),
        GlobalAction.DeviceGroupsForRoleFetch({ ids: res.userRoles.map((role) => role.id) }),
      ])
    )
  );

  /**
   * User preferences fetch
   */

  globalUserPreferencesFetch$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GlobalAction.UserPreferencesFetch),
      exhaustMap((_) =>
        getUserPreferences(this.currentUserService) /*this.currentUserService.getPreferencesByUsername()*/
          .pipe(
            map((preferences) => {
              // make sure to deep clone the object because the instance gets frozen by being
              // passed as an action param
              const userPreferences: IUserPreferences = {
                global: {
                  ...INITIAL_USER_PREFERENCES.global,
                },
                notification: { ...INITIAL_USER_PREFERENCES.notification },
              };

              if (!!preferences && preferences.length > 0) {
                Object.values(UserPreferenceKeys).forEach((prefKey) => {
                  const pref = preferences.find((p) => p.key === prefKey);
                  if (pref) {
                    assignUserPreference(userPreferences, pref);
                  }
                });
              }

              return GlobalAction.UserPreferencesFetchSuccess({ preferences: userPreferences });
            }),
            catchError((err) => {
              // TODO: add GA tracking back once decoupling task is completed
              if (err.status === 404) {
                return of(GlobalAction.UserPreferencesFetchSuccess({ preferences: { ...INITIAL_USER_PREFERENCES } }));
              }
              this.messenger.showErrorMessage('global.error.user-preferences-fetch-fail');
              return of(GlobalAction.UserPreferencesFetchFail({ error: err }));
            })
          )
      )
    )
  );

  /**
   * User preferences SINGLE KEY fetch
   */

  userPreferencesSingleKeyFetch$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GlobalAction.UserPreferencesSingleKeyFetch),
      // mergeMap is used because sometimes we fetch 2 different keys at the same time
      mergeMap((action) =>
        getUserPreferencesByKey<any>(this.currentUserService, action.key, undefined).pipe(
          map((preferences) => GlobalAction.UserPreferencesSingleKeyFetchSuccess({ key: action.key, data: preferences })),
          catchError((err) => {
            // TODO: add GA tracking back once decoupling task is completed
            this.messenger.showErrorMessage(action.errorMessageKey || 'global.error.user-preferences-single-key-fetch-fail');
            return of(GlobalAction.UserPreferencesSingleKeyFetchFail({ key: action.key, errorMessage: err.message }));
          })
        )
      )
    )
  );

  /**
   * application preferences - single key update
   */

  appPreferencesSingleKeyUpdate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GlobalAction.AppPreferencesSingleKeyUpdate),
      // make call to api
      switchMap((action) =>
        updateApplicationPreferencesByKey<any>(this.appPreferencesService, action.key, action.data).pipe(
          map((_) => GlobalAction.AppPreferencesSingleKeyUpdateSuccess({ key: action.key, data: action.data, custom: action.custom })),
          catchError((err) => {
            this.messenger.showErrorMessage(action.errorMessageKey);
            return of(GlobalAction.AppPreferencesSingleKeyUpdateFail({ key: action.key, errorMessage: err.message }));
          })
        )
      )
    )
  );

  /**
   * User language update
   */

  globalLangUpdate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GlobalAction.UserLanguageUpdate),
      tap((action) => {
        // whatever it is saved successfully or not, we need to have the language updated
        this.translate.setActiveLang(action.language);
      }),
      // make call to api
      switchMap((action) =>
        updateUserPreferencesByKey(this.currentUserService, UserPreferenceKeys.GLOBAL_USERLANGUAGE, action.language).pipe(
          map((_) => GlobalAction.UserLanguageUpdateSuccess({ language: action.language })),
          catchError((err) => {
            // todo messenger should listen to the state and dispatch its messages accordingly
            this.messenger.showErrorMessage('global.error.user-language-update-fail');
            return of(GlobalAction.UserLanguageUpdateFail({ error: err }));
          })
        )
      )
    )
  );

  /**
   * User preferences - single key update
   */
  userPreferencesSingleKeyUpdate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GlobalAction.UserPreferencesSingleKeyUpdate),
      // make call to api
      switchMap((action) =>
        updateUserPreferencesByKey(this.currentUserService, action.key, action.data).pipe(
          map((_) => GlobalAction.UserPreferencesSingleKeyUpdateSuccess({ key: action.key, data: action.data, custom: action.custom })),
          catchError((err) => {
            // todo messenger should listen to the state and dispatch its messages accordingly
            this.messenger.showErrorMessage(action.errorMessageKey);
            return of(GlobalAction.UserPreferencesSingleKeyUpdateFail({ key: action.key, errorMessage: err.message }));
          })
        )
      )
    )
  );

  systemInformationFetch$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GlobalAction.SystemInformationFetch),
      exhaustMap((_) =>
        this.systemInformationService.getSystemAbout().pipe(
          map((systemInfo) => GlobalAction.SystemInformationFetchSuccess({ info: systemInfo })),
          catchError((err) => {
            // todo messenger should listen to the state and dispatch its messages accordingly
            this.messenger.showErrorMessage('global.error.system-information-fetch-fail');
            return of(GlobalAction.SystemInformationFetchFail({ error: err }));
          })
        )
      )
    )
  );

  deviceGroupsForRoleFetch$ = createEffect(() =>
  this.actions$.pipe(
    ofType(GlobalAction.DeviceGroupsForRoleFetch),
    mergeMap((action) => 
      forkJoin(
        action.ids.map(id =>
          this.deviceGroupService.getDeviceGroupByACL(id).pipe(
            map(deviceGroups => deviceGroups || []),
            catchError(() => [])
          )
        )
      ).pipe(
        map((rolesDeviceGroups: Array<Array<DeviceGroupDTO>>) => {
          const availableDeviceGroups: Record<number, DeviceGroupDTO> = {};

          rolesDeviceGroups.flat().forEach(deviceGroup => {
            availableDeviceGroups[deviceGroup.id] = deviceGroup;
          });

          const sortedDeviceGroups = Object.values(availableDeviceGroups).sort((a, b) => a.name < b.name ? -1 : 1);

          return GlobalAction.DeviceGroupsForRoleFetchSuccess({ deviceGroups: sortedDeviceGroups });
        })
      )
    )
  )
);

  /***
   * Update user preferences
   */
  userPreferencesUpdate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GlobalAction.UserPreferencesUpdate),
      switchMap((action) => {
        const pref = action.preferences.map((p) => ({ key: p.key, value: typeof p.value === 'string' ? p.value : JSON.stringify(p.value) }));
        return updateUserPreferences(this.currentUserService, pref).pipe(
          map(() => {
            this.messenger.showSuccessMessage('user-settings.message.preference-update-success');
            const preferences = action.preferences.reduce((result, prf) => ({ ...result, [`${prf.field}`]: prf.value }), {});
            return GlobalAction.UserPreferencesUpdateSuccess({ preferences: { ...preferences } });
          }),
          catchError((err) => {
            this.messenger.showErrorMessage('user-settings.message.preference-update-fail');
            return of(GlobalAction.UserPreferencesUpdateFail({ errorMessage: err.message }));
          })
        );
      })
    )
  );

  constructor(
    private actions$: Actions,
    private currentUserService: CurrentUserService,
    private appPreferencesService: ApplicationPreferencesService,
    private systemInformationService: SystemInformationService,
    private deviceGroupService: DeviceGroupService,
    private translate: TranslocoService,
    private messenger: MessengerNotificationService,
  ) {}
}
