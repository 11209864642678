import { ITreeNodeItem } from '@activia/ngx-components';
import { ColumnPickerComponent, IColumnPickerItem } from '@amp/column-picker';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { ISiteMonitoringColumnPickerExtraConfig } from '../../../model/site-monitoring-column-picker-extra-config.interface';

@Component({
  selector: 'amp-site-monitoring-columns-editor',
  templateUrl: './site-monitoring-columns-editor.component.html',
  styleUrls: ['./site-monitoring-columns-editor.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SiteMonitoringColumnsEditorComponent {
  @ViewChild(ColumnPickerComponent, { static: true }) treeColumnPicker: ColumnPickerComponent<ISiteMonitoringColumnPickerExtraConfig>;

  /** Indicates if the component is in loading state **/
  @Input() loading = false;

  /** The list of columns to display in the treeview (treeview format) **/
  @Input() columns: ITreeNodeItem<IColumnPickerItem<ISiteMonitoringColumnPickerExtraConfig>>[] = []; // tree node items

  /** the selected column names / ids*/
  @Input() selectedColumns: IColumnPickerItem<ISiteMonitoringColumnPickerExtraConfig>[] = [];

  /** the form optimistic view value */
  @Input() defaultToOptimisticView: boolean;

  /** Emits when column settings have changed **/
  @Output() columnSettingsChanged = new EventEmitter<boolean>();

  /** Indicates if the component is in valid state */
  isValid(): boolean {
    return this.treeColumnPicker.getSelectedColumns()?.length > 0;
  }

  /** Called when the optimistic view checkbox is toggled **/
  onOptimisticViewChange(checked: boolean, item: IColumnPickerItem<ISiteMonitoringColumnPickerExtraConfig>) {
    // update the column extra config and refresh data binding
    this.selectedColumns.find((col) => col.columnDefinition.id === item.columnDefinition.id).extra.optimisticViewEnabled = checked;
    this.selectedColumns = [...this.selectedColumns];
    this.columnSettingsChanged.emit(true);
  }

  /**
   * Update list of columns selected
   *
   * @param event columns selected emit
   */
  updatedSelectedColumns(event: IColumnPickerItem<ISiteMonitoringColumnPickerExtraConfig>[]) {
    const newSelectedColumns: IColumnPickerItem<ISiteMonitoringColumnPickerExtraConfig> = event.filter(
      (sc) => !this.selectedColumns.find((newSelectedColumn) => newSelectedColumn.columnDefinition.id === sc.columnDefinition.id)
    )[0];

    this.selectedColumns = event.map((element) => element.columnDefinition.id === newSelectedColumns?.columnDefinition.id && element.extra?.optimisticViewEnabled !== undefined
        ? { ...element, ...{ extra: { optimisticViewEnabled: this.defaultToOptimisticView } } }
        : element);
  }
}
