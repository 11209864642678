import { ChangeDetectionStrategy, Component, forwardRef, OnDestroy, OnInit } from '@angular/core';
import { IKeyMetricSettingsComponent, KeyMetricsSettingsBaseMixin } from '../key-metrics-settings.mixin';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { ISiteMonitoringKeyMetricEnclosureStatusSettings, KeyMetricEnclosureCategories } from '../../../../../model/key-metrics/site-monitoring-key-metric-enclosure-status-settings.interface';
import { TranslocoService } from '@ngneat/transloco';

@Component({
  selector: 'amp-key-metrics-enclosure-status-settings',
  templateUrl: './key-metrics-enclosure-status-settings.component.html',
  styleUrls: ['./key-metrics-enclosure-status-settings.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  inputs: ['settings'],
  outputs: ['settingsChanged'],
  providers: [
    {
      provide: IKeyMetricSettingsComponent,
      useExisting: forwardRef(() => KeyMetricsEnclosureStatusSettingsComponent),
    },
  ],
})
export class KeyMetricsEnclosureStatusSettingsComponent extends KeyMetricsSettingsBaseMixin<ISiteMonitoringKeyMetricEnclosureStatusSettings>() implements IKeyMetricSettingsComponent, OnInit, OnDestroy {

  options = Object.keys(KeyMetricEnclosureCategories).map((status) => ({
    value: status, label: this._translocoService.translate(`siteMonitoringSharedScope.SITE_MONITORING.COLUMNS.KEY_METRICS.ENCLOSURE_STATUS.STATUS.${status}.TITLE_35`)
  }));

  constructor(private _formBuilder: UntypedFormBuilder, private _translocoService: TranslocoService) {
    super();
  }

  ngOnInit(): void {
    this.form = this._formBuilder.group({ enclosureStatus: [this.settings?.enclosureStatus || [], Validators.required] });
  }

  ngOnDestroy(): void {
    // unsubscribe all subscriptions
    this.onDestroy();
  }
}
