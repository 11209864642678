import { AfterViewInit, ChangeDetectionStrategy, Component, TemplateRef, ViewChild } from '@angular/core';
import { IBadgeSize } from '@activia/ngx-components';
import { DeviceProperty } from '@amp/devices';
import { PlayerColumn } from '../models/player-column';
import { PlayerColumnService } from '../services/player-column.service';
import { DisplayType } from '@amp/column-picker';

@Component({
  selector: 'amp-player-column-templates',
  templateUrl: './player-column-templates.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PlayerColumnTemplatesComponent implements AfterViewInit {
  @ViewChild('hostnameIp', { static: false }) hostnameIp: TemplateRef<void>;
  @ViewChild('hostnameIpWithLink', { static: false }) hostnameIpWithLink: TemplateRef<void>;
  @ViewChild('operationalState', { static: false }) operationalState: TemplateRef<void>;
  @ViewChild('serialMac', { static: false }) serialMac: TemplateRef<void>;

  DeviceProperty = DeviceProperty;
  DisplayType = DisplayType;
  badgeSize: IBadgeSize = IBadgeSize.SMALL;

  constructor(private _playerColumnService: PlayerColumnService) {}

  ngAfterViewInit(): void {
    const templates: Map<PlayerColumn, [TemplateRef<void>, TemplateRef<void>]> = new Map();
    templates.set(PlayerColumn.HOSTNAME_IP, [this.hostnameIp, null]);
    templates.set(PlayerColumn.HOSTNAME_IP_WITH_LINK, [this.hostnameIpWithLink, null]);
    templates.set(PlayerColumn.OPERATIONAL_STATE, [this.operationalState, null]);
    templates.set(PlayerColumn.SERIAL_MAC, [this.serialMac, null]);
    this._playerColumnService.registerTemplates(templates);
  }
}
