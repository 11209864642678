import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import * as SiteManagementAction from '../store/site-management.actions';
import { SiteManagementSiteDetailComponent } from '../components/site-detail/site-management-site-detail/site-management-site-detail.component';
import { ISiteManagementState } from '../store/site-management.reducer';
import { Store } from '@ngrx/store';
import { catchError, Observable, of } from 'rxjs';
import { SitesService } from '@activia/cm-api';
import { map, tap } from 'rxjs/operators';

@Injectable()
/**
 * CanDeactivate guard on site detail page to refresh the site when leaving site
 * detail page to ensure that the module store has the correct counts of this site
 */
export class RefreshSiteGuard  {
  constructor(private _store: Store<ISiteManagementState>, private _sitesService: SitesService) {}

  canDeactivate(_: SiteManagementSiteDetailComponent, route: ActivatedRouteSnapshot, __: RouterStateSnapshot): Observable<boolean> {
    return this._sitesService.findSitesById(route.params.siteId, ['board-count', 'device-count', 'display-count', 'managers']).pipe(
      tap((site) => {
        this._store.dispatch(SiteManagementAction.RefreshSiteSuccess({ site }));
      }),
      map(() => true),
      catchError(() => of(true))
    );
  }
}
