import { Component, Inject } from '@angular/core';
import { IModalComponent, IModalConfig, MODAL_CONFIG, ModalRef } from '@activia/ngx-components';
import { IBoardAlarm } from '../../../model/alarm-event.interface';

@Component({
  selector: 'amp-board-alarm-modal',
  templateUrl: './board-alarm-modal.component.html',
  styleUrls: ['./board-alarm-modal.component.scss'],
})
export class BoardAlarmModalComponent implements IModalComponent {
  constructor(private _dialogRef: ModalRef<BoardAlarmModalComponent>, @Inject(MODAL_CONFIG) public dialogConfig: IModalConfig<{ siteId: number; board: IBoardAlarm }>) {}

  canClose(): boolean {
    return true;
  }

  onClose() {
    this._dialogRef.close();
  }
}
