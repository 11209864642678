import { HealthStatusCode } from '@amp/devices';

/** icons used for each site health status **/
export const HealthStatusIcons: { [key: number]: string } = {
  [HealthStatusCode.OK]: 'action:check_circle',
  [HealthStatusCode.WARNING]: 'alert:warning',
  [HealthStatusCode.ERROR]: 'alert:error',
  [HealthStatusCode.UNREACHABLE]: 'action:help',
  [HealthStatusCode.NOT_MONITORED]: 'content:remove_circle',
};
