import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, Output, ViewChild } from '@angular/core';
import { SiteDTO, SiteTagsService } from '@activia/cm-api';
import { AbstractControl, AsyncValidatorFn, FormControl, FormGroup, ReactiveFormsModule, UntypedFormBuilder, UntypedFormGroup, ValidationErrors, Validators } from '@angular/forms';
import { BehaviorSubject, combineLatest, filter, map, Observable, of, Subject, switchMap, take } from 'rxjs';
import { distinctUntilChanged, takeUntil, tap } from 'rxjs/operators';
import { FormStatus } from '../../models/form-status.constant';
import { SiteUniquenessValidator } from '../../utils/site-uniqueness-validator';
import { isSitePropertyUnique } from '../../utils/site-uniqueness-validator.utils';
import {
  AsyncDataModule,
  AsyncDataState,
  CoreModule,
  dataOnceReady,
  dataWhenReady,
  EmptyDataMessageModule,
  FormFieldModule,
  IconModule,
  InputComponent,
  InputModule,
  LoaderModule,
  LoadingState,
} from '@activia/ngx-components';
import { siteManagementEntities } from '../../store/site-management.selectors';
import { Store } from '@ngrx/store';
import { ISiteManagementState } from '../../store/site-management.reducer';
import { SiteMonitoringFacade, SiteProperties } from '@amp/site-monitoring-shared';
import { CommonModule } from '@angular/common';
import { TranslocoModule } from '@ngneat/transloco';
import { IEngineTagKeyDesc, IEngineTagValue, IOrgPathDefNode, selectSiteOrgPathDefinitionState, selectSiteTagKeysSchema } from '@amp/tag-operation';
import { OrgpathFormGroupComponent } from '../orgpath/orgpath-form-group/orgpath-form-group.component';
import { IOrgPathNode } from '../orgpath/orgpath.interface';
import { SiteOrgpathStore } from '../orgpath/site-orgpath/site-orgpath.store';
import { IJsonSchema } from '@activia/json-schema-forms';

export interface ISiteBasicInfoEditorValueChange {
  site: Pick<SiteDTO, 'name' | 'externalId'>;
  tags: Record<string, IEngineTagValue>;
}

@Component({
  selector: 'amp-site-management-site-basic-info-editor',
  templateUrl: './site-management-site-basic-info-editor.component.html',
  styleUrls: ['./site-management-site-basic-info-editor.component.scss'],
  providers: [SiteOrgpathStore],
  standalone: true,
  imports: [CommonModule, FormFieldModule, InputModule, ReactiveFormsModule, IconModule, TranslocoModule, AsyncDataModule, CoreModule, OrgpathFormGroupComponent, EmptyDataMessageModule, LoaderModule],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SiteManagementSiteBasicInfoEditorComponent implements OnDestroy {
  @ViewChild(OrgpathFormGroupComponent) orgpathFormGroupComponent: OrgpathFormGroupComponent;
  @ViewChild('externalId') externalIdInput: InputComponent;

  @Input() set site(site: SiteDTO) {
    this._site = site;
    this.siteInit();
  }
  get site() {
    return this._site;
  }

  /** (Optional) Connect the basic info editor form to this parent form */
  @Input() parentForm?: UntypedFormGroup;

  /**
   * Set whether to validate the form on the initial load.
   * Used when opening site info in geo fixer or site sync to attract user's attention on the section to fix
   */
  @Input() validateFormOnInitialLoad: boolean;

  @Output() hasErrors: EventEmitter<boolean> = new EventEmitter<boolean>();

  form: UntypedFormGroup;
  nameControlStatusSub: Subject<FormStatus> = new Subject<FormStatus>();
  nameControlStatus$: Observable<FormStatus> = this.nameControlStatusSub.asObservable();
  MIN_CHAR = 3;
  SiteProperties = SiteProperties;

  siteOrgPathDefState$: Observable<AsyncDataState> = this._store.select(selectSiteOrgPathDefinitionState);
  siteOrgPathDef$: Observable<IOrgPathNode> = this.siteOrgpathStore.selectOrgPathDefinition$.pipe(filter((schema) => this.hasNameProperty(schema)));
  tagDefinition$: Observable<Record<string, IJsonSchema>> = this.siteOrgpathStore.selectTagsDefinitions$.pipe(map((tags) => this.getSchemaFromTagDesc(tags)));
  sites$: Observable<SiteDTO[]> = dataOnceReady(this._store.pipe(siteManagementEntities.allSitesData$), this._store.pipe(siteManagementEntities.allSitesDataState$)).pipe(take(1));
  siteTagsState: BehaviorSubject<AsyncDataState> = new BehaviorSubject(LoadingState.INIT);

  controlAsyncValidatorMap: Map<string, AsyncValidatorFn> = new Map();
  hasOrgPath = false;

  private _site: SiteDTO;

  /** @ignore Pattern used to close all subscriptions */
  private _componentDestroyed$: Subject<void> = new Subject<void>();

  constructor(
    private _formBuilder: UntypedFormBuilder,
    private _siteUniquenessValidator: SiteUniquenessValidator,
    private _store: Store<ISiteManagementState>,
    public siteMonitoringFacade: SiteMonitoringFacade,
    private siteOrgpathStore: SiteOrgpathStore,
    private siteTagsService: SiteTagsService,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    if (!this.site) {
      this.initForm();
      this.initializeSiteOrgPath();
    }

    if (this.form.controls['name']) {
      combineLatest([this.form.controls['name'].statusChanges, this.form.controls['name'].valueChanges])
        .pipe(
          distinctUntilChanged(([prevStatus, prevValue], [currStatus, currValue]) => prevStatus === currStatus && prevValue === currValue),
          switchMap(([status, value]) => {
            if (status === 'VALID') {
              this.nameControlStatusSub.next('PENDING');
              const allSites$ = dataOnceReady(this._store.pipe(siteManagementEntities.allSitesData$), this._store.pipe(siteManagementEntities.allSitesDataState$)).pipe(take(1));
              return isSitePropertyUnique(allSites$, this.site?.id, value, 'name').pipe(map((duplicateFound) => (duplicateFound ? 'WARNING' : 'VALID')));
            } else {
              return of(status as FormStatus);
            }
          }),
          tap((status) => {
            this.nameControlStatusSub.next(status);
          }),
          takeUntil(this._componentDestroyed$)
        )
        .subscribe();
    }
  }

  siteInit() {
    this.hasOrgPath = false;
    this.initForm();
    this.initializeSiteOrgPath();

    if (this.validateFormOnInitialLoad) {
      this.form.markAllAsTouched();
    }
  }

  deletePropertyRecursively(obj, prop) {
    for (const key in obj) {
      if (key === prop) {
        delete obj[key];
      } else if (typeof obj[key] === 'object') {
        this.deletePropertyRecursively(obj[key], prop);
      }
    }
  }

  getTags(): Record<string, IEngineTagValue> {
    if (this.hasOrgPath) {
      const updatedObject = { ...this.form.controls['tags'].value };
      this.deletePropertyRecursively(updatedObject, 'name');
      return updatedObject as Record<string, IEngineTagValue>;
    }
    return {};
  }

  getData(): ISiteBasicInfoEditorValueChange {
    return {
      site: {
        name: this.hasOrgPath ? this.findNameControl(this.form.controls['tags'] as FormGroup)?.value : this.form.controls.name.value,
        externalId: this.form.controls.externalId.value,
      },
      tags: this.getTags(),
    };
  }

  /**
   * Checks if the given schema or its child elements have a property with the name "name" recursively.
   */
  hasNameProperty(schema: IOrgPathDefNode): boolean {
    if (!schema) {
      return false;
    }
    return schema.property === 'name' || !!schema.childOneOf?.some((e) => this.hasNameProperty(e));
  }

  getSchemaFromTagDesc(tags: Record<string, IEngineTagKeyDesc>): Record<string, IJsonSchema> {
    if (!tags) return {};
    return Object.keys(tags).reduce((acc, curr) => {
      acc[curr] = tags[curr].schema as IJsonSchema;
      return acc;
    }, {} as Record<string, IJsonSchema>);
  }

  getFormErrors(formGroup: FormGroup): any {
    const formErrors = formGroup.errors || {};

    Object.keys(formGroup.controls).forEach((key) => {
      const control = formGroup.controls[key];

      if (control instanceof FormGroup) {
        const controlErrors = this.getFormErrors(control);
        if (Object.keys(controlErrors).length > 0) {
          formErrors[key] = controlErrors;
        }
      } else {
        const controlErrors = control.errors;
        if (controlErrors) {
          formErrors[key] = controlErrors;
        }
      }
    });

    return Object.keys(formErrors).length > 0 ? formErrors : null;
  }

  orgPathErrors() {
    if (this.orgpathFormGroupComponent?.form) {
      return this.findNameControl(this.orgpathFormGroupComponent.form as FormGroup)?.errors;
    }
  }

  findNameControl(formGroup: FormGroup): AbstractControl {
    const control = formGroup.get('name');

    if (control) {
      return control;
    }

    for (const key in formGroup.controls) {
      const childControl = formGroup.controls[key];

      if (childControl instanceof FormGroup) {
        const nameControl = this.findNameControl(childControl);

        if (nameControl) {
          return nameControl;
        }
      }
    }
  }

  initOrgPathFormGroup(initialOrgPath: object) {
    this.form.removeControl('name');
    this.form.removeControl('tags');

    this.form.addControl('tags', new FormGroup({}));

    this.updateTagForm(initialOrgPath);

    this.hasOrgPath = true;
    this.cdr.detectChanges();
  }

  asyncExternalIdValidator(): (control: FormControl) => Observable<ValidationErrors | null> {
    return (control: FormControl): Observable<ValidationErrors | null> => {
      return this.sites$.pipe(
        map((sites: SiteDTO[]) => {
          if (!control.value || control.value.length === 0) {
            return null;
          }
          let externalId = control.value.trim()?.toLowerCase();
          if (this.externalIdInput?.id) {
            externalId = document.getElementById(this.externalIdInput.id)?.['value']?.trim()?.toLowerCase();
          }

          // Check for duplicates by comparing externalId with the existing sites in the list
          const isDuplicate = sites.some((site) => site.id !== this.site?.id && site.externalId?.trim().toLowerCase() === externalId);

          // Return an error object if there is a duplicate external ID
          return isDuplicate ? { duplicate: true } : null;
        })
      );
    };
  }

  tagFormValueChanged() {
    if (this.orgpathFormGroupComponent?.form) {
      this.form.setControl('tags', this.orgpathFormGroupComponent.form);
      this.hasErrors.emit(this.form.invalid);
      this.cdr.detectChanges();
    }
  }

  /** @ignore **/
  ngOnDestroy(): void {
    this._componentDestroyed$.next();
    this._componentDestroyed$.complete();
  }

  /**
   * Initializes the organization path for the site.
   * This method retrieves the initial organization path value for the specified site,
   * waits for necessary data to be ready, and initializes a form group with the initial path.
   */
  private initializeSiteOrgPath() {
    dataWhenReady(this.siteOrgPathDef$, this.siteOrgPathDefState$, 1)
      .pipe(switchMap(() => this.getInitialOrgPathValue$(this.site)))
      .subscribe((initialOrgPath) => {
        if (initialOrgPath) {
          this.initOrgPathFormGroup(initialOrgPath);
        }
      });
  }

  /**
   * Updates a form group with tag values from the provided initial organization path object.
   */
  private updateTagForm(initialOrgPath: object) {
    const formGroup = this.form.controls.tags as FormGroup;

    Object.entries(initialOrgPath).forEach(([key, value]) => {
      // For each tag value, initialize value in the form control (and create it if it doesn't exist)
      if (!formGroup.contains(key)) {
        formGroup.addControl(key, new FormControl(value));
      } else {
        formGroup.get(key).setValue(value);
      }
    });
  }

  /**
   * Initializes the form for managing site information.
   * This method sets up form controls and validators based on provided data and configurations.
   */
  private initForm(): void {
    const nameAsyncValidatorFn = this._siteUniquenessValidator.validate(this.site?.id, 'name');
    this.controlAsyncValidatorMap.set('name', nameAsyncValidatorFn);

    if (!this.form) {
      this.form = this._formBuilder.group({
        name: [
          this.site?.name,
          {
            validators: [Validators.required, Validators.minLength(this.MIN_CHAR)],
          },
        ],
        externalId: [this.site?.externalId],
      });
      // If parent form exist then connect basic info form to it
      if (this.parentForm) {
        this.parentForm.addControl('basicInfo', this.form);
        this.form.setParent(this.parentForm);
      }
    } else {
      this.form.reset();
      if (this.form.get('name')) {
        this.form.get('name').setValue(this.site?.name);
      }
      this.form.controls.externalId.setValue(this.site?.externalId);
    }
    this.form.controls.externalId.setAsyncValidators(this.asyncExternalIdValidator());
    this.form.controls.externalId.updateValueAndValidity();
  }

  getInitialOrgPathValue$(site: SiteDTO): Observable<object> {
    return this._store.select(selectSiteTagKeysSchema).pipe(
      map((tagKeys) => Object.keys(tagKeys)),
      take(1),
      switchMap((tagKeys) => {
        this.siteTagsState.next(LoadingState.LOADING);
        if (site?.id) {
          return this.siteTagsService.findTagsForEntity2(site.id, tagKeys).pipe(
            tap((_) => this.siteTagsState.next(LoadingState.LOADED)),
            map((tagValues) => {
              return Object.keys(tagValues).reduce(
                (acc, curr) => ({
                  ...acc,
                  [curr]: tagValues[curr][0],
                }),
                { name: site.name }
              );
            })
          );
        } else {
          this.siteTagsState.next(LoadingState.LOADED);
          return of({});
        }
      })
    );
  }
}
