import { Injectable } from '@angular/core';
import { first, of, Subject, takeUntil, tap } from 'rxjs';
import { TimerService } from '@amp/messenger';
import { SiteMonitoringFacade } from '../store/site-monitoring.facade';

@Injectable({ providedIn: 'root' })
export class SiteHealthCountService {

  private _stopTimerSubject: Subject<void> = new Subject<void>();

  constructor(private _timerService: TimerService, private _siteMonitoringFacade: SiteMonitoringFacade) { }

  /** Start timer to periodically fetch site count per health status */
  startTimer() {
    return this._siteMonitoringFacade.dataRefreshIntervalMs$.pipe(
      first(),
      tap((refreshInterval) => {
        const timer$ = refreshInterval ?
          this._timerService.timer$(refreshInterval).pipe(
            takeUntil(this._stopTimerSubject.asObservable()),
          ) :
          of(null);

        timer$.subscribe(() => {
          this._siteMonitoringFacade.fetchHealthStatusCount();
        });
      }),
    );
  }

  /** Stop timer to periodically fetch site count per health status */
  stopTimer() {
    this._stopTimerSubject.next();
  }
}
