/**
 * Describes the type device column
 * This is stored along column definitions so that when we get a dto returned by the backend,
 * we know where to get the data from
 */
export enum DeviceColumnType {
  DeviceProperty = 'DV',
  MonitoredValue = 'MV',
  Tag = 'TG',
}
