import { ThemeType } from '@activia/ngx-components';

/**
 * See https://confluence.stratacache.com/display/CM/AMP+Monitoring+Values for more info
 */
export enum ContentState {
  OK = 1,
  MissingContent = 0,
}

export const ContentStateThemeMap = {
  [ContentState.OK]: ThemeType.SUCCESS,
  [ContentState.MissingContent]: ThemeType.DANGER,
};

/** The statuses ordered by severity (high to low) **/
export const ContentStateSeverity = [ContentState.MissingContent, ContentState.OK];
