import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';
// this is replaced by webpack at build time.
declare const __BUST__;

@Injectable()
export class JSONCacheBustInterceptor implements HttpInterceptor {

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // Check if the request is for a .json file
    if (req.url.endsWith('.json')) {
      // Clone the request and add the cache busting parameter
      const clonedRequest = req.clone({
        url: `${req.url}?v=${__BUST__}`
      });
      return next.handle(clonedRequest);
    }

    return next.handle(req);
  }
}
