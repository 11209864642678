import { IJsonSchema } from '@activia/json-schema-forms';
import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { TranslocoModule, TranslocoService } from '@ngneat/transloco';
import { combineLatest, map, Observable } from 'rxjs';
import { BoardOrgpathStore } from '../board-orgpath.store';
import { IOrgPathNode } from '../../orgpath.interface';
import { OrgpathTreeBase } from '../../orgpath-tree-base';
import { defaultIfEmpty } from 'rxjs/operators';
import { CoreModule, IconModule, TooltipModule } from '@activia/ngx-components';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'amp-board-orgpath-tree',
  templateUrl: './board-orgpath-tree.component.html',
  styleUrls: ['./board-orgpath-tree.component.scss'],
  standalone: true,
  imports: [CommonModule, IconModule, CoreModule, TranslocoModule, TooltipModule],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BoardOrgpathTreeComponent extends OrgpathTreeBase implements OnInit {
  @Input() id: string;
  @Input() editable: boolean;

  hasError$: Observable<string>;
  isSelected$: Observable<boolean>;
  node$: Observable<IOrgPathNode> = this._boardOrgPathStore.selectNodeEntities$.pipe(map((entities) => entities[this.id]));
  schema$: Observable<IJsonSchema>;

  constructor(private _boardOrgPathStore: BoardOrgpathStore, transloco: TranslocoService) {
    super(transloco);
  }

  ngOnInit(): void {
    this.schema$ = combineLatest([this.node$, this._boardOrgPathStore.selectTagsDefinitions$]).pipe(map(([node, tagDefs]) => this.mapSchema(node, tagDefs)));

    this.isSelected$ = this._boardOrgPathStore.selectedNode$.pipe(map((selectedNode) => selectedNode?.id === this.id));

    this.hasError$ = this._boardOrgPathStore.selectErrors$.pipe(
      map((errors) => errors?.[this.id]),
      map((errors) => (errors ? this.getOrgpathNodeErrors(errors) : undefined)),
      defaultIfEmpty(undefined)
    );
  }

  selectNode(): void {
    this._boardOrgPathStore.selectNode(this.id);
  }

  addNewNode(): void {
    this._boardOrgPathStore.addNewNode(this.id);
  }

  deleteNode(): void {
    this._boardOrgPathStore.removeNode(this.id);
  }

  moveUp(): void {
    this._boardOrgPathStore.moveNodeUp(this.id);
  }

  moveDown(): void {
    this._boardOrgPathStore.moveNodeDown(this.id);
  }
}
