<avn-dialog-layout>
  <avn-dialog-header [template]="headerTemplate" [showCloseIcon]="dialogConfig.showCloseIcon" (closeClicked)="onClose()"> </avn-dialog-header>

  <avn-dialog-content avnScroll>
    <avn-stepper [linear]="true" [showHeader]="false">
      <!-- Step 1 : Choose device/player/output -->
      <avn-step [headerIcon]="false">
        <div *ngIf="(siteDevices$ | async).length > 0; else emptyDevices">
          <ng-container *ngIf="selectedDevice$ | async as selectedDevice; else noSelectedDevice">
            <div class="selection">
              <div *ngIf="(editable$ | async) && isBoardLocked$ | async">
                <div>{{ 'siteManagementScope.SITE_MANAGEMENT.BOARD_EDITOR.DISPLAY_DEVICE_SELECTOR.SELECTED_PLAYER_LOCKED_BOARD_50' | transloco }}</div>
              </div>

              <!-- Device Info -->
              <div class="device">
                <div>
                  <amp-device-field-templates [displayType]="DisplayType.TwoLines" [value]="{ line1: selectedDevice.deviceInfo.hostname, line2: selectedDevice.deviceInfo.ipAddress }">
                  </amp-device-field-templates>
                  <span class="separator"></span>
                  <amp-device-field-templates [field]="DeviceProperty.OperationalState" [badgeSize]="badgeSize" [value]="selectedDevice.deviceInfo.operationalState"></amp-device-field-templates>
                </div>
                <div *ngIf="(editable$ | async) && (isBoardLocked$ | async) === false">
                  <ng-container *ngTemplateOutlet="editIcon"></ng-container>
                </div>
              </div>
              <div>
                <div>{{ 'siteManagementScope.SITE_MANAGEMENT.BOARD_EDITOR.DISPLAY_DEVICE_SELECTOR.SERIAL_NUMBER' | transloco }}: {{ selectedDevice.deviceInfo.serial }}</div>
                <ng-container *ngFor="let interface of selectedDevice.deviceInterfaces">
                  <div>{{ 'siteManagementScope.SITE_MANAGEMENT.BOARD_EDITOR.DISPLAY_DEVICE_SELECTOR.MAC' | transloco }}: {{ interface.macAddress }}</div>
                </ng-container>
              </div>

              <!-- Choose player -->
              <div class="players">
                <ng-container *ngIf="(editable$ | async) === false || (isBoardLocked$ | async); else playerSelector">
                  {{ 'siteManagementScope.SITE_MANAGEMENT.BOARD_EDITOR.GLOBAL.PLAYBACK_UNIT' | transloco : { number: (selectedPlayer$ | async) + 1 } }}
                </ng-container>

                <ng-template #playerSelector>
                  <avn-radio-group name="players">
                    <avn-radio
                      type="number"
                      *ngFor="let playerIndex of playerOptions$ | async"
                      [label]="'siteManagementScope.SITE_MANAGEMENT.BOARD_EDITOR.GLOBAL.PLAYBACK_UNIT' | transloco : { number: playerIndex.value + 1 }"
                      [value]="playerIndex.value"
                      [disabled]="playerIndex.disabled"
                      [checked]="(selectedPlayer$ | async) === playerIndex.value"
                      (change)="changeSelectedPlayer(playerIndex.value)"
                    >
                    </avn-radio>
                  </avn-radio-group>
                </ng-template>
              </div>

              <!-- Choose output -->
              <ng-container *ngIf="(selectedPlayer$ | async) !== null && (selectedPlayer$ | async) !== undefined">
                <ng-container *ngIf="outputOptions$ | async as outputOptions">
                  <div class="players" *ngIf="outputOptions.length > 1">
                    <ng-container *ngIf="(editable$ | async) === false || (isBoardLocked$ | async); else outputSelector">
                      {{ 'siteManagementScope.SITE_MANAGEMENT.BOARD_EDITOR.GLOBAL.OUTPUT_UNIT' | transloco : { number: (selectedOutput$ | async) + 1 } }}
                    </ng-container>

                    <ng-template #outputSelector>
                      <avn-radio-group name="outputs">
                        <avn-radio
                          type="number"
                          *ngFor="let outputIndex of outputOptions"
                          [label]="'siteManagementScope.SITE_MANAGEMENT.BOARD_EDITOR.GLOBAL.OUTPUT_UNIT' | transloco : { number: outputIndex.value + 1 }"
                          [value]="outputIndex.value"
                          [disabled]="outputIndex.disabled"
                          [checked]="(selectedOutput$ | async) === outputIndex.value"
                          (change)="changeSelectedOutput(outputIndex.value)"
                        >
                        </avn-radio>
                      </avn-radio-group>
                    </ng-template>
                  </div>
                </ng-container>
              </ng-container>
            </div>
          </ng-container>

          <ng-template #noSelectedDevice>
            <ng-container *ngIf="(editable$ | async) === false || (isBoardLocked$ | async); else unlockWithoutSelectedDevice">
              <span *ngIf="isBoardLocked$ | async; else readOnlyNoSelectedDevice">
                {{ 'siteManagementScope.SITE_MANAGEMENT.BOARD_EDITOR.DISPLAY_DEVICE_SELECTOR.NO_SELECTED_PLAYER_LOCKED_BOARD_50' | transloco }}
              </span>
              <ng-template #readOnlyNoSelectedDevice>
                <span>{{ 'siteManagementScope.SITE_MANAGEMENT.BOARD_EDITOR.DISPLAY_DEVICE_SELECTOR.NO_SELECTED_PLAYER_READONLY_BOARD_50' | transloco }}</span>
              </ng-template>
            </ng-container>
            <ng-template #unlockWithoutSelectedDevice>
              <ng-container *ngIf="(availableDevices$ | async)?.length > 0; else noAvailableDevices">
                <div class="device-info">
                  <span>{{ 'siteManagementScope.SITE_MANAGEMENT.BOARD_EDITOR.DISPLAY_DEVICE_SELECTOR.NO_SELECTED_PLAYER_50' | transloco }}</span>
                  <ng-container *ngTemplateOutlet="editIcon"></ng-container>
                </div>
              </ng-container>
            </ng-template>
          </ng-template>

          <ng-template #editIcon>
            <avn-icon icon="image:edit" #overlayOriginInput="cdkOverlayOrigin" (click)="onOpenEditPlayer()" cdkOverlayOrigin></avn-icon>
          </ng-template>
        </div>
        <div class="buttons-action">
          <avn-button
            *ngIf="defaultSelection?.deviceId && (editable$ | async) && (isBoardLocked$ | async) === false"
            class="btn-remove"
            data-e2e="btn-remove"
            [label]="
              (availableDevices$ | async)?.length > 0 ? ('button.remove' | transloco) : ('siteManagementScope.SITE_MANAGEMENT.SITE_DETAIL.SITE_PROPERTIES_EDITOR.PLAYERS.REMOVE_PLAYER_30' | transloco)
            "
            [avnTheme]="'danger'"
            (actioned)="onRemove()"
          >
          </avn-button>
          <avn-button class="btn-cancel" data-e2e="btn-cancel" [label]="(editable$ | async) ? ('button.cancel' | transloco) : ('button.close' | transloco)" (actioned)="onClose()"> </avn-button>
          <avn-button
            *ngIf="(editable$ | async) && (isBoardLocked$ | async) === false && ((selectedDevice$ | async) || ((siteDevices$ | async)?.length > 0 && (availableDevices$ | async)?.length > 0))"
            class="btn-save"
            data-e2e="btn-save"
            [label]="'button.save' | transloco"
            [avnTheme]="'primary'"
            [disabled]="(hasValuesChangedAndValid$ | async) === false"
            (actioned)="onSave()"
          >
          </avn-button>
        </div>
      </avn-step>

      <!-- Step 2 : Choose device in data table list -->
      <avn-step [headerIcon]="false">
        <div class="step-2">
          <amp-site-management-player-selector
            [columns]="columns$ | async"
            [allDevices]="siteDevices$ | async"
            [availableDevices]="availableDevices$ | async"
            (rowChanged)="changeSelectedDevice($event.data)"
          >
          </amp-site-management-player-selector>
        </div>

        <div class="buttons-action">
          <avn-button [label]="'button.back' | transloco" (actioned)="stepper.previous()"></avn-button>
        </div>
      </avn-step>
    </avn-stepper>

    <ng-template #emptyDevices>
      <ng-container
        *ngTemplateOutlet="
          emptyList;
          context: {
            text: 'siteManagementScope.SITE_MANAGEMENT.SITE_DETAIL.SITE_PROPERTIES_EDITOR.PLAYERS.EMPTY.EMPTY_TEXT_100',
            message:
              (editable$ | async)
                ? 'siteManagementScope.SITE_MANAGEMENT.SITE_DETAIL.SITE_PROPERTIES_EDITOR.PLAYERS.EMPTY.EMPTY_MESSAGE_100'
                : 'siteManagementScope.SITE_MANAGEMENT.SITE_DETAIL.SITE_PROPERTIES_EDITOR.PLAYERS.EMPTY.EMPTY_MESSAGE_READONLY_100'
          }
        "
      ></ng-container>
    </ng-template>

    <ng-template #noAvailableDevices>
      <ng-container
        *ngTemplateOutlet="
          emptyList;
          context: {
            text: 'siteManagementScope.SITE_MANAGEMENT.SITE_DETAIL.SITE_PROPERTIES_EDITOR.PLAYERS.FULLY_USED.TEXT_100',
            message: !!defaultSelection?.deviceId
              ? 'siteManagementScope.SITE_MANAGEMENT.SITE_DETAIL.SITE_PROPERTIES_EDITOR.PLAYERS.FULLY_USED.MESSAGE_WITH_PLAYER_100'
              : 'siteManagementScope.SITE_MANAGEMENT.SITE_DETAIL.SITE_PROPERTIES_EDITOR.PLAYERS.FULLY_USED.MESSAGE_WITHOUT_PLAYER_100'
          }
        "
      ></ng-container>
    </ng-template>

    <ng-template #emptyList let-text="text" let-message="message">
      <div class="no-devices">
        <avn-empty-data-message icon="communication:desktop_access_disabled" [text]="text | transloco" [subText]="message | transloco"> </avn-empty-data-message>
      </div>
    </ng-template>
  </avn-dialog-content>
</avn-dialog-layout>

<ng-template #headerTemplate>
  <div class="header">
    <h5>
      {{
        (isBoardLocked$ | async) || (editable$ | async) === false
          ? ('siteManagementScope.SITE_MANAGEMENT.BOARD_EDITOR.DISPLAY_DEVICE_SELECTOR.TITLE_75'
            | transloco : { deviceIdx: 'siteManagementScope.SITE_MANAGEMENT.BOARD_EDITOR.GLOBAL.DEVICE_INDEX.' + dialogConfig.data.inputType | transloco })
          : defaultSelection?.deviceId
          ? ('siteManagementScope.SITE_MANAGEMENT.BOARD_EDITOR.DISPLAY_DEVICE_SELECTOR.EDIT_TITLE_75'
            | transloco : { deviceIdx: 'siteManagementScope.SITE_MANAGEMENT.BOARD_EDITOR.GLOBAL.DEVICE_INDEX.' + dialogConfig.data.inputType | transloco })
          : ('siteManagementScope.SITE_MANAGEMENT.BOARD_EDITOR.DISPLAY_DEVICE_SELECTOR.ADD_TITLE_75'
            | transloco : { deviceIdx: 'siteManagementScope.SITE_MANAGEMENT.BOARD_EDITOR.GLOBAL.DEVICE_INDEX.' + dialogConfig.data.inputType | transloco })
      }}
    </h5>
  </div>
</ng-template>

<amp-player-column-templates></amp-player-column-templates>
