import { ISiteSyncState } from '../site-sync.model';
import { SiteDTO } from '@activia/cm-api';
import {
  COLUMNS_WITH_REQUIRED_VALUE,
  CsvSiteColumnName,
  ICsvSite,
  SITE_MANAGEMENT_CSV_COLUMNS_DEFINITIONS,
  SITEDTO_FIELDS_WITH_REQUIRED_VALUE
} from '../../../models/site-management-csv-columns';
import { convertCsvSiteToDTO } from './site-sync.utils';

/** Result of validating csv file for unique identifiers **/
export interface IImportValidatorUniqueIdentifierResponse {
  valid: boolean; // whether the csv file data is valid or not
  duplicateIds?: number[]; // list of duplicate ids found within the file
  duplicateExternalIds?: string[]; // list of duplicate external ids found within the file
  missingExternalIdCount?: number; // count of missing external ids found within the file
}

/** Validates the csv file header **/
export const validateSiteSyncCsvFileHeaders = (headerColumnNames: string[]): boolean => {
  const columnNames = Object.keys(SITE_MANAGEMENT_CSV_COLUMNS_DEFINITIONS) as CsvSiteColumnName[];
  const optionalColumnIds = columnNames.filter((columnId) => !SITE_MANAGEMENT_CSV_COLUMNS_DEFINITIONS[columnId].columnRequired);

  const allColumnsHeaderValid = columnNames.every((columnId) => {
    const isOptionalColumn = optionalColumnIds.includes(columnId);
    if (isOptionalColumn) {
      return true;
    }
    const isColumnFoundInFile = headerColumnNames.some((header) => header.toLowerCase().replace(/[ ]/g, '') === columnId.toLowerCase());
    return isColumnFoundInFile;
  });

  return allColumnsHeaderValid;
};

/**
 * https://app.moqups.com/cJXusZQMlBeqqoTxQdM2Up5FzxVs3kum/edit/page/ad2a6e3d6
 * Validates unique identifiers within the csv file
 */
export const validateSiteManagementSyncUniqueIdentifiers = (parsedCsvSites: SiteDTO[], existingSites: SiteDTO[]): IImportValidatorUniqueIdentifierResponse => {
  const result: IImportValidatorUniqueIdentifierResponse = {
    valid: true,
    duplicateIds: [],
    missingExternalIdCount: 0,
    duplicateExternalIds: [],
  };

  const findDuplicates = (arr) => arr.filter((item, index) => arr.indexOf(item) !== index);

  // Verify the IDs in the file (if provided) are unique within the file
  const csvSiteIds = parsedCsvSites.map((csvSite) => csvSite.id).filter((id) => !!id);
  result.duplicateIds = [...new Set<number>(findDuplicates(csvSiteIds))];
  result.valid = result.duplicateIds.length === 0;

  // Verify if all csvSites have no external IDs
  const externalIdAllEmpty = parsedCsvSites.every((csvSite) => !csvSite.externalId);

  // Verify if the external IDs of all csvSites are unique
  if (externalIdAllEmpty) {
    return result;
  }

  // Verify within the file first. External IDs should be all provided and unique
  const csvSiteExternalIds = parsedCsvSites.map((csvSite) => csvSite.externalId);

  result.missingExternalIdCount = csvSiteExternalIds.filter((csvSiteExternalId) => !csvSiteExternalId).length;
  result.valid = result.valid && result.missingExternalIdCount === 0;

  const duplicateCsvSiteExternalIds = [...new Set<string>(findDuplicates(csvSiteExternalIds))];
  // Next verify that these external IDs are also unique in the system by comparing with the existing sites
  // Convert IDs and externalIDs of the existing sites into Map for faster read
  // Note: Backend has validation to ensure externalID is unique when provided. So duplicate within
  //       the system is not possible
  const existingSitesMap = new Map(existingSites.map((existingSite) => [existingSite.externalId, existingSite.id]));

  const duplicateSystemExternalIds = [
    ...new Set(
      parsedCsvSites.filter((csvSite) => {
        const csvSiteId = csvSite.id;
        const isExistingSiteBeingUpdated = !!csvSiteId;

        let isDuplicate: boolean;
        if (isExistingSiteBeingUpdated) {
          // If this row has a ID, it's an existing site to be updated. Verify that the externalId found within the system is not itself
          const existingSiteId = existingSitesMap.get(csvSite.externalId);
          isDuplicate = existingSiteId && existingSiteId !== csvSiteId;
        } else {
          // verify that the externalId is unique within the system
          isDuplicate = existingSitesMap.has(csvSite.externalId);
        }
        return isDuplicate;
      })
    ),
  ].map((csvSite) => csvSite.externalId);

  result.valid = result.valid && duplicateCsvSiteExternalIds.length === 0 && duplicateSystemExternalIds.length === 0;

  if (duplicateCsvSiteExternalIds.length > 0 || duplicateSystemExternalIds.length > 0) {
    result.duplicateExternalIds = [...new Set([...duplicateCsvSiteExternalIds, ...duplicateSystemExternalIds])];
  }

  return result;
};

/** Find columns with missing required value */
export const validateRequiredValuesFilled = (site: SiteDTO): string[] => {
  const fieldsMissingRequiredValues = SITEDTO_FIELDS_WITH_REQUIRED_VALUE.filter((requiredField) => {
    const fieldNames = requiredField.split('.');
    const value = fieldNames.reduce((result, _, currIdx) => result[fieldNames[currIdx]], site);
    return !value;
  });
  return COLUMNS_WITH_REQUIRED_VALUE.filter((col) => fieldsMissingRequiredValues.includes(SITE_MANAGEMENT_CSV_COLUMNS_DEFINITIONS[col].dtoFieldName));
};

export const validateSite = (csvSite: ICsvSite, columnsWithRequiredValue: CsvSiteColumnName[], indexedExistingSites: Record<number, SiteDTO>): ISiteSyncState => {
  const site = convertCsvSiteToDTO(csvSite);

  // if no id is provided, its a new site
  const isExistingSite = !!csvSite.id;
  if (!isExistingSite) {
    return {
      uid: csvSite.uid,
      site,
      validationStatus: 'add',
    };
  }

  // if the id is provided, make sure it is valid
  const existingSite = indexedExistingSites[csvSite.id];
  if (!existingSite) {
    return {
      uid: csvSite.uid,
      site,
      validationStatus: 'invalid-id',
    };
  }

  // Skip if any required value is missing, will be handled in sync process
  const allRequiredValuesFilled = columnsWithRequiredValue.every((requiredColumnId) => !!csvSite[requiredColumnId]);
  const isSiteUnchanged = allRequiredValuesFilled && isSiteEqual(existingSite, csvSite);
  if (isSiteUnchanged) {
    return {
      uid: csvSite.uid,
      site,
      validationStatus: 'unchanged',
    };
  }

  // site is valid and has changes
  return {
    uid: csvSite.uid,
    site,
    validationStatus: 'update',
  };
};

/** Verify if 2 sites are equal */
export const isSiteEqual = (existingSite: SiteDTO, csvSite: ICsvSite): boolean =>
  // TODO: to consider for future, some European countries don't need to specify state, some tiny countries don't
  //       have postal code
  existingSite.name === csvSite.name &&
  ((!existingSite.externalId && !csvSite.externalId) || existingSite.externalId === csvSite.externalId) &&
  // existingSite.geodeticCoordinates.longitude && currentSite.geodeticCoordinates.longitude &&
  // existingSite.geodeticCoordinates.latitude && currentSite.geodeticCoordinates.latitude &&
  existingSite.address.addressLine1 === csvSite.addressLine1 &&
  ((!existingSite.address.addressLine2 && !csvSite.addressLine2) || existingSite.address.addressLine2 === csvSite.addressLine2) &&
  existingSite.address.city === csvSite.city &&
  existingSite.address.state === csvSite.state &&
  existingSite.address.zip === csvSite.zip &&
  existingSite.address.country === csvSite.country;
