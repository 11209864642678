import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ScreenDegreeToOrientation, ScreenshotOrientation } from '../../model/site-screenshot.enum';
import { IBoardDisplayRow } from './store/site-monitoring-detail.model';

// Default maximum width of a display
export const DEFAULT_DISPLAY_MAXIMUM_WIDTH = 320 / 16;

/**
 * This service is used to sync the width of all displays between boards in a section
 */
@Injectable()
export class SiteMonitoringWidthSyncService implements OnDestroy {
  /** Subject of Width per unit of ratio */
  private _displayWidth$ = new BehaviorSubject<number>(DEFAULT_DISPLAY_MAXIMUM_WIDTH);

  /** Save the width per line of displays */
  private _displayWidthPerBoard: { [key: string]: number } = { maximumWidth: DEFAULT_DISPLAY_MAXIMUM_WIDTH };

  /** Observable of Width per unit of ratio */
  public displayWidth$ = this._displayWidth$.asObservable();

  /** Update width of displays in the board */
  updateWidth(boardId: number, boardDisplaysPerRow: IBoardDisplayRow[], totalPixelBoard: number) {
    if (totalPixelBoard) {
      boardDisplaysPerRow.map((row) => {
        // Get a list of all displays ratio
        const ratioPerDisplay = row.displays.map((item) => (ScreenDegreeToOrientation[item.geometry?.orientation] === ScreenshotOrientation.Portrait ? 9 : 16));

        // Get the sum of all display ratio on 1 line of displays
        const totalRatio = ratioPerDisplay.reduce((a, b) => a + b, 0);

        // Get width per unit of ratio (Ex: multiply by 16 to get the total width for landscape. Multiply by 9 to get portrait)
        const widthPerRatio = totalPixelBoard / totalRatio;

        this._displayWidthPerBoard[boardId + '_' + row.rowIndex] = widthPerRatio;
      });
    }

    // Check if the minimum width has changed
    const minWidth = Math.min(...Object.values(this._displayWidthPerBoard));

    // Update width per ratio for every display
    this._displayWidth$.next(minWidth);
  }

  ngOnDestroy() {
    this._displayWidth$.complete();
  }
}
