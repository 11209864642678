import { IJsonSchema } from '@activia/json-schema-forms';
import { FormControlComponent, ThemeType } from '@activia/ngx-components';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Optional,
  Output,
  Self
} from '@angular/core';
import { FormControl, NgControl } from '@angular/forms';
import { IEngineTagValue } from '../../../model/tag-value.interface';

@Component({
  selector: 'amp-tag-value-item',
  templateUrl: './tag-value-item.component.html',
  styleUrls: ['./tag-value-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TagValueItemComponent extends FormControlComponent implements OnInit, OnDestroy {
  /** Represent the tag */
  @Input() tag: IEngineTagValue;

  @Input() ownerConfig: { name: string; color: string; external: boolean };

  @Input() editable = true;
  @Input() isDeletable = true;

  @Output() deleted: EventEmitter<IEngineTagValue> = new EventEmitter();
  /** value changed */
  @Output() changed: EventEmitter<IEngineTagValue> = new EventEmitter();

  /** Internal form control for json-schema-control component. Hold the value of the tag.
   * Used only if we're not using this component as a reactive form control !
   */
  jsonSchemaValueControl = new FormControl();

  // Current value of the form control
  value: any;

  /** Get the current used form control */
  get internalFormControl() {
    return this.formControl || this.jsonSchemaValueControl;
  }

  get isEdited() {
    return this.internalFormControl.dirty;
  }

  themeType = ThemeType;

  constructor(@Optional() @Self() ngControl: NgControl, changeDetector: ChangeDetectorRef) {
    super(ngControl, changeDetector);
  }

  override ngOnInit() {
    super.ngOnInit();

    // Ensure tag is defined
    if (!this.tag) {
      throw new Error('Input [tag] required by <amp-tag-value-item>');
    }

    this.internalFormControl.reset(this.tag.values); // Initialize the form control with the tag value
    this.value = this.tag.values;
  }

  updateValue(value): void {
    this.value = value;
  }

  onDeleteTag() {
    this.deleted.emit(this.tag);
  }

  exampleExist(examples: string[]): boolean {
    return (examples || []).some((example) => !!example);
  }

  infoExist(schema: IJsonSchema): boolean {
    return !!schema && !!(schema.description || this.exampleExist(schema['examples']));
  }

  getLabel(tag: any) {
    const schemaTitle = tag?.keyDescription?.schema?.title;
    const key = tag?.key;
    return schemaTitle && key ? `${schemaTitle} | ${key}` : schemaTitle || key || '';
  }

  /** Remove title from the json-schema */
  getSchemaWithoutTitle(jsonSchema: IJsonSchema) {
    const { title, ...newSchema } = jsonSchema;
    return newSchema;
  }

  onChange(value: any): void {
    this.propagateTouched();
    this.propagateChange(value);
    this.value = value;

    this.changed.emit({
      key: this.tag.key,
      values: value,
      propertyType: this.tag.propertyType,
      keyDescription: this.tag.keyDescription,
    });
  }
}
