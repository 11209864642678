import { dataOnceReady, IDataSource, IDataSourceConfig, IDataTableColumnConfig, IDataTableColumnSortEvent, IDataTableSelectionChangeEvent, ModalService } from '@activia/ngx-components';
import { ChangeDetectionStrategy, Component, OnDestroy, TemplateRef, ViewChild } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { BehaviorSubject, combineLatest, Observable, ReplaySubject, share, Subject } from 'rxjs';
import { map, switchMap, takeUntil, tap } from 'rxjs/operators';
import { IGeoResult } from '@activia/geo';
import { AuthFacade, SITE_MANAGEMENT_ROLES } from '@amp/auth';
import { Store } from '@ngrx/store';
import { ISiteManagementState } from '../../store/site-management.reducer';
import * as SiteManagementAction from '../../store/site-management.actions';
import { SiteColumnsService } from '../services/site-columns.service';
import { siteSyncSelectors } from '../../store/site-sync/site-sync.selectors';
import { ISiteSyncState, SiteSyncGlobalStatus } from '../../store/site-sync/site-sync.model';
import { GEODETIC_SITE_COLS } from '../models/site-column';
import { SiteSyncStatusIcons, SiteSyncStatusThemes } from '../../models/site-management-sync';
import { SyncModalService } from '../../services/sync-modal.service';
import { siteManagementEntities } from '../../store/site-management.selectors';
import { SiteManagementSettingsDrawerComponent } from '../../components/experience-template/site-management-settings-drawer/site-management-settings-drawer.component';

type SiteSyncListFilter = 'all' | 'in-progress' | 'completed' | 'error' | 'warning' | 'skipped';

@Component({
  selector: 'amp-site-sync',
  templateUrl: './site-sync.component.html',
  styleUrls: ['./site-sync.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SiteSyncComponent implements OnDestroy {
  @ViewChild('operationTemplate', { static: false }) operationTemplate: TemplateRef<void>;
  @ViewChild('progressTemplate', { static: false }) progressTemplate: TemplateRef<void>;
  @ViewChild('statusTemplate', { static: false }) statusTemplate: TemplateRef<void>;
  @ViewChild('actionsTemplate', { static: false }) actionsTemplate: TemplateRef<void>;

  hasSyncJob$: Observable<boolean>;
  syncState$: Observable<SiteSyncGlobalStatus>;
  dataSource$: Observable<IDataSource<ISiteSyncState>>;
  columns$: Observable<IDataTableColumnConfig<void>[]>;
  importDataSourceConfig: IDataSourceConfig<ISiteSyncState> = {
    id: 'uid',
  };
  selectedSites: ISiteSyncState[] = [];

  isAdmin$: Observable<boolean>;

  /** Counts of sites in each processing state **/
  counts$: Observable<Record<SiteSyncListFilter, number>>;

  /** Subject that emits the current site filter **/
  private _siteFilterSubject = new BehaviorSubject<SiteSyncListFilter>('all');

  SiteSyncStatusIcons = SiteSyncStatusIcons;
  SiteSyncStatusThemes = SiteSyncStatusThemes;

  /** @ignore **/
  private _componentDestroyed$: Subject<void> = new Subject<void>();

  constructor(
    private _modalService: ModalService,
    private _siteColumnService: SiteColumnsService,
    private _store: Store<ISiteManagementState>,
    private _syncModalService: SyncModalService,
    public translateService: TranslocoService,
    private _authFacade: AuthFacade,
  ) {
    this.isAdmin$ = this._authFacade.hasAnyRole$(...SITE_MANAGEMENT_ROLES.appSettings);

    this.syncState$ = this._store.select(siteSyncSelectors.status);
    this.hasSyncJob$ = this._store.select(siteSyncSelectors.hasSyncJob);

    this.columns$ = this._siteColumnService.siteColumnsFormatted$(GEODETIC_SITE_COLS, 'site.').pipe(map((allCols) => this._addCsvSyncColumns(allCols)));

    this.dataSource$ = combineLatest([this._siteFilterSubject.asObservable(), this._store.select(siteSyncSelectors.sitesSyncState)]).pipe(
      map(([siteFilter, sites]) => {
        const filteredSites = Object.values(sites || {}).filter((site) => this._siteMatchesFilter(site, siteFilter));
        return { data: filteredSites, total: filteredSites.length };
      })
    );

    this.counts$ = this._store
      .select(siteSyncSelectors.sitesSyncState)
      .pipe(
        map((sitesMap) => {
          const siteStates = Object.values(sitesMap || {});
          return siteStates.reduce(
            (counts, siteState) => {
              counts['all']++;
              if (!siteState.syncProgress) {
                counts['skipped']++;
              } else if (siteState?.syncProgress.status === 'queued' || siteState?.syncProgress.status === 'processing') {
                counts['in-progress']++;
              } else if (siteState?.syncProgress.status === 'processed') {
                counts['completed']++;
              } else if (siteState?.syncProgress.status === 'warning') {
                counts['warning']++;
              } else if (siteState?.syncProgress.status === 'error') {
                counts['error']++;
              }
              return counts;
            },
            {
              all: 0,
              skipped: 0,
              'in-progress': 0,
              completed: 0,
              warning: 0,
              error: 0,
            } as Record<SiteSyncListFilter, number>
          );
        })
      )
      .pipe(
        share({
          connector: () => new ReplaySubject(1),
          resetOnRefCountZero: true,
        })
      );
  }

  /** Gets the current filter applied **/
  get siteFilter(): SiteSyncListFilter {
    return this._siteFilterSubject.value;
  }

  /** Sets the current filter **/
  updateFilter(siteFilter: SiteSyncListFilter) {
    this._siteFilterSubject.next(siteFilter);
  }

  /** @ignore **/
  ngOnDestroy(): void {
    this._componentDestroyed$.next();
    this._componentDestroyed$.complete();
  }

  updateSiteListSort({ sortBy }: IDataTableColumnSortEvent) {
    this._store.dispatch(SiteManagementAction.UpdateSiteListSort({ sortBy }));
  }

  formatGeoAddresses(geoResults: IGeoResult[]): string[] {
    return geoResults.map((geoResult) => geoResult.formattedAddress);
  }

  onSelectImportJobs($event: IDataTableSelectionChangeEvent<ISiteSyncState>) {
    this.selectedSites = $event.selected;
  }

  openSiteSyncModal() {
    const modalRef = this._syncModalService.openSiteSyncModal([]);
    modalRef.componentInstance.downloadCsvSites
      .pipe(
        switchMap(() => {
          const sites$ = dataOnceReady(this._store.pipe(siteManagementEntities.allSitesData$), this._store.pipe(siteManagementEntities.allSitesDataState$), 1);
          return sites$.pipe(
            tap((sites) => {
              // export the list of sites selected as csv
              this._syncModalService.exportSites(sites);
            })
          );
        }),
        takeUntil(this._componentDestroyed$)
      )
      .subscribe();
  }

  public openSettingsDrawer(): void {
    this._modalService.openDrawer<SiteManagementSettingsDrawerComponent, any>(SiteManagementSettingsDrawerComponent, { closeOnBackdropClick: true });
  }

  private _addCsvSyncColumns(cols: IDataTableColumnConfig<void>[]): IDataTableColumnConfig<void>[] {
    const disableSortCols = cols.map((c) => {
      const dCol = { ...c };
      dCol.sortable = false;
      return dCol;
    });
    const columns: IDataTableColumnConfig<void>[] = [
      ...disableSortCols,
      {
        id: 'OPERATION',
        name: this.translateService.translate('siteManagementScope.SITE_MANAGEMENT.SYNC_SITES.TABLE.OPERATION_TITLE_35'),
        dataCellTemplate: this.operationTemplate,
        prop: 'validationStatus',
        sortable: false,
        resizable: true,
        draggable: false,
        widthPx: 80,
      },
      {
        id: 'PROGRESS',
        name: this.translateService.translate('siteManagementScope.SITE_MANAGEMENT.SYNC_SITES.TABLE.PROGRESS_TITLE_35'),
        dataCellTemplate: this.progressTemplate,
        sortable: false,
        resizable: true,
        draggable: false,
        widthPx: 90,
      },
      {
        id: 'STATUS',
        name: this.translateService.translate('siteManagementScope.SITE_MANAGEMENT.SYNC_SITES.TABLE.STATUS_TITLE_35'),
        dataCellTemplate: this.statusTemplate,
        sortable: false,
        resizable: true,
        draggable: false,
        widthPx: 220,
      },
      {
        id: 'ACTIONS',
        dataCellTemplate: this.actionsTemplate,
        sortable: false,
        resizable: false,
        draggable: false,
        widthPx: 40,
        isActionColumn: true,
        sticky: true,
        stickyPosition: 'right',
      },
    ];
    return columns;
  }

  private _siteMatchesFilter(siteState: ISiteSyncState, siteFilter: SiteSyncListFilter) {
    const isSkipped = !siteState.syncProgress;
    const status = siteState.syncProgress?.status;
    return (
      siteFilter === 'all' ||
      (siteFilter === 'skipped' && isSkipped) ||
      (siteFilter === 'in-progress' && (status === 'queued' || status === 'processing')) ||
      (siteFilter === 'completed' && status === 'processed') ||
      (siteFilter === 'warning' && status === 'warning') ||
      (siteFilter === 'error' && status === 'error')
    );
  }
}
