import { createAction, props } from '@ngrx/store';
import { Update } from '@ngrx/entity';
import { IGridSize } from '@activia/ngx-components';
import { IBoard } from '../../models/board-config.interface';
import { DisplayDTO } from '@activia/cm-api';
import { IExperienceTemplate } from '../../models/experience-template.interface';

//#region API call
export const AddBoard = createAction('[Board/API] Add Board', props<{ boardName: string; boardOrgPathTags: Record<string, any>; gridSize: IGridSize }>());
export const AddBoardSuccess = createAction('[Board/API] Add Board Success', props<{ board: IBoard; displays: DisplayDTO[] }>());
export const AddBoardFail = createAction('[Board/API] Add Board Fail', props<{ error: any }>());

// Optimistic delete
export const DeleteBoard = createAction('[Board/API] Delete Board', props<{ boardId: number }>());
export const DeleteBoardSuccess = createAction('[Board/API] Delete Board Success', props<{ boardId: number }>());
export const DeleteBoardFail = createAction('[Board/API] Delete Board Fail', props<{ error: any; boardId: number }>());

// Optimistic update
export const UpdateBoard = createAction('[Board/API] Update Board', props<{ boardId: number; boardName: string; boardOrgPathTags: Record<string, any>; gridSize: IGridSize }>());
export const UpdateBoardSuccess = createAction('[Board/API] Update Board Success', props<{ board: IBoard; boardId: number; displays: DisplayDTO[] }>());
export const UpdateBoardFail = createAction('[Board/API] Update Board Fail', props<{ error: any }>());

/** Create boards from template */
export const CreateTemplateBoards = createAction('[Board/API] Create Template Boards', props<{ expTemplate: IExperienceTemplate }>());
export const CreateTemplateBoardsSuccess = createAction('[Board/API] Create Template Boards Success', props<{ boards: IBoard[] }>());
export const CreateTemplateBoardsFail = createAction('[Board/API] Create Template Boards Fail', props<{ error: any }>());

export const SwapBoardOrder = createAction('[Board/API] Swap board order Board', props<{ boardId: number; fromIndex: number; toIndex: number }>());
export const SwapBoardOrderSuccess = createAction('[Board/API] Swap board order Success', props<{ boardId: number; fromIndex: number; toIndex: number }>());
export const SwapBoardOrderFail = createAction('[Board/API] Swap board order Fail', props<{ error: any; boardId: number; fromIndex: number; toIndex: number }>());

export const UpdateBoardSize = createAction('[Board] Update Board Size', props<{ boardId: number; boardSize: IGridSize }>());
export const UpdateBoardSizeSuccess = createAction('[Board] Update Board Size Success', props<{ board: Update<IBoard>; displays: DisplayDTO[] }>());
export const UpdateBoardSizeFail = createAction('[Board] Update Board Size Fail', props<{ error: any }>());
//#endregion

//#region UI Changes
export const ToggleLockBoard = createAction('[Board] Toggle Lock Board', props<{ boardId: number }>());

export const ToggleAccordionBoard = createAction('[Board] Toggle Board', props<{ boardId: number }>());
//#endregion
