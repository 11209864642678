import { ChangeDetectionStrategy, Component, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import {
  IDataTableColumnConfig, IDataTableDataSource,
  IModalComponent,
  ModalRef
} from '@activia/ngx-components';
import { Observable, Subject } from 'rxjs';
import { Store } from '@ngrx/store';
import { TranslocoService } from '@ngneat/transloco';
import { map } from 'rxjs/operators';
import { IGeoAutoFixSite } from '../../models/geo-fixer-site.interface';
import { geoFixerEntities } from '../../store/geo-fixer/geo-fixer.selectors';
import { GEODETIC_SUMMARY_SITE_COLS } from '../../sites/models/site-column';
import { SiteColumnsService } from '../../sites/services/site-columns.service';

@Component({
  selector: 'amp-geo-fix-site-summary-modal',
  templateUrl: './geo-fix-site-summary-modal.component.html',
  styleUrls: ['./geo-fix-site-summary-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GeoFixSiteSummaryModalComponent implements OnInit, OnDestroy, IModalComponent {
  @ViewChild('statusTemplate', { static: true }) statusTemplate: TemplateRef<any>;
  @ViewChild('errorTemplate', { static: true }) errorTemplate: TemplateRef<any>;

  activeLang: string;

  columns$: Observable<IDataTableColumnConfig<void>[]>;
  dataSource$: Observable<IDataTableDataSource<IGeoAutoFixSite>>;

  /** @ignore **/
  private _componentDestroyed$: Subject<void> = new Subject<void>();

  /** @ignore **/
  constructor(
    private _dialogRef: ModalRef<GeoFixSiteSummaryModalComponent>,
    private _store: Store,
    private _translocoService: TranslocoService,
    private _siteColumnService: SiteColumnsService,
  ) {
    this.activeLang = this._translocoService.getActiveLang();

    this.columns$ = this._siteColumnService.siteColumnsFormatted$(GEODETIC_SUMMARY_SITE_COLS).pipe(
      map((allCols) => this._addExtraColumns(allCols))
    );
  }

  ngOnInit() {
    this.dataSource$ = this._store.pipe(geoFixerEntities.autoFixSites$).pipe(
      map((sites) => {
        const dataSource = {
          rows: sites,
          totalRowCount: sites.length,
        };
        return dataSource;
      })
    );
  }

  canClose(): boolean {
    return true;
  }

  onClose() {
    this._dialogRef.close();
  }

  /** @ignore **/
  ngOnDestroy(): void {
    this._componentDestroyed$.next();
    this._componentDestroyed$.complete();
  }

  private _addExtraColumns(cols: IDataTableColumnConfig<void>[]): IDataTableColumnConfig<void>[] {
    const disableSortCols = cols.map((c) => {
      const dCol = { ...c, sortable: false };
      return dCol;
    });
    const columns: IDataTableColumnConfig<void>[] = [
      ...disableSortCols,
      {
        id: 'autoFixStatus',
        name: this._translocoService.selectTranslate('siteManagementScope.SITE_MANAGEMENT.GEODETIC_FIXER.AUTO_FIX_SITES_TASK_PANEL.STATUS_30'),
        dataCellTemplate: this.statusTemplate,
        sortable: false,
        resizable: false,
        draggable: false,
        widthPx: 150,
      },
      {
        id: 'error',
        name: this._translocoService.selectTranslate('siteManagementScope.SITE_MANAGEMENT.GEODETIC_FIXER.AUTO_FIX_SITES_TASK_PANEL.ERROR_30'),
        dataCellTemplate: this.errorTemplate,
        sortable: false,
        resizable: false,
        draggable: false,
      },
    ];
    return columns;
  }
}
