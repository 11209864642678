<ul class="invalid-asset-list" avnScroll>
  <ng-container *ngFor="let tagOperation of tagOperations">
    <ng-container *ngIf="tagOperation.count">
      <ng-container [ngSwitch]="tagOperation.operation">
        <div>
          <avn-icon icon="action:info" [fontSize]="18" [avnTheme]="'warning'"></avn-icon>
          <li>
            <b>{{ tagOperation.title }}</b>

            <ng-container *ngSwitchCase="'delete'">
              {{ tagOperation.count | i18nPlural: i18nTagMessageMapping.delete | transloco: { count: tagOperation.count, oldValue: tagOperation.value } }}
            </ng-container>
            <ng-container *ngSwitchCase="'replace'">
              {{
                tagOperation.count
                  | i18nPlural: i18nTagMessageMapping.replace
                  | transloco: { count: tagOperation.count, oldValue: tagOperation.value, newValue: tagOperation.newValue }
              }}
            </ng-container>
            <ng-container *ngSwitchDefault>
              {{ tagOperation.count | i18nPlural: i18nTagMessageMapping.default | transloco: { count: tagOperation.count } }}
            </ng-container>
          </li>
        </div>
      </ng-container>
    </ng-container>
  </ng-container>
</ul>
