import { IResizeableLayoutDefinition, ModalService, ThemeType } from '@activia/ngx-components';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { IBoardExperienceTemplate, IExperienceTemplate, RedundancyStrategy } from '../../../models/experience-template.interface';
import { SiteManagementSettingsDrawerComponent } from '../site-management-settings-drawer/site-management-settings-drawer.component';

@Component({
  selector: 'amp-exp-template-manager',
  templateUrl: './exp-template-manager.component.html',
  styleUrls: ['./exp-template-manager.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ExpTemplateManagerComponent {
  selectedTemplate: IExperienceTemplate;
  selectedBoard: IBoardExperienceTemplate;

  themeType = ThemeType;

  layout: IResizeableLayoutDefinition = {
    layout: [['list', 'edit', 'detail']],
    cols: [0.2, 0.5, 0.3],
    rows: [1],
  };

  constructor(private _modalService: ModalService) {}

  onSelectTemplate(selectedTemplate: IExperienceTemplate) {
    this.selectedTemplate = selectedTemplate;
    this.selectedBoard = undefined;
  }

  onBoardSelect(selectedBoard: IBoardExperienceTemplate) {
    this.selectedBoard = selectedBoard;
  }

  addNewTemplate() {
    this.selectedBoard = undefined;
    this.selectedTemplate = {
      tags: {},
      label: '',
      boards: [],
    };
  }

  addNewBoard() {
    this.selectedBoard = {
      label: '',
      redundancyStrategy: RedundancyStrategy.NONE,
      player: {
        ipAdress: '',
        model: '',
        namePattern: '',
        playbackUnit: {
          count: 4,
          outputPerPlaybackUnit: 1,
        },
      },
      displays: [],
    };
    this.selectedTemplate = {
      ...this.selectedTemplate,
      boards: [...(this.selectedTemplate?.boards ?? []), this.selectedBoard],
    };
  }

  public openSettingsDrawer(): void {
    this._modalService.openDrawer<SiteManagementSettingsDrawerComponent, any>(SiteManagementSettingsDrawerComponent, { closeOnBackdropClick: true });
  }
}
