import { ChangeDetectionStrategy, Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { SiteDTO } from '@activia/cm-api';
import { ILocation } from '../../../site-management-location/location.interface';
import { BehaviorSubject, distinctUntilChanged, filter, Observable, of, Subject, takeUntil, tap } from 'rxjs';
import { ISiteManagementState } from '../../../../store/site-management.reducer';
import { Store } from '@ngrx/store';
import * as SiteManagementAction from '../../../../store/site-management.actions';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { siteManagementEntities } from '../../../../store/site-management.selectors';
import { ActivatedRoute, Router } from '@angular/router';
import { ThemeType } from '@activia/ngx-components';
import { ISiteManagementConfig, SITE_MANAGEMENT_MODULE_CONFIG } from '@amp/environment';
import { ISaveBeforeExit } from '../../../../guards/save-before-exit.guard';
import { SiteManagementService } from '../../../../services/site-management.service';
import { SiteManagementSiteBasicInfoEditorComponent } from '../../../site-management-site-basic-info-editor/site-management-site-basic-info-editor.component';
import { LocationEditorComponent } from '../../../site-management-location/location-editor/location-editor.component';

@Component({
  selector: 'amp-site-management-site-info-editor',
  templateUrl: './site-management-site-info-editor.component.html',
  styleUrls: ['./site-management-site-info-editor.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SiteManagementSiteInfoEditorComponent implements OnInit, OnDestroy, ISaveBeforeExit {
  @ViewChild(SiteManagementSiteBasicInfoEditorComponent) siteEditorComponent: SiteManagementSiteBasicInfoEditorComponent;
  @ViewChild(LocationEditorComponent) locationEditorComponent: LocationEditorComponent;

  /** Show 'back to overview' button  */
  showBackButton: boolean;

  site$: BehaviorSubject<SiteDTO> = new BehaviorSubject<SiteDTO>(null);
  mapLocation$: BehaviorSubject<ILocation> = new BehaviorSubject<ILocation>({ id: 0 });

  hasBasicInfoErrors = false;

  form: UntypedFormGroup;
  THEME_TYPES = ThemeType;

  editable$: Observable<boolean>;

  /** @ignore Pattern used to close all subscriptions */
  private _componentDestroyed$: Subject<void> = new Subject<void>();

  constructor(
    private _formBuilder: UntypedFormBuilder,
    private _store: Store<ISiteManagementState>,
    private router: Router,
    private route: ActivatedRoute,
    @Inject(SITE_MANAGEMENT_MODULE_CONFIG) private _siteManagementConfig: ISiteManagementConfig,
    private _siteManagementService: SiteManagementService
  ) {
    this.form = this._formBuilder.group({});

    this.editable$ = this._siteManagementService.hasAuthority$('site').pipe(
      tap((editable) => {
        if (editable) {
          this.form.enable();
        } else {
          this.form.disable();
        }
      })
    );
  }

  ngOnInit(): void {
    this._store
      .pipe(siteManagementEntities.selectedCurrentSite$)
      .pipe(
        filter((site) => !!site),
        distinctUntilChanged((prev, curr) => prev.id === curr.id),
        tap((site) => {
          this._setSite(site);

          // Reset form when site changes
          if (this.form) {
            this.form.markAsUntouched();
            this.form.markAsPristine();
          }
        }),
        takeUntil(this._componentDestroyed$)
      )
      .subscribe();

    this.showBackButton = !!this.route.snapshot?.data?.showBackButton;
  }

  /** @ignore **/
  ngOnDestroy(): void {
    this._componentDestroyed$.next();
    this._componentDestroyed$.complete();
  }

  onSave(): void {
    const siteDto = this.siteEditorComponent.getData();
    const locationDto = this.locationEditorComponent.getData();
    const site: SiteDTO = { ...this.site$.value, ...siteDto.site, ...locationDto };
    this._store.dispatch(SiteManagementAction.UpdateSite({ site, tags: siteDto.tags }));
    this.form.markAsUntouched();
    this.form.markAsPristine();
  }

  onCancel(): void {
    this.router.navigate([...this._siteManagementConfig.moduleBasePath, 'sites', this.site$.value.id, 'detail']);
  }

  private _setSite(site: SiteDTO) {
    // this.currSite = { ...site };
    // this.site$.next(this.currSite);
    this.site$.next(site);

    this.mapLocation$.next({
      id: site.id,
      geodeticCoordinates: {
        ...site.geodeticCoordinates,
      },
      address: {
        ...site.address,
      },
    });
  }

  getChangeDetails(): { hasUnsavedChanges: boolean; hasInvalidChanges?: boolean } {
    return { hasUnsavedChanges: this.form.dirty, hasInvalidChanges: this.form.invalid };
  }

  save(): Observable<boolean> {
    this.onSave();
    return of(true);
  }
}
