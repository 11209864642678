<ng-container
  *avnAsyncData="
    keyMetrics$ | async;
    state: keyMetricsDataState$ | async;
    errorTemplate: errorTemplate;
    emptyTemplate: emptyKeyMetricsTemplate;
    loadingTemplate: loadingKeyMetricsTemplate;
    emptyFn: emptyKeyMetricsFn
  "
>
  <div class="key-metrics" *ngIf="!carouselEnabled; else carouselView">
    <ng-container *ngFor="let item of keyMetrics$ | async">
      <ng-container *ngTemplateOutlet="showEachMetric; context: { metric: item }"></ng-container>
    </ng-container>
  </div>
</ng-container>

<ng-template #emptyKeyMetricsTemplate>
  <avn-skeleton
    data-e2e="empty"
    [animate]="false"
    [animateDirection]="'VERTICAL'"
    [text]="'siteMonitoringSharedScope.SITE_MONITORING.KEY_METRICS.KEY_METRICS_NOT_CONFIG_MSG_200' | transloco"
    [subText]="'siteMonitoringSharedScope.SITE_MONITORING.KEY_METRICS.KEY_METRICS_NOT_CONFIG_MSG2_200' | transloco"
    [skeletonType]="'BAR_CHART'"
    [style.height.px]="height || 200"
  >
  </avn-skeleton>
</ng-template>

<ng-template #errorTemplate>
  <avn-empty-data-message
    icon="alert:error"
    [text]="'siteMonitoringSharedScope.SITE_MONITORING.KEY_METRICS.KEY_METRICS_ERROR_TEXT_150' | transloco"
    [subText]="'siteMonitoringSharedScope.SITE_MONITORING.KEY_METRICS.KEY_METRICS_ERROR_SUB_TEXT_150' | transloco"
  >
  </avn-empty-data-message>
</ng-template>

<ng-template #loadingKeyMetricsTemplate>
  <avn-skeleton
    data-e2e="loading"
    [animate]="true"
    [animateDirection]="'VERTICAL'"
    [text]="'siteMonitoringSharedScope.SITE_MONITORING.KEY_METRICS.KEY_METRICS_LOADING_TEXT_50' | transloco"
    [skeletonType]="'BAR_CHART'"
  >
  </avn-skeleton>
</ng-template>

<ng-template #carouselView>
  <avn-carousel [pointVisible]="true" [itemPerSlideConfig]="carouselSlideConfig" [buttonVisible]="true" [minSpace]="50" [withAnimation]="true">
    <ng-container *ngFor="let item of keyMetrics$ | async; let i = index">
      <ng-container *avnCarouselItem>
        <ng-container *ngTemplateOutlet="showEachMetric; context: { metric: item }"></ng-container>
      </ng-container>
    </ng-container>
  </avn-carousel>
</ng-template>

<ng-template let-metric="metric" #showEachMetric>
  <ng-container [ngSwitch]="metric.id">
    <ng-container *ngSwitchCase="SiteMonitoringKeyMetric.HealthStatusSummary">
      <amp-key-metrics-health-summary
        [showCompactView]="showCompactView"
        [config]="metric.config"
        [monitoringData]="metric.data"
        [monitoringDate]="metric.date"
        [monitoringDataDataState]="keyMetricsDataState$ | async"
        [style.height.px]="height"
      ></amp-key-metrics-health-summary>
    </ng-container>

    <ng-container *ngSwitchCase="SiteMonitoringKeyMetric.Services">
      <amp-key-metrics-services
        [showCompactView]="showCompactView"
        [config]="metric.config"
        [monitoringData]="metric.data"
        [monitoringDataDataState]="keyMetricsDataState$ | async"
        [style.height.px]="height"
      ></amp-key-metrics-services>
    </ng-container>

    <ng-container *ngSwitchCase="SiteMonitoringKeyMetric.ContentStatus">
      <amp-key-metrics-content-accuracy
        [showCompactView]="showCompactView"
        [config]="metric.config"
        [monitoringData]="metric.data"
        [monitoringDate]="metric.date"
        [monitoringDataDataState]="keyMetricsDataState$ | async"
        [style.height.px]="height"
      ></amp-key-metrics-content-accuracy>
    </ng-container>

    <ng-container *ngSwitchCase="SiteMonitoringKeyMetric.CodState">
      <amp-key-metrics-cod
        [showCompactView]="showCompactView"
        [config]="metric.config"
        [monitoringData]="metric.data"
        [monitoringDate]="metric.date"
        [monitoringDataDataState]="keyMetricsDataState$ | async"
        [style.height.px]="height"
      ></amp-key-metrics-cod>
    </ng-container>

    <ng-container *ngSwitchCase="SiteMonitoringKeyMetric.PosState">
      <amp-key-metrics-pos
        [showCompactView]="showCompactView"
        [config]="metric.config"
        [monitoringData]="metric.data"
        [monitoringDate]="metric.date"
        [monitoringDataDataState]="keyMetricsDataState$ | async"
        [style.height.px]="height"
      ></amp-key-metrics-pos>
    </ng-container>

    <ng-container *ngSwitchCase="SiteMonitoringKeyMetric.SystemDisk">
      <amp-key-metrics-disk
        [showCompactView]="showCompactView"
        [config]="metric.config"
        [monitoringData]="metric.data"
        [monitoringDataDataState]="keyMetricsDataState$ | async"
        [style.height.px]="height"
      ></amp-key-metrics-disk>
    </ng-container>

    <ng-container *ngSwitchCase="SiteMonitoringKeyMetric.EnclosureStatus">
      <amp-key-metrics-enclosure-status
        [keyMetric]="metric"
        [showCompactView]="showCompactView"
        [style.height.px]="height"
      ></amp-key-metrics-enclosure-status>
    </ng-container>
  </ng-container>
</ng-template>
