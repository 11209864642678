import { AuthenticatedUser, RoleDTO, UserDTO } from '@activia/cm-api';
import { createAction, props } from '@ngrx/store';

export const AuthInitiated = createAction('[Auth] App Initiated');
export const AppSessionReady = createAction('[Auth] App Session Ready', props<{ token: string }>());
export const LoginInitiated = createAction('[Auth] Login initiated', props<{ username: string; password: string; url?: string }>());
export const LoginSuccess = createAction('[Auth] Login success', props<{ authenticatedUser: AuthenticatedUser }>());
export const LoginComplete = createAction(
  '[Auth] Login user Permissions and info received',
  props<{ permissions: string[]; userInfo: UserDTO; userRoles: RoleDTO[]; scopes: any; returnUrl?: string }>()
);
export const LoginIncomplete = createAction('[Auth] Login user Permissions and info failed', props<{ error: any }>());
export const LoginFail = createAction('[Auth] Login fail', props<{ error: any }>());
export const Logout = createAction('[Auth] Logout');
export const LogoutSuccess = createAction('[Auth] Logout Success');
export const LogoutFromTabSync = createAction('[Auth] Logout action from tab sync');
export const LogoutFail = createAction('[Auth] Logout Fail', props<{ error: any }>());
export const AuthExpired = createAction('[Auth] Expired', props<{ httpStatusCode: string }>());
export const UserInfoUpdate = createAction('[Auth] User Info Update', props<{ user: UserDTO }>());
