import { ChangeDetectionStrategy, Component, EventEmitter, Inject, OnDestroy, Output, ViewChild } from '@angular/core';
import { IModalComponent, IModalConfig, MODAL_CONFIG, ModalRef } from '@activia/ngx-components';
import { IModalPickerComponent } from '../../models/modal-picker-component.interface';
import { catchError, of, Subject } from 'rxjs';
import { SiteDTO, SitesService, SiteTagsService } from '@activia/cm-api';
import { tap } from 'rxjs/operators';
import { LocationEditorComponent } from '../site-management-location/location-editor/location-editor.component';
import { MessengerNotificationService } from '@amp/messenger';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import * as SiteManagementAction from '../../store/site-management.actions';
import { Store } from '@ngrx/store';
import { ISiteManagementState } from '../../store/site-management.reducer';
import { createSite$ } from '../../utils/site.utils';
import { CountryService, GeoTimezoneService } from '@activia/geo';
import { IExperienceTemplateInfo } from '../../models/experience-template.interface';
import { SiteManagementSiteBasicInfoEditorComponent } from '../site-management-site-basic-info-editor/site-management-site-basic-info-editor.component';

@Component({
  selector: 'amp-site-management-site-info-creator-modal',
  templateUrl: './site-management-site-info-creator-modal.component.html',
  styleUrls: ['./site-management-site-info-creator-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SiteManagementSiteInfoCreatorModalComponent implements IModalComponent, IModalPickerComponent<number>, OnDestroy {
  @ViewChild(SiteManagementSiteBasicInfoEditorComponent, { static: false }) basicInfoEditor: SiteManagementSiteBasicInfoEditorComponent;
  @ViewChild(LocationEditorComponent, { static: false }) locationEditor: LocationEditorComponent;

  /** Emits the ID of the new site after the site is created **/
  @Output() saved = new EventEmitter<number>();

  experiencesSelected: IExperienceTemplateInfo = null;

  /** Form used to check org path and name validation */
  form: UntypedFormGroup = this._fb.group({});

  /** @ignore **/
  private _componentDestroyed$: Subject<void> = new Subject<void>();

  constructor(
    private _dialogRef: ModalRef<SiteManagementSiteInfoCreatorModalComponent>,
    private _siteService: SitesService,
    private _store: Store<ISiteManagementState>,
    @Inject(MODAL_CONFIG) public dialogConfig: IModalConfig<{ site: SiteDTO }>,
    private _messengerNotificationService: MessengerNotificationService,
    private _fb: UntypedFormBuilder,
    private _geoTimezoneService: GeoTimezoneService,
    private _countryService: CountryService,
    private _siteTagsService: SiteTagsService
  ) {}

  /** @ignore **/
  ngOnDestroy(): void {
    this._componentDestroyed$.next();
    this._componentDestroyed$.complete();
  }

  canClose(): boolean {
    return true;
  }

  /** Called when the cancel button is clicked **/
  onCancel() {
    this._close();
  }

  onExperienceSelected($event: IExperienceTemplateInfo) {
    this.experiencesSelected = $event;
  }

  /** Called when the create button is clicked **/
  onCreate() {
    const siteData = this.basicInfoEditor.getData();
    const locationDto = this.locationEditor.getData();
    const siteDto = { ...siteData.site, ...locationDto };

    createSite$(this._geoTimezoneService, this._countryService, this._siteService, siteDto, siteData.tags, this._siteTagsService)
      .pipe(
        tap((site: SiteDTO) => {
          this.saved.emit(site.id);
          this._store.dispatch(
            SiteManagementAction.CreateSiteSuccess({
              site,
              experiencesSelected: this.experiencesSelected,
            })
          );
          this._messengerNotificationService.showSuccessMessage('siteManagementScope.SITE_MANAGEMENT.GLOBAL.SUCCESS.CREATE_SITE_SUCCESS_150');
          this._close();
        }),
        catchError(() => {
          this._messengerNotificationService.showErrorMessage('siteManagementScope.SITE_MANAGEMENT.GLOBAL.ERROR.CREATE_SITE_FAIL_150');
          this._close();
          return of(null);
        })
      )
      .subscribe();
  }

  private _close() {
    this._dialogRef.close();
  }
}
