/**
 * ALl possible statuses for a 'command status' (aka a master command)
 * A 'command status' is the status for multiple simple commands.
 * The name is kinda duplicate but we have status for a 'CommandStatus' object and different status codes for a 'Cammand' object
 * https://gitlab.activia.io/cm/engine-core/blob/master/src/net/activia/cm/domain/command/CommandStatus.java
 * */
export enum CommandStatusStatusEnum {
  CANCELLED = 0,
  FAILED = 1,
  INPROGRESS = 2,
  FINISHED = 3,
  SCHEDULED = 4,
  IDLE = 5,
  CANCELLING = 6,
  UNKNOWN = 7,
}

export const getCommandStatusStatusByCode = (value: CommandStatusStatusEnum) => Object.keys(CommandStatusStatusEnum)[Object.values(CommandStatusStatusEnum).indexOf(value)];
