import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Observable, ReplaySubject, share } from 'rxjs';
import { SiteMonitoringFacade } from '../../../store/site-monitoring.facade';
import { ModalService, ModalType } from '@activia/ngx-components';
import { Overlay } from '@angular/cdk/overlay';
import { BoardAlarmModalComponent } from '../../site-alarms/board-alarm-modal/board-alarm-modal.component';
import { IBoardAlarm } from '../../../model/alarm-event.interface';
import { map, switchMap, take, withLatestFrom } from 'rxjs/operators';
import { IOrganizationLevelHealthStatus, OrganizationLevelHealthStatus } from '../../../model/organization-level-health';
import { BoardDTO, MonitoringAlarmEventDTO, SiteDTO } from '@activia/cm-api';
import { SiteMonitoringDetailStore } from '../store/site-monitoring-detail.store';
import { getBoardDeviceIds } from '../../../utils/site-boards.utils';
import { parseAlarmEvents } from '../../../utils/alarm-event.utils';
import { IBoardDeviceAlarmInfo, ICombinedDeviceInfo } from '../store/site-monitoring-detail.model';
import { IBoardWithOrgPath } from '../../../model/board-with-orgpath.interface';

@Component({
  selector: 'amp-site-monitoring-health-indicator',
  templateUrl: './site-monitoring-health-indicator.component.html',
  styleUrls: ['./site-monitoring-health-indicator.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SiteMonitoringHealthIndicatorComponent implements OnChanges {
  @Input() site: SiteDTO;
  @Input() board: IBoardWithOrgPath;

  siteSub: ReplaySubject<SiteDTO> = new ReplaySubject<SiteDTO>(1);
  boardSub: ReplaySubject<BoardDTO> = new ReplaySubject<BoardDTO>(1);

  /** Overall health status of this organization level */
  healthStats$: Observable<IOrganizationLevelHealthStatus>;

  boardAlarms$: Observable<IBoardAlarm>;

  OrganizationLevelHealthStatus = OrganizationLevelHealthStatus;

  constructor(private _modalService: ModalService, private _overlay: Overlay, private _siteMonitoringFacade: SiteMonitoringFacade, private _siteMonitoringDetailStore: SiteMonitoringDetailStore) {
    this.boardAlarms$ = this._getBoardAlarms$();
    this.healthStats$ = this.boardAlarms$.pipe(map((a) => a?.boardStatus));
  }

  private _getBoardAlarms$(): Observable<IBoardAlarm> {
    // reuse the already combined data from the store, computed for each device of the site
    const boardDevices$ = this.boardSub.asObservable().pipe(switchMap((board) => this._siteMonitoringDetailStore.selectDevicesInfo(getBoardDeviceIds(board))));
    return boardDevices$
      .pipe(
        withLatestFrom(
          this._siteMonitoringFacade.preference$.pipe(map((pref) => pref.defaultToOptimisticView)),
          this._siteMonitoringFacade.userPreferences$.pipe(map((userPref) => userPref.showOnlyAlarmErrors))
        )
      )
      .pipe(
        map(([devicesInfo, defaultToOptimisticView, showOnlyAlarmErrors]) => {
          const alarms = devicesInfo.reduce((acc, deviceInfo) => [...acc, ...this._getDeviceAlarms(deviceInfo)], [] as MonitoringAlarmEventDTO[]);
          const devices = devicesInfo.map(({ device }) => device);
          const boardInfo = parseAlarmEvents(alarms, [this.board], devices, defaultToOptimisticView, showOnlyAlarmErrors)[0];
          return {
            ...boardInfo,
            combinedDevicesInfo: devicesInfo,
          };
        }),
        share({
          connector: () => new ReplaySubject(1),
          resetOnRefCountZero: true,
          resetOnComplete: false,
          resetOnError: false,
        })
      );
  }

  private _getDeviceAlarms(deviceInfo: ICombinedDeviceInfo): MonitoringAlarmEventDTO[] {
    return [...deviceInfo.alarms, ...deviceInfo.logicalPlayers.reduce((acc, logicalPlayerAlarms) => [...acc, ...logicalPlayerAlarms.alarms], [] as IBoardDeviceAlarmInfo[])]
      .filter((alarm) => alarm.alarm === 'event')
      .map(({ data }) => data);
  }

  ngOnChanges({ site, board }: SimpleChanges): void {
    if (site && site.currentValue) {
      this.siteSub.next(this.site);
    }
    if (board && board.currentValue) {
      this.boardSub.next(this.board);
    }
  }

  openBoardAlarm() {
    this.boardAlarms$.pipe(take(1)).subscribe((boardAlarms) => {
      this._modalService.open<BoardAlarmModalComponent, any>(
        BoardAlarmModalComponent,
        {
          showCloseIcon: true,
          closeOnBackdropClick: true,
          data: { siteId: this.site.id, board: boardAlarms },
        },
        {
          width: '600px',
          maxHeight: 'calc(100vh - 200px)',
          panelClass: 'overlay-panel-class',
          positionStrategy: this._overlay.position().global().centerHorizontally().centerVertically(),
        },
        ModalType.Dialog
      );
    });
  }
}
