<avn-page>
  <avn-ribbon
    [title]="'siteManagementScope.SITE_MANAGEMENT.GEODETIC_FIXER.TITLE_30' | transloco"
    [showAlternateContent]="selectedSitesList.length > 0"
    [fabIcons]="(isAdmin$ | async) ? 'action:settings' : null"
    (fabActionClicked)="openSettingsDrawer()"
  >
    <avn-breadcrumbs>
      <avn-breadcrumb [label]="'siteManagementScope.SITE_MANAGEMENT.DASHBOARD.RIBBON.TITLE.TITLE_50' | transloco"
                      [routerLink]="['..']"></avn-breadcrumb>
      <avn-breadcrumb
        [label]="'siteManagementScope.SITE_MANAGEMENT.DASHBOARD.CARDS.ALL_SITES.BREADCRUMB_TITLE_50' | transloco"
        [routerLink]="['../sites']"></avn-breadcrumb>
    </avn-breadcrumbs>

    <avn-ribbon-facets>
      <ng-container *ngIf="counts$ | async as counts">
        <ng-container *ngFor="let filter of GeoFixerFilters">
          <avn-ribbon-card
            [icon]="GeoFixerFilter[filter].ribbonIcon"
            [title]="'siteManagementScope.SITE_MANAGEMENT.GEODETIC_FIXER.RIBBON.' + filter.toUpperCase() + '.LABEL_20' | transloco"
            [count]="counts[filter]"
            [toggled]="activeFilter === filter"
            [countTheme]="GeoFixerFilter[filter].ribbonTheme"
            [tooltipPlacement]="'bottom'"
            [avnTooltip]="filter === 'all' ? undefined: 'siteManagementScope.SITE_MANAGEMENT.GEODETIC_FIXER.RIBBON.' + filter.toUpperCase() + '.DESCRIPTION_100' | transloco"
            (actioned)="updateFilter(filter)"
          >
          </avn-ribbon-card>
        </ng-container>
      </ng-container>
    </avn-ribbon-facets>

    <!-- this will be the selection context -->
    <!-- delete => launch job -->
    <!-- geo errs (show count of errors applicable to the selected sites ) => launch for selected -->
    <!-- timezone (show count of errors applicable to the selected sites ) => launch job -->
    <avn-ribbon-alternate-content [title]="'siteManagementScope.SITE_MANAGEMENT.DASHBOARD.RIBBON.SELECTION_CONTEXT.COUNT_10' | transloco: { val: selectedSitesList.length }">
      <avn-ribbon-card
        *ngIf="selectedSitesList.length > 0 && selectedSitesList | applyFunction: isAutoFixable"
        icon="image:auto_fix_high"
        [title]="'siteManagementScope.SITE_MANAGEMENT.GEODETIC_FIXER.RIBBON.FIX_SELECTED.LABEL_20' | transloco"
        tooltipPlacement="bottom"
        [avnTooltip]="'siteManagementScope.SITE_MANAGEMENT.GEODETIC_FIXER.RIBBON.FIX_SELECTED.DESCRIPTION_100' | transloco"
        (actioned)="autoFixSites()"
      >
      </avn-ribbon-card>
      <avn-ribbon-card
        *ngIf="selectedSitesList.length"
        icon="file:file_download"
        [title]="'siteManagementScope.SITE_MANAGEMENT.DASHBOARD.RIBBON.ACTIONS.EXPORT_SITES_50' | transloco"
        (actioned)="exportSites()"
      >
      </avn-ribbon-card>
    </avn-ribbon-alternate-content>
  </avn-ribbon>

  <avn-main avnScroll>
    <avn-data-table
      [columns]="columns$ | async"
      [dataSource]="dataSource$ | async"
      [rowHeight]="80"
      [keyboardNavigationEnabled]="true"
      [headerTooltipsEnabled]="true"
      [infiniteScrollEnabled]="false"
      [forceLoadingState]="isLoading$ | async"
      [selectionMode]="'multiple'"
      [footerHeight]="50"
      [footerTemplate]="footerTemplate"
      (selectionChanged)="selectedSitesList = $event.selected"
    >
      <avn-empty-data-message
        [icon]="(geoProblematicSites$ | async)?.length > 0 ? 'image:grid_off' : 'social:thumb_up_alt'"
        [text]="
            (geoProblematicSites$ | async)?.length > 0 ?
            ('siteManagementScope.SITE_MANAGEMENT.GEODETIC_FIXER.NO_FILTERED_SITES_TO_FIX_FOUND.TITLE_20' | transloco) :
            'siteManagementScope.SITE_MANAGEMENT.GEODETIC_FIXER.NO_SITES_TO_FIX_FOUND.TITLE_20' | transloco"
        [subText]="
            (geoProblematicSites$ | async)?.length > 0 ?
            ('siteManagementScope.SITE_MANAGEMENT.GEODETIC_FIXER.NO_FILTERED_SITES_TO_FIX_FOUND.DESCRIPTION_100' | transloco) :
            'siteManagementScope.SITE_MANAGEMENT.GEODETIC_FIXER.NO_SITES_TO_FIX_FOUND.DESCRIPTION_100' | transloco"
      >
      </avn-empty-data-message>
    </avn-data-table>
  </avn-main>
</avn-page>

<ng-template #footerTemplate let-totalRowCount="totalRowCount">
  <div class="site-list-footer">
    {{ 'siteManagementScope.SITE_MANAGEMENT.DASHBOARD.FOOTER.SITE_COUNT' | transloco: { count: totalRowCount } }}
  </div>
</ng-template>

<ng-template #siteListWarnings let-row="row">
  <div *ngIf="row.validationStatus" class="import-status-column">
    <span
      [avnTooltip]="row.validationSubStatus ? ('siteManagementScope.SITE_MANAGEMENT.GEODETIC_FIXER.RIBBON.AMBIGUOUS_ADDRESS.WARNING_TOOLTIP_150' | transloco: { validationStatus: 'siteManagementScope.SITE_MANAGEMENT.GEODETIC_FIXER.RIBBON.' + row.validationStatus + '.WARNING_MESSAGE_100' | transloco | lowercase }) : ''"
      [tooltipTrigger]="'mouseover'"
      tooltipPlacement="bottom">
      {{ 'siteManagementScope.SITE_MANAGEMENT.GEODETIC_FIXER.RIBBON.' + (row.validationSubStatus || row.validationStatus).toUpperCase() + '.WARNING_MESSAGE_100' | transloco }}
    </span>
  </div>
</ng-template>

<ng-template #siteListWarningAction let-row="row">
  <avn-data-table-actions [dataRow]="row">
    <avn-icon
      *ngIf="row.validationStatus === 'INCOMPLETE_ADDRESS' || row.validationSubStatus"
      [fontSize]="20"
      [icon]="'social:construction'"
      [avnTheme]="'warning'"
      [avnTooltip]="'siteManagementScope.SITE_MANAGEMENT.GEODETIC_FIXER.RIBBON.' + (row.validationSubStatus || row.validationStatus) + '.ACTION_TOOLTIP_50' | transloco"
      [tooltipPlacement]="'top'"
      (click)="onEditSite(row)"
    >
    </avn-icon>

    <avn-icon
      *ngIf="!row.validationSubStatus && ['INCOMPLETE_GEODETIC_COORDINATES', 'MISSING_TIMEZONE'].includes(row.validationStatus)"
      [fontSize]="20"
      [icon]="'social:construction'"
      [avnTheme]="'warning'"
      [avnTooltip]="'siteManagementScope.SITE_MANAGEMENT.GEODETIC_FIXER.RIBBON.FIX_SELECTED.LABEL_20' | transloco"
      [tooltipPlacement]="'top'"
      (click)="autoFixSite(row)"
    >
    </avn-icon>
  </avn-data-table-actions>
</ng-template>

<amp-site-templates></amp-site-templates>
