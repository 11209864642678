import { IGridSize, ThemeType } from '@activia/ngx-components';
import { Component, ChangeDetectionStrategy, Input, OnInit, OnChanges, SimpleChanges, Output, EventEmitter } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { TranslocoService } from '@ngneat/transloco';
import { tap } from 'rxjs';
import { IBoardExperienceTemplate, IDisplayTemplate, RedundancyStrategy } from '../../../models/experience-template.interface';
import { ScreenOrientations } from '../../../models/screen-orientation.enum';
import { getColumnCount, getGeometryRowAndColumn } from '../experience-template.utils';

@Component({
  selector: 'amp-experience-template-edit-board',
  templateUrl: './experience-template-edit-board.component.html',
  styleUrls: ['./experience-template-edit-board.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ExperienceTemplateEditBoardComponent implements OnInit, OnChanges {
  @Input() boardTemplate: IBoardExperienceTemplate;

  @Output() boardChanged = new EventEmitter();

  themeType = ThemeType;

  options = [
    { value: RedundancyStrategy.NONE, label: this.translate.translate('siteManagementScope.SITE_MANAGEMENT.EXPERIENCE_TEMPLATE.STRATEGY.NONE_40') },
    { value: RedundancyStrategy.ACTIVE_PASSIVE, label: this.translate.translate('siteManagementScope.SITE_MANAGEMENT.EXPERIENCE_TEMPLATE.STRATEGY.PASSIVE_ACTIVE_60') },
    { value: RedundancyStrategy.ACTIVE_FAILOVER, label: this.translate.translate('siteManagementScope.SITE_MANAGEMENT.EXPERIENCE_TEMPLATE.STRATEGY.ACTIVE_FAILOVER_60') },
  ];

  form: UntypedFormGroup;

  constructor(private fb: UntypedFormBuilder, private translate: TranslocoService) {}

  ngOnInit() {
    this.form = this.fb.group({
      label: [this.boardTemplate?.label, [Validators.required]],
      redundancyStrategy: [this.boardTemplate?.redundancyStrategy, [Validators.required]],
      namePattern: [this.boardTemplate?.player.namePattern, [Validators.required]],
      ipAdress: [this.boardTemplate?.player.ipAdress, [Validators.required, Validators.pattern('^((25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?).){3}(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$')]],
      displays: [this.boardTemplate?.displays, [Validators.required]],
    });

    this.form.valueChanges.pipe(
      tap(() => {
        if (this.form.valid) {
          this.boardChanged.emit(this.form.value);
        }
      })
    );
  }

  ngOnChanges({ boardTemplate }: SimpleChanges): void {
    this.form?.patchValue({
      label: boardTemplate.currentValue?.label,
      redundancyStrategy: boardTemplate.currentValue?.redundancyStrategy,
      namePattern: boardTemplate.currentValue?.player.namePattern,
      ipAdress: boardTemplate.currentValue?.player.ipAdress,
      displays: boardTemplate.currentValue?.displays,
    });
  }

  getGeometry(gridSize: IGridSize) {
    const displays: IDisplayTemplate[] = [];
    for (let i = 0; i < gridSize.row; i++) {
      for (let j = 0; j < gridSize.column; j++) {
        displays.push({
          name: 'screen' + (i + j),
          orientation: ScreenOrientations.landscape,
          geometry: `+${j}+${i}`,
        });
      }
    }

    this.form.patchValue({ displays });
  }

  /** Get the number of column used by all displays */
  getColumnCount(board: IBoardExperienceTemplate) {
    return getColumnCount(board);
  }

  /** Extract from the geometry the row/column position */
  getGeometryRowAndColumn(geometry: string): { row: number; column: number } {
    return getGeometryRowAndColumn(geometry);
  }
}
