import {
  AlarmEvent, AlarmEventDoorOpened, AlarmEventFanError, AlarmEventFilterService, AlarmEventLatchBypass,
  AlarmEventLatchOpened,
  AlarmEventLevel,
  AlarmEventNamespace,
  AlarmEventThermalTrip,
  IAlarmEvent
} from '@amp/devices';

export type KeyMetricEnclosureCategory =
  'FAN_FAILURES' |
  'FILTERS_DIRTY' |
  'LATCHES_NOT_ENGAGED' |
  'DOORS_OPENED' |
  'THERMAL_TRIPS_ENGAGED';

export interface IKeyMetricEnclosureCategory {
  alarmEvents: IAlarmEvent[];
  i18n: {
    dmbMessage: string;
    dmbAction: string
  },
}

export const KeyMetricEnclosureCategories: Record<KeyMetricEnclosureCategory, IKeyMetricEnclosureCategory> = {
  FAN_FAILURES: {
    alarmEvents: [AlarmEventFanError],
    i18n: {
      dmbMessage: 'alarmEvents.ALARM_EVENTS.DMB_MESSAGES.ENCLOSURE_FAN_ISSUE_100',
      dmbAction: 'alarmEvents.ALARM_EVENTS.DMB_ACTIONS.CONTACT_SUPPORT_100',
    }
  },
  FILTERS_DIRTY: {
    alarmEvents: [AlarmEventFilterService],
    i18n: {
      dmbMessage: 'alarmEvents.ALARM_EVENTS.DMB_MESSAGES.ENCLOSURE_FILTER_SERVICE_REQUIRED_100',
      dmbAction: 'alarmEvents.ALARM_EVENTS.DMB_ACTIONS.CHANGE_FILTER_100',
    }
  },
  LATCHES_NOT_ENGAGED: {
    alarmEvents: [AlarmEventLatchOpened, AlarmEventLatchBypass],
    i18n: {
      dmbMessage: 'alarmEvents.ALARM_EVENTS.DMB_MESSAGES.ENCLOSURE_DOOR_OPENED_OR_NOT_LATCHED_100',
      dmbAction: 'alarmEvents.ALARM_EVENTS.DMB_ACTIONS.CLOSE_ENCLOSURE_DOOR_AND_INTERNAL_ENCLOSURE_DOOR_100',
    }
  },
  DOORS_OPENED: {
    alarmEvents: [AlarmEventDoorOpened],
    i18n: {
      dmbMessage: 'alarmEvents.ALARM_EVENTS.DMB_MESSAGES.ENCLOSURE_DOOR_OPENED_100',
      dmbAction: 'alarmEvents.ALARM_EVENTS.DMB_ACTIONS.CLOSE_ENCLOSURE_DOOR_100',
    }
  },
  THERMAL_TRIPS_ENGAGED: {
    alarmEvents: [AlarmEventThermalTrip],
    i18n: {
      dmbMessage: 'alarmEvents.ALARM_EVENTS.DMB_MESSAGES.ENCLOSURE_THERMAL_TRIP_ENGAGED_100',
      dmbAction: 'alarmEvents.ALARM_EVENTS.DMB_ACTIONS.SECURE_ENCLOSURE_100',
    }
  },
}

/** Get full details of all alarm events required by enclosure key metric */
export const getSiteMonitoringKeyMetricEnclosureAlarmEvents = (): IAlarmEvent[] =>
  Object.values(KeyMetricEnclosureCategories)
    .map((category) => category.alarmEvents)
    .reduce((result, curr) => {
      result.push(...curr);
      return result;
    }, []);

/** Get brief details of all alarm events required by enclosure key metric */
export const getKeyMetricEnclosureAlarmEvents = (): Array<{ namespace: AlarmEventNamespace, name: AlarmEvent }> =>
  getSiteMonitoringKeyMetricEnclosureAlarmEvents().map((alarmEvent) => ({ namespace: alarmEvent.namespace, name: alarmEvent.id }));

/**
 * Get a mapping that can retrieve category from alarm event name used in the backend
 * Key: alarm event name used in the backend, e.g. 'assets.missing'
 */
export const getKeyMetricEnclosureAlarmNameCategoryMap = (): Record<string, KeyMetricEnclosureCategory> => {
  return Object.entries(KeyMetricEnclosureCategories).reduce((result, [category, categoryDetail]) => {
    const alarms = categoryDetail.alarmEvents.map((alarmEvent) => alarmEvent.name).flat().reduce((res, name) => {
      res[name as string] = category;
      return res;
    }, {});
    return { ...result, ...alarms };
  }, {});
};

/**
 * Status of each category in enclosure key metric.
 * count: count of each category of all enclosures in a site
 * level: highest level of alarm within this category among all enclosures in a site
 * E.g.
 * {
 *   DOORS_OPENED: { count: 2, level: AlarmEventLevel.Error },
 *   FILTERS_DIRTY: { count: 4, level: AlarmEventLevel.Critical },
 *   LATCHES_NOT_ENGAGED: { count: 1, level: AlarmEventLevel.Info },
 * }
 */
export type KeyMetricEnclosureStatusCount = Record<KeyMetricEnclosureCategory, { count: number; level: AlarmEventLevel }>;

/**
 * Enclosure status of a site.
 * E.g.
 * {
 *   count: {
 *     DOORS_OPENED: { count: 2, level: AlarmEventLevel.Error },
 *     FILTERS_DIRTY: { count: 4, level: AlarmEventLevel.Critical },
 *     LATCHES_NOT_ENGAGED: { count: 1, level: AlarmEventLevel.Info },
 *   },
 *   level: AlarmEventLevel.Critical
 * }
 */
export interface IKeyMetricSiteEnclosureStatus {
  // Counts of each category among all enclosures in a site
  count: KeyMetricEnclosureStatusCount;

  // Highest level of alarm among all selected tracked alarms in a site
  level: AlarmEventLevel;

  // Number of enclosures in the site
  enclosureCount: number;
}

/**
 * Contains the configuration for the enclosure status key metric
 */
export interface ISiteMonitoringKeyMetricEnclosureStatusSettings {
  enclosureStatus: KeyMetricEnclosureCategory[];
}
