import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { AuthInterceptor } from './auth.interceptor';
import { AuthEffects } from './store/auth.effects';
import { AuthFacade } from './store/auth.facade';
import { authReducer, initialAuthState } from './store/auth.reducer';
import { HasAnyAuthorityDirective } from './has-any-authority.directive';
import { HasAnyPermissionDirective } from './has-any-permission.directive';
import { HasUnrestrictedScopeDirective } from './has-unrestricted-scope.directive';

@NgModule({
  imports: [CommonModule, StoreModule.forFeature('auth', authReducer, { initialState: initialAuthState }), EffectsModule.forFeature([AuthEffects])],
  declarations: [HasAnyAuthorityDirective, HasAnyPermissionDirective, HasUnrestrictedScopeDirective],
  exports: [HasAnyAuthorityDirective, HasAnyPermissionDirective, HasUnrestrictedScopeDirective],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      deps: [AuthFacade],
      multi: true,
    },
  ],
})
export class AuthModule {}
