import { CommonModule } from '@angular/common';
import { NgModule, Optional, Self } from '@angular/core';
import { NavigationActionTiming, routerReducer, RouterStateSerializer, StoreRouterConnectingModule } from '@ngrx/router-store';
import { StoreModule } from '@ngrx/store';

import { CustomRouterStateSerializer } from './custom-serializer';
import { Router } from '@angular/router';
import { EffectsModule } from '@ngrx/effects';
import { RouterEffects } from './router.effects';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature('router', routerReducer),
    EffectsModule.forFeature([RouterEffects]),
    StoreRouterConnectingModule.forRoot({ stateKey: 'router', navigationActionTiming: NavigationActionTiming.PostActivation }),
  ],
  providers: [
    {
      provide: RouterStateSerializer,
      useClass: CustomRouterStateSerializer,
    },
  ],
})
export class RouterStoreModule {
  constructor(@Self() @Optional() router: Router) {
    if (!router) {
      console.error('RouterStoreModule must be imported in the same same level as RouterModule');
    }
  }
}
