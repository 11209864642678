/**
 * This function is used to safely get nested properties from an nested object
 * If not found, it will return null
 *
 * @param originalObj : the original object that is been used to get property from
 * @param propArray : an array of the nested properties
 * @deprecated
 */
export const safeGetProperty = (originalObj, propArray) => propArray.reduce((first, second) => (first !== undefined && first !== null && first[second] ? first[second] : null), originalObj);

/**
 * check fully qualified domain name
 *
 * @param domain: the url to be checked
 */
export const isFQDN = (domain: string): boolean => {
  /** fully qualified domain regex */
  const FQDN_REGX = /(?=^.{4,253}$)(^((?!-)[a-zA-Z0-9-]{0,62}[a-zA-Z0-9]\.)+[a-zA-Z]{2,63}$)/g;
  return new RegExp(FQDN_REGX).test(domain);
};

/**
 * check if a value is a number
 *
 * @param value
 * @returns
 */
export const isNumber = (value: string | number): boolean => value != null && value !== '' && !isNaN(Number(value.toString()));
