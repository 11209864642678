import { Component, EventEmitter, Inject, OnDestroy, Output } from '@angular/core';
import { IModalComponent, IModalConfig, MODAL_CONFIG, ModalRef } from '@activia/ngx-components';
import { SiteDTO } from '@activia/cm-api';
import { Subject } from 'rxjs';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { IModalPickerComponent } from '../../../models/modal-picker-component.interface';
import { ILocation } from '../location.interface';

@Component({
  selector: 'amp-location-editor-modal',
  templateUrl: './location-editor-modal.component.html',
  styleUrls: ['./location-editor-modal.component.scss'],
})
export class LocationEditorModalComponent implements IModalComponent, IModalPickerComponent<Partial<SiteDTO>>, OnDestroy {
  /** Emits after the site is updated **/
  @Output() saved = new EventEmitter<Partial<SiteDTO>>();

  mapLocation: ILocation;

  /** Form used to check org path and name validation */
  form: UntypedFormGroup = this._fb.group({});

  private _currLocation: Pick<ILocation, 'address' | 'geodeticCoordinates'>;

  /** @ignore **/
  private _componentDestroyed$: Subject<void> = new Subject<void>();

  constructor(
    private _dialogRef: ModalRef<LocationEditorModalComponent>,
    @Inject(MODAL_CONFIG) public dialogConfig: IModalConfig<{ site: SiteDTO; validateFormOnInitialLoad?: boolean; }>,
    private _fb: UntypedFormBuilder,
  ) {
    this.mapLocation = {
      id: this.dialogConfig.data.site.id,
      geodeticCoordinates: {
        ...this.dialogConfig.data.site.geodeticCoordinates,
      },
      address: {
        ...this.dialogConfig.data.site.address,
      },
    };
  }

  /** @ignore **/
  ngOnDestroy(): void {
    this._componentDestroyed$.next();
    this._componentDestroyed$.complete();
  }

  canClose(): boolean {
    return this.dialogConfig.closeOnBackdropClick;
  }

  onValuesChanged(values: Pick<ILocation, 'address' | 'geodeticCoordinates'>) {
    this._currLocation = values;
  }

  onSave(): void {
    this.saved.emit({ ...this.dialogConfig.data.site, ...this._currLocation });
    this._close();
  }

  /** Called when the cancel button is clicked **/
  onCancel() {
    this._close();
  }

  private _close() {
    this._dialogRef.close();
  }
}
