/*
 * Angular 2 decorators and services
 */
import { DateLocaleService, LoaderIconRegistryService, ModalManagementService, ToastService, WINDOW } from '@activia/ngx-components';
import { AuthFacade, CMRole } from '@amp/auth';
import { MessengerNotificationService } from '@amp/messenger';
import { isFQDN } from '@amp/utils/common';
import { Component, Inject, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { TranslocoService } from '@ngneat/transloco';
import { distinctUntilChanged, merge, Subject } from 'rxjs';
import { filter, map, startWith, switchMap, take, takeUntil, withLatestFrom } from 'rxjs/operators';
import { RouterFacade } from '@amp/router-store';

/*
 * App Component
 * Top Level Component
 */
@Component({
  selector: 'cm-root',
  encapsulation: ViewEncapsulation.None,
  template: ` <router-outlet></router-outlet> `,
})
export class AppComponent implements OnInit, OnDestroy {
  /**
   * Pattern used to close all subscriptions
   *
   * @ignore
   */
  private componentDestroyed$: Subject<void> = new Subject<void>();

  constructor(
    private titleService: Title,
    @Inject(WINDOW) public window: Window,
    private translate: TranslocoService,
    private authFacade: AuthFacade,
    private messengerNotificationService: MessengerNotificationService,
    loaderIconRegistryService: LoaderIconRegistryService,
    private routerFacade: RouterFacade,
    private modalManagementService: ModalManagementService,
    private toastService: ToastService,
    dateLocaleService: DateLocaleService
  ) {
    // Init the translate settings
    loaderIconRegistryService.loadIconFromAsset('./assets/img/amplogo-78_78.png');

    // watch locale change to update date components locales
    this.translate.langChanges$.pipe(startWith(this.translate.getActiveLang()), takeUntil(this.componentDestroyed$)).subscribe((lang) => dateLocaleService.setLocale(lang));
  }

  /**
   * @ignore
   */
  public ngOnInit() {
    /**
     * This listens to state changes in the router to set the page title.
     */
    this.routerFacade.routerNavigated$
      .pipe(
        map((navigatedRoute) => navigatedRoute.payload.routerState.data['title']),
        distinctUntilChanged(),
        switchMap((routeTitle: string) => this.translate.selectTranslate(routeTitle || 'app.name')),
        takeUntil(this.componentDestroyed$)
      )
      .subscribe((title: string) => {
        // update the page title
        this.titleService.setTitle(title);
      });

    // init auth
    this.initAuth();

    if (!isFQDN(this.window.location.hostname)) {
      this.messengerNotificationService.showInfoMessage('global.error.not-fqdn');
    }
  }

  /**
   * Inits the authentication process.
   * Handle redirects of the user after a successful login (manual or from previous valid token in storage)
   */
  private initAuth() {
    // on a successful manual login, redirect the user to the relevant page based on his role
    const manualLoginCompleted$ = this.authFacade.onLoginInitiated$.pipe(switchMap(() => this.authFacade.onLoginCompleted$.pipe(take(1))));

    // on a app session ready / initial load (token from local storage still valid), also redirect the user to the relevant page if the requested page is the login page
    const appSessionReadyNeedsRedirect$ = this.authFacade.onLoginCompleted$.pipe(
      withLatestFrom(this.routerFacade.currentRoute$),
      take(1),
      filter(([, currentUrl]) => currentUrl?.url === '/login'),
      map(([authenticatedUser]) => authenticatedUser),
      takeUntil(this.authFacade.onLoginInitiated$)
    );

    merge(appSessionReadyNeedsRedirect$, manualLoginCompleted$)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe((authenticatedUser) => {
        // Redirections based on user role
        const redirects = {
          [CMRole.ROLE_HOTLINE]: 'monitoring',
          default: 'dashboard',
        };
        const redirectUrl = redirects[authenticatedUser.userRoles.find((userRole) => !!redirects[userRole.name])?.name || 'default'];
        this.routerFacade.navigate({ path: ['app', redirectUrl] });
      });

    // redirect user to login page when unsuccessful
    merge(this.authFacade.onLogoutFailed$, this.authFacade.onLogoutSuccess$)
      .pipe(
        withLatestFrom(this.routerFacade.currentRoute$),
        filter(([, currentUrl]) => currentUrl?.url !== '/login'),
        takeUntil(this.componentDestroyed$)
      )
      .subscribe(() => {
        // close any opened modal
        this.modalManagementService.closeAllOpenedModals();

        this.toastService.clearToastQueue();

        this.routerFacade.navigate({ path: ['login'] });
      });

    // Inits the application trying to login the user with an existing token
    this.authFacade.initAuth();
  }

  /**
   * @ignore
   */
  public ngOnDestroy(): void {
    // unsubscribe all subscriptions
    this.componentDestroyed$.next();
    this.componentDestroyed$.complete();
  }
}
