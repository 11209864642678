<ng-container *ngIf="isLoading$ | async as isLoading; else tagsLoaded">
  <avn-loader [isLoading]="isLoading" avnTheme="warning"></avn-loader>
</ng-container>

<ng-template #tagsLoaded>
  <ng-container *ngIf="currentTags$ | async as tags">
    <ng-container *ngIf="tags && tags.length > 0; else emptyTagMessage">
      <ng-container *ngIf="filteredTags$ | async as filteredTags">
        <h4 *ngIf="showSiteLabel">{{ assignmentScope.siteLabel }}</h4>
        <ng-content select="[impact-warning]"></ng-content>
        <div class="tag-filter">
          <avn-select
            *ngIf="showOwnerFilter$ | async"
            [label]="'tagOperation.TAG_OPERATION.TAG_VALUE_DETAIL.FILTER.APP_50' | transloco"
            [options]="appOwnerConfig$ | async | applyFunction: parseAppOwnerOptions:[]:this"
            [optionsTemplate]="optionTemplate"
            [value]="currentAppOwner$.asObservable() | async"
            (change)="currentAppOwner$.next($event.value)"
          ></avn-select>
          <ng-template #optionTemplate let-option="option">
            <div style="display: flex; flex-direction: row; width: 100%; white-space: normal; align-items: center">
              <div [ngStyle]="{ width: '4px', height: '40px', position: 'absolute', left: 0, background: appOwnerConfig$ | async | applyFunction: getOptionColor:[option.value]:this }"></div>
              <div style="margin-left: 4px">{{ option?.label }}</div>
            </div>
          </ng-template>
          <avn-input
            *ngIf="tags.length > 20"
            [label]="'tagOperation.TAG_OPERATION.TAG_VALUE_DETAIL.FILTER.SEARCH_50' | transloco"
            (changed)="currentSearchTxt$.next($event)"
            [placeholder]="'tagOperation.TAG_OPERATION.TAG_VALUE_DETAIL.FILTER.SEARCH_PLACEHOLDER_60' | transloco"
          ></avn-input>
        </div>
        <ng-container *ngIf="tags | applyFunction: filteredKeyOptions:[]:this as filteredKey$">
          <avn-button
            *ngIf="editable"
            class="assign-tag"
            [disabled]="(filteredKey$ | async)?.length === 0"
            [icon]="'action:add_circle'"
            [label]="'tagOperation.TAG_OPERATION.TAG_VALUE_DETAIL.FILTER.ASSIGN_20' | transloco"
            [avnTheme]="themeType.PRIMARY"
            (actioned)="addTag(tags, filteredKey$)"
          ></avn-button>
        </ng-container>

        <div class="tag-list" avnScroll>
          <amp-tag-value-item
            *ngFor="let tag of filteredTags; trackBy: trackByFn.bind(this); let odd = odd; let even = even"
            [ngClass]="{ 'even-tag-item': even, 'odd-tag-item': odd }"
            [tag]="tag"
            [formControl]="tagFormGroup.controls[tag.key]"
            [ownerConfig]="tag | applyFunction: getAppOwner$:[]:this | async"
            [editable]="editable"
            [isDeletable]="editable"
            (deleted)="onEditTags($event, 'delete')"
            (changed)="onEditTags($event, tag.operation ? tag.operation : 'replace')"
          ></amp-tag-value-item>
        </div>
      </ng-container>
    </ng-container>
    <div class="tag-detail-bottom" *ngIf="tags && tags.length > 0">
      <div class="warning" *ngIf="editedTags$ | async | applyFunction: displayOwnerWarning:[]:this">
        <avn-icon [fontSize]="27" [avnTheme]="themeType.WARNING" [icon]="'communication:sms_failed'"></avn-icon>
        <h5>{{ 'tagOperation.TAG_OPERATION.TAG_VALUE_DETAIL.WARNING.MSG_200' | transloco }}</h5>
      </div>
      <div *ngIf="editable && warningLabel && assignmentScope.ids?.length > 1" class="assigment-warning">
        <avn-icon [icon]="'action:info_outline'"></avn-icon>
        <span>{{ warningLabel }}</span>
      </div>
      <ng-container *ngTemplateOutlet="bottomTemplate"> </ng-container>
    </div>

    <ng-template #bottomTemplate>
      <div class="bottom">
        <div>
          <ng-container *ngFor="let app of appOwnerConfig$ | async">
            <div *ngIf="app.external" class="color-indicator" (click)="currentAppOwner$.next(app.name)">
              <div class="legend" [style.background]="app.color"></div>
              <span class="legend-title">{{ app.name }}</span>
            </div>
          </ng-container>
        </div>
        <div class="action-buttons">
          <avn-button *ngIf="showSiteLabel" [icon]="'action:subdirectory_arrow_left'" [label]="assignmentScope.level | applyFunction: getCancelLabel:[]:this | transloco" (actioned)="onGoBack()">
          </avn-button>
          <avn-button
            *ngIf="editable"
            [avnTheme]="themeType.DANGER"
            [label]="'tagOperation.TAG_OPERATION.TAG_VALUE_DETAIL.CANCEL_20' | transloco"
            [disabled]="editedTags$ | async | applyFunction: isPristine"
            (actioned)="onCancel()"
          ></avn-button>
          <avn-button
            *ngIf="editable"
            [avnTheme]="themeType.PRIMARY"
            [label]="'tagOperation.TAG_OPERATION.TAG_VALUE_DETAIL.SAVE_20' | transloco"
            [disabled]="(editedTags$ | async | applyFunction: isPristine) || !tagFormGroup.valid"
            (actioned)="onPushChanges()"
          ></avn-button>
        </div>
      </div>
    </ng-template>

    <ng-template #emptyTagMessage>
      <div class="empty-tag">
        <ng-container *ngIf="hasTagKeys$ | async; else noKeysTmp">
          <label *ngIf="editedTags$ | async | applyFunction: isPristine; else tagsRemoved">{{
            'tagOperation.TAG_OPERATION.TAG_VALUE_DETAIL.EMPTY_TAG.TITLE_50' | transloco: { entity: assignmentScope?.entityName?.toUpperCase() }
          }}</label>
          <ng-template #tagsRemoved>
            {{ 'tagOperation.TAG_OPERATION.TAG_VALUE_DETAIL.EMPTY_TAG.REMOVED_TAGS_50' | transloco: { entity: assignmentScope?.entityName?.toUpperCase() } }}
          </ng-template>

          <avn-button
            *ngIf="editable"
            [label]="'tagOperation.TAG_OPERATION.TAG_VALUE_DETAIL.EMPTY_TAG.APPLY_DEFAULT_20' | transloco"
            [avnTheme]="themeType.PRIMARY"
            [disabled]="(defaultProvidedTagKeys$ | async)?.length === 0"
            (actioned)="applyDefaults()"
          ></avn-button>
          <ng-container *ngIf="tags | applyFunction: filteredKeyOptions:[]:this as filteredKey">
            <avn-button
              *ngIf="editable"
              [label]="'tagOperation.TAG_OPERATION.TAG_VALUE_DETAIL.CREATE_TAG.ASSIGN_TAG_50' | transloco"
              (actioned)="addTag(tags, filteredKey)"
              [avnTheme]="themeType.PRIMARY"
            ></avn-button>
          </ng-container>
        </ng-container>
        <ng-template #noKeysTmp>
          <label>{{ 'tagOperation.TAG_OPERATION.TAG_VALUE_DETAIL.EMPTY_TAG.KEY_EMPTY_100' | transloco }}</label>
        </ng-template>
      </div>
      <div *ngIf="!(editedTags$ | async | applyFunction: isPristine)" class="empty-tag-footer">
        <ng-container *ngTemplateOutlet="bottomTemplate"> </ng-container>
      </div>
    </ng-template>
  </ng-container>
</ng-template>
