<avn-dialog-layout>
  <avn-dialog-header [title]="'deviceFields.DEVICE.EXPORT_CSV.SUMMARY_MODAL.TITLE_50' | transloco"></avn-dialog-header>

  <avn-dialog-content avnScroll>
    <avn-data-table
      [columns]="columns"
      [dataSource]="dataSource$ | async"
      [rowHeight]="80"
      [tableHeight]="'auto'"
      [showHeader]="true"
      [serverSorting]="false"
      [infiniteScrollEnabled]="false"
    >
      <ng-template #deviceCountTemplate let-row="row">
        <div class="device-count">
          <span>{{ (row.devices || []).length === 0 ? ('deviceFields.DEVICE.EXPORT_CSV.SUMMARY_MODAL.ALL_DEVICES_SELECTED_50' | transloco) : 'deviceFields.DEVICE.EXPORT_CSV.SUMMARY_MODAL.SUBSET_DEVICES_SELECTED_50' | transloco: { count: row.devices.length } }}</span>
        </div>
      </ng-template>

      <ng-template #statusTemplate let-row="row">
        <div class="status" *ngIf="row.status">
          <avn-icon
            [fontSize]="18"
            [icon]="ExportDevicesTaskIcons[row.status]"
            [avnTheme]="ExportDevicesTaskThemes[row.status]">
          </avn-icon>
          <span>{{ 'deviceFields.DEVICE.EXPORT_CSV.SUMMARY_MODAL.STATUS.' + row.status + '_20' | transloco }}</span>
        </div>
      </ng-template>

      <ng-template #actionsTemplate let-row="row">
        <avn-data-table-actions [dataRow]="row">
          <avn-icon
            *ngIf="row.status === 'SUCCESS'"
            [icon]="'content:download'"
            (click)="downloadCsv(row)"
          ></avn-icon>
        </avn-data-table-actions>
      </ng-template>

    </avn-data-table>
  </avn-dialog-content>
</avn-dialog-layout>
