import { getNodeById, IModalComponent, IModalConfig, ITreeNodeItem, MODAL_CONFIG, ModalRef, ApiRequestState, AsyncDataService } from '@activia/ngx-components';
import { AfterViewInit, ChangeDetectionStrategy, Component, EventEmitter, Inject, OnDestroy, Output, ViewChild } from '@angular/core';
import { SiteMonitoringFacade } from '../../../store/site-monitoring.facade';
import { combineLatest, Observable, ReplaySubject, share, Subject } from 'rxjs';
import { IColumnPickerItem } from '@amp/column-picker';
import { map, startWith, take, takeUntil, takeWhile } from 'rxjs/operators';
import { TranslocoService } from '@ngneat/transloco';
import { SiteMonitoringKeyMetricsEditorComponent } from '../site-monitoring-key-metrics-editor/site-monitoring-key-metrics-editor.component';
import { SITE_MONITORING_KEY_METRICS_CATEGORIES } from '../../../model/site-monitoring-key-metrics-categories';
import { ISiteMonitoringKeyMetricConfig } from '../../../model/site-monitoring-key-metric-config.interface';
import { getApplicationPreferencesByKey, updateApplicationPreferencesByKey } from '@amp/utils/common';
import { SITE_MONITORING_KEY_METRICS } from '../../../model/site-monitoring-key-metrics';
import { ISiteMonitoringModalPickerComponent } from '../../modal-picker-component.interface';
import { ApplicationPreferencesService } from '@activia/cm-api';
import { ISiteMonitoringModalPickerData } from '../../modal-picker-data.interface';
import { KeyMetricsSettings } from '../site-monitoring-key-metrics-editor/settings/key-metrics-settings.type';

/**
 * Container component responsible to fetch all the data required for the site list monitoring columns selection
 */
@Component({
  selector: 'amp-site-monitoring-key-metrics-editor-modal',
  templateUrl: './site-monitoring-key-metrics-editor-modal.component.html',
  styleUrls: ['./site-monitoring-key-metrics-editor-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SiteMonitoringKeyMetricsEditorModalComponent implements IModalComponent, ISiteMonitoringModalPickerComponent<ISiteMonitoringKeyMetricConfig>, AfterViewInit, OnDestroy {
  @ViewChild(SiteMonitoringKeyMetricsEditorComponent, { static: false }) siteMonitoringKeyMetricsEditorComponent: SiteMonitoringKeyMetricsEditorComponent;

  /** Emits when the columns are saved **/
  @Output() saved = new EventEmitter<ISiteMonitoringKeyMetricConfig[]>();

  /** treeview data **/
  data$: Observable<{
    allTreeviewKeyMetrics: ITreeNodeItem<IColumnPickerItem<KeyMetricsSettings>>[];
    selectedItems: IColumnPickerItem<KeyMetricsSettings>[];
  }>;

  /** Indicates if the key metrics data has been changed by the user. Used to toggle closeable state of the modal **/
  hasDataChanged = false;

  /** Indicates if the key metrics picker data is being edited by the user. Used to toggle closeable state of the modal **/
  isDataEdited = false;

  /** Indicates if the current profile is the default application profile used by the app **/
  isDefaultAppProfile: boolean;

  /** Indicates if the data for the picker is loading **/
  loading$: Observable<boolean>;

  /** Indicates if the data for the picker could not be loaded **/
  hasError$: Observable<boolean>;

  /** @ignore **/
  private _componentDestroyed$: Subject<void> = new Subject<void>();

  /** @ignore **/
  constructor(
    private _dialogRef: ModalRef<SiteMonitoringKeyMetricsEditorModalComponent>,
    @Inject(MODAL_CONFIG) public dialogConfig: IModalConfig<ISiteMonitoringModalPickerData>,
    private _siteMonitoringFacade: SiteMonitoringFacade,
    private _translateService: TranslocoService,
    private _asyncDataService: AsyncDataService,
    private _applicationPreferencesService: ApplicationPreferencesService
  ) {
    const keyMetricsRequestState = new ApiRequestState();
    const selectedKeyMetrics$ = this._asyncDataService.doRequestWithState$<ISiteMonitoringKeyMetricConfig[]>(
      getApplicationPreferencesByKey<ISiteMonitoringKeyMetricConfig[]>(this._applicationPreferencesService, `${dialogConfig.data.profile}.siteMonitoring.keyMetrics`, []),
      keyMetricsRequestState,
      { message: 'siteMonitoringSharedScope.SITE_MONITORING.KEY_METRICS_PICKER.ERROR.FETCH_FAIL_150' },
      []
    );

    this.loading$ = keyMetricsRequestState.loading$;
    this.hasError$ = keyMetricsRequestState.error$.pipe(map((error) => !!error));

    // build the treeview keyMetrics (use shareReplay to only build it once and then share the value amongs all subscribes)
    this.data$ = selectedKeyMetrics$.pipe(
      map((selectedKeyMetrics) => {
        // convert the selected key metrics into treeview format
        const treeviewKeyMetrics = this.getTreeviewSelectableKeyMetrics(selectedKeyMetrics);
        return {
          allTreeviewKeyMetrics: treeviewKeyMetrics,
          selectedItems: this._getSelectedKeyMetrics(selectedKeyMetrics, treeviewKeyMetrics),
        };
      }),
      share({
        connector: () => new ReplaySubject(1),
        resetOnRefCountZero: false,
        resetOnComplete: false,
        resetOnError: false,
      }),
    );

    this.isDefaultAppProfile = this._siteMonitoringFacade.isDefaultAppProfile(dialogConfig.data.profile);
  }

  ngAfterViewInit(): void {
    this.siteMonitoringKeyMetricsEditorComponent.treeColumnPicker.valueChanged.pipe(take(1)).subscribe(() => (this.hasDataChanged = true));
    combineLatest([
      this.siteMonitoringKeyMetricsEditorComponent.keyMetricSettingsChanged.pipe(startWith(false)),
      this.siteMonitoringKeyMetricsEditorComponent.treeColumnPicker.dataEdited.pipe(startWith(false)),
    ])
      .pipe(
        takeWhile(() => !this.hasDataChanged),
        takeUntil(this._componentDestroyed$)
      )
      .subscribe(([columnSettingsChanged, columnNameChanged]) => (this.isDataEdited = columnSettingsChanged || columnNameChanged));
  }

  canClose(): boolean {
    return !this.hasDataChanged && !this.isDataEdited;
  }

  onClose() {
    this._dialogRef.close();
  }

  /** Called when the cancel button is clicked **/
  onCancel() {
    this._dialogRef.close();
  }

  /** Called when the save button is clicked **/
  onSave() {
    // map our selected key metrics back to the monitoring format
    const keyMetrics = this.siteMonitoringKeyMetricsEditorComponent.treeColumnPicker.getSelectedColumns().map((selectedKeyMetric) => ({
        id: selectedKeyMetric.columnDefinition.id,
        displayType: selectedKeyMetric.selectedDisplayType,
        aggregationType: selectedKeyMetric.selectedAggregationType,
        customConfig: selectedKeyMetric.extra || this._getDefaultKeyMetricSettings(selectedKeyMetric.columnDefinition.id),
      } as ISiteMonitoringKeyMetricConfig));

    // if the key metrics edited are for the current profile, we also need to update the app state
    // close modal when saved successfully
    this._siteMonitoringFacade.profile$.pipe(take(1)).subscribe((activeProfile) => {
      if (activeProfile === this.dialogConfig.data.profile) {
        // close modal when saved successfully
        this._siteMonitoringFacade.onSiteMonitoringUpdateKeyMetricsSuccess$.pipe(take(1), takeUntil(this._componentDestroyed$)).subscribe(() => {
          this._dialogRef.close();
          this.saved.emit(keyMetrics);
        });
        this._siteMonitoringFacade.updateKeyMetrics(keyMetrics);
      } else {
        // save the data directly
        this._asyncDataService
          .doRequest$<any>(
            updateApplicationPreferencesByKey<ISiteMonitoringKeyMetricConfig[]>(this._applicationPreferencesService, `${this.dialogConfig.data.profile}.siteMonitoring.keyMetrics`, keyMetrics),
            { message: 'siteMonitoringSharedScope.SITE_MONITORING.KEY_METRICS_PICKER.ERROR.UPDATE_FAIL_150' },
            []
          )
          .pipe(takeUntil(this._componentDestroyed$))
          .subscribe(() => {
            this._dialogRef.close();
            this.saved.emit(keyMetrics);
          });
      }
    });
  }

  /**
   * Returns the key metrics selectable in the treeview.
   * */
  private getTreeviewSelectableKeyMetrics(selectedKeyMetrics: ISiteMonitoringKeyMetricConfig[]): ITreeNodeItem<IColumnPickerItem<KeyMetricsSettings>>[] {
    // convert our column categories into our treeview format
    // remove any category not returned by the available columns endpoint
    return SITE_MONITORING_KEY_METRICS_CATEGORIES.map((category) => {
      const categoryName = this._translateService.translate(category.label, {}, 'site-monitoring-shared-scope');
      const treeNode: ITreeNodeItem<IColumnPickerItem<KeyMetricsSettings>> = {
        id: category.id,
        label: categoryName,
        children: [
          ...category.children.map((columnDefinition) => {
            // check if the column is already selected to get the current display types
            const currentListSelectedColumn = selectedKeyMetrics.find((col) => col.id === columnDefinition.id);
            const columnName = this._translateService.translate(columnDefinition.name, {}, 'site-monitoring-shared-scope');
            return {
              id: columnDefinition.id,
              label: columnName,
              data: {
                columnDefinition: {
                  ...columnDefinition,
                  name: columnName,
                  description: `siteMonitoringSharedScope.${columnDefinition.description}`,
                },
                categoryName,
                selectedDisplayType: currentListSelectedColumn?.displayType || columnDefinition.defaultDisplayType,
                selectedAggregationType: currentListSelectedColumn?.aggregationType || columnDefinition.defaultAggregationType,
                extra: currentListSelectedColumn?.customConfig || this._getDefaultKeyMetricSettings(columnDefinition.id),
              },
            } as ITreeNodeItem<IColumnPickerItem<KeyMetricsSettings>>;
          }),
        ],
      };
      return treeNode;
    });
  }

  /** @ignore convert the selected key metrics from db into treeview format **/
  private _getSelectedKeyMetrics(
    currentSelectedKeyMetrics: ISiteMonitoringKeyMetricConfig[],
    availableKeyMetrics: ITreeNodeItem<IColumnPickerItem<KeyMetricsSettings>>[]
  ): IColumnPickerItem<KeyMetricsSettings>[] {
    // get the currently selected columns (find the corresponding tree node item)
    // also filter that dont exist anymore
    return currentSelectedKeyMetrics.map((col) => getNodeById(availableKeyMetrics, col.id)?.data).filter((col) => !!col);
  }

  /** @ignore returns the default settings for the specified key metric **/
  private _getDefaultKeyMetricSettings(id: string): unknown {
    return SITE_MONITORING_KEY_METRICS.find((km) => km.id === id).extra.defaultSettings;
  }

  /** @ignore **/
  ngOnDestroy(): void {
    this._componentDestroyed$.next();
    this._componentDestroyed$.complete();
  }
}
