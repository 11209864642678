import { BehaviorSubject, combineLatest, EMPTY, Observable, of, retryWhen, Subject, switchMap, tap, throwError, timer } from 'rxjs';
import { catchError, finalize, map, mergeMap, startWith } from 'rxjs/operators';
import { AsyncDataState, LoadingState } from '@activia/ngx-components';

/** Defines a request to registered in the request chain **/
export type RequestChainFunction<T = any> = (...args) => Observable<T>;

/** Map of all api responses for each request (requestId => response) **/
export type RequestChainResponses = Record<string, any>;

/**
 * Event emitted everytime the state of the request chain changes.
 * e.g when a request starts, completes or errors.
 */
export interface IRequestChainState {
  requests: Record<string, RequestChainFunction>;
  responses: RequestChainResponses; // api responses for each request in the chain
  lastSuccessfulRequestId: string; // id of last request that was successful
  lastFailedRequestId: string; // id of last request that errored
  retryCount: number; // number of retries when an error occurs
}

export const DEFAULT_REQUEST_CHAIN_STATE: IRequestChainState = {
  requests: {},
  responses: {},
  lastSuccessfulRequestId: null,
  lastFailedRequestId: null,
  retryCount: 3,
};

/**
 * Custom error that can be emitted in any request of the chain.
 * It allows to pass custom data to the error, because by default we can only pass a string when creating an basic Error
 */
export class CustomError<C = any, D = any> extends Error {
  code: C;
  data: D;

  constructor(message: string, code: C, data: D) {
    super(message); // (1)
    this.name = 'CustomError';
    this.code = code;
    this.data = data;
  }
}

/**
 * Event emitted everytime the state of the request chain changes.
 * e.g when a request starts, completes or errors.
 */
export interface IRequestChainStateChangeEvent<C = any, D = any> {
  requestId: string;
  loadingState: AsyncDataState;
  progress: number; // 0 to 100 based on amount of requests
  errorInfo?: { message?: string; code?: C; data?: D };
  requestChainState: IRequestChainState;
}

/**
 * Allows to chain several requests and resume on error.
 * Each request is an observable whose response will be pass to the next request in the chain.
 *
 * T = last emitted value from the request chain
 */
export class RequestChain<T = any> {
  private _stateSubject: BehaviorSubject<IRequestChainState>;

  constructor(initialState?: IRequestChainState) {
    this._stateSubject = new BehaviorSubject<IRequestChainState>(initialState || DEFAULT_REQUEST_CHAIN_STATE);
  }

  patchState(state: Partial<IRequestChainState>) {
    this._stateSubject.next({
      ...this.state,
      ...state,
    });
  }

  /**
   * Resumes the request chain at the specified step index.
   * Emits the updated state of the request chain everytime a requests completes (with success or error).
   * If an error occurs (after retrying), the request chain will complete **/
  private _resumeAtStep(stepIndex: number): Observable<IRequestChainStateChangeEvent> {
    let { responses, lastSuccessfulRequestId, lastFailedRequestId } = this.state;
    const { retryCount, requests } = this.state;
    const totalSteps = Object.keys(requests).length;
    const requestsEntries = Object.entries(requests).filter((_, i) => i >= stepIndex);
    const sequenceRequestChangeSubject = new Subject<IRequestChainStateChangeEvent>();

    const sequencedRequest$ = requestsEntries
      .reduce(
        (requestChain$, [requestId, requestFn$], i) =>
          requestChain$.pipe(
            mergeMap(() => {
              const currentStepIndex = stepIndex + i;

              // mark as loading
              sequenceRequestChangeSubject.next({
                requestId,
                loadingState: LoadingState.LOADING,
                progress: (currentStepIndex / totalSteps) * 100,
                requestChainState: this.state,
              });

              return requestFn$(this.state.responses).pipe(
                tap((response) => {
                  responses = {
                    ...responses,
                    [requestId]: response,
                  };
                  lastSuccessfulRequestId = requestId;
                  lastFailedRequestId = null;

                  this.patchState({
                    responses,
                    lastSuccessfulRequestId,
                    lastFailedRequestId,
                  });

                  // mark as loaded
                  sequenceRequestChangeSubject.next({
                    requestId,
                    loadingState: LoadingState.LOADED,
                    progress: ((currentStepIndex + 1) / totalSteps) * 100,
                    requestChainState: this.state,
                  });
                }),
                retryWhen((err$) =>
                  err$.pipe(
                    // i === index, AKA how many tries
                    mergeMap((err, index) =>
                      index >= retryCount || err.name === 'CustomError' // Dont retry custom errors
                        ? // throw error, which is caught by catchError
                          // or second argument to subscribe function if catchError not used
                          throwError(err)
                        : // wait specified duration before retrying
                          timer(1000)
                    )
                  )
                ),
                catchError((err) => {
                  // if a custom error was thrown, keep the associated data
                  lastFailedRequestId = requestId;
                  this.patchState({
                    lastFailedRequestId,
                  });

                  sequenceRequestChangeSubject.next({
                    requestId,
                    loadingState: { errorMsg: err },
                    progress: (currentStepIndex / totalSteps) * 100,
                    errorInfo: this._toErrorInfo(err),
                    requestChainState: this.state,
                  });

                  return EMPTY; // will complete the stream and therefore stop the request chain
                })
              );
            })
          ),
        of(true)
      )
      .pipe(finalize(() => sequenceRequestChangeSubject.complete()));

    // start the chain and emits updates. Completes when chain is completed successfully, or halted due to an error.
    return combineLatest([
      sequenceRequestChangeSubject.asObservable(),
      sequencedRequest$.pipe(
        switchMap(() => EMPTY),
        startWith(null)
      ),
    ]).pipe(map(([changeEvent, _]) => changeEvent));
  }

  /**
   * Resumes the request chained from the start or at the last failed step
   */
  resume$(): Observable<IRequestChainStateChangeEvent> {
    const { lastFailedRequestId, requests } = this.state;
    const lastFailedRequestIndex = !lastFailedRequestId ? -1 : Object.keys(requests).findIndex((requestId) => requestId === lastFailedRequestId);

    if (lastFailedRequestIndex === -1) {
      // start from scratch
      this.patchState({
        responses: {},
        lastSuccessfulRequestId: null,
        lastFailedRequestId: null,
      });
      return this._resumeAtStep(0);
    }

    // restart at last failed step
    return this._resumeAtStep(lastFailedRequestIndex);
  }

  /**
   * Registers a request to be executed sequentially in the chain
   */
  registerRequest({ id, request$ }: { id: string; request$: RequestChainFunction }) {
    this.patchState({
      requests: {
        ...this.state.requests,
        [id]: request$,
      },
    });
  }

  /**
   * Merges request chains together.
   * Appends all requests from specified chain to the list of requests from the current chain.
   * [a, b] mergeWith [b, c] = [a, b, c, d]
   */
  mergeWith(requestChain: RequestChain<T>) {
    this.patchState({
      requests: {
        ...this.state.requests,
        ...requestChain.state.requests,
      },
    });
  }

  get state(): IRequestChainState {
    return this._stateSubject.value;
  }

  get state$(): Observable<IRequestChainState> {
    return this._stateSubject.asObservable();
  }

  private _toErrorInfo(err: any): IRequestChainStateChangeEvent['errorInfo'] {
    if (!err) {
      return null;
    }
    if (err.name === 'CustomError') {
      const { message, code, data } = err as CustomError;
      return {
        message,
        code,
        data,
      };
    }
    return {
      message: err,
    };
  }
}

/** Gets a single api response for the specified request id (first match) **/
export const getRequestChainResponse = <T = any>(responses: RequestChainResponses, ...requestIds: string[]): T => {
  const matchingRequestId = Object.keys(responses).find((requestId) => requestIds.some((id) => requestId.startsWith(id)));
  return !matchingRequestId ? null : (responses[matchingRequestId] as T);
};

/** Gets the api responses for the specified request id, when multiple requests from the chain match the request ids provided  **/
export const getRequestChainResponseArray = <T = any>(responses: RequestChainResponses, ...requestIds: string[]): T[] => {
  const matchingRequestIds = Object.keys(responses).filter((requestId) => requestIds.some((id) => requestId.startsWith(id)));
  return matchingRequestIds.map((matchingRequestId) => responses[matchingRequestId] as T);
};
