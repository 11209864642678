/**
 * Javadoc from backend.
 * As degrees. Values generally range between [0, 360], with the 0 degree direction
 * pointing North as the screen rests in the landscape position, and with
 * increasing values as the screen turns clockwise.
 */
export enum ScreenOrientations {
  portrait = 90,
  landscape = 0,
}
