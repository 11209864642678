import { IDataTableColumnConfig, IDataTableDataSource, IModalConfig, MODAL_CONFIG } from '@activia/ngx-components';
import { ChangeDetectionStrategy, Component, Inject, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { ALL_COLUMNS, COLUMNS_WITH_REQUIRED_VALUE, COLUMNS_WITH_UNIQUE_VALUE } from '../../models/site-management-csv-columns';

interface IFieldDescription {
  header?: string;
  type?: string;
  required?: boolean;
  unique?: boolean;
  description?: string;
}

/**
 * This component is responsible to show a list of all possible fields to include in the site sync csv file
 */
@Component({
  selector: 'amp-site-sync-csv-field-list',
  templateUrl: './site-sync-csv-field-list.component.html',
  styleUrls: ['./site-sync-csv-field-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SiteSyncCsvFieldListComponent implements OnInit {
  @ViewChild('requiredField', { static: true }) requiredFieldTemplate: TemplateRef<void>;
  @ViewChild('uniqueField', { static: true }) uniqueFieldTemplate: TemplateRef<void>;

  /** The recent devices data table columns configuration */
  columns: IDataTableColumnConfig<void>[] = [];

  /** list of the sites to display */
  dataSource: IDataTableDataSource<IFieldDescription>;

  constructor(@Inject(MODAL_CONFIG) public dialogConfig: IModalConfig<{ isSelectedSites: boolean }>, private _transloco: TranslocoService) {}

  ngOnInit(): void {
    this._setColumns();
    this._initDataTable();
  }

  canClose(): boolean {
    return true;
  }

  /** Init the dataTable */
  private _initDataTable() {
    const numberTypeList = ['id', 'longitude', 'latitude'];
    const rows = ALL_COLUMNS.map((columns) => ({
      header: this._transloco.translate(`siteManagementScope.SITE_MANAGEMENT.FIELD_DESCRIPTION.TABS.FIELD.${columns}.TITLE_20`),
      type: numberTypeList.includes(columns) ? 'number' : 'alphanumeric',
      required: !!COLUMNS_WITH_REQUIRED_VALUE.find((requiredColumn) => requiredColumn === columns),
      unique: !!COLUMNS_WITH_UNIQUE_VALUE.find((uniqueColumn) => uniqueColumn === columns),
      description: this._transloco.translate(`siteManagementScope.SITE_MANAGEMENT.FIELD_DESCRIPTION.TABS.FIELD.${columns}.DESCRIPTION_200`),
    }));
    this.dataSource = {
      rows,
      totalRowCount: ALL_COLUMNS.length,
    };
  }

  /** define the column name */
  private _setColumns() {
    this.columns = [
      {
        id: 'PROPERTY_HEADER',
        name: this._transloco.translate('siteManagementScope.SITE_MANAGEMENT.FIELD_DESCRIPTION.TABS.FIELD.PROPERTY_HEADER_20'),
        prop: 'header',
        sortable: false,
        resizable: true,
        draggable: false,
        widthPx: 150,
      },
      {
        id: 'TYPE',
        name: this._transloco.translate('siteManagementScope.SITE_MANAGEMENT.FIELD_DESCRIPTION.TABS.FIELD.TYPE_20'),
        prop: 'type',
        sortable: false,
        resizable: true,
        draggable: false,
        widthPx: 100,
      },
      {
        id: 'REQUIRED',
        name: this._transloco.translate('siteManagementScope.SITE_MANAGEMENT.FIELD_DESCRIPTION.TABS.FIELD.REQUIRED_20'),
        dataCellTemplate: this.requiredFieldTemplate,
        sortable: false,
        resizable: true,
        draggable: false,
        widthPx: 120,
      },
      {
        id: 'UNIQUE',
        name: this._transloco.translate('siteManagementScope.SITE_MANAGEMENT.FIELD_DESCRIPTION.TABS.FIELD.UNIQUE_20'),
        dataCellTemplate: this.uniqueFieldTemplate,
        sortable: false,
        resizable: true,
        draggable: false,
        widthPx: 80,
      },
      {
        id: 'DESCRIPTION',
        name: this._transloco.translate('siteManagementScope.SITE_MANAGEMENT.FIELD_DESCRIPTION.TABS.FIELD.DESCRIPTION_20'),
        prop: 'description',
        sortable: false,
        resizable: true,
        draggable: false,
      },
    ];
  }
}
