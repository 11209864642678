/**
 * List of permissions: https://confluence.stratacache.com/pages/viewpage.action?spaceKey=CM&title=Roles+and+Authorization
 */
export enum PERMISSIONS {
  /** Adds a Device's Category */
  ADD_CATEGORY = 'ADD_CATEGORY',

  /** Adds a Device in a CDN */
  ADD_DEVICE = 'ADD_DEVICE',

  /** Covers Administritation related operations */
  ADMIN_OP = 'ADMIN_OP',

  /** Applies a configuration file to a specific Device */
  APPLY_CONF_ON_DEVICE = 'APPLY_CONF_ON_DEVICE',

  /** Applies a configuration file to a group of Devices */
  APPLY_CONF_ON_DEVICES = 'APPLY_CONF_ON_DEVICES',

  /** Backups the current configuration of a specific Device */
  BACKUP_DEVICE_CONF = 'BACKUP_DEVICE_CONF',

  /** Backups the current configuration of Devices belonging to specific group */
  BACKUP_DEVICES_CONF = 'BACKUP_DEVICES_CONF',

  /** Creates a User's account */
  CREATE_ACCOUNT = 'CREATE_ACCOUNT',

  /** Creates a new CDN */
  CREATE_CDN = 'CREATE_CDN',

  /** Creates a content set */
  CREATE_CONTENT_SET = 'CREATE_CONTENT_SET',

  /** Creates a DeviceGroup */
  CREATE_DEVICE_GROUP = 'CREATE_DEVICE_GROUP',

  /** Creates a Monitoring View */
  CREATE_MONITORING_VIEW = 'CREATE_MONITORING_VIEW',

  /** Generate logs reports */
  GENERATE_LOG_REPORTS = 'GENERATE_LOG_REPORTS',

  /** Return the list of active publications */
  GET_ACTIVE_PUBLICATIONS = 'GET_ACTIVE_PUBLICATIONS',

  /** Returns the properties of an Alarm */
  GET_AN_ALARM = 'GET_AN_ALARM',

  /** Returns the list of all users created Device's Categories */
  GET_CATEGORIES = 'GET_CATEGORIES',

  /** Returns the list of CDNs */
  GET_CDN_LIST = 'GET_CDN_LIST',

  /** Returns the properties of command */
  GET_COMMAND = 'GET_COMMAND',

  /** Returns the status of a backup/configuration String */
  GET_CONF_ORDER_STATUS = 'GET_CONF_ORDER_STATUS',

  /** Returns the list of publications related to a specific content manager */
  GET_CONTENT_MANAGER_PUBLICATIONS = 'GET_CONTENT_MANAGER_PUBLICATIONS',

  /** Returns the properties of a content set */
  GET_CONTENT_SET = 'GET_CONTENT_SET',

  /** Returns the list of publications related to a specific content set */
  GET_CONTENT_SET_PUBLICATIONS = 'GET_CONTENT_SET_PUBLICATIONS',

  /** Returns the list of content set of the CDN */
  GET_CONTENT_SETS = 'GET_CONTENT_SETS',

  /** Returns the Device's properties */
  GET_DEVICE = 'GET_DEVICE',

  /** Returns the list of Devices of the CDN */
  GET_DEVICES = 'GET_DEVICES',

  /** Returns a specific DeviceGroup */
  GET_DEVICE_GROUP = 'GET_DEVICE_GROUP',

  /** Returns the list of all DeviceGroups in the CDN */
  GET_DEVICE_GROUPS = 'GET_DEVICE_GROUPS',

  /** Returns the list of Device's Operations */
  GET_DEVICE_OPERATIONS = 'GET_DEVICE_OPERATIONS',

  /** Get status of log reports generation */
  GET_GENERATE_LOG_REPORTS_STATUS = 'GET_GENERATE_LOG_REPORTS_STATUS',

  /** Returns some log reports */
  GET_LOG_REPORTS = 'GET_LOG_REPORTS',

  /** Returns the monitored values of the current monitoring session */
  GET_MONITORED_VALUES = 'GET_MONITORED_VALUES',

  /** Returns a MonitoringView */
  GET_MONITORING_VIEW = 'GET_MONITORING_VIEW',

  /** Returns the User object */
  GET_MY_ACCOUNT = 'GET_MY_ACCOUNT',

  /** Returns the list of Permissions associated to the User */
  GET_MY_PERMISSIONS = 'GET_MY_PERMISSIONS',

  /** Returns the list of Roles associated to the User */
  GET_MY_ROLES = 'GET_MY_ROLES',

  /** Returns the list of Operations */
  GET_OPERATIONS = 'GET_OPERATIONS',

  /** Returns the list of Permissions associated to a Role */
  GET_PERMISSIONS = 'GET_PERMISSIONS',

  /** Returns the properties of a specified publication */
  GET_PUBLICATION = 'GET_PUBLICATION',

  /** Returns the list of all publications */
  GET_PUBLICATIONS = 'GET_PUBLICATIONS',

  /** Returns the status of a publication */
  GET_PUBLICATION_STATUS_REPORT = 'GET_PUBLICATION_STATUS_REPORT',

  /** Returns the user's Role(s) */
  GET_ROLE = 'GET_ROLE',

  /** Returns the list of Roles */
  GET_ROLES = 'GET_ROLES',

  /** Get status of logs upload command */
  GET_UPLOAD_LOGS_STATUS = 'GET_UPLOAD_LOGS_STATUS',

  /** Returns a User's account */
  GET_USER = 'GET_USER',

  /** Returns the list of Users */
  GET_USERS = 'GET_USERS',

  /** Returns the list of Users with the specified Role */
  GET_USERS_WITH_ROLE = 'GET_USERS_WITH_ROLE',

  PURGE_CONTENT = 'PURGE_CONTENT',

  READ_COMMAND_STATUS_ID = 'READ_COMMAND_STATUS_ID',

  /** Removes an Alarm */
  REMOVE_ALARM = 'REMOVE_ALARM',

  /** Removes a content set */
  REMOVE_CONTENT_SET = 'REMOVE_CONTENT_SET',

  /** Removes a Device */
  REMOVE_DEVICE = 'REMOVE_DEVICE',

  /** Removes a DeviceGroup */
  REMOVE_DEVICE_GROUP = 'REMOVE_DEVICE_GROUP',

  /**  Removes a User's account */
  REMOVE_USER = 'REMOVE_USER',

  /** Search Devices in the CDN */
  SEARCH_DEVICES = 'SEARCH_DEVICES',

  /** Sends by email the password of the User */
  SEND_PASSWORD = 'SEND_PASSWORD',

  /** Covers Service related operations */
  SERVICE_ACTION = 'SERVICE_ACTION',

  /** Sets an alarm on a DeviceGroup */
  SET_ALARM_ON_DEVICE_GROUP = 'SET_ALARM_ON_DEVICE_GROUP',

  /** Sets an alarm on a set of Devices */
  SET_ALARM_ON_DEVICES = 'SET_ALARM_ON_DEVICES',

  /** Sets up the delivery properties for a content set */
  SETUP_DELIVERY = 'SETUP_DELIVERY',

  /** Sets up a publication */
  SETUP_PUBLICATION = 'SETUP_PUBLICATION',

  /** Sets up publication resources */
  SETUP_PUBLICATION_RESOURCES = 'SETUP_PUBLICATION_RESOURCES',

  /** Starts to monitor a DeviceGroup */
  START_MONITORING_DEVICE_GROUP = 'START_MONITORING_DEVICE_GROUP',

  /** Starts to monitor a set of Devices */
  START_MONITORING_DEVICES = 'START_MONITORING_DEVICES',

  /** Stops the monitoring session */
  STOP_MONITORING = 'STOP_MONITORING',

  /** Covers System related operations */
  SYSTEM_ACTION = 'SYSTEM_ACTION',

  /** Updates the Device's properties */
  UPDATE_DEVICE = 'UPDATE_DEVICE',

  /** Updates a User's account */
  UPDATE_ACCOUNT = 'UPDATE_ACCOUNT',

  /** Updates a content set */
  UPDATE_CONTENT_SET = 'UPDATE_CONTENT_SET',

  /** Updates the DeviceGroup's properties */
  UPDATE_DEVICE_GROUP = 'UPDATE_DEVICE_GROUP',

  /** Updates the account of the User */
  UPDATE_MY_ACCOUNT = 'UPDATE_MY_ACCOUNT',

  /** Upload logs to ContentReporter */
  UPLOAD_LOGS = 'UPLOAD_LOGS',

  USE_EDJE_SCHEDULER = 'USE_EDJE_SCHEDULER',
  USE_MK2000_SCHEDULER = 'USE_MK2000_SCHEDULER',
  USE_POS_SCHEDULER = 'USE_POS_SCHEDULER',
  USE_STORE_PROXY_SCHEDULER = 'USE_STORE_PROXY_SCHEDULER',
  USE_TRIDISP_SCHEDULER = 'USE_TRIDISP_SCHEDULER',
  USE_ZIRIS_SCHEDULER = 'USE_ZIRIS_SCHEDULER',
}
