import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CountPipe } from './pipes/count.pipe';
import { DurationPipe } from './pipes/duration.pipe';
import { I18nFormattedValuePipe } from './pipes/i18n-formatted-value.pipe';
import { RemoveWhitespacesCapitalizePipe } from './pipes/remove-whitespaces-capitalize.pipe';
import { CustomFunctionPipe } from './pipes/custom-function.pipe';
import { MinValidatorDirective } from './validators/min-validator.directive';

@NgModule({
  imports: [CommonModule],
  exports: [CountPipe, DurationPipe, I18nFormattedValuePipe, RemoveWhitespacesCapitalizePipe, CustomFunctionPipe, MinValidatorDirective],
  declarations: [CountPipe, DurationPipe, I18nFormattedValuePipe, RemoveWhitespacesCapitalizePipe, CustomFunctionPipe, MinValidatorDirective],
})
export class UIModule {}
