<span class="message">
  {{ experienceTemplate.boards.length | i18nPlural: i18nMessage | transloco: { boardCount: experienceTemplate.boards.length, templateName: experienceTemplate.label, siteName: siteName } }}
</span>

<div class="experience-template-options-wrapper" (click)="selectOption('provision')">
  <div class="experience-template-player-option">
    <h5>{{ 'siteManagementScope.SITE_MANAGEMENT.EXPERIENCE_TEMPLATE.SELECTION_MODAL.PROVISION_NEW_PLAYER.TITLE_60' | transloco }}</h5>
    <span> {{ 'siteManagementScope.SITE_MANAGEMENT.EXPERIENCE_TEMPLATE.SELECTION_MODAL.PROVISION_NEW_PLAYER.TEXT_100' | transloco }}</span>
    <avn-button
      class="experience-template-player-option-button experience-template-button"
      [label]="'siteManagementScope.SITE_MANAGEMENT.EXPERIENCE_TEMPLATE.SELECTION_MODAL.PROVISION_NEW_PLAYER.BUTTON_40' | transloco"
      avnTheme="primary"
    ></avn-button>
  </div>

  <div class="experience-template-player-option" (click)="selectOption('connect')">
    <h5>{{ 'siteManagementScope.SITE_MANAGEMENT.EXPERIENCE_TEMPLATE.SELECTION_MODAL.CONNECT_EXISTING_PLAYERS.TITLE_60' | transloco }}</h5>
    <span> {{ 'siteManagementScope.SITE_MANAGEMENT.EXPERIENCE_TEMPLATE.SELECTION_MODAL.CONNECT_EXISTING_PLAYERS.TEXT_100' | transloco }}</span>
    <avn-button
      class="experience-template-player-option-button experience-template-button"
      [label]="'siteManagementScope.SITE_MANAGEMENT.EXPERIENCE_TEMPLATE.SELECTION_MODAL.CONNECT_EXISTING_PLAYERS.BUTTON_40' | transloco"
      avnTheme="primary"
    ></avn-button>
  </div>
</div>

<!-- Buttons -->
<div class="experience-template-modal-footer">
  <avn-button class="btn-close experience-template-button" (actioned)="selectOption('close')" [label]="'siteManagementScope.SITE_MANAGEMENT.BOARD_EDITOR.GLOBAL.CANCEL_10' | transloco"></avn-button>
</div>
