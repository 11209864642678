import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslocoService } from '@ngneat/transloco';
import { take } from 'rxjs/operators';

/**
 * This component is displayed when an routing error (404) or a non authorized routing (403) occurs
 * in the application. Displays an error page with an associated error message.
 */
@Component({
  selector: 'amp-error',
  styleUrls: ['./error.component.scss'],
  templateUrl: './error.component.html',
})
export class ErrorComponent {
  /** The http status code of the error (e.g. 403, 404...) **/
  httpStatusCode: number;

  /** The back href if passed via the extra data **/
  backHref: any[];

  /** The text for the back href button **/
  backHrefText: string;

  /** The error text to display stating the error **/
  errorText: string;

  /** The description text giving some context about the error **/
  errorDescriptionText: string;

  /** @ignore **/
  constructor(private router: Router, activatedRoute: ActivatedRoute, private translate: TranslocoService) {
    this.httpStatusCode = activatedRoute.snapshot.data.httpStatusCode;

    // fill the properties
    const state = this.router.getCurrentNavigation().extras.state || {};
    this.backHref = state.backHref || ['/app', 'dashboard'];
    this.errorDescriptionText = state.errorDescriptionText;
    this.translate
      .selectTranslate('error.navigate-to-home')
      .pipe(take(1))
      .subscribe((_) => {
        this.backHrefText = state.backHrefText || this.translate.translate('error.navigate-to-home');
        this.errorText = state.errorText || this.translate.translate(`error.${this.httpStatusCode}.description`);
      });
  }

  /** Navigates to the home page or to a custom page (when specified) **/
  navigateBack(): void {
    this.router.navigate(this.backHref);
  }
}
