import { Router, RouterStateSnapshot } from '@angular/router';
import { IRouterStateUrl } from './router.interfaces';
import { RouterStateSerializer } from '@ngrx/router-store';
import { Injectable } from '@angular/core';
import { RouterService } from '../router.service';

/**
 * Custom serializer to specify how/what data to save in the router state
 */
@Injectable()
export class CustomRouterStateSerializer implements RouterStateSerializer<IRouterStateUrl> {
  constructor(private _router: Router, private _routerService: RouterService) {}

  serialize(routerState: RouterStateSnapshot): IRouterStateUrl {
    return {
      url: routerState.url,
      params: this._routerService.getRouteParams(routerState.root, (r) => r.params),
      queryParams: this._routerService.getRouteParams(routerState.root, (r) => r.queryParams),
      data: this._routerService.getRouteData(routerState.root),
      extras: this._router.getCurrentNavigation()?.extras || {},
    };
  }
}
