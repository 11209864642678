import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromBoard from './board-org-path-definition.reducer';

export const selectBoardState = createFeatureSelector<fromBoard.BoardOrgPathDefState>(fromBoard.boardOrgPathDefinitionsFeatureKey);

export const selectBoardOrgPathDefinition = createSelector(selectBoardState, (state) => state.orgPathDefinition.data);
export const selectBoardOrgPathDefinitionState = createSelector(selectBoardState, (state) => state.orgPathDefinition.state);

export const selectBoardTagKeysSchema = createSelector(selectBoardState, (state) => state.tagKeys);

export const selectBoardOrgPathSaveState = createSelector(selectBoardState, (state) => state.orgPathSaveState);
