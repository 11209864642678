import { BoardDTO, DisplayDTO } from '@activia/cm-api';
import { IBoardDisplayRow, IDeviceToDisplayConnection } from '../components/site-monitoring-detail/store/site-monitoring-detail.model';

/**
 * Returns the ordered list of displays that can be found on the specified board
 * To determine the board order we rely on the display 'geometry' field
 * for a grid type = PIXELS, geometry format is 640x480+1+0 (+1 = colIndex, + 0 = rowIndex)
 * for grid type = SCREENS, geometry format is +1+0 (+1 = colIndex, + 0 = rowIndex)
 * **/
export const getOrderedBoardDisplays = (board: BoardDTO): IBoardDisplayRow[] => {
  if (board.displays.length === 0) {
    return [
      {
        rowIndex: 0,
        displays: board.displays,
      },
    ];
  }

  // group our displays per row
  const displaysPerRow = board.displays.reduce((rows, display, index) => {
    let geometry = display.geometry?.geometry || '';
    geometry = geometry.substring(geometry.indexOf('+') || 0);
    const split = geometry.split('+').filter((item) => !!item); // remove the blank space
    const rowIndex = +(split[1] ?? 0);
    const colIndex = +(split[0] ?? index);

    let currentRow = rows.find((row) => row.rowIndex === rowIndex);
    if (!currentRow) {
      currentRow = {
        rowIndex,
        displays: [],
      };
      rows.push(currentRow);
    }
    currentRow.displays.push({ colIndex, display });
    return rows;
  }, []);

  // sort the rows using rowIndex AND sort the displays using the col index
  return displaysPerRow
    .sort((row1, row2) => (row1.rowIndex < row2.rowIndex ? -1 : 1))
    .map((row) => ({
      ...row,
      displays: row.displays.sort((display1, display2) => (display1.colIndex < display2.colIndex ? -1 : 1)).map((display) => display.display),
    }));
};

/**
 * Returns the list of device ids used on the specified board
 * **/
export const getBoardDeviceIds = (board: BoardDTO): number[] => (board?.displays || [])
    .reduce((deviceIds, display) => {
      deviceIds = [...deviceIds, ...display.inputs.map((input) => input?.deviceId ?? null)].filter((deviceId) => deviceId !== null);
      return deviceIds;
    }, [])
    .filter((device, index, arr) => index === arr.indexOf(device));

/**
 * Returns the list of device ids used on the specified boards
 * **/
export const getBoardsDeviceIds = (boards: BoardDTO[]): number[] => (boards || []).reduce((deviceIds, board) => [...deviceIds, ...getBoardDeviceIds(board)], []).filter((device, index, arr) => index === arr.indexOf(device));

/**
 * Returns the ids of all the devices connected to the specified display
 * **/
export const getDisplayDeviceIds = (display: DisplayDTO): number[] =>
  (display?.inputs || []).reduce((deviceIds, input) => [...deviceIds, input.deviceId ?? null], []).filter((deviceId, index, arr) => deviceId !== null && index === arr.indexOf(deviceId));

/**
 * Returns the device to display connections for a device on the specified boards
 * **/
export const getBoardsDeviceConnectivity = (boards: BoardDTO[]): IDeviceToDisplayConnection[] => (boards || []).reduce(
    (displays, board) => [
      ...displays,
      ...board.displays.reduce((connections, display) => [
          ...connections,
          ...display.inputs.map(
            (input, index) =>
              ({
                boardId: board.id,
                deviceId: input.deviceId,
                devicePlayerId: input.playerId,
                deviceOutput: input.output,
                displayId: display.id,
                displayInput: index,
                displayIndex: display.boardScreenIdx,
              } as IDeviceToDisplayConnection)
          ),
        ], [] as IDeviceToDisplayConnection[]),
    ],
    [] as IDeviceToDisplayConnection[]
  );

/**
 * Sorts the devices ids based on their connections to the displays
 * => first show all devices feeding the display's primary inputs (in order of displays)
 * => then show all devices feeding the display's backup inputs (in order of displays)
 *
 * @param displays the sorted displays
 * **/
export const sortDeviceIdsByDisplays = (displays: DisplayDTO[]): number[] => {
  const displayPrimaryDeviceIds = displays.reduce((res, curr) => {
    const deviceId = (curr.inputs || [])[0]?.deviceId;
    return [...res, ...(deviceId && !res.includes(deviceId) ? [deviceId] : [])];
  }, []);
  const displayBackupDeviceIds = displays.reduce((res, curr) => {
    const deviceId = (curr.inputs || [])[1]?.deviceId;
    return [...res, ...(deviceId && !res.includes(deviceId) && !displayPrimaryDeviceIds.includes(deviceId) ? [deviceId] : [])];
  }, []);
  return [...displayPrimaryDeviceIds, ...displayBackupDeviceIds];
};

/**
 * Some boards have null as display. :(
 * Will provide such display some default values so that the existing code
 * still work with minimum changes to handle such case (otherwise will have
 * to add null check everywhere).
 */
export const handleNullDisplays = (boards: BoardDTO[]): BoardDTO[] => boards.map((board) => ({
  ...board,
  displays: (board?.displays || []).map((display, idx) => display ?? { boardScreenIdx: idx, geometry: { orientation: 0 }, inputs: [], parentBoardId: board.id }),
}));
