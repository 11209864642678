import { createFeatureSelector, createSelector, select } from '@ngrx/store';
import { IGeoFixerState } from './geo-fixer.reducer';
import { LoadingState } from '@activia/ngx-components';

const geoFixerSelector = createFeatureSelector<IGeoFixerState>('geoFixer');

const allGeoProblematicSites = createSelector(geoFixerSelector, (state) => state.allGeoProblematicSites.data);
const geoProblematicSitesCount = createSelector(geoFixerSelector, (state) => state.allGeoProblematicSites.data.length);
const allGeoProblematicSitesDataState = createSelector(geoFixerSelector, (state) => state.allGeoProblematicSites.state);
const isLoadingAllGeoProblematicSites = createSelector(geoFixerSelector, (state) => state.allGeoProblematicSites.state === LoadingState.LOADING);

const autoFixSites = createSelector(geoFixerSelector, (state) => state.autoFixSites);
const sitesToAutoFix = createSelector(geoFixerSelector, (state) => state.autoFixSites);
const sitesFailedToAutoFix = createSelector(geoFixerSelector, (state) => state.autoFixSites.filter((site) => site.autoFixStatus === 'FAILED'));

export const geoFixerSelectors = {
  allGeoProblematicSites,
  geoProblematicSitesCount,
  allGeoProblematicSitesDataState,
  isLoadingAllGeoProblematicSites,
  autoFixSites,
  sitesToAutoFix,
  sitesFailedToAutoFix,
};

const allGeoProblematicSites$ = select(geoFixerSelectors.allGeoProblematicSites);
const geoProblematicSitesCount$ = select(geoFixerSelectors.geoProblematicSitesCount);
const allGeoProblematicSitesDataState$ = select(geoFixerSelectors.allGeoProblematicSitesDataState);
const isLoadingAllGeoProblematicSites$ = select(geoFixerSelectors.isLoadingAllGeoProblematicSites);
const autoFixSites$ = select(geoFixerSelectors.autoFixSites);
const sitesToAutoFix$ = select(geoFixerSelectors.sitesToAutoFix);
const sitesFailedToAutoFix$ = select(geoFixerSelectors.sitesFailedToAutoFix);

export const geoFixerEntities = {
  allGeoProblematicSites$,
  geoProblematicSitesCount$,
  allGeoProblematicSitesDataState$,
  isLoadingAllGeoProblematicSites$,
  autoFixSites$,
  sitesToAutoFix$,
  sitesFailedToAutoFix$,
};
