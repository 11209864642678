import { AfterViewInit, ChangeDetectionStrategy, Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { asapScheduler, forkJoin, Observable, of, ReplaySubject, share } from 'rxjs';
import { AutocompleteComponent, IExpressionSuggestionsResult, IOptionData, NlpOverlayComponent, NlpOverlayService } from '@activia/ngx-components';
import { ManagerDTO, ManagerService } from '@activia/cm-api';
import { catchError, debounceTime, filter, map, startWith, switchMap } from 'rxjs/operators';

@Component({
  selector: 'amp-nlp-manager-picker',
  templateUrl: './nlp-manager-picker.component.html',
  styleUrls: ['./nlp-manager-picker.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NlpManagerPickerComponent extends NlpOverlayComponent<number> implements OnInit, AfterViewInit {
  @ViewChild(AutocompleteComponent, { static: true }) searchInput: AutocompleteComponent<ManagerDTO>;

  form: UntypedFormGroup;

  managersDatasource: any;

  selectedManager$: Observable<IOptionData<ManagerDTO>>;

  constructor(private _nlpOverlayService: NlpOverlayService, private _formBuilder: UntypedFormBuilder, private _managerService: ManagerService) {
    super(_nlpOverlayService);

    // Add callback function for manager search autocomplete
    this.managersDatasource = (searchText: string): Observable<IOptionData<ManagerDTO>[]> =>
      // TODO: until CM-5891 is done, we can only search by one field at time. Search is case-insensitive but does not support substring search.
      // e.g. If Manager's first name is "Tony", we receive the correct data returned from this endpoint only if we search either
      // "Tony" or "tony" in first name field.
       forkJoin([
        this._managerService.findAllManagers(undefined, searchText).pipe(catchError((_) => of([]))),
        this._managerService.findAllManagers(undefined, undefined, searchText).pipe(catchError((_) => of([]))),
        this._managerService.findAllManagers(undefined, undefined, undefined, searchText).pipe(catchError((_) => of([]))),
        this._managerService.findAllManagers(undefined, undefined, undefined, undefined, searchText).pipe(catchError((_) => of([]))),
      ]).pipe(
        map(([matchesByUsername, matchesByFirstName, matchesByLastName, matchesByEmail]) => {
          const matches: Array<ManagerDTO> = matchesByUsername.concat(matchesByFirstName).concat(matchesByLastName).concat(matchesByEmail);
          return matches.map((manager) => ({
              label: `${manager.firstName} ${manager.lastName}`,
              value: manager.id,
              data: manager,
            }));
        })
      )
    ;
    this.managersDatasource.bind(this);
  }

  /** @ignore **/
  ngOnInit() {
    this.form = this._formBuilder.group({
      manager: new UntypedFormControl(null),
    });

    this.selectedManager$ = this.activated$.pipe(
      debounceTime(300),
      filter((_) => !!this.value),
      switchMap((_) => this._managerService.findManagerById(this.value).pipe(
          map((manager) => ({ label: `${manager.firstName} ${manager.lastName}`, value: manager.id, data: manager })),
          catchError(() => of({}))
        )),
      startWith(null),
      share({
        connector: () => new ReplaySubject(1),
        resetOnRefCountZero: true,
        resetOnComplete: false,
        resetOnError: false,
      }),
    );
  }

  /** @ignore */
  ngAfterViewInit(): void {
    // hack: input does not seem ready right away, schedule it on the micro task
    asapScheduler.schedule(() => this.searchInput.autocompleteInput.nativeElement.focus());
  }

  /** Called when a manager is selected  **/
  onManagerSelected($event) {
    if ($event) {
      this._nlpOverlayService.setValueSelected(`${$event?.value}`, -1, true);
    }
  }

  /** @ignore parses the value from the expression parsing results **/
  protected parseValue(res: IExpressionSuggestionsResult): number {
    if (!res.currentFullFieldExpression.values || res.currentFullFieldExpression.values.length === 0) {
      return null;
    }
    return Number(res.currentFullFieldExpression.values[0].image);
  }

  /**
   * Called when the option list of any of the select components in our forms is toggled
   * This will prevent the overlay to close
   **/
  onOptionListToggled(opened: boolean) {
    this._nlpOverlayService.setAllowCloseOnOutsideClick(!opened);
  }
}
