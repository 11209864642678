<div class="container">
  <avn-accordion>
    <avn-accordion-panel [headerTemplate]="customHeader">
      <div>{{ 'tagOperation.TAG_OPERATION.INVALID_ENTITY.INVALID_ENUM_TAG_OPERATION.MESSAGE_250' | transloco }}</div>
      <div class="tags" *ngFor="let tag of tags">
        <avn-icon icon="action:dangerous" [fontSize]="18" [avnTheme]="'danger'"></avn-icon>
        <div>{{ tag }}</div>
      </div>
    </avn-accordion-panel>
  </avn-accordion>
</div>

<ng-template #customHeader>
  <div class="custom-header">
    <avn-icon icon="action:dangerous" [avnTheme]="'danger'"></avn-icon>
    <h5 class="title">{{ 'tagOperation.TAG_OPERATION.INVALID_ENTITY.INVALID_ENUM_TAG_OPERATION.TITLE_50' | transloco }}</h5>
  </div>
</ng-template>
