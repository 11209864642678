import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'amp-invalid-enum-tag-operation-message',
  templateUrl: './invalid-enum-tag-operation-message.component.html',
  styleUrls: ['./invalid-enum-tag-operation-message.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InvalidEnumTagOperationMessageComponent {
  /** Tag operation impacts */
  @Input() tags: string[];
}
