export enum EnclosureType {
  SINGLE = 'single',
  DOUBLE = 'double',
  TRIPLE = 'triple',
}
export enum EnclosureMode {
  COOL = 'cool',
  NONE = 'none',
  HEAT = 'heat',
}
