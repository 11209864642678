import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { TagValueAssignmentScope } from '@amp/tag-operation';
import { map, Observable } from 'rxjs';
import { take, tap } from 'rxjs/operators';
import { ISiteManagementConfig, SITE_MANAGEMENT_MODULE_CONFIG } from '@amp/environment';
import { RouterFacade } from '@amp/router-store';
import { Store } from '@ngrx/store';
import { ISiteManagementState } from '../../../../store/site-management.reducer';
import { dataOnceReady } from '@activia/ngx-components';
import { siteManagementEntities } from '../../../../store/site-management.selectors';
import { isEmpty } from 'lodash';
import { IBoard } from '../../../../models/board-config.interface';
import { Dictionary } from '@ngrx/entity';
import { DisplayDTO } from '@activia/cm-api';
import * as BoardSelectors from '../../../../store/board/board.selectors';
import { BoardState } from '../../../../store/board/board.reducer';
import { DisplayState } from '../../../../store/display/display.reducer';
import * as DisplaySelectors from '../../../../store/display/display.selectors';
import { NavigationExtras } from '@angular/router';

@Component({
  selector: 'amp-screen-tags',
  templateUrl: './screen-tags.component.html',
  styleUrls: ['./screen-tags.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ScreenTagsComponent {
  site$ = dataOnceReady(this._store.pipe(siteManagementEntities.currSiteData$), this._store.pipe(siteManagementEntities.currSiteDataState$));
  hasScreen$: Observable<boolean> = this._routerFacade.currentRoute$.pipe(map((route) => !!route.params.screenName));
  hasBoard$: Observable<boolean> = this._routerFacade.currentRoute$.pipe(map((route) => !!route.params.boardId));
  board$: Observable<IBoard> = this._boardStore.select(BoardSelectors.selectedCurrentBoard);
  displayList$: Observable<Dictionary<DisplayDTO>> = this._displayStore.select(DisplaySelectors.selectDisplayEntities);

  constructor(
    @Inject(SITE_MANAGEMENT_MODULE_CONFIG) private _siteManagementConfig: ISiteManagementConfig,
    private _routerFacade: RouterFacade,
    private _store: Store<ISiteManagementState>,
    private _boardStore: Store<BoardState>,
    private _displayStore: Store<DisplayState>
  ) {}

  public selectBoardOrScreen(scope: TagValueAssignmentScope): void {
    this.site$
      .pipe(
        take(1),
        map((site) => site.id),
        tap((siteId) => {
          let path = [...this._siteManagementConfig.moduleBasePath, siteId, 'screens', 'board', `${scope.id}`];
          if (!isEmpty(scope.entityName)) {
            path = path.concat(['screen', `${scope.entityName}`]);
          }
          const extras = { queryParamsHandling: 'preserve' } as NavigationExtras;
          this._routerFacade.navigate({ path, extras });
        })
      )
      .subscribe();
  }
}
