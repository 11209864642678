<div *ngIf="site$ | async as site" class="site-board-main-wrapper">
  <amp-site-selector [isAssignableMode]="false" (siteSelectionChanged)="onSiteSelectionChange($event)"></amp-site-selector>

  <h4 class="site-board-main-title">{{ site.name + ' - ' + ('siteManagementScope.SITE_MANAGEMENT.SITE_DETAIL.SIDE_MENU.LABEL.BOARD_LIST_40' | transloco) }}</h4>
  <ng-container *avnAsyncData="''; state: boardState$ | async; loaderType: 'linear'; emptyTemplate: errorTemplate; errorTemplate: errorTemplate">
    <div class="site-board-list" *ngIf="(boardList$ | async).length; else siteEmpty">
      <div class="board" *ngFor="let board of boardList$ | async">
        <avn-accordion>
          <avn-accordion-panel [headerTemplate]="customHeader" [avnBackground]="{ cssProp: '--data-table-row-odd-background', shade: 700 }">
            <div avnScroll class="panel-detail" *ngIf="displayList$ | async as displayEntities">
              <div
                *ngFor="let displayId of board.displays"
                class="display"
                [routerLink]="[board.organizationPath, displayEntities[displayId]?.name]"
                queryParamsHandling="preserve"
                [avnTooltip]="(editable$ | async) ? ('siteManagementScope.SITE_MANAGEMENT.SITE_DETAIL.SIDE_MENU.BUTTON.UPDATE_TAG_40' | transloco) : 'siteManagementScope.SITE_MANAGEMENT.SITE_DETAIL.SIDE_MENU.BUTTON.VIEW_TAG_40' | transloco"
                [tooltipPlacement]="'bottom'"
              >
                <avn-icon [icon]="'communication: desktop_windows'"></avn-icon>
                <span>
                  {{ displayEntities[displayId]?.name }}
                </span>
              </div>
            </div>
          </avn-accordion-panel>
        </avn-accordion>

        <!-- Accordion header -->
        <ng-template #customHeader>
          <div style="display: flex; justify-content: space-between; align-items: center">
            <avn-section-breadcrumb height="32px" [sections]="board | applyFunction: getBoardBreadcrumbSections"></avn-section-breadcrumb>

            <avn-button
              [size]="'small'"
              [routerLink]="board.organizationPath"
              queryParamsHandling="preserve"
              [label]="(editable$ | async) ? ('siteManagementScope.SITE_MANAGEMENT.SITE_DETAIL.SIDE_MENU.BUTTON.UPDATE_TAG_40' | transloco) : 'siteManagementScope.SITE_MANAGEMENT.SITE_DETAIL.SIDE_MENU.BUTTON.VIEW_TAG_40' | transloco"
              [avnTheme]="'primary'"
            ></avn-button>
          </div>
        </ng-template>
      </div>
    </div>
  </ng-container>

  <ng-template #siteEmpty>
    <div class="site-board-empty">
      <avn-empty-data-message
        icon="hardware:tv"
        [text]="'siteManagementScope.SITE_MANAGEMENT.BOARD_EDITOR.BOARD_CONFIG.NOT_SET_TITLE_50' | transloco"
        [subText]="(editable$ | async) ?
          ('siteManagementScope.SITE_MANAGEMENT.BOARD_EDITOR.BOARD_CONFIG.NOT_SET_MESSAGE_100' | transloco) :
          ('siteManagementScope.SITE_MANAGEMENT.BOARD_EDITOR.BOARD_CONFIG.NOT_SET_READONLY_MESSAGE_100' | transloco)"
      >
      </avn-empty-data-message>
      <avn-button
        *ngIf="editable$ | async"
        avnTheme="primary"
        [label]="'siteManagementScope.SITE_MANAGEMENT.SITE_DETAIL.SIDE_MENU.BUTTON.ADD_BOARD_40' | transloco"
        (actioned)="goToBoardDetail()">
      </avn-button>
    </div>
  </ng-template>

  <ng-template #errorTemplate>
    <div class="no-data">
      <avn-empty-data-message icon="alert:error" [text]="'siteManagementScope.SITE_MANAGEMENT.GLOBAL.ERROR.FETCH_SITE_BOARDS_FAIL_150' | transloco"> </avn-empty-data-message>
    </div>
  </ng-template>
</div>
