<div class="content" [class.disabled]="(editable$ | async) === false" avnScroll>
  <section>
    <h5>{{ 'siteManagementScope.SITE_MANAGEMENT.SITE_DETAIL.SITE_PROPERTIES_EDITOR.INFO.GENERAL_35' | transloco }}</h5>
    <amp-site-management-site-basic-info-editor [parentForm]="form" [site]="site$ | async" (hasErrors)="hasBasicInfoErrors = $event">
    </amp-site-management-site-basic-info-editor>
  </section>

  <section>
    <h5>{{ 'siteManagementScope.SITE_MANAGEMENT.SITE_DETAIL.SITE_PROPERTIES_EDITOR.INFO.LOCATION_35' | transloco }}</h5>
    <amp-location-editor [parentForm]="form" [siteId]="(site$ | async)?.id" [location]="mapLocation$ | async" [editable]="editable$ | async">
    </amp-location-editor>
  </section>
</div>

<div class="footer">
  <div class="actions">
    <avn-button
      *ngIf="showBackButton"
      [icon]="'action:subdirectory_arrow_left'"
      [label]="'siteManagementScope.SITE_MANAGEMENT.SITE_DETAIL.SITE_PROPERTIES_EDITOR.INFO.BACK_TO_OVERVIEW_50' | transloco"
      (actioned)="onCancel()"
    >
    </avn-button>
    <avn-button
      *ngIf="editable$ | async"
      [label]="'button.save' | transloco"
      [avnTheme]="THEME_TYPES.PRIMARY"
      [disabled]="form.pristine || form.invalid || hasBasicInfoErrors"
      (actioned)="onSave()"
    >
    </avn-button>
  </div>
</div>
