import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { commandsReducer, initialCommandsState } from './store/commands.reducer';
import { CommandsEffects } from './store/commands.effects';
import { CommandsTaskPanelItemComponent } from './components/commands-task-panel-item/commands-task-panel-item.component';
import { ButtonModule, CoreModule, DataTableModule, IconModule, ModalModule, ProgressbarModule } from '@activia/ngx-components';
import { RouterModule } from '@angular/router';
import { TRANSLOCO_SCOPE, TranslocoModule } from '@ngneat/transloco';
import { CommandsSummaryModalComponent } from './components/commands-summary-modal/commands-summary-modal.component';
import { CommandProgressBarComponent } from './components/command-progress-bar/command-progress-bar.component';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature('commands', commandsReducer, { initialState: initialCommandsState }),
    EffectsModule.forFeature([CommandsEffects]),
    ModalModule,
    ButtonModule,
    RouterModule,
    CoreModule,
    TranslocoModule,
    ProgressbarModule,
    DataTableModule,
    IconModule,
  ],
  declarations: [CommandsTaskPanelItemComponent, CommandsSummaryModalComponent, CommandProgressBarComponent],
  exports: [CommandProgressBarComponent],
  providers: [{ provide: TRANSLOCO_SCOPE, useValue: 'commands', multi: true }],
})
export class CommandsModule {}
