import { Component, ChangeDetectionStrategy, Input, OnInit } from '@angular/core';
import { PropertyType } from '../../model/operation-scope.interface';
import { ITagChangeSummary } from '../../model/tag-values-operations.interface';

@Component({
  selector: 'amp-invalid-tag-message',
  templateUrl: './invalid-tag-message.component.html',
  styleUrls: ['./invalid-tag-message.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InvalidTagMessageComponent implements OnInit {
  @Input() propertyType: PropertyType = 'asset';

  /** Tag operation impacts */
  @Input() tagOperations: ITagChangeSummary[];

  /** i18n key pluralized when necessary */
  i18nTagMessageMapping: Record<string, any>;

  ngOnInit() {
    this.i18nTagMessageMapping = {
      delete: {
        '=0': `tagOperation.TAG_OPERATION.INVALID_ENTITY.DELETE_INVALIDATE_ENTITY_COUNT.${this.propertyType.toUpperCase()}.SINGULAR_100`,
        '=1': `tagOperation.TAG_OPERATION.INVALID_ENTITY.DELETE_INVALIDATE_ENTITY_COUNT.${this.propertyType.toUpperCase()}.SINGULAR_100`,
        other: `tagOperation.TAG_OPERATION.INVALID_ENTITY.DELETE_INVALIDATE_ENTITY_COUNT.${this.propertyType.toUpperCase()}.PLURAL_100`,
      },
      replace: {
        '=0': `tagOperation.TAG_OPERATION.INVALID_ENTITY.REPLACE_INVALIDATE_ENTITY_COUNT.${this.propertyType.toUpperCase()}.SINGULAR_100`,
        '=1': `tagOperation.TAG_OPERATION.INVALID_ENTITY.REPLACE_INVALIDATE_ENTITY_COUNT.${this.propertyType.toUpperCase()}.SINGULAR_100`,
        other: `tagOperation.TAG_OPERATION.INVALID_ENTITY.REPLACE_INVALIDATE_ENTITY_COUNT.${this.propertyType.toUpperCase()}.PLURAL_100`,
      },
      default:{
        '=0': `tagOperation.TAG_OPERATION.INVALID_ENTITY.DEFAULT_INVALIDATE_ENTITY_COUNT.${this.propertyType.toUpperCase()}.SINGULAR_100`,
        '=1': `tagOperation.TAG_OPERATION.INVALID_ENTITY.DEFAULT_INVALIDATE_ENTITY_COUNT.${this.propertyType.toUpperCase()}.SINGULAR_100`,
        other: `tagOperation.TAG_OPERATION.INVALID_ENTITY.DEFAULT_INVALIDATE_ENTITY_COUNT.${this.propertyType.toUpperCase()}.PLURAL_100`,
      },
    };
  }
}
