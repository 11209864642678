import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthFacade } from './store/auth.facade';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private authFacade: AuthFacade) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      catchError((err) => {
        if (err instanceof HttpErrorResponse) {
          // assuming cm-engine only is using /amp/v1 :(
          if ([401].includes(err.status) && err.url.indexOf('/amp/v1') > -1) {
            this.authFacade.expireCurrentSession('401');
          }
        }
        return throwError(err);
      })
    );
  }
}
