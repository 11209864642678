import { IColumnDefinition } from '@amp/column-picker';
import { SiteMonitoringKeyMetric } from './site-monitoring-key-metric.enum';
import { SITE_MONITORING_KEY_METRICS } from './site-monitoring-key-metrics';

/** Represents a key metrics category and its children **/
export interface ISiteMonitoringKeyMetricsCategory {
  id: string;
  label: string;
  children: IColumnDefinition[];
}

/** @ignore **/
const getKeyMetric = (id: string) => SITE_MONITORING_KEY_METRICS.find((km) => id === km.id);

/** Defines the categories for the monitoring module columns (we reuse some categories of the columns) **/
export const SITE_MONITORING_KEY_METRICS_CATEGORIES: ISiteMonitoringKeyMetricsCategory[] = [
  {
    id: 'summary_indicators',
    label: 'SITE_MONITORING.COLUMNS.CATEGORIES.SUMMARY_INDICATORS.TITLE_35',
    children: [getKeyMetric(SiteMonitoringKeyMetric.HealthStatusSummary)],
  },
  {
    id: 'content_accuracy',
    label: 'SITE_MONITORING.COLUMNS.CATEGORIES.CONTENT_ACCURACY.TITLE_35',
    children: [getKeyMetric(SiteMonitoringKeyMetric.ContentStatus)],
  },
  {
    id: 'critical_system_components',
    label: 'SITE_MONITORING.COLUMNS.CATEGORIES.CRITICAL_SYSTEM_COMPONENTS.TITLE_35',
    children: [getKeyMetric(SiteMonitoringKeyMetric.Services), getKeyMetric(SiteMonitoringKeyMetric.CodState), getKeyMetric(SiteMonitoringKeyMetric.PosState)],
  },
  {
    id: 'system_metrics',
    label: 'SITE_MONITORING.COLUMNS.CATEGORIES.SYSTEM_METRICS.TITLE_35',
    children: [getKeyMetric(SiteMonitoringKeyMetric.SystemDisk)],
  },
  {
    id: 'enclosure',
    label: 'SITE_MONITORING.COLUMNS.CATEGORIES.ENCLOSURE.TITLE_35',
    children: [getKeyMetric(SiteMonitoringKeyMetric.EnclosureStatus)],
  },
];
