import { Pipe, PipeTransform } from '@angular/core';
import { ThemeType } from '@activia/ngx-components';
import { DevicePropertiesStatus, DevicePropertiesStatusThemeMap } from '@amp/devices';
import { CountAggregationData } from '../model/site-monitoring-data.interface';

@Pipe({ name: 'devicePropertiesStatus' })
export class DevicePropertiesStatusPipe implements PipeTransform {
  transform(value: CountAggregationData<DevicePropertiesStatus>): CountAggregationData<DevicePropertiesStatus | ThemeType> {
    if (!value) {
      return value;
    }
    // combine statuses together and remove those with a 0 total
    const map = new Map<ThemeType, number>();
    Object.entries(value).forEach(([k, v]) => {
      const theme = DevicePropertiesStatusThemeMap.get(Number(k));
      const newCount = (map.get(theme) || 0) + v;
      map.set(theme, newCount);
    });
    return Array.from(map.entries())
      .filter((entry) => entry[1] > 0)
      .reduce(
        (res, [k, v]) => ({
          ...res,
          [k]: v,
        }),
        {}
      );
  }
}
