export enum ScreenshotOrientation {
  LandScape = 'LANDSCAPE',
  Portrait = 'PORTRAIT',
}

export enum BoardGridType {
  Screens = 'SCREENS',
  Pixels = 'PIXELS',
}

export const ScreenDegreeToOrientation: { [key: number]: ScreenshotOrientation } = {
  0: ScreenshotOrientation.LandScape,
  90: ScreenshotOrientation.Portrait,
  180: ScreenshotOrientation.LandScape,
  270: ScreenshotOrientation.Portrait,
};
