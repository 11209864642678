<form [formGroup]="form">
  <div class="inline-mode" [class.disabled]="!editable">
    <ng-container *ngTemplateOutlet="geoSearch"></ng-container>
    <ng-container *ngIf="showAddressFormEditor === false">
      <amp-address-viewer
        class="address-viewer"
        [address]="currAddress"
        [showEditIcon]="editable"
        (displayModeChanged)="toggleAddressEditor()">
      </amp-address-viewer>
    </ng-container>
    <ng-container *ngIf="showAddressFormEditor">
      <amp-address-editor
        [siteId]="siteId"
        [address]="currAddress"
        [isGeoAddressValid]="isGeoAddressValid"
        [validateFormOnInitialLoad]="validateFormOnInitialLoad"
        [parentForm]="parentForm"
        (addressChanged)="onLocationChanged($event)"
      >
      </amp-address-editor>
    </ng-container>
  </div>

  <ng-template #geoSearch>
    <div class="search">
      <avn-form-field>
        <avn-geo-search formControlName="geoSearchInput"
          [label]="'siteManagementScope.SITE_MANAGEMENT.SITE_DETAIL.SITE_PROPERTIES_EDITOR.INFO.ADDRESS.TITLE_35' | transloco"
          [placeholder]="'siteManagementScope.SITE_MANAGEMENT.SITE_DETAIL.SITE_PROPERTIES_EDITOR.INFO.ADDRESS.PLACEHOLDER_100' | transloco"
          [countries]="['us', 'ca']"
          [searchType]="'address'"
          (itemSelectedChange)="onGeoSearchChanged($event)"
        >
        </avn-geo-search>
      </avn-form-field>
      <span [class.visible]="duplicateGeoAddressSelected$ | async" class="warning">
        {{ 'siteManagementScope.SITE_MANAGEMENT.SITE_DETAIL.SITE_PROPERTIES_EDITOR.INFO.ADDRESS.VALIDATION.DUPLICATE_ADDRESS_100' | transloco }}
      </span>
    </div>
  </ng-template>
</form>
