import { Directive, Input, OnDestroy, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { AuthFacade } from '@amp/auth';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { ScopeDTO } from '@activia/cm-api';

/**
 * @whatItDoes Conditionally includes an HTML element if current user has
 * unrestricted scope (a.k.a users scope has "authorizeAllDeviceGroups" set
 * to true)
 *
 * @howToUse
 * ```
 *    <some-element *ampHasUnrestrictedScope>...</some-element>
 * ```
 */
@Directive({
  selector: '[ampHasUnrestrictedScope]',
})
export class HasUnrestrictedScopeDirective implements OnInit, OnDestroy {

  requiresUnrestrictedScope: boolean;
  hasUnrestrictedScope: boolean;

  @Input()
  set ampHasUnrestrictedScope(value: boolean) {
    this.requiresUnrestrictedScope = value;
    this.updateView();
  }

  private componentDestroyed$: Subject<void> = new Subject<void>();

  constructor(private authFacade: AuthFacade, private templateRef: TemplateRef<any>, private viewContainerRef: ViewContainerRef) {}

  ngOnInit() {
    this.authFacade.authenticatedUserScopes$.pipe(
      takeUntil(this.componentDestroyed$)
    ).subscribe((scopes: ScopeDTO[]) => {
      this.hasUnrestrictedScope = (scopes || []).some((scope) => !!scope?.authorizeAllDeviceGroups);
      this.updateView();
    });
  }

  ngOnDestroy() {
    this.componentDestroyed$.next();
    this.componentDestroyed$.complete();
  }

  private updateView(): void {
    if (!this.requiresUnrestrictedScope || this.hasUnrestrictedScope) {
      this.viewContainerRef.clear();
      this.viewContainerRef.createEmbeddedView(this.templateRef);
    } else {
      this.viewContainerRef.clear();
    }
  }
}
