import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { SiteManagementSitePropertiesTab } from '../site-management-site-properties.constant';
import { first, map, Observable, tap } from 'rxjs';
import { RouterFacade } from '@amp/router-store';
import { ActivatedRoute, Router } from '@angular/router';
import { SITE_MANAGEMENT_MODULE_CONFIG, ISiteManagementConfig } from '@amp/environment';

@Component({
  selector: 'amp-site-management-site-properties',
  templateUrl: './site-management-site-properties.component.html',
  styleUrls: ['./site-management-site-properties.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SiteManagementSitePropertiesComponent {
  selectedTabId$: Observable<SiteManagementSitePropertiesTab>;

  constructor(private _routerFacade: RouterFacade, private router: Router, private route: ActivatedRoute, @Inject(SITE_MANAGEMENT_MODULE_CONFIG) private _siteManagementConfig: ISiteManagementConfig) {
    this.selectedTabId$ = this._routerFacade.currentRoute$.pipe(map(() => this.route.firstChild?.routeConfig?.path as 'info' | 'tags' | 'managers'));
  }

  /**
   * Update selected tab
   *
   * @param tab Selected tab
   */
  onTabChange(tab: SiteManagementSitePropertiesTab) {
    this._routerFacade.currentRoute$
      .pipe(
        first(),
        tap((route) => this.router.navigate([...this._siteManagementConfig.moduleBasePath, route.params.siteId, 'properties', tab]))
      )
      .subscribe();
  }
}
