import { IGridSize } from '@activia/ngx-components';
import { CreateAllDisplaysInBoardDTO, DisplayDTO, DisplayInputDTO, UpdateAllDisplaysInBoardDTO } from '@activia/cm-api';
import { ScreenOrientations } from '../models/screen-orientation.enum';
import { IDisplayTemplate } from '../models/experience-template.interface';
import { IBoard } from '../models/board-config.interface';
import { EngineTagLevel, TagValueAssignmentScope } from '@amp/tag-operation';

export const generateDisplayFromTemplate = (display: IDisplayTemplate, displayIdx: number): DisplayDTO => ({
  boardScreenIdx: displayIdx,
  geometry: {
    gridType: 'SCREENS',
    orientation: display.orientation,
    geometry: display.geometry || `+${displayIdx}+0`, // When generating from template, the layout is always multi column x 1 row
  },
  name: display.name,
  inputs: [],
  parentBoardId: null, // Will be set after the board is created
});

export const convertToCreateAllDisplaysInBoardDTO = (displays: DisplayDTO[]): CreateAllDisplaysInBoardDTO => ({
  grid: 'SCREENS',
  screens: displays
    .filter((display) => !!display)
    .map((display: DisplayDTO) => {
      const geometry = display.geometry.geometry.split('+').filter((offset) => !!offset);
      return {
        name: display.name,
        geometry: {
          orientation: display.geometry.orientation,
          xoffset: +geometry[0],
          yoffset: +geometry[1],
        },
        inputs: display.inputs,
      };
    }),
});

export const convertToUpdateAllDisplaysInBoardDTO = (displays: DisplayDTO[]): UpdateAllDisplaysInBoardDTO => ({
  grid: 'SCREENS',
  displays: displays
    .filter((display) => !!display)
    .map((display) => {
      const geometry = display.geometry.geometry.split('+').filter((offset) => !!offset);
      return {
        id: display.id,
        geometry: {
          orientation: display.geometry.orientation,
          xoffset: +geometry[0],
          yoffset: +geometry[1],
        },
        inputs: display.inputs,
      };
    }),
});

/** Generate default displays */
export const generateDisplays = (size: IGridSize): DisplayDTO[] => {
  const displays: DisplayDTO[] = [];
  for (let rIdx = 0; rIdx < size.row; rIdx++) {
    for (let cIdx = 0; cIdx < size.column; cIdx++) {
      displays.push(
        generateDisplay(
          displays.length,
          cIdx,
          rIdx,
          displays.map((e) => e.name)
        )
      );
    }
  }
  return displays;
};

/** generate one default display */
export const generateDisplay = (displayIndex: number, xoffset: number, yoffset: number, usedName?: string[]): DisplayDTO => {
  const nameSuffix = 'Panel';
  let nameIndex = 1;
  while (usedName?.includes(nameSuffix + nameIndex)) {
    nameIndex++;
  }

  return {
    boardScreenIdx: displayIndex,
    geometry: {
      gridType: 'SCREENS',
      orientation: ScreenOrientations.portrait,
      geometry: `+${xoffset}+${yoffset}`,
    },
    name: nameSuffix + nameIndex,
    inputs: [generateInput()],
    parentBoardId: null, // Will be set after the board is created
  };
};

/** Generate a default display input DTO */
export const generateInput = (): DisplayInputDTO => ({
  deviceId: null,
  playerId: 0,
  output: 0,
});

export const mapScreens = (board: IBoard, displays: DisplayDTO[], screenName, siteLabel?: string): TagValueAssignmentScope => {
  const targetDisplay = board ? displays.find((display) => display.parentBoardId === board.id && display.name?.toString() === screenName) : undefined;
  const boards: { id: number; name: string; screens: DisplayDTO[] }[] = [];
  const ids = [];
  for (const value of Object.values(boards)) {
    const displayIds = value.screens.map((screen) => screen.id);
    displayIds.forEach((displayId) => ids.push(displayId));
  }
  return targetDisplay
    ? {
        entityName: targetDisplay.name,
        id: targetDisplay.parentBoardId,
        ids,
        idx: targetDisplay.boardScreenIdx,
        level: EngineTagLevel.SCREEN,
        siteLabel,
        boards,
      }
    : undefined;
};
