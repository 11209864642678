import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { SiteMonitoringDetailStore } from '../store/site-monitoring-detail.store';
import { ISiteBoard, ISiteSection } from '../store/site-monitoring-detail.model';
import { getOrderedBoardDisplays } from '../../../utils/site-boards.utils';
import { SiteMonitoringWidthSyncService } from '../site-monitoring-width-sync.service';
import { IBoardWithOrgPath } from '../../../model/board-with-orgpath.interface';
/**
 * 1. Find all boards of the section and keep them in order
 * 2. Make sure all screenshots from boards of the section are responsive in one row
 */
@Component({
  selector: 'amp-site-monitoring-section',
  templateUrl: './site-monitoring-section.component.html',
  styleUrls: ['./site-monitoring-section.component.scss'],
  providers: [SiteMonitoringWidthSyncService],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SiteMonitoringSectionComponent implements OnChanges {
  /** section structure */
  @Input() section: ISiteSection;

  boards$: Observable<IBoardWithOrgPath[]> = of([]);

  /** css based on total display counts */
  gridCssColumns$ = of('');

  boardTrackFn = (_: number, board: ISiteBoard) => board.id;

  constructor(private _siteMonitoringDetailStore: SiteMonitoringDetailStore) {}

  ngOnChanges({ section }: SimpleChanges) {
    if (section) {
      const boardIds = section.currentValue?.boards.map((board) => board.id);
      this.boards$ = this._siteMonitoringDetailStore.selectBoards(boardIds);
      this.gridCssColumns$ = this.boards$.pipe(
        map((boards) =>
          boards
            .map((board) => {
              // get the displays ordered per row
              const boardDisplaysPerRow = getOrderedBoardDisplays(board);

              // Get maximum device per row to calculate board width
              const maxDevicePerRow = Math.max(...boardDisplaysPerRow.map((e) => e.displays.length));

              // If 0 is yield, that means this board has no display, set the grid size to 1
              return (maxDevicePerRow === 0 ? 1 : maxDevicePerRow) + 'fr';
            })
            .join(' ')
        )
      );
    }
  }
}
