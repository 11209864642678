import { ConstraintTypeEnum, getJSONSchemaType, IJsonSchema } from '@activia/json-schema-forms';
import { TranslocoService } from '@ngneat/transloco';
import { IOrgPathNodeErrors } from './orgpath.interface';
import { ThemeType } from '@activia/ngx-components';

export abstract class OrgpathTreeBase {
  onHover = false;
  themeType = ThemeType;

  constructor(private transloco: TranslocoService) {}

  mapSchema(node, tagDefs) {
    if (node?.tag === '') {
      return undefined;
    } else if (tagDefs[node?.tag]) {
      return tagDefs[node.tag].schema as IJsonSchema;
    } else {
      return node?.schema;
    }
  }

  trackById(_, item): string {
    return item.id;
  }

  getTypeText(schema: IJsonSchema): string {
    return this.getTypeLabel(schema);
  }

  getOrgpathNodeErrors = (errors: IOrgPathNodeErrors): string => {
    return Object.keys(errors)
      .map((errorType) => {
        const errorTranslationBase = 'siteManagementScope.SITE_MANAGEMENT.GLOBAL.ORGANIZATIONAL_PATH.ORGPATH_EDITOR.ERRORS.';
        switch (errorType) {
          case 'hasDuplicate':
            return this.transloco.translate(`${errorTranslationBase}HAS_DUPLICATE`, { value: errors.hasDuplicate.join(', ') });
          case 'missingConditions':
            return this.transloco.translate(`${errorTranslationBase}MISSING_CONDITIONS`, {
              value: errors.missingConditions.join(', '),
            });
          case 'missingNoConditionNode':
            return this.transloco.translate(`${errorTranslationBase}MISSING_CHILD_WITHOUT_CONDITION`);
          case 'noChild':
            return this.transloco.translate(`${errorTranslationBase}NO_CHILD`);
          case 'notALeaf':
            return this.transloco.translate(`${errorTranslationBase}NOT_A_LEAF`);
          case 'noType':
            return this.transloco.translate(`${errorTranslationBase}NO_TYPE`);
        }
      })
      .join('\r\n');
  };

  getTypeLabel = (schema: IJsonSchema): string => {
    const translations = {
      specific: 'SPECIFIC_50',
      alphanumeric: 'REGEX_30',
      text: 'TEXT_30',
      numeric: 'NUMERIC_30',
      boolean: 'BOOLEAN_30',
      schema: 'SCHEMA_30',
    };

    const type = getJSONSchemaType(schema);
    const translationKey = `siteManagementScope.SITE_MANAGEMENT.GLOBAL.ORGANIZATIONAL_PATH.ORGPATH_EDITOR.SCHEMA_TYPE.${translations[type]}`;

    switch (type) {
      case ConstraintTypeEnum.specific:
        return `${this.transloco.translate(translationKey)} ${schema.enum.join(', ')}`;
      case ConstraintTypeEnum.alphanumeric:
      case ConstraintTypeEnum.text:
      case ConstraintTypeEnum.boolean:
      case ConstraintTypeEnum.schema:
        return this.transloco.translate(translationKey);
      case ConstraintTypeEnum.numeric:
        if (schema.minimum && schema.maximum) {
          return this.transloco.translate(translationKey, { minimum: schema.minimum, maximum: schema.maximum });
        } else {
          return this.transloco.translate(translationKey);
        }
      default:
        return '';
    }
  };
}
