import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as GeoFixerAction from './geo-fixer.actions';
import * as SiteManagementAction from '../../store/site-management.actions';
import { Injectable } from '@angular/core';
import { map, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { dataOnceReady, TaskPanelService } from '@activia/ngx-components';
import { siteManagementEntities } from '../site-management.selectors';
import { Store } from '@ngrx/store';
import { ISiteManagementState } from '../site-management.reducer';
import { findSitesWithIncompleteInfo } from '../../utils/site.utils';
import { differenceBy } from 'lodash';
import { GeoFixSiteTaskPanelItemComponent } from '../../components/geo-fix-site-task-panel-item/geo-fix-site-task-panel-item.component';
import { IGeoFixerSite } from '../../models/geo-fixer-site.interface';
import { geoFixerEntities } from './geo-fixer.selectors';

@Injectable()
export class GeoFixerEffects {

  fetchAllSitesSuccess$ = createEffect(() =>
    this._actions$.pipe(
      ofType(SiteManagementAction.FetchAllSitesSuccess),
      map((_) => GeoFixerAction.FetchAllGeoProblematicSites())
    )
  );

  fetchAllGeoProblematicSites$ = createEffect(() =>
    this._actions$.pipe(
      ofType(GeoFixerAction.FetchAllGeoProblematicSites),
      switchMap(() => dataOnceReady(this._store.pipe(siteManagementEntities.allSitesData$), this._store.pipe(siteManagementEntities.allSitesDataState$), 1)),
      withLatestFrom(this._store.pipe(geoFixerEntities.allGeoProblematicSites$)),
      map(([allSites, existingProblematicSites]) => {
        // First find all sites with incomplete address, coordinate or missing timezone
        const problematicSites: IGeoFixerSite[] = findSitesWithIncompleteInfo(allSites);
        const newProblematicSites = differenceBy(problematicSites, existingProblematicSites, 'id');
        return GeoFixerAction.FetchAllGeoProblematicSitesSuccess({ sites: newProblematicSites });
      })
    )
  );

  /** Auto-fix some sites */
  fixSites$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(GeoFixerAction.AutoFixGeoProblematicSites),
        // adds the task panel component that will queue sites to fix
        tap(() => {
          this._taskPanelService.addTaskComponent(GeoFixSiteTaskPanelItemComponent);
        })
      ),
    { dispatch: false }
  );

  constructor(
    private _actions$: Actions,
    private _store: Store<ISiteManagementState>,
    private _taskPanelService: TaskPanelService,
  ) {}
}
