import { KeyMetricsCodComponent } from './components/keymetrics/key-metrics-cod/key-metrics-cod.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SiteMonitoringColumnsEditorComponent } from './components/columns/site-monitoring-columns-editor/site-monitoring-columns-editor.component';
import { SiteMonitoringColumnsEditorModalComponent } from './components/columns/site-monitoring-columns-editor-modal/site-monitoring-columns-editor-modal.component';
import { SiteFieldTemplatesComponent } from './components/site-field-templates/site-field-templates.component';
import { AggregationPipe } from './pipes/aggregation.pipe';
import { ColumnThresholdThemePipe } from './pipes/column-threshold-theme.pipe';
import { RemoveZeroCountPipe } from './pipes/remove-zero-count.pipe';
import { SortCountByKeyPipe } from './pipes/sort-count-by-key.pipe';
import { DevicePropertiesStatusPipe } from './pipes/device-properties-status.pipe';
import { StatusRatioPipe } from './pipes/status-ratio.pipe';
import { SiteMonitoringKeyMetricsEditorComponent } from './components/keymetrics/site-monitoring-key-metrics-editor/site-monitoring-key-metrics-editor.component';
import { SiteMonitoringKeyMetricsEditorModalComponent } from './components/keymetrics/site-monitoring-key-metrics-editor-modal/site-monitoring-key-metrics-editor-modal.component';
import { SiteMonitoringBoardComponent } from './components/site-monitoring-detail/site-monitoring-board/site-monitoring-board.component';
import { StoreModule } from '@ngrx/store';
import { siteMonitoringInitialState, siteMonitoringReducer } from './store/site-monitoring.reducer';
import { EffectsModule } from '@ngrx/effects';
import { SiteMonitoringEffects } from './store/site-monitoring.effects';
import { TranslocoModule, TRANSLOCO_SCOPE } from '@ngneat/transloco';
import { SiteAddressPipe } from './pipes/site-address.pipe';
import { KeyMetricsPosComponent } from './components/keymetrics/key-metrics-pos/key-metrics-pos.component';
import { KeyMetricsDiskComponent } from './components/keymetrics/key-metrics-disk/key-metrics-disk.component';

import {
  BadgeModule,
  ButtonModule,
  CheckboxModule,
  CoreModule,
  EmptyDataMessageModule,
  IconModule,
  LayoutModule,
  LoaderModule,
  ModalModule,
  TooltipModule,
  MediaCardModule,
  WidgetModule,
  SelectModule,
  CarouselModule,
  SkeletonModule,
  CheckboxGroupModule,
  FormFieldModule,
  ContextMenuModule,
  AsyncDataModule,
  ConnectedElementModule,
  AccordionModule,
  SwitchModule,
  ActionBarModule,
  SummaryCardModule,
  SectionBreadcrumbComponent,
} from '@activia/ngx-components';
import { GeoModule } from '@activia/geo';
import { ColumnPickerModule } from '@amp/column-picker';
import { AuthModule } from '@amp/auth';
import { UIModule } from '@amp/ui';
import { SiteMonitoringDetailComponent } from './components/site-monitoring-detail/site-monitoring-detail.component';
import { SiteMonitoringDetailContainerComponent } from './components/site-monitoring-detail-container/site-monitoring-detail-container.component';
import { SiteMonitoringDisplayComponent } from './components/site-monitoring-detail/site-monitoring-display/site-monitoring-display.component';
import { SiteScreenshotModalComponent } from './components/site-monitoring-detail/screenshot-modal/site-screenshot-modal.component';
import { OptimisticViewPipe } from './pipes/optimistic-view.pipe';
import { SiteMonitoringKeyMetricsViewerComponent } from './components/keymetrics/key-metrics-viewer/key-metrics-viewer.component';
import { KeyMetricsHealthSummaryComponent } from './components/keymetrics/key-metrics-health-summary/key-metrics-health-summary.component';
import { KeyMetricsContentAccuracyComponent } from './components/keymetrics/key-metrics-content-accuracy/key-metrics-content-accuracy.component';
import { KeyMetricsServicesComponent } from './components/keymetrics/key-metrics-services/key-metrics-services.component';
import { ChartCoreModule, ConnectorLineModule, CountCardModule, DonutModule, PercentDonutModule, PieModule } from '@activia/dataviz';
import { SiteMonitoringSectionComponent } from './components/site-monitoring-detail/site-monitoring-section/site-monitoring-section.component';
import { SitePreviewComponent } from './components/site-preview/site-preview.component';
import { SiteMonitoringHealthIndicatorComponent } from './components/site-monitoring-detail/site-monitoring-health-indicator/site-monitoring-health-indicator.component';
import { SiteHealthOverviewComponent } from './components/site-health-overview/site-health-overview.component';
import { KeyMetricsStatusCardComponent } from './components/keymetrics/status-count-card/key-metrics-status-card.component';
import { KeyMetricsServicesSettingsComponent } from './components/keymetrics/site-monitoring-key-metrics-editor/settings/services-settings/key-metrics-services-settings.component';
import { ReactiveFormsModule } from '@angular/forms';
import { KeyMetricsOptimisticSettingsComponent } from './components/keymetrics/site-monitoring-key-metrics-editor/settings/optimistic-settings/key-metrics-optimistic-settings.component';
import { SiteMonitoringSiteMapComponent } from './components/site-monitoring-site-map/site-monitoring-site-map.component';
import { SiteContentStatusComponent } from './components/site-content-status/site-content-status.component';
import { StatusSeverityPipe } from './pipes/status-severity.pipe';
import { RemoveKeyMetricsNotAvailablePipe } from './pipes/remove-key-metrics-not-available.pipe';
import { SiteMonitoringDeviceComponent } from './components/site-monitoring-detail/site-monitoring-device/site-monitoring-device.component';
import { SiteMonitoringScreenshotComponent } from './components/screenshot/site-monitoring-screenshot.component';
import { DatavizPieDataItemsPipe } from './pipes/dataviz-pie-data-items.pipe';
import { SiteGlobalConfigConfirmationComponent } from './components/site-global-config-confirmation/site-global-config-confirmation.component';
import { BoardAlarmModalComponent } from './components/site-alarms/board-alarm-modal/board-alarm-modal.component';
import { BoardAlarmsComponent } from './components/site-alarms/board-alarms/board-alarms.component';
import { DevicesModule } from '@amp/devices';
import { BoardAlarmPipe } from './components/site-monitoring-detail/board-alarm.pipe';
import { SiteDeviceActionsComponent } from './components/site-device-actions/site-device-actions.component';
import { KeyMetricsAccordionComponent } from './components/keymetrics/key-metrics-accordion/key-metrics-accordion.component';
import { SiteAlarmsComponent } from './components/site-alarms/site-alarms.component';
import { FeatureToggleModule } from '@amp/feature-toggle';
import { KeyMetricsHideOkSettingsComponent } from './components/keymetrics/site-monitoring-key-metrics-editor/settings/hide-ok-settings/key-metrics-hide-ok-settings.component';
import { KeyMetricsShowTooltipSettingsComponent } from './components/keymetrics/site-monitoring-key-metrics-editor/settings/show-tooltip-settings/key-metrics-show-tooltip-settings.component';
import { KeyMetricsEnclosureStatusSettingsComponent } from './components/keymetrics/site-monitoring-key-metrics-editor/settings/enclosure-status-settings/key-metrics-enclosure-status-settings.component';
import { KeyMetricsEnclosureStatusComponent } from './components/keymetrics/key-metrics-enclosure-status/key-metrics-enclosure-status.component';
import { SiteHealthMonitorComponent } from './components/site-health-monitor/site-health-monitor.component';
import { SitesHealthMonitorComponent } from './components/sites-health-monitor/sites-health-monitor.component';
import { SiteTitlePipe } from './pipes/site-title.pipe';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature('siteMonitoring', siteMonitoringReducer, { initialState: siteMonitoringInitialState }),
    EffectsModule.forFeature([SiteMonitoringEffects]),
    TranslocoModule,
    LayoutModule,
    ButtonModule,
    CoreModule,
    AuthModule,
    IconModule,
    TooltipModule,
    ColumnPickerModule,
    LoaderModule,
    ModalModule,
    UIModule,
    BadgeModule,
    EmptyDataMessageModule,
    MediaCardModule,
    WidgetModule,
    CheckboxModule,
    CountCardModule,
    SummaryCardModule,
    LayoutModule,
    SelectModule,
    CarouselModule,
    GeoModule,
    SkeletonModule,
    ChartCoreModule,
    PieModule,
    PercentDonutModule,
    DonutModule,
    CheckboxGroupModule,
    ReactiveFormsModule,
    FormFieldModule,
    ConnectorLineModule,
    ContextMenuModule,
    AsyncDataModule,
    EmptyDataMessageModule,
    ConnectedElementModule,
    SwitchModule,
    ActionBarModule,
    AccordionModule,
    DevicesModule,
    FeatureToggleModule,
    SectionBreadcrumbComponent,
  ],
  declarations: [
    SiteMonitoringColumnsEditorComponent,
    SiteMonitoringColumnsEditorModalComponent,
    SiteFieldTemplatesComponent,
    SiteMonitoringKeyMetricsEditorComponent,
    SiteMonitoringKeyMetricsEditorModalComponent,
    SiteMonitoringDetailComponent,
    SiteMonitoringDetailContainerComponent,
    SiteMonitoringBoardComponent,
    SiteMonitoringDisplayComponent,
    SiteMonitoringScreenshotComponent,
    SiteScreenshotModalComponent,
    SiteMonitoringKeyMetricsViewerComponent,
    KeyMetricsHealthSummaryComponent,
    KeyMetricsContentAccuracyComponent,
    KeyMetricsServicesComponent,
    KeyMetricsCodComponent,
    SiteMonitoringSectionComponent,
    KeyMetricsStatusCardComponent,
    KeyMetricsServicesSettingsComponent,
    KeyMetricsOptimisticSettingsComponent,
    SiteGlobalConfigConfirmationComponent,
    AggregationPipe,
    ColumnThresholdThemePipe,
    RemoveZeroCountPipe,
    SortCountByKeyPipe,
    DevicePropertiesStatusPipe,
    StatusRatioPipe,
    SiteAddressPipe,
    OptimisticViewPipe,
    StatusSeverityPipe,
    SiteTitlePipe,
    RemoveKeyMetricsNotAvailablePipe,
    DatavizPieDataItemsPipe,
    BoardAlarmPipe,
    KeyMetricsPosComponent,
    KeyMetricsDiskComponent,
    SitePreviewComponent,
    SiteMonitoringHealthIndicatorComponent,
    SiteHealthOverviewComponent,
    KeyMetricsStatusCardComponent,
    SiteMonitoringSiteMapComponent,
    SiteContentStatusComponent,
    SiteMonitoringDeviceComponent,
    BoardAlarmModalComponent,
    BoardAlarmsComponent,
    SiteDeviceActionsComponent,
    KeyMetricsAccordionComponent,
    SiteAlarmsComponent,
    KeyMetricsHideOkSettingsComponent,
    KeyMetricsShowTooltipSettingsComponent,
    KeyMetricsEnclosureStatusSettingsComponent,
    KeyMetricsEnclosureStatusComponent,
    SiteHealthMonitorComponent,
    SitesHealthMonitorComponent,
  ],
  exports: [
    SiteMonitoringColumnsEditorComponent,
    SiteMonitoringColumnsEditorModalComponent,
    SiteFieldTemplatesComponent,
    SiteMonitoringKeyMetricsEditorComponent,
    SiteMonitoringKeyMetricsEditorModalComponent,
    SiteMonitoringDetailComponent,
    SiteMonitoringDetailContainerComponent,
    SiteMonitoringBoardComponent,
    SiteMonitoringDisplayComponent,
    SiteMonitoringScreenshotComponent,
    SiteScreenshotModalComponent,
    SiteMonitoringKeyMetricsViewerComponent,
    KeyMetricsHealthSummaryComponent,
    KeyMetricsContentAccuracyComponent,
    KeyMetricsServicesComponent,
    KeyMetricsCodComponent,
    AggregationPipe,
    ColumnThresholdThemePipe,
    RemoveZeroCountPipe,
    SortCountByKeyPipe,
    DevicePropertiesStatusPipe,
    StatusRatioPipe,
    SiteAddressPipe,
    OptimisticViewPipe,
    StatusSeverityPipe,
    SiteTitlePipe,
    RemoveKeyMetricsNotAvailablePipe,
    DatavizPieDataItemsPipe,
    KeyMetricsPosComponent,
    KeyMetricsDiskComponent,
    SitePreviewComponent,
    SiteHealthOverviewComponent,
    SiteMonitoringSiteMapComponent,
    KeyMetricsServicesSettingsComponent,
    KeyMetricsOptimisticSettingsComponent,
    SiteContentStatusComponent,
    SiteGlobalConfigConfirmationComponent,
    BoardAlarmModalComponent,
    BoardAlarmsComponent,
    SiteDeviceActionsComponent,
    SiteAlarmsComponent,
    KeyMetricsEnclosureStatusComponent,
    SiteHealthMonitorComponent,
    SitesHealthMonitorComponent,
  ],
  providers: [{ provide: TRANSLOCO_SCOPE, useValue: 'site-monitoring-shared-scope', multi: true }],
})
export class SiteMonitoringSharedModule {}
