import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component, Inject,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild
} from '@angular/core';
import {IDataTableColumnConfig, IDataTableDataSource, IModalComponent, ModalRef} from '@activia/ngx-components';
import {Observable, Subject} from 'rxjs';
import {DOCUMENT} from '@angular/common';
import {TranslocoService} from '@ngneat/transloco';
import {DevicesFacade} from '../../../store/devices.facade';
import {map} from 'rxjs/operators';
import {DateTime} from 'luxon';
import {downloadData} from '@amp/utils/common';
import {
  DownloadLogsTaskIcons,
  DownloadLogsTaskThemes,
  IDownloadLogsTask
} from '../../../model/download-logs-task.interface';

@Component({
  selector: 'amp-download-logs-tasks-summary-modal',
  templateUrl: './download-logs-tasks-summary-modal.component.html',
  styleUrls: ['./download-logs-tasks-summary-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DownloadLogsTasksSummaryModalComponent implements OnInit, OnDestroy, AfterViewInit, IModalComponent {
  @ViewChild('statusTemplate', { static: true }) statusTemplate: TemplateRef<any>;
  @ViewChild('actionsTemplate', { static: true }) actionsTemplate: TemplateRef<any>;
  @ViewChild('messageTemplate', { static: true }) messageTemplate: TemplateRef<any>;

  columns: IDataTableColumnConfig<void>[];
  columns$: Observable<IDataTableColumnConfig<void>[]>;
  dataSource$: Observable<IDataTableDataSource<IDownloadLogsTask>>;

  DownloadLogsTaskIcons = DownloadLogsTaskIcons;
  DownloadLogsTaskThemes = DownloadLogsTaskThemes;

  private _componentDestroyed$: Subject<void> = new Subject<void>();

  constructor(
    @Inject(DOCUMENT) private _document: Document,
    private _translocoService: TranslocoService,
    private _devicesFacade: DevicesFacade,
    private _dialogRef: ModalRef<DownloadLogsTasksSummaryModalComponent>,
  ) { }

  ngOnInit() {
    this.dataSource$ = this._devicesFacade.downloadLogsTasks$.pipe(
      map((tasks) => ({
        rows: tasks,
        totalRowCount: tasks.length,
      }))
    );
  }

  ngAfterViewInit() {
    this.columns = this._setColumns();
  }

  /** @ignore **/
  ngOnDestroy(): void {
    this._componentDestroyed$.next();
    this._componentDestroyed$.complete();
  }

  canClose(): boolean {
    return true;
  }

  onClose() {
    this._dialogRef.close();
  }

  download(row: IDownloadLogsTask) {
    const contentType = 'text/plain';
    const filename = `${row.hostname}_${DateTime.now().toFormat('yyyyMMdd-HHmmss-SSS')}.zip`;
    downloadData(this._document, filename, row.logsData, contentType);
  }

  private _setColumns(): IDataTableColumnConfig<void>[] {
    const columns: IDataTableColumnConfig<void>[] = [
      {
        id: 'deviceId',
        name: this._translocoService.selectTranslate('deviceFields.DEVICE.DOWNLOAD_LOGS.SUMMARY_MODAL.COLUMNS.ID_50'),
        prop: 'deviceId',
        sortable: false,
        resizable: false,
        draggable: false,
        widthPx: 100,
      },
      {
        id: 'hostname',
        name: this._translocoService.selectTranslate('deviceFields.DEVICE.DOWNLOAD_LOGS.SUMMARY_MODAL.COLUMNS.HOSTNAME_50'),
        prop: 'hostname',
        sortable: false,
        resizable: false,
        draggable: false,
        widthPx: 200,
      },
      {
        id: 'status',
        name: this._translocoService.selectTranslate('deviceFields.DEVICE.DOWNLOAD_LOGS.SUMMARY_MODAL.COLUMNS.STATUS_50'),
        dataCellTemplate: this.statusTemplate,
        sortable: false,
        resizable: false,
        draggable: false,
        widthPx: 150,
      },
      {
        id: 'actions',
        name: this._translocoService.selectTranslate('deviceFields.DEVICE.DOWNLOAD_LOGS.SUMMARY_MODAL.COLUMNS.DOWNLOAD_50'),
        dataCellTemplate: this.actionsTemplate,
        sortable: false,
        resizable: false,
        draggable: false,
        widthPx: 100,
      },
      {
        id: 'message',
        name: this._translocoService.selectTranslate('deviceFields.DEVICE.DOWNLOAD_LOGS.SUMMARY_MODAL.COLUMNS.ERROR_400'),
        dataCellTemplate: this.messageTemplate,
        sortable: false,
        resizable: false,
        draggable: false,
      },
    ];
    return columns;
  }
}
