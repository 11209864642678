import { IToastAction, IToastConfig, ThemeType, ToastService } from '@activia/ngx-components';
import { Injectable } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { of } from 'rxjs';
import { MessengerResponse } from './messenger-response.interface';
import { FeatureToggleService } from '@amp/feature-toggle';

@Injectable({ providedIn: 'root' })
export class MessengerNotificationService {
  constructor(private translate: TranslocoService, private toastService: ToastService, private featureToggleService: FeatureToggleService) {}

  showErrorMessage(messageKey: string, messageParamsInterpolate?: any, titleKey?: string, uniqueToast?: boolean): Promise<MessengerResponse> {
    return this.featureToggleService.isOn('toast.showErrorToast')
      ? this.showMessage(ThemeType.DANGER, messageKey, messageParamsInterpolate, titleKey, uniqueToast)
      : of({ toastRef: undefined, toast: undefined }).toPromise();
  }

  showInfoMessage(messageKey: string, messageParamsInterpolate?: any, titleKey?: string, uniqueToast?: boolean): Promise<MessengerResponse> {
    return this.featureToggleService.isOn('toast.showInfoToast')
      ? this.showMessage(ThemeType.INFO, messageKey, messageParamsInterpolate, titleKey, uniqueToast)
      : of({ toastRef: undefined, toast: undefined }).toPromise();
  }

  showSuccessMessage(messageKey: string, messageParamsInterpolate?: any, titleKey?: string, uniqueToast?: boolean): Promise<MessengerResponse> {
    return this.featureToggleService.isOn('toast.showSuccessToast')
      ? this.showMessage(ThemeType.SUCCESS, messageKey, messageParamsInterpolate, titleKey, uniqueToast)
      : of({ toastRef: undefined, toast: undefined }).toPromise();
  }

  /**
   * Similar to showErrorMessage except this function opens a toast with the action buttons defined in the token.
   * If there is no action button defined, it will open a standard error toast.
   *
   * @param actions List of IToastAction objects
   * @param toastTitle (Optional) i18n key for the error message title that will be displayed in the toast
   * @param toastMessage (Optional) i18n key for the error message body that will be displayed in the toast
   * @param toastMessageParams (Optional) Params for the i18n key of the error message body
   * @param uniqueToast (Optional) See the explanation for uniqueToast field in IToastConfig
   */
  showErrorMessageWithActions(actions: Array<IToastAction>, toastTitle?: string, toastMessage?: string, toastMessageParams?: any, uniqueToast?: boolean): Promise<MessengerResponse> {
    return this.featureToggleService.isOn('toast.showErrorToast')
      ? this.showMessage(ThemeType.DANGER, toastMessage, toastMessageParams, toastTitle, uniqueToast, 'alert:error', actions)
      : of({ toastRef: undefined, toast: undefined }).toPromise();
  }

  private showMessage(
    theme: ThemeType,
    messageKey: string,
    messageParamsInterpolate?: any,
    titleKey?: string,
    uniqueToast?: boolean,
    icon?: string,
    actions?: Array<IToastAction>
  ): Promise<MessengerResponse> {
    const toastConfig = {
      actions,
      icon,
      title: titleKey ? this.translate.translate(titleKey) : undefined,
      body: messageKey ? this.translate.translate(messageKey, messageParamsInterpolate) : undefined,
      theme,
      showUniqueToast: !!uniqueToast,
    } as IToastConfig<any>;
    return this.toastService.openStandardToast(toastConfig);
  }
}
