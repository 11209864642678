import { IModalComponent, IModalConfig, ModalRef, MODAL_CONFIG } from '@activia/ngx-components';
import { EngineTagLevel, IEngineTagKey, TagAdapterService, convertToEngineTagKey } from '@amp/tag-operation';
import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { IModalPickerComponent } from '../../../models/modal-picker-component.interface';
import { BoardTagsService, SiteTagsService } from '@activia/cm-api';
import { Observable, map } from 'rxjs';

@Component({
  selector: 'amp-orgpath-add-tag-modal',
  templateUrl: './orgpath-add-tag-modal.component.html',
  styleUrls: ['./orgpath-add-tag-modal.component.scss'],
})
export class OrgpathAddTagModalComponent implements OnInit, IModalComponent, IModalPickerComponent<IEngineTagKey> {
  @Output() saved = new EventEmitter<IEngineTagKey>();
  @Output() cancel = new EventEmitter();

  tagLevel: EngineTagLevel;

  keyList$: Observable<IEngineTagKey[]>;

  constructor(
    private _dialogRef: ModalRef<OrgpathAddTagModalComponent>,
    private siteTagsService: SiteTagsService,
    private boardTagsService: BoardTagsService,
    private tagAdapterService: TagAdapterService,
    @Inject(MODAL_CONFIG) public dialogConfig: IModalConfig<void>
  ) {}

  ngOnInit(): void {
    /** Fetch a list of all existing keys */
    const getTagKeys$ = this.tagLevel === EngineTagLevel.SITE ? this.siteTagsService.findAllTagKeys2() : this.boardTagsService.findAllTagKeys();

    this.keyList$ = getTagKeys$.pipe(
      map((tags) => this.tagAdapterService.normalizeIncomingTagDescriptions(tags)),
      map((tags) => convertToEngineTagKey(tags, this.tagLevel))
    );
  }

  canClose(): boolean {
    return true;
  }

  onClose() {
    this._dialogRef.close();
  }

  onSave(newTag: IEngineTagKey) {
    this.saved.emit(newTag);
    this.onClose();
  }
}
