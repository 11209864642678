import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class ApiTokenService {
  private TOKEN_KEY = 'apiToken';

  setApiToken(token: string) {
    localStorage.setItem(this.TOKEN_KEY, token);
  }

  getApiToken() {
    return localStorage.getItem(this.TOKEN_KEY);
  }

  removeApiToken() {
    localStorage.removeItem(this.TOKEN_KEY);
  }
}
