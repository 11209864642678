import { ManagerDTO } from '@activia/cm-api';
import { dataOnceReady, IDataTableColumnConfig, IDataTableDataSource } from '@activia/ngx-components';
import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { siteManagementEntities } from '../../../../store/site-management.selectors';
import { ISiteManagementState } from '../../../../store/site-management.reducer';
import { Store } from '@ngrx/store';
import { map, Observable } from 'rxjs';
import { MessengerNotificationService } from '@amp/messenger';

@Component({
  selector: 'amp-site-management-managers',
  templateUrl: './site-management-managers.component.html',
  styleUrls: ['./site-management-managers.component.scss'],
})
export class SiteManagementManagersComponent implements OnInit {
  /** list of the sites to display */
  dataSource$: Observable<IDataTableDataSource<ManagerDTO>>;
  @ViewChild('nameTemplate', { static: true }) nameTemplate: TemplateRef<void>;
  @ViewChild('emailTemplate', { static: true }) emailTemplate: TemplateRef<void>;

  /** The data table columns configuration **/
  columns: IDataTableColumnConfig<void>[];

  constructor(private _messenger: MessengerNotificationService, private _translateService: TranslocoService, private _store: Store<ISiteManagementState>) {}

  ngOnInit() {
    const site$ = dataOnceReady(this._store.pipe(siteManagementEntities.currSiteData$), this._store.pipe(siteManagementEntities.currSiteDataState$));

    this.columns = [
      {
        id: 'NAME',
        name: this._translateService.translate('siteManagementScope.SITE_MANAGEMENT.SITE_DETAIL.SITE_PROPERTIES_EDITOR.MANAGERS.NAME_15'),
        dataCellTemplate: this.nameTemplate,
        sortable: false,
        resizable: true,
        draggable: false,
      },
      {
        id: 'EMAIL',
        name: this._translateService.translate('siteManagementScope.SITE_MANAGEMENT.SITE_DETAIL.SITE_PROPERTIES_EDITOR.MANAGERS.EMAIL_15'),
        dataCellTemplate: this.emailTemplate,
        sortable: false,
        resizable: true,
        draggable: false,
      },
    ];

    this.dataSource$ = site$.pipe(
      map((site) => ({
        rows: site?.managers || [],
        totalRowCount: site?.managers?.length || 0,
      }))
    );
  }

  copyToClipboard(text: string) {
    navigator.clipboard.writeText(text).then(() => this._messenger.showInfoMessage('siteManagementScope.SITE_MANAGEMENT.SITE_DETAIL.SITE_PROPERTIES_EDITOR.MANAGERS.COPY.EMAIL_COPIED'));
  }
}
