<h5 class="sidebar-nav-title" [hidden]="(sideNavOpened$ | async) === false">{{ 'layout.sidenav.deviceStatus.header' | transloco }}<a class="action-link" href="#"></a></h5>
<div
  class="badge-container"
  [routerLink]="[
    'legacy-module',
    {
      module: 'QuickSearchDevices',
      title: 'TITLE.LEGACY.DEVICE_OK',
      methodToCall: 'quickSearch',
      requestId: 1,
      context: 'std_ctxt',
      qSearch: 'Health Status=0'
    }
  ]"
>
  <avn-badge [label]="(count$ | async)?.ok | count" avnTheme="success" badgeSize="small"></avn-badge>
  <p>{{ 'deviceFields.DEVICE.ENUM.HEALTH_STATUS.OK' | transloco }}</p>
</div>
<div
  class="badge-container"
  [routerLink]="[
    'legacy-module',
    {
      module: 'QuickSearchDevices',
      title: 'TITLE.LEGACY.DEVICE_IN_ERROR',
      methodToCall: 'quickSearch',
      requestId: 1,
      context: 'std_ctxt',
      qSearch: 'Health Status=2'
    }
  ]"
>
  <avn-badge [label]="(count$ | async)?.error | count" avnTheme="danger" badgeSize="small"></avn-badge>
  <p>{{ 'deviceFields.DEVICE.ENUM.HEALTH_STATUS.ERROR' | transloco }}</p>
</div>
<div
  class="badge-container"
  [routerLink]="[
    'legacy-module',
    {
      module: 'QuickSearchDevices',
      title: 'TITLE.LEGACY.DEVICE_IN_WARNING',
      methodToCall: 'quickSearch',
      requestId: 1,
      context: 'std_ctxt',
      qSearch: 'Health Status=1'
    }
  ]"
>
  <avn-badge [label]="(count$ | async)?.warning | count" avnTheme="warning" badgeSize="small"></avn-badge>
  <p>{{ 'deviceFields.DEVICE.ENUM.HEALTH_STATUS.WARNING' | transloco }}</p>
</div>
<div
  class="badge-container"
  [routerLink]="[
    'legacy-module',
    {
      module: 'QuickSearchDevices',
      title: 'TITLE.LEGACY.DEVICE_UNREACHABLE',
      methodToCall: 'quickSearch',
      requestId: 1,
      context: 'std_ctxt',
      qSearch: 'Health Status=3'
    }
  ]"
>
  <avn-badge [label]="(count$ | async)?.unreachable | count" avnTheme="secondary" badgeSize="small"></avn-badge>
  <p>{{ 'deviceFields.DEVICE.ENUM.HEALTH_STATUS.UNREACHABLE' | transloco }}</p>
</div>
<div
  class="badge-container"
  [routerLink]="[
    'legacy-module',
    {
      module: 'QuickSearchDevices',
      title: 'TITLE.LEGACY.DEVICE_NOT_MONITORED',
      methodToCall: 'quickSearch',
      requestId: 1,
      context: 'std_ctxt',
      qSearch: 'Health Status=4'
    }
  ]"
>
  <avn-badge [label]="(count$ | async)?.notMonitored | count" avnTheme="info" badgeSize="small"></avn-badge>
  <p>{{ 'deviceFields.DEVICE.ENUM.HEALTH_STATUS.NOT_MONITORED' | transloco }}</p>
</div>
