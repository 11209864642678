import { TranslocoService } from '@ngneat/transloco';
import { AsyncDataState, hasAsyncDataError, LoadingState } from '@activia/ngx-components';

/**
 * Returns a feedback text message showing the progress of the board creation.
 * For now we only show a global message for each board (e.g. creating board #1)
 * It could be improved to show more details for each step of the process (eg. creating board#1 devices, attaching devices to site..., etc..)
 *
 * @param stepId the current step in the board creation process (see createExperienceTemplatesRequestChain for list of steps)
 * @param loadingState current loading state of the step
 */
export const getCreateBoardMessage = (stepId: string, loadingState: AsyncDataState, translateService: TranslocoService): string => {
  const [, stepInfo] = stepId.split('|');
  const [experienceIndex, boardIdx] = stepInfo.split('-');

  const boardNumber = +experienceIndex * 3 + +boardIdx + 1;
  if (loadingState === LoadingState.LOADING) {
    return translateService.translate('siteManagementScope.SITE_MANAGEMENT.SYNC_SITES.SYNC_MESSAGES.CREATE_BOARD.LOADING_50', { boardNumber });
  }
  if (loadingState === LoadingState.LOADED) {
    return translateService.translate('siteManagementScope.SITE_MANAGEMENT.SYNC_SITES.SYNC_MESSAGES.CREATE_BOARD.LOADED_50', { boardNumber });
  }
  if (hasAsyncDataError(loadingState)) {
    return translateService.translate('siteManagementScope.SITE_MANAGEMENT.SYNC_SITES.SYNC_MESSAGES.CREATE_BOARD.ERRORS.CREATE_FAIL_100', { boardNumber });
  }
};
