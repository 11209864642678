import { UnparseConfig } from './../csv/interfaces/unparse-config';

export const DEFAULT_PAPAPARSE_CONFIG: Pick<UnparseConfig, 'quotes' | 'quoteChar' | 'delimiter' | 'escapeChar'> = {
  // wrap values with quotes because values with commas inside get quoted anyway, so let do it for all fields for consistency
  // https://stackoverflow.com/questions/53674251/papa-parse-with-backslash-escaping
  quotes: true,
  // separate values with commas
  delimiter: ',',
  // character to wrap the values
  quoteChar: '"',
  // The character used to escape the quoteChar inside field values
  escapeChar: '\\' as any,
};
