import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { ITaskPanelItemComponent } from '@activia/ngx-components';
import { BehaviorSubject, Observable } from 'rxjs';
import { ISiteManagementConfig, SITE_MANAGEMENT_MODULE_CONFIG } from '@amp/environment';
import { RouterFacade } from '@amp/router-store';
import { Store } from '@ngrx/store';
import { siteSyncSelectors } from '../../store/site-sync/site-sync.selectors';
import { filter, map, tap, withLatestFrom } from 'rxjs/operators';
import { SiteSyncStatusIcons, SiteSyncStatusThemes } from '../../models/site-management-sync';

/**
 * This component is responsible to display feedback about csv site sync in the task panel
 */
@Component({
  selector: 'amp-site-sync-task-panel-item',
  templateUrl: './site-sync-task-panel-item.component.html',
  styleUrls: ['./site-sync-task-panel-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SiteSyncTaskPanelItemComponent implements ITaskPanelItemComponent {
  /** Emits when all tasks are completed */
  completed$ = new BehaviorSubject(false);
  model$: Observable<{
    skippedCount: number;
    warningCount: number;
    errorCount: number;
    processedCount: number;
    completedCount: number; // unchanged + processed
    totalCount: number;
    completePercentage: number;
    taskCompleted: boolean;
  }>;

  SiteSyncStatusIcons = SiteSyncStatusIcons;
  SiteSyncStatusThemes = SiteSyncStatusThemes;

  constructor(@Inject(SITE_MANAGEMENT_MODULE_CONFIG) private _siteManagementConfig: ISiteManagementConfig, private _routerFacade: RouterFacade, private _store: Store) {
    const sitesSyncState$ = this._store.select(siteSyncSelectors.sitesSyncState);
    const globalSyncStatus$ = this._store.select(siteSyncSelectors.status);

    this.model$ = sitesSyncState$.pipe(
      withLatestFrom(globalSyncStatus$),
      // only update the counts once the csv process starts
      filter(([sitesMap, globalSyncStatus]) => !!sitesMap && (globalSyncStatus === 'in-progress' || globalSyncStatus === 'finished')),
      map(([sitesMap, _]) => {
        const counts = Object.values(sitesMap).reduce(
          (acc, siteSyncState) => {
            const skipped = ['unchanged', 'invalid-id'].includes(siteSyncState.validationStatus);
            if (skipped) {
              acc.skippedCount++;
            } else if (siteSyncState.syncProgress?.status === 'error') {
              acc.errorCount++;
            } else if (siteSyncState.syncProgress?.status === 'warning') {
              acc.warningCount++;
            } else if (siteSyncState.syncProgress?.status === 'processed') {
              acc.processedCount++;
            }
            return acc;
          },
          {
            skippedCount: 0,
            warningCount: 0,
            errorCount: 0,
            processedCount: 0,
          }
        );

        const totalCount = Object.keys(sitesMap).length;
        const completedCount = counts.processedCount + counts.skippedCount;
        const completePercentage = (completedCount / totalCount) * 100;
        return {
          ...counts,
          completedCount,
          totalCount,
          completePercentage,
          taskCompleted: completePercentage === 100,
        };
      }),
      tap(({ taskCompleted }) => {
        this.completed$.next(taskCompleted);
      })
    );
  }

  showImportSummary() {
    this._routerFacade.navigate({ path: [...this._siteManagementConfig.moduleBasePath, 'sync'] });
  }
}
