import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges
} from '@angular/core';
import { CountAggregationData } from '../../model/site-monitoring-data.interface';
import { SiteMonitoredValue } from '../../model/site-monitored-value.enum';
import { HealthStatusCode } from '@amp/devices';
import { ApiRequestState, AsyncDataService, AsyncDataState } from '@activia/ngx-components';
import {
  BehaviorSubject,
  combineLatest,
  distinctUntilChanged,
  filter, map,
  Observable, ReplaySubject, share,
  Subject,
  withLatestFrom
} from 'rxjs';
import { TranslocoService } from '@ngneat/transloco';
import { switchMap } from 'rxjs/operators';
import { SitesService, SiteStatesDTO } from '@activia/cm-api';
import { convertSiteHealthToPieData, ISiteHealthPieDatum } from '../../utils/site-health-monitor.utils';

@Component({
  selector: 'amp-sites-health-monitor',
  templateUrl: './sites-health-monitor.component.html',
  styleUrls: ['./sites-health-monitor.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SitesHealthMonitorComponent implements OnChanges, OnDestroy {

  @Input() siteIds: number[];

  /**
   * In DMB, when optimisticView is true, values of all status, except error, are flattened to greyed out status
   * (will put to OK status). When optimisticView is false, only warning and error are visible in the chart,
   * values from all other status besides these 2 are flattened to greyed out status (will put to OK status).
   */
  @Input() optimisticViewEnabled = false;

  /** Dimension of the chart, this value will be used as height and width */
  @Input() heightInPx: number;

  /**
   * Emit the health status and count among the sites. The values are optimized.
   * The emitted value will not have UNREACHABLE and NOT_MONITORED status, the values of these 2 status are
   * flattened to OK state. If optimisticViewEnabled is set to true, the emitted value will not have WARNING as
   * the value for WARNING is also flattened to OK.
   * E.g.
   * { 0: 1, 2: 2 } // 1 Ok, 2 Error
   * { 0: 1, 1: 3, 2: 2 } // 1 Ok, 3 Warning, 2 Error
   */
  @Output() metricsState: EventEmitter<{ data: CountAggregationData<HealthStatusCode, number>; dataState: AsyncDataState }> = new EventEmitter();

  pieDataItems$: Observable<ISiteHealthPieDatum[]>;

  /**
   * The datum which the value would be displayed in the center of the chart by default.
   * By default it would be the value of the highest error level: error, or warning if no error.
   */
  displayedPieDatum$: Observable<ISiteHealthPieDatum>;

  apiRequestState = new ApiRequestState();

  private _siteIdsSub: BehaviorSubject<number[]> = new BehaviorSubject<number[]>(null);
  private _optimisticViewEnabledSub: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(this.optimisticViewEnabled);

  private _componentDestroyed$: Subject<void> = new Subject<void>();

  constructor(
    private _translocoService: TranslocoService,
    private _sitesService: SitesService,
    private _asyncDataService: AsyncDataService,
  ) {
    const siteStates$: Observable<number[]> = this._siteIdsSub.pipe(
      filter((siteIds) => !!siteIds),
      distinctUntilChanged(),
      switchMap((siteIds) => this._asyncDataService.doRequestWithState$<SiteStatesDTO[]>(
        this._sitesService.findSiteStates(SiteMonitoredValue.HealthStatus),
        this.apiRequestState,
        null,
        []
      ).pipe(
        map((resp) =>
           this.getSitesStates(resp || [], siteIds)
        )
      )),
    );

    this.pieDataItems$ = combineLatest([siteStates$, this._optimisticViewEnabledSub]).pipe(
      withLatestFrom(this.apiRequestState.dataState$),
      map(([[sitesStates, optimisticView], dataState]) => {
        const statusMap = this.optimizeSitesStates(sitesStates, optimisticView);
        const pieData = convertSiteHealthToPieData(statusMap, this._translocoService);
        this.metricsState.emit({ data: statusMap, dataState });
        return pieData;
      }),
      share({
        connector: () => new ReplaySubject(1),
        resetOnRefCountZero: true,
        resetOnComplete: false,
        resetOnError: false,
      }),
    );

    // Get the datum which the value will be displayed in the center of the chart by default
    // Data are already sorted by level, from error - warning - ok, hence just take the 1st datum
    this.displayedPieDatum$ = this.pieDataItems$.pipe(
      map((pieDataItems) => pieDataItems[0]),
    );
  }

  ngOnChanges({ siteIds, optimisticViewEnabled }: SimpleChanges) {
    if (siteIds && siteIds.currentValue) {
      this._siteIdsSub.next(this.siteIds);
    }

    if (optimisticViewEnabled) {
      this._optimisticViewEnabledSub.next(this.optimisticViewEnabled);
    }
  }

  ngOnDestroy() {
    this._componentDestroyed$.next();
    this._componentDestroyed$.complete();
  }

  /**
   * Get the highest level of health status of each sites in the list.
   * Error is the highest level, followed by warning, all other status are considered to be ok
   */
  getSitesStates(resp: SiteStatesDTO[], siteIds: number[]): (HealthStatusCode.OK | HealthStatusCode.WARNING | HealthStatusCode.ERROR)[] {
    const sitesStates = resp.filter((siteState) => siteIds.includes(siteState.siteId))
      .map((siteState) => {
        const states = siteState.distinctStates;
        // This chart shows the overall health states of a customer
        // Hence for each site this customer has, we just want to know the highest level of health status of this site
        // Mark this site as error if error code is found in distinctStates
        // Or mark this site as warning if no error code but warning code is found
        // All other status are considered to be ok
        return states.includes(HealthStatusCode.ERROR.toString()) ? HealthStatusCode.ERROR :
          states.includes(HealthStatusCode.WARNING.toString()) ? HealthStatusCode.WARNING : HealthStatusCode.OK;
      });
    return sitesStates;
  }

  /**
   * Optimize the health status of sites if applicable. When optimisticView is set to true, warning is
   * considered to be ok.
   */
  optimizeSitesStates(sitesStates: HealthStatusCode[], optimisticView: boolean): CountAggregationData<HealthStatusCode, number> {
    const statusMap = sitesStates.reduce((res, siteState) => {
      const state = optimisticView && siteState === HealthStatusCode.WARNING ? HealthStatusCode.OK : siteState;
      return {
        ...res,
        [state]: (res[state] || 0) + 1,
      };
    }, {});
    return statusMap;
  }
}
