import { ChangeDetectionStrategy, Component, OnInit, ViewChild } from '@angular/core';
import { ISaveBeforeExit } from '../../guards/save-before-exit.guard';
import { filter, map, Observable } from 'rxjs';
import { EngineTagLevel, EngineTagValueDetailComponent, TagValueAssignmentScope } from '@amp/tag-operation';
import { SiteDTO } from '@activia/cm-api';
import { siteManagementEntities } from '../../store/site-management.selectors';
import { Store } from '@ngrx/store';
import { ISiteManagementState } from '../../store/site-management.reducer';
import { dataOnceReady } from '@activia/ngx-components';
import { SiteManagementService } from '../../services/site-management.service';

@Component({
  selector: 'amp-site-tags',
  templateUrl: './site-tags.component.html',
  styleUrls: ['./site-tags.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SiteTagsComponent implements OnInit, ISaveBeforeExit {

  @ViewChild(EngineTagValueDetailComponent) tagEditorComponent: EngineTagValueDetailComponent;

  changeDetails: { hasUnsavedChanges: boolean; hasInvalidChanges?: boolean };

  scope$: Observable<TagValueAssignmentScope>;

  editable$: Observable<boolean>;

  constructor(private _siteStore: Store<ISiteManagementState>, private _siteManagementService: SiteManagementService) {
    this.editable$ = this._siteManagementService.hasAuthority$('tag');
  }

  ngOnInit(): void {
    this.scope$ = dataOnceReady(
      this._siteStore.pipe(siteManagementEntities.currSiteData$),
      this._siteStore.pipe(siteManagementEntities.currSiteDataState$),
    ).pipe(
      filter((site) => !!site),
      map((site: SiteDTO) => ({
        entityName: site?.name,
        id: site?.id,
        ids: [],
        level: EngineTagLevel.SITE,
        siteLabel: site.externalId,
      }))
    );
  }

  getChangeDetails(): { hasUnsavedChanges: boolean; hasInvalidChanges?: boolean } {
    return this.changeDetails;
  }

  save(): Observable<boolean> {
    return this.tagEditorComponent.onPushChanges();
  }
}
