import { ChangeDetectionStrategy, Component, OnDestroy } from '@angular/core';
import { ITaskPanelItemComponent } from '@activia/ngx-components';
import { BehaviorSubject, mergeMap, Observable, of, pairwise, ReplaySubject, scan, Subject, takeUntil } from 'rxjs';
import { Store } from '@ngrx/store';
import { catchError, map, mapTo, startWith, tap } from 'rxjs/operators';
import { SitesService } from '@activia/cm-api';
import { SiteSyncStatusIcons, SiteSyncStatusThemes } from '../../models/site-management-sync';
import { siteManagementSelectors } from '../../store/site-management.selectors';
import { difference } from 'lodash/array';
import { ClearDeletedSites, DeleteSiteSuccess } from '../../store/site-management.actions';

const MAX_CONCURRENT_DELETE_SITE_REQUESTS = 50;
/**
 * This task panel component is responsible of:
 * - deleting sites in queue
 * - display feedback about the deletion of sites .
 *
 * Logic is as follows:
 * - Deleted site gets their ids added to the ngrx store in 'deletedSiteIds'.
 * - The component will watch the store and locally create a FIFO queue with n sites allowed to be deleted concurrently (update MAX_CONCURRENT_DELETE_SITE_REQUESTS to change the concurrency amount)
 * - As sites are deleted, the counts are updated.
 */
@Component({
  selector: 'amp-site-delete-task-panel-item',
  templateUrl: './site-delete-task-panel-item.component.html',
  styleUrls: ['./site-delete-task-panel-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SiteDeleteTaskPanelItemComponent implements ITaskPanelItemComponent, OnDestroy {
  /** Emits when all tasks are completed */
  completed$ = new BehaviorSubject(false);
  model$: Observable<{
    errorCount: number;
    successCount: number;
    totalCount: number;
    completePercentage: number;
    taskCompleted: boolean;
  }>;

  SiteSyncStatusIcons = SiteSyncStatusIcons;
  SiteSyncStatusThemes = SiteSyncStatusThemes;

  private _deleteSiteSubject = new ReplaySubject<{ siteId: number }>();
  private _componentDestroyed$: Subject<void> = new Subject<void>();

  constructor(private _sitesService: SitesService, private _store: Store) {
    // total site will increase as more sites are deleted
    let totalSites = 0;

    this._store
      .select(siteManagementSelectors.deleteSiteIds)
      .pipe(
        startWith([]),
        pairwise(),
        map(([previousDeleteSiteIds, currentDeleteSiteIds]) => {
          const newSitesToDelete = difference(currentDeleteSiteIds, previousDeleteSiteIds);
          newSitesToDelete.forEach((siteId) => {
            totalSites++;
            this._deleteSiteSubject.next({ siteId });
          });
        }),
        takeUntil(this._componentDestroyed$)
      )
      .subscribe();

    this.model$ = this._deleteSiteSubject.asObservable().pipe(
      mergeMap(
        ({ siteId }) =>
          this._sitesService.deleteSite(siteId).pipe(
            tap(() => this._store.dispatch(DeleteSiteSuccess({ siteId }))),
            mapTo({ success: true }),
            catchError((_) => of({ success: false }))
          ),
        MAX_CONCURRENT_DELETE_SITE_REQUESTS
      ),
      // accumulate counts as the sites are deleted ('scan' is the observable equivalent to 'reduce')
      scan(
        (counts, { success }) => {
          if (success) {
            counts.successCount++;
          } else {
            counts.errorCount++;
          }
          counts.totalCount = totalSites;
          counts.completePercentage = ((counts.successCount + counts.errorCount) / counts.totalCount) * 100;
          counts.taskCompleted = counts.completePercentage === 100;
          return counts;
        },
        {
          errorCount: 0,
          successCount: 0,
          totalCount: totalSites,
          completePercentage: 0,
          taskCompleted: false,
        }
      ),
      tap(({ taskCompleted }) => {
        this.completed$.next(taskCompleted);
      }),
      startWith({
        errorCount: 0,
        successCount: 0,
        totalCount: 0,
        completePercentage: 0,
        taskCompleted: true,
      })
    );
  }

  /** @ignore **/
  ngOnDestroy(): void {
    this._store.dispatch(ClearDeletedSites());
    this._componentDestroyed$.next();
    this._componentDestroyed$.complete();
  }
}
