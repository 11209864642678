import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { mapTo } from 'rxjs/operators';
import { SiteListService } from '../services/site-list.service';

@Injectable()
export class PreloadRefreshSitesGuard  {
  constructor(private _siteListService: SiteListService) {}

  canActivate(): Observable<boolean> {
    return this._siteListService.startTimer().pipe(mapTo(true));
  }
}
