<ng-container *ngIf="value !== null && value !== undefined">
  <!-- Display according to the display type -->
  <ng-container [ngSwitch]="displayType" *ngIf="displayType">
    <ng-container *ngSwitchCase="DisplayType.Raw">
      {{ value }}
    </ng-container>

    <ng-container *ngSwitchCase="DisplayType.Duration">
      {{ value ? (value | duration) : '' }}
    </ng-container>

    <ng-container *ngSwitchCase="DisplayType.DateTime">
      {{ value ? (value | date: 'medium') : '' }}
    </ng-container>

    <ng-container *ngSwitchCase="DisplayType.TimeAgo">
      {{ value | timeAgo | async }}
    </ng-container>

    <ng-container *ngSwitchCase="DisplayType.Percent">
      {{ value ? percentValidation(value) + '%' : '' }}
    </ng-container>

    <ng-container *ngSwitchCase="DisplayType.Boolean">
      {{ value ? 'true' : 'false' }}
    </ng-container>

    <ng-container *ngSwitchCase="DisplayType.Numeric">
      {{ value || '0' }}
    </ng-container>

    <ng-container *ngSwitchCase="DisplayType.TwoDecimals">
      {{ value ? value.toFixed(2) : '0.00' }}
    </ng-container>

    <ng-container *ngSwitchCase="DisplayType.Currency">
      {{ (value ? value : 0) | currency: 'CAD':'symbol-narrow':'4.2-2' }}
    </ng-container>

    <ng-container *ngSwitchCase="DisplayType.TwoLines">
      <ng-container *ngTemplateOutlet="twoLines"></ng-container>
    </ng-container>

    <ng-container *ngSwitchCase="DisplayType.HostnameIp">
      <span class="hostname-ip">
        <a *ngIf="(url || '').length > 0; else twoLines" [routerLink]="[url]" target="_blank"> {{ value.line1 }}<br />{{ value.line2 }} </a>
      </span>
    </ng-container>

    <ng-container *ngSwitchCase="DisplayType.RawWithTooltip">
      <div class="raw-tooltip" [avnTooltip]="value" [tooltipPlacement]="'top'">{{ value }}</div>
    </ng-container>

    <ng-container *ngSwitchCase="DisplayType.HealthStatus">
      <ng-container *ngTemplateOutlet="healthStatusIndicator"></ng-container>
    </ng-container>

    <ng-container *ngSwitchCase="DisplayType.HealthErrorIds">
      <ng-container *ngTemplateOutlet="healthErrorsIndicator"></ng-container>
    </ng-container>

    <ng-container *ngSwitchCase="DisplayType.Status">
      <ng-container *ngTemplateOutlet="statusIndicator"></ng-container>
    </ng-container>

    <ng-container *ngSwitchCase="DisplayType.ServiceStatus">
      <ng-container *ngTemplateOutlet="serviceStatusIndicator"></ng-container>
    </ng-container>

    <ng-container *ngSwitchCase="DisplayType.OperationalState">
      <ng-container *ngTemplateOutlet="operationalStateIndicator"></ng-container>
    </ng-container>

    <ng-container *ngSwitchCase="DisplayType.PercentChart">
      <ng-container *ngTemplateOutlet="percentageIndicator; context: { threshold: 50 }"></ng-container>
    </ng-container>

    <ng-container *ngSwitchCase="DisplayType.PercentPieChart">
      <ng-container *ngTemplateOutlet="percentagePieIndicator"></ng-container>
    </ng-container>

    <ng-container *ngSwitchCase="DisplayType.PlayerVersion">
      <ng-container *ngTemplateOutlet="playerVersionIndicator"></ng-container>
    </ng-container>

    <ng-container *ngSwitchCase="DisplayType.ContentStatus">
      <ng-container *ngTemplateOutlet="contentStatusIndicator"></ng-container>
    </ng-container>

    <ng-container *ngSwitchCase="DisplayType.ContentStatusErrorIds">
      <ng-container *ngTemplateOutlet="contentStatusErrorIdsIndicator"></ng-container>
    </ng-container>

    <ng-container *ngSwitchCase="DisplayType.DevicePropertiesStatus">
      <ng-container *ngTemplateOutlet="devicePropertiesStatusIndicator"></ng-container>
    </ng-container>
  </ng-container>

  <!-- Display according to the column id -->
  <ng-container [ngSwitch]="field" *ngIf="!displayType">
    <ng-container *ngSwitchCase="MonitoredValue.Uptime">
      {{ value ? (value | duration) : '' }}
    </ng-container>

    <ng-container *ngSwitchCase="MonitoredValue.SystemDisk">
      <ng-container *ngTemplateOutlet="percentageIndicator; context: { threshold: 50 }"></ng-container>
    </ng-container>

    <ng-container *ngSwitchCase="MonitoredValue.CpuPercent">
      <ng-container *ngTemplateOutlet="percentageIndicator; context: { threshold: 50 }"></ng-container>
    </ng-container>

    <ng-container *ngSwitchCase="MonitoredValue.SystemMemory">
      <ng-container *ngTemplateOutlet="percentageIndicator; context: { threshold: 50 }"></ng-container>
    </ng-container>

    <ng-container *ngSwitchCase="MonitoredValue.OmnicastStatus">
      <ng-container *ngTemplateOutlet="statusIndicator"></ng-container>
    </ng-container>

    <ng-container *ngSwitchCase="MonitoredValue.HttpService">
      <ng-container *ngTemplateOutlet="statusIndicator"></ng-container>
    </ng-container>

    <ng-container *ngSwitchCase="MonitoredValue.FtpStatus">
      <ng-container *ngTemplateOutlet="statusIndicator"></ng-container>
    </ng-container>

    <ng-container *ngSwitchCase="MonitoredValue.ServicePlayer">
      <ng-container *ngTemplateOutlet="statusIndicator"></ng-container>
    </ng-container>

    <ng-container *ngSwitchCase="MonitoredValue.PlayerState">
      <ng-container *ngTemplateOutlet="statusIndicator"></ng-container>
    </ng-container>

    <ng-container *ngSwitchCase="MonitoredValue.ContentState">
      <ng-container *ngTemplateOutlet="statusIndicator"></ng-container>
    </ng-container>

    <ng-container *ngSwitchCase="MonitoredValue.PlayerVersion">
      <ng-container *ngTemplateOutlet="playerVersionIndicator"></ng-container>
    </ng-container>

    <ng-container *ngSwitchCase="MonitoredValue.HealthStatus">
      <ng-container *ngTemplateOutlet="healthStatusIndicator"></ng-container>
    </ng-container>

    <ng-container *ngSwitchCase="MonitoredValue.HealthErrorIds">
      <ng-container *ngTemplateOutlet="healthErrorsIndicator"></ng-container>
    </ng-container>

    <ng-container *ngSwitchCase="MonitoredValue.ProxyByteHitRatio">
      <ng-container *ngTemplateOutlet="percentageIndicator; context: { threshold: 50 }"></ng-container>
    </ng-container>

    <ng-container *ngSwitchCase="MonitoredValue.ProxyHitRatio">
      <ng-container *ngTemplateOutlet="percentageIndicator; context: { threshold: 50 }"></ng-container>
    </ng-container>

    <ng-container *ngSwitchCase="MonitoredValue.ProxyMemory">
      <ng-container *ngTemplateOutlet="percentageIndicator; context: { threshold: 50 }"></ng-container>
    </ng-container>

    <ng-container *ngSwitchCase="DeviceProperty.OperationalState">
      <ng-container *ngTemplateOutlet="operationalStateIndicator"></ng-container>
    </ng-container>

    <ng-container *ngSwitchDefault>
      <!-- Logical player state -->
      <ng-container *ngIf="isPlayerState(field); else defaultTemplate">
        <ng-container *ngTemplateOutlet="statusIndicator"></ng-container>
      </ng-container>

      <ng-template #defaultTemplate>
        {{ value }}
      </ng-template>
    </ng-container>
  </ng-container>
</ng-container>

<ng-template #twoLines>
  {{ value.line1 }}<br />{{ value.line2 }}
</ng-template>

<ng-template #percentageIndicator let-threshold="threshold">
  <div class="percentage-indicator" *ngIf="value !== undefined">
    <div class="gauge" [style.width.%]="percentValidation(value)" [avnThemify]="value < threshold ? 'success' : 'danger'"></div>
    <span>{{ value >= 0 ? value : 0 }}%</span>
  </div>
</ng-template>

<ng-template #percentagePieIndicator>
  <div class="percentage-pie-chart-indicator" *ngIf="value !== undefined">
    <avn-percent-donut
      [style.width.px]="'40'"
      [style.height.px]="'40'"
      [value]="percentValidation(value)"
      [valueSuffix]="'%'"
      [color]="!value || value <= 70 ? 'var(--success-base)' : value <= 85 ? 'var(--warning-base)' : 'var(--danger-base)'"
    >
    </avn-percent-donut>
  </div>
</ng-template>

<ng-template #statusIndicator>
  <avn-badge *ngIf="value !== undefined" [badgeSize]="badgeSize" [label]="!!value ? 'OK' : 'Fail'" [avnTheme]="!!value ? ThemeType.SUCCESS : ThemeType.DANGER"></avn-badge>
</ng-template>

<ng-template #serviceStatusIndicator>
  <avn-badge *ngIf="value !== undefined" [badgeSize]="badgeSize" [label]="!!value ? 'up' : 'down'" [avnTheme]="!!value ? ThemeType.SUCCESS : ThemeType.DANGER"></avn-badge>
</ng-template>

<ng-template #healthStatusIndicator>
  <ng-container [ngSwitch]="value">
    <ng-container *ngSwitchCase="HealthStatusCode.OK">
      <avn-badge [badgeSize]="badgeSize" [label]="'deviceFields.DEVICE.ENUM.HEALTH_STATUS.OK' | transloco" [avnTheme]="ThemeType.SUCCESS"></avn-badge>
    </ng-container>
    <ng-container *ngSwitchCase="HealthStatusCode.WARNING">
      <avn-badge [badgeSize]="badgeSize" [label]="'deviceFields.DEVICE.ENUM.HEALTH_STATUS.WARNING' | transloco" [avnTheme]="ThemeType.WARNING"></avn-badge>
    </ng-container>
    <ng-container *ngSwitchCase="HealthStatusCode.ERROR">
      <avn-badge [badgeSize]="badgeSize" [label]="'deviceFields.DEVICE.ENUM.HEALTH_STATUS.ERROR' | transloco" [avnTheme]="ThemeType.DANGER"></avn-badge>
    </ng-container>
    <ng-container *ngSwitchCase="HealthStatusCode.UNREACHABLE">
      <avn-badge [badgeSize]="badgeSize" [label]="'deviceFields.DEVICE.ENUM.HEALTH_STATUS.UNREACHABLE' | transloco" [avnTheme]="ThemeType.SECONDARY"></avn-badge>
    </ng-container>
    <ng-container *ngSwitchCase="HealthStatusCode.NOT_MONITORED">
      <avn-badge [badgeSize]="badgeSize" [label]="'deviceFields.DEVICE.ENUM.HEALTH_STATUS.NOT_MONITORED' | transloco" [avnTheme]="ThemeType.INFO"></avn-badge>
    </ng-container>
  </ng-container>
</ng-template>

<ng-template #healthErrorsIndicator>
  <ng-container *ngFor="let error of !!value ? value.split(',') : [value]; let i = index">
    <ng-container [ngSwitch]="error">
      <ng-container *ngSwitchCase="HealthErrorIds.PLAYER_SERVICE_DOWN">
        {{ 'deviceFields.DEVICE.ENUM.HEALTH_ERROR_IDS.PLAYER_SERVICE_DOWN' | transloco }}
      </ng-container>
      <ng-container *ngSwitchCase="HealthErrorIds.HTTP_SERVICE_DOWN">
        {{ 'deviceFields.DEVICE.ENUM.HEALTH_ERROR_IDS.HTTP_SERVICE_DOWN' | transloco }}
      </ng-container>
      <ng-container *ngSwitchCase="HealthErrorIds.OMNICAST_SERVICE_DOWN">
        {{ 'deviceFields.DEVICE.ENUM.HEALTH_ERROR_IDS.OMNICAST_SERVICE_DOWN' | transloco }}
      </ng-container>
      <ng-container *ngSwitchCase="HealthErrorIds.FTP_SERVICE_DOWN">
        {{ 'deviceFields.DEVICE.ENUM.HEALTH_ERROR_IDS.FTP_SERVICE_DOWN' | transloco }}
      </ng-container>
      <ng-container *ngSwitchCase="HealthErrorIds.MISSING_CONTENT">
        {{ 'deviceFields.DEVICE.ENUM.HEALTH_ERROR_IDS.MISSING_CONTENT' | transloco }}
      </ng-container>
      <ng-container *ngSwitchCase="HealthErrorIds.HD_ERROR">
        {{ 'deviceFields.DEVICE.ENUM.HEALTH_ERROR_IDS.HD_ERROR' | transloco }}
      </ng-container>
      <ng-container *ngSwitchCase="HealthErrorIds.FAN_ERROR">
        {{ 'deviceFields.DEVICE.ENUM.HEALTH_ERROR_IDS.FAN_ERROR' | transloco }}
      </ng-container>
      <ng-container *ngSwitchCase="HealthErrorIds.HIGH_PROCESSOR_TEMPERATURE">
        {{ 'deviceFields.DEVICE.ENUM.HEALTH_ERROR_IDS.HIGH_PROCESSOR_TEMPERATURE' | transloco }}
      </ng-container>
      <ng-container *ngSwitchCase="HealthErrorIds.HIGH_CPU">
        {{ 'deviceFields.DEVICE.ENUM.HEALTH_ERROR_IDS.HIGH_CPU' | transloco }}
      </ng-container>
      <ng-container *ngSwitchCase="HealthErrorIds.HIGH_MEMORY">
        {{ 'deviceFields.DEVICE.ENUM.HEALTH_ERROR_IDS.HIGH_MEMORY' | transloco }}
      </ng-container>
    </ng-container>
    <ng-container *ngIf="!!value && value.split(',').length > i + 1">, </ng-container>
  </ng-container>
</ng-template>

<ng-template #operationalStateIndicator>
  <ng-container [ngSwitch]="value">
    <ng-container *ngSwitchCase="OperationalState.Running">
      <avn-badge [badgeSize]="badgeSize" [label]="'deviceFields.DEVICE.ENUM.OPERATIONAL_STATE.' + (value | i18nFormattedValue) | transloco" [avnTheme]="ThemeType.SUCCESS"></avn-badge>
    </ng-container>
    <ng-container *ngSwitchCase="OperationalState.HardwareMaintenance">
      <avn-badge [badgeSize]="badgeSize" [label]="'deviceFields.DEVICE.ENUM.OPERATIONAL_STATE.' + (value | i18nFormattedValue) | transloco" [avnTheme]="ThemeType.WARNING"></avn-badge>
    </ng-container>
    <ng-container *ngSwitchCase="OperationalState.SoftwareMaintenance">
      <avn-badge [badgeSize]="badgeSize" [label]="'deviceFields.DEVICE.ENUM.OPERATIONAL_STATE.' + (value | i18nFormattedValue) | transloco" [avnTheme]="ThemeType.WARNING"></avn-badge>
    </ng-container>
    <ng-container *ngSwitchCase="OperationalState.Provisioned">
      <avn-badge [badgeSize]="badgeSize" [label]="'deviceFields.DEVICE.ENUM.OPERATIONAL_STATE.' + (value | i18nFormattedValue) | transloco" [avnTheme]="ThemeType.PRIMARY"></avn-badge>
    </ng-container>
    <ng-container *ngSwitchCase="OperationalState.Decommissioned">
      <avn-badge [badgeSize]="badgeSize" [label]="'deviceFields.DEVICE.ENUM.OPERATIONAL_STATE.' + (value | i18nFormattedValue) | transloco" [avnTheme]="ThemeType.DANGER"></avn-badge>
    </ng-container>
  </ng-container>
</ng-template>

<ng-template #playerVersionIndicator>
  <ng-container *ngIf="value !== undefined">
    <span *ngIf="!!value.description; else textOnly" [avnTooltip]="value.description" [tooltipPlacement]="'bottom'">{{ value.versionCode }}</span>
    <ng-template #textOnly>
      <span>{{ value.versionCode }}</span>
    </ng-template>
  </ng-container>
</ng-template>

<ng-template #contentStatusIndicator>
  <avn-badge [badgeSize]="badgeSize" [label]="'deviceFields.DEVICE.ENUM.CONTENT_STATUS.' + value | transloco" [avnTheme]="ContentStatusThemes[value]"></avn-badge>
</ng-template>

<ng-template #contentStatusErrorIdsIndicator>
  <ng-container *ngFor="let error of !!value ? value.split(',') : [value]; let i = index">
    <ng-container [ngSwitch]="error">
      <ng-container *ngSwitchCase="ContentStatusErrorIds.MISSING_CONTENT">
        <span>{{ 'deviceFields.DEVICE.ENUM.CONTENT_STATUS_ERROR_IDS.MISSING_CONTENT' | transloco }}</span>
      </ng-container>
    </ng-container>
    <ng-container *ngIf="!!value && value.split(',').length > i + 1">, </ng-container>
  </ng-container>
</ng-template>

<ng-template #devicePropertiesStatusIndicator>
  <avn-badge
    [badgeSize]="badgeSize"
    [label]="'deviceFields.DEVICE.ENUM.DEVICE_PROPERTIES_STATUS_COMBINED.' + DevicePropertiesStatusTheme.get(value) | transloco"
    [avnTheme]="DevicePropertiesStatusTheme.get(value)"
  >
  </avn-badge>
</ng-template>
