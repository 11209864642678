import { CoreModule } from '@activia/ngx-components';
import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { TranslocoModule } from '@ngneat/transloco';

@NgModule({
  imports: [CommonModule, TranslocoModule, CoreModule],
})
export class MessengerModule {
  static forRoot(config): ModuleWithProviders<MessengerModule> {
    return {
      ngModule: MessengerModule,
      providers: [config],
    };
  }
}
