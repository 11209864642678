import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Observable } from 'rxjs';
import { SiteDTO } from '@activia/cm-api';
import { RouterFacade } from '@amp/router-store';
import { map } from 'rxjs/operators';
import { SiteManagementBoardConfigPropertiesTab } from '../site-management-board-config-properties.constant';
import { dataOnceReady } from '@activia/ngx-components';
import { siteManagementEntities } from '../../../../store/site-management.selectors';
import { Store } from '@ngrx/store';
import * as DeviceSelectors from '../../../../store/device/device.selectors';
import { DeviceState } from '../../../../store/device/device.reducer';

@Component({
  selector: 'amp-site-management-board-config-properties',
  templateUrl: './site-management-board-config-properties.component.html',
  styleUrls: ['./site-management-board-config-properties.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SiteManagementBoardConfigPropertiesComponent {
  site$: Observable<SiteDTO>;

  selectedTabId$: Observable<SiteManagementBoardConfigPropertiesTab>;
  deviceCount$: Observable<number>;

  constructor(private _routerFacade: RouterFacade, private _store: Store<DeviceState>) {
    this.site$ = dataOnceReady(this._store.pipe(siteManagementEntities.currSiteData$), this._store.pipe(siteManagementEntities.currSiteDataState$));

    this.selectedTabId$ = this._routerFacade.currentRoute$.pipe(map((route) => route.queryParams.st));

    this.deviceCount$ = dataOnceReady(this._store.select(DeviceSelectors.selectAllDevices), this._store.select(DeviceSelectors.selectDeviceDataState)).pipe(map((siteDevices) => siteDevices.length));
  }

  /**
   * Update selected tab
   *
   * @param tab Selected tab
   */
  onTabChange(tab: SiteManagementBoardConfigPropertiesTab) {
    this._routerFacade.navigate({
      path: [],
      extras: {
        queryParams: { st: tab },
        queryParamsHandling: 'merge',
        replaceUrl: true,
      },
    });
  }
}
