<avn-data-table
  [columns]="columns"
  [dataSource]="dataSource$ | async"
  [rowHeight]="50"
  [keyboardNavigationEnabled]="true"
  [showHeader]="(dataSource$ | async)?.totalRowCount > 0"
  [infiniteScrollEnabled]="false"
>
  <avn-empty-data-message
    [icon]="'action:supervisor_account'"
    [text]="'siteManagementScope.SITE_MANAGEMENT.SITE_DETAIL.SITE_PROPERTIES_EDITOR.MANAGERS.NO_MANAGER_FOUND.TEXT_50' | transloco"
    [subText]="'siteManagementScope.SITE_MANAGEMENT.SITE_DETAIL.SITE_PROPERTIES_EDITOR.MANAGERS.NO_MANAGER_FOUND.SUBTEXT_100' | transloco"
  >
  </avn-empty-data-message>
</avn-data-table>

<ng-template #nameTemplate let-row="row">
  <span>{{ row.firstName }}, {{ row.lastName | uppercase }}</span>
</ng-template>

<ng-template #emailTemplate let-row="row">
  <div class="manager-email">
    <span>{{ row.email }}</span>
    <avn-icon
      icon="fa:copy"
      [fontSize]="14"
      [avnTooltip]="'siteManagementScope.SITE_MANAGEMENT.SITE_DETAIL.SITE_PROPERTIES_EDITOR.MANAGERS.COPY.TOOLTIP' | transloco"
      tooltipPlacement="top"
      (click)="copyToClipboard(row.email)"
    ></avn-icon>
  </div>
</ng-template>
