import { CommandStatusDTO } from '@activia/cm-api';
import { TranslocoService } from '@ngneat/transloco';
import { ThemeType } from '@activia/ngx-components';
import { CommandCodeEnum } from './command-code.enum';
import { CommandStatusStatusEnum } from './command-status-status.enum';

/**
 * Computed info about a command status
 */
export interface ICommandStatusInfo {
  commandDescription: string;
  commandStatusDescription: string;
  hasProgressInfo: boolean;
  progressPercentCompleted?: number;
  triggerInfo: string;
  deviceLeftInfo: string;
  isCompleted: boolean;
  title: string;
  toastBody: string;
  theme: ThemeType;
  statusText: string; // A statusText text describing the global statusText of all commands in the commands statusText (i.e. Cancelled (All Failed), Cancelled (Partially Succeeded), etc..)
}

/**
 * Returns computed info about a command status
 */
export const getCommandStatusInfo = (commandStatus: CommandStatusDTO, translate: TranslocoService): ICommandStatusInfo => {
  if (!commandStatus) {
    return null;
  }
  // when no command exists, default to upload logs
  const commandCodeExists = Object.values(CommandCodeEnum).some((code) => code === commandStatus.commandCode);
  const commandCode = commandCodeExists ? commandStatus.commandCode : CommandCodeEnum.UploadLogs;

  // get descriptions
  const commandDescription = translate.translate('NOTIFICATIONS.COMMAND_STATUS.NAME.' + commandCode);
  const commandStatusDescription = translate.translate('NOTIFICATIONS.COMMAND_STATUS.STATUS.' + commandStatus.status);

  // set the notification info data
  const hasProgressInfo = isCommandStatusWithProgress(commandStatus.status);
  const isCompleted = isCommandStatusCompleted(commandStatus.status);
  const title = `${commandDescription} ${commandStatus.parameter ? ' - ' + commandStatus.parameter : ''} ${commandStatus.targetName ? ' - ' + commandStatus.targetName : ''}`;
  let toastBody = translate.translate('NOTIFICATIONS.COMMAND_STATUS.BODY_COMMAND_CODE_300.PRE.' + commandCode, { user: commandStatus.username, deviceGroup: commandStatus.targetName });

  // set progress info if relevant
  let progressPercentCompleted: number;
  let deviceLeftInfo: string;
  if (hasProgressInfo) {
    // set progress related data
    progressPercentCompleted = parseInt((((commandStatus.ok + commandStatus.failed + commandStatus.other) / commandStatus.count) * 100).toFixed(0), 10);

    // add the device left count info if there are devices left to be processed
    const deviceLeftCount = commandStatus.count - commandStatus.failed - commandStatus.ok - commandStatus.other;
    if (deviceLeftCount > 0) {
      deviceLeftInfo = translate.translate('NOTIFICATIONS.COMMAND_STATUS.DEVICES_LEFT', { deviceCount: deviceLeftCount });
    }
  }

  let triggerInfo: string;
  if (commandStatus.username) {
    triggerInfo = translate.translate('NOTIFICATIONS.COMMAND_STATUS.TRIGGERED_BY', { trigger: commandStatus.username });
  }

  let theme: ThemeType;
  let statusText: string;

  // depending on the statusText, set the theme and add more details
  switch (commandStatus.status) {
    case CommandStatusStatusEnum.CANCELLED:
      if (commandStatus.ok === commandStatus.count) {
        toastBody += ` ${translate.translate('NOTIFICATIONS.COMMAND_STATUS.BODY_COMMAND_CODE_300.STATUS.' + commandStatus.status + '.ALL_SUCCESS', {
          total: commandStatus.count,
        })}`;
        statusText = translate.translate('NOTIFICATIONS.COMMAND_STATUS.STATUS.' + commandStatus.status + '_ALL_SUCCESS');
        theme = ThemeType.SUCCESS;
      } else if (commandStatus.failed >= commandStatus.count || commandStatus.other === commandStatus.count) {
        if (commandStatus.count === 1) {
          toastBody += ` ${translate.translate('NOTIFICATIONS.COMMAND_STATUS.BODY_COMMAND_CODE_300.STATUS.' + commandStatus.status + '.SINGLE_ONE_FAIL')}`;
        } else {
          toastBody += ` ${translate.translate('NOTIFICATIONS.COMMAND_STATUS.BODY_COMMAND_CODE_300.STATUS.' + commandStatus.status + '.ALL_FAIL', {
            total: commandStatus.count,
          })}`;
        }
        statusText = translate.translate('NOTIFICATIONS.COMMAND_STATUS.STATUS.3_ALL_FAIL');
        theme = ThemeType.DANGER;
      } else {
        toastBody += ` ${translate.translate('NOTIFICATIONS.COMMAND_STATUS.BODY_COMMAND_CODE_300.STATUS.' + commandStatus.status + '.PARTIAL', {
          errors: commandStatus.failed,
          total: commandStatus.count,
        })}`;
        statusText = translate.translate('NOTIFICATIONS.COMMAND_STATUS.STATUS.3_PARTIAL');
        theme = ThemeType.WARNING;
      }
      break;
    case CommandStatusStatusEnum.FAILED:
      toastBody += ` ${translate.translate('NOTIFICATIONS.COMMAND_STATUS.BODY_COMMAND_CODE_300.STATUS.' + commandStatus.status)}`;
      theme = ThemeType.DANGER;
      break;
    case CommandStatusStatusEnum.INPROGRESS:
      toastBody += ` ${translate.translate('NOTIFICATIONS.COMMAND_STATUS.BODY_COMMAND_CODE_300.STATUS.' + commandStatus.status)}`;
      theme = commandStatus.failed > 0 ? ThemeType.WARNING : ThemeType.INFO;
      break;
    case CommandStatusStatusEnum.FINISHED:
      if (commandStatus.ok === commandStatus.count) {
        toastBody += ` ${translate.translate('NOTIFICATIONS.COMMAND_STATUS.BODY_COMMAND_CODE_300.STATUS.' + commandStatus.status + '.ALL_SUCCESS', {
          total: commandStatus.count,
        })}`;
        statusText = translate.translate('NOTIFICATIONS.COMMAND_STATUS.STATUS.3_ALL_SUCCESS');
        theme = ThemeType.SUCCESS;
      } else if (commandStatus.ok === 0) {
        if (commandStatus.count === 1) {
          toastBody += ` ${translate.translate('NOTIFICATIONS.COMMAND_STATUS.BODY_COMMAND_CODE_300.STATUS.' + commandStatus.status + '.SINGLE_ONE_FAIL')}`;
        } else {
          toastBody += ` ${translate.translate('NOTIFICATIONS.COMMAND_STATUS.BODY_COMMAND_CODE_300.STATUS.' + commandStatus.status + '.ALL_FAIL', {
            total: commandStatus.count,
          })}`;
        }
        statusText = translate.translate('NOTIFICATIONS.COMMAND_STATUS.STATUS.3_ALL_FAIL');
        theme = ThemeType.DANGER;
      } else {
        toastBody += ` ${translate.translate('NOTIFICATIONS.COMMAND_STATUS.BODY_COMMAND_CODE_300.STATUS.' + commandStatus.status + '.PARTIAL', {
          errors: commandStatus.failed,
          total: commandStatus.count,
        })}`;
        statusText = translate.translate('NOTIFICATIONS.COMMAND_STATUS.STATUS.3_PARTIAL');
        theme = ThemeType.WARNING;
      }
      break;
    case CommandStatusStatusEnum.SCHEDULED:
      toastBody += ` ${translate.translate('NOTIFICATIONS.COMMAND_STATUS.BODY_COMMAND_CODE_300.STATUS.' + commandStatus.status)}`;
      theme = ThemeType.INFO;
      break;
    case CommandStatusStatusEnum.IDLE:
      toastBody += ` ${translate.translate('NOTIFICATIONS.COMMAND_STATUS.BODY_COMMAND_CODE_300.STATUS.' + commandStatus.status)}`;
      theme = ThemeType.WARNING;
      break;
    case CommandStatusStatusEnum.CANCELLING:
      // todo assumming this is by system.
      toastBody += ` ${translate.translate('NOTIFICATIONS.COMMAND_STATUS.BODY_COMMAND_CODE_300.STATUS.' + commandStatus.status)}`;
      theme = ThemeType.INFO;
      break;
    case CommandStatusStatusEnum.UNKNOWN:
    default:
      toastBody += ` ${translate.translate('NOTIFICATIONS.COMMAND_STATUS.BODY_COMMAND_CODE_300.STATUS.DEFAULT')}`; // TODO does not exist
      theme = ThemeType.DANGER;
      statusText = translate.translate('NOTIFICATIONS.COMMAND_STATUS.STATUS.DEFAULT');
      break;
  }

  return {
    commandDescription,
    commandStatusDescription,
    hasProgressInfo,
    progressPercentCompleted,
    triggerInfo,
    deviceLeftInfo,
    isCompleted,
    title,
    toastBody,
    theme,
    statusText,
  };
};

/**
 * Returns whether the command statusText is completed or not
 */
export const isCommandStatusCompleted = (statusCode: number): boolean => [CommandStatusStatusEnum.FINISHED, CommandStatusStatusEnum.CANCELLED, CommandStatusStatusEnum.FAILED].includes(statusCode);

/**
 * Returns whether the command statusText should show a progress bar or not
 */
export const isCommandStatusWithProgress = (statusCode: number): boolean =>
  ![CommandStatusStatusEnum.IDLE, CommandStatusStatusEnum.FAILED, CommandStatusStatusEnum.SCHEDULED, CommandStatusStatusEnum.CANCELLED, CommandStatusStatusEnum.CANCELLING].includes(statusCode);

export const getCommandStatusIcon = (commandCode: CommandCodeEnum) => {
  switch (commandCode) {
    case CommandCodeEnum.UploadConfiguration:
      return 'fa:cloud-upload';
    case CommandCodeEnum.BackupConfiguration:
      return 'action:backup';
    case CommandCodeEnum.RestoreConfiguration:
      return 'action:restore';
    case CommandCodeEnum.Purge:
      return 'action:delete_forever';
    case CommandCodeEnum.ChannelUpdate:
      return 'fa:upload';
    case CommandCodeEnum.ServiceAction:
      return 'fa:gears';
    case CommandCodeEnum.SystemAction:
      return 'hardware:computer';
    case CommandCodeEnum.UpdateSoftware:
      return 'notification:system_update';
    case CommandCodeEnum.PlayerAction:
      return 'fa:television';
    case CommandCodeEnum.UploadLogs:
      return 'fa:cloud-upload';
    default:
      return 'fa:cloud-upload';
  }
};
