<div class="page-container" [formGroup]="tagForm">
  <div class="form-section editor-wrapper">
    <div class="api-form">
      <form [formGroup]="tagForm">
        <div>
          <avn-select
            class="level"
            [label]="'tagOperation.TAG_OPERATION.TAG_DETAIL.TAG_LEVEL_TITLE_50' | transloco"
            [options]="tagLevelOptions"
            [value]="tagForm.get(tagKeyCreationEnum.level).value"
            (change)="onTagLevelChanged($event)"
            [placeholder]="'tagOperation.TAG_OPERATION.TAG_TYPES.SELECT_LEVEL_PLACEHOLDER_50' | transloco"
            [formControlName]="tagKeyCreationEnum.level"
          ></avn-select>
        </div>
        <div class="key-dynamic">
          <avn-form-field>
            <avn-input [formControlName]="tagKeyCreationEnum.tagKey" [label]="'tagOperation.TAG_OPERATION.TAG_SCHEMA.FORM.LABEL_60' | transloco" [avnTheme]="themeType?.PRIMARY" [debounceTime]="300">
            </avn-input>
            <avn-form-field-error key="required" [message]="'tagOperation.TAG_OPERATION.TAG_SCHEMA.FORM.KEY_REQUIRED_60' | transloco"> </avn-form-field-error>
            <avn-form-field-error key="keyAlreadyExist" [message]="'tagOperation.TAG_OPERATION.TAG_SCHEMA.FORM.KEY_EXIST_60' | transloco"></avn-form-field-error>
            <avn-form-field-error key="pattern" [message]="'tagOperation.TAG_OPERATION.TAG_SCHEMA.FORM.INVALID_KEY_60' | transloco"> </avn-form-field-error>
          </avn-form-field>
          <div class="dynamic" *ngIf="enableLevelChange">
            <avn-switch
              labelPosition="after"
              [formControlName]="tagKeyCreationEnum.dynamic"
              [label]="'tagOperation.TAG_OPERATION.TAG_DETAIL.DYNAMIC_20' | transloco"
              [avnTheme]="themeType.PRIMARY"
            ></avn-switch>
            <avn-icon [icon]="'action:info'" [fontSize]="18" [avnTooltip]="'tagOperation.TAG_OPERATION.TAG_DETAIL.DYNAMIC_TOOLTIP_200' | transloco"></avn-icon>
          </div>
        </div>
      </form>
    </div>
    <avn-json-schema-editor
      #jsonSchemaEditor
      [constraintTypeOptions]="schemaEditorConstraintTypeOptions"
      [showArraySwitch]="allowMultipleValue"
      [hideDefaultWhileArraySchema]="true"
      [formControlName]="tagKeyCreationEnum.jsonSchema"
      [labels]="{
        arraySwitch: 'tagOperation.TAG_OPERATION.TAG_DETAIL.MULTIVALUES_20' | transloco,
        typeField: 'tagOperation.TAG_OPERATION.TAG_DETAIL.TYPE_20' | transloco,
        constraintsHeader: 'tagOperation.TAG_OPERATION.TAG_DETAIL.CONSTRAINTS_40' | transloco,
        descriptionHeader: 'tagOperation.TAG_OPERATION.TAG_DETAIL.DESCRIPTION_40' | transloco
      }"
    ></avn-json-schema-editor>
    <div class="tag-key-creation-buttons">
      <avn-button class="tag-key-creation-button" [disabled]="tagForm.pristine || jsonSchemaEditor.pristine" (actioned)="resetForm()" [label]="'button.reset' | transloco"></avn-button>
      <avn-button
        class="tag-key-creation-button"
        [disabled]="!tagForm.valid || !jsonSchemaEditor.valid"
        (actioned)="createTagKey()"
        [label]="'button.create' | transloco"
        [avnTheme]="themeType.PRIMARY"
      ></avn-button>
    </div>
  </div>
  <div class="form-section preview-wrapper">
    <ng-container *ngIf="previewForm$ | async as newForm">
      <div>
        <span>{{ 'tagOperation.TAG_OPERATION.TAG_DETAIL.PREVIEW_TITLE_50' | transloco }}</span>
      </div>
      <div class="preview-content">
        <div class="schema-container">
          <label class="schema-type">{{ 'tagOperation.TAG_OPERATION.TAG_TYPES.' + jsonSchemaEditor?.constraintType?.toUpperCase() + '_NAME_20' | transloco }}</label>
          <amp-tag-value-item [tag]="newForm | applyFunction: convertToTagValue:[]:this" [isDeletable]="false"> </amp-tag-value-item>
        </div>
      </div>
    </ng-container>
  </div>
</div>
