import { Pipe, PipeTransform } from '@angular/core';
import { CountAggregationData } from '../model/site-monitoring-data.interface';

@Pipe({ name: 'removeZeroCountPipe' })
export class RemoveZeroCountPipe implements PipeTransform {
  transform(value: CountAggregationData): CountAggregationData {
    if (!value) {
      return value;
    }
    // only keep object keys where the value (count) is > 0
    return Object.entries(value).reduce((res, [k, v]) => ({ ...res, ...(v > 0 ? { [k]: v } : {}) }), {});
  }
}
