import { createAction, props } from '@ngrx/store';
import { IGeoFixerAutoFixStatus, IGeoFixerSite } from '../../models/geo-fixer-site.interface';

/** Fetch all sites with geo location issues */
export const FetchAllGeoProblematicSites = createAction('[Site Management Geo Fixer] Fetch All Geo Problematic Sites');
export const FetchAllGeoProblematicSitesSuccess = createAction('[Site Management Geo Fixer] Fetch All Geo Problematic Sites Success', props<{ sites: IGeoFixerSite[] }>());
export const FetchAllGeoProblematicSitesFail = createAction('[Site Management Geo Fixer] Fetch All Geo Problematic Sites Fail', props<{ error: any }>());

/** Auto fix some sites */
export const AutoFixGeoProblematicSites = createAction('[Site Management Geo Fixer] Auto Fix Geo Problematic Sites', props<{ sites: IGeoFixerSite[] }>());
export const AutoFixGeoProblematicSiteSuccess = createAction('[Site Management Geo Fixer] Auto Fix Geo Problematic Site Success', props<{ site: IGeoFixerSite }>());
export const AutoFixGeoProblematicSiteFail = createAction('[Site Management Geo Fixer] Auto Fix Geo Problematic Site Fail', props<{ site: IGeoFixerSite; error?: string }>());

/** Mainly use to change auto-fix task of one site to processing status */
export const UpdateAutoFixGeoProblematicSiteStatus = createAction('[Site Management Geo Fixer] Update Auto Fix Geo Problematic Site Status', props<{ siteId: number; status: IGeoFixerAutoFixStatus }>());

export const ClearAutoFixedGeoProblematicSites = createAction('[Site Management Geo Fixer] Clear Auto Fixed Geo Problematic Sites');
