import { IModalComponent, IModalConfig, MODAL_CONFIG, ModalRef, StepperComponent } from '@activia/ngx-components';
import { ChangeDetectionStrategy, Component, EventEmitter, Inject, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { siteSyncSelectors } from '../../store/site-sync/site-sync.selectors';
import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { SiteSyncProcessCsvFile, SiteSyncResetState, SiteSyncValidateCsvFile } from '../../store/site-sync/site-sync.actions';
import { DeviceService } from '@activia/cm-api';
import { siteManagementSelectors } from '../../store/site-management.selectors';
import { IExperienceTemplateInfo } from '../../models/experience-template.interface';

@Component({
  selector: 'amp-site-sync-dnd-csv-modal',
  templateUrl: './site-sync-dnd-csv-modal.component.html',
  styleUrls: ['./site-sync-dnd-csv-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SiteSyncDndCsvModalComponent implements OnInit, IModalComponent {
  @ViewChild('requiredField', { static: true }) requiredFieldTemplate: TemplateRef<void>;
  @ViewChild('uniqueField', { static: true }) uniqueFieldTemplate: TemplateRef<void>;
  @ViewChild(StepperComponent, { static: true }) stepper: StepperComponent;

  @Output() downloadCsvSites = new EventEmitter<void>();
  showStartSyncButton$: Observable<boolean>;
  siteListLoaded$: Observable<boolean>;

  showSelectExperienceTemplateButton$: Observable<boolean>;
  experiencesSelected: IExperienceTemplateInfo = null;

  canCloseModal = true;

  constructor(
    private _dialogRef: ModalRef<SiteSyncDndCsvModalComponent>,
    @Inject(MODAL_CONFIG) public dialogConfig: IModalConfig<{ isSelectedSites: boolean }>,
    private _store: Store,
    private _deviceService: DeviceService
  ) {}

  ngOnInit() {
    this._store.dispatch(SiteSyncResetState());

    this.showStartSyncButton$ = combineLatest([this._store.select(siteSyncSelectors.sitesValidationStatusCount), this.stepper.stepChanged]).pipe(
      map(([counts, { selectedStepIndex }]) => {
        const hasSitesToAddOrUpdate = counts?.add > 0 || counts?.update > 0;
        if (!hasSitesToAddOrUpdate) {
          return false;
        }
        const hasSitesToAdd = counts?.add > 0;
        const isSummaryStep = selectedStepIndex === 2;
        const isTemplateSelectedStep = selectedStepIndex === 3;
        if ((isSummaryStep && !hasSitesToAdd) || isTemplateSelectedStep) {
          return true;
        }
        return false;
      })
    );

    this.showSelectExperienceTemplateButton$ = combineLatest([this._store.select(siteSyncSelectors.sitesValidationStatusCount), this.stepper.stepChanged]).pipe(
      map(([counts, { selectedStepIndex }]) => {
        const hasSitesToAdd = counts?.add > 0;
        const isSummaryStep = selectedStepIndex === 2;
        return hasSitesToAdd && isSummaryStep;
      })
    );

    this.siteListLoaded$ = this._store.select(siteManagementSelectors.allSitesLoaded);
  }

  canClose(): boolean {
    return this.canCloseModal;
  }

  /** Called when the cancel button is clicked **/
  onCancel() {
    this._close();
  }

  /** Change to the next stepper modal */
  openFieldDescription() {
    this.stepper.selectedIndex = 1;
  }

  /** allow to download sites */
  onDownloadSites() {
    this.downloadCsvSites.emit();
  }

  onSelectedFile(file: File) {
    this.stepper.selectedIndex = 2;
    this._store.dispatch(SiteSyncValidateCsvFile({ file }));
  }

  /** Close the modal */
  private _close() {
    this._dialogRef.close();
  }

  startSync() {
    // fetch the device types so it can be reused across the whole sync process
    this._deviceService.getDeviceTypes().subscribe((deviceTypes) => {
      this._store.dispatch(SiteSyncProcessCsvFile({ experiencesTemplate: this.experiencesSelected, deviceTypes }));
      this._close();
    });
  }

  onStepperBack() {
    // skip field description step
    const newStepIndex = this.stepper.selectedIndex - 1;
    this.stepper.selectedIndex = newStepIndex === 1 ? 0 : newStepIndex;
  }

  onExperienceSelected($event: IExperienceTemplateInfo) {
    this.experiencesSelected = $event;
  }

  get syncButtonDisabled(): boolean {
    return !!this.experiencesSelected && this.experiencesSelected.experiences.length > 0 && this.experiencesSelected.deviceAction === null;
  }

  onStepChanged(selectedStepIndex: number) {
    // can only close the modal until validation has occurred
    this.canCloseModal = this.canCloseModal && selectedStepIndex < 2;
  }
}
