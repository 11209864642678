<avn-dialog-layout>
  <avn-dialog-header
    [showCloseIcon]="dialogConfig.showCloseIcon"
    [title]="'siteManagementScope.SITE_MANAGEMENT.GLOBAL.EDIT_SITE_50' | transloco: { siteName: dialogConfig.data.site.name }"
    (closeClicked)="onCancel()"
  ></avn-dialog-header>

  <avn-dialog-content avnScroll>
    <avn-tabs [justify]="'fill'" [avnTheme]="'primary'">
      <avn-tab
        [id]="'general'"
        [label]="'siteManagementScope.SITE_MANAGEMENT.SITE_DETAIL.SITE_PROPERTIES_EDITOR.INFO.GENERAL_35' | transloco"
        [disabled]="false"
      >
        <div class="tab-container">
          <amp-site-management-site-basic-info-editor
            [parentForm]="form"
            [site]="this.dialogConfig.data.site"
            [validateFormOnInitialLoad]="dialogConfig.data.validateFormOnInitialLoad"
          ></amp-site-management-site-basic-info-editor>
        </div>
      </avn-tab>

      <avn-tab
        [id]="'location'"
        [label]="'siteManagementScope.SITE_MANAGEMENT.SITE_DETAIL.SITE_PROPERTIES_EDITOR.INFO.LOCATION_35' | transloco"
        [disabled]="false"
      >
        <div class="tab-container">
          <amp-location-editor
            [parentForm]="form"
            [siteId]="dialogConfig.data.site.id"
            [location]="mapLocation"
            [validateFormOnInitialLoad]="dialogConfig.data.validateFormOnInitialLoad"
          ></amp-location-editor>
        </div>
      </avn-tab>
    </avn-tabs>
  </avn-dialog-content>

  <avn-dialog-footer class="row-flex">
    <avn-button [label]="'button.cancel' | transloco" (actioned)="onCancel()"></avn-button>
    <avn-button
      [label]="'button.save' | transloco"
      [disabled]="form.invalid || form.pristine"
      avnTheme="primary"
      (actioned)="onSave()"
    >
    </avn-button>
  </avn-dialog-footer>
</avn-dialog-layout>
