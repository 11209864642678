import { Component, ChangeDetectionStrategy, OnDestroy, ViewChild, ElementRef, Output, EventEmitter, Inject, OnInit } from '@angular/core';
import { IModalComponent, StepperComponent, IBadgeSize, ThemeType, IOptionData, IDataTableColumnConfig, ModalRef, MODAL_CONFIG, IModalConfig, dataOnceReady } from '@activia/ngx-components';
import { ISiteDeviceSelector } from './site-management-device-selector.interface';
import { Observable, BehaviorSubject, Subject, combineLatest } from 'rxjs';
import { DeviceDTO, DisplayDTO, DisplayInputDTO } from '@activia/cm-api';
import { debounceTime, filter, first, map, startWith, takeUntil, tap, withLatestFrom } from 'rxjs/operators';
import { IModalPickerComponent } from '../../../../models/modal-picker-component.interface';
import { PlayerUnit } from '../../../../models/player-unit.enum';
import { CdkOverlayOrigin } from '@angular/cdk/overlay';
import { DeviceProperty, OperationalState } from '@amp/devices';
import { Store } from '@ngrx/store';
import { ISiteManagementState } from '../../../../store/site-management.reducer';
import { siteManagementEntities } from '../../../../store/site-management.selectors';
import { DeviceState } from '../../../../store/device/device.reducer';
import * as DeviceSelectors from '../../../../store/device/device.selectors';
import { DisplayState } from '../../../../store/display/display.reducer';
import * as DisplaySelectors from '../../../../store/display/display.selectors';
import { BoardState } from '../../../../store/board/board.reducer';
import * as BoardSelectors from '../../../../store/board/board.selectors';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { PlayerColumnService } from '../../../../players/services/player-column.service';
import { STANDARD_PLAYER_COLS } from '../../../../players/models/player-column';
import { DisplayType } from '@amp/column-picker';
import { ISiteManagementPlayerSelectorRow } from '../site-management-player-selector/site-management-player-selector.component';
import { SiteManagementService } from '../../../../services/site-management.service';

@Component({
  selector: 'amp-site-management-device-selector-modal',
  templateUrl: './site-management-device-selector-modal.component.html',
  styleUrls: ['./site-management-device-selector-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SiteManagementDeviceSelectorModalComponent implements OnInit, OnDestroy, IModalComponent, IModalPickerComponent {
  @ViewChild(StepperComponent, { static: true }) stepper: StepperComponent;
  @ViewChild('overlayOriginInput', { static: false }) originInput: CdkOverlayOrigin;
  @ViewChild('connectedElement', { static: false }) connectedElement: ElementRef;

  /** Triggered when the user click on the save button */
  @Output() saved = new EventEmitter<{ deviceId: number; playerId: PlayerUnit; output: number }>();

  /** All devices binded to the site */
  siteDevices$: Observable<DeviceDTO[]>;

  /** All available devices that could be used for this specific display input */
  availableDevices$: Observable<DeviceDTO[]>;

  /** The current selected device in the modal */
  selectedDevice$ = new BehaviorSubject<DeviceDTO>(undefined);

  /** The current player selected in the modal */
  selectedPlayer$ = new BehaviorSubject<PlayerUnit>(undefined);

  /** The current output selected in the modal */
  selectedOutput$ = new BehaviorSubject<number>(undefined);

  /** List of available players */
  playerOptions$: Observable<IOptionData<void>[]>;

  /** List of available output */
  outputOptions$: Observable<IOptionData<void>[]>;

  /** Default selected device/player/output when modal opened */
  defaultSelection: DisplayInputDTO;

  /** If the board of the current display is locked */
  isBoardLocked$: Observable<boolean>;

  /** If the user has the role required to edit */
  editable$: Observable<boolean>;

  /** If values have changed compared to original values*/
  hasValuesChangedAndValid$: Observable<boolean>;

  /** Detect changes on deviceId, player and output  */
  form: UntypedFormGroup;

  DisplayType = DisplayType;
  DeviceProperty = DeviceProperty;
  PlayerUnits = PlayerUnit;
  badgeSize: IBadgeSize = IBadgeSize.SMALL;
  themeType = ThemeType;

  /** @ignore to use in template **/
  operationalState = OperationalState;

  /** The recent devices data table columns configuration */
  columns$: Observable<IDataTableColumnConfig<void>[]>;

  /** define if the editing device will allow available connection only */
  private isAvailableConnectionsOnlySubject = new BehaviorSubject<boolean>(true);

  get isAvailableConnectionsOnly$() {
    return this.isAvailableConnectionsOnlySubject.asObservable();
  }

  get searchByHostname$() {
    return this.searchByHostnameSubject.asObservable();
  }

  searchByHostnameSubject = new BehaviorSubject<string>(null);

  private componentDestroyed$: Subject<void> = new Subject();

  constructor(
    private _dialogRef: ModalRef<SiteManagementDeviceSelectorModalComponent>,
    @Inject(MODAL_CONFIG) public dialogConfig: IModalConfig<ISiteDeviceSelector>,
    private _fb: UntypedFormBuilder,
    private _siteStore: Store<ISiteManagementState>,
    private _deviceStore: Store<DeviceState>,
    private _displayStore: Store<DisplayState>,
    private _boardStore: Store<BoardState>,
    private _playerColumnService: PlayerColumnService,
    private _siteManagementService: SiteManagementService
  ) {
    // Default selection device/player/output
    this.defaultSelection = dialogConfig.data.display.inputs[dialogConfig.data.inputType];
  }

  ngOnInit(): void {
    /** config the modal player columns */
    this.setColumns();

    this.form = this._fb.group({
      deviceId: ['', Validators.required],
      playerId: ['', Validators.required],
      output: ['', Validators.required],
    });

    // Check if form has changed compared to original values
    this.hasValuesChangedAndValid$ = this.form.valueChanges.pipe(
      map((values) => values.deviceId !== this.defaultSelection?.deviceId || values.playerId !== this.defaultSelection?.playerId || values.output !== this.defaultSelection?.output),
      map((hasChanged) => hasChanged && this.form.valid),
      startWith(false)
    );

    this.isBoardLocked$ = this._boardStore.select(BoardSelectors.selectBoardEntities).pipe(map((boards) => boards[this.dialogConfig.data.display.parentBoardId].isLocked));

    this.editable$ = this._siteManagementService.hasAuthority$('site');

    // All available devices binded to the site
    this.siteDevices$ = dataOnceReady(this._deviceStore.select(DeviceSelectors.selectAllDevices), this._deviceStore.select(DeviceSelectors.selectDeviceDataState));

    // List of available player
    this.playerOptions$ = combineLatest([this._displayStore.select(DisplaySelectors.selectAllDisplays), this.selectedDevice$]).pipe(
      filter(([, selectedDevice]) => !!selectedDevice),
      withLatestFrom(this._siteStore.pipe(siteManagementEntities.siteConfigData$)),
      map(([[displays, selectedDevice], siteConfig]) =>
        // Generate the list of player availability
        [...Array(siteConfig.defaultPlayerCountPerDevice)].map((_, index) => ({
          value: index,
          disabled: this._isAllOutputUsed(displays, selectedDevice?.id, index, siteConfig.defaultOutputCountPerPlayer) && !this._isOriginalPlayer(selectedDevice.id, index), // disabled if output is used except original output
        }))
      )
    );

    // List of available output
    this.outputOptions$ = combineLatest([this._displayStore.select(DisplaySelectors.selectAllDisplays), this.selectedDevice$, this.selectedPlayer$]).pipe(
      filter(([, selectedDevice, selectedPlayer]) => !!selectedDevice && selectedPlayer !== undefined && selectedPlayer !== null),
      withLatestFrom(this._siteStore.pipe(siteManagementEntities.siteConfigData$)),
      map(([[displays, selectedDevice, selectedPlayer], siteConfig]) => {
        // Find all already used output for this specific device/player
        const usedOutput = displays
          .map((device) => device.inputs)
          .flat()
          .filter((input) => input.deviceId === selectedDevice.id && input.playerId === selectedPlayer) // Get all output of current player
          .map((e) => e.output);

        // Generate the list of output availability
        return [...Array(siteConfig.defaultOutputCountPerPlayer)].map((_, index) => ({
          value: index,
          disabled: usedOutput.includes(index) && !this._isOriginalOutput(selectedDevice.id, selectedPlayer, index), // disabled if output is used expect original output
        }));
      })
    );

    // Initialize the current selected device/player/output when we open the modal
    this.siteDevices$.pipe(first()).subscribe((devices) => {
      const display = this.dialogConfig.data.display;
      const inputs = display.inputs[this.dialogConfig.data.inputType];

      if (inputs?.deviceId) {
        this.selectedDevice$.next(devices.find((d) => d.id === inputs.deviceId));
        this.selectedPlayer$.next(inputs.playerId);
        this.selectedOutput$.next(inputs.output);
      }
    });

    // Set the form everytime one of the control changed
    combineLatest([this.selectedDevice$, this.selectedPlayer$, this.selectedOutput$])
      .pipe(debounceTime(1), takeUntil(this.componentDestroyed$))
      .subscribe(([device, playerId, output]) => this.form.patchValue({ deviceId: device?.id, playerId, output }));

    // List of available devices
    this.availableDevices$ = combineLatest([
      this.siteDevices$,
      this._displayStore.select(DisplaySelectors.selectDeviceUsage),
      dataOnceReady(this._siteStore.pipe(siteManagementEntities.siteConfigData$), this._siteStore.pipe(siteManagementEntities.siteConfigDataState$)),
    ]).pipe(
      map(([siteDevices, usages, siteConfig]) =>
        siteDevices.filter(
          (device) =>
            !usages[device.id]?.count || // Device is unused or
            (usages[device.id].count < siteConfig.defaultOutputCountPerPlayer * siteConfig.defaultPlayerCountPerDevice && // Device hasn't reached maximum connection and
              (siteConfig.allowDevicesAcrossMultipleBoards || usages[device.id].boardId === this.dialogConfig.data.display.parentBoardId)) // Device is allowed to be connected in the board
        )
      )
    );
  }

  canClose(): boolean {
    return true;
  }

  onClose(): void {
    this._dialogRef.close();
  }

  changeSelectedDevice(device: ISiteManagementPlayerSelectorRow /*DeviceDTO & { datatableRowOptions: { disabled: boolean } }*/) {
    this.siteDevices$
      .pipe(
        first(),
        tap((devices) => {
          if (this.isAvailableConnectionsOnlySubject.value || !device.datatableRowOptions.disabled) {
            this.selectedDevice$.next(devices.find((d) => d.id === device.id));
            this.selectedPlayer$.next(null);
            this.selectedOutput$.next(null);
            this.form.markAllAsTouched();
            this.stepper.previous();
          }
        })
      )
      .subscribe();
  }

  changeSelectedPlayer(player: number) {
    this._siteStore.pipe(siteManagementEntities.siteConfigData$, first()).subscribe(({ defaultOutputCountPerPlayer }) => {
      this.form.get('playerId').markAsTouched();
      this.form.get('output').markAsTouched();
      this.selectedPlayer$.next(player);
      this.selectedOutput$.next(defaultOutputCountPerPlayer > 1 ? null : 0); // Select first output if there is only 1 choice
    });
  }

  changeSelectedOutput(output: number) {
    this.form.get('output').markAsTouched();
    this.selectedOutput$.next(output);
  }

  /** Change to the next stepper modal */
  onOpenEditPlayer() {
    this.stepper.next();
  }

  onSave(): void {
    this.saved.emit(this.form.getRawValue());
    this.onClose();
  }

  onRemove(): void {
    this.saved.emit({ deviceId: null, playerId: null, output: null });
    this.onClose();
  }

  setColumns(): void {
    this.columns$ = this._playerColumnService.getFormattedPlayerColumns$(STANDARD_PLAYER_COLS);
  }

  /** Search players with hostname filter */
  onSearchHostname(deviceFiltered: DeviceDTO[], searchByHostname: string): DeviceDTO[] {
    return deviceFiltered.filter((device) => (device?.deviceInfo?.hostname || '').toLowerCase().indexOf(searchByHostname.toLowerCase()) > -1);
  }

  showAvailablePlayers(evt: boolean): void {
    this.isAvailableConnectionsOnlySubject.next(evt);
  }

  ngOnDestroy(): void {
    this.componentDestroyed$.next();
    this.componentDestroyed$.complete();
  }

  private _isAllOutputUsed(displays: DisplayDTO[], deviceId: number, playerId: number, outputCountPerPlayer: number) {
    // Find all already used output for this specific device/player
    return (
      displays
        .map((display) => display.inputs)
        .flat()
        // Get all output of current player
        .filter((input) => input.deviceId === deviceId && input.playerId === playerId)?.length === outputCountPerPlayer
    );
  }

  private _isOriginalPlayer(deviceId: number, playerId: number) {
    return deviceId === this.defaultSelection?.deviceId && playerId === this.defaultSelection?.playerId;
  }

  /** Check if output is the same as the original one  */
  private _isOriginalOutput(deviceId: number, playerId: number, output: number) {
    return deviceId === this.defaultSelection?.deviceId && playerId === this.defaultSelection?.playerId && output === this.defaultSelection?.output;
  }
}
