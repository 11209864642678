import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { PropertyType } from '../../model/operation-scope.interface';
import { ITagChangeSummary } from '../../model/tag-values-operations.interface';

@Component({
  selector: 'amp-invalid-tag-message-summary',
  templateUrl: './invalid-tag-message-summary.component.html',
  styleUrls: ['./invalid-tag-message-summary.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InvalidTagMessageSummaryComponent {
  /** used to find right i18n translation key */
  @Input() propertyType: PropertyType;

  /** Tag operation impacts */
  @Input() tagOperations: ITagChangeSummary[];
}
