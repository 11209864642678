<div
  class="wrapper"
  [tooltipPlacement]="'left'"
  [tooltipTheme]="alarm | applyFunction: getTooltipTheme"
  [avnTooltip]="'alarmEvents.ALARM_EVENTS.LEVELS.' + alarm.level | transloco"
  [tooltipMouseEnterOpenDelay]="0"
  [tooltipMouseLeaveCloseDelay]="0"
>
  <avn-icon [fontSize]="22" class="level-icon" [icon]="alarm | applyFunction: getLevelIconName" [avnTheming]="alarm | applyFunction: getLevelIconThemeInfo"></avn-icon>
  <div class="alarm-text" *ngIf="!!alarmText">
    <ng-container *ngIf="showNamespace && alarmText.namespace">
      <span class="namespace">
        {{ alarmText.namespace.key | transloco: alarmText.namespace.params }}
      </span>
    </ng-container>
    <ng-container *ngIf="alarmText.customerMessage || alarmText.longMessage">
      <span class="message">
        <ng-container *ngIf="alarmText.customerMessage"> {{ alarmText.customerMessage.key | transloco: alarmText.customerMessage.params }}</ng-container>
        <ng-container *ngIf="alarmText.longMessage">
          <ng-container *ngIf="alarmText.customerMessage"> (</ng-container>
          {{ alarmText.longMessage }}
          <ng-container *ngIf="alarmText.customerMessage">)</ng-container>
        </ng-container>
      </span>
      <span class="action" *ngIf="alarmText.customerAction || alarmText.defaultAction">
        <ng-container *ngIf="alarmText.customerAction"> {{ alarmText.customerAction.key | transloco: alarmText.customerAction.params }}</ng-container>
        <ng-container *ngIf="alarmText.defaultAction && !alarmText.customerAction">
          <ng-container *ngIf="alarmText.longMessage">
            <ng-container *ngIf="alarmText.customerAction"> (</ng-container>
            {{ alarmText.defaultAction.key | transloco: alarmText.defaultAction.params }}
            <ng-container *ngIf="alarmText.customerAction">)</ng-container>
          </ng-container>
        </ng-container>
      </span>
    </ng-container>
  </div>
</div>
