/**
 * Represents the possible operational state of a device
 */
import { ThemeType } from '@activia/ngx-components';

export enum OperationalState {
  Running = 'Running',
  Provisioned = 'Provisioned',
  SoftwareMaintenance = 'Software Maintenance',
  HardwareMaintenance = 'Hardware Maintenance',
  Decommissioned = 'Decommissioned',
}

/** themes used for each operational state **/
export const OperationalStateThemes: { [key: string]: ThemeType } = {
  [OperationalState.Running]: ThemeType.SUCCESS,
  [OperationalState.Provisioned]: ThemeType.PRIMARY,
  [OperationalState.SoftwareMaintenance]: ThemeType.WARNING,
  [OperationalState.HardwareMaintenance]: ThemeType.WARNING,
  [OperationalState.Decommissioned]: ThemeType.DANGER,
};
