import { SiteDTO } from '@activia/cm-api';

/**
 * Converts a list of site dtos to csv
 *
 * @param sites
 */
export const toCsvSites = (sites: SiteDTO[]): any[] =>
  sites.map((site) => [
    site.id,
    site.externalId,
    site.name,
    site.address.addressLine1,
    site.address.addressLine2,
    site.address.city,
    site.address.state,
    site.address.zip,
    site.address.country,
    site.geodeticCoordinates.longitude,
    site.geodeticCoordinates.latitude,
  ]);
