import { IModalConfig, ItemPerSlideConfig, MODAL_CONFIG, ModalRef } from '@activia/ngx-components';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { Observable, of } from 'rxjs';
import { delay } from 'rxjs/operators';

@Component({
  selector: 'amp-site-screenshot-modal',
  templateUrl: './site-screenshot-modal.component.html',
  styleUrls: ['./site-screenshot-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SiteScreenshotModalComponent implements OnInit {
  showCarousel$: Observable<boolean>;

  itemPerSlideConfig: ItemPerSlideConfig = {
    handset: 1,
    tablet: 1,
    web: 1,
    huge: 1,
    yuge: 1,
    gigantic: 1,
  };

  canClose(): boolean {
    return true;
  }

  onClose(): void {
    this.dialogRef.close();
  }

  constructor(private dialogRef: ModalRef<SiteScreenshotModalComponent>, public cdr: ChangeDetectorRef, @Inject(MODAL_CONFIG) public dialogConfig: IModalConfig<any>) {}

  ngOnInit() {
    // Add delay to show carousel. Wait for the dialog to open completely first to prevent showing carousel resizing on UI.
    this.showCarousel$ = of(true).pipe(delay(150));
  }
}
