import { DeviceDTO, DisplayDTO } from '@activia/cm-api';
import { dataOnceReady, IDraggableElement, ModalService, ModalType } from '@activia/ngx-components';
import { OperationalStateThemes } from '@amp/devices';
import { Overlay } from '@angular/cdk/overlay';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { BehaviorSubject, combineLatest, merge, Observable, of, ReplaySubject, Subject, timer } from 'rxjs';
import { filter, first, map, pairwise, startWith, switchMap, take, takeUntil, tap, withLatestFrom } from 'rxjs/operators';
import { DEFAULT_DRAG_DROP_ROW_ID, DisplayInputType } from '../../../../models/player-unit.enum';
import { ScreenOrientations } from '../../../../models/screen-orientation.enum';
import { SiteManagementDeviceSelectorModalComponent } from '../site-management-device-selector-modal/site-management-device-selector-modal.component';
import { ISiteDeviceSelector } from '../site-management-device-selector-modal/site-management-device-selector.interface';
import { Store } from '@ngrx/store';
import { ISiteManagementState } from '../../../../store/site-management.reducer';
import { siteManagementEntities } from '../../../../store/site-management.selectors';
import * as DisplayActions from '../../../../store/display/display.actions';
import * as DisplaySelectors from '../../../../store/display/display.selectors';
import * as DeviceSelectors from '../../../../store/device/device.selectors';
import { DisplayState } from '../../../../store/display/display.reducer';
import { DeviceState } from '../../../../store/device/device.reducer';
import { IBoardExperienceTemplate } from '../../../../models/experience-template.interface';

@Component({
  selector: 'amp-site-management-display',
  templateUrl: './site-management-display.component.html',
  styleUrls: ['./site-management-display.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SiteManagementDisplaysComponent implements OnChanges, OnInit {
  @Input() boardId: number;
  @Input() isBoardLocked: boolean;
  @Input() displayId: number;
  @Input() displayIndex: number;
  @Input() displayOrigin: string;
  @Input() editable: boolean;
  @Input() experienceTemplate: IBoardExperienceTemplate;

  @Output() shakeLock = new EventEmitter<string>();

  isBoardLockedSub: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);

  primaryInput$: Observable<DeviceDTO>;
  backupInput$: Observable<DeviceDTO>;

  display$: Observable<DisplayDTO>;

  showLoader$: Subject<DisplayInputType> = new Subject<DisplayInputType>();

  primaryDeviceLoader$: Observable<boolean>;

  backupDeviceLoader$: Observable<boolean>;

  /** Drop area target to link it with device list side menu */
  dragDropTarget$: Observable<string[]>;

  DisplayInputType = DisplayInputType;

  OperationalStateThemes = OperationalStateThemes;

  private _displayId$: BehaviorSubject<number> = new BehaviorSubject<number>(null);

  constructor(
    private _overlay: Overlay,
    private _modalService: ModalService,
    private _siteStore: Store<ISiteManagementState>,
    private _displayStore: Store<DisplayState>,
    private _deviceStore: Store<DeviceState>
  ) {}

  ngOnChanges({ displayId, isBoardLocked }: SimpleChanges) {
    if (displayId) {
      this._displayId$.next(displayId.currentValue);
    }

    if (isBoardLocked) {
      this.isBoardLockedSub.next(isBoardLocked.currentValue);
    }
  }

  ngOnInit() {
    // Check if board is part of an experience template

    this.display$ = combineLatest([this._displayId$, this._displayStore.select(DisplaySelectors.selectDisplayEntities)]).pipe(map(([displayId, displayEntities]) => displayEntities[displayId]));

    // show loader for at least 800ms, and as long as API take
    const loaderForDisplayDeviceType$ = (deviceType: DisplayInputType) =>
      this.showLoader$.pipe(
        filter((index) => index === deviceType),
        switchMap(() =>
          merge(
            of(true),
            timer(800).pipe(
              switchMap(() =>
                this._displayStore.select(DisplaySelectors.selectTemporaryUpdatedDisplays).pipe(
                  map((temporaryUpdatedDisplays) => {
                    const { [this.displayId]: updatedDisplay } = temporaryUpdatedDisplays;
                    return !!updatedDisplay;
                  }),
                  startWith(true),
                  pairwise(),
                  filter(([before, after]) => before && !after),
                  map(([, current]) => current),
                  take(1)
                )
              )
            )
          )
        )
      );

    this.primaryDeviceLoader$ = loaderForDisplayDeviceType$(0);
    this.backupDeviceLoader$ = loaderForDisplayDeviceType$(1);

    const device$ = (deviceIdx: DisplayInputType) =>
      this.display$.pipe(
        filter((display) => !!display),
        switchMap((display) =>
          dataOnceReady(this._deviceStore.select(DeviceSelectors.selectAllDevices), this._deviceStore.select(DeviceSelectors.selectDeviceDataState)).pipe(
            map((devices) => [devices, display] as [DeviceDTO[], DisplayDTO])
          )
        ),
        map(([devices, display]) => {
          const input = display.inputs[deviceIdx];
          return input?.deviceId > 0 ? devices.find((device) => device.id === input.deviceId) : null;
        })
      );

    this.dragDropTarget$ = dataOnceReady(this._siteStore.pipe(siteManagementEntities.siteConfigData$), this._siteStore.pipe(siteManagementEntities.siteConfigDataState$)).pipe(
      map((siteConfig) => (siteConfig.allowDevicesAcrossMultipleBoards ? [DEFAULT_DRAG_DROP_ROW_ID] : [DEFAULT_DRAG_DROP_ROW_ID, DEFAULT_DRAG_DROP_ROW_ID + '_' + this.boardId]))
    );

    this.primaryInput$ = device$(DisplayInputType.PRIMARY);
    this.backupInput$ = device$(DisplayInputType.BACKUP);
  }

  removeDisplay(id: number) {
    this._displayStore.dispatch(DisplayActions.DeleteDisplay({ displayId: id }));
  }

  addNewDisplay() {
    this._displayStore.dispatch(DisplayActions.AddDisplay({ boardId: this.boardId, displayIdx: this.displayIndex }));
  }

  onChangeName(displayName: string, displayId: number) {
    this._displayStore.dispatch(DisplayActions.UpdateDisplayName({ displayId, displayName }));
  }

  onScreenOrientationChange(screenOrientation: ScreenOrientations, displayId: number) {
    this._displayStore.dispatch(DisplayActions.ChangeDisplayOrientation({ displayId, orientation: screenOrientation }));
  }

  dropDevice(event: IDraggableElement<DeviceDTO>, deviceIdx: DisplayInputType) {
    const device: DeviceDTO = event.draggableData.targetData;

    // Get next available output in the device and connect it to current display. If no output is available, do nothing
    combineLatest([this._displayStore.select(DisplaySelectors.selectAllDisplays), this._siteStore.pipe(siteManagementEntities.siteConfigData$)])
      .pipe(first())
      .subscribe(([displays, { defaultPlayerCountPerDevice, defaultOutputCountPerPlayer }]) => {
        let outputIndex = 0;
        let playerIndex = 0;
        let index = 0;

        const usedOutputInDisplays = displays
          .map((display) => display.inputs)
          .flat()
          .filter((input) => input.deviceId === device.id);

        // If the series is not listed, we default the number of logical players to 2
        const maximumNbrOfPlayers = defaultPlayerCountPerDevice;
        const nbrOfOutput = defaultOutputCountPerPlayer;

        // Loop until we find the next available output in the device
        while (playerIndex < maximumNbrOfPlayers) {
          playerIndex = Math.floor(index / nbrOfOutput);
          outputIndex = index % nbrOfOutput;

          if (usedOutputInDisplays.some((e) => e.playerId === playerIndex && e.output === outputIndex)) {
            // This input is already used in a display
            index++;
          } else {
            // This input is available
            this._displayStore.dispatch(
              DisplayActions.SetDeviceToDisplay({
                displayId: this.displayId,
                device,
                displayInputType: deviceIdx,
                playerId: playerIndex,
                output: outputIndex,
              })
            );
            this.showLoader$.next(deviceIdx);

            return;
          }
        }
      });
  }

  /** Delete completely the device from the input */
  deleteDevice(inputType: DisplayInputType) {
    this._displayStore.dispatch(
      DisplayActions.RemoveDeviceFromDisplay({
        displayId: this.displayId,
        displayDevice: inputType,
      })
    );
  }

  /** Remove the specific device from the input but keep an empty object */
  deleteInput(inputType: DisplayInputType) {
    this._displayStore.dispatch(
      DisplayActions.RemoveInputFromDisplay({
        displayId: this.displayId,
        displayDevice: inputType,
      })
    );
  }

  openDeviceSelector(inputType: DisplayInputType) {
    this.display$
      .pipe(
        first(),
        tap((display) => {
          const modalRef = this._modalService.open<SiteManagementDeviceSelectorModalComponent, ISiteDeviceSelector>(
            SiteManagementDeviceSelectorModalComponent,
            {
              closeOnBackdropClick: true,
              showCloseIcon: true,
              data: {
                display,
                inputType,
                // isBoardLocked: this.isBoardLocked,
              },
            },
            {
              width: '600px',
              panelClass: 'overlay-panel-class',
              positionStrategy: this._overlay.position().global().centerHorizontally().centerVertically(),
            },
            ModalType.Dialog
          );

          modalRef.componentInstance.saved
            .pipe(take(1), withLatestFrom(this._deviceStore.select(DeviceSelectors.selectDeviceEntities)), takeUntil(modalRef.afterClosed))
            .subscribe(([data, deviceEntities]) => {
              if (data.deviceId) {
                // End-user set a new device to the display
                this._displayStore.dispatch(
                  DisplayActions.SetDeviceToDisplay({
                    displayId: this.displayId,
                    device: deviceEntities[data.deviceId],
                    displayInputType: inputType,
                    playerId: data.playerId,
                    output: data.output,
                  })
                );

                this.showLoader$.next(inputType);
              } else {
                // End-user deleted the device from the display
                this.deleteDevice(inputType);
              }
            });
        })
      )
      .subscribe();
  }

  /**
   * Returns whether this display can be removed or not.
   * A display can be removed when it has no device connection set up yet.
   */
  canRemoveDisplay(display: DisplayDTO) {
    return display.inputs.every((input) => !input.deviceId);
  }

  onShakeLock(event: boolean) {
    this.shakeLock.emit(event ? this.displayOrigin : '');
  }
}
