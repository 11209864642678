import { IFieldExpression } from '@activia/ngx-components';
import { IToken, TokenType } from 'chevrotain';

import {
  DateField,
  DateValue,
  DurationField,
  EqualityNumericField,
  EqualityStringField,
  Equals,
  IsDefined,
  IsNotDefined,
  NotEquals,
  NumericField,
  NumericValue,
  RelationalOperator,
  StringField,
  StringOperator,
  StringValue,
  TagField,
  TimeUnit,
} from './device-filter.tokens';

export class DeviceFilterExpressionListVisitor {
  visitor: any;

  constructor(BaseCstVisitorWithDefaults: any) {
    class DeviceFilterExpressionVisitorClass extends BaseCstVisitorWithDefaults {
      constructor() {
        super();
        this.validateVisitor();
      }

      filter(ctx): IFieldExpression[] {
        return this.visit(ctx.filterExpression);
      }

      filterExpression(ctx): IFieldExpression[] {
        let expressionList: IFieldExpression[] = this.visit(ctx.lhs);
        // "rhs" key may be undefined as the grammar defines it as optional (MANY === zero or more).
        if (ctx.rhs) {
          ctx.rhs.forEach((rhsFieldExpression) => {
            // there will be one operator for each rhs operand
            expressionList = [...expressionList, ...this.visit(rhsFieldExpression)];
          });
        }
        return expressionList;
      }

      fieldExpression(ctx): IFieldExpression[] {
        if (ctx.stringExpression) {
          return [this.visit(ctx.stringExpression)];
        } else if (ctx.tagFieldExpression) {
          return [this.visit(ctx.tagFieldExpression)];
        } else if (ctx.numericExpression) {
          return [this.visit(ctx.numericExpression)];
        } else if (ctx.dateExpression) {
          return [this.visit(ctx.dateExpression)];
        } else if (ctx.durationExpression) {
          return [this.visit(ctx.durationExpression)];
        } else if (ctx.parenthesisExpression) {
          return this.visit(ctx.parenthesisExpression);
        } else if (ctx.selfEndExpression) {
          return [this.visit(ctx.selfEndExpression)];
        } else {
          return [];
        }
      }

      parenthesisExpression(ctx): IFieldExpression[] {
        return this.visit(ctx.filterExpression);
      }

      selfEndExpression(ctx): IFieldExpression {
        if (ctx.isDefinedOrUndefinedExpression) {
          return this.visit(ctx.isDefinedOrUndefinedExpression);
        } else {
          return null;
        }
      }

      isDefinedOrUndefinedExpression(ctx): IFieldExpression {
        return {
          field: getFromContext(ctx, StringField),
          operator: getFromContext(ctx, IsDefined, IsNotDefined),
          values: [],
          ruleName: 'isDefinedExpression',
          completed: true,
        };
      }

      stringExpression(ctx): IFieldExpression {
        if (ctx.regularStringExpression) {
          return this.visit(ctx.regularStringExpression);
        } else if (ctx.equalityStringExpression) {
          return this.visit(ctx.equalityStringExpression);
        } else {
          return null;
        }
      }

      regularStringExpression(ctx): IFieldExpression {
        const value = getArrayFromContext(ctx, StringValue);
        return {
          field: getFromContext(ctx, StringField),
          operator: getFromContext(ctx, StringOperator),
          values: value,
          ruleName: 'regularStringExpression',
          completed: value != null && value.length === 1,
        };
      }

      equalityStringExpression(ctx): IFieldExpression {
        const value = getArrayFromContext(ctx, StringValue);
        return {
          field: getFromContext(ctx, EqualityStringField),
          operator: getFromContext(ctx, Equals, NotEquals),
          values: value,
          ruleName: 'equalityStringExpression',
          completed: value != null && value.length === 1,
        };
      }

      dateExpression(ctx): IFieldExpression {
        const durationValues = [getFromContext(ctx, NumericValue), getFromContext(ctx, TimeUnit)].filter((v) => !!v);
        const dateValue = [getFromContext(ctx, DateValue)].filter((v) => !!v);
        const values = [...durationValues, ...dateValue];

        return {
          field: getFromContext(ctx, DateField),
          operator: getFromContext(ctx, RelationalOperator),
          values: values.length === 0 ? null : values,
          ruleName: 'dateExpression',
          completed: dateValue.length === 1 || durationValues.length === 2,
        };
      }

      durationExpression(ctx): IFieldExpression {
        const durationValues = [getFromContext(ctx, NumericValue), getFromContext(ctx, TimeUnit)].filter((v) => !!v);
        return {
          field: getFromContext(ctx, DurationField),
          operator: getFromContext(ctx, RelationalOperator),
          values: durationValues.length === 0 ? null : durationValues,
          ruleName: 'durationExpression',
          completed: durationValues.length === 2,
        };
      }

      tagFieldExpression(ctx): IFieldExpression {
        const value = getArrayFromContext(ctx, StringValue);
        return {
          field: getFromContext(ctx, TagField),
          operator: getFromContext(ctx, StringOperator),
          values: value,
          ruleName: 'tagFieldExpression',
          completed: value != null && value.length === 1,
        };
      }

      numericExpression(ctx): IFieldExpression {
        if (ctx.regularNumericExpression) {
          return this.visit(ctx.regularNumericExpression);
        } else if (ctx.equalityNumericExpression) {
          return this.visit(ctx.equalityNumericExpression);
        } else {
          return null;
        }
      }

      regularNumericExpression(ctx): IFieldExpression {
        const value = getArrayFromContext(ctx, NumericValue);
        return {
          field: getFromContext(ctx, NumericField),
          operator: getFromContext(ctx, RelationalOperator),
          values: value,
          ruleName: 'regularNumericExpression',
          completed: value != null && value.length === 1,
        };
      }

      equalityNumericExpression(ctx): IFieldExpression {
        const value = getArrayFromContext(ctx, NumericValue);
        return {
          field: getFromContext(ctx, EqualityNumericField),
          operator: getFromContext(ctx, Equals, NotEquals),
          values: value,
          ruleName: 'equalityNumericExpression',
          completed: value != null && value.length === 1,
        };
      }
    }

    // create an instance of the visitor
    this.visitor = new DeviceFilterExpressionVisitorClass();
  }

  /**
   * Visits the CST Tree to return the list of field expressions
   */
  getFieldExpressionList(cstResult: any): IFieldExpression[] {
    return this.visitor.visit(cstResult);
  }
}

/** helper function to retrieve data */
const getFromContext = (ctx: any, ...tokens: TokenType[]): IToken => {
  for (const token of tokens) {
    if (ctx[token.name]) {
      const res: IToken = ctx[token.name][0];
      if (res.image) {
        return res;
      }
    }
  }
  return null;
};

const getArrayFromContext = (ctx: any, ...tokens: TokenType[]): IToken[] => {
  const res = getFromContext(ctx, ...tokens);
  if (res) {
    return [res];
  }
  return null;
};
