<ng-container *avnAsyncData="site$ | async; state: siteApiRequestState.dataState$ | async; errorTemplate: error; emptyTemplate: empty; loaderType: 'loader'">
  <amp-site-monitoring-detail [site]="site$ | async"></amp-site-monitoring-detail>
</ng-container>

<ng-template #error>
  <avn-empty-data-message
    icon="alert:error"
    [text]="'siteMonitoringSharedScope.SITE_MONITORING.SITE_DETAIL.FETCH_SITE_ERROR_TITLE_50' | transloco"
    [subText]="'siteMonitoringSharedScope.SITE_MONITORING.SITE_DETAIL.FETCH_SITE_ERROR_DESCRIPTION_200' | transloco"
  >
  </avn-empty-data-message>
</ng-template>

<ng-template #empty>
  <div class="site-not-found">
    <avn-empty-data-message
      icon="communication:location_off"
      [text]="'siteMonitoringSharedScope.SITE_MONITORING.SITE_DETAIL.FETCH_SITE_EMPTY_TITLE_50' | transloco"
      [subText]="'siteMonitoringSharedScope.SITE_MONITORING.SITE_DETAIL.FETCH_SITE_EMPTY_DESCRIPTION_200' | transloco"
    >
    </avn-empty-data-message>
  </div>
</ng-template>
