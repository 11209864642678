import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { AbstractControl, AsyncValidatorFn, FormControl, FormGroup, ReactiveFormsModule, ValidationErrors, Validators } from '@angular/forms';
import { ButtonModule, CoreModule, FormFieldModule, IconModule, InputModule, IOptionData, SelectModule, SwitchModule, ThemeType, TooltipModule } from '@activia/ngx-components';
import { TranslocoModule, TranslocoService } from '@ngneat/transloco';
import { combineLatest, debounceTime, distinctUntilChanged, Observable, of, startWith, Subject, takeUntil } from 'rxjs';
import {
  ConstraintTypeEnum as JsonSchemaConstraintTypeEnum,
  ConstraintTypeEnum,
  getJSONSchemaType,
  IConstraintTypeOption,
  IJsonSchema,
  JsonSchemaEditorComponent,
  JsonSchemaPropertyType,
} from '@activia/json-schema-forms';
import { IEngineTagKey } from '../../model/engine-tag-key.interface';
import { getTagDescriptionFromRawEditForm } from '../../utils/tag.util';
import { adjustTagKeyCustomRequirement, ConstraintTypes, EditTagRawForm, SCHEMA_TAG_SUFFIX, TagKeyCreationEnum } from '../../utils/schema-helper';
import { EngineTagLevel } from '../../model/operation-scope.interface';
import { IEditTagKeyForm } from '../../model/edit-tag-key-form.interface';
import { IEngineTagValue } from '../../model/tag-value.interface';
import { filter, map, tap } from 'rxjs/operators';
import { CommonModule } from '@angular/common';
import { FeaturesSharedTagOperationModule } from '../../features-shared-tag-operation.module';

@Component({
  selector: 'amp-create-tag-key',
  templateUrl: './create-tag-key.component.html',
  styleUrls: ['./create-tag-key.component.scss'],
  standalone: true,
  imports: [
    JsonSchemaEditorComponent,
    SelectModule,
    CommonModule,
    ReactiveFormsModule,
    JsonSchemaEditorComponent,
    TranslocoModule,
    ButtonModule,
    CoreModule,
    FeaturesSharedTagOperationModule,
    SwitchModule,
    IconModule,
    FormFieldModule,
    InputModule,
    TooltipModule,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CreateTagKeyComponent implements OnInit, OnDestroy {
  @ViewChild('jsonSchemaEditor', { static: true }) jsonSchemaEditor: JsonSchemaEditorComponent;

  /** Emits on save/create */
  @Output() actioned: EventEmitter<IEngineTagKey> = new EventEmitter<IEngineTagKey>();

  @Input() keyList$: Observable<IEngineTagKey[]>;

  /** Specifies which level the tag should be created. If not specified, UI shows a dropdown to select this option */
  @Input() set tagLevel(tagLevel: EngineTagLevel) {
    this._initialTagLevel = tagLevel || EngineTagLevel.SITE;
  }

  get tagLevel(): EngineTagLevel {
    return this.tagForm?.controls.level?.value;
  }

  /**
   * Setter for the restrictTypes property.
   * When restrictTypes is set to true, the schemaTypeOptions array is filtered to include only certain basic types.
   */
  @Input() set restrictTypes(restrictTypes: boolean) {
    if (restrictTypes) {
      const basicTypes = [ConstraintTypeEnum.alphanumeric, ConstraintTypeEnum.numeric, ConstraintTypeEnum.specific];
      this.schemaTypeOptions = this.schemaTypeOptions.filter((value) => basicTypes.includes(value));
    }
  }

  /** Keylist used to prohibit key names that already exist */
  @Input() keyList: IEngineTagKey[];

  /** (Optional) Specifies if tag allow multiple value. If not specified, UI shows a switch to select this option */
  @Input() allowMultipleValue: boolean;

  /** Enable Level Change, so the user can select one of the level dropdown options, default is true */
  @Input() enableLevelChange = true;

  /** List of all tag keys that already exist */
  existingKeys$: Observable<string[]>;
  /** Choose schema type to create */
  schemaType: ConstraintTypeEnum;

  previewForm$ = new Observable<EditTagRawForm>();
  tagKeyCreationEnum = TagKeyCreationEnum;
  themeType = ThemeType;
  tagForm: FormGroup<IEditTagKeyForm>;
  schemaTypeOptions: ConstraintTypeEnum[] = ConstraintTypes;
  schemaEditorConstraintTypeOptions: IConstraintTypeOption[];
  tagLevelOptions: IOptionData<void>[] = [
    { label: this._translate.translate('tagOperation.TAG_OPERATION.TAG_LIST.LEVEL.SITE'), value: EngineTagLevel.SITE },
    { label: this._translate.translate('tagOperation.TAG_OPERATION.TAG_LIST.LEVEL.BOARD'), value: EngineTagLevel.BOARD },
    {
      label: this._translate.translate('tagOperation.TAG_OPERATION.TAG_LIST.LEVEL.SCREEN'),
      value: EngineTagLevel.SCREEN,
    },
  ];

  private _componentDestroyed$: Subject<void> = new Subject<void>();
  private _initialTagLevel: EngineTagLevel = EngineTagLevel.SITE;

  constructor(private _translate: TranslocoService) {}

  ngOnInit(): void {
    this.tagForm = this._initialState();
    this.previewForm$ = this.tagForm.valueChanges.pipe(
      map((formValue) => this._normalizePreviewSchema(formValue as EditTagRawForm)),
      distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b)),
      startWith(
        this._normalizePreviewSchema({
          dynamic: false,
          jsonSchema: {
            examples: [''],
            pattern: '^[a-zA-Z0-9]*$',
            type: 'string',
          },
          level: 'site',
          tagKey: '',
        } as EditTagRawForm)
      )
    );
    this.tagForm.valueChanges.pipe(debounceTime(1), takeUntil(this._componentDestroyed$)).subscribe(() => this._handleFormChanges());
    if (this.keyList$) {
      this.existingKeys$ = this._getExistingKeys$();
      this._updateTagKeyValidator();
    }
    this.schemaEditorConstraintTypeOptions = this.schemaTypeOptions.map((type) => ({
      value: JsonSchemaConstraintTypeEnum[type],
      label: this._translate.translate('tagOperation.TAG_OPERATION.TAG_TYPES.' + type.toUpperCase() + '_NAME_20'),
      description: this._translate.translate('tagOperation.TAG_OPERATION.TAG_TYPES.' + type.toUpperCase() + '_DESC_100'),
    }));
  }

  ngOnDestroy(): void {
    this._componentDestroyed$.next();
    this._componentDestroyed$.complete();
  }

  resetForm(): void {
    this.tagForm.reset();
  }

  onTagLevelChanged(option: IOptionData<void> | IOptionData<void>[]): void {
    this.tagForm.controls.level.setValue((option as IOptionData<void>).value);
  }

  /** Create tag key object based on user input */
  createTagKey(): void {
    const tagKey = this._getTagKeyFromForm(this.tagForm);
    this.actioned.emit(tagKey);
  }

  convertToTagValue(tagValue: EditTagRawForm): IEngineTagValue {
    return {
      key: tagValue?.tagKey,
      propertyType: tagValue?.level,
      values: (tagValue.jsonSchema?.items || tagValue.jsonSchema)?.default || null,
      keyDescription: getTagDescriptionFromRawEditForm(tagValue, this.jsonSchemaEditor?.constraintType === JsonSchemaConstraintTypeEnum.schema),
    } as IEngineTagValue;
  }

  private _handleFormChanges(): void {
    this.schemaType = getJSONSchemaType(this.tagForm.controls.jsonSchema?.value?.items || this.tagForm.controls.jsonSchema?.value);
  }

  /**
   * Massages the data for preview, currently only needed for json tags,
   * adds .json to key to enable textarea and stringifys the default content
   */
  private _normalizePreviewSchema(form: EditTagRawForm): EditTagRawForm {
    if (this.jsonSchemaEditor.constraintType !== JsonSchemaConstraintTypeEnum.schema) {
      return form;
    }
    const defaultVal = form.jsonSchema?.items?.default || form.jsonSchema?.default;
    const stringifyDefault = JSON.stringify(defaultVal);
    const tagKey = form.tagKey.match(/(.json)$/i) ? form.tagKey : `${form.tagKey}.json`;

    const newJsonSchema = form.jsonSchema?.items
      ? {
          ...form.jsonSchema,
          items: {
            ...form.jsonSchema.items,
            default: stringifyDefault,
          },
        }
      : { ...form.jsonSchema, default: stringifyDefault };

    return {
      ...form,
      ...(defaultVal ? { jsonSchema: newJsonSchema } : {}),
      tagKey,
    };
  }

  private _updateTagKeyValidator(): void {
    this.existingKeys$
      .pipe(
        filter((existingKeys: string[]) => existingKeys.length > 0),
        distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b)),
        tap((existingKeys: string[]) => {
          this.tagForm.controls.tagKey?.setAsyncValidators([this._isKeyUniqueValidator(this.tagLevel, existingKeys)]);
          this.tagForm.controls.tagKey?.updateValueAndValidity();
        }),
        takeUntil(this._componentDestroyed$)
      )
      .subscribe();
  }

  private _getTagKeyFromForm(tagForm: FormGroup): IEngineTagKey {
    return {
      key: adjustTagKeyCustomRequirement(tagForm.controls.tagKey?.value, this.schemaType),
      level: tagForm.controls.level?.value,
      description: getTagDescriptionFromRawEditForm(tagForm.getRawValue(), this.jsonSchemaEditor?.constraintType === JsonSchemaConstraintTypeEnum.schema),
    } as IEngineTagKey;
  }

  private _initialState(): FormGroup<IEditTagKeyForm> {
    return new FormGroup<IEditTagKeyForm>({
      tagKey: new FormControl<string>('', [Validators.required, Validators.pattern(/^[A-Za-z0-9_]*$/)]),
      level: new FormControl<EngineTagLevel>(
        {
          value: this._initialTagLevel,
          disabled: !this.enableLevelChange,
        },
        Validators.required
      ),
      dynamic: new FormControl<boolean>(false),
      jsonSchema: new FormControl<IJsonSchema>(null),
    });
  }

  private _isKeyUniqueValidator(tagLevel: EngineTagLevel, existingKeys: string[]): AsyncValidatorFn {
    if (!tagLevel) {
      return;
    }
    return (control: AbstractControl): Observable<ValidationErrors> =>
      of(existingKeys).pipe(
        map((keys: string[]) => keys.some((a) => a === control.value)),
        map((result: boolean) => (result ? { keyAlreadyExist: true } : null))
      );
  }

  private _getExistingKeys$(): Observable<string[]> {
    return combineLatest([this.keyList$, this.tagForm.controls.level.valueChanges.pipe(startWith(this.tagLevel))]).pipe(
      map(([tagList, level]: [IEngineTagKey[], EngineTagLevel]) => {
        const filteredTagsByLevel = tagList.filter((tag) => tag.level === level);
        return filteredTagsByLevel.map((tag) => this._removeTagKeyCustomRequirement(tag.key, (tag?.description?.schema as any)?.type));
      })
    );
  }

  private _removeTagKeyCustomRequirement = (keyName: string, type: JsonSchemaPropertyType) =>
    !!keyName && (type === 'object' || type === 'array') ? keyName.slice(0, keyName.lastIndexOf(SCHEMA_TAG_SUFFIX)) : keyName;
}
