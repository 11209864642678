import { IExpressionSuggestionsResult, InputComponent, NlpOverlayComponent, NlpOverlayService } from '@activia/ngx-components';
import { AfterViewInit, ChangeDetectionStrategy, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { asapScheduler, fromEvent, Observable, Subject } from 'rxjs';
import { distinctUntilChanged, filter, map, takeUntil } from 'rxjs/operators';

/**
 * nlp custom picker:
 *
 *  - for a single numeric / float value input
 */
@Component({
  selector: 'amp-nlp-numeric-value-picker',
  templateUrl: './nlp-numeric-value-picker.component.html',
  styleUrls: ['./nlp-numeric-value-picker.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NlpNumericValuePickerComponent extends NlpOverlayComponent<number> implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild(InputComponent, { static: true }) inputComponent: InputComponent;

  /** The unit name for the numeric value */
  labelKey$: Observable<string>;

  numeric: FormControl;

  /** @ignore pattern for destroy all subscriptions **/
  private _componentDestroyed$: Subject<void> = new Subject<void>();

  constructor(private nlpOverlayService: NlpOverlayService) {
    super(nlpOverlayService);
    this.numeric = new FormControl(undefined);
  }

  ngOnInit(): void {
    this.activated$
      .pipe(
        map(() => this.value),
        takeUntil(this._componentDestroyed$)
      )
      .subscribe((value) => {
        this.numeric.patchValue(value);
      });

    this.labelKey$ = this.nlpOverlayService.expressionSuggestionsResult$.pipe(
      map((expression: IExpressionSuggestionsResult) => expression.currentFieldExpression?.field?.tokenType?.name),
      distinctUntilChanged(),
      map((fieldName: string) => {
        let res;
        switch (fieldName) {
          case 'EncTemperatureIn':
          case 'EncTemperatureMin':
          case 'EncTemperatureMax':
          case 'EncTemperatureAim':
            res = 'NLP.CUSTOM_UNIT.CELCIUS';
            break;
          case 'EncPressure':
            res = 'NLP.CUSTOM_UNIT.PASCAL';
            break;
          case 'EncPower0':
          case 'EncPower1':
            res = 'NLP.CUSTOM_UNIT.AMPERES';
            break;
          default:
            break;
        }
        return res;
      })
    );
  }

  ngAfterViewInit(): void {
    asapScheduler.schedule(() => this.inputComponent.inputElement.nativeElement.focus());

    fromEvent<KeyboardEvent>(this.inputComponent.inputElement.nativeElement, 'keydown')
      .pipe(
        filter((event) => event.key === 'Enter'),
        takeUntil(this._componentDestroyed$)
      )
      .subscribe((event: KeyboardEvent) => {
        event.preventDefault();
        event.stopPropagation();
        this.nlpOverlayService.setValueSelected(`${this.numeric.value}`);
      });
  }

  /** @ignore parses the value from the expression parsing results **/
  parseValue(res: IExpressionSuggestionsResult): number {
    if (!res.currentFullFieldExpression.values || res.currentFullFieldExpression.values.length === 0) {
      return 0;
    }
    return Number(res.currentFullFieldExpression.values[0].image);
  }

  /** @ignore **/
  ngOnDestroy(): void {
    this._componentDestroyed$.next();
    this._componentDestroyed$.complete();
  }
}
