import { TranslocoService } from '@ngneat/transloco';
import { ChangeDetectionStrategy, Component, OnChanges, SimpleChanges } from '@angular/core';
import { CountAggregationData } from './../../../model/site-monitoring-data.interface';
import { HealthStatusCode, HealthStatusThemes } from '@amp/devices';
import { IPieDataItem, themeTypeToCssVar } from '@activia/dataviz';
import { ISiteMonitoringKeyMetricComponent, KeyMetricsBaseMixin } from '../key-metrics.mixin';
import { ISiteMonitoringKeyMetricOptimisticSettings } from '../../../model/key-metrics/site-monitoring-key-metric-optimistic-settings.interface';
import { enumToMap } from '@activia/ngx-components';
import { healthSummaryKeyMetricConfig } from '../key-metric-viewer-config.constant';

@Component({
  selector: 'amp-key-metrics-health-summary',
  templateUrl: './key-metrics-health-summary.component.html',
  styleUrls: ['./key-metrics-health-summary.component.scss'],
  inputs: ['config', 'monitoringData', 'monitoringDate', 'monitoringDataDataState', 'showCompactView'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class KeyMetricsHealthSummaryComponent extends KeyMetricsBaseMixin<ISiteMonitoringKeyMetricOptimisticSettings>() implements ISiteMonitoringKeyMetricComponent, OnChanges {
  /** The datasource for the health status chart **/
  healthStatusDataSource: IPieDataItem[] = [];

  compactViewInfo = healthSummaryKeyMetricConfig;

  constructor(private translate: TranslocoService) {
    super();
  }

  ngOnChanges({ monitoringData }: SimpleChanges) {
    if (!this.showCompactView) {
      if (monitoringData && monitoringData.currentValue) {
        this.healthStatusDataSource = this._toHealthStatusDataSource(this.monitoringData as CountAggregationData);
      }
    }
  }

  /** @ignore Converts the monitoring values to pie datasource **/
  private _toHealthStatusDataSource(healthStatusData: CountAggregationData<HealthStatusCode, number>): IPieDataItem[] {
    // create a pie item for each status code that has a count
    const pieData: IPieDataItem[] = [...enumToMap<number>(HealthStatusCode).entries()].map(([name, code]) => ({
      value: healthStatusData[code] || 0,
      name: this.translate.translate(`siteMonitoringSharedScope.SITE_MONITORING.GLOBAL.HEALTH_STATUS.${name}.TITLE_20`),
      color: themeTypeToCssVar(HealthStatusThemes[code]),
    }));
    return pieData.filter((status) => status.value > 0);
  }

  // return color with priority order: danger > warning > ok > unreachable > not monitored
  dataDisplayTypeCode(): string {
    const statuses = Object.keys(this.monitoringData).map((status) => +status);
    return statuses.includes(HealthStatusCode.ERROR)
      ? HealthStatusThemes[HealthStatusCode.ERROR]
      : statuses.includes(HealthStatusCode.WARNING)
      ? HealthStatusThemes[HealthStatusCode.WARNING]
      : statuses.includes(HealthStatusCode.OK)
      ? HealthStatusThemes[HealthStatusCode.OK]
      : statuses.includes(HealthStatusCode.UNREACHABLE)
      ? HealthStatusThemes[HealthStatusCode.UNREACHABLE]
      : statuses.includes(HealthStatusCode.NOT_MONITORED)
      ? HealthStatusThemes[HealthStatusCode.NOT_MONITORED]
      : null;
  }
}
