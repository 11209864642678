<form class="experience-template-provision-row-form" [formGroup]="deviceFormGroup">
  <div class="experience-template-provision-body-cell">
    <avn-form-field>
      <avn-input
        formControlName="name"
        [debounceTime]="300"
        [placeholder]="'siteManagementScope.SITE_MANAGEMENT.EXPERIENCE_TEMPLATE.CREATION_MODAL.PLACEHOLDER_DEVICE_HOSTNAME_60' | transloco"
      ></avn-input>
    </avn-form-field>
  </div>
  <div class="experience-template-provision-body-cell">
    <avn-form-field>
      <avn-input formControlName="ip" [debounceTime]="300" [placeholder]="'1.1.1.1'"></avn-input>
    </avn-form-field>
  </div>
  <div class="experience-template-provision-body-cell">
    <avn-form-field>
      <avn-select formControlName="model" [options]="modelsOptions"></avn-select>
    </avn-form-field>
  </div>
</form>
