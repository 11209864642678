import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { switchMap } from 'rxjs/operators';
import { take } from 'rxjs/operators';

import { AuthFacade } from './store/auth.facade';

@Injectable({ providedIn: 'root' })
export class AuthGuard  {
  constructor(private authFacade: AuthFacade) {}

  /**
   * Checks the authenticated user to determine whether the user can access the content or not
   */
  canActivate(): Observable<boolean> {
    return this.authFacade.authStateReady$.pipe(
      take(1),
      switchMap(() => this.authFacade.authenticatedUser$),
      switchMap((authenticatedUser) => {
        if (authenticatedUser) {
          // authenticated user found so return true
          return of(true);
        }

        // not auth user found, logout the user
        this.authFacade.logoutCurrentUser();
        return of(false);
      }),

      // if something went wrong, logout the user
      catchError((_) => {
        this.authFacade.logoutCurrentUser();
        return of(false);
      })
    );
  }
}
