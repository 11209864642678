/**
 * Enum that represents aggregation types for a column
 */
export enum AggregationType {
  Min = 'Min',
  Max = 'Max',
  Average = 'Average',
  Sum = 'Sum',
  All = 'All',
}
