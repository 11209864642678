import { ChangeDetectionStrategy, Component, forwardRef, OnDestroy, OnInit } from '@angular/core';
import { IKeyMetricSettingsComponent, KeyMetricsSettingsBaseMixin } from '../key-metrics-settings.mixin';
import { ISiteMonitoringKeyMetricShowTooltipSettings } from '../../../../../model/key-metrics/site-monitoring-key-metric-show-tooltip-settings.interface';
import { UntypedFormBuilder } from '@angular/forms';

@Component({
  selector: 'amp-key-metrics-show-tooltip-settings',
  templateUrl: './key-metrics-show-tooltip-settings.component.html',
  styleUrls: ['./key-metrics-show-tooltip-settings.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  inputs: ['settings'],
  outputs: ['settingsChanged'],
  providers: [
    {
      provide: IKeyMetricSettingsComponent,
      useExisting: forwardRef(() => KeyMetricsShowTooltipSettingsComponent),
    },
  ],
})
export class KeyMetricsShowTooltipSettingsComponent extends KeyMetricsSettingsBaseMixin<ISiteMonitoringKeyMetricShowTooltipSettings>() implements IKeyMetricSettingsComponent, OnInit, OnDestroy {

  constructor(private _formBuilder: UntypedFormBuilder) {
    super();
  }

  ngOnInit(): void {
    this.form = this._formBuilder.group({ showTooltip: [!!this.settings?.showTooltip] });
  }

  ngOnDestroy(): void {
    // unsubscribe all subscriptions
    this.onDestroy();
  }
}
