import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { DurationPipe, I18nFormattedValuePipe } from '@amp/ui';
import { NestedPropertyPipe, toRelativeTime } from '@activia/ngx-components';
import { CurrencyPipe, DatePipe } from '@angular/common';
import { DisplayType } from '@amp/column-picker';
import { DeviceProperty } from '../model/device-properties.enum';
import { MonitoredValue } from '../model/monitored-value.enum';
import { isPlayerState, percentValidation } from '../utils/device-field-template.utils';
import { ContentErrorIdNames } from '../model/content-status-error-ids.enum';
import { DevicePropertiesStatusThemeMap } from '../model/device-properties-status.enum';
import { HealthErrorIdNames } from '../model/health-error-ids.enum';
import { HealthStatusAPINames } from '../model/health-status.enum';
import { DeviceColumnType } from '../model/device-column-type';
import { IMonitoringListDatum, IMonitoringSharedListDTO } from '../model/monitoring-list.interface';
import { getDeviceMonitoredValueDisplayType, getDevicePropertyDisplayType } from '../utils/device-columns.utils';
import { DEVICE_PROPERTIES_MAP } from '../model/device-columns-mapping';
import { IMonitoringListColumn } from '../model/monitoring-list-column.interface';

/**
 * Convert a device property or monitored value into user-friendly format.
 * Similar to what device-field-templates.component does.
 */
@Injectable({ providedIn: 'root' })
export class DeviceFieldTemplateService {
  constructor(
    @Inject(LOCALE_ID) private locale: string,
    private _translateService: TranslocoService,
  ) {}

  /**
   * Convert device properties and monitored values to the format defined by the columns in the list
   * with user-friendly message.
   */
  toExportableDevices(list: IMonitoringSharedListDTO, devices: IMonitoringListDatum[]): { columns: string[]; data: string[][] } {
    const columns = list.columns.map((column) => this._getColumnLabel(column));

    const csvDevices = devices.map((device) => {
      const csvDevice = list.columns.reduce((res, col) => {
        const displayType =
          col.type === DeviceColumnType.DeviceProperty ? getDevicePropertyDisplayType(col) : col.type === DeviceColumnType.MonitoredValue ? getDeviceMonitoredValueDisplayType(col) : col.displayType;
        const value =
          col.type === DeviceColumnType.DeviceProperty
            ? new NestedPropertyPipe().transform(device, `device.${DEVICE_PROPERTIES_MAP.get(col.value as DeviceProperty)}`)
            : col.type === DeviceColumnType.MonitoredValue
            ? device.monitoringData[`${col.value}`]
            : device.tagsData[`${col.value}`];
        res.push(this._formatDeviceFieldValue(displayType, col.value, value));
        return res;
      }, []);
      return csvDevice;
    });
    return { columns, data: csvDevices };
  }

  private _formatDeviceFieldValue(displayType: DisplayType, field: any, value: any): string {
    if (displayType) {
      return this._formatDeviceFieldByDisplayType(displayType, value);
    } else {
      return this._formatDeviceFieldByColumnId(field, value);
    }
  }

  private _getColumnLabel(col: IMonitoringListColumn): string {
    if (col.type === DeviceColumnType.DeviceProperty) {
      return col.displayName || this._translateService.translate(`deviceFields.DEVICE.PROPERTY.${col.value}.NAME`);
    } else if (col.type === DeviceColumnType.MonitoredValue) {
      return col.displayName || this._translateService.translate(`deviceFields.DEVICE.MONITORED_VALUE.${col.value}.NAME`);
    } else {
      return col.value;
    }
  }

  /** Format according to the display type */
  private _formatDeviceFieldByDisplayType(displayType: DisplayType, value: any): string {
    switch (displayType) {
      case DisplayType.Raw:
        return value;

      case DisplayType.Duration:
        return value ? new DurationPipe(this._translateService).transform(value) : '';

      case DisplayType.DateTime:
        return value ? new DatePipe(this.locale).transform(value, 'medium') : '';

      case DisplayType.TimeAgo:
        return toRelativeTime(value);

      case DisplayType.Percent:
        return value !== null && value !== undefined ? `${percentValidation(value)}%` : '';

      case DisplayType.Boolean:
        return value ? this._translateService.translate(`BOOLEAN.TRUE_10`) : this._translateService.translate(`BOOLEAN.FALSE_10`);

      case DisplayType.Numeric:
        return value || '0';

      case DisplayType.TwoDecimals:
        return value ? value.toFixed(2) : '0.00';

      case DisplayType.Currency:
        return new CurrencyPipe(this.locale).transform(value ? value : 0, 'CAD', 'symbol-narrow', '4.2-2');

      case DisplayType.TwoLines:
      case DisplayType.HostnameIp:
        return [value.line1, value.line2].map((val, idx) => (val || '').length > 0 ? idx === 1 ? `(${val})` : val : val).filter((val) => (val || '').length > 0).join(' ');

      case DisplayType.RawWithTooltip:
        return value;

      case DisplayType.HealthStatus:
        return this._formatHealthStatus(value);

      case DisplayType.HealthErrorIds:
        return this._formatHealthErrorIds(value);

      case DisplayType.Status:
        return this._formatStatus(value);

      case DisplayType.ServiceStatus:
        return value !== null && value !== undefined ? this._translateService.translate(`deviceFields.DEVICE.ENUM.SERVICE_STATUS_CODE.${value ? '1' : '0'}`) : '';

      case DisplayType.OperationalState:
        return this._formatOperationalState(value);

      case DisplayType.PercentChart:
        return this._formatPercentage(value);

      case DisplayType.PercentPieChart:
        return value !== null && value !== undefined ? `${percentValidation(value)}%` : '';

      case DisplayType.PlayerVersion:
        return this._formatPlayerVersion(value);

      case DisplayType.ContentStatus:
        return this._translateService.translate(`deviceFields.DEVICE.ENUM.CONTENT_STATUS.${value}`);

      case DisplayType.ContentStatusErrorIds:
        return this._formatContentErrorIds(value);

      case DisplayType.DevicePropertiesStatus:
        return this._translateService.translate(`deviceFields.DEVICE.ENUM.DEVICE_PROPERTIES_STATUS_COMBINED.${DevicePropertiesStatusThemeMap.get(value)}`);
    }
  }

  /** Format according to the column id */
  private _formatDeviceFieldByColumnId(field: any, value: any): string {
    switch (field) {
      case MonitoredValue.Uptime:
        return value ? new DurationPipe(this._translateService).transform(value) : '';

      case MonitoredValue.SystemDisk:
        return this._formatPercentage(value);

      case MonitoredValue.CpuPercent:
        return this._formatPercentage(value);

      case MonitoredValue.SystemMemory:
        return this._formatPercentage(value);

      case MonitoredValue.OmnicastStatus:
        return this._formatStatus(value);

      case MonitoredValue.HttpService:
        return this._formatStatus(value);

      case MonitoredValue.FtpStatus:
        return this._formatStatus(value);

      case MonitoredValue.ServicePlayer:
        return this._formatStatus(value);

      case MonitoredValue.PlayerState:
        return this._formatStatus(value);

      case MonitoredValue.ContentState:
        return this._formatStatus(value);

      case MonitoredValue.PlayerVersion:
        return this._formatPlayerVersion(value);

      case MonitoredValue.HealthStatus:
        return this._formatHealthStatus(value);

      case MonitoredValue.HealthErrorIds:
        return this._formatHealthErrorIds(value);

      case MonitoredValue.ProxyByteHitRatio:
        return this._formatPercentage(value);

      case MonitoredValue.ProxyHitRatio:
        return this._formatPercentage(value);

      case MonitoredValue.ProxyMemory:
        return this._formatPercentage(value);

      case DeviceProperty.OperationalState:
        return this._formatOperationalState(value);

      default:
        if (isPlayerState(field)) {
          return this._formatStatus(value);
        } else {
          return value;
        }
    }
  }

  private _formatHealthStatus(value: any): string {
    return value !== undefined && value !== null ? this._translateService.translate(`deviceFields.DEVICE.ENUM.HEALTH_STATUS.${HealthStatusAPINames[value]}`) : '';
  }

  private _formatHealthErrorIds(value: any): string {
    return (value?.length > 0 ? value.split(',') : []).map((error) => this._translateService.translate(`deviceFields.DEVICE.ENUM.HEALTH_ERROR_IDS.${HealthErrorIdNames[error]}`)).join(', ');
  }

  private _formatContentErrorIds(value: any): string {
    return (value?.length > 0 ? value.split(',') : []).map((error) => this._translateService.translate(`deviceFields.DEVICE.ENUM.CONTENT_STATUS_ERROR_IDS.${ContentErrorIdNames[error]}`)).join(', ');
  }

  private _formatStatus(value: any): string {
    return value !== undefined && value !== null
      ? value
        ? this._translateService.translate('deviceFields.DEVICE.ENUM.OTHER_STATUS.OK')
        : this._translateService.translate('deviceFields.DEVICE.ENUM.OTHER_STATUS.ERROR')
      : '';
  }

  private _formatPlayerVersion(value: any): string {
    return value !== undefined && value !== null ? (value.description ? `${value.versionCode} (${value.description})` : value.versionCode) : '';
  }

  private _formatOperationalState(value: any): string {
    return value !== undefined && value !== null ? this._translateService.translate(`deviceFields.DEVICE.ENUM.OPERATIONAL_STATE.${new I18nFormattedValuePipe().transform(value)}`) : '';
  }

  private _formatPercentage(value: any): string {
    return value !== undefined && value !== null ? `${value >= 0 ? value : 0}%` : '';
  }
}
