import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { ISiteSyncProgress } from '../../store/site-sync/site-sync.model';
import { ThemeType } from '@activia/ngx-components';
import { SiteSyncStatusIcons, SiteSyncStatusThemes } from '../../models/site-management-sync';

@Component({
  selector: 'amp-site-sync-progress',
  templateUrl: './site-sync-progress.component.html',
  styleUrls: ['./site-sync-progress.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SiteSyncProgressComponent implements OnChanges {
  /** info about progress of each step of the sync process status. site must be valid. **/
  @Input() syncProgress: ISiteSyncProgress;

  showStatusIcon: boolean;
  icon: string;
  iconTheme: ThemeType;
  percentCompletion: number;
  isQueued: boolean;
  showProgressBar: boolean;
  progressBarTheme: ThemeType;

  ngOnChanges({ syncProgress }: SimpleChanges): void {
    if (syncProgress) {
      const syncProgressValue: ISiteSyncProgress = syncProgress.currentValue;
      const { status } = syncProgressValue;
      this.icon = SiteSyncStatusIcons[status];
      this.iconTheme = SiteSyncStatusThemes[status];
      this.showStatusIcon = !!this.icon;
      this.percentCompletion = syncProgressValue.progress;
      this.isQueued = status === 'queued';
      this.showProgressBar = !this.isQueued;
      this.progressBarTheme = status === 'error' ? ThemeType.DANGER : status === 'warning' ? ThemeType.WARNING : ThemeType.SUCCESS;
    }
  }
}
