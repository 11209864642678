import { ThemeService } from '@activia/ngx-components';
import { WHITE_LABEL_TOKEN } from '@amp/global';
import { enableProdMode, NgModuleRef } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from './app/app.module';
import { AMP_CONFIGURATION_TOKEN } from './app/config/amp-config-initializer';
import { environment } from './environments/environment';
declare const __BUST__;
if (environment.production) {
  enableProdMode();
}

/* This is important, it loads amp-config from the a server dynamically (replacing angular environments  )
 * Usually app initializers https://angular.io/api/core/APP_INITIALIZER are the recommended solution.
 * However, they won't work when used by other providers or when another service injects them
 * since they are subject to race conditions between the bootstrap delay and invocation of the constructors of the services.
 * see: https://github.com/angular/angular/issues/23279
 */

const fetchAmpConfigs = async () => {
  const data = await Promise.all([
    fetch(`${environment.configPath}?v${__BUST__}`).then((response) => {
      if (response.ok) {
        // todo sanity check to ensure that config has the expected values and formats
        return response.json();
      } else {
        // we add default config here.
        // these match the 5.1 release defaults with port and assume that ui is on the same host as amp engine
        // todo 5.3+ this would be handled server side or as an nginx config.

        // a bit of overkill but makes window usage slightly more tolerable
        // if somehow we are not in a browser environment we error out.
        if (!window) {
          const errorMsg = `could not load config from ${environment.configPath}. Expected OK but got ${response.status}.
              Please check the deploy.md for instructions on how to add a config file for amp ui.
            `;
          applyNoConfigFallBack();
          throw new Error(errorMsg);
        }

        const locationOrigin = `${window.location.protocol}//${window.location.hostname}`;
        const locationHost = window.location.hostname;

        const defaultConf = {
          cmEngineApiUrl: `${locationOrigin}:8093`,
          legacyUrl: `${locationOrigin}:8443/`,
          grafanaUrl: `${locationOrigin}:3001`,
          ampEngineWebsocketUrl: `wss://${locationHost}:8093/activia/socket/`,
          pipUrl: `${locationOrigin}:8443`,
        };

        // info message as best practice is to have an amp-config.json file.
        const warnMsg = `Amp ui is running on default config since we could not find a config from ${environment.configPath}. Expected OK but got ${response.status}.
            Please check the deploy.md for instructions on how to add a config file for amp ui.
          `;

        console.warn(warnMsg);

        return defaultConf;
      }
    }),
  ]);
  return data;
};

const fetchWhiteLabel = async () => {
  const data = await Promise.all([
    fetch(`${environment.whiteLabelPath}?v${__BUST__}`).then((response) => {
      if (response.ok) {
        return response.json();
      } else {
        return [];
      }
    }),
  ]);

  return data[0];
};

const applyNoConfigFallBack = () => {
  const message = document.querySelector('#preapp') as HTMLElement;
  message.innerText = 'could not load config';
};

Promise.all([fetchAmpConfigs().then((res) => (res = res[0])), fetchWhiteLabel()]).then(([ampConfigs, whiteLabel]) => {
  platformBrowserDynamic([
    { provide: AMP_CONFIGURATION_TOKEN, useValue: ampConfigs },
    { provide: WHITE_LABEL_TOKEN, useValue: whiteLabel },
  ])
    .bootstrapModule(AppModule)
    .then((ngModule: NgModuleRef<AppModule>) => {
      /** we assign the services that legacy needs to the window object. */
      const injector = ngModule.injector;
      window['themeService'] = injector.get(ThemeService);
    })
    .catch((err) => console.error(err));
});
