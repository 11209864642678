import { Pipe, PipeTransform } from '@angular/core';
import { ThemeType } from '@activia/ngx-components';
import { SITE_MONITORING_COLUMNS_THRESHOLDS } from '../model/site-monitoring-columns-threshold-themes';
import { themeTypeToCssVar } from '@activia/dataviz';

/**
 * This pipe returns the theme to apply based on the current value
 * e.g. for system memory:
 * - if value <= 70 return Success theme
 * - if value > 70 and <= 85 return Warning theme
 * - if value > 85 and <= 100 return Danger theme
 *
 * @param useCssVarName Set to true to receive value as the css variable, otherwise false
 */
@Pipe({ name: 'columnThresholdTheme' })
export class ColumnThresholdThemePipe implements PipeTransform {
  transform(value: number, field: string, useCssVarName = false): ThemeType | string {
    if (!field || value === undefined || value === null) {
      return null;
    }
    const thresholds = SITE_MONITORING_COLUMNS_THRESHOLDS[field];
    if (!thresholds || thresholds.length === 0) {
      return null;
    }

    // start with the highest threshold value and compare. if no threshold match, use the default theme
    const theme = [...thresholds].reverse().find((threshold) => !threshold.thresholdValue || threshold.thresholdValue < value)?.theme;
    return useCssVarName ? themeTypeToCssVar(theme) : theme;
  }
}
