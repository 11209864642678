import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { ColumnPickerComponent } from './column-picker.component';
import { ColumnPickerListComponent } from './column-picker-list/column-picker-list.component';
import { ColumnPickerOptionsSelectorComponent } from './column-picker-options-selector/column-picker-options-selector.component';
import { TreeviewModule, ModalModule, CoreModule, TooltipModule, IconModule, InputModule, ButtonModule } from '@activia/ngx-components';
import { TranslocoModule } from '@ngneat/transloco';
import { DragDropModule } from '@angular/cdk/drag-drop';

@NgModule({
  declarations: [ColumnPickerComponent, ColumnPickerListComponent, ColumnPickerOptionsSelectorComponent],
  imports: [CommonModule, TreeviewModule, ModalModule, CommonModule, TranslocoModule, DragDropModule, CoreModule, TooltipModule, IconModule, InputModule, ButtonModule],
  exports: [ColumnPickerComponent, ColumnPickerListComponent, ColumnPickerOptionsSelectorComponent],
})
export class ColumnPickerModule {}
