import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ISiteMonitoringKeyMetricComponent, KeyMetricsBaseMixin } from '../key-metrics.mixin';
import { posCardConfig } from '../key-metric-viewer-config.constant';

@Component({
  selector: 'amp-key-metrics-pos',
  templateUrl: './key-metrics-pos.component.html',
  styleUrls: ['./key-metrics-pos.component.scss'],
  inputs: ['config', 'monitoringData', 'monitoringDate', 'monitoringDataDataState', 'showCompactView'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class KeyMetricsPosComponent extends KeyMetricsBaseMixin() implements ISiteMonitoringKeyMetricComponent {
  cardConfig = posCardConfig;

  constructor() {
    super();
  }
}
