import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  Inject,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild
} from '@angular/core';
import {IDataTableColumnConfig, IDataTableDataSource, IModalComponent, ModalRef} from '@activia/ngx-components';
import {Observable, Subject} from 'rxjs';
import {TranslocoService} from '@ngneat/transloco';
import {map} from 'rxjs/operators';
import {DateTime} from 'luxon';
import {downloadData} from '@amp/utils/common';
import {DOCUMENT} from '@angular/common';
import {
  ExportDevicesTaskIcons,
  ExportDevicesTaskThemes,
  IExportDevicesTask
} from '../../model/export-devices-task.interface';
import {DevicesFacade} from '../../store/devices.facade';

@Component({
  selector: 'amp-export-devices-tasks-summary-modal',
  templateUrl: './export-devices-tasks-summary-modal.component.html',
  styleUrls: ['./export-devices-tasks-summary-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ExportDevicesTasksSummaryModalComponent implements OnInit, OnDestroy, AfterViewInit, IModalComponent {
  @ViewChild('deviceCountTemplate', { static: true }) deviceCountTemplate: TemplateRef<any>;
  @ViewChild('statusTemplate', { static: true }) statusTemplate: TemplateRef<any>;
  @ViewChild('actionsTemplate', { static: true }) actionsTemplate: TemplateRef<any>;

  columns: IDataTableColumnConfig<void>[];
  columns$: Observable<IDataTableColumnConfig<void>[]>;
  dataSource$: Observable<IDataTableDataSource<IExportDevicesTask>>;

  ExportDevicesTaskIcons = ExportDevicesTaskIcons;
  ExportDevicesTaskThemes = ExportDevicesTaskThemes;

  private _componentDestroyed$: Subject<void> = new Subject<void>();

  constructor(
    @Inject(DOCUMENT) private _document: Document,
    private _translocoService: TranslocoService,
    private _devicesFacade: DevicesFacade,
    private _dialogRef: ModalRef<ExportDevicesTasksSummaryModalComponent>,
  ) { }

  ngOnInit() {
    this.dataSource$ = this._devicesFacade.exportTasks$.pipe(
      map((tasks) => ({
        rows: tasks,
        totalRowCount: tasks.length,
      }))
    );
  }

  ngAfterViewInit() {
    this.columns = this._setColumns();
  }

  /** @ignore **/
  ngOnDestroy(): void {
    this._componentDestroyed$.next();
    this._componentDestroyed$.complete();
  }

  canClose(): boolean {
    return true;
  }

  onClose() {
    this._dialogRef.close();
  }

  downloadCsv(row: IExportDevicesTask) {
    const contentType = 'text/csv;charset=utf-8';
    const dateFormat = 'yyyyMMdd-HHmmss-SSS';
    const filename = `${row.list.name}_${DateTime.now().toFormat(dateFormat)}.csv`;
    downloadData(this._document, filename, row.csvData, contentType);
  }

  private _setColumns(): IDataTableColumnConfig<void>[] {
    const columns: IDataTableColumnConfig<void>[] = [
      {
        id: 'listName',
        name: this._translocoService.selectTranslate('deviceFields.DEVICE.EXPORT_CSV.SUMMARY_MODAL.COLUMNS.LIST_50'),
        prop: 'list.name',
        sortable: false,
        resizable: false,
        draggable: false,
      },
      {
        id: 'deviceCount',
        name: this._translocoService.selectTranslate('deviceFields.DEVICE.EXPORT_CSV.SUMMARY_MODAL.COLUMNS.DEVICE_COUNT_50'),
        dataCellTemplate: this.deviceCountTemplate,
        sortable: false,
        resizable: false,
        draggable: false,
        widthPx: 200,
      },
      {
        id: 'status',
        name: this._translocoService.selectTranslate('deviceFields.DEVICE.EXPORT_CSV.SUMMARY_MODAL.COLUMNS.STATUS_50'),
        dataCellTemplate: this.statusTemplate,
        sortable: false,
        resizable: false,
        draggable: false,
        widthPx: 150,
      },
      {
        id: 'actions',
        name: this._translocoService.selectTranslate('deviceFields.DEVICE.EXPORT_CSV.SUMMARY_MODAL.COLUMNS.DOWNLOAD_50'),
        dataCellTemplate: this.actionsTemplate,
        sortable: false,
        resizable: false,
        draggable: false,
        widthPx: 150,
      },
    ];
    return columns;
  }
}
