import {
  dataOnceReady,
  IDataTableColumnConfig,
  IDataTableDataSource,
  ModalService,
  ModalType,
} from '@activia/ngx-components';
import { Overlay } from '@angular/cdk/overlay';
import { ChangeDetectionStrategy, Component, OnDestroy, TemplateRef, ViewChild } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import {
  BehaviorSubject, combineLatest,
  Observable,
  Subject,
} from 'rxjs';
import { map, take, takeUntil } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { ISiteManagementState } from '../../store/site-management.reducer';
import { GEODETIC_SITE_COLS } from '../models/site-column';
import { SiteColumnsService } from '../services/site-columns.service';
import { IGeoFixerSite, IGeoFixerValidationStatus } from '../../models/geo-fixer-site.interface';
import { SyncModalService } from '../../services/sync-modal.service';
import { geoFixerEntities } from '../../store/geo-fixer/geo-fixer.selectors';
import { GeoFixerFilter, GeoFixerFilters } from './geodetic-fixer-filter.constants';
import * as GeoFixerAction from '../../store/geo-fixer/geo-fixer.actions';
import { SiteManagementSettingsDrawerComponent } from '../../components/experience-template/site-management-settings-drawer/site-management-settings-drawer.component';
import { AuthFacade, SITE_MANAGEMENT_ROLES } from '@amp/auth';
import { LocationEditorModalComponent } from '../../components/site-management-location/location-editor-modal/location-editor-modal.component';
import * as SiteManagementAction from '../../store/site-management.actions';

@Component({
  selector: 'amp-geodetic-fixer',
  templateUrl: './geodetic-fixer.component.html',
  styleUrls: ['./geodetic-fixer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GeodeticFixerComponent implements OnDestroy {
  /** Template for the datatable action icons **/
  @ViewChild('siteListWarnings', { static: false }) siteListWarningsTemplate: TemplateRef<void>;
  @ViewChild('siteListWarningAction', { static: false }) siteListWarningActionTemplate: TemplateRef<void>;

  /** list of the sites to display */
  dataSource$: Observable<IDataTableDataSource<IGeoFixerSite>>;

  /** The recent devices data table columns configuration */
  columns$: Observable<IDataTableColumnConfig<void>[]>;

  /** display dataTable loader */
  isLoading$: Observable<boolean>;

  /** list of selected sites */
  selectedSitesList: IGeoFixerSite[] = [];

  currConnectedElementUid: string | number;

  /** All sites with incomplete location info */
  geoProblematicSites$: Observable<IGeoFixerSite[]>;

  /** Counts of sites in each type of warning **/
  counts$: Observable<Record<GeoFixerFilter, number>>;

  GeoFixerFilter = GeoFixerFilter;
  GeoFixerFilters = GeoFixerFilters;

  isAdmin$: Observable<boolean>;

  /** Subject that emits the current site filter **/
  private _filterSubject = new BehaviorSubject<GeoFixerFilter>('all');

  /** @ignore **/
  private _componentDestroyed$: Subject<void> = new Subject<void>();

  /** Gets the current filter applied **/
  get activeFilter(): GeoFixerFilter {
    return this._filterSubject.value;
  }

  constructor(
    private _overlay: Overlay,
    private _modalService: ModalService,
    public translateService: TranslocoService,
    private _store: Store<ISiteManagementState>,
    private _siteColumnService: SiteColumnsService,
    private _syncModalService: SyncModalService,
    private _authFacade: AuthFacade,
  ) {
    this.isAdmin$ = this._authFacade.hasAnyRole$(...SITE_MANAGEMENT_ROLES.appSettings);

    this.isLoading$ = this._store.pipe(geoFixerEntities.isLoadingAllGeoProblematicSites$);

    this.geoProblematicSites$ = dataOnceReady(this._store.pipe(geoFixerEntities.allGeoProblematicSites$), this._store.pipe(geoFixerEntities.allGeoProblematicSitesDataState$));

    this.dataSource$ = combineLatest([this.geoProblematicSites$, this._filterSubject]).pipe(
      map(([sitesToFix, siteFilter]) => {
        const statusToFilter: string[] = siteFilter === 'all' ? undefined : [siteFilter.toUpperCase()];
        const sites = statusToFilter ? sitesToFix.filter((site) => statusToFilter.includes(site.validationStatus)) : sitesToFix;
        const newDataSource: IDataTableDataSource<IGeoFixerSite> = {
          rows: sites,
          totalRowCount: sites.length,
        };
        return newDataSource;
      }),
    );

    this.counts$ = this.geoProblematicSites$.pipe(
      map((sites) => {
        const results: IGeoFixerValidationStatus[] = sites.map((site) => site.validationStatus);
        const initCounts: Record<GeoFixerFilter, number> = {
          all: 0,
          incomplete_address: 0,
          incomplete_geodetic_coordinates: 0,
          missing_timezone: 0,
        };

        const res = results.reduce((acc, curr) => {
          acc.all++;
          acc[curr.toLowerCase()]++;
          return acc;
        }, initCounts);

        return Object.values(res).every((count) => count === 0) ? null : res;
      }),
    );

    this.columns$ = this._siteColumnService.siteColumnsFormatted$(GEODETIC_SITE_COLS).pipe(map((allCols) => this._addActionColumns(allCols)));
  }

  /** @ignore **/
  ngOnDestroy(): void {
    this._componentDestroyed$.next();
    this._componentDestroyed$.complete();
  }

  isAutoFixable(selections: IGeoFixerSite[]): boolean {
    return !(selections || []).some((selection) => selection.validationSubStatus || selection.validationStatus === 'INCOMPLETE_ADDRESS');
  }

  /**
   * Open a modal to edit a site
   */
  onEditSite(site: IGeoFixerSite) {
    // Remove custom field from the site that is not part of SiteDTO
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { validationStatus, validationSubStatus, ...siteDTO } = site;
    const modalRef = this._modalService.open(
      LocationEditorModalComponent,
      {
        closeOnBackdropClick: true,
        showCloseIcon: true,
        data: { site: siteDTO, validateFormOnInitialLoad: true },
      },
      {
        width: '600px',
        panelClass: 'overlay-panel-class',
        positionStrategy: this._overlay.position().global().centerHorizontally().top('50px'),
      },
      ModalType.Dialog
    );

    modalRef.componentInstance.saved.pipe(takeUntil(modalRef.afterClosed)).subscribe((ste) => {
      this._store.dispatch(SiteManagementAction.UpdateSite({ site: ste }));
    });
  }

  autoFixSite(site: IGeoFixerSite) {
    this._autoFixSites([site]);
  }

  /** Sets the current filter **/
  updateFilter(siteFilter: GeoFixerFilter) {
    this._filterSubject.next(siteFilter);
  }

  autoFixSites() {
    this._autoFixSites(this.selectedSitesList);
  }

  exportSites() {
    this.dataSource$.pipe(
      take(1),
      takeUntil(this._componentDestroyed$)
    ).subscribe((dataSource) => {
      this._syncModalService.exportSites(this.selectedSitesList.length ? this.selectedSitesList : dataSource.rows);
    });
  }

  private _autoFixSites(sites: IGeoFixerSite[]) {
    // If a site has already been auto-fixed but turns out to have invalid address, filter them out so
    // no auto-fix attempt will be done on such site again. A site with invalid address needs to be fixed
    // manually by providing a new address
    const stes = sites.filter((s) => !s.validationSubStatus);
    this._store.dispatch(GeoFixerAction.AutoFixGeoProblematicSites({ sites: stes }));
  }

  /** define the column name */
  private _addActionColumns(cols: IDataTableColumnConfig<void>[]): IDataTableColumnConfig<any>[] {
    const columns = [
      ...cols,
      {
        id: 'SITE_LIST_WARNINGS',
        name: this.translateService.translate('siteManagementScope.SITE_MANAGEMENT.GLOBAL.WARNING_20'),
        dataCellTemplate: this.siteListWarningsTemplate,
        sortable: false,
        resizable: true,
        draggable: false,
      } as IDataTableColumnConfig<any>,
      {
        id: 'SITE_LIST_WARNINGS_ACTION',
        dataCellTemplate: this.siteListWarningActionTemplate,
        sortable: false,
        resizable: true,
        draggable: false,
        widthPx: 50,
        sticky: true,
        stickyPosition: 'right',
      } as IDataTableColumnConfig<any>,
    ];
    return columns;
  }

  public openSettingsDrawer(): void {
    this._modalService.openDrawer<SiteManagementSettingsDrawerComponent, any>(SiteManagementSettingsDrawerComponent, { closeOnBackdropClick: true });
  }
}
