import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { IGeoResult } from '@activia/geo';
import { IOptionData } from '@activia/ngx-components';

@Component({
  selector: 'amp-import-address-selector',
  templateUrl: './import-address-selector.component.html',
  styleUrls: ['./import-address-selector.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ImportAddressSelectorComponent {
  /** list of geo results found by Google Maps API */
  @Input() geoResultOptions: IOptionData<IGeoResult>[];

  /** Location of the site, either formatted address or formatted geo coordinates */
  @Input() siteLocation: string;

  /** Emit the selected value or null if user chooses to retain the original value provided */
  @Output() locationSelected = new EventEmitter<IGeoResult>();

  selectedLocation: IGeoResult;

  i18nPluralMessage: { [key: string]: string } = {
    '=0': 'siteManagementScope.SITE_MANAGEMENT.SYNC_SITES.ADDRESS_SELECTOR.SELECT_VALUE.SINGULAR_50',
    '=1': 'siteManagementScope.SITE_MANAGEMENT.SYNC_SITES.ADDRESS_SELECTOR.SELECT_VALUE.SINGULAR_50',
    other: 'siteManagementScope.SITE_MANAGEMENT.SYNC_SITES.ADDRESS_SELECTOR.SELECT_VALUE.PLURAL_50',
  };

  onSelectLocation() {
    this.locationSelected.emit(this.selectedLocation);
  }
}
