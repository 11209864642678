import { Routes } from '@angular/router';
import { AuthGuard } from '@amp/auth';
import { ErrorComponent } from '@amp/error';
import { RetrieveSessionGuard } from './guards/retrieve-session.guard';

export const ROUTES: Routes = [
  { path: '', redirectTo: 'app', pathMatch: 'full' },
  {
    path: 'app',
    loadChildren: () => import('./layout/layout.module').then((m) => m.LayoutModule),
    canActivate: [AuthGuard, RetrieveSessionGuard],
  },
  {
    path: 'login',
    loadChildren: () => import('./login/login.module').then((m) => m.LoginModule),
  },
  {
    path: 'error',
    component: ErrorComponent,
    data: { httpStatusCode: 404 },
  },
  {
    path: 'forbidden',
    component: ErrorComponent,
    data: { httpStatusCode: 403 },
  },
  {
    path: '**',
    redirectTo: 'error',
  },
];
