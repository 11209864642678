/**
 * Reference: https://confluence.stratacache.com/display/CM/AMP+Player+Alarms
 */
export enum AlarmEventNamespace {
  Player = 'Player',
  Display = 'Display',
  Scheduler = 'Scheduler',
  Cms = 'Cms =',
  System = 'System',
  Log = 'log',
  Ntp = 'Ntp',
  Enclosure = 'Enclosure',
  Temperature = 'Temperature',
  POS = 'POS',
  COD = 'COD',
}

export const AlarmEventNamespaceRegex: Record<AlarmEventNamespace, string | RegExp> = {
  [AlarmEventNamespace.Player]: /^player\.\d+$/,
  [AlarmEventNamespace.Display]: /^display\.\d+$/,
  [AlarmEventNamespace.Scheduler]: 'scheduler',
  [AlarmEventNamespace.Cms]: 'cms',
  [AlarmEventNamespace.System]: 'system',
  [AlarmEventNamespace.Log]: 'log',
  [AlarmEventNamespace.Ntp]: 'ntp',
  [AlarmEventNamespace.Enclosure]: 'enclosure',
  [AlarmEventNamespace.Temperature]: 'temperature',
  [AlarmEventNamespace.POS]: 'ext.pos',
  [AlarmEventNamespace.COD]: 'ext.cod',
};
