<div class="container">
  <form [formGroup]="form">
    <h6>{{ 'NLP.LABEL.ORGANIZATION_INFO.SITE_MANAGER.SEARCH.TITLE_50' | transloco }}</h6>
    <avn-form-field [enableValidTheme]="false" [enableInvalidTheme]="false">
      <avn-autocomplete
        formControlName="manager"
        [minChars]="3"
        [caseSensitive]="false"
        [debounceTimeMs]="300"
        [datasource]="managersDatasource"
        [placeholder]="'NLP.LABEL.ORGANIZATION_INFO.SITE_MANAGER.SEARCH.PLACEHOLDER_100' | transloco: { minChar: 3 }"
        [selectedOption]="selectedManager$ | async"
        (valueChanged)="onManagerSelected($event)"
      >
      </avn-autocomplete>
    </avn-form-field>
  </form>

  <div class="selected-manager" *ngIf="selectedManager$ | async">
    <ng-container *ngIf="(selectedManager$ | async).data; else notFound">
      <h6>{{ 'NLP.LABEL.ORGANIZATION_INFO.SITE_MANAGER.SELECTED_MANAGER_50' | transloco }}</h6>
      <div>{{ (selectedManager$ | async).data.firstName }} {{ (selectedManager$ | async).data.lastName }}</div>
      <div>{{ (selectedManager$ | async).data.email }}</div>
    </ng-container>

    <ng-template #notFound>
      <div class="not-found">
        <avn-icon icon="alert:error_outline" [fontSize]="42"></avn-icon>
        <h6>{{ 'NLP.LABEL.ORGANIZATION_INFO.SITE_MANAGER.NOT_FOUND.TITLE_50' | transloco }}</h6>
        <div>{{ 'NLP.LABEL.ORGANIZATION_INFO.SITE_MANAGER.NOT_FOUND.MESSAGE_200' | transloco }}</div>
      </div>
    </ng-template>
  </div>
</div>
