import { Pipe, PipeTransform } from '@angular/core';
import { CountAggregationData } from '../model/site-monitoring-data.interface';

@Pipe({ name: 'sortCountByKey' })
export class SortCountByKeyPipe implements PipeTransform {
  transform(value: CountAggregationData): CountAggregationData {
    return Object.entries(value)
      .sort(([k1], [k2]) => +k1 - +k2)
      .reduce((res, [k, v]) => ({ ...res, [k]: v }), {});
  }
}
