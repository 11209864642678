import { ChangeDetectionStrategy, Component, forwardRef, Input, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { IKeyMetricSettingsComponent, KeyMetricsSettingsBaseMixin } from '../key-metrics-settings.mixin';
import { ISiteMonitoringKeyMetricOptimisticSettings } from '../../../../../model/key-metrics/site-monitoring-key-metric-optimistic-settings.interface';
import { SiteMonitoredValue } from '../../../../../model/site-monitored-value.enum';
import { SiteMonitoringKeyMetric } from '../../../../../model/site-monitoring-key-metric.enum';

@Component({
  selector: 'amp-key-metrics-optimistic-settings',
  templateUrl: './key-metrics-optimistic-settings.component.html',
  styleUrls: ['./key-metrics-optimistic-settings.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  inputs: ['settings'],
  outputs: ['settingsChanged'],
  providers: [
    {
      provide: IKeyMetricSettingsComponent,
      useExisting: forwardRef(() => KeyMetricsOptimisticSettingsComponent),
    },
  ],
})
export class KeyMetricsOptimisticSettingsComponent extends KeyMetricsSettingsBaseMixin<ISiteMonitoringKeyMetricOptimisticSettings>() implements IKeyMetricSettingsComponent, OnInit, OnDestroy {
  /** the site monitoring field to which the settings apply **/
  @Input() field: SiteMonitoredValue | SiteMonitoringKeyMetric;

  constructor(private _formBuilder: UntypedFormBuilder) {
    super();
  }

  ngOnInit(): void {
    this.form = this._formBuilder.group({ optimisticViewEnabled: [!!this.settings?.optimisticViewEnabled] });
  }

  ngOnDestroy(): void {
    // unsubscribe all subscriptions
    this.onDestroy();
  }
}
