import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import {
  AsyncDataState,
  DataTableComponent,
  DataTableSelectionMode,
  IDataTableColumnConfig,
  IDataTableDataFetchEvent,
  IDataTableDataSource,
  IDataTableSelectionChangeEvent,
  LoadingState,
} from '@activia/ngx-components';

@Component({
  selector: 'amp-search-devices',
  templateUrl: './search-devices.component.html',
  styleUrls: ['./search-devices.component.scss'],
})
export class SearchDevicesComponent<T> {
  @Input() columns: Array<IDataTableColumnConfig<void>> = [];

  @Input() dataSource: IDataTableDataSource<T>;

  @Input() loadingState: AsyncDataState;

  @Input() searchQuery: string;

  @Input() selectionMode: DataTableSelectionMode;

  @Output() fetchData: EventEmitter<IDataTableDataFetchEvent> = new EventEmitter<IDataTableDataFetchEvent>();

  @Output() searchQueryChanged: EventEmitter<string> = new EventEmitter<string>();

  @Output() selectedDevices: EventEmitter<T[]> = new EventEmitter<T[]>();

  /** the data table  */
  @ViewChild(DataTableComponent, { static: false }) table: DataTableComponent<T, void>;

  LoadingStates = LoadingState;

  onSearchQueryChanged($event: string) {
    if (this.loadingState !== LoadingState.INIT && this.table) {
      this.table.reset();
    }
    this.searchQueryChanged.emit($event);
  }

  /** When datatable emits a data fetch event **/
  onFetchData(event: IDataTableDataFetchEvent) {
    this.fetchData.emit(event);
  }

  onSelectionChanged($event: IDataTableSelectionChangeEvent<T>) {
    this.selectedDevices.emit($event.selected);
  }
}
