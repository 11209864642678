import { ISiteMonitoringKeyMetricConfig } from '../model/site-monitoring-key-metric-config.interface';
import { ISiteMonitoringListColumnConfig } from '../model/site-monitoring-list-column-config.interface';

/** Verify if every selected column, when applicable, has the same optimistic view setting as the global one */
export const getMismatchColumns = (globalOptimisticView: boolean, columns: Array<ISiteMonitoringListColumnConfig>): Array<ISiteMonitoringListColumnConfig> =>
  (columns || []).filter((col) => col.optimisticViewEnabled != null && col.optimisticViewEnabled !== globalOptimisticView);

/** Verify if every selected column, when applicable, has the same optimistic view setting as the global one */
export const hasMismatchColumns = (globalOptimisticView: boolean, columns: Array<ISiteMonitoringListColumnConfig>): boolean => getMismatchColumns(globalOptimisticView, columns).length > 0;

/** Verify if every selected key metrics, when applicable, has the same optimistic view setting as the global one */
export const getMismatchKeyMetrics = (globalOptimisticView: boolean, keyMetrics: Array<ISiteMonitoringKeyMetricConfig>): Array<ISiteMonitoringKeyMetricConfig> =>
  (keyMetrics || []).filter((km) => km.customConfig?.optimisticViewEnabled != null && km.customConfig.optimisticViewEnabled !== globalOptimisticView);

/** Verify if every selected key metrics, when applicable, has the same optimistic view setting as the global one */
export const hasMismatchKeyMetrics = (globalOptimisticView: boolean, keyMetrics: Array<ISiteMonitoringKeyMetricConfig>): boolean => getMismatchKeyMetrics(globalOptimisticView, keyMetrics).length > 0;
