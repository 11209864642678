<avn-dialog-layout>
  <avn-dialog-header
    [showCloseIcon]="dialogConfig.showCloseIcon"
    [title]="'siteManagementScope.SITE_MANAGEMENT.GLOBAL.EDIT_SITE_50' | transloco: { siteName: dialogConfig.data.site.name }"
    (closeClicked)="onCancel()"
  ></avn-dialog-header>

  <avn-dialog-content avnScroll>
    <amp-location-editor
      [parentForm]="form"
      [siteId]="dialogConfig.data.site.id"
      [location]="mapLocation"
      [validateFormOnInitialLoad]="dialogConfig.data.validateFormOnInitialLoad"
      (locationChanged)="onValuesChanged($event)"
    ></amp-location-editor>
  </avn-dialog-content>

  <avn-dialog-footer>
    <avn-button [label]="'button.cancel' | transloco" (actioned)="onCancel()"></avn-button>
    <avn-button
      [label]="'button.save' | transloco"
      [disabled]="form.invalid || form.pristine"
      avnTheme="primary"
      (actioned)="onSave()"
    >
    </avn-button>
  </avn-dialog-footer>
</avn-dialog-layout>
