import { ChangeDetectionStrategy, Component } from '@angular/core';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { ITaskPanelItemComponent, ModalService, ModalType } from '@activia/ngx-components';
import { map, tap } from 'rxjs/operators';
import { CommandsSummaryModalComponent } from '../commands-summary-modal/commands-summary-modal.component';
import { Store } from '@ngrx/store';
import { IRunningCommand } from '../../model/running-command.interface';
import { commandsQuery } from '../../store/commands.selectors';

/**
 * This component is responsible to display feedback about running commands in the task panel
 */
@Component({
  selector: 'amp-commands-task-panel-item',
  templateUrl: 'commands-task-panel-item.component.html',
  styleUrls: ['commands-task-panel-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CommandsTaskPanelItemComponent implements ITaskPanelItemComponent {
  /** Emits when all tasks are completed */
  completed$ = new BehaviorSubject(false);
  model$: Observable<{
    runningCommandsCount: number;
    completedCommandsCount: number;
    completedCommandsWarningCount: number;
    completedCommandsSuccessCount: number;
    completedCommandsFailedCount: number;
    totalCommandsCount: number;
    allCommandsFinished: boolean;
    totalCompletionPercent: number;
  }>;

  constructor(private _store: Store, private _modalService: ModalService) {
    const runningCommands$ = this._store.select<IRunningCommand[]>(commandsQuery.getRunningCommands);
    const completedCommands$ = this._store.select<IRunningCommand[]>(commandsQuery.getCompletedCommands);

    this.model$ = combineLatest([runningCommands$, completedCommands$]).pipe(
      map(([runningCommands, completedCommands]) => {
        const totalCommandsCount = runningCommands.length + completedCommands.length;
        const completedCommandsCount = completedCommands.length;
        const completedCommandsSuccessCount = completedCommands.filter((command) => command.commandStatus.count === command.commandStatus.ok).length;
        const completedCommandsFailedCount = completedCommands.filter((command) => command.commandStatus.failed > 0).length;
        const completedCommandsWarningCount = completedCommands.length - completedCommandsSuccessCount - completedCommandsFailedCount;
        const runningCommandsCount = runningCommands.length;
        const allCommandsFinished = runningCommandsCount === 0;
        const totalCompletionPercent = (completedCommandsCount / totalCommandsCount) * 100;

        return {
          totalCommandsCount,
          completedCommandsCount,
          completedCommandsSuccessCount,
          completedCommandsWarningCount,
          completedCommandsFailedCount,
          runningCommandsCount,
          allCommandsFinished,
          totalCompletionPercent,
        };
      }),
      tap(({ allCommandsFinished }) => {
        this.completed$.next(allCommandsFinished);
      })
    );
  }

  showCommandSummary() {
    this._modalService.open<CommandsSummaryModalComponent, void>(
      CommandsSummaryModalComponent,
      {
        closeOnBackdropClick: true,
      },
      {
        width: '50%',
        minWidth: '500px',
        maxWidth: '1000px',
        height: '50%',
        minHeight: '500px',
        maxHeight: '800px',
      },
      ModalType.Dialog
    );
  }
}
