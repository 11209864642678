/* eslint-disable @typescript-eslint/prefer-for-of */
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { Observable, combineLatest, of } from 'rxjs';
import { mergeMap, switchMap, take } from 'rxjs/operators';

import { IAuthority } from './authority.interface';
import { AuthFacade } from './store/auth.facade';

/**
 * RoleGuard will check if user has certain role, and also certain permissions
 */
@Injectable({ providedIn: 'root' })
export class RoleGuard  {
  constructor(private router: Router, private authFacade: AuthFacade) {}

  canLoad(route: ActivatedRouteSnapshot): Observable<boolean> {
    return this.canActivate(route);
  }

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
    const authorities = route.data['authorities'] as IAuthority;

    if (!authorities || !authorities.roles || authorities.roles.length === 0) {
      return of(true);
    }

    return this.authFacade.authStateReady$.pipe(
      take(1),
      switchMap(() => combineLatest([this.authFacade.authenticatedUserRoles$, this.authFacade.authenticatedUserPermissions$])),
      mergeMap(([roles, userPermissions]) => {
        if (
          roles &&
          this.hasRoleRequired(
            authorities.roles,
            roles.map((role) => role.name)
          )
        ) {
          return of(true);
        } else if (this.hasPermissionsRequired(authorities.permissions, userPermissions)) {
          return of(true);
        } else {
          this.router.navigate(['forbidden']);
          return of(false);
        }
      })
    );
  }

  private hasRoleRequired(authorities: Array<string>, roles: Array<string>) {
    for (let i = 0; i < authorities.length; i++) {
      for (let j = 0; j < roles.length; j++) {
        if (roles[j] === authorities[i]) {
          return true;
        }
      }
    }
    return false;
  }

  private hasPermissionsRequired(permissions: Array<string>, userPermissions: Array<string>) {
    if (permissions && userPermissions) {
      for (let i = 0; i < permissions.length; i++) {
        for (let j = 0; j < userPermissions.length; j++) {
          if (userPermissions[j] === permissions[i]) {
            return true;
          }
        }
      }
      return false;
    } else {
      return false;
    }
  }
}
