import { KeyValueDTO } from '@activia/cm-api';

export const environment = {
  production: true,
  development: false,
  staging: false,
  googleAnalyticsConfig: {
    apiKey: 'G-RVC6ZXWWXS',
    siteSpeedSampleRate: 1,
  },
  googleMapAPIKey: 'AIzaSyC3Pn1n2ISbQJ4VKIlyXZcUhRIEFCQOtMg', // TODO: google map api key for prod
  // the path for the configuration of amp
  configPath: '/conf/amp-config.json',
  // the path for the white-label
  whiteLabelPath: '/conf/white-label.json',

  // application level preferences, this will be used if
  // there is on application preferences defined for {{host}}/api/apppreferences/PasswordStrength
  passwordStrength: [
    {
      key: 'weakLengthLimit',
      value: '4',
    },
    {
      key: 'maxTypeLimit',
      value: '3',
    },
    {
      key: 'specialCharAllowed',
      value: '#?!@$%^&*-',
    },
    {
      key: 'level',
      value: 'weak',
    },
    {
      key: 'maxLengthLimit',
      value: '7',
    },
    {
      key: 'mediumTypeLimit',
      value: '2',
    },
    {
      key: 'weakTypeLimit',
      value: '0',
    },
    {
      key: 'mediumLengthLimit',
      value: '5',
    },
    {
      key: 'PasswordStrength',
      value: 'weak',
    },
    {
      key: 'maxLength',
      value: 20,
    },
  ] as Array<KeyValueDTO>,
  monitoringListTemplates: [{ key: 'unreachable_devices', value: '{"threshold": 48}' }] as Array<KeyValueDTO>,
};
