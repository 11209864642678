import { DeviceTypeDTO } from '@activia/cm-api';
import { IOptionData } from '@activia/ngx-components';
import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'amp-experience-template-provision-player-row',
  templateUrl: './experience-template-provision-player-row.component.html',
  styleUrls: ['./experience-template-provision-player-row.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ExperienceTemplateProvisionPlayerRowComponent implements OnInit {
  @Input() deviceFormGroup: UntypedFormGroup;

  /** List of all existing device */
  @Input() deviceTypes: DeviceTypeDTO[];

  modelsOptions: IOptionData<void>[];

  

  ngOnInit(): void {
    this.modelsOptions = this.deviceTypes.map((e) => ({ value: e.model, label: e.model }));
  }
}
