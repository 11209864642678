import { SiteDTO, SitesService, SiteSummaryDTO } from '@activia/cm-api';
import { ApiRequestState, AsyncDataService } from '@activia/ngx-components';
import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { BehaviorSubject, combineLatest, filter, Observable, ReplaySubject, share } from 'rxjs';
import { distinctUntilChanged, map, switchMap } from 'rxjs/operators';
import { IColumnNameOverride } from '../../model/column-name-override.interface';
import { SiteMonitoredValue } from '../../model/site-monitored-value.enum';
import { SiteProperties } from '../../model/site-properties.enum';
import { SiteMonitoringFacade } from '../../store/site-monitoring.facade';
import { IBoardAlarm } from '../../model/alarm-event.interface';
import { hasVisibleKeyMetrics } from '../../utils/key-metrics.utils';
import { KeyMetricsViewerService } from '../keymetrics/key-metrics-viewer/key-metrics-viewer.service';

@Component({
  selector: 'amp-site-preview',
  templateUrl: './site-preview.component.html',
  styleUrls: ['./site-preview.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SitePreviewComponent implements OnChanges {
  /**
   * Id of the site for which the overview is displayed
   **/
  @Input() site: Pick<SiteDTO, 'id'>;

  /**
   * When enabled, key metrics will be show as regular view in carousel.
   * When disabled, key metrics will be shows as compact view stacked vertically
   */
  @Input() showCompactView = true;

  /** Observable of the detail site **/
  site$: Observable<SiteSummaryDTO>;

  /** Information about the loading state of the api request to fetch the site **/
  siteApiRequestState = new ApiRequestState();

  /** site preview title */
  siteTitle$: Observable<{ name: string; id: string }>;

  hasVisibleKeyMetrics$: Observable<boolean>;

  /** Contains the current state of the monitoring values api request **/
  monitoringValuesRequestState = new ApiRequestState();

  /** Whether this site has at least one device connect to a board */
  hasOneDevice$: Observable<boolean>;

  /** Observable of all the board alarms **/
  boardAlarms$: Observable<Array<IBoardAlarm>>;

  private siteSub: BehaviorSubject<SiteDTO> = new BehaviorSubject<SiteDTO>(undefined);

  constructor(
    private siteService: SitesService,
    private asyncDataService: AsyncDataService,
    private _siteMonitoringFacade: SiteMonitoringFacade,
    private _keyMetricsViewerService: KeyMetricsViewerService,
  ) {
    const request$ = combineLatest([
      this._siteMonitoringFacade.preference$.pipe(map((pref) => pref.defaultToOptimisticView)),
      this._siteMonitoringFacade.userPreferences$.pipe(map((userPref) => userPref.showOnlyAlarmErrors)),
      this.siteSub,
    ]).pipe(
      distinctUntilChanged(
        ([prevDefaultToOptimisticView, prevShowOnlyAlarmErrors, prevSite], [currDefaultToOptimisticView, currShowOnlyAlarmErrors, currSite]) =>
          prevDefaultToOptimisticView === currDefaultToOptimisticView && prevShowOnlyAlarmErrors === currShowOnlyAlarmErrors && prevSite.id === currSite.id
      ),
      switchMap(([defaultToOptimisticView, showOnlyAlarmErrors, site]) => this.fetchSite(defaultToOptimisticView, showOnlyAlarmErrors, site)),
      share({
        connector: () => new ReplaySubject(1),
        resetOnRefCountZero: true,
        resetOnComplete: false,
        resetOnError: false,
      })
    );

    this._fetchKeyMetricsData();

    this.hasOneDevice$ = request$.pipe(map((res) => res.hasOneDevice));
    this.boardAlarms$ = request$.pipe(map((res) => res.boardsAlarms));
    this.site$ = request$.pipe(map((res) => res.site));

    this.siteTitle$ = combineLatest([this.site$, this._siteMonitoringFacade.columnNameOverrides$]).pipe(
      map(([sd, cno]) => {
        const override = cno.find((item: IColumnNameOverride) => item.columnId === SiteProperties.ExternalId);

        if (override && sd.site?.externalId) {
          return { name: override.name, id: '' + sd.site?.externalId };
        } else if (sd.site?.externalId) {
          return { name: 'id', id: '' + sd.site.externalId };
        }
        return {
          name: 'id',
          id: '' + sd.site?.id,
        };
      }),
      share({
        connector: () => new ReplaySubject(1),
        resetOnRefCountZero: true,
        resetOnComplete: false,
        resetOnError: false,
      })
    );
  }

  ngOnChanges({ site }: SimpleChanges): void {
    if (site && site.currentValue) {
      this.siteSub.next(this.site);
    }
  }

  private fetchSite(defaultToOptimisticView: boolean, showOnlyAlarmErrors: boolean, _site: SiteDTO) {
    this.siteApiRequestState = new ApiRequestState();

    const requests$ = combineLatest([
      this.siteService.findSiteSummary([this.site.id], [SiteMonitoredValue.HealthStatus, SiteMonitoredValue.LastUpdate]).pipe(map((v) => v[0])),
      this._siteMonitoringFacade.fetchBoardAlarms(_site.id, defaultToOptimisticView, showOnlyAlarmErrors, true),
    ]).pipe(map(([site, { boardsAlarms, hasOneDevice }]) => ({ site, boardsAlarms, hasOneDevice })));

    return this.asyncDataService.doRequestWithState$(requests$, this.siteApiRequestState, {
      message: 'site-monitoring.error.fetch-detail-site-fail',
    });
  }

  private _fetchKeyMetricsData() {
    this.hasVisibleKeyMetrics$ = this.siteSub.pipe(
      filter((site) => !!site),
      switchMap((site) => {
        const { keyMetrics$ } = this._keyMetricsViewerService.getSiteKeyMetrics(site.id);
        return keyMetrics$;
      }),
      map((monitoredData) => hasVisibleKeyMetrics(monitoredData)),
    );
  }
}
