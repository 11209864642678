<div class="line">
  <span>{{ title }}</span>
  <span class="font-weight-bold" *ngIf="hasProgressInfo">
    <ng-container *ngIf="progressPercentCompleted > 0 && !isCompleted"> {{ progressPercentCompleted }}% </ng-container>
  </span>
</div>
<div class="line" *ngIf="hasProgressInfo">
  <avn-progressbar [showValue]="true" [max]="commandCount" [finished]="isCompleted">
    <avn-progress-segment id="none" *ngIf="showProgressSegmentNone" [value]="0"></avn-progress-segment>
    <avn-progress-segment id="ok" *ngIf="showProgressSegmentOk" [value]="okCommandCount" avnTheme="success"></avn-progress-segment>
    <avn-progress-segment id="fail" *ngIf="showProgressSegmentFail" [value]="failedCommandCount" avnTheme="danger"></avn-progress-segment>
    <avn-progress-segment id="other" *ngIf="showProgressSegmentOther" [value]="otherCommandCount" avnTheme="warning"></avn-progress-segment>
  </avn-progressbar>
</div>
<div class="line trigger" [class.with-progress]="hasProgressInfo">
  {{ triggerInfo }}
</div>
