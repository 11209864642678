import { UnparseConfig } from './interfaces/unparse-config';
import { Papa } from './papa.service';
/**
 * Export a list of data as csv data.
 *
 *
 * ATTENTION: Note that blob above use different charset. This is because some addresses
 *            in Quebec returned by Google Maps API use French chars. Need to support exporting
 *            such addresses.
 *
 * @param papaParser the parser from PapaParse library
 * @param document a reference to the document
 * @param headers header columns for the csv file
 * @param data data rows for the csv file
 * @param filenamePrefix Optional. A prefix to append to the csv filename generated with the current timestamp
 * @param unparseConfig Optional. Overrides the default unparse config from PapaParse library
 */
export const exportAsCsv = (papaParser: Papa, headers: string[], data: any[][], unparseConfig: UnparseConfig = {}): string => {
  // Papaparse options, reference: https://www.papaparse.com/docs#json-to-csv
  const config: UnparseConfig = {
    quotes: true, // wrap values with quotes because values with commas inside get quoted anyway, so let do it for all fields for consistency (https://stackoverflow.com/questions/53674251/papa-parse-with-backslash-escaping)
    delimiter: ',', // separate values with commas
    quoteChar: '"', // character to wrap the values
    escapeChar: '\\' as any, // The character used to escape quoteChar inside field values.
    skipEmptyLines: false, // other option is 'greedy', meaning skip delimiters, quotes, and whitespace.
    ...unparseConfig,
  };
  const csvData = papaParser.unparse({ fields: headers, data }, config);
  return csvData;
};

/**
 * Downloads a file containing the specified data
 *
 * @param document a reference to the document
 * @param papaParser filename of the downloaded file
 * @param content type type of data (ex: text/csv;charset=utf-8)
 */
export const downloadData = (document: Document, filename: string, data: BlobPart, contentType: string): void => {
  // create the file and downloading
  const blob = new Blob([data], { type: contentType });
  const link = document.createElement('a');
  link.style.display = 'none';
  link.href = URL.createObjectURL(blob);
  link.download = filename;
  document.body.appendChild(link);
  link.click();
};
