import { createAction, props } from '@ngrx/store';
import { IDeviceCommandInfo } from '../device-commands.constant';
import { CommandStatusDTO, DeviceInfoDTO, PlayerActionCommandDTO } from '@activia/cm-api';
import { IRunningCommand } from '../model/running-command.interface';
import { DeviceActionCommandEnum } from '../model/device-command.enum';
import { IDeviceGroupCommand } from '../model/device-group-command.interface';

export const RunDeviceCommand = createAction('[Commands Queue] Run Device Command', props<{ command: IDeviceCommandInfo; deviceId: number; deviceInfo: DeviceInfoDTO; gaCategory: string }>());
export const RunDeviceCommandSuccess = createAction('[Commands Queue] Run Device Command Success', props<{ runningCommand: IRunningCommand }>());
export const DeviceCommandThrottle = createAction('[Commands Queue] Device Command Throttle', props<{ runningCommand: IRunningCommand }>());
export const DeviceCommandUnthrottle = createAction('[Commands Queue] Device Command Unthrottle', props<{ runningCommand: IRunningCommand }>());
export const DeviceCommandCompleted = createAction('[Commands Queue] Device Command Completed', props<{ commandStatus: CommandStatusDTO; runningCommand: IRunningCommand }>());
export const DeviceCommandUpdateStatus = createAction('[Commands Queue] Device Command Update Status', props<{ commandStatus: CommandStatusDTO; deviceCommand?: PlayerActionCommandDTO }>());
export const DeviceCommandClearCompleted = createAction('[Commands Queue] Device Command Clear Completed');

export const RunDeviceGroupCommand = createAction('[Commands Queue] Run Device Group Command',
  props<{ command: DeviceActionCommandEnum; deviceGroup: Partial<IDeviceGroupCommand>; devices: DeviceInfoDTO[]; gaCategory: string }>());
