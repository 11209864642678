<avn-dialog-layout>
  <avn-dialog-header [showCloseIcon]="dialogConfig.showCloseIcon" [title]="'tagOperation.TAG_OPERATION.DIALOG.CONFIRM_PROCEED_60' | transloco" (closeClicked)="onClose()"></avn-dialog-header>

  <avn-dialog-content avnScroll class="invalid-asset-dialog-body-content">
    <amp-invalid-tag-message [propertyType]="dialogConfig.data.propertyType" [tagOperations]="dialogConfig.data.impactList || []"></amp-invalid-tag-message>
  </avn-dialog-content>

  <avn-dialog-footer class="row-flex">
    <avn-button [label]="'button.cancel' | transloco" (actioned)="onClose()"></avn-button>
    <avn-button [label]="'button.save' | transloco" [avnTheme]="ThemeType.PRIMARY" (actioned)="editTags()"></avn-button>
  </avn-dialog-footer>
</avn-dialog-layout>
