<div #shakeLock [ngClass]="{ 'shake-lock': !!shakeBaord && !!isLocked }">
  <avn-icon [disabled]="disabled" [icon]="isLocked ? 'action:lock' : 'action:lock_open'" [fontSize]="19" (click)="showLockModal()"></avn-icon>
</div>

<ng-template #unlockContent>
  <p>
    {{ 'siteManagementScope.SITE_MANAGEMENT.BOARD_EDITOR.LOCK_MODAL.CONTENT_200' | transloco }}
  </p>
  <p>
    {{ 'siteManagementScope.SITE_MANAGEMENT.BOARD_EDITOR.LOCK_MODAL.PROCEED_50' | transloco }}
  </p>
</ng-template>
