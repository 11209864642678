import { createFeatureSelector, createSelector } from '@ngrx/store';
import { IAuthState } from './auth.reducer';

const authSelector = createFeatureSelector<IAuthState>('auth');
export const selectLoginRequestPending = createSelector(authSelector, (state) => state.pending);
export const selectUserInfo = createSelector(authSelector, (state) => state.user);
export const selectUserToken = createSelector(authSelector, (state) => state.token);
export const selectRoles = createSelector(authSelector, (state) => state.roles);
export const selectScopes = createSelector(authSelector, (state) => state.scopes);
export const selectPermissions = createSelector(authSelector, (state) => state.permissions);

export const authQuery = {
  selectLoginRequestPending,
  selectUserInfo,
  selectUserToken,
  selectRoles,
  selectScopes,
  selectPermissions,
};
