import { HttpHeaders, HttpResponse } from '@angular/common/http';
import { EMPTY, Observable } from 'rxjs';
import { expand, map, reduce } from 'rxjs/operators';

const CONTENT_RANGE = 'Content-Range';

/**
 * Extracts the total number of records from the content-Range http headers
 * ex. for header [Content-Range: items 0-20/1500], it will return 1500
 */
export const extractTotalRecordsFromHttpHeaders = <T>(response: HttpResponse<T[]>): number => {
  const headers = response.headers;
  if (!headers?.has(CONTENT_RANGE)) {
    return (response.body || []).length;
  }
  const contentRange = headers.get(CONTENT_RANGE);
  const CONTENT_RANGE_REGEX = /^items \d+-\d+\/(\d+)$/g;
  const match = new RegExp(CONTENT_RANGE_REGEX).exec(contentRange);
  if (!match) {
    return (response.body || []).length;
  }
  return Number(match[1]) || 0;
};

/**
 * Extracts the last index of current paged result from the content-Range http headers
 * ex. for header [Content-Range: items 1000-1499/1500], it will return 1499
 * - [startIdx - endIdx / total] : when endIdx + 1 === total: everything fetched
 */
export const extractLastIdxOfCurrentPageFromHttpHeaders = <T>(response: HttpResponse<T[]>): number => {
  const headers = response.headers;
  if (!headers?.has(CONTENT_RANGE)) {
    return (response.body || []).length - 1;
  }
  const contentRange = headers.get(CONTENT_RANGE);
  const LAST_IDX_REGEX = /^items \d+-(\d)+\/\d+$/g;
  const match = new RegExp(LAST_IDX_REGEX).exec(contentRange);
  if (!match) {
    return (response.body || []).length - 1;
  }
  return Number(match[1]) || 0;
};

/** Recursively fetch all of the paged data, and return the combined list as final result
 *
 * @param request$ expecting function that returning HttpsResponse, as we need parse header
 * @returns observable of list data
 *
 */
export const recursivelyFetchAll = <T>(request$: (offset: number) => Observable<HttpResponse<T[]>>): Observable<T[]> =>
  request$(0).pipe(
    expand((response) => {
      const totalRows = extractTotalRecordsFromHttpHeaders<T>(response);
      const lastIdx = extractLastIdxOfCurrentPageFromHttpHeaders<T>(response);
      if (lastIdx + 1 === totalRows) {
        return EMPTY;
      } else {
        return request$(lastIdx + 1);
      }
    }),
    map((res) => res.body as T[]),
    reduce((p: T[], c: T[]) => p.concat(c), [])
  );

/**
 * Extracts last modified timestamp and last modified by info from the HTTP headers
 */
export const extractLastModifiedInfoFromHttpHeaders = (headers: HttpHeaders): { modificationTime: string; lastModifiedBy: string } => {
  if (!headers || (!headers.has('Last-Modified') && !headers.has('X-Last-Modified-By'))) {
    return null;
  }
  const lastModified = headers.get('Last-Modified');
  const lastModifiedBy = headers.get('X-Last-Modified-By');
  return { modificationTime: lastModified, lastModifiedBy };
};

/** Extracts the ID from the HTTP Location headers */
export const extractIdFromHttpLocationHeaders = (headers: HttpHeaders): number | null => {
  // E.g. Location: https://amp-whopper-nugget-qa.dev.activia.io:8093/amp/v1/query-results/17641895
  if (!headers?.has('Location')) {
    return null;
  }
  const url = headers.get('Location');

  const idx = url.lastIndexOf('/');
  const id = idx < 0 ? null : Number(url.slice(idx + 1));

  return id;
};
