<avn-dialog-layout>
  <avn-dialog-header [template]="headerTemplate" [showCloseIcon]="true" (closeClicked)="onClose()"> </avn-dialog-header>

  <avn-dialog-content avnScroll>
    <ng-container *ngIf="device">
      <div class="selection">
        <!-- Device Info -->
        <div class="device">
          <div>
            <amp-device-field-templates [displayType]="DisplayType.TwoLines" [value]="{ line1: device.deviceInfo.hostname, line2: device.deviceInfo.ipAddress }"> </amp-device-field-templates>
            <span class="separator"></span>
            <amp-device-field-templates [field]="DeviceProperty.OperationalState" [badgeSize]="badgeSize" [value]="device.deviceInfo.operationalState"></amp-device-field-templates>
          </div>
        </div>
        <div>
          <div>
            {{ 'siteManagementScope.SITE_MANAGEMENT.BOARD_EDITOR.DISPLAY_DEVICE_SELECTOR.SERIAL_NUMBER' | transloco }}: <span *ngIf="device.deviceInfo.serial?.length > 1">{{ device.deviceInfo.serial }}</span>
          </div>
          <ng-container *ngFor="let interface of device.deviceInterfaces">
            <div>{{ 'siteManagementScope.SITE_MANAGEMENT.BOARD_EDITOR.DISPLAY_DEVICE_SELECTOR.MAC' | transloco }}: {{ interface.macAddress }}</div>
          </ng-container>
        </div>
      </div>
    </ng-container>
  </avn-dialog-content>
</avn-dialog-layout>

<ng-template #headerTemplate>
  <div class="header">
    <h5>
      {{ 'siteManagementScope.SITE_MANAGEMENT.BOARD_EDITOR.DISPLAY_DEVICE_SELECTOR.MEDIA_PLAYER_INFO' | transloco }}
    </h5>
  </div>
</ng-template>
