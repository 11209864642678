import { SharedDeviceColumn } from './shared-devices-columns';
import { IDataTableColumnConfig } from '@activia/ngx-components';
import { TranslocoService } from '@ngneat/transloco';

export const getSharedDeviceListColumns = (translateService: TranslocoService, visibleColumns: SharedDeviceColumn[]): IDataTableColumnConfig<void>[] => {

  const nameCol: IDataTableColumnConfig<any> = {
    id: SharedDeviceColumn.NAME,
    name: translateService.translate(`deviceFields.DEVICE.SHARED_DEVICES.${SharedDeviceColumn.NAME}_50`),
    prop: 'name',
    widthRatio: 1,
    resizable: true,
    sortable: false,
    draggable: false,
  };

  const descriptionCol: IDataTableColumnConfig<any> = {
    id: SharedDeviceColumn.DESCRIPTION,
    name: translateService.translate(`deviceFields.DEVICE.SHARED_DEVICES.${SharedDeviceColumn.DESCRIPTION}_50`),
    prop: 'description',
    widthRatio: 2,
    resizable: true,
    sortable: false,
    draggable: false,
  };

  const nameDescriptionCol: IDataTableColumnConfig<any> = {
    id: SharedDeviceColumn.NAME_DESCRIPTION,
    name: translateService.translate(`deviceFields.DEVICE.SHARED_DEVICES.${SharedDeviceColumn.NAME_DESCRIPTION}_50`),
    widthRatio: 2,
    resizable: true,
    sortable: false,
    draggable: false,
  };

  const columnCountCol: IDataTableColumnConfig<any> = {
    id: SharedDeviceColumn.COLUMN_COUNT,
    name: translateService.translate(`deviceFields.DEVICE.SHARED_DEVICES.${SharedDeviceColumn.COLUMN_COUNT}_50`),
    widthPx: 80,
    resizable: true,
    sortable: false,
    draggable: false,
  };

  const deviceCountCol: IDataTableColumnConfig<any> = {
    id: SharedDeviceColumn.DEVICE_COUNT,
    name: translateService.translate(`deviceFields.DEVICE.SHARED_DEVICES.${SharedDeviceColumn.DEVICE_COUNT}_50`),
    widthPx: 80,
    resizable: true,
    sortable: false,
    draggable: false,
  };

  const ownerCol: IDataTableColumnConfig<any> = {
    id: SharedDeviceColumn.OWNER,
    name: translateService.translate(`deviceFields.DEVICE.SHARED_DEVICES.${SharedDeviceColumn.OWNER}_50`),
    prop: 'owner',
    widthPx: 150,
    resizable: true,
    sortable: false,
    draggable: false,
  };

  const activeCol: IDataTableColumnConfig<any> = {
    id: SharedDeviceColumn.ACTIVE,
    name: translateService.translate(`deviceFields.DEVICE.SHARED_DEVICES.${SharedDeviceColumn.ACTIVE}_50`),
    prop: 'active',
    widthPx: 100,
    resizable: true,
    sortable: false,
    draggable: false,
  };

  const columns = [nameCol, descriptionCol, nameDescriptionCol, columnCountCol, deviceCountCol, ownerCol, activeCol]
    .filter((col) => visibleColumns.includes(col.id as SharedDeviceColumn));

  return columns;
};
