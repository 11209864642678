import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import * as SiteOrgPathDefinitionActions from './site-org-path-definition.actions';
import { SitesService, SiteTagsService } from '@activia/cm-api';
import { MessengerNotificationService } from '@amp/messenger';
import { IOrgPathDefNode, IOrgPathDefRoot } from '../../model/board-org-path-definition.interface';
import { IJsonSchema } from '@activia/json-schema-forms';
import { getAllUsedTagsInBoardOrgPathDefinition, getSiteTagsDefinitions } from '../../..';

@Injectable()
export class SiteOrgPathDefinitionEffects {
  /**
   * Fetch the board org path definition and all tags key associated with the definition
   */
  fetchSiteOrgPathDefinition$ = createEffect(() =>
    this._actions$.pipe(
      ofType(SiteOrgPathDefinitionActions.FetchSiteOrgPathDefinition),
      switchMap(() => this._siteService.getSitesLocation()),
      switchMap((boardOrgPathDef: IOrgPathDefRoot) => {
        const tags = getAllUsedTagsInBoardOrgPathDefinition(boardOrgPathDef.root as IOrgPathDefNode);

        return getSiteTagsDefinitions(this._siteTagsService, tags).pipe(map((tagsKey) => [boardOrgPathDef, tagsKey] as [IOrgPathDefRoot, Record<string, IJsonSchema>]));
      }),
      map(([siteOrgPathDefinition, tagKeys]) =>
        SiteOrgPathDefinitionActions.FetchSiteOrgPathDefinitionSuccess({
          siteOrgPathDefinition,
          tagKeys,
        })
      ),
      catchError((err) => {
        this._messengerNotificationService.showErrorMessage('siteManagementScope.SITE_MANAGEMENT.GLOBAL.ERROR.FETCH_SITE_JSON_SCHEMA_FAIL_150');
        return of(SiteOrgPathDefinitionActions.FetchSiteOrgPathDefinitionFail({ error: err }));
      })
    )
  );

  /**
   * Save the board org path definition
   */
  saveSiteOrgPathDefinition$ = createEffect(() =>
    this._actions$.pipe(
      ofType(SiteOrgPathDefinitionActions.SaveSiteOrgPathDefinition),
      switchMap((action) => this._siteService.updateSitesOrgPath(action.siteOrgPathDefinition).pipe(map(() => action.siteOrgPathDefinition))),
      switchMap((boardOrgPathDef: IOrgPathDefRoot) => {
        const tags = getAllUsedTagsInBoardOrgPathDefinition(boardOrgPathDef.root as IOrgPathDefNode);

        return getSiteTagsDefinitions(this._siteTagsService, tags).pipe(map((tagsKey) => [boardOrgPathDef, tagsKey] as [IOrgPathDefRoot, Record<string, IJsonSchema>]));
      }),
      map(([siteOrgPathDefinition, tagKeys]) =>
        SiteOrgPathDefinitionActions.SaveSiteOrgPathDefinitionSuccess({
          siteOrgPathDefinition,
          tagKeys,
        })
      ),
      catchError((err) => {
        this._messengerNotificationService.showErrorMessage('siteManagementScope.SITE_MANAGEMENT.GLOBAL.ERROR.SAVE_BOARD_JSON_SCHEMA_FAIL_150');
        return of(SiteOrgPathDefinitionActions.SaveSiteOrgPathDefinitionFail({ error: err }));
      })
    )
  );

  constructor(private _actions$: Actions, private _siteService: SitesService, private _siteTagsService: SiteTagsService, private _messengerNotificationService: MessengerNotificationService) {}
}
