import { Injectable } from '@angular/core';
import { TokenType } from 'chevrotain';

import { DeviceType } from './../../model/device-type.enum';

import { DeviceFilterNlpDatasourceService } from './device-filter-nlp-datasource.service';
import { DeviceFilterNlpParser } from './device-filter-nlp-parser';
import { MvLastAttempt, MvLastUpdate } from './device-filter.tokens';
import { addDeviceTypesToNlpFilter } from './device-filter.utils';

/***
 * This service converts a device filter expression to the api native expression
 * This is required because the backend expression do not support relative times in the expression
 * and therefore relative times need to be converted to a date just before the filter is sent to the
 * server
 */
@Injectable({
  providedIn: 'root',
})
export class DeviceFilterApiExpressionService {
  /** @ignore a parser is maintained for each set of tokens that make up the language **/
  private _deviceFilterNlpParserMap = new Map<TokenType[], DeviceFilterNlpParser>();

  /** @ignore **/
  constructor(private _datasourceService: DeviceFilterNlpDatasourceService) {}

  /**
   * Returns the api device filter expression
   *
   * @param expression the nlp native expression as supported in our local nlp parser
   * @param deviceTypes the device types to add to the filter
   * @param tokens a set of supported tokens for the passed expression (some parsers may support
   *        more fields than others)
   * @param tagsEnabled Specifies if tags are supported in the passed expression
   * @param optionalFilters Additional optional filters that are not defined in NLP to be append to the full
   *        expression. Needs to include the operator to be append to the main expression if applicable
   *        e.g. "& site.id = 1234" if there is an expression, or "site.id = 1234" if there is no expression
   */
  getDeviceFilter(expression: string, deviceTypes: DeviceType[], tokens: TokenType[], tagsEnabled: boolean, optionalFilters?: string): string {
    // Parse expression from nlp
    let filter = this._getApiExpression(expression || '', tokens, tagsEnabled);
    if (optionalFilters) {
      // When there is optional filters, wrap the rendered filter in () if applicable
      // e.g. If the expression is provided, convert to '(mv.health.status = 1)'
      // e.g. If the expression is not provided, keep the filter as ''
      filter = `${filter === '' ? '' : '('}${filter}${filter === '' ? '' : ')'}`;
      // Then append the optional filters, e.g. '(mv.health.status = 1) & site.id = -1'
      filter = `${filter} ${optionalFilters}`;
    }

    // first thing, add the devices types to the expression
    return addDeviceTypesToNlpFilter(filter, deviceTypes);
  }

  /** @ignore **/
  private _getApiExpression(expression: string, tokens: TokenType[], tagsEnabled: boolean): string {
    const hasDateTokens = this._hasDateTokens(tokens);

    // if we dont have any date tokens and tags not enabled, just return the current expression
    if (!hasDateTokens && !tagsEnabled) {
      return expression;
    }

    // first check if we already have a parser for this set of tokens
    let parser = this._deviceFilterNlpParserMap.get(tokens);
    if (!parser) {
      // create a new parser for the set of tokens
      parser = new DeviceFilterNlpParser(tokens, this._datasourceService, true, tagsEnabled);
      this._deviceFilterNlpParserMap.set(tokens, parser);
    }

    // convert the expression
    const parsedExpression = parser.updateExpression(expression);
    return parser.getApiExpression(parsedExpression);
  }

  /** @ignore **/
  private _hasDateTokens(tokens: TokenType[]) {
    return tokens.some((tokenType) => [MvLastAttempt, MvLastUpdate].includes(tokenType));
  }
}
