import { CommandStatusDTO, DeviceGroupDTO, DeviceInfoDTO, PlayerActionSimpleCommandDTO } from '@activia/cm-api';
import { DeviceActionCommandEnum } from './device-command.enum';

export enum CommandEntityType {
  DEVICE = 'DEVICE',
  DEVICE_GROUP = 'DEVICE_GROUP',
}

/** Represents a command manually run by the user **/
export interface IRunningCommand {
  action: DeviceActionCommandEnum;

  /** ID of the entity which the command executes on */
  entityId: number;

  /** Entity which the command executes on */
  entity: DeviceGroupDTO | DeviceInfoDTO;

  /** Type of the entity which the command executes on */
  entityType: CommandEntityType;

  /** If the entity type is device group, the list of devices in this device group */
  devices?: DeviceInfoDTO[];

  commandId: number;
  commandStatusId: number;
  commandStatus?: CommandStatusDTO; // current status of the command
  deviceCommand?: PlayerActionSimpleCommandDTO; // device command
}
