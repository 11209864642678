import {SiteProperties} from '@amp/site-monitoring-shared';

export enum SiteColumn {
  ID = SiteProperties.Id,
  EXTERNAL_ID = SiteProperties.ExternalId,
  NAME = SiteProperties.Name,
  ADDRESS = 'ADDRESS',
  CITY = SiteProperties.City,
  STATE = SiteProperties.State,
  TIMEZONE = 'TIMEZONE',
  DEVICE_COUNT = SiteProperties.DeviceCount,
  CONNECTED_DEVICE_COUNT = 'CONNECTED_DEVICE_COUNT',
  BOARD_COUNT = 'BOARD_COUNT',
  DISPLAY_COUNT = 'DISPLAY_COUNT',
  CREATED = 'CREATED',
  LAST_UPDATED = 'LAST_UPDATED',
  MODIFIED_BY = 'MODIFIED_BY',
  LONGITUDE = 'LONGITUDE',
  LATITUDE = 'LATITUDE',
}

/** Standard set of Site columns */
export const STANDARD_SITE_COLS = [
  SiteColumn.ID,
  SiteColumn.EXTERNAL_ID,
  SiteColumn.NAME,
  SiteColumn.ADDRESS,
  SiteColumn.DEVICE_COUNT,
  SiteColumn.CONNECTED_DEVICE_COUNT,
  SiteColumn.BOARD_COUNT,
  SiteColumn.DISPLAY_COUNT,
  SiteColumn.CREATED,
  SiteColumn.LAST_UPDATED,
  SiteColumn.MODIFIED_BY,
];

/** Site columns + long lat */
export const GEODETIC_SITE_COLS = [
  SiteColumn.ID,
  SiteColumn.EXTERNAL_ID,
  SiteColumn.NAME,
  SiteColumn.ADDRESS,
  SiteColumn.CITY,
  SiteColumn.STATE,
  SiteColumn.BOARD_COUNT,
  SiteColumn.DISPLAY_COUNT,
  SiteColumn.TIMEZONE,
  SiteColumn.LONGITUDE,
  SiteColumn.LATITUDE,
  SiteColumn.CREATED,
  SiteColumn.LAST_UPDATED,
  SiteColumn.MODIFIED_BY,
];

/** Site columns used in auto-geo-fix summary table */
export const GEODETIC_SUMMARY_SITE_COLS = [
  SiteColumn.ID,
  SiteColumn.EXTERNAL_ID,
  SiteColumn.NAME,
  SiteColumn.ADDRESS,
  SiteColumn.CITY,
  SiteColumn.STATE,
  SiteColumn.TIMEZONE,
  SiteColumn.LONGITUDE,
  SiteColumn.LATITUDE,
];
