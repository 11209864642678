import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromDevice from './device.reducer';

export const selectDeviceState = createFeatureSelector<fromDevice.DeviceState>(fromDevice.DEVICE_FEATURE_KEY);

const { selectIds, selectEntities, selectAll, selectTotal } = fromDevice.adapter.getSelectors();

export const selectDeviceIds = createSelector(selectDeviceState, selectIds);
export const selectDeviceEntities = createSelector(selectDeviceState, selectEntities);
export const selectAllDevices = createSelector(selectDeviceState, selectAll);
export const selectDeviceTotal = createSelector(selectDeviceState, selectTotal);

export const selectActivatedDevice = createSelector(selectDeviceState, (state) => state.activatedDeviceId);

export const selectDeviceDataState = createSelector(selectDeviceState, (state) => state.status);
