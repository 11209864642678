import { createAction, props } from '@ngrx/store';
import { IExportDevicesTask } from '../model/export-devices-task.interface';
import { IDownloadLogsTask } from '../model/download-logs-task.interface';

export const DevicesFetchSharedLists = createAction('[Devices] Fetch Shared Lists', props<{ userSharedListsKey: string; appSharedListsKey: string }>());
export const DevicesFetchSharedListsSuccess = createAction('[Devices] Fetch Shared Lists Success', props<{ data: any }>());
export const DevicesFetchSharedListsFail = createAction('[Devices] Fetch Shared Lists Fail', props<{ errorMessage: string }>());

export const DevicesUpdateSharedListCount = createAction('[Devices] Update Shared List Count', props<{ sharedListId: string; managerId: number; resetCountHistory: boolean }>());
export const DevicesUpdateSharedListCountSuccess = createAction('[Devices] Update Shared List Count Success', props<{ sharedListId: string; count: number }>());
export const DevicesUpdateSharedListCountFail = createAction('[Monitoring] Update Shared List Count Fail', props<{ sharedListId: string; errorMessage: string }>());

export const DevicesAddExportTask = createAction('[Devices] Add Export Task', props<{ task: IExportDevicesTask }>());
export const DevicesAddExportTaskSuccess = createAction('[Devices] Add Export Task Success', props<{ task: IExportDevicesTask }>());
export const DevicesAddExportTaskFail = createAction('[Devices] Add Export Task Fail', props<{ task: IExportDevicesTask }>());
export const DevicesClearExportTasks = createAction('[Devices] Clear Export Tasks');

export const DevicesAddDownloadLogsTask = createAction('[Devices] Add Download Logs Task', props<{ task: IDownloadLogsTask }>());
export const DevicesAddDownloadLogsTaskSuccess = createAction('[Devices] Add Download Logs Task Success', props<{ task: IDownloadLogsTask }>());
export const DevicesAddDownloadLogsTaskFail = createAction('[Devices] Add Download Logs Task Fail', props<{ task: IDownloadLogsTask }>());
export const DevicesClearDownloadLogsTask = createAction('[Devices] Clear Download Logs Tasks');
