import { dataOnceReady, IDataTableColumnConfig } from '@activia/ngx-components';
import { IColumnNameOverride, SiteMonitoringFacade, SiteProperties } from '@amp/site-monitoring-shared';
import { Injectable, TemplateRef } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { map, Observable, Subject, zip } from 'rxjs';
import { SiteColumn } from '../models/site-column';

@Injectable({
  providedIn: 'root',
})
export class SiteColumnsService {
  /**
   * internal storage of templates
   *
   * @ignore
   *
   */
  private _templatesSubject$: Subject<Map<string, [TemplateRef<void>, TemplateRef<void>]>> = new Subject();

  /**
   * @param cols - optional list of columns to show. Omitting this param returns all cols.
   */
  siteColumnsFormatted$(cols?: SiteColumn[], prefix = ''): Observable<IDataTableColumnConfig<void>[]> {
    return zip(
      // Get all site columns
      dataOnceReady(this.siteMonitoringFacade.columnNameOverrides$, this.siteMonitoringFacade.columnNameOverridesDataState$, 1).pipe(
        map((cnames) => this._initSiteCols(cnames))
      ),
      this._templatesSubject$
    ).pipe(
      map(([colDefMap, templates]) => {
        let applicableCols = cols ? (Object.values(cols) as string[]) : [];

        if (!cols || cols.length === 0) {
          applicableCols = Array.from(colDefMap.values()).map((v) => v.id);
        }
        const reqCol: IDataTableColumnConfig<any>[] = [];
        applicableCols.forEach((col) => {
          if (colDefMap.has(col)) {
            const colDef = colDefMap.get(col);
            if (templates && templates.has(col)) {
              colDef.dataCellTemplate = templates.get(col)[0];
            }
            reqCol.push({ ...colDef, prop: `${prefix}${colDef.prop}` });
          }
        });
        return reqCol;
      })
    );
  }

  constructor(
    private translateService: TranslocoService,
    private siteMonitoringFacade: SiteMonitoringFacade,
  ) {}

  /**
   *
   * @param templateRefs map of templates per SiteColumn [cell, headers]
   */
  registerTemplates(templateRefs?: Map<SiteColumn, [TemplateRef<void>, TemplateRef<void>]>): void {
    this._templatesSubject$.next(templateRefs);
  }

  private _initSiteCols(columnNameOverrides: IColumnNameOverride[]): Map<string, IDataTableColumnConfig<void>> {
    const defaultCol = {
      sortable: true,
      resizable: false,
      draggable: false,
    };
    const idCol: IDataTableColumnConfig<any> = {
      ...defaultCol,
      id: SiteColumn.ID,
      name: this.translateService.translate(`siteMonitoringSharedScope.SITE_MONITORING.COLUMNS.SITE_PROPERTIES.${SiteColumn.ID}.TITLE_35`),
      prop: 'id',
      widthPx: 60,
      sticky: true,
      stickyPosition: 'left',
    };
    const externalIdCol: IDataTableColumnConfig<any> = {
      ...defaultCol,
      id: SiteColumn.EXTERNAL_ID,
      name: this._overrideName(columnNameOverrides, SiteColumn.EXTERNAL_ID, this.translateService.translate(`siteMonitoringSharedScope.SITE_MONITORING.COLUMNS.SITE_PROPERTIES.${SiteColumn.EXTERNAL_ID}.TITLE_35`)),
      prop: 'externalId',
      widthPx: 130,
      sticky: true,
      stickyPosition: 'left',
    };
    const nameCol: IDataTableColumnConfig<any> = {
      ...defaultCol,
      id: SiteColumn.NAME,
      name: this._overrideName(columnNameOverrides, SiteColumn.NAME, this.translateService.translate(`siteMonitoringSharedScope.SITE_MONITORING.COLUMNS.SITE_PROPERTIES.${SiteColumn.NAME}.TITLE_35`)),
      prop: 'name',
      widthPx: 100,
      sticky: true,
      stickyPosition: 'left',
    };
    const addressCol: IDataTableColumnConfig<any> = {
      ...defaultCol,
      id: SiteColumn.ADDRESS,
      widthPx: 200,
      name: this.translateService.translate(`siteManagementScope.SITE_MANAGEMENT.SITE_PROPERTIES.${SiteColumn.ADDRESS}.TITLE_50`),
      sortableFields: [
        {
          label: this._overrideName(columnNameOverrides, SiteProperties.Street, this.translateService.translate(`siteMonitoringSharedScope.SITE_MONITORING.COLUMNS.SITE_PROPERTIES.${SiteProperties.Street}.TITLE_35`)),
          prop: 'address.addressLine1',
        },
        {
          label: this._overrideName(columnNameOverrides, SiteColumn.CITY, this.translateService.translate(`siteMonitoringSharedScope.SITE_MONITORING.COLUMNS.SITE_PROPERTIES.${SiteColumn.CITY}.TITLE_35`)),
          prop: 'address.city',
        },
        {
          label: this._overrideName(columnNameOverrides, SiteColumn.STATE, this.translateService.translate(`siteMonitoringSharedScope.SITE_MONITORING.COLUMNS.SITE_PROPERTIES.${SiteColumn.STATE}.TITLE_35`)),
          prop: 'address.state',
        },
        {
          label: this._overrideName(columnNameOverrides, SiteProperties.Country, this.translateService.translate(`siteMonitoringSharedScope.SITE_MONITORING.COLUMNS.SITE_PROPERTIES.${SiteProperties.Country}.TITLE_35`)),
          prop: 'address.country',
        },
        {
          label: this.translateService.translate(`siteManagementScope.SITE_MANAGEMENT.SITE_PROPERTIES.ADDRESS_LINE_3.TITLE_50`),
          prop: 'timezone',
        },
      ],
    };
    const modifiedByCol: IDataTableColumnConfig<any> = {
      ...defaultCol,
      id: SiteColumn.MODIFIED_BY,
      name: this.translateService.translate(`siteManagementScope.SITE_MANAGEMENT.SITE_PROPERTIES.${SiteColumn.MODIFIED_BY}.TITLE_50`),
      prop: 'lastModifiedBy',
      widthPx: 115,
    };
    const creationDateCol: IDataTableColumnConfig<any> = {
      ...defaultCol,
      id: SiteColumn.CREATED,
      name: this.translateService.translate(`siteManagementScope.SITE_MANAGEMENT.SITE_PROPERTIES.${SiteColumn.CREATED}.TITLE_50`),
      prop: 'creationTime',
      widthPx: 120,
    };
    const lastUpdatedDateCol: IDataTableColumnConfig<any> = {
      ...defaultCol,
      id: SiteColumn.LAST_UPDATED,
      name: this.translateService.translate(`siteManagementScope.SITE_MANAGEMENT.SITE_PROPERTIES.${SiteColumn.LAST_UPDATED}.TITLE_50`),
      prop: 'modificationTime',
      widthPx: 125,
    };
    const deviceCount: IDataTableColumnConfig<any> = {
      ...defaultCol,
      id: SiteColumn.DEVICE_COUNT,
      name: this._overrideName(columnNameOverrides, SiteColumn.DEVICE_COUNT, this.translateService.translate(`siteMonitoringSharedScope.SITE_MONITORING.COLUMNS.SITE_PROPERTIES.${SiteColumn.DEVICE_COUNT}.TITLE_35`)),
      prop: 'deviceCount',
      description: this.translateService.translate(`siteMonitoringSharedScope.SITE_MONITORING.COLUMNS.SITE_PROPERTIES.${SiteColumn.DEVICE_COUNT}.DESCRIPTION_200`),
      widthPx: 130,
    };
    const displayCount: IDataTableColumnConfig<any> = {
      ...defaultCol,
      id: SiteColumn.DISPLAY_COUNT,
      name: this.translateService.translate(`siteManagementScope.SITE_MANAGEMENT.SITE_PROPERTIES.${SiteColumn.DISPLAY_COUNT}.TITLE_50`),
      prop: 'displayCount',
      description: this.translateService.translate(`siteManagementScope.SITE_MANAGEMENT.SITE_PROPERTIES.${SiteColumn.DISPLAY_COUNT}.DESCRIPTION_100`),
      widthPx: 100,
    };
    const connectedDeviceCount: IDataTableColumnConfig<any> = {
      ...defaultCol,
      id: SiteColumn.CONNECTED_DEVICE_COUNT,
      name: this.translateService.translate(`siteManagementScope.SITE_MANAGEMENT.SITE_PROPERTIES.${SiteColumn.CONNECTED_DEVICE_COUNT}.TITLE_50`),
      prop: 'connectedDeviceCount',
      description: this.translateService.translate(`siteManagementScope.SITE_MANAGEMENT.SITE_PROPERTIES.${SiteColumn.CONNECTED_DEVICE_COUNT}.DESCRIPTION_100`),
      widthPx: 160,
    };
    const boardCount: IDataTableColumnConfig<any> = {
      ...defaultCol,
      id: SiteColumn.BOARD_COUNT,
      name: this.translateService.translate(`siteManagementScope.SITE_MANAGEMENT.SITE_PROPERTIES.${SiteColumn.BOARD_COUNT}.TITLE_50`),
      prop: 'boardCount',
      description: this.translateService.translate(`siteManagementScope.SITE_MANAGEMENT.SITE_PROPERTIES.${SiteColumn.BOARD_COUNT}.DESCRIPTION_100`),
      widthPx: 100,
    };

    const longitudeCol = {
      id: SiteColumn.LONGITUDE,
      name: this.translateService.translate(`siteManagementScope.SITE_MANAGEMENT.SITE_PROPERTIES.${SiteColumn.LONGITUDE}.TITLE_50`),
      prop: 'geodeticCoordinates.longitude',
      widthPx: 80,
      sortable: false,
      resizable: false,
      draggable: false,
    };
    const latitudeCol = {
      id: SiteColumn.LATITUDE,
      name: this.translateService.translate(`siteManagementScope.SITE_MANAGEMENT.SITE_PROPERTIES.${SiteColumn.LATITUDE}.TITLE_50`),
      prop: 'geodeticCoordinates.latitude',
      widthPx: 80,
      sortable: false,
      resizable: false,
      draggable: false,
    };

    const columns = [
      idCol,
      externalIdCol,
      nameCol,
      addressCol,
      deviceCount,
      connectedDeviceCount,
      boardCount,
      displayCount,
      creationDateCol,
      lastUpdatedDateCol,
      modifiedByCol,
      longitudeCol,
      latitudeCol,
    ];

    const columnMap = new Map<string, IDataTableColumnConfig<void>>(columns.map((x) => [x.id, x]));
    return columnMap;
  }

  /** override the name of the column*/
  private _overrideName(columnNameOverrides: IColumnNameOverride[], id: any, name: string) {
    return columnNameOverrides.find((column) => column.columnId === id)?.name || name;
  }
}
