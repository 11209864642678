<ng-container *ngIf="template; else empty">
  <h5>{{ 'siteManagementScope.SITE_MANAGEMENT.EXPERIENCE_TEMPLATE.EDIT_GENERAL.GENERAL_40' | transloco }}</h5>
  <form [formGroup]="form" class="edit-general-wrapper">
    <div class="edit-general-section">
      <avn-form-field class="edit-general-form-field-wrapper">
        <avn-input formControlName="label" class="edit-general-form-field" [label]="'siteManagementScope.SITE_MANAGEMENT.EXPERIENCE_TEMPLATE.CREATION_MODAL.LABEL_40' | transloco"></avn-input>
        <avn-icon class="edit-general-form-field-icon" icon="action:help"></avn-icon>
      </avn-form-field>
      <avn-form-field class="edit-general-form-field-wrapper">
        <avn-input formControlName="orgPath" class="edit-general-form-field" [label]="'siteManagementScope.SITE_MANAGEMENT.GLOBAL.ORGANIZATIONAL_PATH.TITLE_50' | transloco"></avn-input>
        <avn-icon class="edit-general-form-field-icon" icon="action:help"></avn-icon>
      </avn-form-field>
    </div>
  </form>

  <h5>{{ 'siteManagementScope.SITE_MANAGEMENT.EXPERIENCE_TEMPLATE.EDIT_GENERAL.BOARDS_40' | transloco }}</h5>
  <div class="edit-general-wrapper">
    <div>
      <div
        class="experience-template-general-board"
        [ngClass]="{ 'experience-template-general-selected-board': selectedBoard?.label === board.label }"
        *ngFor="let board of template.boards"
        (click)="onSelectBoard(board)"
      >
        <div class="board-title">
          <h6 class="board-label">{{ board.label }}</h6>
          <avn-icon avnContextMenu [contextMenuDataSource]="dataSource" icon="navigation:more_vert"></avn-icon>
        </div>
        <div class="displays-list">
          <div class="display" *ngFor="let display of board.displays">
            <div class="smd-header">
              <amp-screen-orientation [screenOrientation]="display.orientation"></amp-screen-orientation>
              <div class="display-header">
                <avn-icon icon="action:delete_forever" [fontSize]="14"></avn-icon>
              </div>
            </div>
            <div class="smdc-title">
              <avn-form-field>
                <avn-input [label]="'siteManagementScope.SITE_MANAGEMENT.EXPERIENCE_TEMPLATE.CREATION_MODAL.DISPLAY_40' | transloco" [value]="display.name"></avn-input>
              </avn-form-field>
            </div>
            <avn-skeleton [animate]="false" [skeletonType]="'SCREEN_SHOT'" class="screenshot-skeleton"> </avn-skeleton>
          </div>
        </div>
      </div>
      <div class="add-button" (click)="addBoard()">
        <!-- Add button -->
        <avn-icon icon="action:add"></avn-icon>
        <span>{{ 'siteManagementScope.SITE_MANAGEMENT.EXPERIENCE_TEMPLATE.EDIT_GENERAL.ADD_NEW_BOARD_60' | transloco }}</span>
      </div>
    </div>
  </div>
</ng-container>

<ng-template #empty>
  <avn-empty-data-message
    icon="communication:desktop_access_disabled"
    [text]="'siteManagementScope.SITE_MANAGEMENT.EXPERIENCE_TEMPLATE.EDIT_GENERAL.SITE_EMPTY_MESSAGE.TEXT_100' | transloco"
    [subText]="'siteManagementScope.SITE_MANAGEMENT.EXPERIENCE_TEMPLATE.EDIT_GENERAL.SITE_EMPTY_MESSAGE.SUBTEXT_100' | transloco"
  ></avn-empty-data-message>
</ng-template>
