import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromSite from './site-org-path-definition.reducer';

export const selectSiteState = createFeatureSelector<fromSite.SiteOrgPathDefState>(fromSite.siteOrgPathDefinitionsFeatureKey);

export const selectSiteOrgPathDefinition = createSelector(selectSiteState, (state) => state.orgPathDefinition.data);
export const selectSiteOrgPathDefinitionState = createSelector(selectSiteState, (state) => state.orgPathDefinition.state);

export const selectSiteTagKeysSchema = createSelector(selectSiteState, (state) => state.tagKeys);

export const selectSiteOrgPathSaveState = createSelector(selectSiteState, (state) => state.orgPathSaveState);
