<form [formGroup]="form">
  <avn-form-field [enableValidTheme]="false">
    <avn-checkbox
      formControlName="hideKeyMetricOnOk"
      [label]="'siteMonitoringSharedScope.SITE_MONITORING.COLUMNS_PICKER.HIDE_OK_30' | transloco"
      [avnTooltip]="'siteMonitoringSharedScope.SITE_MONITORING.COLUMNS_PICKER.HIDE_OK_DESCRIPTION_100' | transloco"
      [tooltipPlacement]="'left'"
    ></avn-checkbox>
  </avn-form-field>
</form>
