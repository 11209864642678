<ng-container *ngIf="site$ | async as site">
  <div class="fullscreen-container" avnScroll>
    <avn-grid [cols]="rCols" [gridRowHeight]="10">
      <!-- Maps -->
      <avn-widget
        *ngIf="enableSiteDetailMap$ | async"
        name="SiteMap"
        avnGridElement
        [columnSpan]="{ web: 2, tablet: 1, handset: 1 }"
        [rowSpan]="10"
        [headingTitle]="'siteMonitoringSharedScope.SITE_MONITORING.SITE_DETAIL.MAP.TITLE_50' | transloco"
        [showCloseButton]="true"
        (close)="onCloseWidget('showSiteDetailMap')"
      >
        <ng-container>
          <amp-site-monitoring-site-map [siteIds]="[site.id]"></amp-site-monitoring-site-map>
        </ng-container>
      </avn-widget>

      <!-- Key Metrics -->
      <avn-widget
        *ngIf="enableSiteDetailKeyMetrics$ | async"
        avnGridElement
        [columnSpan]="{ web: (enableSiteDetailMap$ | async) ? 6 : 8, tablet: 1, handset: 1 }"
        [rowSpan]="10"
        [headingTitle]="
          ('siteMonitoringSharedScope.SITE_MONITORING.SITE_DETAIL.KEY_METRICS_100' | transloco) +
          ' ' +
          (site.deviceCount | i18nPlural: i18nMapping | transloco: { count: site.deviceCount })
        "
        [showCloseButton]="true"
        (close)="onCloseWidget('enableSiteDetailKeyMetrics')"
      >
        <amp-key-metrics-viewer [siteId]="site.id" [height]="200"></amp-key-metrics-viewer>
      </avn-widget>

      <!-- Show alarms widget only when this site has at least one board with one device -->
      <ng-container *ngIf="siteAlarms$ | async">
        <avn-widget
          *ngIf="enableSiteDetailAlarmEventsSummary$ | async"
          avnGridElement
          [columnSpan]="{ web: (enableSiteDetailMap$ | async) && (enableSiteDetailKeyMetrics$ | async) !== true ? 6 : 8, tablet: 1, handset: 1 }"
          [rowSpan]="10"
          [headingTitle]="'siteMonitoringSharedScope.SITE_MONITORING.ALARM_EVENTS.ALARMS_10' | transloco"
          [showCloseButton]="true"
          (close)="onCloseWidget('enableSiteDetailAlarmEventsSummary')"
        >
          <ng-container *avnAsyncData="siteAlarms$ | async; state: siteAlarmsState$ | async; loadingTemplate: alarmsLoading; errorTemplate: alarmsError; backgroundRefreshEnable: true">
            <amp-site-alarms [siteId]="site.id" [siteTitle]="siteTitle" [boardAlarmsBySection]="siteAlarms$ | async"></amp-site-alarms>
          </ng-container>
        </avn-widget>
      </ng-container>
      <ng-template #alarmsLoading>
        <avn-skeleton
          style="min-height: 150px; max-width: 300px; margin: auto"
          data-e2e="alarms-loading"
          [animate]="true"
          [animateDirection]="'VERTICAL'"
          [text]="'siteMonitoringSharedScope.SITE_MONITORING.SITE_DETAIL.LOADING_ALARMS_30' | transloco"
          [skeletonType]="'CARD'"
        >
        </avn-skeleton>
      </ng-template>
      <ng-template #alarmsError>
        <avn-empty-data-message
          icon="alert:error"
          [text]="'siteMonitoringSharedScope.SITE_MONITORING.SITE_DETAIL.FETCH_ALARMS_ERROR_TITLE_50' | transloco"
          [subText]="'siteMonitoringSharedScope.SITE_MONITORING.SITE_DETAIL.FETCH_ALARMS_ERROR_DESCRIPTION_200' | transloco"
        >
        </avn-empty-data-message>
      </ng-template>

      <!-- Boards -->
      <ng-container *ngIf="sections$ | async as sections">
        <ng-container *ngIf="(sections || []).length > 0; else noBoard">
          <avn-widget
            *ngFor="let section of sections; let first = first; trackBy: sectionTrackFn"
            avnGridElement
            [columnSpan]="{
          web: first && (enableSiteDetailMap$ | async) && (enableSiteDetailKeyMetrics$ | async) !== true && (enableSiteDetailAlarmEventsSummary$ | async) !== true ? 6 : 8,
          tablet: 1,
          handset: 1
        }"
            [rowSpan]="first && (enableSiteDetailKeyMetrics$ | async) !== true ? 10 : 5"
            name="Screenshots"
            [showCloseButton]="false"
          >
            <amp-site-monitoring-section [section]="section"></amp-site-monitoring-section>
          </avn-widget>
        </ng-container>
      </ng-container>

      <ng-template #noBoard>
        <avn-widget
          avnGridElement
          [columnSpan]="{ web: (enableSiteDetailKeyMetrics$ | async) !== true ? ((enableSiteDetailMap$ | async) !== true ? 8 : 6) : 8, tablet: 1, handset: 1 }"
          [rowSpan]="(enableSiteDetailKeyMetrics$ | async) !== true ? 10 : 5"
          name="Screenshots"
          [headingTitle]="'Boards'"
          [showCloseButton]="false"
        >
          <ng-container *ampHasAnyAuthority="[CmRoles.ROLE_ADMIN, CmRoles.ROLE_NETWORK_ADMIN]; else nonAdminError">
            <avn-empty-data-message
              icon="communication:desktop_access_disabled"
              [text]="'siteMonitoringSharedScope.SITE_MONITORING.SITE_DETAIL.BOARD.NO_BOARD_TITLE_50' | transloco"
              [subText]="'siteMonitoringSharedScope.SITE_MONITORING.SITE_DETAIL.BOARD.NO_BOARD_DESCRIPTION_200' | transloco"
            >
              <div *ampHasFeature="'siteManagement.module'" class="action-button">
                <avn-button avnTheme="primary" [label]="'siteMonitoringSharedScope.SITE_MONITORING.SITE_DETAIL.BOARD.ADD_BOARD_BUTTON_30' | transloco" (actioned)="navigateToSiteManagement()">
                </avn-button>
              </div>
            </avn-empty-data-message>
          </ng-container>

          <ng-template #nonAdminError>
            <avn-empty-data-message
              icon="communication:desktop_access_disabled"
              [text]="'siteMonitoringSharedScope.SITE_MONITORING.SITE_DETAIL.BOARD.NO_BOARD_TITLE_50' | transloco"
              [subText]="
                ('siteMonitoringSharedScope.SITE_MONITORING.SITE_DETAIL.BOARD.NO_BOARD_DESCRIPTION_200' | transloco) +
                ' ' +
                ('siteMonitoringSharedScope.SITE_MONITORING.SITE_DETAIL.BOARD.EMPTY_BOARD_CONTACT_ADMIN_100' | transloco)
              "
            ></avn-empty-data-message>
          </ng-template>
        </avn-widget>
      </ng-template>
    </avn-grid>
  </div>
</ng-container>
