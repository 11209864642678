import { ChangeDetectionStrategy, Component, ElementRef, Input, OnChanges, SimpleChanges } from '@angular/core';
import { CountAggregationData, MinMaxAggregationData } from '../../../model/site-monitoring-data.interface';
import { ISiteMonitoringKeyMetricConfig } from '../../../model/site-monitoring-key-metric-config.interface';
import { IStatusCardConfig, IStatusInfo } from './status-card-config.interface';
import { LoadingState, ThemeType } from '@activia/ngx-components';
import { SiteMonitoredValue } from '../../../model/site-monitored-value.enum';
import { CountCardValueSize } from '@activia/dataviz';
import { ISiteMonitoringKeyMetricComponent, KeyMetricsBaseMixin } from '../key-metrics.mixin';

@Component({
  selector: 'amp-key-metrics-status-card',
  templateUrl: './key-metrics-status-card.component.html',
  styleUrls: ['./key-metrics-status-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class KeyMetricsStatusCardComponent extends KeyMetricsBaseMixin() implements ISiteMonitoringKeyMetricComponent, OnChanges {
  /** The configuration of the current key metric component **/
  @Input() config: ISiteMonitoringKeyMetricConfig;

  /** Contains the aggregated data for each of the statuses **/
  @Input() monitoringData: CountAggregationData;

  /** Date value of this monitoring data needed for this key metric component **/
  @Input() monitoringDate: MinMaxAggregationData<number>;

  /** Contains loading state info of the monitoring data api call */
  @Input() monitoringDataDataState = LoadingState.INIT;

  /** Necessary configuration for the card */
  @Input() cardConfig: IStatusCardConfig;

  /** Indicates if the data should be optimistic */
  @Input() optimisticViewEnabled = false;

  /** Indicates if the card should show the compact view */
  @Input() showCompactView = false;

  /** info about the currently displayed status info (count, label and theme) **/
  countInfo: {
    count: number;
    label: string;
    theme: ThemeType;
  };

  CountCardValueSize = CountCardValueSize;

  constructor(private _elementRef: ElementRef<any>) {
    super();
  }

  ngOnChanges({ monitoringData }: SimpleChanges): void {
    if (!this.showCompactView) {
      if (monitoringData) {
        this.countInfo = this._getStatusInformation(this.cardConfig.data.field, this.cardConfig.statuses, this.monitoringData);
      }
    }
  }

  /**
   * Returns info about the status to display (count, label and theme to use)
   * It will show the first status that has a count > 0, in the order provided in the statuses array.
   * ex. if we have errors, show the error count, otherwise if we have warning, show the warning count, else show the ok count
   * */
  private _getStatusInformation(field: SiteMonitoredValue, statuses: IStatusInfo[], data: CountAggregationData) {
    for (const status of statuses) {
      const isLastStatus = status === statuses[statuses.length - 1];
      const count = data[status.code];
      if (count > 0 || isLastStatus) {
        return {
          count,
          label: `siteMonitoringSharedScope.SITE_MONITORING.COLUMNS.KEY_METRICS.${field}.${status.name.toUpperCase()}.${count > 1 ? 'PLURAL' : 'SINGULAR'}_20`,
          theme: status.theme,
        };
      }
    }
  }

  /** Indicates if the date information is present **/
  get hasDate(): boolean {
    return !!this.cardConfig.date && !!this.monitoringDate;
  }

  /** Set the card dimensions the height of the parent. Used to keep the card square **/
  get cardDimensions(): number {
    return this._elementRef.nativeElement.parentElement.clientHeight;
  }

  // return color with priority order: danger > warning > success
  dataDisplayTypeCode(): string {
    const dataStatuses = Object.keys(this.monitoringData).map((s) => +s);

    // The statuses are already ordered by severity, with danger as the highest (index 0) and success as the lowest
    // (index 1 if service status or index 2 if content status)
    const displayStatus = this.cardConfig.statuses.find((status) => dataStatuses.includes(status.code));
    return displayStatus?.theme;
  }
}
