import { Injectable } from '@angular/core';
import { first, of, Subject, takeUntil, tap } from 'rxjs';
import { TimerService } from '@amp/messenger';
import { Store } from '@ngrx/store';
import { ISiteManagementState } from '../store/site-management.reducer';
import { siteManagementEntities } from '../store/site-management.selectors';
import * as SiteManagementAction from '../store/site-management.actions';

@Injectable({ providedIn: 'root' })
export class SiteListService {

  private _stopTimerSubject: Subject<void> = new Subject<void>();

  constructor(private _timerService: TimerService, private _store: Store<ISiteManagementState>) { }

  /** Start timer that periodically fetches all site */
  startTimer() {
    return this._store.pipe(siteManagementEntities.dataRefreshIntervalMs$).pipe(
      first(),
      tap((refreshInterval) => {
        const timer$ = refreshInterval ?
          this._timerService.timer$(refreshInterval).pipe(
            takeUntil(this._stopTimerSubject.asObservable()),
          ) :
          of(null);

        timer$.subscribe(() => {
          this._store.dispatch(SiteManagementAction.FetchAllSites());
        });
      }),
    );
  }

  /** Stop the timer that periodically fetches all sites */
  stopTimer() {
    this._stopTimerSubject.next();
  }
}
