<div class="experience-template-list-main-wrapper" [ngClass]="{ 'experience-template-edition': editMode }" avnScroll>
  <!-- List of template -->
  <div
    class="experience-template-list-template"
    [ngClass]="{ 'experience-template-selected': selectedTemplate?.label === template.label }"
    *ngFor="let template of templates$ | async"
    (click)="openTemplateCreation(template)"
  >
    <h5 class="experience-template-list-template-title">{{ template.orgPath + ' / ' + template.label }}</h5>
    <div class="experience-template-list-template-body">
      <!-- List of board -->
      <ng-container *ngFor="let board of template.boards">
        <div *ngIf="board | applyFunction: getColumnCount:[]:this as columnCount" [ngStyle]="{ flex: columnCount }" class="experience-template-list-board-template">
          <div class="experience-template-display-title">{{ board.label }}</div>
          <div class="experience-template-display-list">
            <!-- List of display -->

            <ng-container *ngFor="let display of board.displays">
              <ng-container *ngIf="display.geometry | applyFunction: getGeometryRowAndColumn as geometry; else displayNoOrder">
                <div class="experience-template-display-item" [ngStyle]="{ gridRow: geometry.row, gridColumn: geometry.column }">
                  <avn-skeleton [animate]="false" [skeletonType]="'SCREEN_SHOT'" class="screenshot-skeleton"> </avn-skeleton>
                </div>
              </ng-container>
            </ng-container>
          </div>
        </div>
      </ng-container>
    </div>
  </div>

  <!-- Add button -->
  <div *ngIf="editMode" class="add-button" (click)="addCreationTemplate()">
    <avn-icon icon="action:add"></avn-icon>
  </div>
</div>

<ng-template #displayNoOrder>
  <div class="experience-template-display-item">
    <avn-skeleton [animate]="false" [skeletonType]="'SCREEN_SHOT'" class="screenshot-skeleton"> </avn-skeleton>
  </div>
</ng-template>
