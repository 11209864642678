<div class="container">
  <avn-accordion *ngIf="tagOperations.length > 3">
    <avn-accordion-panel [headerTemplate]="customHeader">
      <amp-invalid-tag-message avnScroll [propertyType]="propertyType" class="warning-msg" [tagOperations]="tagOperations"></amp-invalid-tag-message>
    </avn-accordion-panel>
  </avn-accordion>

  <amp-invalid-tag-message [propertyType]="propertyType" *ngIf="0 <= tagOperations.length && tagOperations.length <= 3" class="warning-msg" [tagOperations]="tagOperations"></amp-invalid-tag-message>
</div>
<ng-template #customHeader>
  <div class="custom-header">
    <avn-icon icon="action:info" [avnTheme]="'warning'"></avn-icon>
    <h5 class="title">
      <span>{{ 'tagOperation.TAG_OPERATION.INVALID_ENTITY.SUMMARY_TITLE.' + propertyType.toUpperCase() + '_80' | transloco }}</span>
    </h5>
  </div>
</ng-template>
