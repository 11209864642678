import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, concatMap, withLatestFrom } from 'rxjs/operators';
import { of } from 'rxjs';
import { SitesService } from '@activia/cm-api';
import { Store } from '@ngrx/store';
import { siteManagementEntities } from '../site-management.selectors';
import { MessengerNotificationService } from '@amp/messenger';
import * as DeviceActions from './device.actions';

@Injectable()
export class DeviceEffects {
  addDevice$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DeviceActions.AddDevices),
      map((action) => action.devices),
      withLatestFrom(this._store.pipe(siteManagementEntities.currSiteData$)),
      concatMap(([devices, currSite]) => {
        const dto = {
          create: devices.map((device) => device.id),
          delete: [],
        };
        return this._siteService.attachDetachDevicesToSite(currSite.id, dto as any).pipe(
          map((resp: any) => {
            const addedDevicesIds = Array.from(resp.create)
              .filter((device: any) => device.code === 200)
              .map((device: any) => device.id);
            const addedDevices = devices.filter((device) => addedDevicesIds.includes(device.id)).map((device) => ({ ...device, usages: [] }));

            const failedDeviceNames = devices.filter((device) => !addedDevicesIds.includes(device.id)).map((device) => device.deviceInfo.hostname);
            if (failedDeviceNames.length > 0) {
              this._messengerNotificationService.showErrorMessage('siteManagementScope.SITE_MANAGEMENT.GLOBAL.ERROR.ADD_SITE_DEVICES_FAIL_PARTIALLY_150', {
                devicesNames: failedDeviceNames.join(', '),
              });
            }

            return DeviceActions.AddDevicesSuccess({ devices: addedDevices });
          }),
          catchError((err) => {
            this._messengerNotificationService.showErrorMessage('siteManagementScope.SITE_MANAGEMENT.GLOBAL.ERROR.ADD_SITE_DEVICES_FAIL_150');
            return of(DeviceActions.AddDevicesFail({ deviceIds: devices.map((device) => device.id), error: err.error }));
          })
        );
      })
    )
  );

  deleteDevices$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DeviceActions.DeleteDevices),
      map((action) => action.deviceIds),
      withLatestFrom(this._store.pipe(siteManagementEntities.currSiteData$)),
      concatMap(([deviceIds, currSite]) => {
        const dto = { create: [], delete: deviceIds };
        return this._siteService.attachDetachDevicesToSite(currSite.id, dto as any).pipe(
          map((resp: any) => {
            const deletedDevicesIds = Array.from(resp.delete)
              .filter((device: any) => device.code === 200)
              .map((device: any) => device.id);
            return DeviceActions.DeleteDevicesSuccess({ deviceIds: deletedDevicesIds });
          }),
          catchError((err) => {
            this._messengerNotificationService.showErrorMessage('siteManagementScope.SITE_MANAGEMENT.GLOBAL.ERROR.DELETE_SITE_DEVICE_FAIL_150');
            return of(DeviceActions.DeleteDevicesFail({ deviceIds, error: err.error }));
          })
        );
      })
    )
  );

  constructor(private actions$: Actions, private _store: Store, private _siteService: SitesService, private _messengerNotificationService: MessengerNotificationService) {}
}
