import { IDataSource, IDataTableColumnConfig, IModalComponent, ISort, ModalRef } from '@activia/ngx-components';
import { ChangeDetectionStrategy, Component, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { combineLatest, Observable, of, Subject  } from 'rxjs';
import { CommandStatusDTO, DeviceGroupDTO, DeviceInfoDTO } from '@activia/cm-api';
import { map } from 'rxjs/operators';
import { TranslocoService } from '@ngneat/transloco';
import { Store } from '@ngrx/store';
import { CommandEntityType, IRunningCommand } from '../../model/running-command.interface';
import { commandsQuery } from '../../store/commands.selectors';
import { getCommandStatusIcon, getCommandStatusInfo, ICommandStatusInfo } from '../../model/command-info.utils';

type IDataSourceItem = ICommandStatusInfo & { commandStatus: CommandStatusDTO; icon: string };

/**
 * Displays a summary of the currently running commands
 */
@Component({
  selector: 'amp-commands-summary-modal',
  templateUrl: './commands-summary-modal.component.html',
  styleUrls: ['./commands-summary-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CommandsSummaryModalComponent implements OnInit, OnDestroy, IModalComponent {
  @ViewChild('iconTemplate', { static: true }) iconTemplate: TemplateRef<any>;
  @ViewChild('progressTemplate', { static: true }) progressTemplate: TemplateRef<any>;
  @ViewChild('dateTemplate', { static: true }) dateTemplate: TemplateRef<any>;
  @ViewChild('messageTemplate', { static: true }) messageTemplate: TemplateRef<any>;

  /** @ignore **/
  private _componentDestroyed$: Subject<void> = new Subject<void>();

  columns$: Observable<IDataTableColumnConfig<void>[]>;
  dataSource$: Observable<IDataSource<IDataSourceItem>>;
  sortBy: ISort = {
    id: 'startDate',
    direction: 'asc',
  };
  activeLang: string;

  /** @ignore **/
  constructor(private _dialogRef: ModalRef<CommandsSummaryModalComponent>, private _store: Store, private _translocoService: TranslocoService) {
    this.activeLang = this._translocoService.getActiveLang();
  }

  ngOnInit() {
    const runningCommands$ = this._store.select<IRunningCommand[]>(commandsQuery.getRunningCommands);
    const completedCommands$ = this._store.select<IRunningCommand[]>(commandsQuery.getCompletedCommands);

    this.dataSource$ = combineLatest([runningCommands$, completedCommands$]).pipe(
      map(([runningCommands, completedCommands]) => {
        const rows = [...runningCommands, ...completedCommands].map((command) => {
          // currently target name of command status is the ip of the device, but its more relevant to show the device name
          const commandStatus: CommandStatusDTO = !command.commandStatus
            ? null
            : {
                ...command.commandStatus,
                targetName: this._getCommandStatusTarget(command.commandStatus.targetName, command.entityType, command.entity),
              };
          const commandStatusInfo = getCommandStatusInfo(commandStatus, this._translocoService);
          const commandStatusIcon = getCommandStatusIcon(commandStatus.commandCode);
          return { ...commandStatusInfo, commandStatus, icon: commandStatusIcon };
        });
        const dataSource: IDataSource<IDataSourceItem> = {
          data: rows,
          total: rows.length,
        };
        return dataSource;
      })
    );

    this._initColumns();
  }

  canClose(): boolean {
    return true;
  }

  onClose() {
    this._dialogRef.close();
  }

  /** @ignore **/
  ngOnDestroy(): void {
    this._componentDestroyed$.next();
    this._componentDestroyed$.complete();
  }

  private _initColumns() {
    const fixedColumns$: Observable<IDataTableColumnConfig<void>[]> = of([
      {
        id: 'icon',
        name: '',
        sortable: false,
        dataCellTemplate: this.iconTemplate,
        horizontalAlign: 'center',
        widthPx: 100,
      },
      {
        id: 'progress',
        name: this._translocoService.selectTranslate('commands.COMMANDS.COMMANDS_SUMMARY.COLUMNS.PROGRESS_20'),
        sortable: false,
        dataCellTemplate: this.progressTemplate,
        widthRatio: 0.7,
      },
      {
        id: 'startDate',
        name: this._translocoService.selectTranslate('commands.COMMANDS.COMMANDS_SUMMARY.COLUMNS.START_DATE_20'),
        sortable: false,
        dataCellTemplate: this.dateTemplate,
        widthRatio: 0.2,
      },
    ]);

    const variableColumns$: Observable<IDataTableColumnConfig<void>[]> = this.dataSource$.pipe(
      map((dataSource) => {
        const hasMessage = dataSource.data.some((item) => !!item.commandStatus.messageText);
        if (hasMessage) {
          return [
            {
              id: 'message',
              name: this._translocoService.selectTranslate('commands.COMMANDS.COMMANDS_SUMMARY.COLUMNS.MESSAGE_20'),
              sortable: false,
              prop: 'commandStatus.messageText',
              widthPx: 150,
            },
          ];
        }
        return [];
      })
    );

    this.columns$ = combineLatest([fixedColumns$, variableColumns$]).pipe(map(([fixedColumns, variableColumns]) => [...fixedColumns, ...variableColumns]));
  }

  /**
   * Command status usually returns the device ip address, but hostname would be more relevant to identify the device.
   * So this method will append the device name or hostname (whichever is filled)
   * **/
  private _getCommandStatusTarget(commandStatusTarget: string, entityType: CommandEntityType, entity: DeviceGroupDTO | DeviceInfoDTO) {
    if (!entity) {
      return commandStatusTarget;
    }
    if (!commandStatusTarget) {
      return '';
    }
    return entityType === CommandEntityType.DEVICE ? (
      this._getCommandStatusTargetWithProperty(commandStatusTarget, (entity as DeviceInfoDTO).deviceName) ||
      this._getCommandStatusTargetWithProperty(commandStatusTarget, (entity as DeviceInfoDTO).hostname) ||
      commandStatusTarget
    ) : (
      this._getCommandStatusTargetWithProperty(commandStatusTarget, (entity as DeviceGroupDTO).name) ||
      commandStatusTarget
    );
  }

  private _getCommandStatusTargetWithProperty(commandStatusTarget: string, propertyValue: string) {
    if (!propertyValue) {
      return null;
    }
    const propertyValueEqualsTarget = commandStatusTarget.toLowerCase() === propertyValue.toLowerCase();
    if (propertyValueEqualsTarget) {
      return null;
    }
    const propertyValueContainsTarget = propertyValue.toLowerCase().indexOf(commandStatusTarget.toLowerCase()) !== -1;
    if (propertyValueContainsTarget) {
      return propertyValue;
    }
    return `${propertyValue} (${commandStatusTarget})`;
  }
}
