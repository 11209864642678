import { IExpressionSuggestionsResult, NlpOverlayComponent, NlpOverlayService, SliderComponent } from '@activia/ngx-components';
import { ChangeDetectionStrategy, Component, Input, OnDestroy, ViewChild } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'amp-nlp-numeric-range-picker',
  templateUrl: './nlp-numeric-range-picker.component.html',
  styleUrls: ['./nlp-numeric-range-picker.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NlpNumericRangePickerComponent extends NlpOverlayComponent<number> implements OnDestroy {
  @ViewChild(SliderComponent, { static: true }) slider: SliderComponent;

  /** min number */
  @Input() min = 0;

  /** maximum number capable */
  @Input() max = 100;

  @Input() title: string;

  /** @ignore pattern for destroy all subscriptions **/
  private _componentDestroyed$: Subject<void> = new Subject<void>();

  /** observable of the current percentage value **/
  value$: Observable<number>;

  /** @ignore **/

  constructor(private nlpOverlayService: NlpOverlayService) {
    super(nlpOverlayService);
    this.value$ = this.activated$.pipe(
      map(() => this.value),
      takeUntil(this._componentDestroyed$)
    );
  }

  /** Called when the slider is released  **/
  onValueChanged(value: number) {
    this.nlpOverlayService.setValueSelected(`${value}`);
  }

  /** @ignore parses the value from the expression parsing results **/
  parseValue(res: IExpressionSuggestionsResult): number {
    if (!res.currentFullFieldExpression.values || res.currentFullFieldExpression.values.length === 0) {
      return 0;
    }
    return Number(res.currentFullFieldExpression.values[0].image);
  }

  /** @ignore **/
  ngOnDestroy(): void {
    this._componentDestroyed$.next();
    this._componentDestroyed$.complete();
  }
}
