<div class="container" [class.padded]="currentView === 'duration'">
  <ng-container [ngSwitch]="currentView">
    <!-- List -->
    <ng-container *ngSwitchCase="'list'">
      <avn-option-list #optionList [virtualization]="false" [keyboardNavigationEnabled]="true" [maxOptionsVisibleInViewport]="9" [options]="listOptions" (optionClicked)="onListSelection($event)">
      </avn-option-list>
    </ng-container>

    <!-- Duration -->
    <ng-container *ngSwitchCase="'duration'">
      <form id="duration" [formGroup]="durationForm">
        <ng-container *ngTemplateOutlet="iconHeader; context: { title: 'NLP.LABEL.ENTER_DURATION' | transloco, icon: 'av:av_timer' }"></ng-container>
        <div class="duration-selection">
          <avn-form-field id="amount" [enableValidTheme]="false" [enableInvalidTheme]="false">
            <avn-input type="number" [placeholder]="'NLP.LABEL.AMOUNT' | transloco" formControlName="durationAmount" [debounceTime]="300"></avn-input>
          </avn-form-field>
          <avn-form-field id="duration" [enableValidTheme]="false" [enableInvalidTheme]="false">
            <avn-select [placeholder]="'NLP.CATEGORY.TIME_UNIT' | transloco" formControlName="durationUnit" [options]="timeUnitOptions" (optionListToggled)="onOptionListToggled($event)"></avn-select>
          </avn-form-field>
        </div>
      </form>
    </ng-container>

    <!-- Date -->
    <ng-container *ngSwitchCase="'date'">
      <avn-calendar [timezone]="'floating'" [selectedDate]="selectedDate" (selected)="onDateSelected($event)"></avn-calendar>
    </ng-container>

    <!-- Time -->
    <ng-container *ngSwitchCase="'time'">
      <avn-time-picker [timezone]="'floating'" [selectedTime]="selectedDate" (selected)="onTimeSelected($event)" [showKeypad]="false"></avn-time-picker>
    </ng-container>
  </ng-container>
</div>

<ng-template #iconHeader let-title="title" let-icon="icon">
  <div class="icon-header">
    <avn-icon [icon]="icon" *ngIf="icon"></avn-icon>
    <h6>{{ title }}</h6>
  </div>
</ng-template>
