<avn-switch
  [label]="'siteMonitoringSharedScope.SITE_MONITORING.ALARM_EVENTS.SHOW_ERRORS_ONLY_50' | transloco"
  [status]="showOnlyAlarmErrors$ | async"
  [labelPosition]="'after'"
  (statusChanged)="toggleErrorOnlyView()"
></avn-switch>

<ng-container *ngIf="boardAlarms?.length > 0 || boardAlarmsBySection?.size > 0; else emptyAlarm">
  <ng-container *ngIf="boardAlarmsBySection; else listView">
    <ng-container *ngTemplateOutlet="sectionView"></ng-container>
  </ng-container>
</ng-container>

<!-- View of all alarms from all boards in a vertical list -->
<ng-template #listView>
  <amp-board-alarms [siteId]="siteId" [boards]="boardAlarms" [expand]="boardAlarms.length === 1" [disableExpansion]="boardAlarms.length === 1"></amp-board-alarms>
</ng-template>

<!-- View of the alarms from the boards grouped by section list -->
<ng-template #sectionView>
  <div class="section-view">
    <div class="section" *ngFor="let section of boardAlarmsBySection | keyvalue">
      <div class="section-title-container" [avnBackground]="{ cssProp: '--surface-background', shade: 700 }">
        <div class="section-title">{{ section.key }}</div>
      </div>
      <amp-board-alarms [siteId]="siteId" [boards]="section.value.boards" [boardHeaderStyle]="'boardNameOnly'" [expand]="true" [disableExpansion]="true" [showDetailsButton]="false"></amp-board-alarms>
    </div>
  </div>
</ng-template>

<ng-template #emptyAlarm>
  <div class="empty-message">
    <avn-empty-data-message
      [icon]="(showOnlyAlarmErrors$ | async) ? 'action:check_circle' : 'action:task_alt'"
      [text]="
        (showOnlyAlarmErrors$ | async)
          ? ('siteMonitoringSharedScope.SITE_MONITORING.ALARM_EVENTS.EMPTY.EMPTY_ERROR_ALARM_EVENT.TITLE_50' | transloco: { siteTitle: siteTitle })
          : ('siteMonitoringSharedScope.SITE_MONITORING.ALARM_EVENTS.EMPTY.EMPTY_ALARM_EVENT.TITLE_50' | transloco: { siteTitle: siteTitle })
      "
      [subText]="
        (showOnlyAlarmErrors$ | async)
          ? ('siteMonitoringSharedScope.SITE_MONITORING.ALARM_EVENTS.EMPTY.EMPTY_ERROR_ALARM_EVENT.MESSAGE_100' | transloco: { siteTitle: siteTitle })
          : ('siteMonitoringSharedScope.SITE_MONITORING.ALARM_EVENTS.EMPTY.EMPTY_ALARM_EVENT.MESSAGE_100' | transloco: { siteTitle: siteTitle })
      "
    >
    </avn-empty-data-message>
  </div>
</ng-template>
