import { AnalyticsService } from '@activia/ngx-components';
import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';

/** Wires up page view to GA / Analytics module */
@Injectable()
export class RouterListenerService {
  constructor(private router: Router, private analyticsService: AnalyticsService) {}

  init(): void {
    this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe((event: NavigationEnd) => {
      this.analyticsService.trackPageView({
        page: event.urlAfterRedirects,
      });
    });
  }
}
