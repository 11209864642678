export const hasUserPermission = (permissionsRequired: string[], userPermissions: string[]): boolean => {
  // if the permissions required are empty, that means no permissions are required
  if ((permissionsRequired || []).length === 0) {
    return true;
  }

  for (let i = 0; i < permissionsRequired.length; i++) {
    for (let j = 0; j < userPermissions.length; j++) {
      if (userPermissions[j] === permissionsRequired[i]) {
        return true;
      }
    }
  }
  return false;
};
