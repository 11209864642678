import { ThemeType } from '@activia/ngx-components';

export enum HealthStatusCode {
  OK = 0,
  WARNING = 1,
  ERROR = 2,
  UNREACHABLE = 3,
  NOT_MONITORED = 4,
}
export enum HealthStatusName {
  OK = 'ok',
  WARNING = 'warning',
  ERROR = 'error',
  UNREACHABLE = 'unreachable',
  NOT_MONITORED = 'notMonitored',
}

/** Health status enum names used to send over API */
export const HealthStatusAPINames: { [key: number]: string } = {
  [HealthStatusCode.OK]: 'OK',
  [HealthStatusCode.WARNING]: 'WARNING',
  [HealthStatusCode.ERROR]: 'ERROR',
  [HealthStatusCode.UNREACHABLE]: 'UNREACHABLE',
  [HealthStatusCode.NOT_MONITORED]: 'NOT_MONITORED',
};

/** icons used for each site health status **/
export const HealthStatusIcons: { [key: number]: string } = {
  [HealthStatusCode.OK]: 'action:check_circle',
  [HealthStatusCode.WARNING]: 'alert:warning',
  [HealthStatusCode.ERROR]: 'alert:error',
  [HealthStatusCode.UNREACHABLE]: 'action:help',
  [HealthStatusCode.NOT_MONITORED]: 'content:remove_circle',
};

/** themes used for each site health status **/
export const HealthStatusThemes: { [key: number]: ThemeType } = {
  [HealthStatusCode.OK]: ThemeType.SUCCESS,
  [HealthStatusCode.WARNING]: ThemeType.WARNING,
  [HealthStatusCode.ERROR]: ThemeType.DANGER,
  [HealthStatusCode.UNREACHABLE]: ThemeType.SECONDARY,
  [HealthStatusCode.NOT_MONITORED]: ThemeType.INFO,
};

/** The statuses ordered by severity (high to low) **/
export const HealthStatusSeverity = [HealthStatusCode.UNREACHABLE, HealthStatusCode.ERROR, HealthStatusCode.WARNING, HealthStatusCode.OK, HealthStatusCode.NOT_MONITORED];
