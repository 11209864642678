import { ThemeType } from '@activia/ngx-components';

export enum ServiceStatusCode {
  OK = 1,
  ERROR = 0,
}

export const ServiceStatusThemeMap = {
  [ServiceStatusCode.OK]: ThemeType.SUCCESS,
  [ServiceStatusCode.ERROR]: ThemeType.DANGER,
};

/** The statuses ordered by severity (high to low) **/
export const ServiceStatusSeverity = [ServiceStatusCode.ERROR, ServiceStatusCode.OK];
