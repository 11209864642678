import { IEngineTagKeyDesc, IOrgPathDefNode } from '@amp/tag-operation';
import { IJsonSchema } from '@activia/json-schema-forms';
import { AsyncDataState } from '@activia/ngx-components';

export interface IOrgPathNode extends IOrgPathDefNode {
  id?: string;
  parent?: IOrgPathNode;
  childOneOf?: IOrgPathNode[];
}

export interface IOrgPathNodeErrors {
  hasDuplicate?: string[]; // List of duplicate conditions
  missingConditions?: string[]; // List of missing conditions
  missingNoConditionNode?: boolean; // In case the tag is not "specific", then it needs at least 1 node without condition
  noChild?: boolean; // A "tag" node should have a child
  notALeaf: boolean; // A "Name" node should have no child
  noType: boolean; // The node is neither a "tag" or a "name"
}

export const defaultSchema: IJsonSchema = { type: 'string', pattern: '^[a-zA-Z0-9]*$' };

export enum PropertyTypes {
  NAME = 'name',
  TAG = 'tag',
}

export interface OrgpathState {
  selectedNode: string | undefined;
  nodeEntities: Record<string, IOrgPathNode>;
  tagsDefinitions: Record<string, IEngineTagKeyDesc>;
  state: AsyncDataState;
  isSaved: boolean;
}
