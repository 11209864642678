<avn-dialog-layout>
  <avn-dialog-header
    [showCloseIcon]="false"
    [title]="'siteMonitoringSharedScope.SITE_MONITORING.PREFERENCE.GLOBAL_CONFIG_CONFIRMATION.TITLE_100' | transloco: { profile: profile | transloco }"
  ></avn-dialog-header>

  <avn-dialog-content avnScroll>
    <ng-container>
      <span class="message">{{
        mismatchColumns?.length + mismatchKeyMetrics?.length
          | i18nPlural: configMessage
          | transloco
            : {
                status:
                  (dialogConfig.data.defaultToOptimisticView
                    ? 'siteMonitoringSharedScope.SITE_MONITORING.PREFERENCE.GLOBAL_CONFIG_CONFIRMATION.OPTIMISTIC_VIEW.ON_15'
                    : 'siteMonitoringSharedScope.SITE_MONITORING.PREFERENCE.GLOBAL_CONFIG_CONFIRMATION.OPTIMISTIC_VIEW.OFF_15'
                  ) | transloco,
                column: mismatchColumns?.length | i18nPlural: columnI18n | transloco,
                keyMetric: mismatchKeyMetrics?.length | i18nPlural: keyMetricI18n | transloco,
                and: (mismatchColumns?.length && mismatchKeyMetrics?.length ? 'siteMonitoringSharedScope.SITE_MONITORING.PREFERENCE.GLOBAL_CONFIG_CONFIRMATION.AND_10' : '') | transloco,
                profile: profile | transloco
              }
      }}</span>
      <div class="data">
        <ng-container *ngIf="mismatchColumns?.length > 0">
          <div class="category">
            <span class="title">{{
              'siteMonitoringSharedScope.SITE_MONITORING.PREFERENCE.GLOBAL_CONFIG_CONFIRMATION.MISMATCH_COLUMNS_50'
                | transloco
                  : {
                      column: mismatchColumns?.length | i18nPlural: columnI18n | transloco | titlecase
                    }
            }}</span>
            <span *ngFor="let col of mismatchColumns">{{
              'siteMonitoringSharedScope.SITE_MONITORING.COLUMNS.' + (col.type === SiteMonitoringColumnType.SiteProperty ? 'SITE_PROPERTIES' : 'MONITORED_VALUES') + '.' + col.id + '.TITLE_35'
                | transloco
            }}</span>
          </div>
        </ng-container>
        <ng-container *ngIf="mismatchKeyMetrics?.length > 0">
          <div class="category">
            <span class="title">{{
              'siteMonitoringSharedScope.SITE_MONITORING.PREFERENCE.GLOBAL_CONFIG_CONFIRMATION.MISMATCH_KEYMETRICS_50'
                | transloco
                  : {
                      keyMetric: mismatchKeyMetrics?.length | i18nPlural: keyMetricI18n | transloco | titlecase
                    }
            }}</span>
            <span *ngFor="let km of mismatchKeyMetrics">{{ 'siteMonitoringSharedScope.SITE_MONITORING.KEY_METRICS.' + km.id + '.TITLE_35' | transloco }}</span>
          </div>
        </ng-container>
      </div>
    </ng-container>
  </avn-dialog-content>

  <avn-dialog-footer>
    <ng-container>
      <avn-button
        [label]="'siteMonitoringSharedScope.SITE_MONITORING.PREFERENCE.GLOBAL_CONFIG_CONFIRMATION.BUTTON.APPLY_15' | transloco"
        [avnTheme]="'primary'"
        (actioned)="onSave(confirmationTypes.CASCADE)"
      >
      </avn-button>
      <avn-button
        [label]="'siteMonitoringSharedScope.SITE_MONITORING.PREFERENCE.GLOBAL_CONFIG_CONFIRMATION.BUTTON.DO_NOT_APPLY_20' | transloco"
        [avnTheme]="'primary'"
        (actioned)="onSave(confirmationTypes.SOLO)"
      >
      </avn-button>
      <avn-button [label]="'siteMonitoringSharedScope.SITE_MONITORING.GLOBAL.BUTTON.CANCEL_15' | transloco" (actioned)="onCancel()"> </avn-button>
    </ng-container>
  </avn-dialog-footer>
</avn-dialog-layout>
