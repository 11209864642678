import { Observable } from 'rxjs';
import { findGeoLocation, IGeoLocationValidationResult } from '../../../utils/geo-location-validator.utils';
import { map } from 'rxjs/operators';
import { GoogleMapsService } from '@activia/geo';
import { SiteDTO } from '@activia/cm-api';

export type ISiteGeoResponse<T = any> = Omit<IGeoLocationValidationResult<T>, 'valid'> & { response: 'ok' | 'error' | 'warning' };

export const validateCsvSiteGeoLocation$ = (site: SiteDTO, googleMapsService: GoogleMapsService): Observable<ISiteGeoResponse> =>
  findGeoLocation(googleMapsService, {
    address: site.address,
    geodeticCoordinates: site.geodeticCoordinates,
  }).pipe(
    map((result: IGeoLocationValidationResult) => {
      if (result.valid) {
        return {
          response: 'ok',
          result: {
            site: {
              ...site,
              geodeticCoordinates: result.result.location
            }
          },
        };
      }

      const response = ['MISMATCH_GEODETIC_COORDINATE', 'MISMATCH_ADDRESS', 'MULTIPLE_ADDRESSES_FOUND'].includes(result.status) ? 'warning' : 'error';
      return {
        response,
        ...result,
      };
    })
  );
