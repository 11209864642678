import { ChangeDetectionStrategy, Component, EventEmitter, Output } from '@angular/core';
import { Store } from '@ngrx/store';
import { getExperienceTemplates } from '../../store/experience-template';
import { Observable } from 'rxjs';
import { map, withLatestFrom } from 'rxjs/operators';
import { IOptionData, ISelectionChangeEvent } from '@activia/ngx-components';
import { IExperienceTemplate, IExperienceTemplateInfo } from '../../models/experience-template.interface';
import { getBoardOrgPathFromTags, selectBoardOrgPathDefinition } from '@amp/tag-operation';

@Component({
  selector: 'amp-site-sync-experience-selection',
  templateUrl: './site-sync-experience-selection.component.html',
  styleUrls: ['./site-sync-experience-selection.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SiteSyncExperienceSelectionComponent {
  @Output() experiencesSelected = new EventEmitter<IExperienceTemplateInfo>();

  experienceTemplateOptions$: Observable<IOptionData<IExperienceTemplate>[]>;
  selection: IExperienceTemplateInfo = {
    experiences: [],
    deviceAction: null,
  };

  constructor(private _store: Store) {
    this.experienceTemplateOptions$ = this._store.select(getExperienceTemplates).pipe(
      withLatestFrom(this._store.select(selectBoardOrgPathDefinition)),
      map(([data, boardOrgPathDef]) =>
        Object.values(data).map((experience) => ({
          value: experience.label,
          label: experience.label,
          data: experience,
          group: getBoardOrgPathFromTags(boardOrgPathDef, experience.tags).split('.').join(' '),
        }))
      )
    );
  }

  selectExperienceDeviceAction(deviceAction: 'provision' | 'connect-existing') {
    this.selection = {
      ...this.selection,
      deviceAction,
    };
    this.experiencesSelected.emit(this.selection);
  }

  onExperienceSelectionChanged(event: ISelectionChangeEvent<IOptionData<IExperienceTemplate>>) {
    this.selection = {
      ...this.selection,
      experiences: event.selected.map((selectedExperience) => ({
        experience: selectedExperience.data,
        count: 1, // for now we dont allow specifying how many experience need to be created
      })),
    };
    this.experiencesSelected.emit(this.selection);
  }
}
