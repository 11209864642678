import { DeviceGroupDTO } from '@activia/cm-api';
import * as GlobalAction from './global.action';
import * as AuthAction from '@amp/auth';
import { Action, createReducer, on } from '@ngrx/store';
import { AsyncData, LoadingState } from '@activia/ngx-components';


/**
 * The global state
 * responsible for storing app wide state that is unrelated to auth
 * i.e global preferences or display parameters
 */
export interface IGlobalState {
  isUserOnline: boolean;
  lang: string;
  /** Available device groups within the current user's role */
  deviceGroups: AsyncData<Array<DeviceGroupDTO>>;
  /** Default device group saved in user's preferences */
  defaultDeviceGroup: AsyncData<DeviceGroupDTO>;
  searchResultSize: number;
  defaultTimeZone: string;
  engineVersion: string;
  customerName: string;
}

export const initialGlobalState: IGlobalState = {
  isUserOnline: true,
  lang: null,
  deviceGroups: {
    data: [],
    state: LoadingState.INIT,
  },
  defaultDeviceGroup: {
    data: undefined,
    state: LoadingState.INIT,
  },
  searchResultSize: 50,
  defaultTimeZone: '',
  engineVersion: undefined,
  customerName: undefined,
};

const reducer = createReducer<IGlobalState>(
  initialGlobalState,

  on(GlobalAction.UserConnectionStatusUpdate, (state, action) => ({
    ...state,
    isUserOnline: action.isUserOnline,
  })),

  on(GlobalAction.UserPreferencesFetch, (state, _) => ({
    ...state,
    defaultDeviceGroup: {
      ...state.defaultDeviceGroup,
      state: LoadingState.LOADING,
      lastAttempt: new Date(),
    },
  })),

  on(GlobalAction.UserPreferencesFetchSuccess, (state, action) => {
    const uPref = {
      ...state,
      lang: action.preferences.global.userLanguage,
      defaultDeviceGroup: {
        ...state.defaultDeviceGroup,
        data: action.preferences.global.defaultDeviceGroup,
        state: LoadingState.LOADED,
        lastSuccess: new Date(),
      },
      searchResultSize: action.preferences.global.searchResultSize,
      defaultTimeZone: action.preferences.global.defaultTimeZone,
    };

    return uPref;
  }),

  on(GlobalAction.UserPreferencesFetchFail, (state, action) => ({
    ...state,
    defaultDeviceGroup: {
      ...state.defaultDeviceGroup,
      state: { errorMsg: action.error },
    },
  })),

  on(GlobalAction.UserLanguageUpdateSuccess, (state, action) => ({
    ...state,
    lang: action.language,
  })),


  on(GlobalAction.UserPreferencesUpdateSuccess, (state, action) => ({
      ...state,
      ...action.preferences,
      defaultDeviceGroup: {
        ...state.defaultDeviceGroup,
        data: action.preferences.defaultDeviceGroup
      },
    })),

  on(GlobalAction.SystemInformationFetchSuccess, (state, action) => ({
    ...state,
    engineVersion: action.info.cmEngineVersion,
    customerName: action.info.customerName,
  })),

  on(GlobalAction.DeviceGroupsForRoleFetch, (state, _) => ({
    ...state,
    deviceGroups: {
      ...state.deviceGroups,
      state: LoadingState.LOADING,
      lastAttempt: new Date(),
    },
  })),

  on(GlobalAction.DeviceGroupsForRoleFetchSuccess, (state, action) => {
    const found = action.deviceGroups.find((dg) => dg.id === state.defaultDeviceGroup.data?.id);
    const newState = {
      ...state,
      deviceGroups: {
        ...state.deviceGroups,
        data: action.deviceGroups,
        state: LoadingState.LOADED,
        lastSuccess: new Date(),
      },
      defaultDeviceGroup: {
        ...state.defaultDeviceGroup,
        data: found ? state.defaultDeviceGroup.data : undefined,
      },
    };
    return newState;
  }),

  on(GlobalAction.DeviceGroupsForRoleFetchFail, (state, action) => ({
    ...state,
    deviceGroups: {
      ...state.deviceGroups,
      state: { errorMsg: action.error },
    },
  })),

  on(AuthAction.LogoutSuccess, (state, _) => ({
    ...state,
    lang: null,
  }))
);

export const globalReducer = (state = initialGlobalState, action: Action): IGlobalState => reducer(state, action);
