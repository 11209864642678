import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { Address } from '@activia/cm-api';
import { LocationViewMode } from '../location-view-mode.constant';
import { CountryService } from '@activia/geo';
import { Observable, of } from 'rxjs';
import { TranslocoService } from '@ngneat/transloco';

@Component({
  selector: 'amp-address-viewer',
  templateUrl: './address-viewer.component.html',
  styleUrls: ['./address-viewer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddressViewerComponent {
  @Input() address: Address;
  @Input() showEditIcon = true;
  @Output() displayModeChanged = new EventEmitter<LocationViewMode>();

  constructor(private _countryService: CountryService, private _translocoService: TranslocoService) {}

  getCountryTranslation(countryNameOrCode: string): Observable<string> {
    const country = this._countryService.getCountryByCountryNameOrCode(countryNameOrCode);
    return country?.shortCountryCode ? this._translocoService.selectTranslate(country.shortCountryCode, {}, { scope: 'countries' }) : of('');
  }
}
