<ng-container *ngIf="boards$ | async as boards">
  <ng-container *transloco="let t; read: 'siteManagementScope.SITE_MANAGEMENT.SITE_DETAIL.SITE_BOARDS_EDITOR'">
    <amp-board-selector [filterByDisplay]="true" (boardChange)="selectBoard($event, boards)"></amp-board-selector>

    <ng-container *ngIf="selectedBoard$ | async as board">
      <ng-container *ngIf="board | applyFunction: hasScreen">
        <span>{{ t('SELECT_SCREEN_50') }}</span>
        <div class="screens" *ngIf="displayList$ | async as displayEntities">
          <div *ngFor="let displayId of board.displays" class="display">
            <avn-button
              [icon]="'communication: desktop_windows'"
              [label]="displayEntities[displayId]?.name"
              [avnTheme]="selectedScreenId$ | async | applyFunction: getTheme:[displayId]:this"
              (actioned)="onScreenChange(displayId, board, displayEntities)"
            >
            </avn-button>
          </div>
        </div>
      </ng-container>
    </ng-container>
  </ng-container>
</ng-container>
