import { SiteDTO } from '@activia/cm-api';
import { ICsvSite } from '../../../models/site-management-csv-columns';

export const convertCsvSiteToDTO = (csvSite: ICsvSite): SiteDTO => ({
  id: csvSite.id,
  externalId: csvSite.externalId,
  name: csvSite.name,
  address: {
    addressLine1: csvSite.addressLine1,
    addressLine2: csvSite.addressLine2,
    city: csvSite.city,
    state: csvSite.state,
    zip: csvSite.zip,
    country: csvSite.country,
  },
  geodeticCoordinates: {
    longitude: csvSite.longitude,
    latitude: csvSite.latitude,
  },
});
