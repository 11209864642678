import { Component, ChangeDetectionStrategy, OnInit, OnDestroy, Output, EventEmitter, Input } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { IBoardExperienceTemplate, IExperienceTemplate } from '../../models/experience-template.interface';
import { getColumnCount, getGeometryRowAndColumn } from '../experience-template/experience-template.utils';
import * as ExperienceTemplateStore from '../../store/experience-template';

@Component({
  selector: 'amp-experience-template-list',
  templateUrl: './experience-template-list.component.html',
  styleUrls: ['./experience-template-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ExperienceTemplateListComponent implements OnInit, OnDestroy {
  selectedTemplate: IExperienceTemplate;

  @Input() editMode = false;

  @Output() selectTemplate = new EventEmitter<IExperienceTemplate>();

  @Output() addNewTemplate = new EventEmitter<void>();

  /** List of all experience template */
  templates$: Observable<IExperienceTemplate[]>;
  isCollectionLoading$: Observable<boolean>;
  isCollectionLoaded$: Observable<boolean>;

  /** @ignore Pattern used to close all subscriptions*/
  private _componentDestroyed$: Subject<void> = new Subject<void>();

  constructor(private _store: Store) {}

  ngOnInit(): void {
    // Get all experience template
    this.templates$ = this._store.pipe(select(ExperienceTemplateStore.getExperienceTemplatesList));
    this.isCollectionLoading$ = this._store.pipe(select(ExperienceTemplateStore.isExperienceTemplatesLoading));
    this.isCollectionLoaded$ = this._store.pipe(select(ExperienceTemplateStore.isExperienceTemplatesLoaded));
  }

  /**
   * Open a modal to start creation wizard for template
   */
  openTemplateCreation(template: IExperienceTemplate) {
    this.selectedTemplate = template;

    this.selectTemplate.emit(this.selectedTemplate);
  }

  addCreationTemplate() {
    this.selectedTemplate = undefined;
    this.addNewTemplate.emit();
  }

  /** Get the number of column used by all displays */
  getColumnCount(board: IBoardExperienceTemplate) {
    return getColumnCount(board);
  }

  /** Extract from the geometry the row/column position */
  getGeometryRowAndColumn(geometry: string): { row: number; column: number } {
    return getGeometryRowAndColumn(geometry);
  }

  /** @ignore **/
  ngOnDestroy(): void {
    this._componentDestroyed$.next();
    this._componentDestroyed$.complete();
  }
}
