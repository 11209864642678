import { ThemeType } from '@activia/ngx-components';

/** Filters for geodetic fixer page */
export type GeoFixerFilter =
  'all' |
  /** Sites with incomplete addresses or ambiguous addresses */
  'incomplete_address' |
  'incomplete_geodetic_coordinates' |
  'missing_timezone';

export interface IGeoFixerFilter {
  ribbonTheme: ThemeType;
  ribbonIcon: string;
}

export const GeoFixerFilter: Record<GeoFixerFilter, IGeoFixerFilter> = {
  all: {
    ribbonTheme: ThemeType.INFO,
    ribbonIcon: 'content:select_all',
  },
  incomplete_address: {
    ribbonTheme: ThemeType.DANGER,
    ribbonIcon: 'maps:place',
  },
  incomplete_geodetic_coordinates: {
    ribbonTheme: ThemeType.WARNING,
    ribbonIcon: 'maps:my_location',
  },
  missing_timezone: {
    ribbonTheme: ThemeType.WARNING,
    ribbonIcon: 'device:access_time',
  },
};

export const GeoFixerFilters = Object.keys(GeoFixerFilter);
