import { MonitoringAlarmEventDTO } from '@activia/cm-api';
import { IAlarmEventDisplayConfig } from '../model/alarm-event-display-config.interface';
import { AlarmEventNamespace } from '../model/alarm-event-namespace.enum';
import { extractDisplayIdFromAlarm, extractPlayerIdFromAlarm, findAlarmEvent } from './alarm-event.utils';

interface IAlarmEventTextI18n {
  key: string;
  params?: any;
}
export interface IAlarmEventTextInfo {
  customerMessage: IAlarmEventTextI18n;
  customerAction: IAlarmEventTextI18n;
  defaultAction: IAlarmEventTextI18n;
  namespace: IAlarmEventTextI18n;
  longMessage: string;
}

export const toAlarmEventTextInfo = (alarm: MonitoringAlarmEventDTO, { showCustomerMessage, showLongMessage }: IAlarmEventDisplayConfig): IAlarmEventTextInfo => {
  // Find the alarm to be able to retrieve the customer-friendly i18n messages
  const deviceAlarm = findAlarmEvent(alarm);

  // build the alarm message
  const namespace = deviceAlarm?.namespace ? getAlarmNamespaceI18nDetails(deviceAlarm.namespace, alarm) : null;

  // for now we use the same customer text for dmb / amp
  const customerMessageI18n = deviceAlarm?.i18n.dmbMessage;
  const customerActionI18n = deviceAlarm?.i18n.dmbAction;
  const defaultActionI18n = deviceAlarm?.i18n.defaultAction;

  // define what info to use in the alarm message
  const customerMessage = showCustomerMessage && customerMessageI18n ? { key: customerMessageI18n } : null;
  const customerAction = showCustomerMessage && customerActionI18n ? { key: customerActionI18n } : null;
  const showDefaultAction = showLongMessage || !showCustomerMessage;
  const defaultAction = showDefaultAction && defaultActionI18n ? { key: defaultActionI18n } : null;
  const longMessage = showLongMessage || !showCustomerMessage || !customerMessage ? alarm.longMessage : null;

  return {
    customerMessage,
    customerAction,
    defaultAction,
    namespace,
    longMessage,
  };
};

export const getAlarmNamespaceI18nDetails = (alarmEventNamespace: AlarmEventNamespace, alarm: MonitoringAlarmEventDTO): IAlarmEventTextI18n => {
  let params: unknown;
  if (alarmEventNamespace === AlarmEventNamespace.Player) {
    const playerId = extractPlayerIdFromAlarm(alarm);
    // note for UI display we increase the id by 1 (display 0 => display 1) to match with alarm messages content
    params = { playerId: playerId === null ? null : playerId + 1 };
  } else if (alarmEventNamespace === AlarmEventNamespace.Display) {
    const displayId = extractDisplayIdFromAlarm(alarm);
    params = { displayId: displayId === null ? null : displayId + 1 };
  }
  return {
    key: `alarmEvents.ALARM_EVENTS.NAMESPACES.${alarmEventNamespace.toUpperCase()}`,
    params,
  };
};
