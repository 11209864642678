import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { IColumnPickerItem } from './column-picker-item.interface';

/**
 * Service internal to the column picker to facilitate sharing the selected columns across all column picker components
 */
@Injectable()
export class ColumnPickerService {
  /** Ids of the selected items **/
  private _selectedColumnsSubject = new BehaviorSubject<IColumnPickerItem[]>([]);

  /** @ignore Emits when the selected columns were changed by the user **/
  private _valueChangedSubject = new Subject<IColumnPickerItem[]>();

  /**
   * Sets the selected columns of the column picker
   *
   * @param items the newly selected items
   * @param fromUserAction indicates if the selected items changed was triggered by a user action (adding columns, reordering, etc..)
   */
  public setSelectedColumns(items: IColumnPickerItem[], fromUserAction: boolean) {
    this._selectedColumnsSubject.next(items);
    if (fromUserAction) {
      this._valueChangedSubject.next(items);
    }
  }

  /** Returns an observable of the selected columns of the column picker */
  get selectedColumns$(): Observable<IColumnPickerItem[]> {
    return this._selectedColumnsSubject.asObservable();
  }

  /** Returns the currently selected columns of the column picker */
  get selectedColumns(): IColumnPickerItem[] {
    return this._selectedColumnsSubject.getValue();
  }

  /** Emits when the selected columns were changed by the user (col added/removed/reordered) **/
  get valueChanged$(): Observable<IColumnPickerItem[]> {
    return this._valueChangedSubject.asObservable();
  }
}
