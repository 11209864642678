import { ISiteMonitoringConfig } from '@amp/environment';
import { ISiteMonitoringUserPreferences, SiteMonitoringPreference } from '../model/site-monitoring-preference.interface';

export const getDefaultSiteMonitoringPreferences = (config: Partial<ISiteMonitoringConfig>): SiteMonitoringPreference => ({
    defaultToOptimisticView: config.defaultToOptimisticView,
    hideKeyMetricsOnOk: config.hideKeyMetricsOnOk,
    enableDashboard: config.enableDashboard,
    dashboardSitesThreshold: config.dashboardSitesThreshold,
    enableSiteSearch: true,
    showBoardPlayerMapping: config.showBoardPlayerMapping,
    siteSearchMinChars: config.siteSearchMinChars,
    showLongMessage: false,
    showCustomerMessage: true,
    showHealthErrorAlarms: true,
    showIpAddress: true,
    sharedPlayerListIds: [],
  });

export const getDefaultSiteMonitoringUserPreferences = (): ISiteMonitoringUserPreferences => ({
    enableSiteDetailKeyMetrics: true,
    showOnlyAlarmErrors: true,
    showSiteDetailMap: true,
    showDashboardMap: true,
    enableSiteDetailAlarmEventsSummary: true,
    enableSiteListCardView: false,
  });
