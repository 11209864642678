import { BoardDTO, DeviceDTO, DisplayDTO } from '@activia/cm-api';
import { IGridSize } from '@activia/ngx-components';
import { generateDisplay, generateInput } from './display.utils';
import { OperationalState } from '@amp/devices';
import { IBoard } from '../models/board-config.interface';
import { EngineTagLevel, getBoardOrgPathFromTags, IOrgPathDefRoot, TagValueAssignmentScope } from '@amp/tag-operation';
import { IJsonSchema } from '@activia/json-schema-forms';

/**
 * Convert the data returned from the endpoint to the internal format.
 * Also, data returned might have some fields that are not set correctly, such as geometry, or board
 * order might be null, or display might be null. This function will try to set correct values whenever possible.
 */
export const convertToIBoard = (
  board: BoardDTO,
  boardIdx: number,
  siteDevices: DeviceDTO[],
  tagsStructure: {
    key?: string;
    value?: string;
    title?: string;
  }[],
  expTemplateTag?: any
): IBoard => {
  // Task 1 - Calculate the board size from geometry values of all displays in this board
  // WARNING: the existing boards don't have this value set properly. In this case, use the length of
  //          the displays as the board size, and always assume the layout is multiple columns x 1 row.
  let displays = board.displays || [];

  const xOffsetsInDisplays = displays
    .filter((display) => !!display)
    .map((display) => display.geometry.geometry)
    .map((g) => +g.split('+')[1]);
  const yOffsetsInDisplays = displays
    .filter((display) => !!display)
    .map((display) => display.geometry.geometry)
    .map((g) => +g.split('+')[2]);
  const col = Math.max(...xOffsetsInDisplays, 0) + 1;
  const row = Math.max(...yOffsetsInDisplays, 0) + 1;

  // Task 2 - Fix board order whenever applicable
  // Some boards in some existing sites have null as the value of the order, will set this value based
  // on the order they are returned within the same org path
  const order = board.order ?? boardIdx;

  // Task 3 - in the case that a display comes back as null, or comes back without inputs, or with
  //          missing primary/secondary input, create empty data whenever applicable for the missing one(s)
  // Task 3.1 - handle displays that are not null only. If a display is null, it might be left like this
  //            intentionally by the user to have an empty spot at that position
  displays = displays.map((display) => {
    if (!display) {
      return null;
    }

    let inputs = [...(display.inputs ?? [])];
    // Task 3.2 - handle null inputs
    if (inputs.length === 0) {
      // In this case generate 2 inputs, one for primary device another for secondary device
      inputs = [generateInput()];
    } else {
      // If any input is null, generate a default one
      inputs = inputs.map((input) => input || generateInput());
    }

    return {
      ...display,
      inputs,
    };
  });

  return {
    ...board,
    organizationPath: tagsStructure.map((e) => e.value).join('.') + '.' + board.name,
    displays: displays.map((e) => e?.id),
    id: board.id,
    order,
    size: {
      row,
      column: col,
    },
    isLocked: isBoardLocked(displays, siteDevices),
    isAccordionOpen: true,
    minSelectableSize: { row: 1, column: 1 },
    tagsStructure,
    experienceTemplateTag: expTemplateTag,
  };
};

/**
 * Returns whether the board is locked or not.
 * A board is locked when its physical setup is done. In this case, the board cannot change physical settings
 * (size, display orientation, etc) again.
 *
 * The physical setup is considered to be done when:
 * - every display in this board has at least the primary device set, and
 * - any of the devices in this board is NOT in provisioned state
 */
export const isBoardLocked = (displays: DisplayDTO[], siteDevices: DeviceDTO[]): boolean => {
  // Board is not locked when there is no display or at least one null/undefined display
  if (!displays || displays.length === 0 || displays.filter((display) => !display).length > 0) {
    return false;
  }
  const hasEveryDisplayPrimaryDevice = displays.every((display) => !!display.inputs[0].deviceId);
  const deviceIds = displays.reduce((curr, display) => curr.concat(display.inputs.filter((input) => !!input.deviceId).map((input) => input.deviceId)), []);
  const devices = siteDevices.filter((device) => deviceIds.includes(device.id));
  const hasNonRunningDevice = devices.some((device) => device?.deviceInfo?.operationalState !== OperationalState.Running);
  return hasEveryDisplayPrimaryDevice && !hasNonRunningDevice;
};

/** Change the size of a board and re-generate displays whenever applicable */
export const changeBoardSize = (displays: DisplayDTO[], newBoardSize: IGridSize): DisplayDTO[] => {
  // Get all display with a device connected
  const displaysToKeep = displays.filter((display) => display?.inputs.some((input) => !!input?.deviceId));

  // Check if there is enough room for all connected displays
  if (newBoardSize.row * newBoardSize.column < displaysToKeep.length) {
    return displays;
  }

  // Create the grid [row x column] of displays
  const grid = Array(newBoardSize.row)
    .fill(0)
    .map(() => Array(newBoardSize.column).fill(null));

  // Place displays that can keep their position into the grid and get all that need to be repositioned
  const displayToReposition = displaysToKeep.filter((display) => {
    const position = display.geometry.geometry.split('+');
    const col = +position[1];
    const row = +position[2];

    if (col >= newBoardSize.column) {
      return true;
    } else if (row >= newBoardSize.row) {
      return true;
    }

    grid[row][col] = display; // this display keep the same position as before

    return false;
  });

  // Fill the remaining grid slot with :
  // 1 - Connected Display that need to be repositioned
  // 2 - Empty display that was at that position previously
  // 3 - If no display is available, then generate a new one
  const emptyDisplays = displays.filter((display) => display?.inputs.every((input) => !input?.deviceId));
  for (let row = newBoardSize.row - 1; row >= 0; row--) {
    for (let col = newBoardSize.column - 1; col >= 0; col--) {
      if (!grid[row][col]) {
        if (displayToReposition.length) {
          // 1. Connected Display that need to be repositioned
          grid[row][col] = displayToReposition.pop();
        } else {
          const emptyDisplay = emptyDisplays.find((e) => e.geometry.geometry === `+${col}+${row}`);
          if (emptyDisplay) {
            // 2. Empty display that was at that position previously
            grid[row][col] = emptyDisplay;
          }
        }
      }
    }
  }

  // 3. Generate new display for all empty slot
  const usedName = displays.filter((e) => !!e).map((e) => e.name);
  for (let row = 0; row < newBoardSize.row; row++) {
    for (let col = 0; col < newBoardSize.column; col++) {
      if (!grid[row][col]) {
        grid[row][col] = generateDisplay(col + row, col, row, usedName);
        usedName.push(grid[row][col].name); // Update list of used name
      }
    }
  }

  // Sanitize geometry for resulting grid
  return resetDisplaysGeometry(grid.flat(), newBoardSize);
};

/**
 * Get the next ancestor tags for the next board to be created.
 * A site can a have many board on the same zone but board org paths need to be unique so we prefix them with an index
 * ex: outdoor.1.menuboard, outdoor.2.menuboard
 *
 * This method returns the next board ancestor org path tags with the correct index to avoid duplicate board org paths.
 * */
export const getNewBoardTags = (
  boardOrgPathDef: IOrgPathDefRoot,
  tagsDefinitions: Record<string, IJsonSchema>,
  boardName: string,
  tags: Record<string, unknown>,
  existingSiteBoardsOrgPath: string[]
): Record<string, unknown> => {
  // Find first tags with an index
  let node = boardOrgPathDef.root;
  let numberTagKey;

  while (node && !numberTagKey) {
    if (tagsDefinitions[node.tag]?.type === 'number') {
      numberTagKey = node.tag;
    } else {
      // Find the next node
      node = node.childOneOf?.find((e) => e.dependentItem === tags[node.tag] || e.dependentItem === undefined);
    }
  }

  // Start with 1 and increment until we find a free index
  let tagIndex = 1;
  while (
    existingSiteBoardsOrgPath.includes(
      getBoardOrgPathFromTags(
        boardOrgPathDef,
        {
          ...tags,
          [numberTagKey]: tagIndex,
        },
        boardName
      )
    )
  ) {
    tagIndex++;
  }

  return {
    ...tags,
    [numberTagKey]: tagIndex,
  };
};

/** Reset geometry and boardScreenIdx of displays within a board after shrink/expand the board */
const resetDisplaysGeometry = (displays: DisplayDTO[], boardSize: IGridSize) =>
  displays.map((display, idx) => {
    const xOffset = idx % boardSize.column;
    const yOffset = Math.floor(idx / boardSize.column);

    return {
      ...display,
      boardScreenIdx: idx,
      geometry: { ...display.geometry, geometry: `+${xOffset}+${yOffset}` },
    };
  });

export const mapBoards = (board: any, siteLabel: string): TagValueAssignmentScope => {
  return {
    entityName: board?.name,
    id: board?.id,
    ids: [],
    level: EngineTagLevel.BOARD,
    siteLabel,
  } as TagValueAssignmentScope;
};
