/* eslint-disable arrow-body-style */
import { Observable, of, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { ApplicationPreferencesService } from '@activia/cm-api';
import { HttpResponse } from '@angular/common/http';

/**
 * Facilitates the creation of application preferences
 * (no need to check for 404)
 *
 * @param applicationPreferencesService reference to the application preference service
 * @param preferenceKey the key of the preference
 * @param defaultValue a default value to be returned if the preference data does not exists
 */
export const getApplicationPreferencesByKey = <T>(applicationPreferencesService: ApplicationPreferencesService, preferenceKey: string, defaultValue: T, handleNotFoundError = true): Observable<T> =>
  applicationPreferencesService.getPreferencesByAppKey(preferenceKey).pipe(
    map((dto) => JSON.parse(dto.preferences[0]?.value || null) || defaultValue),
    catchError((err) => (err.status === 404 && handleNotFoundError ? of(defaultValue) : throwError(err)))
  );

/**
 * Facilitates the update of application preferences
 * The logic for saving app preferences seems like overkill so this methods provides a simpler api
 * We need to use a particular endpoint for saving if the key already exists,
 * or another endpoint must be used if it does not exist..
 *
 * @param applicationPreferencesService reference to the application preference service
 * @param preferenceKey the key of the preference
 * @param value the value to save in preferences
 */
export const updateApplicationPreferencesByKey = <T = any>(applicationPreferencesService: ApplicationPreferencesService, preferenceKey: string, value: T): Observable<HttpResponse<any>> =>
  applicationPreferencesService
    .updateAppPreferences({
      appName: preferenceKey,
      preferences: [
        {
          key: preferenceKey,
          value: JSON.stringify(value),
        },
      ],
    })
    .pipe(catchError((err) => (err.status === 404 ? createApplicationPreferences(applicationPreferencesService, preferenceKey, value) : throwError(err))));

/**
 * Facilitates the creation of an application preference
 *
 * @param applicationPreferencesService reference to the application preference service
 * @param preferenceKey the key of the preference
 * @param value the value of the preference
 */
export const createApplicationPreferences = <T = any>(applicationPreferencesService: ApplicationPreferencesService, preferenceKey: string, value: T): Observable<HttpResponse<any>> => {
  return applicationPreferencesService.createApplicationPreferences({
    appName: preferenceKey,
    preferences: [
      {
        key: preferenceKey,
        value: JSON.stringify(value),
      },
    ],
  });
};
