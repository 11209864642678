<amp-key-metrics-status-card
  [config]="config"
  [monitoringDataDataState]="monitoringDataDataState"
  [cardConfig]="cardConfig"
  [monitoringData]="monitoringData"
  [monitoringDate]="monitoringDate"
  [optimisticViewEnabled]="config.customConfig?.optimisticViewEnabled"
  [showCompactView]="showCompactView"
>
</amp-key-metrics-status-card>
