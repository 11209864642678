import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Inject,
  OnDestroy,
  Output,
  ViewChild
} from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import {
  dataOnceReady,
  IModalComponent,
  IModalConfig,
  MODAL_CONFIG,
  ModalRef,
  StepperComponent
} from '@activia/ngx-components';
import { IModalPickerComponent } from '../../models/modal-picker-component.interface';
import { combineLatest, map, Observable, ReplaySubject, share, Subject } from 'rxjs';
import { SiteDTO } from '@activia/cm-api';
import { siteManagementEntities } from '../../store/site-management.selectors';
import { distinctUntilChanged } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { ISiteManagementState } from '../../store/site-management.reducer';
import { MessengerNotificationService } from '@amp/messenger';
import { SiteMonitoringFacade, SiteProperties } from '@amp/site-monitoring-shared';

@Component({
  selector: 'amp-custom-site-selection-modal',
  templateUrl: './custom-site-selection-modal.component.html',
  styleUrls: ['./custom-site-selection-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CustomSiteSelectionModalComponent implements OnDestroy, IModalComponent, IModalPickerComponent<SiteDTO[]> {
  @ViewChild(StepperComponent, { static: false }) stepper: StepperComponent;

  /** Emits the ID of the new site after the site is created **/
  @Output() saved = new EventEmitter<SiteDTO[]>();

  form: UntypedFormGroup;

  summary$: Observable<{ valid: SiteDTO[]; invalid: string[] }>;
  summary: { valid: SiteDTO[]; invalid: string[] };

  SiteProperties = SiteProperties;

  private _selectedExternalIdsSubject: Subject<string[]> = new Subject<string[]>();

  /** @ignore **/
  private _componentDestroyed$: Subject<void> = new Subject<void>();

  constructor(
    @Inject(MODAL_CONFIG) public dialogConfig: IModalConfig<void>,
    private _dialogRef: ModalRef<CustomSiteSelectionModalComponent>,
    private _fb: UntypedFormBuilder,
    private _store: Store<ISiteManagementState>,
    private _messenger: MessengerNotificationService,
    public siteMonitoringFacade: SiteMonitoringFacade,
  ) {
    const _allSites$ = dataOnceReady(this._store.pipe(siteManagementEntities.allSitesData$), this._store.pipe(siteManagementEntities.allSitesDataState$)).pipe(
      distinctUntilChanged((prev, curr) => JSON.stringify(prev) === JSON.stringify(curr)),
    );

    this.summary$ = combineLatest([_allSites$, this._selectedExternalIdsSubject]).pipe(
      map(([allSites, externalIds]) => {
        const validSelections = [];
        const invalidSelections = [];
        externalIds.forEach((externalId) => {
          const site = allSites.find((s) => s.externalId?.trim().toLowerCase() === externalId);
          if (site) {
            validSelections.push(site);
          } else {
            invalidSelections.push(externalId);
          }
        });
        this.summary = { valid: validSelections, invalid: invalidSelections };
        return this.summary;
      }),
      share({
        connector: () => new ReplaySubject(1),
        resetOnRefCountZero: true,
        resetOnComplete: false,
        resetOnError: false,
      }),
    );

    this.form = this._fb.group({
      externalIds: ['', [Validators.required]],
    });
  }

  /** @ignore **/
  ngOnDestroy(): void {
    this._componentDestroyed$.next();
    this._componentDestroyed$.complete();
  }

  canClose(): boolean {
    return true;
  }

  /** Called when the cancel button is clicked **/
  onCancel() {
    this._close();
  }

  goNext() {
    if (this.form.valid) {
      if (this.summary.invalid.length) {
        this.stepper.next();
      } else {
        this.selectSites();
        this.stepper.complete();
      }
    }
  }

  copyInvalidSelections() {
    navigator.clipboard.writeText(JSON.stringify(this.summary.invalid))
      .then(() => this._messenger.showInfoMessage('siteManagementScope.SITE_MANAGEMENT.DASHBOARD.SITE_SELECTION_MODAL.COPY_MESSAGE_50'));
  }

  onSitesSelected($event: string) {
    const selectedExternalIds = $event.split(',')
      .map((externalId) => externalId.trim())
      .filter((externalId) => !!externalId)
      .map((externalId) => externalId.toLowerCase());
    this._selectedExternalIdsSubject.next(selectedExternalIds);
  }

  selectSites() {
    this.saved.emit(this.summary.valid);
    this._close();
  }

  private _close() {
    this._dialogRef.close();
  }
}
