import { Inject, Injectable, OnDestroy } from '@angular/core';
import { CreateQueryResultDTO, DeviceDTO, DeviceQueryResultService, DeviceService } from '@activia/cm-api';
import { addExtraDeviceData, getMonitoringListMonitoredValues, toMonitoringListData } from '../utils/device-list.utils';
import { DeviceColumnType } from '../model/device-column-type';
import { DeviceFieldTemplateService } from '../services/device-field-template.service';
import { DeviceFilterApiExpressionService } from '../nlp/device-filter/device-filter-api-expression.service';
import { IMonitoringListDatum, IMonitoringListDTO, IMonitoringSharedListDTO } from '../model/monitoring-list.interface';
import { MONITORING_NLP_DEVICE_FIELDS } from '../nlp/monitoring-nlp-fields';
import { SiteManager } from '../nlp/device-filter/device-filter.tokens';
import {
  DEFAULT_PAPAPARSE_CONFIG,
  exportAsCsv,
  extractIdFromHttpLocationHeaders,
  extractTotalRecordsFromHttpHeaders,
  Papa
} from '@amp/utils/common';
import { catchError, map, mergeMap, switchMap, take, toArray } from 'rxjs/operators';
import { from, Observable, of, Subject, throwError } from 'rxjs';
import { AMP_DMB_INFO_TOKEN, AmpDmbInfoProvider } from '@amp/analytics';
import { HttpResponse } from '@angular/common/http';

@Injectable({ providedIn: 'root' })
export class DeviceListService implements OnDestroy {

  serviceDestroyed$: Subject<void> = new Subject<void>();

  constructor(
    @Inject(AMP_DMB_INFO_TOKEN) private _ampDmbInfoProvider: AmpDmbInfoProvider,
    private _papaParser: Papa,
    private _deviceService: DeviceService,
    private _deviceQueryResultService: DeviceQueryResultService,
    private _deviceFilterApiExpressionService: DeviceFilterApiExpressionService,
    private _deviceFieldTemplateService: DeviceFieldTemplateService,
  ) {
  }

  ngOnDestroy() {
    this.serviceDestroyed$.next();
    this.serviceDestroyed$.complete();
  }

  getCachedDeviceList(list: IMonitoringListDTO, managerId?: number, withMonitoredValues?: boolean, withTags?: boolean): Observable<IMonitoringListDatum[]> {
    const paginationLimit = 1000;
    const cacheTimeInSec = 600;

    const monitoredValues = withMonitoredValues ? getMonitoringListMonitoredValues(list) : null;
    const tagsColumns = withTags ? list.columns.filter((col) => col.type === DeviceColumnType.Tag).map((item) => item.value) : null;
    const optionalFilter = managerId ? list.filter?.length > 0 ? `& ${SiteManager.PATTERN}=${managerId}` : `${SiteManager.PATTERN}=${managerId}` : null;
    const _filter = this._deviceFilterApiExpressionService.getDeviceFilter(list.filter, list.deviceTypes, MONITORING_NLP_DEVICE_FIELDS, true, optionalFilter);

    const queryResult: CreateQueryResultDTO = {
      filter: _filter,
      deviceGroupId: list.deviceGroupId,
    };

    return this._deviceService.createQueryResultObject(cacheTimeInSec, queryResult, 'response').pipe(
      map((response: HttpResponse<any>) => extractIdFromHttpLocationHeaders(response.headers)),
      switchMap((queryResultId: number) => queryResultId ? this._deviceQueryResultService.getQueryResult(queryResultId, paginationLimit, 0, monitoredValues, tagsColumns, 'response').pipe(
        switchMap((response) => {
          const total = extractTotalRecordsFromHttpHeaders<DeviceDTO>(response);
          const devices = response.body || [];
          if (total <= paginationLimit) {
            // If the list contains less or equal to 1000 devices, fetch is done
            return of(devices);
          } else {
            // Else calculate how many more calls are still needed
            // E.g. if total is 3245, each call is limited to 1000 devices only, total 4 calls required
            // First call is already done, so 3 more calls needed
            // Subsequent calls should be done through query result to get the cached data
            const numOfSubsequentCalls = Math.ceil(total / paginationLimit) - 1;

            return from([...Array(numOfSubsequentCalls).keys()].map((key) => key + 1)).pipe(
              mergeMap((idx) => this._deviceQueryResultService.getQueryResult(queryResultId, idx === numOfSubsequentCalls ? total % paginationLimit : paginationLimit, paginationLimit * idx, monitoredValues, tagsColumns), 5),
              toArray(),
              map((remainingDeviceSubsets) => remainingDeviceSubsets.reduce((res, subset) => [ ...res, ...subset ], [...devices])),
            );
          }
        }),
        map((devices: DeviceDTO[]) => {
          const rows = toMonitoringListData(devices, tagsColumns);
          rows.forEach(addExtraDeviceData);
          return rows;
        }),
      ) : throwError([])),
      catchError((_) => throwError([]))
    );
  };

  getExportableDevicesCsvData(list: IMonitoringSharedListDTO, withManagerId: boolean, devices?: IMonitoringListDatum[]): Observable<string> {
    const managerId$ = withManagerId ? this._ampDmbInfoProvider.managerId$ : of(null);
    return managerId$.pipe(
      take(1),
      switchMap((managerId) => devices ? of(devices) : this.getCachedDeviceList(list, managerId, true, true)),
      map((ds) => {
        const { columns, data } = this._deviceFieldTemplateService.toExportableDevices(list, ds);
        // export data to csv format and download it
        const csvFormattedData = exportAsCsv(this._papaParser, columns, data, DEFAULT_PAPAPARSE_CONFIG);
        return csvFormattedData;
      }),
      catchError((_) => of(null)),
    );
  }
}
