<avn-dialog-layout>
  <avn-dialog-header [showCloseIcon]="true" (closeClicked)="onClose()" [template]="headerTemplate"></avn-dialog-header>
  <avn-dialog-content avnScroll>
    <ng-container *ngIf="hasError$ | async; else noError">
      <avn-empty-data-message
        icon="alert:error"
        [text]="'siteMonitoringSharedScope.SITE_MONITORING.COLUMNS_PICKER.ERROR.PICKER_DATA.TITLE_75' | transloco"
        [subText]="'siteMonitoringSharedScope.SITE_MONITORING.COLUMNS_PICKER.ERROR.PICKER_DATA.DESCRIPTION_200' | transloco"
      >
      </avn-empty-data-message>
    </ng-container>
    <ng-template #noError>
      <amp-site-monitoring-columns-editor
        [selectedColumns]="(data$ | async)?.selectedItems"
        [columns]="(data$ | async)?.allTreeviewColumns"
        [loading]="loading$ | async"
        [defaultToOptimisticView]="dialogConfig.data.defaultToOptimisticView"
      ></amp-site-monitoring-columns-editor>
    </ng-template>
  </avn-dialog-content>
  <avn-dialog-footer>
    <ng-container *ngIf="(hasError$ | async) !== true">
      <avn-button [label]="'siteMonitoringSharedScope.SITE_MONITORING.GLOBAL.BUTTON.CANCEL_15' | transloco" (click)="onCancel()"> </avn-button>
      <avn-button [label]="'siteMonitoringSharedScope.SITE_MONITORING.GLOBAL.BUTTON.SAVE_15' | transloco" [avnTheme]="'primary'" (click)="onSave()"> </avn-button>
    </ng-container>
  </avn-dialog-footer>
</avn-dialog-layout>

<ng-template #headerTemplate>
  <div class="header">
    <h5>{{ 'siteMonitoringSharedScope.SITE_MONITORING.COLUMNS_PICKER.TITLE_50' | transloco }}</h5>
    <avn-badge
      [label]="'siteMonitoringSharedScope.SITE_MONITORING.PROFILES.' + (dialogConfig.data.profile | uppercase) + '_20' | transloco"
      [badgeSize]="'extraSmall'"
      [avnTheme]="isDefaultAppProfile ? 'info' : 'warning'"
      [avnTooltip]="'siteMonitoringSharedScope.SITE_MONITORING.COLUMNS_PICKER.ACTIVE_PROFILE_TOOLTIP_100' | transloco: { profile: dialogConfig.data.profile }"
      [tooltipPlacement]="'bottom'"
    ></avn-badge>
  </div>
</ng-template>
