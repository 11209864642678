import { AlarmEvent } from './alarm-event.enum';
import { AlarmEventNamespace } from './alarm-event-namespace.enum';

/**
 * Describes a device alarm and the associated i18n customers messages and actions required
 * Also indicates how the alarm is matched using the alarm name + short message combination
 *
 * See https://confluence.stratacache.com/pages/viewpage.action?pageId=69650963
 * **/
export interface IAlarmEvent {
  id: AlarmEvent;
  namespace: AlarmEventNamespace;
  name: (string | RegExp)[];
  message: string[];
  i18n: {
    defaultAction?: string;
    dmbMessage?: string;
    dmbAction?: string;
  };
}

export const AlarmEventLatchOpened: IAlarmEvent = {
  id: AlarmEvent.LatchOpened,
  namespace: AlarmEventNamespace.Enclosure,
  name: ['latchopen'],
  message: ['latch open'],
  i18n: {
    dmbMessage: 'alarmEvents.ALARM_EVENTS.DMB_MESSAGES.ENCLOSURE_DOOR_NOT_LATCHED_100',
    dmbAction: 'alarmEvents.ALARM_EVENTS.DMB_ACTIONS.CLOSE_ENCLOSURE_DOOR_100',
  },
}

export const AlarmEventLatchBypass: IAlarmEvent = {
  id: AlarmEvent.LatchBypass,
  namespace: AlarmEventNamespace.Enclosure,
  name: ['latchbypass'],
  message: ['latch open'],
  i18n: {
    dmbMessage: 'alarmEvents.ALARM_EVENTS.DMB_MESSAGES.ENCLOSURE_DOOR_NOT_LATCHED_100',
    dmbAction: 'alarmEvents.ALARM_EVENTS.DMB_ACTIONS.CLOSE_INTERNAL_ENCLOSURE_DOOR_100',
  },
}

export const AlarmEventDoorOpened: IAlarmEvent = {
  id: AlarmEvent.DoorOpened,
  namespace: AlarmEventNamespace.Enclosure,
  name: ['dooropen'],
  message: ['door open'],
  i18n: {
    dmbMessage: 'alarmEvents.ALARM_EVENTS.DMB_MESSAGES.ENCLOSURE_DOOR_OPENED_100',
    dmbAction: 'alarmEvents.ALARM_EVENTS.DMB_ACTIONS.CLOSE_ENCLOSURE_DOOR_100',
  },
}

export const AlarmEventFilterService: IAlarmEvent = {
  id: AlarmEvent.FilterService,
  namespace: AlarmEventNamespace.Enclosure,
  name: ['filterservice'],
  message: ['filter service required'],
  i18n: {
    dmbMessage: 'alarmEvents.ALARM_EVENTS.DMB_MESSAGES.ENCLOSURE_FILTER_SERVICE_REQUIRED_100',
    dmbAction: 'alarmEvents.ALARM_EVENTS.DMB_ACTIONS.CHANGE_FILTER_100',
  },
}

export const AlarmEventThermalTrip: IAlarmEvent = {
  id: AlarmEvent.ThermalTrip,
  namespace: AlarmEventNamespace.Enclosure,
  name: ['thermaltrip'],
  message: ['thermaltrip engaged'],
  i18n: {
    dmbMessage: 'alarmEvents.ALARM_EVENTS.DMB_MESSAGES.ENCLOSURE_THERMAL_TRIP_ENGAGED_100',
    dmbAction: 'alarmEvents.ALARM_EVENTS.DMB_ACTIONS.SECURE_ENCLOSURE_100',
  },
};

export const AlarmEventFanError: IAlarmEvent = {
  id: AlarmEvent.FanError,
  namespace: AlarmEventNamespace.Enclosure,
  name: ['fans.error'],
  message: ['fan error'],
  i18n: {
    dmbMessage: 'alarmEvents.ALARM_EVENTS.DMB_MESSAGES.ENCLOSURE_FAN_ISSUE_100',
    dmbAction: 'alarmEvents.ALARM_EVENTS.DMB_ACTIONS.CONTACT_SUPPORT_100',
  },
};

/**
 * Alarms definitions
 * **/
export const AlarmEvents: IAlarmEvent[] = [
  {
    id: AlarmEvent.AssetsMissing,
    namespace: AlarmEventNamespace.Player,
    name: ['assets.missing'],
    message: ['missing contents'],
    i18n: {
      defaultAction: 'alarmEvents.ALARM_EVENTS.ACTIONS.TRIGGER_CHANNEL_UPDATE_100',
      dmbMessage: 'alarmEvents.ALARM_EVENTS.DMB_MESSAGES.CONTENT_OUT_OF_SYNC_100',
      dmbAction: 'alarmEvents.ALARM_EVENTS.DMB_ACTIONS.PUBLISH_FROM_SCHEDULE_100',
    },
  },
  {
    id: AlarmEvent.PlayerProfileResetFailed,
    namespace: AlarmEventNamespace.Player,
    name: ['player.state.main'],
    message: ['resetting player profile failed'],
    i18n: {
      defaultAction: 'alarmEvents.ALARM_EVENTS.ACTIONS.ESCALATE_100',
      dmbMessage: 'alarmEvents.ALARM_EVENTS.DMB_MESSAGES.PLAYER_ISSUE_DETECTED_100',
      dmbAction: 'alarmEvents.ALARM_EVENTS.DMB_ACTIONS.RESTART_MEDIA_PLAYER_100',
    },
  },
  {
    id: AlarmEvent.ScheduleUpdateException,
    namespace: AlarmEventNamespace.Player,
    name: ['player.state.main'],
    message: ['schedule update exception'],
    i18n: {
      defaultAction: 'alarmEvents.ALARM_EVENTS.ACTIONS.ESCALATE_100',
      dmbMessage: 'alarmEvents.ALARM_EVENTS.DMB_MESSAGES.PLAYER_ISSUE_DETECTED_100',
      dmbAction: 'alarmEvents.ALARM_EVENTS.DMB_ACTIONS.RESTART_MEDIA_PLAYER_100',
    },
  },
  {
    id: AlarmEvent.MainNoPlaylist,
    namespace: AlarmEventNamespace.Player,
    name: ['player.state.main'],
    message: ['no playlist'],
    i18n: {
      defaultAction: 'alarmEvents.ALARM_EVENTS.ACTIONS.TRIGGER_CHANNEL_UPDATE_100',
      dmbMessage: 'alarmEvents.ALARM_EVENTS.DMB_MESSAGES.PLAYER_ISSUE_DETECTED_100',
      dmbAction: 'alarmEvents.ALARM_EVENTS.DMB_ACTIONS.RESTART_MEDIA_PLAYER_100',
    },
  },
  {
    id: AlarmEvent.MainCannotReadPlaylist,
    namespace: AlarmEventNamespace.Player,
    name: ['player.state.main'],
    message: ['cannot read playlist'],
    i18n: {
      defaultAction: 'alarmEvents.ALARM_EVENTS.ACTIONS.TRIGGER_CHANNEL_UPDATE_100',
      dmbMessage: 'alarmEvents.ALARM_EVENTS.DMB_MESSAGES.CONTENT_ISSUE_DETECTED_100',
      dmbAction: 'alarmEvents.ALARM_EVENTS.DMB_ACTIONS.PUBLISH_FROM_SCHEDULE_100',
    },
  },
  {
    id: AlarmEvent.MainPlaylistStartFailed,
    namespace: AlarmEventNamespace.Player,
    name: ['player.state.main'],
    message: ['playlist start failed'],
    i18n: {
      defaultAction: 'alarmEvents.ALARM_EVENTS.ACTIONS.RESTART_SERVICE_100',
      dmbMessage: 'alarmEvents.ALARM_EVENTS.DMB_MESSAGES.PLAYER_ISSUE_DETECTED_100',
      dmbAction: 'alarmEvents.ALARM_EVENTS.DMB_ACTIONS.RESTART_MEDIA_PLAYER_100',
    },
  },
  {
    id: AlarmEvent.PlaylistStartTimeout,
    namespace: AlarmEventNamespace.Player,
    name: [/^player\.state\.(sign|kiosk|data)\.(override|current|default)$/], // player.state.(sign|kiosk|data).(override|current|default)
    message: ['start timeout'],
    i18n: {
      defaultAction: 'alarmEvents.ALARM_EVENTS.ACTIONS.RESTART_SERVICE_100',
      dmbMessage: 'alarmEvents.ALARM_EVENTS.DMB_MESSAGES.PLAYER_ISSUE_DETECTED_100',
      dmbAction: 'alarmEvents.ALARM_EVENTS.DMB_ACTIONS.RESTART_MEDIA_PLAYER_100',
    },
  },
  {
    id: AlarmEvent.PlaylistStartFailed,
    namespace: AlarmEventNamespace.Player,
    name: [/^player\.state\.(sign|kiosk|data)\.(override|current|default)$/],
    message: ['start failed'],
    i18n: {
      defaultAction: 'alarmEvents.ALARM_EVENTS.ACTIONS.RESTART_SERVICE_100',
      dmbMessage: 'alarmEvents.ALARM_EVENTS.DMB_MESSAGES.PLAYER_ISSUE_DETECTED_100',
      dmbAction: 'alarmEvents.ALARM_EVENTS.DMB_ACTIONS.RESTART_MEDIA_PLAYER_100',
    },
  },
  {
    id: AlarmEvent.BlankPlaylist,
    namespace: AlarmEventNamespace.Player,
    name: [/^player\.state\.(sign|kiosk|data)\.(override|current|default)$/],
    message: ['blank playlist'],
    i18n: {
      defaultAction: 'alarmEvents.ALARM_EVENTS.ACTIONS.TRIGGER_CHANNEL_UPDATE_100',
      dmbMessage: 'alarmEvents.ALARM_EVENTS.DMB_MESSAGES.PLAYER_CONFIGURATION_ISSUE_100',
      dmbAction: 'alarmEvents.ALARM_EVENTS.DMB_ACTIONS.CONTACT_SUPPORT_100',
    },
  },
  {
    id: AlarmEvent.PlaylistCheckFailed,
    namespace: AlarmEventNamespace.Player,
    name: [/^player\.state\.(sign|kiosk|data)\.(override|current|default)$/],
    message: ['playlist check failed'],
    i18n: {
      defaultAction: 'alarmEvents.ALARM_EVENTS.ACTIONS.TRIGGER_CHANNEL_UPDATE_100',
      dmbMessage: 'alarmEvents.ALARM_EVENTS.DMB_MESSAGES.CONTENT_ISSUE_DETECTED_100',
      dmbAction: 'alarmEvents.ALARM_EVENTS.DMB_ACTIONS.CONTACT_SUPPORT_100',
    },
  },
  {
    id: AlarmEvent.PlaylistMissingContents,
    namespace: AlarmEventNamespace.Player,
    name: [/^player\.state\.(sign|kiosk|data)\.(override|current|default)$/],
    message: ['missing contents'],
    i18n: {
      defaultAction: 'alarmEvents.ALARM_EVENTS.ACTIONS.TRIGGER_CHANNEL_UPDATE_100',
      dmbMessage: 'alarmEvents.ALARM_EVENTS.DMB_MESSAGES.CONTENT_OUT_OF_SYNC_100',
      dmbAction: 'alarmEvents.ALARM_EVENTS.DMB_ACTIONS.PUBLISH_FROM_SCHEDULE_100',
    },
  },
  {
    id: AlarmEvent.AdapterNotFound,
    namespace: AlarmEventNamespace.Player,
    name: ['adapter.state'],
    message: ['adapter not found'],
    i18n: {
      defaultAction: 'alarmEvents.ALARM_EVENTS.ACTIONS.REBOOT_100',
      dmbMessage: 'alarmEvents.ALARM_EVENTS.DMB_MESSAGES.DISPLAY_NOT_DETECTED_100',
      dmbAction: 'alarmEvents.ALARM_EVENTS.DMB_ACTIONS.CHECK_VIDEO_CABLE_CONNECTION_100',
    },
  },
  {
    id: AlarmEvent.GeometryError,
    namespace: AlarmEventNamespace.Player,
    name: ['adapter.state'],
    message: ['geometry error'],
    i18n: {
      defaultAction: 'alarmEvents.ALARM_EVENTS.ACTIONS.REBOOT_100',
      dmbMessage: 'alarmEvents.ALARM_EVENTS.DMB_MESSAGES.PLAYER_CONFIGURATION_ISSUE_100',
      dmbAction: 'alarmEvents.ALARM_EVENTS.DMB_ACTIONS.CONTACT_SUPPORT_100',
    },
  },
  {
    id: AlarmEvent.DisplayDisconnected,
    namespace: AlarmEventNamespace.Player,
    name: ['adapter.state'],
    message: ['display disconnected'],
    i18n: {
      defaultAction: 'alarmEvents.ALARM_EVENTS.ACTIONS.REQUIRE_TECHNICIAN_INTERVENTION_100',
      dmbMessage: 'alarmEvents.ALARM_EVENTS.DMB_MESSAGES.DISPLAY_NOT_DETECTED_100',
      dmbAction: 'alarmEvents.ALARM_EVENTS.DMB_ACTIONS.CHECK_VIDEO_CABLE_CONNECTION_100',
    },
  },
  {
    id: AlarmEvent.ResolutionNotSet,
    namespace: AlarmEventNamespace.Player,
    name: ['adapter.state'],
    message: ['resolution not set'],
    i18n: {
      dmbMessage: 'alarmEvents.ALARM_EVENTS.DMB_MESSAGES.DISPLAY_ISSUE_DETECTED_100',
      dmbAction: 'alarmEvents.ALARM_EVENTS.DMB_ACTIONS.CONTACT_SUPPORT_100',
    },
  },
  {
    id: AlarmEvent.AdapterError,
    namespace: AlarmEventNamespace.Player,
    name: ['adapter.state'],
    message: ['adapter error'],
    i18n: {
      dmbMessage: 'alarmEvents.ALARM_EVENTS.DMB_MESSAGES.DISPLAY_ISSUE_DETECTED_100',
      dmbAction: 'alarmEvents.ALARM_EVENTS.DMB_ACTIONS.CONTACT_SUPPORT_100',
    },
  },
  {
    id: AlarmEvent.ResolutionError,
    namespace: AlarmEventNamespace.Player,
    name: ['adapter.state'],
    message: ['resolution error'],
    i18n: {
      dmbMessage: 'alarmEvents.ALARM_EVENTS.DMB_MESSAGES.DISPLAY_ISSUE_DETECTED_100',
      dmbAction: 'alarmEvents.ALARM_EVENTS.DMB_ACTIONS.CONTACT_SUPPORT_100',
    },
  },
  {
    id: AlarmEvent.NoPlaylist,
    namespace: AlarmEventNamespace.Player,
    name: ['noplaylist'],
    message: ['no playlist'],
    i18n: {
      defaultAction: 'alarmEvents.ALARM_EVENTS.ACTIONS.FIX_OTHER_ALARMS_100',
      dmbMessage: 'alarmEvents.ALARM_EVENTS.DMB_MESSAGES.PLAYER_ISSUE_DETECTED_100',
      dmbAction: 'alarmEvents.ALARM_EVENTS.DMB_ACTIONS.RESTART_MEDIA_PLAYER_100',
    },
  },
  {
    id: AlarmEvent.AudioNotSet,
    namespace: AlarmEventNamespace.Player,
    name: [/^prop\.(mute|volume)$/], // prop.(mute|volume)
    message: ['not set'],
    i18n: {
      dmbMessage: 'alarmEvents.ALARM_EVENTS.DMB_MESSAGES.PLAYER_AUDIO_CONFIGURATION_ISSUE_100',
    },
  },
  {
    id: AlarmEvent.AudioDisabled,
    namespace: AlarmEventNamespace.Player,
    name: [/^prop\.(mute|volume)$/],
    message: ['audio disabled'],
    i18n: {
      dmbMessage: 'alarmEvents.ALARM_EVENTS.DMB_MESSAGES.PLAYER_AUDIO_CONFIGURATION_ISSUE_100',
    },
  },
  {
    id: AlarmEvent.AudioQueryFailed,
    namespace: AlarmEventNamespace.Player,
    name: [/^prop\.(mute|volume)$/],
    message: ['query failed'],
    i18n: {
      dmbMessage: 'alarmEvents.ALARM_EVENTS.DMB_MESSAGES.PLAYER_AUDIO_CONFIGURATION_ISSUE_100',
    },
  },
  {
    id: AlarmEvent.AudioPropertyNotSupported,
    namespace: AlarmEventNamespace.Player,
    name: [/^prop\.(mute|volume)$/],
    message: ['not supported'],
    i18n: {
      dmbMessage: 'alarmEvents.ALARM_EVENTS.DMB_MESSAGES.PLAYER_AUDIO_CONFIGURATION_ISSUE_100',
    },
  },
  {
    id: AlarmEvent.WrongAudioPropertyValue,
    namespace: AlarmEventNamespace.Player,
    name: [/^prop\.(mute|volume)$/],
    message: ['wrong (manual|override|auto) value'],
    i18n: {
      defaultAction: 'alarmEvents.ALARM_EVENTS.ACTIONS.RESET_AUDIO_VALUE_100',
      dmbMessage: 'alarmEvents.ALARM_EVENTS.DMB_MESSAGES.PLAYER_AUDIO_CONFIGURATION_ISSUE_100',
    },
  },
  {
    id: AlarmEvent.BlankScreenDetected,
    namespace: AlarmEventNamespace.Player,
    name: [/^prop\.(mute|volume)$/],
    message: ['blank screen detected'],
    i18n: {
      dmbMessage: 'alarmEvents.ALARM_EVENTS.DMB_MESSAGES.CONTENT_ISSUE_DETECTED_100',
      dmbAction: 'alarmEvents.ALARM_EVENTS.DMB_ACTIONS.CONTACT_SUPPORT_100',
    },
  },
  {
    id: AlarmEvent.NoPlaybackStatus,
    namespace: AlarmEventNamespace.Player,
    name: [/^playback\.\d+\.channel$/], // playback.<C>.channel
    message: ['no status'],
    i18n: {
      dmbMessage: 'alarmEvents.ALARM_EVENTS.DMB_MESSAGES.CONTENT_ISSUE_DETECTED_100',
      dmbAction: 'alarmEvents.ALARM_EVENTS.DMB_ACTIONS.CONTACT_SUPPORT_100',
    },
  },
  {
    id: AlarmEvent.RegionPlaybackIssue,
    namespace: AlarmEventNamespace.Player,
    name: [/^playback\.\d+\.region\..+$/], // playback.<C>.region.<R>
    message: ['command input timeout', 'failed to launch GPU process', 'GL Problem', 'browser has crashed', 'cannot allocate memory'], // TODO (also SmileBackEnd errors, see confluence)
    i18n: {
      dmbMessage: 'alarmEvents.ALARM_EVENTS.DMB_MESSAGES.CONTENT_ISSUE_DETECTED_100',
      dmbAction: 'alarmEvents.ALARM_EVENTS.DMB_ACTIONS.CONTACT_SUPPORT_100',
    },
  },
  {
    id: AlarmEvent.WrongRegionState,
    namespace: AlarmEventNamespace.Player,
    name: [/^playback\.\d+\.region\..+$/],
    message: ['wrong region state'],
    i18n: {
      dmbMessage: 'alarmEvents.ALARM_EVENTS.DMB_MESSAGES.CONTENT_ISSUE_DETECTED_100',
      dmbAction: 'alarmEvents.ALARM_EVENTS.DMB_ACTIONS.CONTACT_SUPPORT_100',
    },
  },
  {
    id: AlarmEvent.PlayerStopped,
    namespace: AlarmEventNamespace.Player,
    name: [/^playback\.\d+$/], //  playback.<C>
    message: ['player stopped'],
    i18n: {
      dmbMessage: 'alarmEvents.ALARM_EVENTS.DMB_MESSAGES.PLAYER_OVERRIDE_DETECTED_100',
      dmbAction: 'alarmEvents.ALARM_EVENTS.DMB_ACTIONS.RESTART_MEDIA_PLAYER_100',
    },
  },
  {
    id: AlarmEvent.PlayerOverrideDetected,
    namespace: AlarmEventNamespace.Player,
    name: ['state'],
    message: ['manual override'],
    i18n: {
      defaultAction: 'alarmEvents.ALARM_EVENTS.ACTIONS.START_PLAYER_100',
      dmbMessage: 'alarmEvents.ALARM_EVENTS.DMB_MESSAGES.PLAYER_OVERRIDE_DETECTED_100',
      dmbAction: 'alarmEvents.ALARM_EVENTS.DMB_ACTIONS.RESTART_MEDIA_PLAYER_100',
    },
  },
  {
    id: AlarmEvent.ConnectionFailed,
    namespace: AlarmEventNamespace.Display,
    name: [/^prop\.(power|input|mute|volume)$/], // prop.(power|input|mute|volume)
    message: ['connection failed'],
    i18n: {
      dmbMessage: 'alarmEvents.ALARM_EVENTS.DMB_MESSAGES.POSSIBLE_PLAYER_CONFIGURATION_ISSUE_100',
      dmbAction: 'alarmEvents.ALARM_EVENTS.DMB_ACTIONS.CONTACT_SUPPORT_100',
    },
  },
  {
    id: AlarmEvent.PropertyNotSet,
    namespace: AlarmEventNamespace.Display,
    name: ['prop.(power|input|mute|volume)'],
    message: ['not set'],
    i18n: {},
  },
  {
    id: AlarmEvent.AdapterOff,
    namespace: AlarmEventNamespace.Display,
    name: ['prop.(power|input|mute|volume)'],
    message: ['adapter off'],
    i18n: {
      dmbMessage: 'alarmEvents.ALARM_EVENTS.DMB_MESSAGES.POSSIBLE_PLAYER_CONFIGURATION_ISSUE_100',
      dmbAction: 'alarmEvents.ALARM_EVENTS.DMB_ACTIONS.CONTACT_SUPPORT_100',
    },
  },
  {
    id: AlarmEvent.WrongPropertyValue,
    namespace: AlarmEventNamespace.Display,
    name: ['prop.(power|input|mute|volume)'],
    message: ['wrong (manual|override|auto) value'],
    i18n: {
      dmbMessage: 'alarmEvents.ALARM_EVENTS.DMB_MESSAGES.POSSIBLE_PLAYER_ISSUE_DETECTED_100',
      dmbAction: 'alarmEvents.ALARM_EVENTS.DMB_ACTIONS.CONTACT_SUPPORT_100',
      defaultAction: 'alarmEvents.ALARM_EVENTS.DMB_MESSAGES.RESET_PROPERTY_VALUE_100',
    },
  },
  {
    id: AlarmEvent.MaintenanceInProgress,
    namespace: AlarmEventNamespace.Scheduler,
    name: ['interface'],
    message: ['operation in progress'],
    i18n: {},
  },
  {
    id: AlarmEvent.BackupConfigurationInUse,
    namespace: AlarmEventNamespace.Scheduler,
    name: ['interface'],
    message: ['backup configuration in use'],
    i18n: {
      dmbMessage: 'alarmEvents.ALARM_EVENTS.DMB_MESSAGES.PLAYER_CONFIGURATION_ISSUE_100',
      dmbAction: 'alarmEvents.ALARM_EVENTS.DMB_ACTIONS.CONTACT_SUPPORT_100',
    },
  },
  {
    id: AlarmEvent.SchedulerAssetsMissing,
    namespace: AlarmEventNamespace.Cms,
    name: ['assets'],
    message: ['assets missing'],
    i18n: {
      dmbMessage: 'alarmEvents.ALARM_EVENTS.DMB_MESSAGES.CONTENT_OUT_OF_SYNC_100',
      dmbAction: 'alarmEvents.ALARM_EVENTS.DMB_ACTIONS.PUBLISH_FROM_SCHEDULE_100',
    },
  },
  {
    id: AlarmEvent.RebootCycleDetected,
    namespace: AlarmEventNamespace.System,
    name: ['boot'],
    message: ['reboot cycle'],
    i18n: {
      dmbMessage: 'alarmEvents.ALARM_EVENTS.DMB_MESSAGES.PLAYER_STABILITY_ISSUE_DETECTED_100',
      dmbAction: 'alarmEvents.ALARM_EVENTS.DMB_ACTIONS.CONTACT_SUPPORT_100',
    },
  },
  {
    id: AlarmEvent.NtpError,
    namespace: AlarmEventNamespace.Ntp,
    name: ['state'],
    message: ['NTP service is down', 'no NTP server found', 'NTP not synchronized', 'NTP still synchronizing'],
    i18n: {
      dmbMessage: 'alarmEvents.ALARM_EVENTS.DMB_MESSAGES.TIME_SYNC_ISSUE_DETECTED_100',
      dmbAction: 'alarmEvents.ALARM_EVENTS.DMB_ACTIONS.IF_ISSUE_PERSITS_CONTACT_SUPPORT_100',
      defaultAction: 'alarmEvents.ALARM_EVENTS.ACTIONS.FIX_CONFIG_OR_NETWORK_100',
    },
  },
  {
    id: AlarmEvent.RebootNeeded,
    namespace: AlarmEventNamespace.System,
    name: ['run.needreboot'],
    message: ['reboot needed'],
    i18n: {
      dmbMessage: 'alarmEvents.ALARM_EVENTS.DMB_MESSAGES.CANNOT_GET_ENCLOSURE_STATUS_100',
    },
  },
  {
    id: AlarmEvent.ControllerFailed,
    namespace: AlarmEventNamespace.Enclosure,
    name: ['mcstate'],
    message: ['controller error'],
    i18n: {
      dmbMessage: 'alarmEvents.ALARM_EVENTS.DMB_MESSAGES.CANNOT_GET_ENCLOSURE_STATUS_100',
    },
  },
  {
    id: AlarmEvent.KeyDisabled,
    namespace: AlarmEventNamespace.Enclosure,
    name: ['keyen'],
    message: ['key disabled'],
    i18n: {
      dmbMessage: 'alarmEvents.ALARM_EVENTS.DMB_MESSAGES.ENCLOSURE_KEY_DISABLED_100',
    },
  },
  AlarmEventLatchOpened,
  AlarmEventLatchBypass,
  AlarmEventDoorOpened,
  AlarmEventFilterService,
  AlarmEventThermalTrip,
  AlarmEventFanError,
  {
    id: AlarmEvent.TemperatureSensorError,
    namespace: AlarmEventNamespace.Enclosure,
    name: ['tempsensors'],
    message: ['temp sensor error'],
    i18n: {
      dmbMessage: 'alarmEvents.ALARM_EVENTS.DMB_MESSAGES.ENCLOSURE_SENSOR_ISSUE_100',
    },
  },
  {
    id: AlarmEvent.PowerMissing,
    namespace: AlarmEventNamespace.Enclosure,
    name: ['valarm'],
    message: ['48V no power'],
    i18n: {
      dmbMessage: 'alarmEvents.ALARM_EVENTS.DMB_MESSAGES.ENCLOSURE_POWER_ISSUE_100',
    },
  },
  {
    id: AlarmEvent.DeviceError,
    namespace: AlarmEventNamespace.Enclosure,
    name: ['valardevice.errorm'],
    message: ['device error'],
    i18n: {
      dmbMessage: 'alarmEvents.ALARM_EVENTS.DMB_MESSAGES.MEDIA_PLAYER_POWER_ISSUE_100',
    },
  },
  {
    id: AlarmEvent.RebootInProgress,
    namespace: AlarmEventNamespace.Enclosure,
    name: ['reboot'],
    message: ['enclosure reboot'],
    i18n: {},
  },
  {
    id: AlarmEvent.TemperatureTooLow,
    namespace: AlarmEventNamespace.Temperature,
    name: ['system.low', 'enclosure.low', /^display\d+.low$/],
    message: ['abnormal temperature'],
    i18n: {
      dmbMessage: 'alarmEvents.ALARM_EVENTS.DMB_MESSAGES.LOW_TEMPERATURE_DETECTED_100',
    },
  },
  {
    id: AlarmEvent.TemperatureTooHigh,
    namespace: AlarmEventNamespace.Temperature,
    name: ['system.high', 'enclosure.high', /^display\d+.high$/],
    message: ['abnormal temperature'],
    i18n: {
      dmbMessage: 'alarmEvents.ALARM_EVENTS.DMB_MESSAGES.HIGH_TEMPERATURE_DETECTED_100',
    },
  },
  {
    id: AlarmEvent.PosUncaughtException,
    namespace: AlarmEventNamespace.POS,
    name: ['app'],
    message: ['uncaught exception'],
    i18n: {
      dmbMessage: 'alarmEvents.ALARM_EVENTS.DMB_MESSAGES.POS_UNKNOWN_ERROR_OCCURRED_100',
      dmbAction: 'alarmEvents.ALARM_EVENTS.DMB_ACTIONS.CONTACT_SUPPORT_100',
    },
  },
  {
    id: AlarmEvent.PosDatabaseError,
    namespace: AlarmEventNamespace.POS,
    name: ['database'],
    message: ['database error'],
    i18n: {
      dmbMessage: 'alarmEvents.ALARM_EVENTS.DMB_MESSAGES.POS_UPDATE_FAILED_100',
      dmbAction: 'alarmEvents.ALARM_EVENTS.DMB_ACTIONS.CONTACT_SUPPORT_100',
    },
  },
  {
    id: AlarmEvent.PosDataParsingError,
    namespace: AlarmEventNamespace.POS,
    name: ['data'],
    message: ['parsing error', 'data error'],
    i18n: {
      dmbMessage: 'alarmEvents.ALARM_EVENTS.DMB_MESSAGES.POS_UNEXPECTED_DATA_FORMAT_100',
      dmbAction: 'alarmEvents.ALARM_EVENTS.DMB_ACTIONS.CONTACT_SUPPORT_100',
    },
  },
  {
    id: AlarmEvent.PosMisconfiguration,
    namespace: AlarmEventNamespace.POS,
    name: ['conf'],
    message: ['failed pos module', 'failed conf', 'transfer mode mismatch', 'configured for poll', 'received post'],
    i18n: {
      defaultAction: 'alarmEvents.ALARM_EVENTS.ACTIONS.CHECK_CONFIG_TAGS_100',
      dmbMessage: 'alarmEvents.ALARM_EVENTS.DMB_MESSAGES.POS_CONFIGURATION_ERROR_100',
      dmbAction: 'alarmEvents.ALARM_EVENTS.DMB_ACTIONS.CONTACT_SUPPORT_100',
    },
  },
  {
    id: AlarmEvent.PosPermissionDenied,
    namespace: AlarmEventNamespace.POS,
    name: ['client'],
    message: ['permission denied', 'data send failure'],
    i18n: {
      defaultAction: 'alarmEvents.ALARM_EVENTS.ACTIONS.ESCALATE_100',
      dmbMessage: 'alarmEvents.ALARM_EVENTS.DMB_MESSAGES.POS_PERMISSION_ERROR_100',
      dmbAction: 'alarmEvents.ALARM_EVENTS.DMB_ACTIONS.CONTACT_SUPPORT_100',
    },
  },
  {
    id: AlarmEvent.PosDataFetchFailed,
    namespace: AlarmEventNamespace.POS,
    name: ['data'],
    message: ['data fetch failure'],
    i18n: {
      defaultAction: 'alarmEvents.ALARM_EVENTS.ACTIONS.ESCALATE_100',
      dmbMessage: 'alarmEvents.ALARM_EVENTS.DMB_MESSAGES.POS_DATA_FETCH_FAILED_100',
      dmbAction: 'alarmEvents.ALARM_EVENTS.DMB_ACTIONS.CONTACT_SUPPORT_100',
    },
  },
  {
    id: AlarmEvent.PosStaleUpdates,
    namespace: AlarmEventNamespace.POS,
    name: ['client'],
    message: ['stale pos updates'],
    i18n: {
      defaultAction: 'alarmEvents.ALARM_EVENTS.ACTIONS.ESCALATE_100',
      dmbMessage: 'alarmEvents.ALARM_EVENTS.DMB_MESSAGES.POS_STALE_DATA_100',
      dmbAction: 'alarmEvents.ALARM_EVENTS.DMB_ACTIONS.VALIDATE_POS_CONFIG_100',
    },
  },
  {
    id: AlarmEvent.CodError,
    namespace: AlarmEventNamespace.COD,
    name: ['app'],
    message: ['Parsing Error'],
    i18n: {
      dmbMessage: 'alarmEvents.ALARM_EVENTS.DMB_MESSAGES.COD_PARSING_ERROR_100',
      dmbAction: 'alarmEvents.ALARM_EVENTS.DMB_ACTIONS.CONTACT_SUPPORT_100',
    },
  },
];
