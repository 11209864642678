<form [formGroup]="form">
  <avn-form-field [enableValidTheme]="false">
    <avn-checkbox
      formControlName="showTooltip"
      [label]="'siteMonitoringSharedScope.SITE_MONITORING.COLUMNS_PICKER.SHOW_TOOLTIP_30' | transloco"
      [avnTooltip]="'siteMonitoringSharedScope.SITE_MONITORING.COLUMNS_PICKER.SHOW_TOOLTIP_DESCRIPTION_100' | transloco"
      [tooltipPlacement]="'left'"
    ></avn-checkbox>
  </avn-form-field>
</form>
