import { ChangeDetectionStrategy, Component, Input, OnChanges, OnDestroy, SimpleChanges } from '@angular/core';
import { Observable, ReplaySubject, share, Subject } from 'rxjs';
import { SiteDTO, SitesService } from '@activia/cm-api';
import { ApiRequestState, AsyncDataService } from '@activia/ngx-components';

/**
 * This component allows to display the site detail for the site identified by the id passed via input.
 */
@Component({
  selector: 'amp-site-monitoring-detail-container',
  templateUrl: './site-monitoring-detail-container.component.html',
  styleUrls: ['./site-monitoring-detail-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SiteMonitoringDetailContainerComponent implements OnChanges, OnDestroy {
  /** The site for the detail is displayed **/
  @Input() siteId: number;

  /** Observable of the detail site **/
  site$: Observable<SiteDTO>;

  /** Information about the loading state of the api request to fetch the site **/
  siteApiRequestState = new ApiRequestState();

  private _componentDestroyed$: Subject<void> = new Subject<void>();

  constructor(private _asyncDataService: AsyncDataService, private _sitesService: SitesService) {}

  ngOnChanges({ siteId }: SimpleChanges): void {
    if (siteId && siteId.currentValue) {
      this._fetchSite(siteId.currentValue);
    }
  }

  /** @ignore Fetches the site via its id **/
  private _fetchSite(siteId?: number) {
    // reset the api request state
    this.siteApiRequestState = new ApiRequestState();
    const siteRequest$ = this._sitesService.findSitesById(siteId);
    // make the site observable hot make sure by sharing the results of the fetch amongst all subscribers
    this.site$ = this._asyncDataService
      .doRequestWithState$(siteRequest$, this.siteApiRequestState, { message: 'site-monitoring.error.fetch-detail-site-fail' })
      .pipe(
        share({
          connector: () => new ReplaySubject(1),
          resetOnRefCountZero: false,
          resetOnComplete: false,
          resetOnError: false,
        }),

      );
  }

  /** @ignore **/
  ngOnDestroy(): void {
    this._componentDestroyed$.next();
    this._componentDestroyed$.complete();
  }
}
