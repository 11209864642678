import { Injectable } from '@angular/core';
import { createEffect, Actions, ofType } from '@ngrx/effects';

import * as ExperienceTemplateActions from './experience-template.actions';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { of } from 'rxjs';
import { MessengerNotificationService } from '@amp/messenger';
import { ExperienceTemplateService } from '../../services/experience-template.service';

@Injectable()
export class ExperienceTemplateEffects {
  fetchExperienceTemplate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ExperienceTemplateActions.FetchExperienceTemplates),
      switchMap(() => this.experienceTemplateService.getExperienceTemplates()),
      map((experienceTemplates) => ExperienceTemplateActions.FetchExperienceTemplatesSuccess({ experienceTemplates })),
      catchError((err) => of(ExperienceTemplateActions.FetchExperienceTemplatesFail({ errorMessage: err.message })))
    )
  );

  fetchExperienceTemplateFail$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ExperienceTemplateActions.FetchExperienceTemplatesFail),
        tap(({ errorMessage }) => this.notificationService.showErrorMessage(errorMessage))
      ),
    { dispatch: false }
  );

  constructor(private actions$: Actions, private experienceTemplateService: ExperienceTemplateService, private notificationService: MessengerNotificationService) {}
}
