<ng-template #creationTime let-row="row">
  <ng-container *ngTemplateOutlet="timeAgoWithTooltip; context: { row: row, property: 'creationTime' }"> </ng-container>
</ng-template>

<ng-template #lastUpdate let-row="row">
  <ng-container *ngTemplateOutlet="timeAgoWithTooltip; context: { row: row, property: 'modificationTime' }"> </ng-container>
</ng-template>

<ng-template #address let-row="row">
  <span>{{ (prefix ? row[prefix] : row) | applyFunction: formatAddress:[]:this }}</span>
</ng-template>

<ng-template #timeAgoWithTooltip let-row="row" let-property="property">
  <ng-container *ngIf="(prefix ? row[prefix] : row)?.[property] as isoDateString">
    <span tooltipPlacement="left" [avnTooltip]="isoDateString | dateFormat: 'datetime_short_secs'" [tooltipTrigger]="'mouseover'" [tooltipPlacement]="'top'">
      {{ isoDateString | timeAgo | async }}</span
    >
  </ng-container>
</ng-template>
