import { SiteDTO } from '@activia/cm-api';
import { AfterViewInit, ChangeDetectionStrategy, Component, Input, TemplateRef, ViewChild } from '@angular/core';
import { SiteColumn } from '../models/site-column';
import { SiteColumnsService } from '../services/site-columns.service';
import { CountryService } from '@activia/geo';
import { formatSiteAddress } from '../../utils/site.utils';

@Component({
  selector: 'amp-site-templates',
  templateUrl: './site-templates.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SiteTemplatesComponent implements AfterViewInit {
  @Input() prefix = '';

  @ViewChild('creationTime', { static: false }) creationTime: TemplateRef<void>;
  @ViewChild('lastUpdate', { static: false }) lastUpdate: TemplateRef<void>;
  @ViewChild('address', { static: false }) addressTemplate: TemplateRef<void>;

  constructor(private _siteColumnService: SiteColumnsService, private _countryService: CountryService) {}

  ngAfterViewInit(): void {
    const templates: Map<SiteColumn, [TemplateRef<void>, TemplateRef<void>]> = new Map();
    templates.set(SiteColumn.CREATED, [this.creationTime, null]);
    templates.set(SiteColumn.LAST_UPDATED, [this.lastUpdate, null]);
    templates.set(SiteColumn.ADDRESS, [this.addressTemplate, null]);
    this._siteColumnService.registerTemplates(templates);
  }

  formatAddress(site: SiteDTO): string {
    return formatSiteAddress(site, this._countryService);
  }
}
