<ng-container
  *avnAsyncData="monitoringData; state: monitoringDataDataState; emptyTemplate: showCompactView ? null : emptyTemplate; loadingTemplate: loadingTemplate; emptyFn: emptyMonitoringDataFn.bind(this)"
>
  <ng-container *ngIf="!showCompactView; else compactViewTemplate">
    <h4>{{ 'siteMonitoringSharedScope.SITE_MONITORING.KEY_METRICS.SERVICES.TITLE_35' | transloco }}</h4>
    <div data-e2e="service-container" class="services" [style.grid-template-columns]="servicesContainerGridTemplateColumns">
      <div *ngFor="let service of servicesDataSource" class="service">
        <p>{{ service.name | transloco }}</p>
        <ng-container *ngIf="service.statuses.length > 0; else statusCountNotAvailableTemplate">
          <div class="badge" *ngFor="let serviceStatus of service.statuses">
            <avn-badge [badgeSize]="'large'" [label]="serviceStatus.count" [avnTheme]="serviceStatus.theme" [avnTooltip]="serviceStatus.tooltip | transloco"> </avn-badge>
          </div>
        </ng-container>
      </div>
    </div>
  </ng-container>

  <ng-template #compactViewTemplate>
    <amp-key-metrics-accordion
    class="key-metrics-container"
      [keyMetricsAccordionBorderColor]="dataDisplayTypeCode()"
      [keyMetricsAccordionHeader]="'siteMonitoringSharedScope.SITE_MONITORING.KEY_METRICS.SERVICES.TITLE_35' | transloco"
      [keyMetricsAccordionContent]="accordionTemplate"
    ></amp-key-metrics-accordion>

    <ng-template #accordionTemplate>
        <ng-container *ngFor="let service of servicesDataSource">
          <div class="accordion-content">
            <div class="item">
              <span>{{ service.name | transloco }}</span>
            </div>
            <div class="item right">
              <amp-site-field-templates [field]="service.key" [displayType]="compactViewInfo.displayType" [value]="monitoringData[service.key]" [showEmptyPlaceholder]="true"></amp-site-field-templates>
            </div>
          </div>
        </ng-container>
    </ng-template>

  </ng-template>
</ng-container>

<ng-template #emptyTemplate>
  <avn-skeleton
    data-e2e="empty"
    [style.height.px]="_elementRef.nativeElement.parentElement.clientHeight"
    [style.width.px]="_elementRef.nativeElement.parentElement.clientHeight"
    [animate]="false"
    [text]="'siteMonitoringSharedScope.SITE_MONITORING.COLUMNS.KEY_METRICS.SERVICE.NOT_AVAILABLE_125' | transloco"
    [skeletonType]="'CARD'">
  </avn-skeleton>
</ng-template>

<ng-template #loadingTemplate>
  <div class="key-metric-loading-container">
    <h6 class="title">{{ 'siteMonitoringSharedScope.SITE_MONITORING.KEY_METRICS.SERVICES.TITLE_35' | transloco }}</h6>
    <avn-skeleton
      data-e2e="loading"
      [animate]="true"
      [animateDirection]="'VERTICAL'"
      [text]="'siteMonitoringSharedScope.SITE_MONITORING.KEY_METRICS.KEY_METRIC_DATA_LOADING_TEXT_50' | transloco"
      [skeletonType]="'BAR_CHART'"
    >
    </avn-skeleton>
  </div>
</ng-template>

<ng-template #statusCountNotAvailableTemplate>
  <span class="not-available">{{ 'siteMonitoringSharedScope.SITE_MONITORING.GLOBAL.DATA_NOT_AVAILABLE_20' | transloco }}</span>
</ng-template>
