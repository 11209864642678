<div *ngIf="!pickingMode; else pickingModeTpl" class="main-container">
  <div *ngIf="isDisplayEnabled" class="selection" [class.editable]="isDisplayTypeEditable" (click)="isDisplayTypeEditable && showDisplayTypePicker()">
    <span class="lighter">{{ 'COLUMNS.DISPLAY_TITLE_20' | transloco }}</span>
    <ng-container *ngTemplateOutlet="displayTypeTemplate; context: { item: item, displayType: item.selectedDisplayType }"></ng-container>
    <avn-icon class="edit" icon="content:edit" [fontSize]="16" *ngIf="isDisplayTypeEditable"></avn-icon>
  </div>
  <div *ngIf="isAggregationEnabled" class="selection" [class.editable]="isAggregationTypeEditable" (click)="isAggregationTypeEditable && showAggregationTypePicker()">
    <span class="lighter">{{ 'COLUMNS.AGGREGATION_TITLE_20' | transloco }}</span>
    <span>{{ 'COLUMNS.AGGREGATION_TYPE.' + item.selectedAggregationType + '_20' | uppercase | transloco }}</span>
    <avn-icon class="edit" icon="content:edit" [fontSize]="16" *ngIf="isAggregationTypeEditable"></avn-icon>
  </div>
</div>

<ng-template #pickingModeTpl>
  <div *ngIf="pickingMode === 'display'" class="picker-container">
    <div *ngFor="let displayType of item.columnDefinition.displayTypes" class="item editable" [class.selected]="item.selectedDisplayType === displayType" (click)="selectDisplayType(displayType)">
      <span class="title">{{ 'COLUMNS.DISPLAY_TYPE.' + displayType + '_20' | transloco }}</span>
      <ng-container *ngTemplateOutlet="displayTypeTemplate; context: { item: item, displayType: displayType }"></ng-container>
    </div>
  </div>
  <div *ngIf="pickingMode === 'aggregation'" class="picker-container">
    <div
      *ngFor="let aggregationType of item.columnDefinition.aggregationTypes"
      class="item editable"
      [class.selected]="item.selectedAggregationType === aggregationType"
      (click)="selectAggregationType(aggregationType)"
    >
      <span>{{ 'COLUMNS.AGGREGATION_TYPE.' + aggregationType + '_20' | uppercase | transloco }}</span>
    </div>
  </div>
</ng-template>
