/** Represent a player in a device */
export enum PlayerUnit {
  PRIMARY,
  SECONDARY,
  TERTIARY,
  QUATERNARY,
}

/** Represent the input in a display. All displays have a primary input and a backup */
export enum DisplayInputType {
  PRIMARY,
  BACKUP,
}

/** Generic ID used by DnD between player (device) and display */
export const DEFAULT_DRAG_DROP_ROW_ID = 'player';
