import { ChangeDetectionStrategy, Component, EventEmitter, OnInit, Output } from '@angular/core';
import { map, Observable } from 'rxjs';
import { ThemeType } from '@activia/ngx-components';
import { IBoard } from '../../../../models/board-config.interface';
import { Store } from '@ngrx/store';
import { BoardState } from '../../../../store/board/board.reducer';
import * as BoardSelectors from '../../../../store/board/board.selectors';
import { Dictionary } from '@ngrx/entity';
import { DisplayDTO } from '@activia/cm-api';
import * as DisplaySelectors from '../../../../store/display/display.selectors';
import { DisplayState } from '../../../../store/display/display.reducer';
import { TagValueAssignmentScope } from '@amp/tag-operation';
import { mapScreens } from '../../../../utils/display.utils';
import { withLatestFrom } from 'rxjs/operators';
import { IRouterStateUrl, RouterFacade } from '@amp/router-store';

@Component({
  selector: 'amp-screen-selector',
  templateUrl: './screen-selector.component.html',
  styleUrls: ['./screen-selector.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ScreenSelectorComponent implements OnInit {
  @Output() scopeChange: EventEmitter<TagValueAssignmentScope> = new EventEmitter<TagValueAssignmentScope>();

  boards$: Observable<IBoard[]> = this._boardStore.select(BoardSelectors.selectAllBoards);
  displayList$: Observable<Dictionary<DisplayDTO>> = this._displayStore.select(DisplaySelectors.selectDisplayEntities);
  selectedBoard$: Observable<IBoard> = this._boardStore.select(BoardSelectors.selectedCurrentBoard);
  board$: Observable<IBoard> = this._boardStore.select(BoardSelectors.selectedCurrentBoard);

  selectedBoard: IBoard;
  selectedScreenId$: Observable<number>;

  constructor(private _boardStore: Store<BoardState>, private _displayStore: Store<DisplayState>, private _routerFacade: RouterFacade) {}

  ngOnInit() {
    this.selectedScreenId$ = this._routerFacade.currentRoute$.pipe(
      withLatestFrom(this.board$, this.displayList$),
      map(([route, board, displays]: [IRouterStateUrl, IBoard, Dictionary<DisplayDTO>]) => {
        const screenName = route.params.screenName;
        const screen = Object.values(displays).find((display) => display.parentBoardId === board.id && display.name === screenName);
        return screen?.id;
      })
    );
  }

  /**
   * Highlights current board or screen selected among the options available to make them standout
   */
  getTheme(entityId: number, currentEntityId: number): ThemeType {
    return entityId === currentEntityId ? ThemeType.PRIMARY : undefined;
  }

  selectBoard(boardId: number, boards: IBoard[]): void {
    this.selectedBoard = boards.find((board) => board.id === boardId);
    this.scopeChange.emit({ id: boardId } as TagValueAssignmentScope);
  }

  onScreenChange(displayId: number, board: IBoard, displays: Dictionary<DisplayDTO>): void {
    const scope = mapScreens(board, Object.values(displays), displays[displayId].name);
    this.scopeChange.emit(scope);
  }

  hasScreen(board: IBoard): boolean {
    return board?.displays?.length > 0;
  }
}
