/** Definition of a column within the site sync csv file */
export interface ICsvSiteColumnDefinition {
  columnRequired: boolean; // Specifies the column is required in the csv file
  valueRequired: boolean; // Specifies if a value must be provided for the column
  unique: boolean; // Specifies if the provided value must be unique across all sites found in csv
  dtoFieldName: string; // Name of the field in SiteDTO
}

/** Represents a row of data in the site sync csv site */
export interface ICsvSite {
  uid: string; // unique identifier outside of csv data
  id?: number;
  externalId?: string;
  name: string;
  addressLine1: string;
  addressLine2: string;
  city: string;
  state: string;
  zip: string;
  country: string;
  longitude?: number;
  latitude?: number;
}

/** List of possible column names within the csv file */
export type CsvSiteColumnName = Exclude<keyof ICsvSite, 'uid'>;

/** Definitions of csv site columns */
export const SITE_MANAGEMENT_CSV_COLUMNS_DEFINITIONS: Record<CsvSiteColumnName, ICsvSiteColumnDefinition> = {
  id: {
    columnRequired: true,
    valueRequired: false,
    unique: false, // TODO check,
    dtoFieldName: 'id',
  },
  externalId: {
    columnRequired: true,
    valueRequired: false,
    unique: true,
    dtoFieldName: 'externalId',
  },
  name: {
    columnRequired: true,
    valueRequired: true,
    unique: false,
    dtoFieldName: 'name',
  },
  addressLine1: {
    columnRequired: true,
    valueRequired: true,
    unique: false,
    dtoFieldName: 'address.addressLine1',
  },
  addressLine2: {
    columnRequired: true,
    valueRequired: false,
    unique: false,
    dtoFieldName: 'address.addressLine2',
  },
  city: {
    columnRequired: true,
    valueRequired: true,
    unique: false,
    dtoFieldName: 'address.city',
  },
  state: {
    columnRequired: true,
    valueRequired: true,
    unique: false,
    dtoFieldName: 'address.state',
  },
  zip: {
    columnRequired: true,
    valueRequired: true,
    unique: false,
    dtoFieldName: 'address.zip',
  },
  country: {
    columnRequired: true,
    valueRequired: true,
    unique: false,
    dtoFieldName: 'address.country',
  },
  longitude: {
    columnRequired: false,
    valueRequired: false,
    unique: false,
    dtoFieldName: 'geodeticCoordinates.longitude',
  },
  latitude: {
    columnRequired: false,
    valueRequired: false,
    unique: false,
    dtoFieldName: 'geodeticCoordinates.latitude',
  },
};



/**
 * This field can be used to show all columns that would present in the exported csv file.
 * Complete list of columns includes the optional ones that are not required to be provided
 * in the csv for import.
 */
export const ALL_COLUMNS = Object.keys(SITE_MANAGEMENT_CSV_COLUMNS_DEFINITIONS) as CsvSiteColumnName[];
/**
 * List of columns which the values are required.
 */
export const COLUMNS_WITH_REQUIRED_VALUE = ALL_COLUMNS.filter((col) => SITE_MANAGEMENT_CSV_COLUMNS_DEFINITIONS[col].valueRequired);
/**
 * The matching SiteDTO fields which the values of the columns are required.
 */
export const SITEDTO_FIELDS_WITH_REQUIRED_VALUE = COLUMNS_WITH_REQUIRED_VALUE.map((col) => SITE_MANAGEMENT_CSV_COLUMNS_DEFINITIONS[col].dtoFieldName);

/**
 * List of columns that must be provided in the import csv file.
 * Optional ones are not required to be in the import csv file for import to work.
 */
export const COLUMNS_WITH_REQUIRED_HEADER = ALL_COLUMNS.filter((col) => SITE_MANAGEMENT_CSV_COLUMNS_DEFINITIONS[col].columnRequired);

/**
 * List of columns which the values must be unique within the file.
 */
export const COLUMNS_WITH_UNIQUE_VALUE = ALL_COLUMNS.filter((col) => SITE_MANAGEMENT_CSV_COLUMNS_DEFINITIONS[col].unique);
