import { Injectable } from '@angular/core';
import { Subject, Observable, ReplaySubject } from 'rxjs';

/**
 * This service holds and dispatches following actions to avoid using Router in site monitoring shared lib.
 * - toggle board fullscreen
 * - navigate to device detail
 */
@Injectable()
export class SiteMonitoringDetailRoutingService {
  /** sent by detail button, so the parent app knows which device to go */
  private navigateToDeviceDetail: Subject<number> = new Subject<number>();

  navigateToDeviceDetail$: Observable<number> = this.navigateToDeviceDetail.asObservable();

  /** inner toggle state change, this is to notify outter app to update Router accordingly*/
  private toggleStateChanged: Subject<{ queryParam: { key: string; value: string }; state: boolean; extra?: any }> = new ReplaySubject(1);

  toggleStateChanged$: Observable<{ queryParam: { key: string; value: string }; state: boolean; extra?: any }> = this.toggleStateChanged.asObservable();

  /** force toggle state change, this is dispatched by Router change to let toggle in sync*/
  private routerQueryParamChange: Subject<{ [key: string]: string }> = new ReplaySubject(1);

  routerQueryParamChange$: Observable<{ [key: string]: string }> = this.routerQueryParamChange.asObservable();

  /** Container element, which toggle fullscreen is based on */
  container: HTMLElement;

  setDetailContainer(element: HTMLElement) {
    this.container = element;
  }

  navigate(id: number) {
    this.navigateToDeviceDetail.next(id);
  }

  toggleStateChange(state: { queryParam: { key: string; value: string }; state: boolean; extra?: any }) {
    this.toggleStateChanged.next(state);
  }

  routerParamChange(state: { [key: string]: string }) {
    this.routerQueryParamChange.next(state);
  }
}
