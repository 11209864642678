<ng-container *ngIf="scope$ | async as scope">
  <amp-site-selector [isAssignableMode]="editable$ | async" (siteSelectionChanged)="onSiteSelectionChange($event)"></amp-site-selector>
  <amp-engine-tag-value-detail
    [style.height]="scope.ids?.length > 1 ? 'calc(100% - 150px)' : '100%'"
    [warningLabel]="scope.ids?.length > 0 ? ('tagOperation.TAG_OPERATION.TAG_VALUE_DETAIL.FILTER.TAG_ASSIGNMENT_WARNING_50' | transloco: { sites: scope.ids.length }) : undefined"
    [assignmentScope]="scope"
    [editable]="editable$ | async"
    (changeDetails)="changeDetails = $event"
  ></amp-engine-tag-value-detail>
</ng-container>
