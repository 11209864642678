import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { IMapData, IMapElement, IMapMarkerElement, InfoWindowTriggerTypes } from '@activia/geo';
import { catchError, forkJoin, Observable, of, ReplaySubject, share, Subject, withLatestFrom } from 'rxjs';
import { SiteAddressPipe } from '../../pipes/site-address.pipe';
import { SiteMonitoringFacade } from '../../store/site-monitoring.facade';
import { map, mergeMap, startWith, switchMap } from 'rxjs/operators';
import { SiteDTO, SitesService, SiteSummaryDTO } from '@activia/cm-api';
import { SkeletonAnimateDirection, SkeletonType, ThemeType } from '@activia/ngx-components';
import { updateOrgLevelHealthStatus } from '../../utils/site-monitoring-health-status.utils';

@Component({
  selector: 'amp-site-monitoring-site-map',
  templateUrl: './site-monitoring-site-map.component.html',
  styleUrls: ['./site-monitoring-site-map.component.scss'],
})
export class SiteMonitoringSiteMapComponent implements OnInit, OnChanges, OnDestroy {
  /** The ids of the sites to display on the map **/
  @Input() siteIds: number[];

  @Output() markerClicked: EventEmitter<number> = new EventEmitter();

  /** @ignore Used in the template **/
  InfoWindowTriggerTypes = InfoWindowTriggerTypes;
  SkeletonType = SkeletonType;
  SkeletonAnimateDirection = SkeletonAnimateDirection;
  insufficientMessageMapping: { [key: string]: string } = {
    '=1': 'siteMonitoringSharedScope.SITE_MONITORING.SITE_DETAIL.MAP.MAP_INSUFFICIENT_DATA_DESCRIPTION_200.SINGULAR',
    other: 'siteMonitoringSharedScope.SITE_MONITORING.SITE_DETAIL.MAP.MAP_INSUFFICIENT_DATA_DESCRIPTION_200.PLURAL',
  };

  /** contains our map data for the sites **/
  mapData$: Observable<IMapData>;

  /** @ignore Emits when the sites to display change **/
  private _sitesChangedSubject = new ReplaySubject<number[]>();

  private componentDestroy$ = new Subject<void>();

  constructor(private _siteService: SitesService, private _siteMonitoringFacade: SiteMonitoringFacade) {}

  ngOnChanges({ siteIds }: SimpleChanges): void {
    if (siteIds && !siteIds.isFirstChange()) {
      this._sitesChangedSubject.next(siteIds.currentValue || []);
    }
  }

  ngOnInit(): void {
    // init the map data: when the site ids change, we need to rebuild the map data
    this.mapData$ = this._sitesChangedSubject.pipe(
      startWith(this.siteIds || null),
      // switch map (cancel any previous execution when new value is received)
      switchMap((siteIds) => {
        // no sites ids, no data to fetch
        if ((siteIds ?? []).length === 0) {
          return of({ markers: [] } as IMapData);
        }

        // fetch the data of each site
        const fetchAllSites$ = this._siteService.findSiteSummary(siteIds).pipe(map((sites: Array<SiteSummaryDTO>) => sites.map((s) => s.site)));

        // for each site, find the health status and create a marker
        return fetchAllSites$.pipe(
          mergeMap((sites) => forkJoin(sites.map((site) => this._createMarkerFromSite(site)))),
          map(
            (markers) =>
              ({
                markers,
              } as IMapData)
          )
        );
      }),
      // make it hot observable (all subscribers share the same instance of the latest emitted value -> code in pipe is not re-executed on each subscribtion)
      share({
        connector: () => new ReplaySubject(1),
        resetOnRefCountZero: true,
        resetOnComplete: false,
        resetOnError: false,
      }),
    );
  }

  ngOnDestroy() {
    this.componentDestroy$.next();
    this.componentDestroy$.complete();
  }

  mapClicked($event: IMapElement) {
    // Emit only when user clicks on a marker (key is undefined if user clicks on the map)
    if ($event?.key) {
      this.markerClicked.emit($event.key as number);
    }
  }

  copyErrorLog($event: Array<IMapElement>) {
    navigator.clipboard.writeText(JSON.stringify($event));
  }

  /** @ignore creates a map marker from a site **/
  private _createMarkerFromSite(site: SiteDTO): Observable<IMapMarkerElement> {
    return this._siteMonitoringFacade.fetchHealthStatusByOrgLevel(site.id).pipe(
      withLatestFrom(this._siteMonitoringFacade.preference$),
      map(([healthStatus, pref]) => updateOrgLevelHealthStatus(healthStatus, pref.defaultToOptimisticView)),
      map((siteHealth) => this._createMarker(site, siteHealth.theme)),
      catchError(_ => of(this._createMarker(site, ThemeType.INFO))),
    );
  }

  private _createMarker(site: SiteDTO, theme: ThemeType): IMapMarkerElement {
    return {
      key: site.id,
      longitude: site.geodeticCoordinates?.longitude,
      latitude: site.geodeticCoordinates?.latitude,
      infoWindowContent: new SiteAddressPipe().transform(site.address, ', ', '<br />'),
      useCircleMarker: true,
      circleMarkerTheme: theme,
    } as IMapMarkerElement;
  }
}
