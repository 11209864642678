import { createFeatureSelector, createSelector } from '@ngrx/store';

import { ICommandsState } from './commands.reducer';

const commandsSelector = createFeatureSelector<ICommandsState>('commands');

export const getRunningCommands = createSelector(commandsSelector, (state) => state.runningCommands);
export const getCompletedCommands = createSelector(commandsSelector, (state) => state.completedCommands);
export const getThrottledCommands = createSelector(commandsSelector, (state) => state.throttledCommands);

export const commandsQuery = {
  getRunningCommands,
  getCompletedCommands,
  getThrottledCommands
};
