import { Pipe, PipeTransform } from '@angular/core';
import { Address } from '@activia/cm-api';

@Pipe({
  name: 'siteAddress',
  pure: false,
})
export class SiteAddressPipe implements PipeTransform {
  public transform(address: Address, separator = ', ', lineBreaker?: string) {
    if (!address) {
      return '';
    }

    let res = '';
    const append = (value: string, breakLine?: boolean) => (res += !value ? `${breakLine ? lineBreaker : ''}` : `${res.length > 0 ? separator : ''}${value}${breakLine ? lineBreaker : ''}`);
    if (lineBreaker?.length > 0) {
      append(address.addressLine1);
      append(address.addressLine2, true);
      append(address.city);
      append(address.state, true);
      append(address.zip);
      // append(address.country);
      const regex = new RegExp(`${lineBreaker}${separator}`, 'gi');
      res = res.replace(regex, lineBreaker);
    } else {
      append(address.addressLine1);
      append(address.addressLine2);
      append(address.city);
      append(address.state);
      append(address.zip);
      // append(address.country);
    }
    return res;
  }
}
