import { DisplayType } from '@amp/column-picker';
import { IMonitoringListColumn } from '../model/monitoring-list-column.interface';
import { DEVICE_PROPERTY_COLUMNS, MONITORED_VALUE_COLUMNS } from '../model/device-columns';

/** Get the display type of a device property. If not set, find the default display type */
export const getDevicePropertyDisplayType = (listColumn: IMonitoringListColumn) => listColumn.displayType ? listColumn.displayType : DEVICE_PROPERTY_COLUMNS.find((dc) => dc.id === listColumn.value)?.defaultDisplayType || DisplayType.Raw;

/** Get the display type of a monitored value of a device. If not set, find the default display type */
export const getDeviceMonitoredValueDisplayType = (listColumn: IMonitoringListColumn) =>
  listColumn.displayType ? listColumn.displayType : MONITORED_VALUE_COLUMNS.find((mc) => mc.id === listColumn.value)?.defaultDisplayType || DisplayType.Raw;
