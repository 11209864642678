/** Type of commands */
export enum DeviceCommandTypeEnum {
  Volume = 'Volume',
  Mute = 'Mute',
  Display = 'Display',
  Playback = 'Playback',
  Assets = 'Assets',
  UpdateProperties = 'UpdateProperties',
  SystemPower = 'SystemPower',
  DisplayPower = 'DisplayPower',
}
