<avn-accordion [multiExpansionEnabled]="true">
  <avn-accordion-panel
    [avnBackground]="{ cssProp: '--surface-background', shade: 700 }"
    [expand]="keyMetricsAccordionBorderColor === 'danger'"
    [toggleIconPosition]="'none'"
    [headerTemplate]="keyMetricsHeader"
    [disableExpansion]="false"
    (toggled)="showAccordionContent = $event.opened"
    ><div class="key-metrics-container">
      <ng-container *ngTemplateOutlet="keyMetricsAccordionContent"></ng-container></div
  ></avn-accordion-panel>
</avn-accordion>

<ng-template #keyMetricsHeader>
  <div class="key-metrics-header" [ngClass]="keyMetricsAccordionBorderColor">
    <div class="key-metrics-header-title">
      {{ keyMetricsAccordionHeader }}
    </div>
    <div class="key-metrics-header-action">
      <avn-icon icon="navigation:chevron_left" [fontSize]="17" [ngStyle]="{ transform: showAccordionContent ? 'rotate(-90deg)' : 'rotate(0)' }"></avn-icon>
    </div>
  </div>
</ng-template>
