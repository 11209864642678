/**
 * Reference: https://confluence.stratacache.com/display/CM/AMP+Player+Alarms
 */
export enum AlarmEvent {
  AssetsMissing = 'AssetsMissing',
  PlayerProfileResetFailed = 'PlayerProfileResetFailed',
  ScheduleUpdateException = 'ScheduleUpdateException',
  MainNoPlaylist = 'MainNoPlaylist',
  MainCannotReadPlaylist = 'MainCannotReadPlaylist',
  MainPlaylistStartFailed = 'MainPlaylistStartFailed',
  PlaylistStartTimeout = 'PlaylistStartTimeout',
  PlaylistStartFailed = 'PlaylistStartFailed',
  BlankPlaylist = 'BlankPlaylist',
  PlaylistCheckFailed = 'PlaylistCheckFailed',
  PlaylistMissingContents = 'PlaylistMissingContents',
  AdapterNotFound = 'AdapterNotFound',
  GeometryError = 'GeometryError',
  DisplayDisconnected = 'DisplayDisconnected',
  ResolutionNotSet = 'ResolutionNotSet',
  AdapterError = 'AdapterError',
  ResolutionError = 'ResolutionError',
  NoPlaylist = 'NoPlaylist',
  AudioNotSet = 'AudioNotSet',
  AudioDisabled = 'AudioDisabled',
  AudioQueryFailed = 'AudioQueryFailed',
  AudioPropertyNotSupported = 'AudioPropertyNotSupported',
  WrongAudioPropertyValue = 'WrongAudioPropertyValue',
  BlankScreenDetected = 'BlankScreenDetected',
  NoPlaybackStatus = 'NoPlaybackStatus',
  RegionPlaybackIssue = 'CommandInputTimeout',
  WrongRegionState = 'WrongRegionState',
  PlayerStopped = 'PlayerStopped',
  PlayerOverrideDetected = 'PlayerOverrideDetected',
  ConnectionFailed = 'ConnectionFailed',
  PropertyNotSet = 'PropertyNotSet',
  AdapterOff = 'AdapterOff',
  WrongPropertyValue = 'WrongPropertyValue',
  MaintenanceInProgress = 'MaintenanceInProgress',
  BackupConfigurationInUse = 'BackupConfigurationInUse',
  SchedulerAssetsMissing = 'SchedulerAssetsMissing',
  RebootCycleDetected = 'RebootCycleDetected',
  NtpError = 'NtpError',
  RebootNeeded = 'RebootNeeded',
  ControllerFailed = 'ControllerFailed',
  KeyDisabled = 'KeyDisabled',
  LatchOpened = 'LatchOpened',
  LatchBypass = 'LatchBypass',
  DoorOpened = 'DoorOpened',
  FilterService = 'FilterService',
  ThermalTrip = 'ThermalTrip',
  TemperatureSensorError = 'TemperatureSensorError',
  PowerMissing = 'PowerMissing',
  FanError = 'FanError',
  DeviceError = 'DeviceError',
  RebootInProgress = 'RebootInProgress',
  TemperatureTooLow = 'TemperatureTooLow',
  TemperatureTooHigh = 'TemperatureTooHigh',
  PosUncaughtException = 'PosUncaughtException',
  PosDatabaseError = 'PosDatabaseError',
  PosDataParsingError = 'PosDataParsingError',
  PosMisconfiguration = 'PosMisconfiguration',
  PosPermissionDenied = 'PosPermissionDenied',
  PosDataFetchFailed = 'PosDataFetchFailed',
  PosStaleUpdates = 'PosStaleUpdates',
  CodError = 'CodError',
}
