<div class="header">
  <ng-template #tooltipWarningsTemplate>
    <div>
      <div *ngFor="let item of warnings">{{ item | transloco }}</div>
    </div>
  </ng-template>
  <div class="warning-container">
    <avn-icon
      *ngIf="warnings.length > 0"
      [icon]="'alert:warning'"
      [fontSize]="18"
      [avnTheme]="'warning'"
      avnTooltip
      [tooltipTemplate]="tooltipWarningsTemplate"
      [tooltipTheme]="'warning'"
      tooltipPlacement="top"
    >
    </avn-icon>
    <avn-icon
      *ngIf="isLoggerEnabled"
      (click)="downloadLog()"
      [icon]="'content:save_alt'"
      [fontSize]="18"
      tooltipPlacement="top"
      [avnTooltip]="'siteMonitoringSharedScope.SITE_MONITORING.SITE_DETAIL.DISPLAY.DOWNLOAD_DISPLAY_50' | transloco"
    ></avn-icon>
  </div>
  <div class="status-icons">
    <avn-status-icon
      *ngIf="devicesLoaded && isPrimaryInputSetUp"
      data-e2e="primary"
      [class.loading]="!devicesLoaded"
      [class.active]="isPrimaryInputUsed"
      [statusTheme]="primaryInputInfo | applyFunction: getPlayerStatusTheme:[isPrimaryScreenshotUnavailable]"
      [avnTooltip]="
        !isPrimaryInputConfigured
          ? ('siteMonitoringSharedScope.SITE_MONITORING.SITE_DETAIL.DISPLAY.PRIMARY_NOT_CONFIGURED_50' | transloco)
          : !isPrimaryInputUsed
          ? ('siteMonitoringSharedScope.SITE_MONITORING.SITE_DETAIL.SCREENSHOT.FAILED_AFTER_RETRY_50' | transloco)
          : ('siteMonitoringSharedScope.SITE_MONITORING.SITE_DETAIL.DISPLAY.PRIMARY_RUNNING_50' | transloco)
      "
      [tooltipTheme]="isPrimaryInputUsed ? 'primary' : 'danger'"
      [tooltipPlacement]="'top'"
      [tooltipDisabled]="!devicesLoaded || isPrimaryScreenshotUnavailable"
    >
      <avn-icon
        [class.rotate]="isFullScreen"
        [fontSize]="isPrimaryInputUsed ? 20 : 16"
        [icon]="isFullScreen ? 'action:settings_input_hdmi' : 'fa:hdd-o'"
        [avnConnectorLineAnchor]="[
          {
            id: boardId + '-' + display.inputs[0]?.deviceId + '-' + display.inputs[0]?.playerId + '-' + display.id + '-0',
            type: 'end',
            group: '' + display.id,
            lineOptions: { color: !showConnectors ? 'transparent' : isPrimaryInputUsed ? 'rgb(255, 255, 255)' : 'rgba(255, 255, 255, 0.3)', zIndex: 501 }
          }
        ]"
        [avnConnectorLineAnchorParentElement]="elementRef.nativeElement"
      >
      </avn-icon>
    </avn-status-icon>
    <avn-status-icon
      *ngIf="devicesLoaded && isBackupInputSetUp"
      data-e2e="backup"
      [class.loading]="!devicesLoaded"
      [class.active]="isBackupInputUsed"
      [statusTheme]="backupInputInfo | applyFunction: getPlayerStatusTheme:[isBackupScreenshotUnavailable]"
      [avnTooltip]="
        !isBackupInputConfigured
          ? ('siteMonitoringSharedScope.SITE_MONITORING.SITE_DETAIL.DISPLAY.SECONDARY_NOT_CONFIGURED_50' | transloco)
          : !backupInputInfo?.screenshot
          ? ('siteMonitoringSharedScope.SITE_MONITORING.SITE_DETAIL.SCREENSHOT.FAILED_AFTER_RETRY_50' | transloco)
          : isBackupInputUsed
          ? ('siteMonitoringSharedScope.SITE_MONITORING.SITE_DETAIL.DISPLAY.BACKUP_RUNNING_50' | transloco)
          : ''
      "
      [tooltipDisabled]="!devicesLoaded || isBackupScreenshotUnavailable"
      [tooltipTheme]="isBackupInputSetUp && !backupInputInfo?.screenshot ? 'danger' : isBackupInputUsed ? 'primary' : 'warning'"
      [tooltipPlacement]="'top'"
    >
      <avn-icon
        [ngClass]="{ rotate: isFullScreen }"
        [fontSize]="isBackupInputUsed ? 20 : 16"
        [icon]="isFullScreen ? 'action:settings_input_hdmi' : 'fa:hdd-o'"
        [avnConnectorLineAnchor]="[
          {
            id: boardId + '-' + (display?.inputs)[1]?.deviceId + '-' + (display?.inputs)[1]?.playerId + '-' + display.id + '-1',
            type: 'end',
            group: '' + display.id,
            lineOptions: { color: !showConnectors ? 'transparent' : isBackupInputUsed ? 'rgb(255, 255, 255)' : 'rgba(255, 255, 255, 0.3)', zIndex: 501 }
          }
        ]"
        [avnConnectorLineAnchorParentElement]="elementRef.nativeElement"
      >
      </avn-icon>
    </avn-status-icon>
  </div>
</div>
<amp-site-monitoring-screenshot
  [boardId]="boardId"
  [display]="display"
  [screenshotDisplayInfo]="screenshotDisplayInfo"
  [isScreenshotUnavailable]="isPrimaryScreenshotUnavailable && isBackupScreenshotUnavailable"
  (expand)="onScreenshotClicked()"
>
</amp-site-monitoring-screenshot>
