import { Pipe, PipeTransform } from '@angular/core';

/**
 * Takes in a custom function to apply to the value
 * That pipe helps with performance by avoid recalculations when change detection kicks in
 */

@Pipe({ name: 'customFn' })
export class CustomFunctionPipe implements PipeTransform {
  // eslint-disable-next-line @typescript-eslint/no-shadow
  transform<T, R>(value: T, fn: (value: T, ...fnArgs) => R, fnArgs: any[] = [], fnContext: any = this): R {
    return fn.bind(fnContext)(value, ...fnArgs);
  }
}
