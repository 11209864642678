<amp-site-management-player-selector
  [loadingState]="loadingState$ | async"
  [columns]="columns$ | async"
  [allDevices]="allDevices$ | async"
  [availableDevices]="availableDevices$ | async"
  (rowChanged)="onRowChanged($event)"
>
</amp-site-management-player-selector>

<ng-template #dragIndicatorTemplate let-row="row">
  <div class="drag-indicator">
    <avn-icon icon="navigation:more_vert" [fontSize]="21" [class.disabled]="row.datatableRowOptions.disabled" [disabled]="row.datatableRowOptions.disabled"> </avn-icon>
  </div>
</ng-template>

<ng-template #actionTemplate let-row="row">
  <avn-icon
    [icon]="'fa:chain-broken'"
    [fontSize]="18"
    [disabled]="row.datatableRowOptions.usage > 0"
    (click)="unlinkDevice(row)"
    [avnTooltip]="
      row.datatableRowOptions.usage > 0
        ? ('siteManagementScope.SITE_MANAGEMENT.SITE_DETAIL.SITE_PROPERTIES_EDITOR.PLAYERS.UNLINK_PLAYER.PLAYER_ALREADY_USED_100' | transloco : { boardName: row.datatableRowOptions.boardName })
        : ('siteManagementScope.SITE_MANAGEMENT.SITE_DETAIL.SITE_PROPERTIES_EDITOR.PLAYERS.UNLINK_PLAYER.ACTION_UNLINK_10' | transloco)
    "
    [tooltipTrigger]="'mouseover'"
    tooltipPlacement="bottom"
  ></avn-icon>
</ng-template>

<ng-template #infoTemplate let-row="row">
  <avn-icon
    [icon]="'content:info'"
    [fontSize]="18"
    (click)="openDeviceInfoModal(row)"
    [avnTooltip]="'siteManagementScope.SITE_MANAGEMENT.BOARD_EDITOR.DISPLAY_DEVICE_SELECTOR.MEDIA_PLAYER_INFO' | transloco"
    [tooltipTrigger]="'mouseover'"
    tooltipPlacement="bottom"
  ></avn-icon>
</ng-template>

<ng-template #deviceActionIconTemplate let-row="row">
  <avn-icon
    [icon]="'social:monitor_heart'"
    [fontSize]="18"
    [avnTooltip]="'siteManagementScope.SITE_MANAGEMENT.SITE_DETAIL.SITE_PROPERTIES_EDITOR.PLAYERS.VIEW_DEVICE_MONITORING_50' | transloco"
    [tooltipTrigger]="'mouseover'"
    tooltipPlacement="bottom"
    (click)="goToDeviceMonitoring(row)"
  ></avn-icon>
</ng-template>

<amp-player-column-templates></amp-player-column-templates>

<avn-button
  *ngIf="editable$ | async"
  class="add-devices"
  avnTheme="primary"
  [label]="'siteManagementScope.SITE_MANAGEMENT.SITE_DETAIL.SITE_PROPERTIES_EDITOR.PLAYERS.ADD_PLAYERS_30' | transloco"
  (actioned)="addDevices()"
>
</avn-button>
