import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { DeviceFilterApiExpressionService, DeviceProperty, DeviceType, getApiSortExpression, MONITORING_NLP_DEVICE_FIELDS } from '@amp/devices';
import { AsyncDataState, IBadgeSize, IDataTableColumnConfig, IDataTableDataFetchEvent, IDataTableDataSource, LoadingState } from '@activia/ngx-components';
import { BehaviorSubject, combineLatest, Observable, of, Subject } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, filter, map, startWith, switchMap } from 'rxjs/operators';
import { DeviceDTO, DeviceService } from '@activia/cm-api';
import { extractTotalRecordsFromHttpHeaders } from '@amp/utils/common';
import { HttpErrorResponse } from '@angular/common/http';
import { PlayerColumnService } from '../../players/services/player-column.service';
import { STANDARD_PLAYER_COLS } from '../../players/models/player-column';

@Component({
  selector: 'amp-site-management-search-devices',
  templateUrl: './site-management-search-devices.component.html',
  styleUrls: ['./site-management-search-devices.component.scss'],
})
export class SiteManagementSearchDevicesComponent implements OnInit, OnDestroy {
  @Output() selectedDevices: EventEmitter<DeviceDTO[]> = new EventEmitter<DeviceDTO[]>();

  amount = 20;
  DEFAULT_PARAMS: IDataTableDataFetchEvent = { offset: 0, amount: this.amount, sort: undefined };

  DEFAULT_FILTER = 'site.id.defined="false"';

  DEFAULT_DATASOURCE: IDataTableDataSource<DeviceDTO> = { rows: [], totalRowCount: 0 };
  dataSource: IDataTableDataSource<DeviceDTO> = this.DEFAULT_DATASOURCE;
  dataSource$: Observable<IDataTableDataSource<DeviceDTO>>;

  fetchDataSub: Subject<IDataTableDataFetchEvent> = new Subject<IDataTableDataFetchEvent>();
  filterSub: Subject<string> = new Subject<string>();

  /** The recent devices data table columns configuration */
  columns$: Observable<IDataTableColumnConfig<void>[]>;

  loadingStateSub: BehaviorSubject<AsyncDataState> = new BehaviorSubject<AsyncDataState>(LoadingState.INIT);
  loadingState$: Observable<AsyncDataState> = this.loadingStateSub.asObservable();

  LoadingState = LoadingState;
  DeviceProperty = DeviceProperty;

  badgeSize: IBadgeSize = IBadgeSize.EXTRA_SMALL;

  /** @ignore Pattern used to close all subscriptions*/
  private componentDestroyed$: Subject<void> = new Subject<void>();

  get queryParamsChanged$(): Observable<IDataTableDataFetchEvent> {
    return this.fetchDataSub.asObservable().pipe(
      startWith(this.DEFAULT_PARAMS),
      filter((params) => !!params)
    );
  }

  get filterChanged$(): Observable<string> {
    // Force search on init to show all devices that are not attached to any site
    return this.filterSub.asObservable().pipe(startWith(''));
  }

  constructor(
    private deviceFilterApiExpressionService: DeviceFilterApiExpressionService,
    private deviceService: DeviceService,
    private playerColumnService: PlayerColumnService,
  ) {}

  ngOnInit(): void {
    this.setColumns();

    this.dataSource$ = combineLatest([this.filterChanged$, this.queryParamsChanged$]).pipe(
      debounceTime(200),
      distinctUntilChanged((prev, curr) => {
        const [prevFilterExpression, prevParams] = prev;
        const [currFilterExpression, currParams] = curr;
        return prevFilterExpression === currFilterExpression && prevParams.offset === currParams.offset && prevParams.amount === currParams.amount;
      }),
      // TODO: UIC-505 set devices that are already added
      switchMap(([filterExpression, params]) => {
        // get the sort direction (defaults to asc if not set)
        const sortAsc = params.sort ? params.sort.direction === 'asc' : true;
        const filters = this.deviceFilterApiExpressionService.getDeviceFilter(
          filterExpression,
          [DeviceType.DigitalSignageNetworkPlayer],
          MONITORING_NLP_DEVICE_FIELDS,
          true,
          filterExpression.length === 0 ? `${this.DEFAULT_FILTER}` : `& ${this.DEFAULT_FILTER}`
        );

        return this.deviceService.getDevices(params.amount, params.offset, getApiSortExpression(params.sort), sortAsc, filters, null, null, ['*'], null, 'response').pipe(
          map((response) => {
            const deviceList = response.body || [];
            const totalRows = extractTotalRecordsFromHttpHeaders<DeviceDTO>(response);
            return [totalRows, deviceList] as [number, DeviceDTO[]];
          }),
          map(([totalRows, newData]) => {
            // build the new datasource
            const appendData = params.offset > 0;

            const newDataSource: IDataTableDataSource<DeviceDTO> = {
              rows: appendData ? [...this.dataSource.rows, ...newData] : newData,
              totalRowCount: +totalRows,
            };
            this.loadingStateSub.next(LoadingState.LOADED);
            this.dataSource = newDataSource;
            return newDataSource;
          }),
          catchError((err: HttpErrorResponse) => {
            this.loadingStateSub.next({ errorMsg: err.message });
            return of(this.DEFAULT_DATASOURCE);
          })
        );
      }),
      startWith(this.DEFAULT_DATASOURCE)
    );
  }

  /** @ignore */
  ngOnDestroy(): void {
    // unsubscribe all subscriptions
    this.componentDestroyed$.next();
    this.componentDestroyed$.complete();
  }

  setColumns(): void {
    this.columns$ = this.playerColumnService.getFormattedPlayerColumns$(STANDARD_PLAYER_COLS);
  }

  /** When datatable emits a data fetch event **/
  onFetchData(event: IDataTableDataFetchEvent) {
    this.fetchDataSub.next(event);
  }

  searchQueryChanged(filtr: string) {
    this.filterSub.next((filtr ?? '').trim());
  }

  onSelectionChanged(devices: DeviceDTO[]) {
    this.selectedDevices.emit(devices);
  }
}
