import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, concatMap, withLatestFrom, switchMap, mapTo } from 'rxjs/operators';
import { of } from 'rxjs';
import { DeviceDTO, DeviceService, DisplayDTO, DisplaysInBoardService, SitesService } from '@activia/cm-api';
import { Store } from '@ngrx/store';
import { convertToCreateAllDisplaysInBoardDTO, convertToUpdateAllDisplaysInBoardDTO, generateDisplay } from '../../utils/display.utils';
import * as DisplayActions from './display.actions';
import * as DisplaySelectors from './display.selectors';
import * as BoardSelectors from '../board/board.selectors';
import * as DeviceSelectors from '../device/device.selectors';
import { siteManagementSelectors } from '../site-management.selectors';
import { createSiteLocationForDevice } from '../../utils/site.utils';
import { MessengerNotificationService } from '@amp/messenger';
import { CountryService } from '@activia/geo';
import { IBoard } from '../../models/board-config.interface';

@Injectable()
export class DisplayEffects {
  addDisplay$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DisplayActions.AddDisplay),
      withLatestFrom(this._store.select(BoardSelectors.selectBoardEntities), this._store.select(DisplaySelectors.selectDisplayEntities)),
      concatMap(([action, boardEntities, displayEntities]) => {
        const board = boardEntities[action.boardId] as IBoard;
        const displays = board.displays.map((displayId) => displayEntities[displayId] as DisplayDTO);

        const xOffset = action.displayIdx % board.size.column;
        const yOffset = Math.floor(action.displayIdx / board.size.column);
        displays.splice(
          action.displayIdx,
          0,
          generateDisplay(
            action.displayIdx,
            xOffset,
            yOffset,
            displays.map((e) => e.name)
          )
        );

        return this._displayInBoardService.createOrReplaceAllDisplaysInBoard(action.boardId, convertToCreateAllDisplaysInBoardDTO(displays)).pipe(
          map((displaysResp: DisplayDTO[]) =>
            DisplayActions.AddDisplaySuccess({
              boardId: action.boardId,
              displays: displaysResp,
            })
          ),
          catchError((error) => {
            this._messengerNotificationService.showErrorMessage('siteManagementScope.SITE_MANAGEMENT.GLOBAL.ERROR.ADD_DISPLAY_FAILED_150', { boardName: board.name });
            return of(DisplayActions.AddDisplayFail({ error }));
          })
        );
      })
    )
  );

  deleteDisplay$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DisplayActions.DeleteDisplay),
      withLatestFrom(this._store.select(BoardSelectors.selectBoardEntities), this._store.select(DisplaySelectors.selectDisplayEntities), this._store.select(DisplaySelectors.selectDisplayState)),
      concatMap(([action, boardEntities, displayEntities, { temporaryDeletedDisplays }]) => {
        const deletedDisplay = temporaryDeletedDisplays[action.displayId];
        const board = boardEntities[deletedDisplay.parentBoardId];

        // Take all displays except the one we want to delete
        const displays = board.displays
          .filter((displayId) => displayId !== action.displayId)
          .map((displayId, index) => ({ ...displayEntities[displayId], boardScreenIdx: index }))
          .sort((a, b) => a.boardScreenIdx - b.boardScreenIdx);

        return this._displayInBoardService.createOrReplaceAllDisplaysInBoard(deletedDisplay.parentBoardId, convertToCreateAllDisplaysInBoardDTO(displays)).pipe(
          map((displaysResp: DisplayDTO[]) =>
            DisplayActions.DeleteDisplaySuccess({
              displayId: action.displayId,
              boardId: board.id,
              displays: displaysResp,
            })
          ),
          catchError((error) => {
            this._messengerNotificationService.showErrorMessage('siteManagementScope.SITE_MANAGEMENT.GLOBAL.ERROR.UPDATE_DISPLAY_FAILED_150', { boardName: board.name });
            return of(DisplayActions.DeleteDisplayFail({ error, displayId: action.displayId }));
          })
        );
      })
    )
  );

  updateDisplayName$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DisplayActions.UpdateDisplayName),
      withLatestFrom(this._store.select(DisplaySelectors.selectDisplayEntities)),
      concatMap(([action, displayEntities]) => {
        const display = displayEntities[action.displayId] as DisplayDTO;
        const boardId = display.parentBoardId;

        return this._displayInBoardService.updateScreenIndependentProperties(boardId, display.boardScreenIdx, { name: action.displayName.trim() }).pipe(
          map(() =>
            DisplayActions.UpdateDisplayNameSuccess({
              displayId: action.displayId,
              displayName: action.displayName.trim(),
            })
          ),
          catchError((error) => {
            this._messengerNotificationService.showErrorMessage('siteManagementScope.SITE_MANAGEMENT.GLOBAL.ERROR.UPDATE_DISPLAY_NAME_FAILED_100', { displayName: action.displayName.trim() });
            return of(DisplayActions.UpdateDisplayNameFail({ error, displayId: action.displayId }));
          })
        );
      })
    )
  );

  changeDisplayOrientation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DisplayActions.ChangeDisplayOrientation),
      withLatestFrom(this._store.select(DisplaySelectors.selectDisplayEntities)),
      concatMap(([action, displayEntities]) => {
        const display = displayEntities[action.displayId];
        const boardId = display.parentBoardId;

        // Because of optimistic update, changes are already updated in the store so we don't need to edit the display in effect
        // All displays in the board
        const displaysInBoard = Object.values(displayEntities)
          .filter((e) => e.parentBoardId === boardId)
          .sort((a, b) => a.boardScreenIdx - b.boardScreenIdx);

        return this._displayInBoardService.updateAllDisplays(boardId, convertToUpdateAllDisplaysInBoardDTO(displaysInBoard)).pipe(
          map(() =>
            DisplayActions.ChangeDisplayOrientationSuccess({
              displayId: action.displayId,
              orientation: action.orientation,
            })
          ),
          catchError((error) => {
            this._messengerNotificationService.showErrorMessage('siteManagementScope.SITE_MANAGEMENT.GLOBAL.ERROR.CHANGE_DISPLAY_ORIENTATION_FAILED_100', { displayName: display.name });
            return of(DisplayActions.ChangeDisplayOrientationFail({ error, displayId: action.displayId }));
          })
        );
      })
    )
  );

  setDeviceToDisplay$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DisplayActions.SetDeviceToDisplay),
      withLatestFrom(this._store.select(DisplaySelectors.selectDisplayEntities), this._store.select(DeviceSelectors.selectDeviceEntities), this._store.select(siteManagementSelectors.currSiteData)),
      concatMap(([action, displayEntities, devicesEntities, site]) => {
        const device = devicesEntities[action.device.id];
        const display = displayEntities[action.displayId];
        const boardId = display.parentBoardId;

        // Because of optimistic update, changes are already updated in the store so we don't need to edit the display in effect
        // All displays in the board
        const displaysInBoard = Object.values(displayEntities)
          .filter((e) => e.parentBoardId === boardId)
          .sort((a, b) => a.boardScreenIdx - b.boardScreenIdx);

        let attachDeviceToSite$ = of(device);

        if (!device) {
          // Device is not in the site. Update location and attach it to site
          const updatedDevice: DeviceDTO = {
            ...action.device,
            deviceInfo: {
              ...action.device.deviceInfo,
              deviceLocationInfo: createSiteLocationForDevice(this._countryService, site),
            },
          };

          attachDeviceToSite$ = this._deviceService.updateDevice(updatedDevice).pipe(
            switchMap(() => this._siteService.attachDetachDevicesToSite(site.id, { create: [action.device.id] as any })),
            mapTo(updatedDevice)
          );
        }

        return attachDeviceToSite$.pipe(
          switchMap((newDevice) => this._displayInBoardService.updateAllDisplays(boardId, convertToUpdateAllDisplaysInBoardDTO(displaysInBoard)).pipe(mapTo(newDevice))),
          map((newDevice) => DisplayActions.SetDeviceToDisplaySuccess({ displayId: display.id, device: newDevice })),
          catchError((error) => {
            this._messengerNotificationService.showErrorMessage('siteManagementScope.SITE_MANAGEMENT.GLOBAL.ERROR.UPDATE_DEVICE_TO_DISPLAY_FAIL_150', { displayName: display.name });
            return of(DisplayActions.SetDeviceToDisplayFail({ error, displayId: display.id }));
          })
        );
      })
    )
  );

  removeDeviceFromDisplay$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DisplayActions.RemoveDeviceFromDisplay, DisplayActions.RemoveInputFromDisplay),
      withLatestFrom(this._store.select(DisplaySelectors.selectDisplayEntities)),
      concatMap(([action, displayEntities]) => {
        const display = displayEntities[action.displayId];
        const boardId = display.parentBoardId;

        // Because of optimistic update, changes are already updated in the store so we don't need to edit the display in effect
        // All displays in the board
        const displaysInBoard = Object.values(displayEntities)
          .filter((e) => e.parentBoardId === boardId)
          .sort((a, b) => a.boardScreenIdx - b.boardScreenIdx);

        return this._displayInBoardService.updateAllDisplays(boardId, convertToUpdateAllDisplaysInBoardDTO(displaysInBoard)).pipe(
          map(() => DisplayActions.RemoveDeviceFromDisplaySuccess({ displayId: display.id })),
          catchError((error) => {
            this._messengerNotificationService.showErrorMessage('siteManagementScope.SITE_MANAGEMENT.GLOBAL.ERROR.UPDATE_DEVICE_TO_DISPLAY_FAIL_150', { displayName: display.name });
            return of(DisplayActions.RemoveDeviceFromDisplayFail({ error, displayId: display.id }));
          })
        );
      })
    )
  );

  constructor(
    private actions$: Actions,
    private _store: Store,
    private _displayInBoardService: DisplaysInBoardService,
    private _deviceService: DeviceService,
    private _siteService: SitesService,
    private _messengerNotificationService: MessengerNotificationService,
    private _countryService: CountryService
  ) {}
}
