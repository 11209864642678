<avn-dialog-layout>
  <avn-dialog-header
    [showCloseIcon]="true"
    [title]="'siteManagementScope.SITE_MANAGEMENT.EXPERIENCE_TEMPLATE.CREATION_MODAL.TITLE_50' | transloco: { key: experienceTemplate.label }"
    (closeClicked)="onClose()"
  >
  </avn-dialog-header>

  <avn-dialog-content>
    <avn-stepper [selectedIndex]="selectedIndex" [showHeader]="false" [linear]="false" [orientation]="'horizontal'" [avnTheme]="themeType?.PRIMARY">
      <avn-step class="experience-template-step">
        <amp-experience-template-player-selection [siteName]="site.name" [experienceTemplate]="experienceTemplate" (actioned)="getNextStep($event)"></amp-experience-template-player-selection>
      </avn-step>

      <avn-step>
        <amp-experience-template-provision-player [site]="site" [boardTemplates]="experienceTemplate.boards" (addDevices)="addBoardsWithDevices($event)"></amp-experience-template-provision-player>
      </avn-step>
    </avn-stepper>
  </avn-dialog-content>
</avn-dialog-layout>
