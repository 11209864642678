import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Observable, of, Subject } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, filter, map, startWith, takeUntil, tap } from 'rxjs/operators';
import { IRecognitionException } from 'chevrotain';
import { AutocompleteDatasourceFn, IFieldExpression, IOptionData, NlpExpressionInputComponent } from '@activia/ngx-components';
import {
  DateField,
  DateValue,
  EncFanCount,
  EncFantime,
  EncPwm0,
  EncPwm1,
  NumericField,
  NumericValue,
  PercentageField,
  SiteManager,
  StringValue,
  TimeUnit,
} from '../../nlp/device-filter/device-filter.tokens';
import { DeviceFilterNlpDatasourceService } from '../../nlp/device-filter/device-filter-nlp-datasource.service';
import { DeviceFilterNlpParser } from '../../nlp/device-filter/device-filter-nlp-parser';
import { addDeviceNlpTokenGroups, createDeviceNlpCustomTagError, getVisibleTokens } from '../../nlp/device-filter/device-filter.utils';
import { MONITORING_NLP_DEVICE_FIELDS } from '../../nlp/monitoring-nlp-fields';
import { TranslocoService } from '@ngneat/transloco';
import { FeatureToggleService } from '@amp/feature-toggle';
import { DeviceGroupDTO, DeviceGroupService } from '@activia/cm-api';

@Component({
  selector: 'amp-search-devices-form',
  templateUrl: './search-devices-form.component.html',
  styleUrls: ['./search-devices-form.component.scss'],
})
export class SearchDevicesFormComponent implements OnInit, OnChanges, OnDestroy {
  @ViewChild(NlpExpressionInputComponent, { static: true }) nlpInputComponent: NlpExpressionInputComponent;

  @Input() showDeviceGroupInput = true;

  @Input() deviceGroup: DeviceGroupDTO;

  @Input() searchQueryLabel: string;

  @Input() showSearchQueryHint = false;

  @Input() searchQuery: string;

  @Output() deviceGroupIdChanged: EventEmitter<number> = new EventEmitter<number>();

  @Output() searchQueryChanged: EventEmitter<string> = new EventEmitter<string>();

  /** The form containing the user filter details */
  form: UntypedFormGroup;

  /** datasource for the device group autocomplete component */
  deviceGroupDatasource: AutocompleteDatasourceFn;

  /** The NLP parser to create device filters  */
  nlpParser: DeviceFilterNlpParser;

  /** @ignore to use in template **/
  DateField = DateField;
  /** @ignore to use in template **/
  NumericValue = NumericValue;
  /** @ignore to use in template **/
  StringValue = StringValue;
  /** @ignore to use in template **/
  TimeUnit = TimeUnit;
  /** @ignore to use in template **/
  DateValue = DateValue;
  /** @ignore to use in template **/
  PercentageField = PercentageField;
  /** @ignore to use in template **/
  SiteManager = SiteManager;
  /** @ignore to use in template **/
  EncFanCount = EncFanCount;
  /** @ignore to use in template **/
  EncFantime = EncFantime;
  EncPwm0 = EncPwm0;
  EncPwm1 = EncPwm1;
  /** @ignore to use in template **/
  NumericField = NumericField;

  /** @ignore to use in template **/
  deviceNlpCustomTagError: (exceptions: IRecognitionException[], currentFieldExpression: IFieldExpression) => string;

  /** @ignore Pattern used to close all subscriptions */
  private _componentDestroyed$: Subject<void> = new Subject<void>();

  constructor(
    private _formBuilder: UntypedFormBuilder,
    private _deviceGroupService: DeviceGroupService,
    private _translate: TranslocoService,
    private _nlpDatasourceService: DeviceFilterNlpDatasourceService,
    private _featureToggleService: FeatureToggleService
  ) {
    // init device nlp
    this.nlpParser = new DeviceFilterNlpParser(getVisibleTokens(MONITORING_NLP_DEVICE_FIELDS, this._featureToggleService), this._nlpDatasourceService, true, true);
    addDeviceNlpTokenGroups(this.nlpParser, this._translate, this._featureToggleService);
    // create the tag error function
    this.deviceNlpCustomTagError = createDeviceNlpCustomTagError(this.nlpParser, this._translate);

    // create the device group datasource for the autocomplete
    this.deviceGroupDatasource = (name: string): Observable<IOptionData<DeviceGroupDTO>[]> =>
      this._deviceGroupService.searchDeviceGroupsAndType(25, 0, undefined, name, undefined, undefined, 'CMENGINE').pipe(
        map((dto) => dto.map((group) => ({ value: group.id, label: group.name }))),
        catchError(() => of([]))
      );
    this.deviceGroupDatasource.bind(this);
  }

  ngOnInit(): void {
    this._initForm();
  }

  ngOnChanges({ deviceGroup, searchQuery }: SimpleChanges) {
    if (deviceGroup && !deviceGroup.firstChange && this.showDeviceGroupInput) {
      this.form.get('deviceGroup').setValue(this.deviceGroup?.id);
    }
    if (searchQuery && !searchQuery.firstChange) {
      this.form.get('filterExpression').setValue(this.searchQuery);
    }
  }

  /** @ignore */
  ngOnDestroy(): void {
    // unsubscribe all subscriptions
    this._componentDestroyed$.next();
    this._componentDestroyed$.complete();
  }

  private _initForm() {
    let formConfig: any = {
      filterExpression: new UntypedFormControl(this.searchQuery, []),
    };
    if (this.showDeviceGroupInput) {
      formConfig = {
        ...formConfig,
        deviceGroup: new UntypedFormControl(this.deviceGroup?.id, [Validators.required]),
      };
    }
    this.form = this._formBuilder.group(formConfig);

    if (this.showDeviceGroupInput) {
      this.form
        .get('deviceGroup')
        .valueChanges.pipe(
          filter((_) => this.form.get('deviceGroup').valid),
          map((deviceGroupId) => +deviceGroupId),
          distinctUntilChanged((prev, curr) => prev === curr),
          startWith(Number(this.form.get('deviceGroup').value)),
          filter((deviceGroupId) => !!deviceGroupId),
          tap((deviceGroupId) => {
            this.deviceGroupIdChanged.emit(deviceGroupId);
          }),
          takeUntil(this._componentDestroyed$)
        )
        .subscribe();
    }

    this.form
      .get('filterExpression')
      .valueChanges.pipe(
        startWith(this.form.get('filterExpression').value),
        debounceTime(500),
        filter((filterExpression) => this.form.get('filterExpression').valid && !!filterExpression),
        map((filterExpression) => filterExpression.trim()),
        distinctUntilChanged((prev: string, curr: string) => prev.toLowerCase() === curr.toLowerCase()),
        tap((filterExpression) => {
          this.searchQueryChanged.emit(filterExpression);
        }),
        takeUntil(this._componentDestroyed$)
      )
      .subscribe();
  }
}
