import { ChangeDetectionStrategy, Component, forwardRef, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { SiteMonitoredValue } from '../../../../../model/site-monitored-value.enum';
import { getSiteMonitoringColumnDefinition } from '../../../../../utils/site-monitoring-columns.utils';
import { ISiteMonitoringKeyMetricServiceSettings } from '../../../../../model/key-metrics/site-monitoring-key-metric-service-settings.interface';
import { IKeyMetricSettingsComponent, KeyMetricsSettingsBaseMixin } from '../key-metrics-settings.mixin';

@Component({
  selector: 'amp-key-metrics-service-settings',
  templateUrl: './key-metrics-services-settings.component.html',
  styleUrls: ['./key-metrics-services-settings.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  inputs: ['settings'],
  outputs: ['settingsChanged'],
  providers: [
    {
      provide: IKeyMetricSettingsComponent,
      useExisting: forwardRef(() => KeyMetricsServicesSettingsComponent),
    },
  ],
})
export class KeyMetricsServicesSettingsComponent extends KeyMetricsSettingsBaseMixin<ISiteMonitoringKeyMetricServiceSettings>() implements IKeyMetricSettingsComponent, OnInit, OnDestroy {
  /** @ignore to use in template **/
  SiteMonitoredValue = SiteMonitoredValue;

  constructor(private _formBuilder: UntypedFormBuilder) {
    super();
  }

  ngOnInit(): void {
    this.form = this._formBuilder.group({ services: [this.settings?.services || [], Validators.required] });
  }

  /** returns the label for the service monitoring value **/
  getServiceI18nLabel(id: string): string {
    return `siteMonitoringSharedScope.${getSiteMonitoringColumnDefinition(id).name}.TITLE_35`;
  }

  ngOnDestroy(): void {
    // unsubscribe all subscriptions
    this.onDestroy();
  }
}
