import { Pipe, PipeTransform } from '@angular/core';
import { ContentStateSeverity, ContentStatusSeverity, DevicePropertiesStatusSeverity, HealthStatusSeverity, ServiceStatusSeverity } from '@amp/devices';
import { KeyValue } from '@angular/common';
import { SiteMonitoredValue } from '../model/site-monitored-value.enum';

/**
 * Orders the statuses by severity
 * Unfortunately its not possible to sort object keys when they are numbers.
 * So we use KeyValue instead of CountAggregationData (in which the key is a number)
 */
@Pipe({ name: 'statusSeverity' })
export class StatusSeverityPipe implements PipeTransform {
  transform(values: KeyValue<string, number>[], field: SiteMonitoredValue, order: 'high' | 'low' = 'high'): KeyValue<string, number>[] {
    // get the severity order for each status field
    const severityInfo = {
      [SiteMonitoredValue.ContentState]: ContentStateSeverity,
      [SiteMonitoredValue.HealthStatus]: HealthStatusSeverity,
      [SiteMonitoredValue.HttpService]: ServiceStatusSeverity,
      [SiteMonitoredValue.OmnicastStatus]: ServiceStatusSeverity,
      [SiteMonitoredValue.ServicePlayer]: ServiceStatusSeverity,
      [SiteMonitoredValue.ContentStatus]: ContentStatusSeverity,
      [SiteMonitoredValue.DevicePropertiesStatus]: DevicePropertiesStatusSeverity,
      [SiteMonitoredValue.PosState]: ServiceStatusSeverity,
      [SiteMonitoredValue.CodState]: ServiceStatusSeverity,
    };

    if (!Object.keys(severityInfo).includes(field)) {
      return values;
    }

    // sort by severity
    const orderedStatuses = severityInfo[field];
    const res = values.sort((a, b) => {
      const indexOfStatusA = orderedStatuses.indexOf(Number(a.key));
      const indexOfStatusB = orderedStatuses.indexOf(Number(b.key));
      return indexOfStatusA - indexOfStatusB;
    });

    if (order === 'low') {
      res.reverse();
    }
    return res;
  }
}
