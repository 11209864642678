import { DeviceDTO, DisplayDTO } from '@activia/cm-api';
import { createAction, props } from '@ngrx/store';
import { DisplayInputType, PlayerUnit } from '../../models/player-unit.enum';
import { ScreenOrientations } from '../../models/screen-orientation.enum';

export const AddDisplay = createAction('[Display/API] Add Display', props<{ boardId: number; displayIdx: number }>());
export const AddDisplaySuccess = createAction('[Display/API] Add Display Success', props<{ boardId: number; displays: DisplayDTO[] }>());
export const AddDisplayFail = createAction('[Display/API] Add Display Fail', props<{ error: any }>());

export const DeleteDisplay = createAction('[Display/API] Delete Display', props<{ displayId: number }>());
export const DeleteDisplaySuccess = createAction('[Display/API] Delete Display Success', props<{ displayId: number; boardId: number; displays: DisplayDTO[] }>());
export const DeleteDisplayFail = createAction('[Display/API] Delete Display Fail', props<{ error: any; displayId: number }>());

export const UpdateDisplayName = createAction('[Display/API] Update Display name', props<{ displayId: number; displayName: string }>());
export const UpdateDisplayNameSuccess = createAction('[Display/API] Update Display name Success', props<{ displayId: number; displayName: string }>());
export const UpdateDisplayNameFail = createAction('[Display/API] Update Display name Fail', props<{ error: any; displayId: number }>());

export const ChangeDisplayOrientation = createAction('[Display/API] Change Display Orientation', props<{ displayId: number; orientation: ScreenOrientations }>());
export const ChangeDisplayOrientationSuccess = createAction('[Display/API] Change Display Orientation Success', props<{ displayId: number; orientation: ScreenOrientations }>());
export const ChangeDisplayOrientationFail = createAction('[Display/API] Change Display Orientation Fail', props<{ error: any; displayId: number }>());

export const SetDeviceToDisplay = createAction(
  '[Display/API] Set Device To Display',
  props<{ displayId: number; device: DeviceDTO; displayInputType: DisplayInputType; playerId: PlayerUnit; output: number }>()
);
export const SetDeviceToDisplaySuccess = createAction('[Display/API] Set Device To Display Success', props<{ displayId: number; device: DeviceDTO }>());
export const SetDeviceToDisplayFail = createAction('[Display/API] Set Device To Display Fail', props<{ error: any; displayId: number }>());

/** Remove the device from the input (set the display input to null) */
export const RemoveDeviceFromDisplay = createAction('[Display/API] Remove Device To Display', props<{ displayId: number; displayDevice: DisplayInputType }>());
export const RemoveDeviceFromDisplaySuccess = createAction('[Display/API] Remove Device To Display Success', props<{ displayId: number }>());
export const RemoveDeviceFromDisplayFail = createAction('[Display/API] Remove Device To Display Fail', props<{ error: any; displayId: number }>());

/** Remove only the input ( set the display input to empty object {} ) */
export const RemoveInputFromDisplay = createAction('[Display/API] Remove Input To Display', props<{ displayId: number; displayDevice: DisplayInputType }>());
