import { CommonModule } from '@angular/common';
import { APP_INITIALIZER, ModuleWithProviders, NgModule } from '@angular/core';

import { HasFeatureDirective } from './feature-toggle.directive';
import { FeatureToggleService } from './feature-toggle.service';
import { FEATURE_TOGGLE_OPTIONS, IFeatureToggleOptions } from './feature-toggle.token';

export const featureToggleFactory = (featureToggleService: FeatureToggleService): (() => Promise<any>) => () => featureToggleService.load();

@NgModule({
  declarations: [HasFeatureDirective],
  imports: [CommonModule],
  exports: [HasFeatureDirective],
})
export class FeatureToggleModule {
  static forRoot(config?: IFeatureToggleOptions): ModuleWithProviders<FeatureToggleModule> {
    return {
      ngModule: FeatureToggleModule,
      providers: [
        { provide: FEATURE_TOGGLE_OPTIONS, useValue: config },
        { provide: APP_INITIALIZER, useFactory: featureToggleFactory, deps: [FeatureToggleService], multi: true },
      ],
    };
  }
}
