<!-- Header -->
<div class="experience-template-provision-header">
  <div class="experience-template-provision-header-cell">{{ 'siteManagementScope.SITE_MANAGEMENT.EXPERIENCE_TEMPLATE.CREATION_MODAL.HOSTNAME_40' | transloco }}</div>
  <div class="experience-template-provision-header-cell">{{ 'siteManagementScope.SITE_MANAGEMENT.EXPERIENCE_TEMPLATE.CREATION_MODAL.IP_ADRESS_40' | transloco }}</div>
  <div class="experience-template-provision-header-cell">{{ 'siteManagementScope.SITE_MANAGEMENT.EXPERIENCE_TEMPLATE.CREATION_MODAL.MODEL_40' | transloco }}</div>
</div>

<!-- Lane -->
<form class="experience-template-provision-body" [formGroup]="boardsFormGroup" *ngIf="deviceType$ | async as deviceTypes">
  <div class="experience-template-provision-lane" *ngFor="let board of boardsFormGroup.get('boards')['controls']">
    <div class="experience-template-provision-header-row">
      <div class="provision-header-row-text">{{ board.get('label').value }}</div>
      <div class="provision-header-row-triangle"></div>
    </div>

    <ng-container *ngFor="let device of board.get('displays')['controls']">
      <amp-experience-template-provision-player-row class="experience-template-provision-row" [deviceFormGroup]="device" [deviceTypes]="deviceTypes"> </amp-experience-template-provision-player-row>
    </ng-container>
  </div>
</form>

<!-- Footer -->
<div class="experience-template-provision-footer">
  <avn-button [label]="'siteManagementScope.SITE_MANAGEMENT.EXPERIENCE_TEMPLATE.CREATION_MODAL.CREATE_DEVICES_60' | transloco" (actioned)="createDevices()" [avnTheme]="'primary'"></avn-button>
</div>
