<ng-container *transloco="let t; read: 'siteManagementScope.SITE_MANAGEMENT.SITE_DETAIL.SITE_PROPERTIES_EDITOR.INFO.ADDRESS'">
  <ng-container *ngIf="!!address">
    <div class="address">
      <span>{{ address.addressLine1 }}</span>
      <span *ngIf="address.addressLine2">{{ address.addressLine2 }}</span>
      <span>{{ address.city }} {{ address.state }}</span>
      <span>{{ address.zip }}</span>
      <span>{{ address.country | applyFunction: getCountryTranslation:[]:this | async }}</span>
    </div>
    <div *ngIf="showEditIcon" class="actions">
      <avn-icon
        [icon]="'content:create'"
        [fontSize]="16"
        [avnTooltip]="t('EDIT_MANUALLY_MESSAGE_80')"
        [tooltipCloseOnMouseLeave]="true"
        [tooltipPlacement]="'bottom'"
        (click)="displayModeChanged.emit('edit')">
      </avn-icon>
    </div>
  </ng-container>
</ng-container>
