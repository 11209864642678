import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { preloadBoardOrgPathDefinition } from '@amp/site-monitoring-shared';

@Injectable()
export class PreloadBoardOrgPathDefinitionGuard  {
  constructor(private _store: Store) {}

  canActivate(): Observable<boolean> {
    return preloadBoardOrgPathDefinition(this._store).pipe(
      map(() => true)
    );
  }
}
