import { DeviceType } from './device-type.enum';
import { IMonitoringListColumn } from './monitoring-list-column.interface';
import { AsyncDataState } from '@activia/ngx-components';
import { DeviceDTO } from '@activia/cm-api';

/** Contains the device count and current loading state for a monitoring list **/
export interface IMonitoringListDeviceCount {
  listId: string;
  state: AsyncDataState;
  count: number;
  previousCount: number | null;
}

/** enum indicating the monitoring list type */
export enum MonitoringListType {
  SystemList = 'SYSTEM_LIST',
  UserList = 'USER_LIST',
  SharedList = 'SHARED_LIST',
}

/**
 * Represents the data of monitoring lists (user-defined or system).
 * This data is saved in user preferences for user list and in app preferences for system lists.
 * Then it is loaded into app state
 * Since this is persisted in app preferences changes need to be backwards compatible
 */
export interface IMonitoringListDTO {
  /** A unique id for the list **/
  id: string;

  /** Name of the list **/
  name: string;

  /** Description of the list **/
  description?: string;

  /** A color indicating the feel/sentiment for the list (danger, success, warning..) **/
  color: string;

  /** The filter expression associated with the list **/
  filter: string;

  /** The devices types to which the list applies **/
  deviceTypes: DeviceType[];

  /** the columns to display in the list **/
  columns: IMonitoringListColumn[];

  /** the device group associated with the list **/
  deviceGroupId: number | null;

  /** this is a indicator to show whether this list is an application list, or user specific list */
  listType?: MonitoringListType;
}

export const SHARED_TO_ALL = 'ALL';
export type SharedToAll = typeof SHARED_TO_ALL;

/** this represents the shared monitoring list */
export interface IMonitoringSharedListDTO extends IMonitoringListDTO {
  /** admin can toggle this field to show or hide this already shared list */
  active?: boolean;

  /** user shared to, can be array of users or ALL as constant */
  sharedTo?: Array<string> | SharedToAll;

  /** owner username */
  owner?: string;
}

export interface IMonitoringSharedList {
  /** new shared */
  new: Array<string>;

  /** shared list */
  dataset: IMonitoringSharedListDTO[];
}

/**
 * This represent a row info of device for Monitor list data only,
 * not supposed to be expanded for other usage or purpose.
 */
export interface IMonitoringListDatum {
  /** The device associated with the data row */
  device?: DeviceDTO;

  /** The monitoring data associated with the data row */
  monitoringData?: { [name: string]: any };

  /** The tags data associated with the data row */
  tagsData?: { [key: string]: any };
}
