import {ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, OnDestroy} from '@angular/core';
import {BehaviorSubject, combineLatest, Observable, of, Subject, tap, withLatestFrom, zip} from 'rxjs';
import {ReactiveFormsModule, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {IColumnNameOverride} from '../../model/column-name-override.interface';
import {SITE_COLUMNS} from '../../model/site-monitoring-columns';
import {SITE_MONITORING_COLUMN_NAME_OVERRIDES_PREFERENCE_KEY} from '../../model/preference-key.constants';
import {SITE_MONITORING_COLUMNS_CATEGORIES} from '../../model/site-monitoring-columns-categories';
import {SITE_MONITORING_GA_EVENT_ACTION, SITE_MONITORING_GA_EVENT_CATEGORY} from '../../model/site-monitoring-ga-events.constant';
import {SITE_MONITORING_PROFILES, SiteMonitoringProfile} from '../../model/site-monitoring-profile.type';
import {SiteMonitoringFacade} from '../../store/site-monitoring.facade';
import {SiteMonitoringSharedModule} from '../../site-monitoring-shared.module';
import {
  AsyncDataModule, AsyncDataService, ButtonModule, CoreModule, dataOnceReady,
  dataWhenReady, FormFieldModule, InputModule, LayoutModule, LoaderModule, SelectModule, SwitchModule, WidgetModule,
} from '@activia/ngx-components';
import {ApplicationPreferencesService} from '@activia/cm-api';
import {MessengerNotificationService} from '@amp/messenger';
import {map, switchMap, take, takeUntil} from 'rxjs/operators';
import {getApplicationPreferencesByKey, updateApplicationPreferencesByKey} from '@amp/utils/common';
import {DeviceColumnType} from '@amp/devices';
import {CommonModule} from '@angular/common';
import {TranslocoModule} from '@ngneat/transloco';
import {preloadColumnNameOverrides, preloadProfile} from '../../utils/site-monitoring-guards.utils';
import {AuthFacade, AuthModule, CMRole} from '@amp/auth';

@Component({
  standalone: true,
  selector: 'amp-site-global-settings',
  templateUrl: './site-global-settings.component.html',
  styleUrls: ['./site-global-settings.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    CommonModule,
    TranslocoModule,
    CoreModule,
    LayoutModule,
    ReactiveFormsModule,
    FormFieldModule,
    ButtonModule,
    InputModule,
    AsyncDataModule,
    LoaderModule,
    SelectModule,
    WidgetModule,
    SwitchModule,
    AuthModule,
    SiteMonitoringSharedModule,
  ],
  providers: [
    AsyncDataService,
    ApplicationPreferencesService,
    MessengerNotificationService,
  ],
})
export class SiteGlobalSettingsComponent implements OnDestroy {

  /** Grid setting for menu */
  menuCols = { web: 1, tablet: 1, handset: 2 };

  /** Grid setting for content */
  contentCols = { web: 2, tablet: 1, handset: 1 };

  gridColumns  = {
    gigantic: 6,
    yuge: 6,
    huge: 3,
    web: 3,
    tablet: 1,
    handset: 1,
  };

  DeviceColumnType = DeviceColumnType;
  profiles = SITE_MONITORING_PROFILES;
  applicationProfiles: { [key: string]: { form: UntypedFormGroup; selectedSharedDeviceLists: string[] } } = {};

  loading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

  /** Active profile saved in user's preference */
  activeProfile$: BehaviorSubject<SiteMonitoringProfile> = new BehaviorSubject<SiteMonitoringProfile>(null);

  /** Selected active profile */
  activeProfileSelected: SiteMonitoringProfile;

  /** Profile that is currently viewing */
  selectedProfileSubject = new BehaviorSubject<SiteMonitoringProfile>(null);
  selectedProfile$: Observable<SiteMonitoringProfile> = this.selectedProfileSubject.asObservable();

  siteColumnsCategories = SITE_MONITORING_COLUMNS_CATEGORIES;

  isAdmin$: Observable<boolean>;
  isAdminOrNetworkAdmin$: Observable<boolean>;

  /** @ignore Pattern used to close all subscriptions */
  private componentDestroyed$: Subject<void> = new Subject<void>();

  /**
   * Indicates if the form can be saved. Will check only the selected profile because everytime user changes profile,
   * auto-save is done if there is any change. Hence there can't be any pending change in the other un-selected profile.
   */
  get saveEnabled(): boolean {
    return this.applicationProfiles[this.selectedProfileSubject.value].form.valid &&
      this.applicationProfiles[this.selectedProfileSubject.value].form.dirty ||
      this.activeProfileSelected !== this.activeProfile$.value;
  }

  constructor(
    private _asyncDataService: AsyncDataService,
    private _applicationPreferencesService: ApplicationPreferencesService,
    private _siteMonitoringFacade: SiteMonitoringFacade,
    private _formBuilder: UntypedFormBuilder,
    private _cdr: ChangeDetectorRef,
    private _elementRef: ElementRef,
    private _messengerNotificationService: MessengerNotificationService,
    private _authFacade: AuthFacade,
  ) {
    this.isAdmin$ = this._authFacade.hasAnyRole$(CMRole.ROLE_ADMIN);
    this.isAdminOrNetworkAdmin$ = this._authFacade.hasAnyRole$(CMRole.ROLE_ADMIN, CMRole.ROLE_NETWORK_ADMIN);

    preloadProfile(this._siteMonitoringFacade).subscribe();
    preloadColumnNameOverrides(this._siteMonitoringFacade).subscribe();

    const profile$ = dataWhenReady(this._siteMonitoringFacade.profile$, this._siteMonitoringFacade.profileDataState$);
    profile$.pipe(takeUntil(this.componentDestroyed$)).subscribe((profile) => {
      this.activeProfile$.next(profile);
      this.activeProfileSelected = profile;
    });

    // Set initial settings when this page loads
    profile$.pipe(
      take(1),
      tap((profile) => {
        // Set initial selected profile to the active profile
        this.selectedProfileSubject.next(profile);
      }),
      switchMap((activeProfile: SiteMonitoringProfile) => {
        const inactiveProfile = this._findInactiveProfile(activeProfile);
        return zip(
          this._getActiveProfileCustomColumnNames().pipe(
            map((columns) => ({ profile: activeProfile, form: this._initForm(columns) }))
          ),
          this._getSiteColumnNameOverride(inactiveProfile).pipe(
            map((columns) => ({ profile: inactiveProfile, form: this._initForm(columns) }))
          ),
        );
      }),
      withLatestFrom(this.isAdmin$),
      tap(([profilesSettings, isAdmin]) => {
        this.applicationProfiles = profilesSettings.reduce((res, profileSettings) => {
          const frm = profileSettings.form;
          if (isAdmin) {
            frm.enable();
          } else {
            frm.disable();
          }
          return {
            ...res,
            [profileSettings.profile]: {
              form: frm,
            }
          };
        }, {});

        this.loading$.next(false);
      })
    ).subscribe();
  }

  ngOnDestroy(): void {
    this.componentDestroyed$.next();
    this.componentDestroyed$.complete();
  }

  navigateToSection(id: string) {
    const section = this._elementRef.nativeElement.querySelector(`#${id}`);
    section.scrollIntoView();
  }

  onProfileChange(profile: SiteMonitoringProfile): void {
    // Save the current profile if there is any change before switching to another profile
    this.save();
    this.selectedProfileSubject.next(profile);
  }

  onActiveProfileChanged(selected: boolean): void {
    this.activeProfileSelected = selected ? this.selectedProfileSubject.value : this._findInactiveProfile(this.selectedProfileSubject.value);
  }

  cancel(): void {
    this.activeProfileSelected = this.activeProfile$.value;
    const settings$ = this.selectedProfileSubject.value === this.activeProfile$.value ? this._getActiveProfileCustomColumnNames() : this._getSiteColumnNameOverride(this.selectedProfileSubject.value);
    settings$.subscribe((customNames) => {
      this.applicationProfiles[this.selectedProfileSubject.value].form.reset();
      this.applicationProfiles[this.selectedProfileSubject.value].form.patchValue(this._getFormControls(customNames, false));
    });
  }

  save(): void {
    const profileToSave = this.selectedProfileSubject.value;
    const currentForm = this.applicationProfiles[this.selectedProfileSubject.value].form;
    if (this.activeProfile$.value !== this.activeProfileSelected || currentForm.valid && currentForm.dirty) {
      const results$: Observable<any>[] = [];

      if (this.activeProfile$.value !== this.activeProfileSelected) {
        results$.push(this._siteMonitoringFacade.onSiteMonitoringUpdateProfileSuccess$.pipe(take(1)));
        this._siteMonitoringFacade.updateProfile(this.activeProfileSelected);

        this._siteMonitoringFacade.trackGAEvent(
          SITE_MONITORING_GA_EVENT_CATEGORY.PROFILE,
          SITE_MONITORING_GA_EVENT_ACTION.UPDATE,
          `update site monitoring active profile:, ${this.activeProfileSelected}}`
        );
      } else {
        results$.push(of(null));
      }

      if (currentForm.valid && currentForm.dirty) {
        const settings: IColumnNameOverride[] = Object.keys(currentForm.controls).filter((key) => {
          return (currentForm.controls[key].value || '').trim().length > 0;
        }).map((key) => {
          return { columnId: key, name: currentForm.controls[key].value.trim() };
        });
        results$.push(this._updateSettings(this.selectedProfileSubject.value, settings));
      } else {
        results$.push(of(null));
      }

      combineLatest(results$).subscribe(() => {
        this._messengerNotificationService.showInfoMessage('siteMonitoringSharedScope.SITE_MONITORING.SITE_SETTINGS.PROFILE_SAVE_SUCCESS_100', { profile: profileToSave });
        this._resetFormStatus(profileToSave);
      });
    }
  }

  private _updateSettings(profile: SiteMonitoringProfile, settings: IColumnNameOverride[]): Observable<any> {
    let result$;
    if (this.activeProfileSelected === profile) {
      this._siteMonitoringFacade.updateColumnName(settings);
      result$ = this._siteMonitoringFacade.onSiteMonitoringUpdateColumnNameSuccess$.pipe(take(1));
    } else {
      result$ = this._asyncDataService.doRequest$<any>(
        updateApplicationPreferencesByKey(this._applicationPreferencesService, `${profile}.${SITE_MONITORING_COLUMN_NAME_OVERRIDES_PREFERENCE_KEY}`, settings),
        { message: 'siteMonitoringSharedScope.SITE_MONITORING.KEY_METRICS_PICKER.ERROR.NAME_OVERRIDES_UPDATE_FAIL_150' },
        undefined
      );
    }

    this._siteMonitoringFacade.trackGAEvent(
      SITE_MONITORING_GA_EVENT_CATEGORY.APP_SETTINGS,
      SITE_MONITORING_GA_EVENT_ACTION.UPDATE,
      `update global settings of sites for profile: ${profile}, ${JSON.stringify(settings)}`
    );
    return result$;
  }

  private _getFormControls(customNames: IColumnNameOverride[], createFormControl: boolean) {
    const controls = SITE_COLUMNS.reduce((acc, column) => {
      const customName = customNames.find((n) => n.columnId === column.id);
      return {
        ...acc,
        [column.id]: createFormControl ? new UntypedFormControl(customName?.name) : customName?.name,
      };
    }, {});
    return controls;
  }

  private _initForm(customNames: IColumnNameOverride[]): UntypedFormGroup {
    const controls = this._getFormControls(customNames, true);
    const form = this._formBuilder.group(controls);
    return form;
  }

  private _resetFormStatus(profile: SiteMonitoringProfile) {
    // Update the form
    this.applicationProfiles[profile].form.markAsPristine();
    this.applicationProfiles[profile].form.markAsUntouched();
    this._cdr.detectChanges();
  }

  private _getActiveProfileCustomColumnNames(): Observable<IColumnNameOverride[]> {
    return dataOnceReady(this._siteMonitoringFacade.columnNameOverrides$, this._siteMonitoringFacade.columnNameOverridesDataState$, 1);
  }

  private _getSiteColumnNameOverride(profile: SiteMonitoringProfile): Observable<IColumnNameOverride[]> {
    return getApplicationPreferencesByKey<IColumnNameOverride[]>(this._applicationPreferencesService, `${profile}.${SITE_MONITORING_COLUMN_NAME_OVERRIDES_PREFERENCE_KEY}`, []);
  }

  private _findInactiveProfile(activeProfile: SiteMonitoringProfile) {
    return SITE_MONITORING_PROFILES.find((prof) => prof !== activeProfile);
  }
}
