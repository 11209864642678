<avn-loader avnTheme="primary" [isLoading]="loading"></avn-loader>
<amp-column-picker
  [dataSource]="keysMetrics"
  [selectedColumns]="selectedKeyMetrics"
  [i18nLabels]="{
    treeviewEmptyListMessage: 'siteMonitoringSharedScope.SITE_MONITORING.KEY_METRICS_PICKER.NO_RESULT_FOUND_20' | transloco,
    treeviewSearchPlaceholder: 'siteMonitoringSharedScope.SITE_MONITORING.KEY_METRICS_PICKER.SEARCH_20' | transloco,
    listSelectedColumnsTitle: 'siteMonitoringSharedScope.SITE_MONITORING.KEY_METRICS_PICKER.SELECTED_KEY_METRICS_50' | transloco,
    listNoColumnsSelectedMessage: 'siteMonitoringSharedScope.SITE_MONITORING.KEY_METRICS_PICKER.NO_ITEM_SELECTED_50' | transloco
  }"
  [displayTypeTemplate]="displayTypeTemplate"
  [extraColumnConfigTemplate]="extraColumnConfigTemplate"
  (valueChanged)="onItemSelectionChanged($event)"
></amp-column-picker>

<ng-template #displayTypeTemplate let-item="item" let-displayType="displayType">
  <amp-site-field-templates
    [field]="item.columnDefinition.id"
    [value]="item.columnDefinition.sample"
    [displayType]="displayType"
    [optimisticViewEnabled]="item.extra?.optimisticViewEnabled"
  ></amp-site-field-templates>
</ng-template>

<ng-template #extraColumnConfigTemplate let-item="item">
  <ng-container [ngSwitch]="item.columnDefinition.id">
    <ng-container *ngSwitchCase="SiteMonitoringKeyMetric.HealthStatusSummary">
      <ng-container *ngTemplateOutlet="optimisticSettingsTemplate; context: { item: item }"></ng-container>
      <ng-container *ngTemplateOutlet="hideOkSettingsTemplate; context: { item: item }"></ng-container>
    </ng-container>
    <ng-container *ngSwitchCase="SiteMonitoringKeyMetric.ContentStatus">
      <ng-container *ngTemplateOutlet="optimisticSettingsTemplate; context: { item: item }"></ng-container>
      <ng-container *ngTemplateOutlet="hideOkSettingsTemplate; context: { item: item }"></ng-container>
    </ng-container>
    <ng-container *ngSwitchCase="SiteMonitoringKeyMetric.Services">
      <ng-container *ngTemplateOutlet="servicesSettingsTemplate; context: { item: item }"></ng-container>
      <ng-container *ngTemplateOutlet="hideOkSettingsTemplate; context: { item: item }"></ng-container>
    </ng-container>
    <ng-container *ngSwitchCase="SiteMonitoringKeyMetric.CodState">
      <ng-container *ngTemplateOutlet="hideOkSettingsTemplate; context: { item: item }"></ng-container>
    </ng-container>
    <ng-container *ngSwitchCase="SiteMonitoringKeyMetric.PosState">
      <ng-container *ngTemplateOutlet="hideOkSettingsTemplate; context: { item: item }"></ng-container>
    </ng-container>
    <ng-container *ngSwitchCase="SiteMonitoringKeyMetric.EnclosureStatus">
      <ng-container *ngTemplateOutlet="alarmsSettingsTemplate; context: { item: item }"></ng-container>
      <ng-container *ngTemplateOutlet="optimisticSettingsTemplate; context: { item: item }"></ng-container>
      <ng-container *ngTemplateOutlet="hideOkSettingsTemplate; context: { item: item }"></ng-container>
      <ng-container *ngTemplateOutlet="showTooltipTemplate; context: { item: item }"></ng-container>
    </ng-container>
  </ng-container>
</ng-template>

<ng-template #optimisticSettingsTemplate let-item="item">
  <amp-key-metrics-optimistic-settings
    [settings]="item.extra"
    [field]="item | customFn: getOptimiticViewField"
    (settingsChanged)="onSettingsChanged($event, item)"
  ></amp-key-metrics-optimistic-settings>
</ng-template>

<ng-template #hideOkSettingsTemplate let-item="item">
  <amp-key-metrics-hide-ok-settings
    [settings]="item.extra"
    (settingsChanged)="onSettingsChanged($event, item)"
  ></amp-key-metrics-hide-ok-settings>
</ng-template>

<ng-template #servicesSettingsTemplate let-item="item">
  <amp-key-metrics-service-settings [settings]="item.extra" (settingsChanged)="onSettingsChanged($event, item)"></amp-key-metrics-service-settings>
</ng-template>

<ng-template #showTooltipTemplate let-item="item">
  <amp-key-metrics-show-tooltip-settings [settings]="item.extra" (settingsChanged)="onSettingsChanged($event, item)"></amp-key-metrics-show-tooltip-settings>
</ng-template>

<ng-template #alarmsSettingsTemplate let-item="item">
  <amp-key-metrics-enclosure-status-settings [settings]="item.extra" (settingsChanged)="onSettingsChanged($event, item)"></amp-key-metrics-enclosure-status-settings>
</ng-template>
