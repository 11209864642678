import { createAction, props } from '@ngrx/store';
import { ISiteSyncState, SiteSyncGlobalStatus } from './site-sync.model';
import { IRequestChainStateChangeEvent, IRequestChainState } from './request-chain';
import { IGeoResult } from '@activia/geo';
import { DeviceTypeDTO, SiteDTO } from '@activia/cm-api';
import { IExperienceTemplateInfo } from '../../models/experience-template.interface';

export const SiteSyncResetState = createAction('[Site Sync] Reset State');

export const SiteSyncValidateCsvFile = createAction('[Site Sync] Validate Csv file', props<{ file: File }>());
export const SiteSyncValidateCsvFileSuccess = createAction('[Site Sync] Validate Csv file Success', props<{ sitesSyncState: Record<string, ISiteSyncState> }>());
export const SiteSyncValidateCsvFileError = createAction('[Site Sync] Validate Csv file Error', props<{ status: SiteSyncGlobalStatus; messages?: string[] }>());

export const SiteSyncProcessCsvFile = createAction('[Site Sync] Process Csv file', props<{ experiencesTemplate: IExperienceTemplateInfo; deviceTypes: DeviceTypeDTO[] }>());

export const SiteSyncUpdateSiteRequestChainState = createAction('[Site Sync] Update Site Request Chain State', props<{ siteUid: string; requestChainState: IRequestChainState }>());

export const SiteSyncUpdateSiteProgress = createAction('[Site Sync] Update Site Progress', props<{ siteUid: string; requestChainStateChangeEvent: IRequestChainStateChangeEvent }>());
export const SiteSyncUpdateSiteInfo = createAction('[Site Sync] Update Site Info', props<{ siteUid: string; site: SiteDTO }>());

export const SiteSyncResumeCsvSiteProcessing = createAction('[Site Sync] Resume Csv Site Processing', props<{ siteUid: string; experiencesTemplate?: IExperienceTemplateInfo }>());

export const SiteSyncFixMissingRequiredValue = createAction('[Site Sync] Fix Missing Required Value', props<{ siteUid: string; site: SiteDTO }>());

export const SiteSyncFixGeodeticCoordinates = createAction('[Site Sync] Fix Geo Coordinates', props<{ siteUid: string; geodeticCoordinates: IGeoResult['location']; keepCsvValue: boolean }>());

export const SiteSyncFixSiteAddress = createAction('[Site Sync] Fix Site Address', props<{ siteUid: string; addressGeoResult: IGeoResult; keepCsvValue: boolean }>());
