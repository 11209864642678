import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { GlobalEffects } from './store/global.effects';
import { globalReducer, initialGlobalState } from './store/global.reducer';
import { NetworkConnectivityService } from './network-connectivity/network-connectivity.service';

@NgModule({
  imports: [CommonModule, StoreModule.forFeature('global', globalReducer, { initialState: initialGlobalState }), EffectsModule.forFeature([GlobalEffects])],
})
export class GlobalModule {
  constructor(networkConnectivityService: NetworkConnectivityService) {
    networkConnectivityService.init();
  }
}
