import { ITreeNodeItem } from '@activia/ngx-components';
import { ColumnPickerComponent, IColumnPickerItem } from '@amp/column-picker';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { ISiteMonitoringKeyMetricConfig } from '../../../model/site-monitoring-key-metric-config.interface';
import { SiteMonitoringKeyMetric } from '../../../model/site-monitoring-key-metric.enum';
import { SiteMonitoredValue } from '../../../model/site-monitored-value.enum';
import { IKeyMetricSettingsComponent } from './settings/key-metrics-settings.mixin';
import { SITE_MONITORING_KEY_METRICS } from '../../../model/site-monitoring-key-metrics';
import { KeyMetricsSettings } from './settings/key-metrics-settings.type';

@Component({
  selector: 'amp-site-monitoring-key-metrics-editor',
  templateUrl: './site-monitoring-key-metrics-editor.component.html',
  styleUrls: ['./site-monitoring-key-metrics-editor.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SiteMonitoringKeyMetricsEditorComponent {
  @ViewChild(ColumnPickerComponent, { static: true }) treeColumnPicker: ColumnPickerComponent;

  /** All the key metric settings components currently available **/
  @ViewChildren(IKeyMetricSettingsComponent) settingsComponentsQL: QueryList<IKeyMetricSettingsComponent>;

  /** Indicates if the component is in loading state **/
  @Input() loading = false;

  /** The list of key metrics to display in the treeview (treeview format) **/
  @Input() keysMetrics: ITreeNodeItem<IColumnPickerItem<ISiteMonitoringKeyMetricConfig>>[] = []; // tree node items

  /** the selected column names / ids*/
  @Input() selectedKeyMetrics: IColumnPickerItem[] = [];

  /** @ignore for use in template */
  SiteMonitoringKeyMetric = SiteMonitoringKeyMetric;

  /** items for the service selection */
  SiteMonitoredValue = SiteMonitoredValue;

  /** Emits when key metrics settings have changed **/
  @Output() keyMetricSettingsChanged = new EventEmitter<boolean>();

  /** Indicates if the component is in valid state */
  isValid(): boolean {
    const hasSelectedKeyMetrics = this.treeColumnPicker.getSelectedColumns()?.length > 0;
    if (!hasSelectedKeyMetrics) {
      return false;
    }
    // all settings components must be valid
    return (this.settingsComponentsQL?.toArray() || []).every((settingsComponent) => settingsComponent.isValid());
  }

  /** Called when the settings of a selected key metric are changed **/
  onSettingsChanged(settings: KeyMetricsSettings, item: IColumnPickerItem<KeyMetricsSettings>) {
    // update the column extra config and refresh data binding
    const keyMetric = this.selectedKeyMetrics.find((col) => col.columnDefinition.id === item.columnDefinition.id);
    keyMetric.extra = { ...keyMetric.extra, ...settings };
    this.selectedKeyMetrics = [...this.selectedKeyMetrics];
    this.keyMetricSettingsChanged.emit(true);
  }

  /** Called when an key metric is selected / deselected **/
  onItemSelectionChanged(selectedItems: IColumnPickerItem<KeyMetricsSettings>[]) {
    this.selectedKeyMetrics = selectedItems;
  }

  /** get the site monitoring field related to this key metric. */
  getOptimiticViewField(item: IColumnPickerItem<ISiteMonitoringKeyMetricConfig>): SiteMonitoredValue | SiteMonitoringKeyMetric {
    return SITE_MONITORING_KEY_METRICS.find((km) => km.id === item.columnDefinition.id).extra?.monitoringValues?.[0] ||
      item.columnDefinition.id as SiteMonitoringKeyMetric;
  }
}
