import { MonitoringAlarmEventDTO } from '@activia/cm-api';
import { AlarmEvents, IAlarmEvent } from '../model/alarm-events';
import { DeviceMonitoringEventDTO } from '@activia/device-screenshot-api';
import { AlarmEventNamespace, AlarmEventNamespaceRegex } from '../model/alarm-event-namespace.enum';
import { AlarmEventLevel, AlarmLevelsInfo, IAlarmEventLevelInfo } from '../model/alarm-event-level.enum';

/** Finds a stateful alarm by name and message **/
export const findAlarmEvent = (alarm: MonitoringAlarmEventDTO): IAlarmEvent => AlarmEvents.find(
    (deviceAlarm) => matches(alarm.namespace, [AlarmEventNamespaceRegex[deviceAlarm.namespace]]) && matches(alarm.name, deviceAlarm.name) && matches(alarm.shortMessage, deviceAlarm.message)
  );

const matches = (valueToMatch: string, values: (string | RegExp)[]): boolean => values.some((value) => {
    if (typeof value === 'string') {
      return value === valueToMatch;
    }
    return !!valueToMatch?.match(value);
  });

/**
 * Return all the levels of the alarms up to the minLevel.
 *
 * @param minLevel Minimum level of the alarms E.g. If minLevel is "Error", all Error+ levels will be returned.
 */
export const getAlarmEventLevels = (minLevel: AlarmEventLevel): Array<number> => Object.keys(AlarmEventLevel)
    .filter((key) => AlarmEventLevel[key] <= minLevel)
    .map((key) => AlarmEventLevel[key]);

/** Converts the alarm data returned by the screenshot CGI into the same DTO returned by the alarms endpoint **/
export const convertPlayerAlarm = (event: DeviceMonitoringEventDTO, deviceId: number, playerId?: number): MonitoringAlarmEventDTO => ({
    deviceId,
    namespace: event.ns,
    name: event.name,
    shortMessage: event.msg,
    longMessage: event.longmsg,
    level: event.level,
    firstRecordedTime: event.firsttime,
    lastRecordedTime: event.endtime,
    jsonData: {
      ...(playerId === undefined ? {} : { player: playerId }),
      ...event.data,
    },
  });

/** Removes non stateful alarms from the list of alarms **/
export const filterNonStatefulAlarms = (alarms: MonitoringAlarmEventDTO[]): MonitoringAlarmEventDTO[] => alarms?.filter((alarm) => !!findAlarmEvent(alarm)) || [];

/** Extracts the player id from the alarm
 * ex: player.1 => 1
 * */
export const extractPlayerIdFromAlarm = ({ namespace, jsonData }: MonitoringAlarmEventDTO): number => {
  if (namespace.match(AlarmEventNamespaceRegex[AlarmEventNamespace.Player])) {
    return Number(namespace.substring(namespace.indexOf('.') + 1));
  }

  return (jsonData && jsonData['player']) || null;
};

/** Extracts the display id from the alarm
 * ex: display.1 => 1
 * */
export const extractDisplayIdFromAlarm = ({ namespace }: MonitoringAlarmEventDTO): number => {
  if (namespace.match(AlarmEventNamespaceRegex[AlarmEventNamespace.Display])) {
    return Number(namespace.substring(namespace.indexOf('.') + 1));
  }
  return null;
};

/** Returns info about the highest severity level of all provided alarms */
export const getHighestSeverityLevelInfo = (alarms: MonitoringAlarmEventDTO[]): IAlarmEventLevelInfo => {
  // get the highest severity
  if (alarms?.length > 0) {
    const highestLevel = Math.min(...alarms.map((alarm) => alarm.level as unknown as number));
    return AlarmLevelsInfo[highestLevel];
  } else {
    return AlarmLevelsInfo[AlarmEventLevel.Info];
  }
};
