import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { MonitoringAlarmEventDTO } from '@activia/cm-api';
import { IAlarmEventDisplayConfig } from '../../model/alarm-event-display-config.interface';
import { AlarmLevelsInfo } from '../../model/alarm-event-level.enum';
import { HealthErrorIds } from '../../model/health-error-ids.enum';
import { IAlarmEventTextInfo, toAlarmEventTextInfo } from '../../utils/alarm-text.utils';

/**
 * Shows alerts (errors/warnings) for a device or a player
 * The unreachable error for player is only displayed in the compact mode when it is the only error.
 */
@Component({
  selector: 'amp-device-alarm',
  templateUrl: './device-alarm.component.html',
  styleUrls: ['./device-alarm.component.scss'],
})
export class DeviceAlarmComponent implements OnChanges {
  /** Indicates if the alerts are for a device or a player **/
  @Input() alarm: MonitoringAlarmEventDTO;

  /** Indicates if the alerts are for a device or a player **/
  @Input() displayConfig: IAlarmEventDisplayConfig;

  /** Indicates if the alerts are for a device or a player **/
  @Input() showNamespace = true;

  alarmText: IAlarmEventTextInfo;

  /** @ignore to use in template **/
  HealthErrorIds = HealthErrorIds;

  ngOnChanges({ alarm, displayConfig }: SimpleChanges): void {
    if (alarm || displayConfig) {
      this.alarmText = toAlarmEventTextInfo(this.alarm, this.displayConfig);
    }
  }

  getLevelIconThemeInfo(alarm: MonitoringAlarmEventDTO): { color: { theme: string; shade: number } } {
    const alarmInfo = AlarmLevelsInfo[alarm?.level];
    if (!alarmInfo) {
      return null;
    }
    return {
      color: {
        theme: alarmInfo?.theme,
        shade: alarmInfo?.shade,
      },
    };
  }

  getTooltipTheme(alarm: MonitoringAlarmEventDTO): string {
    return AlarmLevelsInfo[alarm?.level]?.theme;
  }

  getLevelIconName(alarm: MonitoringAlarmEventDTO): string {
    const alarmInfo = AlarmLevelsInfo[alarm?.level];
    if (!alarmInfo) {
      return null;
    }
    return alarmInfo?.icon;
  }
}
