<avn-dialog-layout>
  <avn-dialog-header
    [showCloseIcon]="dialogConfig.showCloseIcon"
    [title]="'siteManagementScope.SITE_MANAGEMENT.SYNC_SITES.DIALOG.TITLE_50' | transloco"
    (closeClicked)="onCancel()"
  ></avn-dialog-header>

  <avn-dialog-content avnScroll>
    <avn-stepper [linear]="false" [showHeader]="false" (stepChanged)="onStepChanged($event.selectedStepIndex)">
      <avn-step>
        <amp-site-sync-dnd-csv
          [hasSiteSelection]="dialogConfig.data.isSelectedSites"
          [downloadCsvSitesEnabled]="siteListLoaded$ | async"
          (downloadCsvSites)="onDownloadSites()"
          (openFieldDescription)="openFieldDescription()"
          (fileSelected)="onSelectedFile($event)"
        ></amp-site-sync-dnd-csv>
      </avn-step>

      <avn-step>
        <avn-tabs [avnTheme]="'primary'">
          <avn-tab [label]="'siteManagementScope.SITE_MANAGEMENT.FIELD_DESCRIPTION.TABS.FIELD.TITLE_10' | transloco" [disabled]="false" [active]="true">
            <amp-site-sync-csv-field-list></amp-site-sync-csv-field-list>
          </avn-tab>
        </avn-tabs>
      </avn-step>

      <avn-step>
        <amp-site-sync-validation-summary avnScroll></amp-site-sync-validation-summary>
      </avn-step>

      <avn-step>
        <amp-site-sync-experience-selection (experiencesSelected)="onExperienceSelected($event)"></amp-site-sync-experience-selection>
      </avn-step>
    </avn-stepper>
  </avn-dialog-content>

  <avn-dialog-footer>
    <avn-button [label]="'button.cancel' | transloco" (actioned)="onCancel()"></avn-button>
    <div class="button-list">
      <avn-button [icon]="'navigation:chevron_left'" *ngIf="stepper.selectedIndex" [label]="'button.back' | transloco" (actioned)="onStepperBack()"></avn-button>
      <avn-button
        *ngIf="showStartSyncButton$ | async"
        [disabled]="syncButtonDisabled"
        [avnTheme]="'primary'"
        [label]="'siteManagementScope.SITE_MANAGEMENT.SYNC_SITES.DIALOG.BUTTON_START_SYNC_20' | transloco"
        (actioned)="startSync()"
      >
      </avn-button>
      <avn-button *ngIf="showSelectExperienceTemplateButton$ | async" [avnTheme]="'primary'" [label]="'button.next' | transloco" (actioned)="stepper.next()"> </avn-button>
    </div>
  </avn-dialog-footer>
</avn-dialog-layout>
