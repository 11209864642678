import { Inject, Injectable, Optional } from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';
import { IWhiteLabel } from './white-label.interface';
import { WHITE_LABEL_TOKEN } from './white-label.token';
@Injectable({
  providedIn: 'root',
})
export class WhiteLabelService {
  /** custom login logo */
  private customLoginLogoExists = new ReplaySubject<boolean>(1);
  /** custom in app logo */
  private customInAppLogoExists = new ReplaySubject<boolean>(1);

  constructor(@Optional() @Inject(WHITE_LABEL_TOKEN) private whiteLabelSettings: IWhiteLabel) {
    this.init();
  }

  getWhiteLabelSettings() {
    return this.whiteLabelSettings;
  }

  /**
   *
   * @deprecated use customLoginLogoExists$
   */
  getCustomLogo() {
    return this.whiteLabelSettings?.customlogo;
  }

  /** login logo exist observable*/
  get customLoginLogoExists$(): Observable<boolean> {
    return this.customLoginLogoExists.asObservable();
  }

  /** in APP logo exist observable */
  get customInAppLogoExists$(): Observable<boolean> {
    return this.customInAppLogoExists.asObservable();
  }

  /** init the white label settings */
  private init() {
    if (this.whiteLabelSettings && !!this.whiteLabelSettings.customlogo) {
      this.customLoginLogoExists.next(!!this.whiteLabelSettings.customlogo.loginLogoPath);
      this.customInAppLogoExists.next(!!this.whiteLabelSettings.customlogo.appLogoPath);
    }
  }
}
