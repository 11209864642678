import {ThemeType} from '@activia/ngx-components';

export type DownloadLogsTaskStatus = 'PROCESSING' | 'SUCCESS' | 'FAIL';

export interface IDownloadLogsTask {
  /** Auto-generated UUID of the task */
  id?: string;

  deviceId: number;
  hostname: string;

  /** Status of the task */
  status?: DownloadLogsTaskStatus;

  /** Logs data in ArrayBuffer */
  logsData?: ArrayBuffer;

  /** Error message if fail to download */
  errMsg?: string;
}

export const DownloadLogsTaskIcons: Record<DownloadLogsTaskStatus, string> = {
  PROCESSING: 'image:rotate_right',
  SUCCESS: 'action:check_circle',
  FAIL: 'alert:error_outline',
};

/** Mapping of the corresponding icon for each import job filter */
export const DownloadLogsTaskThemes: Record<DownloadLogsTaskStatus, ThemeType> = {
  PROCESSING: null,
  SUCCESS: ThemeType.SUCCESS,
  FAIL: ThemeType.DANGER,
};
