<ng-container [formGroup]="form">
  <avn-json-schema-control-field
    *ngIf="schema"
    [jsonSchema]="schema"
    [formControlName]="propertyName"
    [required]="true"
    [errorsText]="propertyName | applyFunction : getNameCustomError : [] : this"
    (changed)="formChanged.emit()"
  >
  </avn-json-schema-control-field>

  <ng-container *ngIf="nodeDefinition.childOneOf as dependencies">
    <ng-container *ngIf="getFirstDependencyChild(dependencies, form.controls[propertyName]?.value) as dependance">
      <!-- The following ngFor is to force the amp-orgpath-form-group component to re-render instead of just changing the @input -->
      <ng-container *ngFor="let childSchema of [dependance]">
        <amp-orgpath-form-group
          [controlAsyncValidatorMap]="controlAsyncValidatorMap"
          [nodeDefinition]="childSchema"
          [tagDefinition]="tagDefinition"
          [editable]="editable"
          (formChanged)="formChanged.emit()"
        ></amp-orgpath-form-group>
      </ng-container>
    </ng-container>
  </ng-container>
</ng-container>
