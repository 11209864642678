import { RoleDTO } from '@activia/cm-api';
import { Injectable } from '@angular/core';
import { filter, map } from 'rxjs/operators';

import { PERMISSIONS } from './permissions.constant';

import { CMRole } from './roles.enum';
import { AuthFacade } from './store/auth.facade';

/***
 * This service contains utility methods related to the authenticated user
 * @deprecated use methods in auth facade instead
 */
@Injectable({ providedIn: 'root' })
export class AuthUtilsService {
  constructor(private _authFacade: AuthFacade) {}

  /** Returns an observable checking the authenticated user has any of the provided roles **/
  hasAnyRole$(...roles: CMRole[]) {
    return this._authFacade.authenticatedUserRoles$.pipe(
      filter((urols) => !!urols),
      map((userRoles: RoleDTO[]) => userRoles.map((r) => r.name)),
      map((userRoles: string[]) => {
        for (let i = 0; i < roles.length; i++) {
          if (userRoles.includes(roles[i])) {
            return true;
          }
        }
        return false;
      })
    );
  }

  /** Returns an observable checking the authenticated user has any of the provided permission */
  hasAnyPermission$(...permissions: PERMISSIONS[]) {
    return this._authFacade.authenticatedUserPermissions$.pipe(
      filter((perms) => !!perms),
      map((perms: string[]) => {
        for (let i = 0; i < permissions.length; i++) {
          if (perms.includes(permissions[i])) {
            return true;
          }
        }
        return false;
      })
    );
  }
}
