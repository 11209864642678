import { TagOperationDTO, TagValueStatsDTO, TagOperationResult, ScreenTagsService, BoardTagsService, SiteTagsService } from '@activia/cm-api';
import { recursivelyFetchAll } from '@amp/utils/common';
import { HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of, map, switchMap } from 'rxjs';
import { IEngineTagKey } from '../model/engine-tag-key.interface';
import { EngineTagLevel } from '../model/operation-scope.interface';
import { ITagOperationChange } from '../model/tag-values-operations.interface';

@Injectable({ providedIn: 'root' })
export class TagOperationService {
  constructor(private _siteTagsService: SiteTagsService, private _boardTagsService: BoardTagsService, private _screenTagsService: ScreenTagsService) {}

  /** called after tag key update, sync affected tag values */
  syncTagValuesAfterKeyUpdate(tag: IEngineTagKey, operations: ITagOperationChange): Observable<any> {
    let valueSyncPayload$: Observable<Array<TagOperationDTO>>;
    // get payload from http call
    let invalidValueFn$: (offset: number) => Observable<HttpResponse<Array<TagValueStatsDTO>>>;

    switch (tag.level) {
      case EngineTagLevel.SITE:
        invalidValueFn$ = (offset) => this._siteTagsService.getInvalidValuesForNewTagKeyDesc2(tag.key, offset, undefined, undefined, undefined, tag.description, 'response');
        break;
      case EngineTagLevel.BOARD:
        invalidValueFn$ = (offset) => this._boardTagsService.getInvalidValuesForNewTagKeyDesc(tag.key, offset, undefined, undefined, undefined, tag.description, 'response');
        break;
      case EngineTagLevel.SCREEN:
        invalidValueFn$ = (offset) => this._screenTagsService.getInvalidValuesForNewTagKeyDesc1(tag.key, offset, undefined, undefined, undefined, tag.description, 'response');
        break;
      default:
        break;
    }
    if (operations?.edited?.length || operations?.removed?.length || operations?.merged?.length) {
      valueSyncPayload$ = of([
        // edit operations
        ...operations.edited.map((edited) => ({
          op: 'replace',
          key: tag.key,
          values: [edited.oldValue],
          newValues: [edited.newValue],
        })),
        // merge operations
        ...operations.merged.map((merged) => ({
          op: 'replace',
          key: tag.key,
          values: [...merged.oldValues],
          newValues: [merged.newValue],
        })),
        // Remove operations
        ...(operations.removed && operations.removed.length > 0
          ? [
              {
                op: 'delete',
                key: tag.key,
                values: [...operations.removed],
              },
            ]
          : []),
      ]);
    } else {
      valueSyncPayload$ = recursivelyFetchAll<TagValueStatsDTO>(invalidValueFn$.bind(this)).pipe(
        map((res) =>
          res && res?.length > 0
            ? [
                {
                  op: 'delete',
                  key: tag.key,
                  values: res.map((rs) => rs.value),
                },
              ]
            : []
        )
      );
    }
    return valueSyncPayload$.pipe(
      switchMap((payload: Array<TagOperationDTO>) => {
        if (payload && payload.length === 0) {
          return of([]);
        }
        let valueSyncRequest$: Observable<Array<TagOperationResult>>;
        switch (tag.level) {
          case EngineTagLevel.SITE:
            valueSyncRequest$ = this._siteTagsService.patchTagsForEntities2(undefined, payload);
            break;
          case EngineTagLevel.BOARD:
            valueSyncRequest$ = this._boardTagsService.patchTagsForEntities(undefined, payload);
            break;
          case EngineTagLevel.SCREEN:
            valueSyncRequest$ = this._screenTagsService.patchTagsForEntities1(undefined, undefined, undefined, undefined, payload);
            break;
          default:
            break;
        }
        return valueSyncRequest$;
      })
    );
  }
}
