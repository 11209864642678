import { Pipe, PipeTransform } from '@angular/core';
import { getI18nFormattedValue } from '@amp/utils/common';

/***
 * Replace all whitespaces in the value with the provided replacement and uppercase the whole value
 */
@Pipe({
  name: 'i18nFormattedValue',
  pure: false,
})
export class I18nFormattedValuePipe implements PipeTransform {
  public transform(value: string) {
    return getI18nFormattedValue(value);
  }
}
