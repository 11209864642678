import { ChangeDetectorRef, Component, EventEmitter, OnDestroy, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { map, Observable, Subject, take, tap } from 'rxjs';
import { dataOnceReady, LoadingState, ThemeType } from '@activia/ngx-components';
import * as SiteManagementAction from '../../../store/site-management.actions';
import { Store } from '@ngrx/store';
import { ISiteManagementState } from '../../../store/site-management.reducer';
import { siteManagementEntities } from '../../../store/site-management.selectors';
import { ISiteManagementConfigSchema, ISiteManagementConfigurationSchema, SiteManagementOrgPathBoardCaseTypes } from '@amp/environment';

@Component({
  selector: 'amp-site-management-preferences',
  templateUrl: './site-management-preferences.component.html',
  styleUrls: ['./site-management-preferences.component.scss'],
})
export class SiteManagementPreferencesComponent implements OnDestroy {
  @Output() closed = new EventEmitter<boolean>();

  public preferencesConfig: ISiteManagementConfigurationSchema;

  public preferencesLoading$: Observable<boolean> = this.store.pipe(siteManagementEntities.siteConfigDataState$).pipe(map((dataState) => dataState === LoadingState.LOADING));

  /** UserPreferences FormGroup */
  public preferencesFormGroup: FormGroup<{
    allowDevicesAcrossMultipleBoards: FormControl<boolean>;
    defaultOutputCountPerPlayer: FormControl<number>;
    defaultPlayerCountPerDevice: FormControl<number>;
    orgPathBoardNameCase: FormControl<'none' | 'uppercase' | 'capitalize'>;
  }>;

  public themeType: typeof ThemeType = ThemeType;

  caseOptions = SiteManagementOrgPathBoardCaseTypes;

  /** @ignore Pattern used to close all subscriptions */
  private componentDestroyed$: Subject<void> = new Subject<void>();

  constructor(private store: Store<ISiteManagementState>, private cdr: ChangeDetectorRef) {
    dataOnceReady(this.store.pipe(siteManagementEntities.siteConfigData$), this.store.pipe(siteManagementEntities.siteConfigDataState$))
      .pipe(
        take(1),
        tap((preferenceConfig) => {
          this.preferencesConfig = preferenceConfig;
          this.initializeFormGroup(preferenceConfig);
        })
      )
      .subscribe();
  }

  updateSwitch(formControlName: string, status: boolean) {
    /** Update formControl on change */
    this.preferencesFormGroup.get(formControlName)?.patchValue(status);
    this.cdr.detectChanges();
  }

  /** Called when the drawer is closed **/
  onClose(): void {
    this.closed.emit(true);
  }

  /** Save UserPreferences form */
  onSaveUserPreferences() {
    // Update into the store
    const userPref = this.preferencesFormGroup.value as ISiteManagementConfigSchema;

    // Convert input text to number
    userPref.defaultOutputCountPerPlayer = +userPref.defaultOutputCountPerPlayer;
    userPref.defaultPlayerCountPerDevice = +userPref.defaultPlayerCountPerDevice;

    this.updateUserPreferences(userPref);
    this.onClose();
  }

  /** On reset userPreferences Form */
  onResetUserPreferences(): void {
    this.preferencesFormGroup.reset();
    this.populatePreferencesFormGroup();
    this.cdr.detectChanges();
  }

  /** @ignore **/
  ngOnDestroy(): void {
    this.componentDestroyed$.next();
    this.componentDestroyed$.complete();
  }

  /** Update User Preferences */
  public updateUserPreferences(userPreferences: ISiteManagementConfigSchema) {
    this.store.dispatch(SiteManagementAction.UpdatePreferences({ preferences: userPreferences }));
  }

  /** Initialize the userPreferences formGroup  */
  private populatePreferencesFormGroup() {
    this.preferencesFormGroup.get('allowDevicesAcrossMultipleBoards').setValue(this.preferencesConfig.allowDevicesAcrossMultipleBoards);
    this.preferencesFormGroup.get('defaultPlayerCountPerDevice').setValue(+this.preferencesConfig.defaultPlayerCountPerDevice);
    this.preferencesFormGroup.get('defaultOutputCountPerPlayer').setValue(+this.preferencesConfig.defaultOutputCountPerPlayer);
    this.preferencesFormGroup.get('orgPathBoardNameCase').setValue(this.preferencesConfig.orgPathBoardNameCase);
    this.cdr.detectChanges();
  }

  private initializeFormGroup(config: ISiteManagementConfigurationSchema): void {
    this.preferencesFormGroup = new FormGroup({
      allowDevicesAcrossMultipleBoards: new FormControl(config.allowDevicesAcrossMultipleBoards),
      defaultOutputCountPerPlayer: new FormControl(config.defaultOutputCountPerPlayer, [Validators.min(config.minDefaultOutputCountPerPlayer), Validators.max(config.maxDefaultOutputCountPerPlayer)]),
      defaultPlayerCountPerDevice: new FormControl(config.defaultPlayerCountPerDevice, [Validators.min(config.minDefaultPlayerCountPerDevice), Validators.max(config.maxDefaultPlayerCountPerDevice)]),
      orgPathBoardNameCase: new FormControl(config.orgPathBoardNameCase),
    });
  }
}
