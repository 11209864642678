import { EventEmitter } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

type Constructor<T> = new (...args: any[]) => T;

/**
 * Interface to implement when creating a key metric settings component
 * **/
export class IKeyMetricSettingsComponent<T = object> {
  /**  the settings for the key metric  **/
  settings: T;

  /** form to update the settings **/
  form: UntypedFormGroup;

  /** emits the new settings when they change **/
  settingsChanged: EventEmitter<T>;

  /** Indicates if the settings are valid **/
  isValid: () => boolean;

  /** To call when the component is destroyed **/
  onDestroy: () => void;
}

/** @docs-private */
export type SiteMonitoringKeyMetricComponentCtor<T> = Constructor<IKeyMetricSettingsComponent<T>>;

/**
 * Mixin to apply to key metrics settings components. It contains the shared behavior.
 * Trade off of using a mixin here:
 * if we implement OnDestroy in the mixin, angular forces us to decorate the class (https://github.com/angular/angular/issues/37769)
 * So lets just add ngOnDestroy in the classes implementing the mixin.
 * It is okay for this use case since the settings component do not hold much logic.
 */
export const mixinKeyMetricSettings = <T extends Constructor<any>, C>(base: T): SiteMonitoringKeyMetricComponentCtor<C> & T => {
  class MixinKeyMetricSettingsClass extends base implements IKeyMetricSettingsComponent<C> {
    /** @ignore Pattern used to close all subscriptions */
    private componentDestroyed$ = new Subject<void>();

    /**  the settings for the key metric  **/
    _settings: C;
    set settings(settings: C) {
      this._settings = settings;
      this.form?.patchValue(settings, { emitEvent: false });
    }
    get settings(): C {
      return this._settings;
    }

    /** form to update the settings **/
    private _form: UntypedFormGroup;
    set form(form: UntypedFormGroup) {
      this._form = form;
      // emit the settings change when the form value change
      this.form.valueChanges.pipe(takeUntil(this.componentDestroyed$)).subscribe((value) => {
        this.settingsChanged.emit(value);
      });
    }
    get form(): UntypedFormGroup {
      return this._form;
    }

    /** emits the new settings when they change **/
    settingsChanged = new EventEmitter<C>();

    /** Indicates if the settings are valid **/
    isValid(): boolean {
      return this.form?.valid;
    }

    /** To call when the component is destroyed **/
    onDestroy() {
      this.componentDestroyed$.next();
      this.componentDestroyed$.complete();
    }

    constructor(...args: any[]) {
      super(...args);
    }
  }
  return MixinKeyMetricSettingsClass;
};

export class KeyMetricsMixinSettingsCore {}

export const KeyMetricsSettingsBaseMixin = <T = any>(): SiteMonitoringKeyMetricComponentCtor<T> & typeof KeyMetricsMixinSettingsCore => mixinKeyMetricSettings(KeyMetricsMixinSettingsCore);
