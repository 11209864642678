import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { first, mapTo, tap } from 'rxjs/operators';
import { LoadingState } from '@activia/ngx-components';
import * as SiteManagementAction from '../store/site-management.actions';
import { Store } from '@ngrx/store';
import { ISiteManagementState } from '../store/site-management.reducer';
import { siteManagementEntities } from '../store/site-management.selectors';

@Injectable()
export class PreloadSiteConfigGuard  {
  constructor(private _store: Store<ISiteManagementState>) {}

  canActivate(): Observable<boolean> {
    return this._store.pipe(siteManagementEntities.siteConfigDataState$).pipe(
      first(),
      tap((state) => {
        if (state !== LoadingState.LOADING && state !== LoadingState.LOADED) {
          this._store.dispatch(SiteManagementAction.FetchSiteConfig());
        }
      }),
      mapTo(true)
    );
  }
}
