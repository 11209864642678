<ng-container [ngSwitch]="syncStatus$ | async">
  <ng-container *ngSwitchCase="'init'">
    <ng-container *ngTemplateOutlet="loader; context: { message: 'siteManagementScope.SITE_MANAGEMENT.SYNC_SITES.DIALOG.READING_FILE_MESSAGE_100' | transloco }"></ng-container>
  </ng-container>

  <ng-container *ngSwitchCase="'validating'">
    <ng-container *ngTemplateOutlet="loader; context: { message: 'siteManagementScope.SITE_MANAGEMENT.SYNC_SITES.DIALOG.VALIDATING_FILE_MESSAGE_100' | transloco }"></ng-container>
  </ng-container>

  <ng-container *ngSwitchCase="'validation-error'">
    <ng-container
      *ngTemplateOutlet="
        headerMessage;
        context: { icon: 'alert:error_outline', theme: 'danger', title: 'siteManagementScope.SITE_MANAGEMENT.SYNC_SITES.DIALOG.FAIL_READ_FILE_MESSAGE_100' | transloco, messages: messages$ | async }
      "
    ></ng-container>
  </ng-container>

  <ng-container *ngSwitchCase="'invalid-columns'">
    <ng-container
      *ngTemplateOutlet="
        headerMessage;
        context: { icon: 'alert:error_outline', theme: 'danger', title: 'siteManagementScope.SITE_MANAGEMENT.SYNC_SITES.DIALOG.INVALID_FILE_HEADERS_250' | transloco, messages: messages$ | async }
      "
    ></ng-container>
  </ng-container>

  <ng-container *ngSwitchCase="'no-data'">
    <ng-container
      *ngTemplateOutlet="
        headerMessage;
        context: { icon: 'alert:error_outline', theme: 'danger', title: 'siteManagementScope.SITE_MANAGEMENT.SYNC_SITES.DIALOG.NO_SITE_DATA_250' | transloco, messages: messages$ | async }
      "
    ></ng-container>
  </ng-container>

  <ng-container *ngSwitchCase="'invalid-ids'">
    <ng-container
      *ngTemplateOutlet="
        headerMessage;
        context: {
          icon: 'alert:error_outline',
          theme: 'danger',
          title: 'siteManagementScope.SITE_MANAGEMENT.SYNC_SITES.DIALOG.INVALID_EXTERNAL_IDS.MESSAGE_150' | transloco,
          messages: messages$ | async
        }
      "
    ></ng-container>
  </ng-container>

  <ng-container *ngSwitchCase="'valid'">
    <ng-container *ngIf="validationResultCount$ | async as counts">
      <ng-container
        *ngTemplateOutlet="
          headerMessage;
          context: { icon: 'action:check_circle_outline', theme: 'success', title: 'siteManagementScope.SITE_MANAGEMENT.SYNC_SITES.DIALOG.VALID_FILE_MESSAGE_100' | transloco, messages: [] }
        "
      ></ng-container>
      <avn-accordion>
        <avn-accordion-panel
          class="disabled-expansion"
          [disableExpansion]="true"
          [headerTemplate]="statusCount"
          [headerTemplateContext]="{ label: 'new sites', count: counts.add, countTheme: SiteSyncStatusThemes['add'] }"
        >
        </avn-accordion-panel>
        <avn-accordion-panel
          class="disabled-expansion"
          [disableExpansion]="true"
          [headerTemplate]="statusCount"
          [headerTemplateContext]="{ label: 'changed sites', count: counts.update, countTheme: SiteSyncStatusThemes['update'] }"
        >
        </avn-accordion-panel>
        <avn-accordion-panel
          [class.disabled-expansion]="counts.invalid === 0"
          [disableExpansion]="counts.invalid === 0"
          [headerTemplate]="statusCount"
          [headerTemplateContext]="{ label: 'invalid sites', count: counts.invalid, countTheme: SiteSyncStatusThemes['error'] }"
        >
          <ng-container *ngIf="counts.invalid > 0">
            <div class="sub-statuses">
              <div class="sub-message" *ngIf="counts['invalid-id'] as count">
                &bull;&nbsp;
                {{
                  count
                    | i18nPlural: i18nImportSubStatusCountMessage
                    | transloco: { count: count, action: ('siteManagementScope.SITE_MANAGEMENT.SYNC_SITES.DIALOG.SUB_STATUS.INVALID_ID_50' | transloco) }
                }}
              </div>
            </div>
          </ng-container>
        </avn-accordion-panel>
        <avn-accordion-panel
          class="disabled-expansion"
          [disableExpansion]="true"
          [headerTemplate]="statusCount"
          [headerTemplateContext]="{ label: 'unchanged sites', icon: 'content:add', count: counts.unchanged, countTheme: SiteSyncStatusThemes['unchanged'] }"
        >
        </avn-accordion-panel>
      </avn-accordion>

      <div class="footer-message" *ngIf="counts.add === 0 && counts.update === 0">
        {{ 'siteManagementScope.SITE_MANAGEMENT.SYNC_SITES.DIALOG.NO_SITE_TO_IMPORT_MESSAGE_100' | transloco }}
      </div>
    </ng-container>
  </ng-container>
</ng-container>

<ng-template #loader let-message="message">
  <avn-loader [isLoading]="true" [displayText]="message" [avnTheme]="'primary'"></avn-loader>
</ng-template>

<ng-template #statusCount let-label="label" let-count="count" let-countTheme="countTheme">
  <div class="status-count">
    <span class="count" [class.active]="count > 0" [avnThemify]="count > 0 ? countTheme : null">{{ count }}</span>
    <span>{{ label }}</span>
  </div>
</ng-template>

<ng-template #headerMessage let-icon="icon" let-theme="theme" let-title="title" let-messages="messages">
  <div class="header">
    <avn-icon [icon]="icon" [fontSize]="48" [avnTheme]="theme"> </avn-icon>
    <div>
      <div class="header-message">{{ title }}</div>
      <div class="messages" *ngIf="messages?.length > 0">
        <ng-container *ngFor="let message of messages$ | async">
          <div class="message">{{ message }}</div>
        </ng-container>
      </div>
    </div>
  </div>
</ng-template>
