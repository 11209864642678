import { DeviceDTO, DisplayDTO, SiteDTO } from '@activia/cm-api';
import { ScreenOrientations } from './screen-orientation.enum';
import { IBoard } from './board-config.interface';

/** Event object after an experience template is created **/
export interface ICreateTemplateBoardsSuccessEvent {
  boards: IBoard[];
  site: SiteDTO;
  displays: DisplayDTO[];
  devices?: DeviceDTO[];
}

/** Event emitted when the user has selected 1+ experience(s) to apply to a csv site **/
export interface IExperienceTemplateInfo {
  experiences: {
    experience: IExperienceTemplate;
    devicesPerBoard?: Record<number, DeviceDTO[]>; // devices per board index within template
    count: number;
  }[];
  deviceAction: 'provision' | 'connect-existing';
}

export interface IExperienceTemplate {
  /** Organizational Path */
  tags: Record<string, unknown>;

  /** Label of the template */
  label: string;

  /** List of all board template */
  boards: IBoardExperienceTemplate[];

  /** If the template allows multiple identical boards on the same site */
  allowMultiple?: boolean;
}

export interface IBoardExperienceTemplate {
  /** Name of the board */
  label: string;

  /** Strategy used to connect players and displays  */
  redundancyStrategy: RedundancyStrategy;

  /** Player settings */
  player: IPlayerTemplate;

  /** Display used in the board */
  displays: IDisplayTemplate[];
}

export interface IBoardCreationTemplate extends IBoardExperienceTemplate {
  /** Devices that will be created for the board */
  devices: DeviceDTO[];
}

export interface IPlayerTemplate {
  /** Default name pattern of the player */
  namePattern: string;

  /** Default model of the player */
  model: string;

  playbackUnit: {
    /** Number of playback units for this player */
    count: number;
    /** Number of output per playback units */
    outputPerPlaybackUnit: number;
  };

  /** Default ip adress of the player */
  ipAdress: string;
}

export interface IDisplayTemplate {
  orientation: ScreenOrientations;
  name: string;
  geometry?: string;
}

export enum RedundancyStrategy {
  NONE = 'NONE',
  ACTIVE_PASSIVE = 'ACTIVE_PASSIVE',
  ACTIVE_FAILOVER = 'ACTIVE_FAILOVER',
}
