<avn-page>
  <avn-ribbon [title]="site$ | async | siteTitle" [fabIcons]="(isAdmin$ | async) ? 'action:settings' : null" (fabActionClicked)="openSettingsDrawer()">
    <avn-breadcrumbs>
      <avn-breadcrumb
        [label]="'siteManagementScope.SITE_MANAGEMENT.DASHBOARD.RIBBON.TITLE.TITLE_50' | transloco"
        [icon]="'social:location_city'"
        [routerLink]="['..']"
        [queryParams]="{ st: null }"
        [queryParamsHandling]="'merge'"
      >
      </avn-breadcrumb>
      <avn-breadcrumb [label]="'siteManagementScope.SITE_MANAGEMENT.DASHBOARD.CARDS.ALL_SITES.BREADCRUMB_TITLE_50' | transloco" [routerLink]="['../sites']"> </avn-breadcrumb>
    </avn-breadcrumbs>
    <avn-ribbon-actions>
      <avn-ribbon-card
        [icon]="'action:settings'"
        [title]="'siteManagementScope.SITE_MANAGEMENT.SITE_DETAIL.RIBBON.ACTIONS.SITE_INFO_20' | transloco"
        [routerLink]="['properties', 'info']"
        [toggled]="propertyLink.isActive"
        routerLinkActive
        #propertyLink="routerLinkActive"
      ></avn-ribbon-card>
      <avn-ribbon-card
        [icon]="'fa:hdd-o'"
        [title]="'siteManagementScope.SITE_MANAGEMENT.SITE_DETAIL.RIBBON.ACTIONS.PLAYERS_20' | transloco"
        [routerLink]="['players']"
        [routerLinkActiveOptions]="{ queryParams: 'ignored', fragment: 'ignored', matrixParams: 'ignored', paths: 'subset' }"
        [replaceUrl]="true"
        [queryParams]="{ st: 'players' }"
        [toggled]="playerLink.isActive"
        routerLinkActive
        #playerLink="routerLinkActive"
      ></avn-ribbon-card>
      <ng-container *ngIf="(boardsCount$ | async) > 0">
        <avn-ribbon-card
          [icon]="'content:web_stories'"
          [title]="'siteManagementScope.SITE_MANAGEMENT.SITE_DETAIL.RIBBON.ACTIONS.BOARD_TAGS_20' | transloco"
          [routerLink]="['boards']"
          [routerLinkActiveOptions]="{ queryParams: 'ignored', fragment: 'ignored', matrixParams: 'ignored', paths: 'subset' }"
          [replaceUrl]="true"
          [toggled]="boardLink.isActive"
          routerLinkActive
          #boardLink="routerLinkActive"
        ></avn-ribbon-card>
        <avn-ribbon-card
          *ngIf="hasDisplay$ | async"
          [icon]="'communication:desktop_windows'"
          [title]="'siteManagementScope.SITE_MANAGEMENT.SITE_DETAIL.RIBBON.ACTIONS.SCREEN_TAGS_20' | transloco"
          [routerLink]="['screens']"
          [routerLinkActiveOptions]="{ queryParams: 'ignored', fragment: 'ignored', matrixParams: 'ignored', paths: 'subset' }"
          [replaceUrl]="true"
          [toggled]="screenLink.isActive"
          routerLinkActive
          #screenLink="routerLinkActive"
        ></avn-ribbon-card>
      </ng-container>
    </avn-ribbon-actions>
    <avn-ribbon-facets>
      <ng-container *ngIf="hasSiteMonitoringAccess$ | async">
        <avn-ribbon-card
          [icon]="'fa:heartbeat'"
          [title]="'siteManagementScope.SITE_MANAGEMENT.DASHBOARD.RIBBON.ACTIONS.VIEW_MONITORING_50' | transloco"
          [avnTooltip]="'siteManagementScope.SITE_MANAGEMENT.DASHBOARD.RIBBON.ACTIONS.VIEW_MONITORING_DESC_100' | transloco"
          (actioned)="navigateToSiteMonitoring()"
        >
        </avn-ribbon-card>
      </ng-container>
      <ng-container *ampHasAnyAuthority="SITE_MANAGEMENT_ROLES.site">
        <avn-ribbon-card
          [icon]="'hardware:browser_updated'"
          [title]="'siteManagementScope.SITE_MANAGEMENT.DASHBOARD.RIBBON.ACTIONS.PUSH_TO_PLAYERS_50' | transloco"
          [avnTooltip]="'siteManagementScope.SITE_MANAGEMENT.DASHBOARD.RIBBON.ACTIONS.PUSH_TO_PLAYERS_DESC_100' | transloco"
          [disabled]="!(siteDevices$ | async).length"
          (actioned)="onPushToPlayers()"
        >
        </avn-ribbon-card>
      </ng-container>
    </avn-ribbon-facets>
  </avn-ribbon>

  <avn-main>
    <avn-resizeable-layout [layout]="layout" *avnAsyncData="site$ | async; state: siteDataState$ | async; errorTemplate: error; emptyTemplate: empty; loaderType: 'loader'">
      <section *avnResizeableLayoutSection="'content'" avnscroll>
        <amp-site-management-board-editor [site]="site$ | async"></amp-site-management-board-editor>
      </section>
      <section *avnResizeableLayoutSection="'properties'">
        <router-outlet></router-outlet>
      </section>
    </avn-resizeable-layout>

    <ng-template #error>
      <avn-empty-data-message icon="alert:error" [text]="'Error loading site'" [subText]="'An error occurred while loading the site. Please try again later.'"></avn-empty-data-message>
    </ng-template>

    <ng-template #empty>
      <div class="site-not-found">
        <avn-empty-data-message icon="communication:location_off" [text]="'Site not found'" [subText]="'The site you are looking could not be found. Please make sure the requested site exists.'">
        </avn-empty-data-message>
      </div>
    </ng-template>
  </avn-main>
</avn-page>
