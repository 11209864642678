import {
  AlarmEventLevel,
  ContentStatus,
  DevicePropertiesStatus,
  HealthStatusCode,
  OptimisticViewStatusCombinations
} from '@amp/devices';

/**
 * Optimistic combinations for Health Status
 */
export const HEALTH_STATUS_OPTIMISTIC_COMBINATIONS: OptimisticViewStatusCombinations<HealthStatusCode> = new Map<HealthStatusCode, HealthStatusCode>([[HealthStatusCode.WARNING, HealthStatusCode.OK]]);

/**
 * Optimistic combinations for Device Properties Status
 */
export const DEVICE_PROPERTIES_STATUS_OPTIMISTIC_COMBINATIONS: OptimisticViewStatusCombinations<DevicePropertiesStatus> = new Map<DevicePropertiesStatus, DevicePropertiesStatus>([
  [DevicePropertiesStatus.NoticeDYN, DevicePropertiesStatus.NoticeALL],
  [DevicePropertiesStatus.WarningDYN, DevicePropertiesStatus.OK],
  [DevicePropertiesStatus.WarningALL, DevicePropertiesStatus.OK],
  [DevicePropertiesStatus.ErrorDYN, DevicePropertiesStatus.ErrorALL],
]);

/**
 * Optimistic combinations for Content Status
 */
export const CONTENT_STATUS_OPTIMISTIC_COMBINATIONS: OptimisticViewStatusCombinations<ContentStatus> = new Map<ContentStatus, ContentStatus>([[ContentStatus.WARNING, ContentStatus.OK]]);

/** Optimistic combinations for key metrics which data sources come from alarms events */
export const ALARM_EVENT_OPTIMISTIC_COMBINATIONS: OptimisticViewStatusCombinations<AlarmEventLevel> = new Map<AlarmEventLevel, AlarmEventLevel>([
  [AlarmEventLevel.Warning, AlarmEventLevel.Info],
  [AlarmEventLevel.Notice, AlarmEventLevel.Info],
  [AlarmEventLevel.Debug, AlarmEventLevel.Info],
]);
