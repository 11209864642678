import { ChangeDetectionStrategy, Component, EventEmitter, Inject, OnDestroy, Output } from '@angular/core';
import { IModalComponent, IModalConfig, MODAL_CONFIG, ModalRef } from '@activia/ngx-components';
import { IModalPickerComponent } from '../../../models/modal-picker-component.interface';
import { Subject } from 'rxjs';
import { DeviceDTO } from '@activia/cm-api';

@Component({
  selector: 'amp-site-management-search-devices-modal',
  templateUrl: './site-management-search-devices-modal.component.html',
  styleUrls: ['./site-management-search-devices-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SiteManagementSearchDevicesModalComponent implements IModalComponent, IModalPickerComponent<DeviceDTO[]>, OnDestroy {
  /** Emits the ID of the selected devices **/
  @Output() saved = new EventEmitter<DeviceDTO[]>();

  selectedDevices: DeviceDTO[] = [];

  /** @ignore **/
  private _componentDestroyed$: Subject<void> = new Subject<void>();

  constructor(private _dialogRef: ModalRef<SiteManagementSearchDevicesModalComponent>, @Inject(MODAL_CONFIG) public dialogConfig: IModalConfig<string>) {}

  /** @ignore **/
  ngOnDestroy(): void {
    this._componentDestroyed$.next();
    this._componentDestroyed$.complete();
  }

  canClose(): boolean {
    return this.dialogConfig.closeOnBackdropClick;
  }

  /** Called when the cancel button is clicked **/
  onCancel() {
    this._close();
  }

  onDevicesSelected(devices: DeviceDTO[]) {
    this.selectedDevices = devices;
  }

  /** Called when the create button is clicked **/
  addDevices() {
    this.saved.emit(this.selectedDevices);
    this._close();
  }

  private _close() {
    this._dialogRef.close();
  }
}
