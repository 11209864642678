import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { SiteSyncGlobalStatus, SiteValidationStatus } from '../../store/site-sync/site-sync.model';
import { Observable } from 'rxjs';
import { siteSyncSelectors } from '../../store/site-sync/site-sync.selectors';
import { SiteSyncStatusThemes } from '../../models/site-management-sync';

@Component({
  selector: 'amp-site-sync-validation-summary',
  templateUrl: './site-sync-validation-summary.component.html',
  styleUrls: ['./site-sync-validation-summary.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SiteSyncValidationSummaryComponent {
  SiteSyncStatusThemes = SiteSyncStatusThemes;

  i18nImportCountMessage: { [key: string]: string } = {
    '=0': 'siteManagementScope.SITE_MANAGEMENT.SYNC_SITES.DIALOG.SITE_COUNT.SINGULAR_50',
    '=1': 'siteManagementScope.SITE_MANAGEMENT.SYNC_SITES.DIALOG.SITE_COUNT.SINGULAR_50',
    other: 'siteManagementScope.SITE_MANAGEMENT.SYNC_SITES.DIALOG.SITE_COUNT.PLURAL_50',
  };

  i18nImportSubStatusCountMessage: { [key: string]: string } = {
    '=0': 'siteManagementScope.SITE_MANAGEMENT.SYNC_SITES.DIALOG.SUB_STATUS.SITE_COUNT.SINGULAR_50',
    '=1': 'siteManagementScope.SITE_MANAGEMENT.SYNC_SITES.DIALOG.SUB_STATUS.SITE_COUNT.SINGULAR_50',
    other: 'siteManagementScope.SITE_MANAGEMENT.SYNC_SITES.DIALOG.SUB_STATUS.SITE_COUNT.PLURAL_50',
  };

  syncStatus$: Observable<SiteSyncGlobalStatus>;
  messages$: Observable<string[]>;
  // counts per validation status
  validationResultCount$: Observable<Record<SiteValidationStatus | 'invalid', number>>;

  constructor(private _store: Store) {
    this.syncStatus$ = this._store.select(siteSyncSelectors.status);
    this.messages$ = this._store.select(siteSyncSelectors.messages);
    this.validationResultCount$ = this._store.select(siteSyncSelectors.sitesValidationStatusCount);
  }
}
