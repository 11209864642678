<avn-icon
  [class.landscape]="screenOrientation === screenOrientationEnum.landscape"
  [class.disabled]="disabled"
  icon="device:screen_rotation"
  [fontSize]="14"
  (click)="toggleOrientation()"
></avn-icon>
<span class="smdh-text" [class.disabled]="disabled">{{
  screenOrientation === screenOrientationEnum.landscape
    ? ('siteManagementScope.SITE_MANAGEMENT.BOARD_EDITOR.GLOBAL.LANDSCAPE_10' | transloco)
    : ('siteManagementScope.SITE_MANAGEMENT.BOARD_EDITOR.GLOBAL.PORTRAIT_10' | transloco)
}}</span>
