<div class="wrapper" #overlayOrigin="cdkOverlayOrigin" cdkOverlayOrigin>
  <div class="header">
    <div class="device-info">
      <avn-badge *ngIf="deviceHealthData" [label]="deviceHealthData?.label | transloco" [badgeSize]="'extraSmall'" [avnTheme]="deviceHealthData?.theme"></avn-badge>
      <div class="title">
        <div class="line">
          <span [attr.data-e2e]="'device-hostname'">{{ deviceInfo.device.hostname }}</span>
          <avn-icon
            [attr.data-e2e]="'device-loading-icon'"
            [class.hidden]="!deviceInfo.deviceLoading"
            fontSize="14"
            icon="notification:sync"
            [rotate]="true"
            [avnTooltip]="'siteMonitoringSharedScope.SITE_MONITORING.SITE_DETAIL.BOARD.DEVICE_CONNECTION_ATTEMPT_30' | transloco"
            [tooltipPlacement]="'top'"
          ></avn-icon>
        </div>
        <span *ngIf="showIpAddress$ | async" [attr.data-e2e]="'device-ipAddress'">{{ deviceInfo.device.ipAddress }}</span>
      </div>
    </div>
    <div class="icons">
      <avn-icon
        *ngIf="viewDeviceEnabled"
        [attr.data-e2e]="'view-device'"
        class="device-detail"
        [icon]="'action:open_in_new'"
        [fontSize]="18"
        [avnTooltip]="'siteMonitoringSharedScope.SITE_MONITORING.SITE_DETAIL.BOARD.DEVICE_DETAIL_50' | transloco"
        [tooltipPlacement]="'top'"
        (click)="goToDeviceDetail(deviceInfo.device.deviceId)"
      ></avn-icon>
      <amp-site-device-actions [deviceInfo]="deviceInfo" [iconSize]="18"></amp-site-device-actions>
    </div>
  </div>
  <div class="body">
    <ul class="alarms" avnScroll>
      <li *ngFor="let alarm of deviceInfo.alarms | applyFunction: filterHealthErrorAlarms:[showHealthErrorAlarms$ | async]">
        <amp-device-alarm [alarm]="alarm | boardAlarm" [displayConfig]="alarmDisplayConfig$ | async"></amp-device-alarm>
      </li>
    </ul>
    <div class="players" [style.grid-template-columns]="getPlayersLayout(deviceInfo.logicalPlayers?.length)">
      <div
        class="player"
        (click)="showPlayerStatus($event, player)"
        *ngFor="let player of deviceInfo.logicalPlayers; trackBy: logicalPlayerTrackBy"
        [avnConnectorLineAnchor]="getConnectorLinesAnchors(player.connections)"
        [avnConnectorLineAnchorParentElement]="elementRef.nativeElement"
        [class.opened]="playerWithAlertsModalOpened === player"
        [class.error]="player.alarms.length > 0"
      >
        <span>{{ ('siteMonitoringSharedScope.SITE_MONITORING.SITE_DETAIL.BOARD.PLAYER_50' | transloco) + ' ' + (player.id + 1) }}</span>
        <avn-icon class="status-icon" [icon]="(player | applyFunction: getPlayerAlertIconInfo).icon" [avnTheme]="(player | applyFunction: getPlayerAlertIconInfo).theme"></avn-icon>
        <div class="player-alerts">
          <span *ngIf="player.alarms.length === 0" class="no-error">{{ 'siteMonitoringSharedScope.SITE_MONITORING.SITE_DETAIL.BOARD.PLAYER_STATUS_OK_15' | transloco }}</span>
          <span *ngIf="player.alarms.length > 0" class="has-errors" [avnTheming]="player.alarms | applyFunction: getLevelIconThemeInfo">
            {{
              'siteMonitoringSharedScope.SITE_MONITORING.SITE_DETAIL.BOARD.ALERTS.' + (player.alarms.length > 1 ? 'ALERT_COUNT_PLURAL_15' : 'ALERT_COUNT_SINGULAR_15')
                | transloco: { count: player.alarms.length }
            }}</span
          >
        </div>
      </div>
    </div>
  </div>
</div>

<avn-connected-element #connectedElement [overlayOrigin]="overlayOrigin" [opened]="!!playerWithAlertsModalOpened" (toggled)="onToggle($event)" [hasVisibleBackdrop]="true" [hasOverlayBackdrop]="true">
  <ul class="alarms modal" *ngIf="playerWithAlertsModalOpened?.alarms.length > 0" avnScroll>
    <li *ngFor="let alarm of playerWithAlertsModalOpened.alarms">
      <amp-device-alarm [alarm]="alarm | boardAlarm" [displayConfig]="alarmDisplayConfig$ | async" [showNamespace]="alarm | applyFunction: showPlayerAlarmNamespace"></amp-device-alarm>
    </li>
  </ul>
</avn-connected-element>
