import { ThemeType } from '@activia/ngx-components';
import { ServiceStatusCode, ServiceStatusThemeMap } from '@amp/devices';
import { CountAggregationData } from './../../../model/site-monitoring-data.interface';
import { ChangeDetectionStrategy, Component, ElementRef, OnChanges, SimpleChanges } from '@angular/core';
import { ISiteMonitoringKeyMetricComponent, KeyMetricsBaseMixin } from '../key-metrics.mixin';
import { ISiteMonitoringKeyMetricServiceSettings } from '../../../model/key-metrics/site-monitoring-key-metric-service-settings.interface';
import { DisplayType } from '@amp/column-picker';
import { IServiceDataSourceItem } from '../../../model/key-metrics/service-data-source-item.interface';

@Component({
  selector: 'amp-key-metrics-services',
  templateUrl: './key-metrics-services.component.html',
  styleUrls: ['./key-metrics-services.component.scss'],
  inputs: ['config', 'monitoringData', 'monitoringDataDataState', 'showCompactView'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class KeyMetricsServicesComponent extends KeyMetricsBaseMixin<ISiteMonitoringKeyMetricServiceSettings>() implements ISiteMonitoringKeyMetricComponent, OnChanges {
  /** The datasource for the services **/
  servicesDataSource: IServiceDataSourceItem[] = [];

  /** info for the compact view **/
  compactViewInfo = {
    displayType: DisplayType.ServiceStatus,
  };

  constructor(public _elementRef: ElementRef) {
    super();
  }

  ngOnChanges({ monitoringData }: SimpleChanges) {
    if (monitoringData && monitoringData.currentValue) {
      this.servicesDataSource = this._toServicesDataSource();
    }
  }

  /** @ignore Converts the monitoring values to services datasource **/
  private _toServicesDataSource(): IServiceDataSourceItem[] {
    return Object.keys(this.monitoringData).reduce((dataSource, service) => {
      // create an entry for each service (even if it has no data)
      const serviceData = this.monitoringData[service] as CountAggregationData<ServiceStatusCode, number>;
      return [
        ...dataSource,
        {
          key: service,
          name: `siteMonitoringSharedScope.SITE_MONITORING.COLUMNS.MONITORED_VALUES.${service}.TITLE_20`,
          statuses: this._hasDataForAtLeastOneStatus(serviceData)
            ? Object.entries(serviceData)
              .sort(this._sortByStatusUpFirst)
              .map(([serviceStatusCode, deviceCount]) => ({
                tooltip: `deviceFields.DEVICE.ENUM.SERVICE_STATUS_CODE.${serviceStatusCode}`,
                count: deviceCount,
                theme: ServiceStatusThemeMap[serviceStatusCode],
              }))
            : [],
        },
      ];
    }, []);
  }

  /** @ignore Sorts statuses codes showing: Up first, then Down **/
  private _sortByStatusUpFirst([statusCode1, _], [statusCode2, __]): number {
    return Number(statusCode1) < Number(statusCode2) ? 1 : -1;
  }

  /** Returns the grid template style for the services container **/
  get servicesContainerGridTemplateColumns(): string {
    return `repeat(${this.servicesDataSource.length}, 1fr)`;
  }

  /** @ignore Indicates if the service has data for at least one status **/
  private _hasDataForAtLeastOneStatus(serviceData: CountAggregationData<ServiceStatusCode, number>) {
    return !!serviceData && Object.values(serviceData).some((statusCount) => statusCount > 0);
  }

  /**
   * A function that indicates if the service data is empty.
   * Overrides from mixin
   **/
  emptyMonitoringDataFn(): boolean {
    if (super.emptyMonitoringDataFn()) {
      return true;
    }
    const hasDataForAtLeastOneService = this.config.customConfig.services.some((service) => !!this.monitoringData[service] && Object.keys(this.monitoringData[service]).length > 0);
    return !hasDataForAtLeastOneService;
  }

  // return color with priority order: danger > warning > ok > undeachable > not monitored
  dataDisplayTypeCode(): string {
    let color: string;
    this.servicesDataSource
      .filter((servicesDataSource) => servicesDataSource.statuses.length)
      .map((servicesDataSources) => servicesDataSources.statuses.map((statuse) => statuse.theme))
      .forEach((colors) => {
        color =
          colors.includes(ThemeType.DANGER) || color === ThemeType.DANGER
            ? ThemeType.DANGER
            : colors.includes(ThemeType.WARNING) || color === ThemeType.WARNING
            ? ThemeType.WARNING
            : colors.includes(ThemeType.SUCCESS) || color === ThemeType.SUCCESS
            ? ThemeType.SUCCESS
            : colors.includes(ThemeType.SECONDARY) || color === ThemeType.SECONDARY
            ? ThemeType.SECONDARY
            : ThemeType.INFO;
      });

    return color || null;
  }
}
