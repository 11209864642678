import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, Output, TemplateRef } from '@angular/core';

import { AggregationType } from '../aggregation-type.enum';
import { DisplayType } from '../display-type.enum';
import { IColumnPickerItem } from '../column-picker-item.interface';

/**
 * This component allow to change the display type / aggregation type of a column
 * *
 */
@Component({
  selector: 'amp-column-picker-options-selector',
  templateUrl: 'column-picker-options-selector.component.html',
  styleUrls: ['column-picker-options-selector.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ColumnPickerOptionsSelectorComponent {
  /** The edited item **/
  @Input() item: IColumnPickerItem;

  /** A template to render the display type content **/
  @Input() displayTypeTemplate: TemplateRef<any>;

  /** A template to render the aggregation type content **/
  @Input() aggregationTypeTemplate: TemplateRef<any>;

  /** current picking mode **/
  pickingMode: 'display' | 'aggregation';

  /** emits when the options are changed **/
  @Output() updated = new EventEmitter<IColumnPickerItem>();

  /** emits when the options are being edited. emits null when not edited anymore **/
  @Output() edited = new EventEmitter<IColumnPickerItem>();

  constructor(private _changeDetector: ChangeDetectorRef) {}

  get isDisplayEnabled() {
    return this.item?.columnDefinition.displayTypes?.length > 0;
  }
  get isDisplayTypeEditable() {
    return this.item?.columnDefinition.displayTypes?.length > 1;
  }

  get isAggregationEnabled() {
    return this.item?.columnDefinition.aggregationTypes?.length > 0;
  }
  get isAggregationTypeEditable() {
    return this.item?.columnDefinition.aggregationTypes?.length > 1;
  }

  showDisplayTypePicker() {
    this.pickingMode = 'display';
    this.edited.emit(this.item);
  }

  showAggregationTypePicker() {
    this.pickingMode = 'aggregation';
    this.edited.emit(this.item);
  }

  selectDisplayType(displayType: DisplayType) {
    this.pickingMode = null;
    this.item = {
      ...this.item,
      selectedDisplayType: displayType,
    };
    this.updated.emit(this.item);
    this.edited.emit(null);
  }

  selectAggregationType(aggregationType: AggregationType) {
    this.pickingMode = null;
    this.item = {
      ...this.item,
      selectedAggregationType: aggregationType,
    };
    this.updated.emit(this.item);
    this.edited.emit(null);
  }

  /** Closes any opened picker **/
  closeOptions() {
    this.pickingMode = null;
    this._changeDetector.detectChanges();
  }
}
