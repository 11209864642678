<avn-tabs class="site-properties-tabs" [justify]="'fill'" [avnTheme]="'primary'" (tabChange)="onTabChange($event.activeTab.id)">
  <avn-tab [id]="'info'" [active]="(selectedTabId$ | async) === 'info'" [label]="'siteManagementScope.SITE_MANAGEMENT.SITE_DETAIL.SITE_PROPERTIES_EDITOR.INFO.TITLE_35' | transloco"> </avn-tab>
  <avn-tab [id]="'tags'" [active]="(selectedTabId$ | async) === 'tags'" [label]="'siteManagementScope.SITE_MANAGEMENT.SITE_DETAIL.SITE_PROPERTIES_EDITOR.TAGS.TITLE_35' | transloco"> </avn-tab>
  <avn-tab [id]="'managers'" [active]="(selectedTabId$ | async) === 'managers'" [label]="'siteManagementScope.SITE_MANAGEMENT.SITE_DETAIL.SITE_PROPERTIES_EDITOR.MANAGERS.TITLE_35' | transloco">
  </avn-tab>
</avn-tabs>

<div class="site-properties-body" avnScroll>
  <router-outlet></router-outlet>
</div>
