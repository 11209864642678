import { createAction, props } from '@ngrx/store';
import { DeviceDTO, DisplayDTO, SiteDTO } from '@activia/cm-api';
import { ISiteManagementConfigSchema, ISiteManagementConfigurationSchema } from '@amp/environment';
import { IBoardCreationTemplate, ICreateTemplateBoardsSuccessEvent, IExperienceTemplate, IExperienceTemplateInfo } from '../models/experience-template.interface';
import { AsyncDataState, ISort } from '@activia/ngx-components';
import { IBoard } from '../models/board-config.interface';
import { IEngineTagValue } from '@amp/tag-operation';

/** Fetch site config actions */
export const FetchSiteConfig = createAction('[Site Management] Fetch Site Config');
export const FetchSiteConfigSuccess = createAction(
  '[Site Management] Fetch Site Config Success',
  props<{
    siteConfig: ISiteManagementConfigurationSchema;
  }>()
);

/** Fetch all sites */
export const FetchAllSites = createAction('[Site Management] Fetch All Sites');
export const FetchAllSitesSuccess = createAction(
  '[Site Management] Fetch All Sites Success',
  props<{
    sites: SiteDTO[];
  }>()
);
export const FetchAllSitesFail = createAction('[Site Management] Fetch All Sites Fail', props<{ error: any }>());

/** Set sort by field and direction of the sites */
export const UpdateSiteListSort = createAction('[Site Management] Update Sites Sort Field', props<{ sortBy: ISort }>());

/** Delete some sites */
export const DeleteSites = createAction('[Site Management] Delete Sites', props<{ siteIds: number[] }>());
export const DeleteSiteSuccess = createAction('[Site Management] Delete Site Success', props<{ siteId: number }>());
export const ClearDeletedSites = createAction('[Site Management] Clear Deleted Sites');

/** Fetch the current site along with its boards and devices and update it in the site list */
export const FetchSiteDetail = createAction('[Site Management] Fetch Site Detail', props<{ siteId: number }>());
export const FetchSiteDetailSuccess = createAction(
  '[Site Management] Fetch Site Detail Success',
  props<{
    site: SiteDTO;
    boards: IBoard[];
    displays: DisplayDTO[];
    devices: DeviceDTO[];
  }>()
);
export const FetchSiteDetailFail = createAction('[Site Management] Fetch Site Detail Fail', props<{ error: any }>());

/** Create new boards from the specified experience template in specified site. In case of provisioning, info of devices to created will be provided. Otherwise default devices will be created */
export const CreateTemplateBoards = createAction(
  '[Site Management] Create Template Boards',
  props<{
    site: SiteDTO;
    expTemplate: IExperienceTemplate;
    boards?: IBoardCreationTemplate[];
  }>()
);
export const CreateTemplateBoardsSuccess = createAction('[Site Management] Create Template Boards Success', props<ICreateTemplateBoardsSuccessEvent>());
export const CreateTemplateBoardsFail = createAction(
  '[Site Management] Create Template Boards Fail',
  props<{
    error: any;
    siteId: number;
  }>()
);
export const CreateTemplateBoardsUpdateStatus = createAction(
  '[Site Management] Create Template Boards Update Status',
  props<{
    stepId: string;
    loadingState: AsyncDataState;
  }>()
);

/** Refresh a site and update it in the site list */
export const RefreshSiteSuccess = createAction('[Site Management] Refresh Site Success', props<{ site: SiteDTO }>());

/** Manually create a site success */
export const CreateSiteSuccess = createAction(
  '[Site Management] Create Site Success',
  props<{
    site: SiteDTO;
    experiencesSelected: IExperienceTemplateInfo;
  }>()
);

/** Update a site */
export const UpdateSite = createAction('[Site Management] Update Site', props<{ site: SiteDTO; tags?: Record<string, IEngineTagValue>; }>());
export const UpdateSiteSuccess = createAction('[Site Management] Update Site Success', props<{ site: SiteDTO }>());

export const UpdatePreferences = createAction(
  '[Site Management] Update Preferences',
  props<{
    preferences: ISiteManagementConfigSchema;
  }>()
);
export const UpdatePreferencesSuccess = createAction(
  '[Site Management] Update Preferences Success',
  props<{
    preferences: ISiteManagementConfigSchema;
  }>()
);
export const UpdatePreferencesFail = createAction('[Site Management] Update Preference Fail', props<{ error: any }>());

export const UpdateSelectedSites = createAction(
  '[Site Management] Update Selected Sites',
  props<{
    selectedSiteIds: string[];
  }>()
);

export const PushChangesToPlayers = createAction(
  '[Site Management] Push changes to player',
  props<{
    currentSiteOnly: boolean;
  }>()
);

export const UnloadSelectedSites = createAction('[Site Management] Unload Selected Sites');
