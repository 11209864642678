import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { ISiteSyncState, SiteSyncStatus } from '../../store/site-sync/site-sync.model';
import { GeodeticCoordinates, SiteDTO } from '@activia/cm-api';
import { IGeoResult } from '@activia/geo';
import { TranslocoService } from '@ngneat/transloco';
import { Store } from '@ngrx/store';
import {
  SiteSyncFixGeodeticCoordinates, SiteSyncFixMissingRequiredValue,
  SiteSyncFixSiteAddress,
  SiteSyncResumeCsvSiteProcessing
} from '../../store/site-sync/site-sync.actions';
import { IOptionData, ModalService, ModalType } from '@activia/ngx-components';
import { ISiteGeoResponse } from '../../store/site-sync/utils/site-sync-geo.utils';
import { SiteManagementSiteInfoEditorModalComponent } from '../site-management-site-info-editor-modal/site-management-site-info-editor-modal.component';
import { Overlay } from '@angular/cdk/overlay';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'amp-site-sync-action',
  templateUrl: './site-sync-action.component.html',
  styleUrls: ['./site-sync-action.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SiteSyncActionComponent implements OnChanges {
  /** info about progress of each step of the sync process status. site must be valid. **/
  @Input() siteImportState: ISiteSyncState;

  overlayOpened: boolean;

  syncStatus: SiteSyncStatus;
  isGeoIssue: boolean;
  missingRequiredValue: boolean;

  constructor(private _translateService: TranslocoService, private _store: Store, private _overlay: Overlay, private _modalService: ModalService) {}

  ngOnChanges({ siteImportState }: SimpleChanges): void {
    if (siteImportState) {
      this.syncStatus = this.siteImportState.syncProgress?.status;
      this.isGeoIssue = this.siteImportState.syncProgress?.stepId === 'validate-geo';
      this.missingRequiredValue = this.siteImportState.syncProgress?.stepId === 'validate-required-values';
    }
  }

  fixMultipleAddressesFound(selectedGeoResult: IGeoResult, siteImportState: ISiteSyncState) {
    const keepCsvValue = selectedGeoResult === null;
    this._store.dispatch(SiteSyncFixSiteAddress({ siteUid: siteImportState.uid, addressGeoResult: selectedGeoResult, keepCsvValue }));
  }

  fixMismatchAddress(selectedGeoResult: IGeoResult, siteImportState: ISiteSyncState) {
    const keepCsvValue = selectedGeoResult === null;
    this._store.dispatch(SiteSyncFixSiteAddress({ siteUid: siteImportState.uid, addressGeoResult: selectedGeoResult, keepCsvValue }));
  }

  fixSiteGeoCoordinates(selectedLocation: IGeoResult, row: ISiteSyncState) {
    const keepCsvValue = selectedLocation === null;
    const geoCoordinates = selectedLocation?.location;
    this._store.dispatch(SiteSyncFixGeodeticCoordinates({ siteUid: row.uid, geodeticCoordinates: geoCoordinates, keepCsvValue }));
  }

  toFormattedSiteAddress(site: SiteDTO): string {
    return [site.address.addressLine1, site.address.addressLine2].filter((part) => !!part).join(', ');
  }

  toFormattedAddresses(geoResults: IGeoResult[]): string[] {
    return geoResults.map((geoResult) => this.toFormattedAddress(geoResult));
  }

  toFormattedAddress(geoResult: IGeoResult): string {
    return geoResult.formattedAddress;
  }

  toFormattedGeoCoordinates(coordinates: GeodeticCoordinates): string {
    /* eslint-disable max-len */
    return `${this._translateService.translate('siteManagementScope.SITE_MANAGEMENT.SITE_PROPERTIES.LONGITUDE.TITLE_50').toLowerCase()}: ${coordinates?.longitude} ${this._translateService
      .translate('siteManagementScope.SITE_MANAGEMENT.SITE_PROPERTIES.LATITUDE.TITLE_50')
      .toLowerCase()}: ${coordinates?.latitude}`;
  }

  toGeoResultOptions(geoResults: IGeoResult[], label: 'address' | 'geoCoordinates'): IOptionData<IGeoResult>[] {
    return geoResults.map((geoResult) => ({
      value: geoResult.resultId,
      label: label === 'address' ? geoResult.formattedAddress : this.toFormattedGeoCoordinates(geoResult.location),
      data: geoResult,
    }));
  }

  resume(siteUid: string) {
    this._store.dispatch(SiteSyncResumeCsvSiteProcessing({ siteUid }));
  }

  openSiteEditor(siteImportState: ISiteSyncState) {
    const modalRef = this._modalService.open(
      SiteManagementSiteInfoEditorModalComponent,
      {
        closeOnBackdropClick: true,
        showCloseIcon: true,
        data: { site: siteImportState.site, validateFormOnInitialLoad: true },
      },
      {
        width: '600px',
        panelClass: 'overlay-panel-class',
        positionStrategy: this._overlay.position().global().centerHorizontally().top('50px'),
      },
      ModalType.Dialog
    );
    modalRef.componentInstance.saved.pipe(takeUntil(modalRef.afterClosed)).subscribe((siteDTO) => {
      this._store.dispatch(SiteSyncFixMissingRequiredValue({ siteUid: siteImportState.uid, site: siteDTO }));
    });
  }

  get errorCode(): string {
    return this.siteImportState.syncProgress?.errorInfo?.code;
  }

  get errorData(): ISiteGeoResponse | any {
    return this.siteImportState.syncProgress?.errorInfo?.data;
  }
}
