<ng-container *ngIf="selectedSites$ | async as sites">
  <div *ngIf="(selectedSites$ | async)?.length > 1" class="preview-site">
    <avn-select
      [label]="'tagOperation.TAG_OPERATION.TAG_VALUE_DETAIL.FILTER.PREVIEW_SITE_50' | transloco"
      [options]="sites | applyFunction: parseSiteOptions:[]:this"
      [value]="selectedSiteId$ | async"
      (change)="onChangeSite($event)"
    ></avn-select>
    <span *ngIf="isAssignableMode">{{
      'tagOperation.TAG_OPERATION.TAG_VALUE_DETAIL.FILTER.PREVIEW_SITE_SUBTITLE_50'
        | transloco: { sites: sites?.length, label: selectedSiteId$ | async | applyFunction: getSelectedSiteLabel:[sites]:this }
    }}</span>
  </div>
</ng-container>
