<ng-container
  *avnAsyncData="monitoringData; state: monitoringDataDataState; emptyTemplate: showCompactView ? null : emptyTemplate; loadingTemplate: loadingTemplate; emptyFn: emptyMonitoringDataFn.bind(this)"
>
  <ng-container *ngIf="!showCompactView; else compactViewTemplate">
    <avn-count-card
      [title]="'siteMonitoringSharedScope.SITE_MONITORING.COLUMNS.KEY_METRICS.' + config.id + '.TITLE_20' | transloco"
      [theme]="countInfo.theme"
      [style.height.px]="cardDimensions"
      [style.width.px]="cardDimensions"
      [value]="countInfo.count"
      [footerText]="null"
      [uom]="countInfo.label | transloco"
      [footerTemplate]="dateTemplate"
      [valueSize]="CountCardValueSize.lg"
    >
    </avn-count-card>
  </ng-container>

  <ng-template #compactViewTemplate>
    <amp-key-metrics-accordion
      class="key-metrics-content"
      [keyMetricsAccordionBorderColor]="dataDisplayTypeCode()"
      [keyMetricsAccordionHeader]="'siteMonitoringSharedScope.SITE_MONITORING.COLUMNS.KEY_METRICS.' + config.id + '.TITLE_20' | transloco"
      [keyMetricsAccordionContent]="accordionTemplate"
    ></amp-key-metrics-accordion>
  </ng-template>
</ng-container>

<ng-template #accordionTemplate>
  <div class="accordion-content">
    <div class="item inline">
      <span>{{ 'siteMonitoringSharedScope.SITE_MONITORING.COLUMNS.KEY_METRICS.HEALTH_STATUS_SUMMARY.LAST_UPDATE_20' | transloco }}:</span>
      <amp-site-field-templates
        [field]="cardConfig.date.field"
        [displayType]="cardConfig.date.displayType"
        [aggregationType]="cardConfig.date.aggregationType"
        [value]="monitoringDate"
        [showEmptyPlaceholder]="true"
      ></amp-site-field-templates>
    </div>
    <div class="item right">
      <amp-site-field-templates
        [field]="cardConfig.data.field"
        [displayType]="cardConfig.data.displayType"
        [optimisticViewEnabled]="optimisticViewEnabled"
        [value]="monitoringData"
        [showEmptyPlaceholder]="true"
      ></amp-site-field-templates>
    </div>
  </div>
</ng-template>

<ng-template #dateTemplate>
  <div class="date-container" *ngIf="hasDate">
    <span>{{ 'siteMonitoringSharedScope.SITE_MONITORING.COLUMNS.KEY_METRICS.' + config.id + '.LAST_UPDATE_20' | transloco }}:</span>
    <amp-site-field-templates
      [field]="cardConfig.date.field"
      [value]="monitoringDate"
      [displayType]="cardConfig.date.displayType"
      [showEmptyPlaceholder]="true"
    ></amp-site-field-templates>
  </div>
</ng-template>

<ng-template #emptyTemplate>
  <ng-container *ngIf="!showCompactView">
    <avn-skeleton
      data-e2e="empty"
      [style.height.px]="cardDimensions"
      [style.width.px]="cardDimensions"
      [animate]="false"
      [text]="'siteMonitoringSharedScope.SITE_MONITORING.COLUMNS.KEY_METRICS.' + config.id + '.NOT_AVAILABLE_125' | transloco"
      [skeletonType]="'CUSTOM_SVG'"
    >
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 80 80"></svg>
    </avn-skeleton>
  </ng-container>
</ng-template>

<ng-template #loadingTemplate>
  <div class="key-metric-loading-container">
    <h6 class="title">{{ 'siteMonitoringSharedScope.SITE_MONITORING.KEY_METRICS.' + config.id + '.TITLE_35' | transloco }}</h6>
    <avn-skeleton
      data-e2e="loading"
      [animate]="true"
      [animateDirection]="'VERTICAL'"
      [text]="'siteMonitoringSharedScope.SITE_MONITORING.KEY_METRICS.KEY_METRIC_DATA_LOADING_TEXT_50' | transloco"
      [skeletonType]="'CARD'"
    >
    </avn-skeleton>
  </div>
</ng-template>
