import { Injectable } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot, Data, Params, Router } from '@angular/router';

@Injectable({ providedIn: 'root' })
export class RouterService {
  constructor(private route: ActivatedRoute, private router: Router) {}

  /**
   * Get the router state on the current navigation.
   *
   * @param (Optional) route snapshot. This param MUST BE provided only in guards.
   * @returns RouterState
   */
  getRouterState(route?: ActivatedRouteSnapshot) {
    const currentRoute = route || this.route.snapshot;

    return {
      url: currentRoute.url,
      params: this.getRouteParams(currentRoute.root, (r) => r.params),
      queryParams: this.getRouteParams(currentRoute.root, (r) => r.queryParams),
      data: this.getRouteData(currentRoute.root),
      extras: this.router.getCurrentNavigation()?.extras || {},
    };
  }

  getRouteParams(route: ActivatedRouteSnapshot, getter: (r: ActivatedRouteSnapshot) => Params): Params {
    if (!route) {
      return {};
    }
    const currentParams = getter(route);
    const primaryChild = route.children.find((c) => c.outlet === 'primary') || route.firstChild;
    return { ...currentParams, ...this.getRouteParams(primaryChild, getter) };
  }

  getRouteData(route: ActivatedRouteSnapshot): Data {
    if (!route) {
      return {};
    }

    const currentData = route.data;
    const primaryChild = route.children.find((c) => c.outlet === 'primary') || route.firstChild;
    return { ...currentData, ...this.getRouteData(primaryChild) };
  }
}
