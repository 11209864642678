<form [formGroup]="form">
  <!--Site org path form -->
  <ng-container *avnAsyncData="''; state: siteOrgPathDefState$ | async; loaderType: 'linear'; emptyTemplate: errorTemplate; errorTemplate: errorTemplate">
    <ng-container *ngIf="hasOrgPath; else noOrgPathForm">
      <!-- Organizational Path -->
      <form formGroupName="tags">
        <ng-container>
          <amp-orgpath-form-group
            [tagDefinition]="tagDefinition$ | async"
            [nodeDefinition]="siteOrgPathDef$ | async"
            [editable]="true"
            [controlAsyncValidatorMap]="controlAsyncValidatorMap"
            (formChanged)="tagFormValueChanged()"
          >
          </amp-orgpath-form-group>
        </ng-container>
      </form>
      <avn-form-field>
        <ng-container *ngIf="form.controls?.tags?.value | applyFunction : orgPathErrors : [] : this as errors">
          <avn-form-field-error
            key="duplicate"
            [message]="'siteManagementScope.SITE_MANAGEMENT.SITE_PROPERTIES.NAME.VALIDATION.DUPLICATE_100' | transloco"
            *ngIf="errors['duplicate']"
            class="warning-message"
            [style.display]="'inherit'"
          >
            {{ 'siteManagementScope.SITE_MANAGEMENT.SITE_PROPERTIES.NAME.VALIDATION.DUPLICATE_100' | transloco }}
          </avn-form-field-error>
        </ng-container>
      </avn-form-field>
    </ng-container>
  </ng-container>

  <!-- Site Name Input -->
  <ng-template #noOrgPathForm>
    <avn-form-field *ngIf="form.controls.name">
      <avn-input formControlName="name" [label]="siteMonitoringFacade.getColumnLabel(SiteProperties.Name) | async"> </avn-input>

      <avn-form-field-hint
        [style.display]="['WARNING', 'PENDING'].includes(nameControlStatus$ | async) ? 'none' : 'inherit'"
        [message]="'siteManagementScope.SITE_MANAGEMENT.SITE_PROPERTIES.NAME.VALIDATION.HINT_100' | transloco"
      ></avn-form-field-hint>
      <avn-form-field-error key="required" [message]="'siteManagementScope.SITE_MANAGEMENT.SITE_PROPERTIES.NAME.VALIDATION.REQUIRED_150' | transloco"></avn-form-field-error>
      <avn-form-field-error key="minlength" [message]="'siteManagementScope.SITE_MANAGEMENT.SITE_PROPERTIES.NAME.VALIDATION.MIN_LENGTH_50' | transloco : { number: MIN_CHAR }"></avn-form-field-error>

      <div class="loading-icon" *ngIf="(nameControlStatus$ | async) === 'PENDING'">
        <avn-icon class="icon" [icon]="'action:autorenew'" [fontSize]="14" [rotate]="true"></avn-icon>
      </div>
      <span class="warning-message" [style.display]="(nameControlStatus$ | async) === 'WARNING' ? 'inherit' : 'none'">
        {{ 'siteManagementScope.SITE_MANAGEMENT.SITE_PROPERTIES.NAME.VALIDATION.DUPLICATE_100' | transloco }}
      </span>
    </avn-form-field>
  </ng-template>

  <avn-form-field>
    <avn-input
      #externalId
      formControlName="externalId"
      [label]="siteMonitoringFacade.getColumnLabel(SiteProperties.ExternalId) | async"
      [placeholder]="'siteManagementScope.SITE_MANAGEMENT.SITE_PROPERTIES.EXTERNAL_ID.PLACEHOLDER_100' | transloco"
    >
    </avn-input>
    <avn-form-field-error key="duplicate" [message]="'siteManagementScope.SITE_MANAGEMENT.SITE_PROPERTIES.EXTERNAL_ID.VALIDATION.DUPLICATE_100' | transloco"></avn-form-field-error>
    <avn-form-field-hint [message]="'siteManagementScope.SITE_MANAGEMENT.SITE_PROPERTIES.EXTERNAL_ID.VALIDATION.HINT_100' | transloco"></avn-form-field-hint>
  </avn-form-field>
</form>

<ng-template #errorTemplate>
  <div class="no-data">
    <avn-empty-data-message
      icon="alert:error"
      [text]="'siteManagementScope.SITE_MANAGEMENT.BOARD_EDITOR.ERRORS.ORG_PATH.MISSING_DEFINITION_DESCRIPTION_100' | transloco"
      [subText]="'siteManagementScope.SITE_MANAGEMENT.BOARD_EDITOR.ERRORS.ORG_PATH.MISSING_DEFINITION_DESCRIPTION_100' | transloco"
    >
    </avn-empty-data-message>
  </div>
</ng-template>
