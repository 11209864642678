<avn-panel>
  <avn-panel-body>
    <ng-container *transloco="let t; read: 'siteManagementScope.SITE_MANAGEMENT.SITE_DETAIL.SITE_BOARDS_EDITOR'">
      <amp-screen-selector (scopeChange)="selectBoardOrScreen($event)"></amp-screen-selector>
      <ng-container *ngIf="hasBoard$ | async; else noSelectedBoard">
        <ng-container *ngIf="hasScreen$ | async; else noSelectedScreen">
          <div class="tags" avnScroll>
            <router-outlet></router-outlet>
          </div>
        </ng-container>
      </ng-container>

      <ng-template #noSelectedScreen>
        <avn-empty-data-message icon="communication:desktop_windows" [text]="t('NO_SCREEN_SELECTED_TITLE_50')" [subText]="t('NO_SCREEN_SELECTED_MESSAGE_50')"> </avn-empty-data-message>
      </ng-template>

      <ng-template #noSelectedBoard>
        <avn-empty-data-message icon="content:web_stories" [text]="t('NO_BOARD_SELECTED_TITLE_50')" [subText]="t('NO_BOARD_SELECTED_FOR_SCREENS_MESSAGE_50')"> </avn-empty-data-message>
      </ng-template>
    </ng-container>
  </avn-panel-body>
</avn-panel>
