import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { ISiteSyncProgress, SiteSyncStepId } from '../../store/site-sync/site-sync.model';
import { hasAsyncDataError, LoadingState, ThemeType } from '@activia/ngx-components';
import { TranslocoService } from '@ngneat/transloco';
import { IGeoResult } from '@activia/geo';
import { SiteSyncStatusIcons, SiteSyncStatusThemes } from '../../models/site-management-sync';
import { getCreateBoardMessage } from '../../utils/experience-template-creation-message.utils';

@Component({
  selector: 'amp-site-sync-status',
  templateUrl: './site-sync-status.component.html',
  styleUrls: ['./site-sync-status.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SiteSyncStatusComponent implements OnChanges {
  /** info about progress of each step of the sync process status. site must be valid. **/
  @Input() syncProgress: ISiteSyncProgress;

  showStatusIcon: boolean;
  icon: string;
  iconTheme: ThemeType;
  message: string;
  percentCompletion: number;

  constructor(private _translateService: TranslocoService) {}

  ngOnChanges({ syncProgress }: SimpleChanges): void {
    if (syncProgress) {
      const syncProgressValue: ISiteSyncProgress = syncProgress.currentValue;
      const { status } = syncProgressValue;
      this.icon = SiteSyncStatusIcons[status];
      this.iconTheme = SiteSyncStatusThemes[status];
      this.message = this.getMessage(syncProgressValue);
      this.percentCompletion = syncProgressValue.progress;
      this.showStatusIcon = !!this.icon && !['processing', 'processed'].includes(status);
    }
  }

  getMessage(syncProgress: ISiteSyncProgress) {
    if (syncProgress.status === 'processed') {
      return this._translateService.translate('siteManagementScope.SITE_MANAGEMENT.SYNC_SITES.SYNC_MESSAGES.COMPLETED_20');
    }
    if (syncProgress.status === 'queued') {
      return this._translateService.translate('siteManagementScope.SITE_MANAGEMENT.SYNC_SITES.SYNC_MESSAGES.QUEUED_20');
    }
    const stepId = syncProgress.stepId.split('|')[0] as SiteSyncStepId;
    if (stepId === 'validate-required-values') {
      return this.getValidateRequiredValuesMessage(syncProgress);
    }
    if (stepId === 'validate-geo') {
      return this.getValidateGeoMessage(syncProgress);
    }
    if (stepId === 'create-site') {
      return this.getAddSiteMessage(syncProgress);
    }
    if (stepId === 'update-site') {
      return this.getUpdateSiteMessage(syncProgress);
    }
    if (stepId === 'refresh-site-info') {
      return this._translateService.translate('siteManagementScope.SITE_MANAGEMENT.SYNC_SITES.SYNC_MESSAGES.REFRESHING_SITE_INFO_50');
    }

    return getCreateBoardMessage(syncProgress.stepId, syncProgress.loadingState, this._translateService);
  }

  getValidateRequiredValuesMessage(syncProgress: ISiteSyncProgress) {
    if (syncProgress.loadingState === LoadingState.LOADING) {
      return this._translateService.translate('siteManagementScope.SITE_MANAGEMENT.SYNC_SITES.SYNC_MESSAGES.VALIDATE_REQUIRED_VALUES.LOADING_50');
    }
    if (syncProgress.loadingState === LoadingState.LOADED) {
      return this._translateService.translate('siteManagementScope.SITE_MANAGEMENT.SYNC_SITES.SYNC_MESSAGES.VALIDATE_REQUIRED_VALUES.LOADED_50');
    }
    if (hasAsyncDataError(syncProgress.loadingState)) {
      const errorData = syncProgress.errorInfo?.data;
      return this._translateService.translate('siteManagementScope.SITE_MANAGEMENT.SYNC_SITES.SYNC_MESSAGES.VALIDATE_REQUIRED_VALUES.ERRORS.MISSING_REQUIRED_VALUE', {
        fields: errorData.dataValidationResponse.fields.join(', ')
      });
    }
  }

  getValidateGeoMessage(syncProgress: ISiteSyncProgress) {
    if (syncProgress.loadingState === LoadingState.LOADING) {
      return this._translateService.translate('siteManagementScope.SITE_MANAGEMENT.SYNC_SITES.SYNC_MESSAGES.VALIDATE_GEO.LOADING_50');
    }
    if (syncProgress.loadingState === LoadingState.LOADED) {
      return this._translateService.translate('siteManagementScope.SITE_MANAGEMENT.SYNC_SITES.SYNC_MESSAGES.VALIDATE_GEO.LOADED_50');
    }
    if (hasAsyncDataError(syncProgress.loadingState)) {
      const errorCode = syncProgress.errorInfo?.code;
      const errorData = syncProgress.errorInfo?.data;

      if (errorCode === 'MISMATCH_GEODETIC_COORDINATE') {
        const { field, geoResult }: { field: 'longitude' | 'latitude'; geoResult: IGeoResult } = errorData.geoResponse.result;
        const valueFound = geoResult.location[field];
        return this._translateService.translate('siteManagementScope.SITE_MANAGEMENT.SYNC_SITES.SYNC_MESSAGES.VALIDATE_GEO.ERRORS.MISMATCH_GEODETIC_COORDINATE_200', {
          coordinate: this._translateService.translate(`siteManagementScope.SITE_MANAGEMENT.SITE_PROPERTIES.${field.toUpperCase()}.TITLE_50`).toLowerCase(),
          valueFound,
        });
      }
      if (errorCode === 'MISMATCH_ADDRESS') {
        const mismatchAddressGeoResult: IGeoResult = errorData.geoResponse.result;
        return this._translateService.translate('siteManagementScope.SITE_MANAGEMENT.SYNC_SITES.SYNC_MESSAGES.VALIDATE_GEO.ERRORS.MISMATCH_ADDRESS_200', {
          address: mismatchAddressGeoResult.formattedAddress,
        });
      }
      return this._translateService.translate(`siteManagementScope.SITE_MANAGEMENT.SYNC_SITES.SYNC_MESSAGES.VALIDATE_GEO.ERRORS.${errorCode}_100`);
    }
  }

  getAddSiteMessage(syncProgress: ISiteSyncProgress) {
    if (syncProgress.loadingState === LoadingState.LOADING) {
      return this._translateService.translate('siteManagementScope.SITE_MANAGEMENT.SYNC_SITES.SYNC_MESSAGES.CREATE_SITE.LOADING_50');
    }
    if (syncProgress.loadingState === LoadingState.LOADED) {
      return this._translateService.translate('siteManagementScope.SITE_MANAGEMENT.SYNC_SITES.SYNC_MESSAGES.CREATE_SITE.LOADED_50');
    }
    if (hasAsyncDataError(syncProgress.loadingState)) {
      return this._translateService.translate('siteManagementScope.SITE_MANAGEMENT.SYNC_SITES.SYNC_MESSAGES.CREATE_SITE.ERRORS.ADD_FAIL_100');
    }
  }

  getUpdateSiteMessage(syncProgress: ISiteSyncProgress) {
    if (syncProgress.loadingState === LoadingState.LOADING) {
      return this._translateService.translate('siteManagementScope.SITE_MANAGEMENT.SYNC_SITES.SYNC_MESSAGES.UPDATE_SITE.LOADING_50');
    }
    if (syncProgress.loadingState === LoadingState.LOADED) {
      return this._translateService.translate('siteManagementScope.SITE_MANAGEMENT.SYNC_SITES.SYNC_MESSAGES.UPDATE_SITE.LOADED_50');
    }
    if (hasAsyncDataError(syncProgress.loadingState)) {
      return this._translateService.translate('siteManagementScope.SITE_MANAGEMENT.SYNC_SITES.SYNC_MESSAGES.UPDATE_SITE.ERRORS.UPDATE_FAIL_100');
    }
  }
}
