import { AsyncData, LoadingState } from '@activia/ngx-components';
import { createReducer, on, Action } from '@ngrx/store';
import { IExperienceTemplate } from '../../models/experience-template.interface';

import * as ExperienceTemplateActions from './experience-template.actions';

export const EXPERIENCE_TEMPLATE_FEATURE_KEY = 'experience-template';

export interface IExperienceTemplateState {
  /** All experience templates from the database */
  experienceTemplates: AsyncData<Record<string, IExperienceTemplate>>;

  temporaryDeletedExperienceTemplate: IExperienceTemplate[];
}

export const initialExperienceTemplateState: IExperienceTemplateState = {
  experienceTemplates: {
    data: {},
    state: LoadingState.INIT,
  },

  temporaryDeletedExperienceTemplate: undefined,
};

const reducer = createReducer<IExperienceTemplateState>(
  initialExperienceTemplateState,

  //#region Fetch Experience Templates
  on(ExperienceTemplateActions.FetchExperienceTemplates, (state) => ({
    ...state,
    experienceTemplates: {
      ...state.experienceTemplates,
      state: LoadingState.LOADING,
    },
  })),

  on(ExperienceTemplateActions.FetchExperienceTemplatesSuccess, (state, action) => {
    // Transform list into map
    const newExperienceTemplates = action.experienceTemplates.reduce((acc, current) => {
      acc[current.label] = current;
      return acc;
    }, {});

    return {
      ...state,
      experienceTemplates: {
        data: newExperienceTemplates,
        state: LoadingState.LOADED,
      },
    };
  }),

  on(ExperienceTemplateActions.FetchExperienceTemplatesFail, (state, action) => ({
    ...state,
    experienceTemplates: {
      ...state.experienceTemplates,
      state: { errorMsg: action.errorMessage },
    },
  }))
  //#endregion
);

export const experienceTemplateReducer = (state: IExperienceTemplateState | undefined, action: Action): IExperienceTemplateState => reducer(state, action);
