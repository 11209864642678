<ng-container *transloco="let t; read: 'deviceFields.DEVICE.EXPORT_CSV.TASK_PANEL'">
  <ng-container *ngIf="(model$ | async) as model">

    <avn-task-panel-item [icon]="'content:download'" [iconText]="t('TITLE_50')">
      <div class="progress" avnTaskPanelItemFeedbackSlot>
        <avn-progressbar *ngIf="model.totalCount > 0" [showValue]="true" [showIcon]="false" [max]="model.totalCount" [finished]="model.completePercentage === 100">
          <avn-progress-segment [value]="model.successCount" avnTheme="info"></avn-progress-segment>
        </avn-progressbar>

        <div class="status-info-wrapper">
          <span>{{ t('COMPLETED_TEXT_50', { completed: model.successCount, total: model.totalCount }) }}</span>
          <div class="count-icons">
            <ng-container *ngIf="model.errorCount > 0">
              <ng-container *ngTemplateOutlet="iconCount; context: { icon: ExportDevicesTaskIcons['FAIL'], count: model.errorCount, theme: ExportDevicesTaskThemes['FAIL'] }"></ng-container>
            </ng-container>
            <ng-container *ngIf="model.successCount > 0">
              <ng-container *ngTemplateOutlet="iconCount; context: { icon: ExportDevicesTaskIcons['SUCCESS'], count: model.successCount, theme: ExportDevicesTaskThemes['SUCCESS'] }"></ng-container>
            </ng-container>
          </div>
        </div>
      </div>

      <avn-button
        avnTaskPanelItemActionSlot
        size="small"
        [avnTheme]="'primary'"
        [label]="t('VIEW_DETAIL_50')"
        (actioned)="showSummary()">
      </avn-button>

    </avn-task-panel-item>
  </ng-container>

  <ng-template #iconCount let-theme="theme" let-count="count" let-icon="icon">
    <div class="icon-count-wrapper">
      <avn-icon [fontSize]="15" [icon]="icon" [avnTheme]="theme"></avn-icon>
      <span>{{ count }}</span>
    </div>
  </ng-template>
</ng-container>
