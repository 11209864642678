<form [formGroup]="form">
  <avn-form-field *ngIf="showDeviceGroupInput">
    <avn-autocomplete
      formControlName="deviceGroup"
      [label]="'deviceFields.DEVICE.SEARCH_DEVICES.DEVICE_GROUP.TITLE_50' | transloco"
      [emptyPlaceholder]="'deviceFields.DEVICE.SEARCH_DEVICES.DEVICE_GROUP.EMPTY_PLACEHOLDER_100' | transloco"
      [minChars]="3"
      [datasource]="deviceGroupDatasource"
      [placeholder]="'deviceFields.DEVICE.SEARCH_DEVICES.DEVICE_GROUP.PLACEHOLDER_100' | transloco"
      [selectedOption]="deviceGroup | toSelectOption: 'id':'name'"
    >
    </avn-autocomplete>
    <avn-form-field-error key="required" [message]="'deviceFields.DEVICE.SEARCH_DEVICES.DEVICE_GROUP.VALIDATION.REQUIRED_100' | transloco"> </avn-form-field-error>
    <avn-form-field-hint [message]="'deviceFields.DEVICE.SEARCH_DEVICES.DEVICE_GROUP.HINT_100' | transloco"></avn-form-field-hint>
  </avn-form-field>

  <avn-form-field>
    <avn-nlp-expression-input
      formControlName="filterExpression"
      [label]="searchQueryLabel ? searchQueryLabel : ''"
      [nlpParser]="nlpParser"
      [debounceTimeMs]="300"
      [placeholder]="'deviceFields.DEVICE.SEARCH_DEVICES.FILTER.PLACEHOLDER_100' | transloco"
      [connectedOverlayMinWidth]="350"
      [showSearchButton]="false"
      [multiline]="true"
      [customErrorFn]="deviceNlpCustomTagError"
    >
      <amp-nlp-date-picker [tokenType]="DateField" *avnNlpSuggestionOverlay="DateField; valueTypes: [[NumericValue, TimeUnit], [DateValue]]"></amp-nlp-date-picker>
      <amp-nlp-date-picker [tokenType]="EncFantime" *avnNlpSuggestionOverlay="EncFantime; valueTypes: [[NumericValue, TimeUnit]]"></amp-nlp-date-picker>
      <amp-nlp-numeric-range-picker
        [title]="'NLP.LABEL.PICK_PERCENTAGE' | transloco"
        [tokenType]="PercentageField"
        *avnNlpSuggestionOverlay="PercentageField; valueTypes: [[NumericValue]]"
      ></amp-nlp-numeric-range-picker>
      <amp-nlp-numeric-range-picker
        [title]="'deviceFields.DEVICE.ENCLOSURE.CONF_ENCLOSURE_FANS.NAME' | transloco"
        [tokenType]="EncFanCount"
        [min]="1"
        [max]="18"
        *avnNlpSuggestionOverlay="EncFanCount; valueTypes: [[NumericValue]]"
      ></amp-nlp-numeric-range-picker>
      <amp-nlp-numeric-range-picker
        [title]="'NLP.CUSTOM_UNIT.POWER' | transloco"
        [tokenType]="EncPwm0"
        [min]="0"
        [max]="255"
        *avnNlpSuggestionOverlay="EncPwm0; valueTypes: [[NumericValue]]"
      ></amp-nlp-numeric-range-picker>
      <amp-nlp-numeric-range-picker
        [title]="'NLP.CUSTOM_UNIT.POWER' | transloco"
        [tokenType]="EncPwm1"
        [min]="0"
        [max]="255"
        *avnNlpSuggestionOverlay="EncPwm1; valueTypes: [[NumericValue]]"
      ></amp-nlp-numeric-range-picker>
      <amp-nlp-numeric-value-picker [tokenType]="NumericField" *avnNlpSuggestionOverlay="NumericField; valueTypes: [[NumericValue]]"></amp-nlp-numeric-value-picker>
      <ng-container *ampHasFeature="'nlp.siteManager'">
        <amp-nlp-manager-picker [tokenType]="SiteManager" *avnNlpSuggestionOverlay="SiteManager; valueTypes: [[NumericValue]]"></amp-nlp-manager-picker>
      </ng-container>
    </avn-nlp-expression-input>
    <avn-form-field-error key="customError"></avn-form-field-error>
    <avn-form-field-error key="expressionError" [message]="'deviceFields.DEVICE.SEARCH_DEVICES.FILTER.INVALID_100' | transloco"></avn-form-field-error>
    <avn-form-field-hint *ngIf="showSearchQueryHint" [message]="'deviceFields.DEVICE.SEARCH_DEVICES.FILTER.HINT_100' | transloco"></avn-form-field-hint>
  </avn-form-field>
</form>
