import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OperationalStateThemes } from '@amp/devices';
import { CoreModule, IconModule, ThemeType } from '@activia/ngx-components';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { DisplayInputType } from '../models/player-unit.enum';
import { DeviceDTO, DisplayInputDTO } from '@activia/cm-api';
import { DeviceState } from '../store/device/device.reducer';
import * as DeviceSelectors from '../store/device/device.selectors';
import { TranslocoModule } from '@ngneat/transloco';

@Component({
  selector: 'amp-display-input-editor',
  standalone: true,
  imports: [CommonModule, CoreModule, IconModule, TranslocoModule],
  templateUrl: './display-input-editor.component.html',
  styleUrls: ['./display-input-editor.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DisplayInputEditorComponent implements OnInit {
  /** If it's possible to edit the input or not */
  @Input() editable: boolean;

  @Input() boardId: number;

  /** Device connected to the input */
  @Input() device: DeviceDTO;

  /** Primary or backup */
  @Input() displayInputType: DisplayInputType;

  /** Represent input connection to the device */
  @Input() displayInput: DisplayInputDTO;

  /** When user click to add a new connection */
  @Output() addInput = new EventEmitter<void>();

  /** When user delete the current device in the input */
  @Output() deleteDevice = new EventEmitter<void>();

  /** When user delete the current input */
  @Output() deleteInput = new EventEmitter<void>();

  @Output() shakeLock = new EventEmitter<boolean>();

  activatedDeviceId$: Observable<number>;

  /** Drop area target to link it with device list side menu */
  dragDropTarget$: Observable<string[]>;

  DisplayInputType = DisplayInputType;
  OperationalStateThemes = OperationalStateThemes;
  ThemeType = ThemeType;

  constructor(private _deviceStore: Store<DeviceState>) {}

  ngOnInit(): void {
    this.activatedDeviceId$ = this._deviceStore.select(DeviceSelectors.selectActivatedDevice);
  }

  onClick() {
    this.addInput.emit();
  }

  deleteDeviceInput(event: Event) {
    event.stopPropagation();

    if (this.displayInput.deviceId === null || this.displayInput.deviceId === undefined) {
      this.deleteDevice.emit();
    } else {
      this.deleteInput.emit();
    }
  }

  onShakeLock(event: MouseEvent) {
    this.shakeLock.emit(event.type === 'mouseenter');
  }

  getLabel() {
    return this.displayInputType === DisplayInputType.PRIMARY
      ? 'siteManagementScope.SITE_MANAGEMENT.BOARD_EDITOR.GLOBAL.PRIMARY_10'
      : 'siteManagementScope.SITE_MANAGEMENT.BOARD_EDITOR.GLOBAL.SECONDARY_10';
  }

  getLabelForNoInput() {
    if (this.displayInputType === DisplayInputType.PRIMARY) {
      return this.editable ? 'siteManagementScope.SITE_MANAGEMENT.BOARD_EDITOR.GLOBAL.ADD_PRIMARY_10' : 'siteManagementScope.SITE_MANAGEMENT.BOARD_EDITOR.GLOBAL.PRIMARY_NOT_SET_10';
    } else {
      return this.editable ? 'siteManagementScope.SITE_MANAGEMENT.BOARD_EDITOR.GLOBAL.ADD_SECONDARY_10' : 'siteManagementScope.SITE_MANAGEMENT.BOARD_EDITOR.GLOBAL.SECONDARY_NOT_SET_10';
    }
  }

  getLabelNotSet() {
    if (this.displayInputType === DisplayInputType.PRIMARY) {
      return 'siteManagementScope.SITE_MANAGEMENT.BOARD_EDITOR.GLOBAL.PRIMARY_NOT_SET_10';
    } else {
      return 'siteManagementScope.SITE_MANAGEMENT.BOARD_EDITOR.GLOBAL.SECONDARY_NOT_SET_10';
    }
  }
}
