<avn-dialog-layout>
  <avn-dialog-header [title]="'commands.COMMANDS.TASK_PANEL.TITLE_20' | transloco"></avn-dialog-header>

  <avn-dialog-content avnScroll>
    <avn-data-table
      [columns]="columns$ | async"
      [dataSource]="dataSource$ | async"
      [rowHeight]="80"
      [tableHeight]="'auto'"
      [showHeader]="true"
      [serverSorting]="false"
      [infiniteScrollEnabled]="false"
      [sortBy]="sortBy"
    >
    </avn-data-table>
  </avn-dialog-content>
</avn-dialog-layout>

<ng-template #iconTemplate let-row="row">
  <avn-status-icon class="notification-icon" [statusTheme]="row.theme">
    <avn-icon [icon]="row.icon"></avn-icon>
  </avn-status-icon>
</ng-template>
<ng-template #progressTemplate let-row="row">
  <avn-command-progress-bar
    [title]="row.title"
    [hasProgressInfo]="row.hasProgressInfo"
    [progressPercentCompleted]="row.progressPercentCompleted"
    [isCompleted]="row.isCompleted"
    [commandCount]="row.commandStatus.count"
    [okCommandCount]="row.commandStatus.ok"
    [failedCommandCount]="row.commandStatus.failed"
    [otherCommandCount]="row.commandStatus.other"
    [triggerInfo]="row.triggerInfo"
  ></avn-command-progress-bar>
</ng-template>
<ng-template #dateTemplate let-row="row">
  {{ row.commandStatus.startDate | timeAgo | async }}
</ng-template>
