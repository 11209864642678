<ng-container *ngIf="healthStats$ | async as healthStats">
  <avn-icon
    *ngIf="healthStats.status !== OrganizationLevelHealthStatus.OK"
    [icon]="healthStats.icon"
    [avnTheme]="healthStats.theme"
    [avnTooltip]="healthStats.tooltip | transloco: { value: healthStats.count, total: healthStats.total }"
    [tooltipTheme]="healthStats.theme"
    tooltipPlacement="top"
    [tooltipDisabled]="true"
    (click)="openBoardAlarm()"
  ></avn-icon>
</ng-container>
