/**
 * Reference: https://confluence.stratacache.com/display/CM/AMP+Player+Alarms#AMPPlayerAlarms-Levels
 *
 * value of each enum item is the code of the level from the backend and the severity (from high to low)
 * */
import { ThemeType } from '@activia/ngx-components';

export enum AlarmEventLevel {
  Emergency = 0,
  Alert = 1,
  Critical = 2,
  Error = 3,
  Warning = 4,
  Notice = 5,
  Info = 6,
  Debug = 7,
}

export interface IAlarmEventLevelInfo {
  theme: ThemeType;
  shade: number;
  icon: string;
}

/** Theme & icon info for each alarm level **/
export const AlarmLevelsInfo: Record<AlarmEventLevel, IAlarmEventLevelInfo> = {
  [AlarmEventLevel.Emergency]: { theme: ThemeType.DANGER, shade: 500, icon: 'action:dangerous' },
  [AlarmEventLevel.Alert]: { theme: ThemeType.DANGER, shade: 500, icon: 'content:report' },
  [AlarmEventLevel.Critical]: { theme: ThemeType.DANGER, shade: 500, icon: 'alert:error' },
  [AlarmEventLevel.Error]: { theme: ThemeType.DANGER, shade: 500, icon: 'alert:error_outline' },
  [AlarmEventLevel.Warning]: { theme: ThemeType.WARNING, shade: 500, icon: 'alert:warning' },
  [AlarmEventLevel.Notice]: { theme: ThemeType.WARNING, shade: 500, icon: 'alert:warning_outline' },
  [AlarmEventLevel.Info]: { theme: ThemeType.INFO, shade: 500, icon: 'action:info' },
  [AlarmEventLevel.Debug]: { theme: ThemeType.INFO, shade: 500, icon: 'action:info' },
};
