import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ISiteMonitoringKeyMetricComponent, KeyMetricsBaseMixin } from '../key-metrics.mixin';
import { codCardConfig } from '../key-metric-viewer-config.constant';

@Component({
  selector: 'amp-key-metrics-cod',
  templateUrl: './key-metrics-cod.component.html',
  styleUrls: ['./key-metrics-cod.component.scss'],
  inputs: ['config', 'monitoringData', 'monitoringDate', 'monitoringDataDataState', 'showCompactView'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class KeyMetricsCodComponent extends KeyMetricsBaseMixin() implements ISiteMonitoringKeyMetricComponent {
  cardConfig = codCardConfig;

  constructor() {
    super();
  }
}
