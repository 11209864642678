import { InjectionToken } from '@angular/core';
import { LEGACY_PATH, GRAFANA_PATH, PIP_PATH, AMP_WSS_PATH } from '@amp/environment';
import { IAmpConfig } from './amp-config.interface';
import { BASE_PATH as AMP_API_BASE_PATH } from '@activia/cm-api';
import { BASE_PATH as DEVICE_SCREENSHOT_API_BASE_PATH } from '@activia/device-screenshot-api';

/**
 * this token matches the IAmpConfig that is retreived before the app boots.
 */
export const AMP_CONFIGURATION_TOKEN = new InjectionToken<IAmpConfig>('AMP_CONFIGURATION_TOKEN');

/**
 * a factory that returns the PIP url from amp-config.json
 */
export const getAmpApiURLFromConfig = (config: IAmpConfig): string => config.cmEngineApiUrl;

/**
 * a Factory that returns the legacy app url from amp-config.json
 */
export const getLegacyURLFromConfig = (config: IAmpConfig): string =>
  // if the legacy url is specified in the environment variables then use it
   config.legacyUrl
;

/**
 * a Factory that returns the grafana url from amp-config.json
 */
export const getGrafanaURLFromConfig = (config: IAmpConfig): string => config.grafanaUrl;

/**
 * a factory that returns the WSS url from amp-config.json
 */
export const getPipURLFromConfig = (config: IAmpConfig): string => config.pipUrl;

/**
 * a factory that returns the WSS websocket from amp-config.json
 */
export const getWssUrlFromConfig = (config: IAmpConfig): string => config.ampEngineWebsocketUrl;

/**
 * providers for amp initialization
 * config for amp-engina paths etc.
 * these are used in all apps
 */
export const AMP_CONFIG_PROVIDERS = [
  {
    provide: AMP_API_BASE_PATH,
    useFactory: getAmpApiURLFromConfig,
    deps: [AMP_CONFIGURATION_TOKEN],
  },
  {
    provide: DEVICE_SCREENSHOT_API_BASE_PATH,
    useFactory: getPipURLFromConfig,
    deps: [AMP_CONFIGURATION_TOKEN],
  },
  {
    provide: PIP_PATH,
    useFactory: getPipURLFromConfig,
    deps: [AMP_CONFIGURATION_TOKEN],
  },
];

/**
 * provider for grafana
 */
export const AMP_GRAFANA_PROVIDER = [
  {
    provide: GRAFANA_PATH,
    useFactory: getGrafanaURLFromConfig,
    deps: [AMP_CONFIGURATION_TOKEN],
  },
];

/**
 * provider for amp legacy url
 * used when for old cm is present
 */
export const AMP_LEGACY_PROVIDER = [
  {
    provide: LEGACY_PATH,
    useFactory: getLegacyURLFromConfig,
    deps: [AMP_CONFIGURATION_TOKEN],
  },
];

/**
 * provider for websockets on amp.
 * required for notifications
 */
export const AMP_WSS_PROVIDER = [{ provide: AMP_WSS_PATH, useFactory: getWssUrlFromConfig, deps: [AMP_CONFIGURATION_TOKEN] }];
