import { MonitoringAlarmEventDTO } from '@activia/cm-api';
import { Pipe, PipeTransform } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { BoardDeviceAlarmsLabels, IBoardDeviceAlarmInfo } from './store/site-monitoring-detail.model';
import { getHealthErrorIdLabel } from '@amp/devices';

/**
 * Convert custom board alarms to the more generic MonitoringAlarmEventDTO used by player events.
 * So that we can reuse the device alarm component.
 * */
@Pipe({ name: 'boardAlarm' })
export class BoardAlarmPipe implements PipeTransform {
  constructor(private _translate: TranslocoService) {}

  transform({ alarm, data }: IBoardDeviceAlarmInfo): MonitoringAlarmEventDTO {
    if (alarm === 'event') {
      return data;
    }
    // build the alarm message
    const messages: string[] = [];

    const alarmLabel = BoardDeviceAlarmsLabels[alarm];
    if (alarmLabel) {
      messages.push(this._translate.translate(alarmLabel));
    }

    if (alarm === 'device-health-error') {
      const { healthErrorId, healthErrorExtraInfo } = data.jsonData as any;
      messages.push(this._translate.translate(getHealthErrorIdLabel(healthErrorId)));
      if (healthErrorExtraInfo) {
        messages.push(healthErrorExtraInfo);
      }
    }

    return {
      ...data,
      longMessage: messages.join(' - '),
    };
  }
}
