
export enum HealthErrorIds {
  PLAYER_SERVICE_DOWN = 100,
  HTTP_SERVICE_DOWN = 110,
  OMNICAST_SERVICE_DOWN = 120,
  FTP_SERVICE_DOWN = 130,
  MISSING_CONTENT = 200,
  HD_ERROR = 300,
  FAN_ERROR = 310,
  HIGH_PROCESSOR_TEMPERATURE = 320,
  HIGH_CPU = 400,
  HIGH_MEMORY = 410,
}

export const HealthErrorIdNames: { [key: number]: string } = {
  [HealthErrorIds.PLAYER_SERVICE_DOWN]: 'PLAYER_SERVICE_DOWN',
  [HealthErrorIds.HTTP_SERVICE_DOWN]: 'HTTP_SERVICE_DOWN',
  [HealthErrorIds.OMNICAST_SERVICE_DOWN]: 'OMNICAST_SERVICE_DOWN',
  [HealthErrorIds.FTP_SERVICE_DOWN]: 'FTP_SERVICE_DOWN',
  [HealthErrorIds.MISSING_CONTENT]: 'MISSING_CONTENT',
  [HealthErrorIds.HD_ERROR]: 'HD_ERROR',
  [HealthErrorIds.FAN_ERROR]: 'FAN_ERROR',
  [HealthErrorIds.HIGH_PROCESSOR_TEMPERATURE]: 'HIGH_PROCESSOR_TEMPERATURE',
  [HealthErrorIds.HIGH_CPU]: 'HIGH_CPU',
  [HealthErrorIds.HIGH_MEMORY]: 'HIGH_MEMORY',
};

export const getHealthErrorIdLabel = (healthErrorId: string) => {
  const found = Object.keys(HealthErrorIds).find((key) => HealthErrorIds[key] === +healthErrorId);
  return `deviceFields.DEVICE.ENUM.HEALTH_ERROR_IDS.${found}`;
};
