<main id="content" role="main">
  <h1 class="error-code" cmResponsiveElement>{{ httpStatusCode }}</h1>
  <p class="error-description">
    {{ errorText }}
  </p>
  <p class="error-sub-description" *ngIf="errorDescriptionText">
    {{ errorDescriptionText }}
  </p>
  <avn-button avnTheme="primary" [label]="backHrefText" (actioned)="navigateBack()"></avn-button>
</main>
<footer class="page-footer" [innerHTML]="'app.copyright' | transloco"></footer>
