import { IModalConfig, MODAL_CONFIG, ModalRef, ThemeType } from '@activia/ngx-components';
import { ChangeDetectionStrategy, Component, EventEmitter, Inject, Output } from '@angular/core';

export type SaveDialogResult = 'save' | 'discard' | 'cancel';

@Component({
  selector: 'amp-save-before-exit-dialog',
  templateUrl: './save-before-exit-dialog.component.html',
  styleUrls: ['./save-before-exit-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SaveBeforeExitDialogComponent {
  @Output() actioned = new EventEmitter<SaveDialogResult>();

  ThemeType = ThemeType;

  constructor(private _dialogRef: ModalRef<SaveBeforeExitDialogComponent>, @Inject(MODAL_CONFIG) public dialogConfig: IModalConfig<{ hasInvalidChanges: boolean }>) {}

  canClose(): boolean {
    return false;
  }

  onClose() {
    this._dialogRef.close();
  }

  onCancel() {
    this.actioned.emit('cancel');
    this.onClose();
  }

  onDiscard() {
    this.actioned.emit('discard');
    this.onClose();
  }

  onSave() {
    this.actioned.emit('save');
    this.onClose();
  }
}
